import {
    Component,
    ChangeDetectionStrategy,
    ViewChild,
    ElementRef,
    Input,
    ChangeDetectorRef,
    Output,
    EventEmitter,
} from '@angular/core';
import { Chart, ChartConfiguration } from 'chart.js';

export interface LabelItem {
    label: string;
    color: string;
    hidden?: boolean;
}

@Component({
    selector: 'widget-pie-chart',
    styleUrls: ['./widget-pie-chart.sass'],
    template: `
        <section class="canvas-wrapper">
            <canvas #canvas></canvas>
            <section class="chart-tooltip" *ngIf="tooltip" [ngStyle]="tooltip.position">
                {{ tooltip.label }}: {{ tooltip.value | uninumberformat: 'money' }}
            </section>
        </section>

        <section *ngIf="legendItems?.length" class="pie-chart-legend">
            <section
                *ngFor="let item of legendItems; let idx = index"
                (click)="onLegendClick(item)"
                class="legend-item"
            >
                <section class="legend-dot" [style.background]="item.color"></section>
                <section
                    class="legend-label"
                    [class.item-hidden]="item.hidden"
                    [matTooltip]="item.label?.length >= 20 ? item.label : undefined"
                >
                    {{ item.label }}
                </section>
            </section>
        </section>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetPieChart {
    @ViewChild('canvas', { static: true })
    private canvas: ElementRef<HTMLCanvasElement>;

    @Input()
    chartConfig: ChartConfiguration;

    @Output()
    legendItemVisibilityChange = new EventEmitter<LabelItem[]>();

    chartRef: Chart;
    legendItems: LabelItem[];
    tooltip: { label: string; value: number; position };

    constructor(private cdr: ChangeDetectorRef) {}

    ngOnChanges(changes) {
        if (changes['chartConfig'] && this.chartConfig) {
            this.chartRef?.destroy();

            if (!this.chartConfig.options.plugins) {
                this.chartConfig.options.plugins = {};
            }

            this.overrideLegend();
            this.overrideTooltip();

            this.chartRef = new Chart(this.canvas.nativeElement, this.chartConfig);
        }
    }

    ngOnDestroy() {
        this.chartRef?.destroy();
    }

    onLegendClick(legendItem) {
        const index = this.legendItems.findIndex((item) => item === legendItem);
        if (index >= 0) {
            legendItem.hidden = !legendItem.hidden;
            if (legendItem.hidden) {
                this.chartRef.hide(0, index);
            } else {
                this.chartRef.show(0, index);
            }
            this.legendItemVisibilityChange.emit(this.legendItems);
        }
    }

    private overrideLegend() {
        try {
            const legendVisible = this.chartConfig.options?.plugins?.legend?.display !== false;
            if (legendVisible) {
                const labels = this.chartConfig.data.labels;
                const colors = this.chartConfig.data.datasets[0].backgroundColor;

                this.legendItems = labels.map((label: string, index) => {
                    return {
                        label,
                        color: colors[index],
                        hidden: false,
                    };
                });
                this.legendItemVisibilityChange.emit(this.legendItems);

                this.chartConfig.options.plugins.legend = { display: false };
            }
        } catch (e) {
            console.error(e);
        }
    }

    private overrideTooltip() {
        try {
            this.chartConfig.options.plugins.tooltip = {
                enabled: false,
                mode: 'index',
                position: 'nearest',
                external: (context) => {
                    const tooltip = context.tooltip;
                    if (tooltip.opacity && tooltip.dataPoints?.length) {
                        const labels = <string[]>this.chartConfig.data.labels;
                        const data = <any[]>this.chartConfig.data.datasets[0].data;
                        const index = tooltip.dataPoints[0].dataIndex;

                        this.tooltip = {
                            label: labels[index],
                            value: data[index],
                            position: {
                                top: tooltip.caretY + 'px',
                                left: tooltip.caretX + 'px',
                            },
                        };
                    } else {
                        this.tooltip = undefined;
                    }

                    this.cdr.markForCheck();
                },
            };
        } catch (e) {
            console.error(e);
        }
    }
}
