<section *ngIf="headerAboveIllustration?.length" class="header">
    <h1>{{ headerAboveIllustration }}</h1>
</section>

<section class="content" [class.dnb-padding]="isDnb" *ngIf="!busy">
    <section class="image-wrapper">
        <img [src]="illustrationUrl" alt="Product highlight illustration" />
    </section>

    <h1 *ngIf="headerBelowIllustration?.length" class="header-below">{{ headerBelowIllustration }}</h1>

    <p><span [innerHtml]="description | keepHtml"></span></p>

    <section class="buttons">
        <button *ngIf="mainAction" class="c2a" (click)="mainAction()">
            {{ mainActionLabel }} <i *ngIf="mainActionIcon" class="material-icons">{{ mainActionIcon }}</i>
        </button>

        <button *ngIf="secondaryAction" class="secondary" (click)="secondaryAction()">
            {{ secondaryActionLabel }}
            <i *ngIf="secondaryActionIcon" class="material-icons">{{ secondaryActionIcon }}</i>
        </button>
    </section>
</section>
