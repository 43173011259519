import { Injectable } from '@angular/core';
import { BizHttp, UniHttp, RequestMethod } from '@uni-framework/core/http';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
    CustomerInvoiceReminder,
    SharingType,
    PrerunInvoiceReminderResult,
    InvoiceAndReminderStatus,
} from '../../unientities';
import { Observable } from 'rxjs';
import { UniCache } from '@app/cache';
import { map, tap } from 'rxjs/operators';
import { RemindersFilterWithCountDTO } from '@app/components/sales/reminders/collector-status-code.enum';

@Injectable({ providedIn: 'root' })
export class CustomerInvoiceReminderService extends BizHttp<CustomerInvoiceReminder> {
    reminderCache = new UniCache(5);

    constructor(
        http: UniHttp,
        private httpClient: HttpClient,
    ) {
        super(http);

        this.relativeURL = CustomerInvoiceReminder.RelativeUrl;
        this.entityType = CustomerInvoiceReminder.EntityType;
        this.DefaultOrderBy = null;

        // Clear custom cache on BizHttp cache invalidation (company change etc)
        this.cacheInvalidated$.subscribe(() => this.reminderCache.clear());
    }

    processReminderData = (reminder, ledgerData) => {
        const ledgerValue = ledgerData?.find((item) => item.CustomerID === reminder.CustomerID)?.LedgerValue;
        reminder.ledgerValue = ledgerValue;
        if (reminder.ProjectNumber) {
            reminder.project = `${reminder.ProjectNumber} - ${reminder.ProjectName}`;
        }

        if (reminder.DepartmentNumber) {
            reminder.department = `${reminder.DepartmentNumber} - ${reminder.DepartmentName}`;
        }
        return reminder;
    };

    getReminders(
        includeInvoiceWithReminderStop: boolean = false,
        top = 0,
        skip = 0,
        orderby = '',
        filter = '',
    ): Observable<InvoiceAndReminderStatus[]> {
        const params = new HttpParams()
            .set('includeInvoiceWithReminderStop', includeInvoiceWithReminderStop)
            .set('top', top)
            .set('skip', skip)
            .set('orderby', orderby)
            .set('filter', filter);

        return this.GetAction(null, `get-customer-invoices-ready-for-reminding`, params.toString());
    }

    getinvoicesWithActiveReminders(
        includeInvoiceWithReminderStop: boolean = false,
        top = 0,
        skip = 0,
        orderby = '',
        filter = '',
    ): Observable<InvoiceAndReminderStatus[]> {
        let params = new HttpParams()
            .set('includeInvoiceWithReminderStop', includeInvoiceWithReminderStop)
            .set('top', top)
            .set('skip', skip)
            .set('orderby', orderby)
            .set('filter', filter);

        return this.GetAction(null, `get-invoices-with-active-reminders`, params.toString());
    }

    getSingleReminder(invoiceID: number) {
        const url = `/api/biz/${this.relativeURL}?action=get-customer-invoice-and-reminder-data&invoiceId=${invoiceID}&getDueInvoicesOnly=true&includeInvoiceWithReminderStop=false`;

        return this.httpClient.get<InvoiceAndReminderStatus[]>(url);
    }

    getCustomerInvoiceReminderSummary() {
        return this.GetAction(null, 'get-customer-invoice-and-reminder-sums');
    }

    getPreRunInfoForReminders(invoiceIDs?: number[]): Observable<PrerunInvoiceReminderResult> {
        return this.ActionWithBody(null, invoiceIDs, 'prerun-invoicereminders-for-invoicelist', 'post');
    }

    getPreRunInfoFromParams(params: HttpParams): Observable<PrerunInvoiceReminderResult> {
        return this.PostAction(null, 'prerun-invoicereminders-from-parameters', params.toString());
    }

    createInvoiceRemindersFromParams(reminderSteps?: number[]) {
        const params = new HttpParams().set('reminderSteps', reminderSteps?.join(',') ?? '');
        return this.PostAction(null, 'create-customerinvoice-reminders-from-parameters', params.toString());
    }

    createInvoiceRemindersForInvoicelist(list: number[]): Observable<CustomerInvoiceReminder[]> {
        return this.ActionWithBody(null, list, `create-invoicereminders-for-invoicelist`, RequestMethod.Post);
    }

    getCountsForReminderView(): Observable<RemindersFilterWithCountDTO[]> {
        return this.GetAction(null, 'get-invoice-and-reminder-count');
    }

    public getSharingTypeText(sharingType: number): string {
        switch (sharingType) {
            case SharingType.Email:
                return 'E-post';
            case SharingType.Print:
                return 'Utskrift';
            case SharingType.InvoicePrint:
                return 'Fakturaprint';
            default:
                return '';
        }
    }

    revertLastReminderForInvoice(invoiceIDs: number[]) {
        return this.ActionWithBody(0, invoiceIDs, 'revert-last-reminder', RequestMethod.Put);
    }

    setStatusToDebtCollection(invoiceIDs: number[]) {
        return this.ActionWithBody(null, invoiceIDs, 'set-status-to-debt-collection', RequestMethod.Put);
    }

    creditOpenFeeAndInterest(invoiceID: number): Observable<string> {
        const params = new HttpParams().set('invoiceID', invoiceID);
        return this.PutAction<string>(0, 'credit-open-fee-and-interests', params.toString());
    }

    transferToOrderOrInvoice(
        invoiceID: number,
        entity: string,
        entityID: number,
        productID: number,
    ): Observable<string> {
        const params = new HttpParams()
            .set('invoiceID', invoiceID)
            .set('entity', entity)
            .set('entityID', entityID)
            .set('productID', productID);
        return this.PutAction<string>(0, 'transfer-fee-or-interest-to-invoice-or-order', params.toString());
    }

    setRemindersToComplete(list: number[]) {
        return this.httpClient
            .put(`/api/biz/invoicereminders/?action=set-status-to-complete`, list)
            .pipe(tap(() => this.invalidateCache()));
    }

    exportToExcel(params: HttpParams): Observable<Blob> {
        var paramString = params.toString();

        return this.http
            .usingBusinessDomain()
            .asGET()
            .withEndPoint(
                `invoicereminders?action=export-reminders-to-excel${paramString.length ? '&' + paramString : ''}`,
            )
            .send({ responseType: 'blob' })
            .pipe(map((res) => new Blob([res.body])));
    }
}
