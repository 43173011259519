import { Component, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Contact, BusinessRelation } from '@uni-entities';
import { UniTableColumn, UniTableColumnType, UniTableConfig } from '@uni-framework/ui/unitable';
import { ConfirmActions, ConfirmModal, UniModalService } from '@uni-framework/uni-modal';
import { ContactModal } from './modals/contact-modal';
import { MainContactCell } from './main-contact-cell';

@Component({
    selector: 'contacts',
    templateUrl: './contacts.html',
    styleUrls: ['./contacts.sass'],
})
export class Contacts {
    @Input() parentBusinessRelation: BusinessRelation;
    @Output() parentBusinessRelationChange: EventEmitter<BusinessRelation> = new EventEmitter();

    public contactTableConfig: UniTableConfig;
    public contacts: Contact[];

    constructor(private modalService: UniModalService) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes['parentBusinessRelation'] && changes['parentBusinessRelation'].currentValue) {
            this.contacts = this.parentBusinessRelation.Contacts;
            this.setupTable();
        }
    }

    openContactEditModal(contact: Contact) {
        this.modalService
            .open(ContactModal, {
                data: {
                    editMode: true,
                    contact: contact,
                },
            })
            .onClose.subscribe((result) => {
                if (result && result.action === ConfirmActions.ACCEPT && result.contact) {
                    const contact = result.contact;
                    let index;

                    if (contact.ID) {
                        index = this.contacts.map((x) => x.ID).indexOf(contact.ID);
                    } else {
                        index = this.contacts.map((x) => x.Info._createguid).indexOf(contact.Info._createguid);
                    }

                    contact.ParentBusinessRelationID = this.parentBusinessRelation.ID;

                    if (index !== -1) {
                        this.contacts[index] = contact;
                    }

                    if (contact['_maincontact'] && contact.Deleted) {
                        this.parentBusinessRelation.DefaultContactID = null;
                    }

                    this.parentBusinessRelation.Contacts = this.contacts;
                    this.parentBusinessRelationChange.emit(this.parentBusinessRelation);
                }
                return;
            });
    }

    public openSetContactAsMainModal(contact: Contact) {
        const header = 'Sett som hovedkontakt';
        const message = `Ønsker du at ${contact.Info.Name} skal være hovedkontakt?<br>
            Du kan kun ha en hovedkontakt per leverandør.
            Dette valget vil derfor overstyre tildligere valgte hovedkontakt.`;

        this.modalService
            .confirm({
                header: header,
                message: message,
                buttonLabels: {
                    accept: 'Sett som hovedkontakt',
                    cancel: 'Avbryt',
                },
                size: 'xs',
            })
            .onClose.subscribe((response) => {
                if (response === ConfirmActions.ACCEPT) {
                    if (contact.ID && this.parentBusinessRelation.DefaultContactID !== contact.ID) {
                        this.parentBusinessRelation.DefaultContactID = contact.ID;
                        this.contacts = this.contacts.map((x) => {
                            x['_maincontact'] = x.ID === contact.ID;
                            return x;
                        });

                        this.parentBusinessRelation.Contacts = this.contacts;
                        this.parentBusinessRelationChange.emit(this.parentBusinessRelation);
                    }
                }
            });
    }

    private setupTable() {
        const nameCol = new UniTableColumn('Info.Name', 'Navn', UniTableColumnType.Text, false).setWidth(250);
        const titleCol = new UniTableColumn('Role', 'Rolle', UniTableColumnType.Text, false);
        const phoneCol = new UniTableColumn('Info.DefaultPhone.Number', 'Telefon', UniTableColumnType.Text, false);
        const emailCol = new UniTableColumn(
            'Info.DefaultEmail.EmailAddress',
            'E-postadresse',
            UniTableColumnType.Text,
            false,
        );

        const isMainContactCol = new UniTableColumn('_maincontact', 'Status', UniTableColumnType.Text, false)
            .setTemplate((item) => {
                if (typeof item._maincontact === 'boolean' && item._maincontact) {
                    return `Hovedkontakt`;
                } else if (item.ID) {
                    return `Sett som hovedkontakt`;
                } else {
                    return '';
                }
            })
            .setCellComponent(MainContactCell, {
                onMainContactChange: (item) => this.openSetContactAsMainModal(item),
            })
            .setWidth(185, false);

        this.contactTableConfig = new UniTableConfig('accounting.supplier.contacts', false)
            .setColumnMenuVisible(false)
            .setColumns([nameCol, titleCol, emailCol, phoneCol, isMainContactCol]);
    }
}
