import { forkJoin, of } from 'rxjs';
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { SubEntity, SubEntityHistoricAga } from '@uni-entities';
import { StatisticsService } from '@app/services/common/statisticsService';
import { AgaZoneService } from '@app/services/common/agaZoneService';
import { switchMap } from 'rxjs/operators';
import { ToastService, ToastType } from '@uni-framework/uniToast/toastService';
import { IUniModal, IModalOptions, UniModalService, ConfirmActions } from '@uni-framework/uni-modal';
import { ErrorService } from '@app/services/common/errorService';
import { SubEntityHistoricAgaService } from '@app/services/common/subEntityHistoricAgaService';

@Component({
    selector: 'uni-show-subentityhistoricaga-modal',
    templateUrl: './show-subentityhistoricaga-modal.html',
    styleUrls: ['./show-subentityhistoricaga-modal.sass'],
})
export class ShowSubEntityHistoricAgaModal implements OnInit, IUniModal {
    @Output() onClose: EventEmitter<SubEntity[]> = new EventEmitter();
    @Input() options?: IModalOptions;

    subEntityHistoricAga: SubEntityHistoricAga[] = [];

    constructor(
        private statisticsService: StatisticsService,
        private agaZoneService: AgaZoneService,
        private historicAgaService: SubEntityHistoricAgaService,
        private errorService: ErrorService,
        private toastService: ToastService,
        private modalService: UniModalService,
    ) {}

    ngOnInit() {
        const subEntityID = this.options.data.SubEntityID;
        const agaSector = this.options.data.agaSector;

        const histAga = `model=SubEntityHistoricAga&select=ID as ID,AgaZone as AgaZone,AgaRule as AgaRule,UsedUntil as UsedUntil&filter=SubEntityID eq ${subEntityID}`;
        const subEntityHistAga = this.statisticsService.GetAllUnwrapped(histAga);

        forkJoin([subEntityHistAga, this.agaZoneService.getAgaZones()]).subscribe(([histAga, agaZones]) => {
            this.subEntityHistoricAga = histAga.map((s) => {
                s['ID'] = s.ID;
                s['AgaZoneName'] = agaZones.find((zone) => zone.ID === s.AgaZone).ZoneName;
                s['SectorName'] = agaSector.find((sector) => sector.SectorID === s.AgaRule).Sector;
                return s;
            });
        });
    }

    deleteHistoricAga(id: number) {
        const options: IModalOptions = {
            header: 'Bekreft sletting av historisk aga',
            message: `Er du sikker på at du vil slette den historiske agasonen/beregningsregel?`,
        };
        this.modalService
            .confirm(options)
            .onClose.pipe(
                switchMap((response) => {
                    if (response !== ConfirmActions.ACCEPT) {
                        return of(false);
                    }
                    return this.historicAgaService.Remove(id);
                }),
            )
            .subscribe({
                next: (response) => {
                    if (response) {
                        this.toastService.addToast('Historisk aga er fjernet', ToastType.good, 10);
                        this.onClose.next(undefined);
                    }
                },
                error: (error) => this.errorService.handle(error),
            });
    }

    close() {
        this.onClose.next(undefined);
    }
}
