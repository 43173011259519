import { Component, EventEmitter } from '@angular/core';
import { AuthService } from '@app/authService';
import { CompanyService } from '@app/services/common/companyService';
import { ErrorService } from '@app/services/common/errorService';
import { Company } from '@uni-entities';
import { IModalOptions } from '@uni-framework/uni-modal';
import { ToastService, ToastType } from '@uni-framework/uniToast/toastService';

@Component({
    selector: 'file-flow-email-modal',
    templateUrl: './file-flow-email-modal.html',
    styleUrls: ['./file-flow-email-modal.sass'],
})
export class FileFlowEmailModal {
    options: IModalOptions = {};
    onClose = new EventEmitter<Company>();

    busy = true;
    company: Company;

    fileFlowEnabled: boolean;
    emailDomain: string;
    orgNumberEmailWithoutDomain: string;
    customEmail: string;

    hasChanges: boolean;

    activationMode: boolean;

    constructor(
        private authService: AuthService,
        private companyService: CompanyService,
        private errorService: ErrorService,
        private toastService: ToastService,
    ) {}

    ngOnInit() {
        if (this.options?.data?.activate) {
            this.companyService.activateFileFlow().subscribe(
                (company) => {
                    this.activationMode = true;
                    this.company = company;
                    this.setFileFlowVariables(company);
                    this.busy = false;
                },
                (err) => {
                    this.errorService.handle(err);
                    this.onClose.emit();
                },
            );
        } else {
            this.companyService.Get(this.authService.activeCompany.ID).subscribe(
                (company) => {
                    this.company = company;
                    this.setFileFlowVariables(company);
                    this.busy = false;
                },
                (err) => {
                    this.errorService.handle(err);
                    this.onClose.emit();
                },
            );
        }
    }

    private setFileFlowVariables(company: Company) {
        this.fileFlowEnabled = !!company.FileFlowEmail || !!company.FileFlowOrgnrEmail;

        if (company.FileFlowEmail) {
            const [email, domain] = company.FileFlowEmail.split('@');
            this.customEmail = email;
            this.emailDomain = domain;
        }

        this.orgNumberEmailWithoutDomain = company.FileFlowOrgnrEmail?.split('@')[0];
    }

    onCustomEmailChange() {
        const newEmail = this.customEmail + '@' + this.emailDomain;
        this.hasChanges = newEmail !== this.company.FileFlowEmail;
    }

    activateFileFlow() {
        this.busy = true;
        this.companyService.activateFileFlow().subscribe(
            (company) => {
                this.company = company;
                this.authService.activeCompany = company;
                this.setFileFlowVariables(company);
                this.busy = false;
            },
            (err) => {
                this.errorService.handle(err);
                this.busy = false;
            },
        );
    }

    deactivateAndClose() {
        this.busy = true;
        this.companyService.deactivateFileFlow().subscribe(
            (company) => {
                this.authService.activeCompany = company;
                this.onClose.emit();
            },
            (err) => {
                this.errorService.handle(err);
                this.busy = false;
            },
        );
    }

    toggleOrgNumberEmail(enabled: boolean) {
        const action = enabled ? 'create-orgnr-email' : 'disable-orgnr-email';
        this.busy = true;

        this.companyService.PutAction(this.company.ID, action).subscribe(
            (company) => {
                this.company = company;
                this.authService.activeCompany = company;
                this.orgNumberEmailWithoutDomain = company.FileFlowOrgnrEmail?.split('@')[0];
                this.busy = false;
                this.toastService.toast({
                    title: `E-postadresse med organisasjonsnummer ${enabled ? 'aktivert' : 'deaktivert'}`,
                    type: ToastType.info,
                    duration: 2,
                });
            },
            () => {
                this.busy = false;
                this.toastService.toast({
                    title: 'Kunne ikke opprette e-post adresse med organisasjonsnummer',
                    message: 'Vennligst sjekk at gyldig organisasjonsnummer er registrert i selskapsinnstillingene',
                    duration: 6,
                });
            },
        );
    }

    saveCustomEmail() {
        const newEmail = this.customEmail + '@' + this.emailDomain;

        if (newEmail === this.company.FileFlowEmail) {
            return;
        }

        this.busy = true;
        this.companyService
            .GetAction(this.company.ID, 'check-email-changed-valid-available', `email=${newEmail}`)
            .subscribe(
                (isValid) => {
                    if (!isValid) {
                        this.toastService.toast({
                            type: ToastType.warn,
                            title: 'E-postadressen er enten ugyldig eller allerede i bruk',
                            duration: 5,
                        });

                        this.busy = false;
                        return;
                    }

                    this.companyService
                        .PutAction(this.company.ID, 'create-update-email', `customEmail=${newEmail}`)
                        .subscribe(
                            (company) => {
                                this.company = company;
                                this.authService.activeCompany = company;
                                this.toastService.toast({
                                    title: 'E-postadresse oppdatert',
                                    type: ToastType.info,
                                    duration: 2,
                                });

                                this.busy = false;
                                this.hasChanges = false;
                            },
                            (err) => {
                                this.errorService.handle(err);
                                this.busy = false;
                            },
                        );
                },
                (err) => {
                    this.errorService.handle(err);
                    this.busy = false;
                },
            );
    }
}
