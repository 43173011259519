<section role="dialog" class="uni-modal">
    <header>{{ activationMode ? 'E-postmottak aktivert' : 'E-postmottak' }}</header>

    <article *ngIf="busy">
        <section class="modal-spinner">
            <mat-spinner class="c2a"></mat-spinner>
        </section>
    </article>

    <article *ngIf="!busy">
        <p *ngIf="activationMode" style="margin: 0 0 1rem">
            Du har nå aktivert e-postmottak, og systemet har laget en e-postadresse basert på navnet på selskapet ditt.
            Denne adressen kan endres om ønskelig.
        </p>

        <section class="info-section flex-column bg-info rounded border mb-2">
            <strong>Egendefinert e-postadresse</strong>

            <section class="email-input flex align-center mt-1">
                <input type="text" [(ngModel)]="customEmail" (ngModelChange)="onCustomEmailChange()" />
                <span class="suffix">&#64;{{ emailDomain }}</span>

                <section class="button-container flex align-center justify-end">
                    <button class="c2a" *ngIf="fileFlowEnabled" (click)="saveCustomEmail()" [disabled]="!hasChanges">
                        Lagre
                    </button>
                </section>
            </section>

            <strong class="mt-3">E-postadresse med organisasjonsnummer</strong>

            <section *ngIf="orgNumberEmailWithoutDomain" class="email-input flex align-center mt-1">
                <input type="text" [value]="orgNumberEmailWithoutDomain" disabled />
                <span class="suffix">&#64;{{ emailDomain }}</span>

                <section class="button-container flex align-center justify-end">
                    <button class="icon-button secondary" (click)="toggleOrgNumberEmail(false)">
                        <i class="material-icons">delete_outline</i>
                    </button>
                </section>
            </section>

            <section *ngIf="!orgNumberEmailWithoutDomain" class="mt-1">
                <p>Det er også mulig å registrere en e-postadresse med org. nummer foran &#64;-symbolet.</p>

                <button
                    *ngIf="!orgNumberEmailWithoutDomain"
                    (click)="toggleOrgNumberEmail(true)"
                    class="secondary small mt-2 mr-auto"
                >
                    Legg til e-post med organisasjonsnummer
                </button>
            </section>
        </section>

        <section *ngIf="!activationMode" class="deactivate flex-column bg-gray rounded border">
            <strong class="mb-1">Slå av e-postmottak</strong>

            <p>Ønsker du ikke lenger å benytte e-postmottak kan det slås av her.</p>

            <button class="secondary bad small mt-2 mr-auto" (click)="deactivateAndClose()">Slå av e-postmottak</button>
        </section>
    </article>

    <footer>
        <button class="secondary bad" *ngIf="activationMode" (click)="deactivateAndClose()">Angre</button>
        <button class="secondary" (click)="onClose.emit()">Lukk</button>
    </footer>
</section>
