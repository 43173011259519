<section class="task-tile flex" *ngIf="task">
    <section class="task-tile-image-container flex align-center justify-center" *ngIf="!task.completed">
        <uni-illustration-deprecated *ngIf="task?.imageLink" [path]="task.imageLink"></uni-illustration-deprecated>
    </section>
    <section
        class="task-tile-action flex align-center justify-center"
        *ngIf="!task.completed"
        (click)="onActionClick()"
    >
        <span class="flex align-center justify-center flex-1 ellipsis"> {{ task.label }} </span>
        <i class="material-icons"> chevron_right </i>
    </section>
    <section class="task-tile-completed" *ngIf="task.completed">
        <i class="material-icons">check_circle</i>
        <span> {{ task.completeLabel }} </span>
    </section>
</section>
