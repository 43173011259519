import { Component, Input } from '@angular/core';
import { ElsaProduct, ElsaProductBillingLevel } from '@app/models';

@Component({
    selector: 'step-price-table',
    templateUrl: './step-price-table.html',
    styleUrls: ['./step-price-table.sass'],
})
export class StepPriceTable {
    @Input() product: ElsaProduct;

    amountHeader: string;
    priceHeader: string;

    ngOnInit() {
        this.setHeaderTexts();
        this.setStepAmountText();
    }

    setHeaderTexts() {
        switch (this.product.BillingLevel) {
            case ElsaProductBillingLevel.PerCompany:
                this.amountHeader = 'Antall selskap';
                this.priceHeader = 'Pris per selskap';
                break;
            case ElsaProductBillingLevel.PerUser:
                this.amountHeader = 'Antall brukere';
                this.priceHeader = 'Pris per bruker';
                break;
            case ElsaProductBillingLevel.PerTransaction:
                this.amountHeader = 'Antall transaksjoner';
                this.priceHeader = 'Pris per transaksjon';
                break;
            default:
                this.amountHeader = 'Antall';
                this.priceHeader = 'Pris';
        }
    }

    setStepAmountText() {
        // just a safeguard, should never happen
        if (!this.product.ProductPrices?.length || !this.product.ProductPrices[0].Steps?.length) return;

        this.product.ProductPrices[0].Steps.map((step) => {
            if (step.StartValue >= 0 && step.EndValue) {
                step['_amount'] = `${step.StartValue} - ${step.EndValue}`;
            } else if (step.StartValue > 0 && !step.EndValue) {
                step['_amount'] = `Flere enn ${step.StartValue - 1}`;
            }
        });
    }
}
