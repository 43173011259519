import { Component, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { FeaturePermissionService } from '@app/featurePermissionService';
import {
    Task,
    AssignmentDetails,
    PaymentBatch,
    PaymentBatchApproveStatus,
    User,
    UserRole,
    Approval,
} from '@uni-entities';
import { IUniModal } from '@uni-framework/uni-modal';
import { map } from 'rxjs/operators';
import { AuthService } from '@app/authService';
import { PaymentBatchService } from '@app/services/accounting/paymentBatchService';
import { ErrorService } from '@app/services/common/errorService';
import { UserRoleService } from '@app/services/common/userRoleService';

@Component({
    selector: 'uni-payment-batch-approval-modal',
    templateUrl: './payment-batch-approval-modal.component.html',
    styleUrls: ['./payment-batch-approval-modal.component.sass'],
})
export class PaymentBatchApprovalModalComponent implements IUniModal {
    onClose = new EventEmitter<boolean>();
    options: any;
    users: User[] = [];
    userIDs: number[] = [];
    message: string;
    busy: boolean;
    isSuccess: boolean;
    approvalStatus: PaymentBatchApproveStatus;
    paymentBatch: PaymentBatch;
    headerText: string;
    approvalsRemaining: number;
    task: Task;
    approval: Approval;
    isDisabled: boolean = false;

    constructor(
        private userRoleService: UserRoleService,
        private errorService: ErrorService,
        private paymentBatchService: PaymentBatchService,
        private router: Router,
        private permissionService: FeaturePermissionService,
        private authService: AuthService,
    ) {}

    ngOnInit(): void {
        this.busy = true;
        this.isDisabled = !this.permissionService.canShowUiFeature('ui.bank.multiple-approvers');
        this.paymentBatch = this.options.data.paymentBatch;
        this.approvalStatus = this.options.data.approvalStatus;
        this.isSuccess = this.approvalStatus.Success;
        this.approvalsRemaining = this.approvalStatus.ApprovalsRemaining;
        this.approval = this.options.data.approval;
        this.headerText = this.isSuccess ? 'Betalingene trenger flere godkjennere' : 'Betalingene er ikke godkjent';
        this.userRoleService
            .GetAll(
                'filter=contains(SharedRoleName, Bank.Approver) or contains(SharedRoleName, Bank.Admin) or contains(SharedRoleName, Bank.Payment) or contains(SharedRoleName, Administrator) or contains(SharedRoleName, Accounting.Admin)&distinct=true',
                ['User'],
            )
            .pipe(map((userRoles: UserRole[]) => userRoles.map((userRole: UserRole) => userRole.User)))
            .subscribe({
                next: (users: User[]) => {
                    const uniqueUserIDs = [];
                    const currentUserId = this.authService.currentUser.ID;
                    this.users = this.isSuccess
                        ? (users || []).filter(
                              (user) =>
                                  user?.StatusCode === 110001 &&
                                  (user?.GlobalIdentity !== this.paymentBatch.CreatedBy ||
                                      this.approval?.Task.Approvals.some((ap) => ap.UserID == currentUserId)) &&
                                  user?.Protected !== true &&
                                  (uniqueUserIDs.includes(user.ID) ? false : uniqueUserIDs.push(user.ID)),
                          ) //user that has created batch is normally not allowed to approve unless something went wrong
                        : (users || []).filter(
                              (user) =>
                                  user?.StatusCode === 110001 &&
                                  user?.Protected !== true &&
                                  (uniqueUserIDs.includes(user.ID) ? false : uniqueUserIDs.push(user.ID)),
                          ); //allow yourself if something went wrong
                    this.userIDs = this.approval?.Task.Approvals.map((x) => x.UserID); //set existing users
                    this.busy = false;
                },
                error: (error) => {
                    this.busy = false;
                    this.errorService.handle(error);
                },
            });
    }

    userSearchFn(searchText: string, user: User) {
        searchText = searchText.toLowerCase();
        return (
            (user.DisplayName || user.UserName)?.toLowerCase().includes(searchText) ||
            user.Email?.toLowerCase().startsWith(searchText)
        );
    }

    assign(): void {
        const assignmentDetails = <AssignmentDetails>{
            Message: this.message,
            UserIDs: this.userIDs,
        };
        this.paymentBatchService.assign(this.paymentBatch.ID, assignmentDetails).subscribe({
            next: (x) => this.onClose.emit(x),
            error: (error) => this.errorService.handle(error),
        });
    }

    gotoUserSettings(): void {
        this.router.navigateByUrl('/settings/users');
        this.onClose.emit();
    }

    gotoMarketplace() {
        this.router.navigateByUrl('/marketplace/modules');
        this.onClose.emit();
    }
}
