import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorService } from '@app/services/common/errorService';
import { StatisticsService } from '@app/services/common/statisticsService';
import { CompanySalaryService } from '@app/services/salary/companySalary/companySalaryService';
import { EmployeeLeaveService } from '@app/services/salary/employee-leave.service';
import { CompanySalary, Employee, EmployeeLeave, Employment, Leavetype } from '@uni-entities';
import { FieldType, UniFieldLayout } from '@uni-framework/ui/uniform';
import { IUniModal, IModalOptions, UniModalService, ConfirmActions } from '@uni-framework/uni-modal';
import { ToastService, ToastType } from '@uni-framework/uniToast/toastService';
import { rigDate } from '@app/components/common/utils/rig-date';
import { Observable, forkJoin, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { OverlappingLeaveModal } from '../overlapping-leave-modal/overlapping-leave-modal';

export enum LeaveEditModalCloseValue {
    NoChange = 0,
    Updated = 1,
    Deleted = 2,
}

@Component({
    selector: 'uni-employee-leave-modal',
    templateUrl: './employee-leave-modal.component.html',
    styleUrls: ['./employee-leave-modal.component.sass'],
})
export class EmployeeLeaveModalComponent implements IUniModal {
    @Input() public options: IModalOptions;
    @Output() public onClose: EventEmitter<LeaveEditModalCloseValue> = new EventEmitter<LeaveEditModalCloseValue>();

    employeeLeave: EmployeeLeave;
    employee: Employee;
    employeeLeaveFields: UniFieldLayout[] = [];
    employeeLeaveFieldsConfig: any;
    employments: Employment[] = [];
    isBusy: boolean = true;
    dirty: boolean;
    isHidden: boolean;
    hasError: boolean;
    infoObject;
    errorMsg = '';

    step = 1;

    selfReportIsLocked = false;
    isPermissionRegistration: boolean = false;
    showPercentage: boolean = false;

    constructor(
        private employeeLeaveService: EmployeeLeaveService,
        private statisticsService: StatisticsService,
        private errrorService: ErrorService,
        private modalService: UniModalService,
        private toast: ToastService,
        private router: Router,
        private companySalaryService: CompanySalaryService,
    ) {}

    ngOnInit(): void {
        this.employeeLeave = this.options?.data?.employeeLeave ?? new EmployeeLeave();
        this.employeeLeave.FromDate = this.employeeLeave.FromDate || new Date(rigDate().format('YYYY-MM-DD'));
        this.employee = this.options?.data?.employee || null;

        this.initEmployeeLeaveAndView();

        if (this.employee) {
            this.onEmployeeSelected(this.employee, this.employeeLeave.EmploymentID);
        }

        if (this.employeeLeave.LeaveType >= Leavetype.Vacation && !this.employeeLeave.ToDate) {
            this.employeeLeave.ToDate = this.employeeLeave.FromDate;
        }
    }

    initEmployeeLeaveAndView() {
        this.employeeLeave.LeavePercent = this.employeeLeave.LeavePercent ?? 100;
        this.employeeLeave.ID = this.employeeLeave.ID || 0;
        this.employeeLeave.LeaveType = this.employeeLeave.LeaveType || this.options.data?.leave?.type || 0;

        this.isPermissionRegistration =
            this.employeeLeave.LeaveType <= Leavetype.Compassionate_leave ||
            this.employeeLeave.LeaveType >= Leavetype.Educational_leave_not_by_law;

        this.infoObject = this.getInfoObject(this.employeeLeave.LeaveType);
    }

    setBusy(isBusy: boolean) {
        this.isBusy = isBusy;
    }

    goToEmployeeCard() {
        this.onClose.emit(LeaveEditModalCloseValue.NoChange);
        this.router.navigateByUrl('/salary/absence/employee-leave-overview/' + this.employee.ID);
    }

    onEmployeeSelected(employee, employmentID) {
        this.employee = employee;
        this.isBusy = true;
        this.selfReportIsLocked = false;

        forkJoin([
            this.statisticsService.GetAllUnwrapped(
                `model=Employment&select=JobName as JobName,Standard as Standard,ID as ID&filter=EmployeeID eq ${this.employee.ID}`,
            ),
            this.companySalaryService.getCompanySalary(),
        ]).subscribe(([employments, settings]) => {
            let selectedEmployment;
            this.employments = employments;
            if (employmentID) selectedEmployment = employments.find((employment) => employment.ID == employmentID);
            else selectedEmployment = employments.find((employment) => employment.Standard);

            if (!selectedEmployment) {
                this.close();
                return;
            }

            this.employeeLeave.Employment = selectedEmployment;
            this.employeeLeave.EmploymentID = selectedEmployment.ID;

            this.step = 2;

            if (
                this.employee?.DeniedSickDays &&
                rigDate(this.employee?.DeniedSickDays).isSameOrAfter(rigDate(new Date())) &&
                this.employeeLeave.LeaveType === Leavetype.Self_report
            ) {
                this.selfReportIsLocked = true;
            } else {
                this.setUniFieldLayout(settings);
            }
            this.isBusy = false;
        });
    }

    deleteLeave() {
        this.isBusy = true;
        const options: IModalOptions = {
            header: 'Bekreft sletting av fravær',
            message: `Er du sikker på at du vil slette dette fraværet på den ansatte ${this.employee['DisplayName'] || this.employee?.BusinessRelationInfo?.Name || ''}? Denne handlingen kan ikke angres.`,
        };
        this.modalService
            .confirm(options)
            .onClose.pipe(
                switchMap((response) => {
                    if (response !== ConfirmActions.ACCEPT) {
                        return of(false);
                    }
                    return this.employeeLeaveService.Remove(this.employeeLeave.ID);
                }),
            )
            .subscribe({
                next: (response) => {
                    if (response) {
                        this.toast.addToast(
                            'Fraværet ble slettet',
                            ToastType.info,
                            6,
                            `${this.employeeLeaveService.getLeaveType(this.employeeLeave.LeaveType).text} fra ${rigDate(this.employeeLeave.FromDate).format('DD.MM.YYYY')} til ${rigDate(this.employeeLeave.ToDate ?? this.employeeLeave.FromDate).format('DD.MM.YYYY')} er fjernet.`,
                        );
                        this.close(LeaveEditModalCloseValue.Deleted);
                    }
                },
            })
            .add(() => (this.isBusy = false));
    }

    close(needsUpdate: LeaveEditModalCloseValue = LeaveEditModalCloseValue.NoChange) {
        this.onClose.emit(needsUpdate);
    }

    updateDisabled(hasError: boolean) {
        this.hasError = hasError;
    }

    save() {
        this.errorMsg = '';

        if (
            !this.employeeLeave.ToDate &&
            (this.employeeLeave.LeaveType === Leavetype.Vacation ||
                this.employeeLeave.LeaveType === Leavetype.Self_report ||
                this.employeeLeave.LeaveType === Leavetype.Sick_child)
        ) {
            this.errorMsg = 'Denne fraværstypen krever en sluttdato';
            return;
        }
        if (this.employeeLeave.LeaveType === Leavetype.Leave) {
            return (this.errorMsg = 'Mangler Type');
        }

        this.isBusy = true;

        delete this.employeeLeave.Employment;

        const request$ = this.employeeLeave.ID
            ? this.employeeLeaveService.Put(this.employeeLeave.ID, this.employeeLeave)
            : this.employeeLeaveService.postWithLeaveError(this.employeeLeave);

        request$.subscribe({
            next: () => {
                this.isBusy = false;
                this.toast.addToast(
                    'Fravær lagret',
                    ToastType.good,
                    6,
                    `${this.employeeLeaveService.getLeaveType(this.employeeLeave.LeaveType).text} registrert på den ansatte fra ${rigDate(this.employeeLeave.FromDate).format('DD.MM.YYYY')} til ${this.employeeLeave.ToDate ? rigDate(this.employeeLeave.ToDate).format('DD.MM.YYYY') : 'ubestemt tid'}`,
                );
                this.close(LeaveEditModalCloseValue.Updated);
            },
            error: (err) => {
                if (err.error?.length && err.status === 400) {
                    this.employeeLeave.Employment = this.employments.find(
                        (emp) => emp.ID === this.employeeLeave.EmploymentID,
                    );
                    this.openOverlappingLeaveModal(err.error);
                } else {
                    this.isBusy = false;
                    this.errrorService.handle(err);
                }
            },
        });
    }

    goBack() {
        this.employee = null;
        this.employments = [];
        this.errorMsg = '';
        this.step = 1;
    }

    getShowPercentage() {
        return (
            this.employeeLeave.LeaveType !== Leavetype.Self_report &&
            this.employeeLeave.LeaveType !== Leavetype.Sick_child &&
            this.employeeLeave.LeaveType !== Leavetype.Vacation
        );
    }

    change() {
        this.dirty = true;
        this.employeeLeave = { ...this.employeeLeave };
    }

    private openOverlappingLeaveModal(employeeLeaveIDs: number[]): void {
        this.modalService
            .open(OverlappingLeaveModal, {
                closeOnClickOutside: false,
                data: {
                    overlappingLeavesIDs: employeeLeaveIDs,
                    currentEmployeeLeave: this.employeeLeave,
                    employee: this.employee,
                },
            })
            .onClose.subscribe({
                next: () => {
                    this.close(LeaveEditModalCloseValue.Updated);
                },
                error: (error) => {
                    this.errrorService.handle(error);
                    this.isBusy = false;
                },
            });
    }

    private setUniFieldLayout(settings: CompanySalary) {
        this.employeeLeaveFields = [
            <UniFieldLayout>{
                Property: 'LeaveType',
                Label: 'Type',
                FieldType: FieldType.DROPDOWN,
                Options: {
                    source: this.employeeLeaveService.getOnlyNewTypes(),
                    template: (type: { ID: number; text: string }) => `${type.text}`,
                    valueProperty: 'ID',
                },
                Hidden: !this.isPermissionRegistration,
            },

            <UniFieldLayout>{
                Property: 'FromDate',
                Label: 'Startdato',
                FieldType: FieldType.LOCAL_DATE_PICKER,
                Classes: 'half-width',
            },
            <UniFieldLayout>{
                Property: 'ToDate',
                Label: 'Sluttdato',
                FieldType: FieldType.LOCAL_DATE_PICKER,
                Classes: 'half-width',
            },
            <UniFieldLayout>{
                Property: 'EmploymentID',
                Label: 'Arbeidsforhold',
                FieldType: FieldType.AUTOCOMPLETE,
                Classes: 'half-width',
                Options: {
                    getDefaultData: () => this.getDefaultEmployment(),
                    search: (query: string) =>
                        this.getEmployments(
                            `employeeID eq ${this.employee.ID} and (contains(JobName,'${query}') or contains(JobCode,'${query}') )`,
                        ),
                    valueProperty: 'ID',
                    debounceTime: 200,
                    template: (employment: Employment) =>
                        employment ? `${employment?.ID ?? ''} - ${employment?.JobName ?? ''}` : '',
                },
                ReadOnly: this.employments?.length <= 1,
                Hidden:
                    this.employeeLeave.LeaveType === Leavetype.Self_report ||
                    this.employeeLeave.LeaveType === Leavetype.Sick_child ||
                    this.employeeLeave.LeaveType === Leavetype.Vacation,
            },
            <UniFieldLayout>{
                Property: 'LeavePercent',
                Label: 'Prosent',
                FieldType: FieldType.NUMERIC,
                Classes: 'half-width',
                ReadOnly: !this.getShowPercentage(),
                Hidden:
                    this.employeeLeave.LeaveType === Leavetype.Self_report ||
                    this.employeeLeave.LeaveType === Leavetype.Sick_child ||
                    this.employeeLeave.LeaveType === Leavetype.Vacation,
            },
            <UniFieldLayout>{
                Property: 'Description',
                Label: 'Kommentar',
                FieldType: FieldType.TEXTAREA,
            },
            ...(settings.OtpExportActive
                ? [
                      <UniFieldLayout>{
                          Property: 'AffectsOtp',
                          Label: 'Påvirker OTP',
                          FieldType: FieldType.CHECKBOX,
                          Hidden: !this.isPermissionRegistration,
                      },
                  ]
                : []),
        ];
    }

    getInfoObject(type) {
        switch (type) {
            case Leavetype.Compassionate_leave:
            case Leavetype.Educational_leave:
            case Leavetype.Military_service_leave:
            case Leavetype.Leave:
            case Leavetype.LayOff:
            case Leavetype.Leave_with_parental_benefit:
            case Leavetype.Compassionate_leave_not_by_law:
            case Leavetype.Compassionate_leave_by_law:
            case Leavetype.Educational_leave_not_by_law:
            case Leavetype.Educational_leave_by_law:
                return null;
            case Leavetype.Self_report:
                return {
                    title: 'Informasjon om egenmelding',
                    infotexts: [
                        {
                            text: 'Egenmelding betyr at du melder fra til arbeidsgiveren din om at du er syk, uten at du leverer sykmelding.',
                        },
                        {
                            text: 'Ifølge folketrygdloven kan du bruke egenmelding opptil tre kalenderdager om gangen og opptil fire ganger i løpet av ett år. Du kan se firmaets innstillinger for egenmeldinger',
                            link: {
                                url: '/settings/aga-and-subentities',
                                params: { index: 5 },
                                text: 'her',
                            },
                        },
                        {
                            text: 'Les mer om lover og regler',
                            link: {
                                text: 'på nav sine sider',
                                blank: 'https://www.nav.no/no/person/arbeid/sykmeldt-arbeidsavklaringspenger-og-yrkesskade/sykmelding-ulike-former/egenmelding#chapter-3',
                            },
                        },
                    ],
                };
            case Leavetype.Sick_leave_paid:
            case Leavetype.Sick_leave_unpaid:
                return {
                    title: 'Informasjon om sykemelding',
                    infotexts: [
                        {
                            text: 'Sykmelding kan en få av fastlegen eller en annen som har rett til å sykmelde deg hvis det er medisinske grunner til at du ikke kan jobbe.',
                        },
                        {
                            text: 'Sykepenger kan graderes ned til 20 prosent. Perioder med graderte sykepenger regnes med i den tiden en kan få sykepenger, som er maksimalt 52 uker.',
                        },
                        {
                            text: 'Avventende sykmelding er en beskjed til arbeidsgiveren om at sykmelding kan unngås hvis det blir lagt til rette på arbeidsplassen.',
                        },
                        {
                            text: ' Les mer om lover og regler',
                            link: {
                                text: 'på nav sine sider',
                                blank: 'https://www.nav.no/no/person/arbeid/sykmeldt-arbeidsavklaringspenger-og-yrkesskade',
                            },
                        },
                    ],
                };
            case Leavetype.Sick_child:
                return {
                    title: 'Informasjon om omsorgspenger',
                    infotexts: [
                        {
                            text: 'Hvor mange omsorgsdager arbeidstaker har krav på avhenger av samlivssituasjonen og antall barn. Arbeidstaker kan søke om ekstra dager hvis han/hun har barn som er kronisk sykt eller funksjonshemmet, eller den andre forelderen ikke kan ha tilsyn med barnet på grunn av for eksempel sykdom, fengselsopphold eller verneplikt.',
                        },
                        {
                            text: 'Arbeidstaker som har ett eller to barn som er 12 år eller yngre, har 10 omsorgsdager i året. Arbeidstaker som har tre eller flere barn som er 12 år eller yngre, har 15 omsorgsdager i året. Enslige forsørgere og foreldre til kronisk syke barn har et utvidet antall omsorgsdager.',
                            link: {
                                text: 'Les mer på altinn',
                                blank: 'https://www.altinn.no/starte-og-drive/arbeidsforhold/permisjoner-og-ferie/Rettigheter-syke-barn/',
                            },
                        },
                    ],
                };
        }
    }

    private getDefaultEmployment(): Observable<Employment[]> {
        return this.employeeLeave?.EmploymentID
            ? this.getEmployments(`ID eq ${this.employeeLeave?.EmploymentID}`)
            : this.getEmployments(`Standard eq 1 and employeeID eq ${this.options?.data?.employeeID}`).pipe(
                  tap((x) => (this.employeeLeave.EmploymentID = x[0]?.ID ?? null)),
              );
    }

    private getEmployments(filter: string): Observable<Employment[]> {
        return this.statisticsService.GetAllUnwrapped(
            'model=employment' + '&select=ID as ID,JobName as JobName,JobCode as JobCode' + `&filter=${filter}`,
        );
    }
}
