import { Component, Input, Output, EventEmitter, ViewChild, ViewContainerRef } from '@angular/core';
import { WidgetDefinition } from '../models';

@Component({
    selector: 'widget',
    template: `<ng-container #componentSlot></ng-container>`,
})
export class Widget {
    @ViewChild('componentSlot', { read: ViewContainerRef, static: true }) componentSlot: ViewContainerRef;

    @Input() widgetDefinition: WidgetDefinition;

    @Output() remove = new EventEmitter();

    constructor() {}

    ngOnChanges(changes) {
        if (changes['widgetDefinition'] && this.widgetDefinition) {
            this.loadWidget();
        }
    }

    loadWidget() {
        if (!this.componentSlot) {
            console.error('componentSlot not found in widget.ts');
            return;
        }

        const comp: any = this.componentSlot.createComponent(this.widgetDefinition.component());
        comp.instance.options = this.widgetDefinition.options;
        comp.instance.removeWidget?.subscribe({
            next: (removeWidget) => {
                if (removeWidget) {
                    this.remove.emit();
                }
            },
        });
    }
}
