<section role="dialog" class="uni-modal" [class]="{ size1: !isImporting, size2: isImporting }">
    <header>{{ header }}</header>

    <article>
        <section *ngIf="loading$ | async" class="modal-spinner">
            <mat-spinner></mat-spinner>
        </section>

        <div class="content" [class.hidden]="!isImporting">
            <custom-import-component
                #importComponent
                [definition]="importDefinition"
                (completed)="onCustomImportCompleted($event)"
            >
            </custom-import-component>
        </div>

        <div class="content" [class.hidden]="isImporting">
            <p>
                <strong>Klient: </strong>
                {{ authService?.activeCompany?.Name }}
            </p>

            <p *ngIf="options.data.hasTemplateAccess && options.data.showTemplateDownloadAndDisclaimerInfo">
                <strong>Last ned mal: </strong>
                <a href="{{ options.data.downloadTemplateUrl }}">
                    {{ options.data.downloadTemplateUrl.split('/').pop() }}
                </a>
            </p>

            <p>{{ options.data.conditionalStatement }}</p>
            <p>{{ options.data.formatStatement }}</p>

            <p *ngIf="options.data.showTemplateDownloadAndDisclaimerInfo">
                Vær vennlig og les gjennom
                <a (click)="openDisclaimerNote()"> våre vilkår </a>
                før du laster opp filen.
            </p>

            <!-- File upload -->
            <label class="uni-label upload-label">
                <span>Filopplasting</span>

                <file-input
                    accept=".xlsx, .txt, .csv"
                    [upload]="false"
                    [multiple]="false"
                    (filesChange)="onFilesChange($event)"
                >
                </file-input>
            </label>

            <section class="alert error" *ngIf="!isValidFileFormat">
                Vennligst legg til en gyldig fil (.xlsx eller .txt) for å importere
            </section>

            <!-- Order -->
            <label *ngIf="showOrderOptions" class="uni-label label-left">
                <span>Importer med status</span>
                <uni-select [config]="selectConfig" [items]="orderOptions" [(value)]="selectedOrderOption">
                </uni-select>
            </label>

            <!-- Invoice-->
            <label *ngIf="showInvoiceOptions" class="uni-label label-left">
                <span>Mva koder basert på</span>
                <uni-select [config]="selectConfig" [items]="invoiceOptions" [(value)]="selectedInvoiceOption">
                </uni-select>
            </label>

            <!-- Date format -->
            <label *ngIf="showDateFormatSelect" class="uni-label label-left">
                <span>Dato format</span>
                <uni-select [config]="selectConfig" [items]="dateFormats" [(value)]="selectedDateFormat"> </uni-select>
            </label>

            <!-- Payroll -->
            <ng-container *ngIf="showPayrollRunSelect && !payrollRunsLoading">
                <label *ngIf="payrollRuns?.length" class="uni-label label-left">
                    <span>Lønnsavregning</span>
                    <uni-select [config]="selectConfig" [items]="payrollRuns" [(value)]="selectedPayrollRun">
                    </uni-select>
                </label>

                <div class="missing-payroll" *ngIf="!payrollRuns?.length">
                    Det må opprettes en
                    <a [routerLink]="['/salary/payrollrun/']" (click)="onClose.emit()"> lønnsavregning </a>
                    før du kan importere variable lønnsposter.
                </div>
            </ng-container>

            <!-- Duplication handling -->
            <mat-radio-group *ngIf="duplicationHandlingOptions?.length" [(ngModel)]="duplicationHandling">
                <mat-radio-button *ngFor="let option of duplicationHandlingOptions" [value]="option.value">
                    {{ option.label }}
                </mat-radio-button>
            </mat-radio-group>
            <section class="alert info" *ngIf="overwriteStatement && duplicationHandling === importOptionOverride">
                <span> {{ overwriteStatement }} </span>
            </section>
        </div>
    </article>

    <footer>
        <button class="secondary" (click)="onCancel()">{{ cancelButtonLabel }}</button>
        <button class="c2a" (click)="onSubmit()" [disabled]="submitButtonDisabled">
            {{ submitLabel }}
        </button>
    </footer>
</section>
