<section role="dialog" class="uni-modal">
    <header>Legg til eller fjern widgeter</header>

    <article>
        <section class="widget-group" *ngFor="let group of widgetGroups">
            <h2>{{ group.header }}</h2>
            <section class="widget-list">
                <rig-checkbox *ngFor="let widget of group.widgets" [(ngModel)]="widget['_active']">
                    {{ widget.label | translate }}
                </rig-checkbox>
            </section>
        </section>

        <section class="widget-group" *ngIf="hasAccessToCompleteStartupTasks">
            <h2>Kom i gang</h2>
            <section class="widget-list">
                <rig-checkbox [(ngModel)]="showStartupTasks"> Oppstartsveiviser og snarveier </rig-checkbox>
            </section>
        </section>
    </article>

    <footer>
        <button (click)="onClose.emit()" class="secondary">Avbryt</button>
        <button (click)="submit()" class="c2a">Lagre</button>
    </footer>
</section>
