<section #toggle class="company-dropdown-toggle">
    <span class="company-name">{{ activeCompany?.Name }}</span>
    <span class="active-year">{{ isCreatingNewYear ? 'Oppretter nytt år...' : activeYear }}</span>
    <i class="material-icons">keyboard_arrow_down</i>
</section>

<dropdown-menu #dropdown [trigger]="toggle" [closeOnClick]="false">
    <ng-template>
        <section class="company-dropdown" (click)="$event.stopPropagation()">
            <uni-select
                *ngIf="availableCompanies"
                [config]="selectCompanyConfig"
                [items]="availableCompanies"
                [value]="activeCompany"
                (valueChange)="companySelected($event)"
            >
            </uni-select>

            <label class="navbar_company_taxyearselect" *ngIf="!lockYearSelector">
                <span>Regnskapsår</span>
                <uni-select
                    [config]="selectYearConfig"
                    [items]="selectYear"
                    [value]="activeYear"
                    (valueChange)="onYearDropdownChange($event)"
                >
                </uni-select>
            </label>

            <section *ngIf="companySettings?.OrganizationNumber" class="info-row">
                <span>Org.nr</span>
                <span>{{ companySettings.OrganizationNumber | uninumberformat: 'orgno' }}</span>
            </section>

            <section *ngIf="companySettings?.DefaultPhone?.Number" class="info-row">
                <span>Telefon</span>
                <span>{{ companySettings.DefaultPhone.Number }}</span>
            </section>
        </section>
    </ng-template>
</dropdown-menu>
