import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { theme, THEMES } from 'src/themes/theme';
import { ElsaCustomer } from '@app/models';
import { AuthService } from '@app/authService';
import { ErrorService } from '@app/services/common/errorService';
import { ElsaAgreementService } from '@app/services/elsa/elsaAgreementService';
import { ElsaContractService } from '@app/services/elsa/elsaContractService';

@Component({
    selector: 'contract-activation-wizard',
    templateUrl: './contract-activation-wizard.html',
    styleUrls: ['./contract-activation-wizard.sass'],
})
export class ContractActivationWizard {
    @Input() contractID: number;
    @Input() companyName: string;
    @Input() orgNumber: string;
    @Input() contractType: number;
    @Input() customer: ElsaCustomer;
    @Output() contractActivated = new EventEmitter();
    @Output() back = new EventEmitter();

    activationInProgress: boolean;

    termsAgreed = false;

    bankName: string;
    headerText = theme.theme === THEMES.SR ? 'Bestill SpareBank 1 Regnskap' : 'Kontaktinformasjon';
    submitButtonText = theme.theme === THEMES.SR ? 'Bestill' : 'Aktiver kundeforhold';
    licenseAgreementUrl: string;

    showTerms = true;

    customerDetailsForm = new UntypedFormGroup({
        ContactPerson: new UntypedFormControl('', Validators.required),
        ContactEmail: new UntypedFormControl('', Validators.required),
        ContactPhone: new UntypedFormControl('', Validators.required),
        // PersonalNumber, IsBankCustomer and BankUserID added in constructor
    });

    constructor(
        private errorService: ErrorService,
        private elsaContractService: ElsaContractService,
        private elsaAgreementService: ElsaAgreementService,
        private authService: AuthService,
    ) {
        this.elsaAgreementService.getCustomerAgreementByContractType(this.contractType).subscribe((agreement) => {
            this.licenseAgreementUrl = agreement?.DownloadUrl || '';
        });

        if (theme.theme === THEMES.SR) {
            this.customerDetailsForm.addControl('PersonalNumber', new UntypedFormControl('', Validators.required));
            this.customerDetailsForm.addControl('IsBankCustomer', new UntypedFormControl(false));
            this.bankName = this.authService.publicSettings?.BankName || 'SpareBank 1';
        } else if (theme.theme === THEMES.EXT02) {
            this.customerDetailsForm.addControl('BankUserID', new UntypedFormControl(''));

            if (window.location.hash.includes('init/register-company')) {
                // they have already agreed in sign-up
                this.showTerms = false;
                this.termsAgreed = true;
            }
        }
    }

    ngOnChanges(changes) {
        if (changes['contractID'] && this.customer) {
            this.customerDetailsForm.patchValue(this.customer);
        }
    }

    activateContract() {
        this.customerDetailsForm.markAllAsTouched();
        if (!this.customerDetailsForm.valid) {
            return;
        }

        this.activationInProgress = true;
        this.customerDetailsForm.disable();

        const customerDetails = this.customerDetailsForm.value;
        customerDetails.Name = this.companyName;
        customerDetails.OrgNumber = this.orgNumber;

        this.elsaContractService.activate(this.contractID, customerDetails, this.contractType).subscribe(
            () => {
                if (customerDetails?.IsBankCustomer === false && this.authService.publicSettings?.BankCustomerUrl) {
                    let url = this.authService.publicSettings.BankCustomerUrl;
                    if (this.orgNumber) {
                        url += `?bm-orgNumber=${this.orgNumber}`;
                    }
                    window.open(url, '_blank');
                }
                this.contractActivated.emit(customerDetails);
            },
            (err) => {
                this.errorService.handle(err);
                this.activationInProgress = false;
                this.customerDetailsForm.enable();
            },
        );
    }
}
