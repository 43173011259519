export enum COMPANY_CREATION_STEPS {
    CONTRACT_TYPE,
    COMPANY_STEP1,
    COMPANY_STEP2,
    CONTRACT_ACTIVATION,
    BANKID_STEP1,
    BANKID_STEP2,
    BANKID_STEP3,
    SB1_BANKID_STEP1,
    VALIDATION_FAILED,
}
