<uni-toolbar [config]="toolbarConfig" [saveactions]="saveActions"> </uni-toolbar>

<section class="application">
    <section class="splitview">
        <nav class="splitview_list" style="width: 30rem">
            <ag-grid-wrapper
                [config]="predefinedDescriptionTypeTableConfig"
                [resource]="predefinedDescriptionTypes"
                (rowSelectionChange)="onDescriptionTypeSelected($event)"
            >
            </ag-grid-wrapper>
        </nav>

        <main class="splitview_detail">
            <ag-grid-wrapper
                [config]="predefinedDescriptionsConfig"
                [(resource)]="predefinedDescriptions"
                (resourceChange)="setHasUnsavedChanges(true)"
            >
            </ag-grid-wrapper>
        </main>
    </section>
</section>
