<section>
    <a
        class="good"
        *ngIf="canPayButNotVerified"
        (click)="goToVerifyWithBankID()"
        title="Gå til side for å verifisere bruker med BankID"
    >
        Klikk her for å verifisere med BankID
    </a>

    <section
        class="outgoing-item-container gap-2 border rounded pointer mt-2"
        *ngFor="let item of VALUE_ITEMS"
        [ngClass]="{ checked: item.selected, disabled: item.disabled }"
        (click)="valueItemSelected(item)"
        [title]="item.disabled ? disabledTitle : ''"
    >
        <i class="material-icons">{{ item.selected ? 'radio_button_checked' : 'radio_button_unchecked' }}</i>

        <section class="outgoing-text-container">
            <strong>{{ item.label | translate }}</strong>
            <span>
                {{ item.infoText | translate }}
                {{ item.value === 1 && !!approveInBankText ? approveInBankText : '' }}
            </span>
        </section>
    </section>
</section>
