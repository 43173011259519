import { Component, Input } from '@angular/core';
import { EHFService } from '@app/services/common/EHFService';
import { CompanySettings } from '@uni-entities';
import { UniActivateAPModal, UniModalService } from '@uni-framework/uni-modal';
import { StartupTaskService, StartupTask } from '../startup-task-service';

@Component({
    selector: 'activate-ehf-task',
    template: `<startup-task [task]="task" (tileClicked)="runTask()"> </startup-task>`,
})
export class ActivateEHFTask {
    @Input()
    public task: StartupTask;

    @Input()
    public companySettings: CompanySettings;

    constructor(
        private service: StartupTaskService,
        private modalService: UniModalService,
        private ehfService: EHFService,
    ) {}

    ngOnChanges(change) {
        if (
            change['companySettings'] &&
            this.companySettings &&
            this.ehfService.isEHFIncomingActivated(this.companySettings) &&
            this.task &&
            !this.task.completed
        ) {
            this.service.markTaskAsCompleted(this.task);
        }
    }

    runTask() {
        this.modalService.open(UniActivateAPModal).onClose.subscribe((status) => {
            if (status !== 0) {
                this.service.markTaskAsCompleted(this.task);
            }
        });
    }
}
