import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { GuidService } from '@app/services/common/guidService';
import { ConfirmActions, IModalOptions, IUniModal } from '../../../../../framework/uni-modal/interfaces';

@Component({
    selector: 'contact-modal',
    templateUrl: './contact-modal.html',
    styleUrls: ['./contact-modal.sass'],
})
export class ContactModal implements IUniModal {
    @Input() options: IModalOptions = {};
    @Output() onClose = new EventEmitter();

    editMode: boolean;
    contact: any;
    missingName: boolean;
    invalidPhoneNumber: boolean;
    invalidEmail: boolean;

    constructor(private guidService: GuidService) {}

    ngOnInit() {
        const defaultRowModel = {
            Role: '',
            Info: {
                Name: '',
                ParentBusinessRelationID: null,
                DefaultEmail: { EmailAddress: null },
                DefaultPhone: { Number: null },
            },
        };
        this.editMode = this.options?.data?.editMode || false;
        this.contact = this.options?.data?.contact || defaultRowModel;
        this.contact.Info.DefaultPhone = this.contact.Info.DefaultPhone ?? { Number: '' };
        this.contact.Info.DefaultEmail = this.contact.Info.DefaultEmail ?? { EmailAddress: '' };
    }

    checkMissingFields() {
        this.missingName = !this.contact?.Info?.Name;

        return this.missingName;
    }

    validateEmail() {
        if (!this.contact?.Info?.DefaultEmail?.EmailAddress) {
            return;
        }

        let invalid = !this.contact?.Info?.DefaultEmail?.EmailAddress.match(
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
        );

        return invalid ? (this.invalidEmail = true) : (this.invalidEmail = false);
    }

    validatePhoneNumber() {
        if (!this.contact?.Info?.DefaultPhone?.Number) {
            this.invalidPhoneNumber = false;

            return this.invalidPhoneNumber;
        }

        let invalid = !this.contact?.Info?.DefaultPhone?.Number.match(/^([0-9\+]{1})([0-9]{7,14})$/);
        if (!invalid) {
            this.invalidPhoneNumber = false;
            this.checkMissingFields();

            return this.invalidPhoneNumber;
        } else {
            this.invalidPhoneNumber = invalid;

            return this.invalidPhoneNumber;
        }
    }

    hasInvalidFields() {
        this.checkMissingFields();
        this.validateEmail();
        this.validatePhoneNumber();

        return this.missingName || this.invalidEmail || this.invalidPhoneNumber;
    }

    preSave() {
        if (!this.contact.ID && !this.contact['_createguid']) {
            this.contact['_createguid'] = this.guidService.guid();
        }

        if (!this.contact.Info.ID && !this.contact.Info['_createguid']) {
            this.contact.Info['_createguid'] = this.guidService.guid();
        }

        const defaultEmail = this.contact.Info.DefaultEmail;
        if (defaultEmail && !defaultEmail.ID && !defaultEmail._createguid) {
            defaultEmail._createguid = this.guidService.guid();
        }

        const defaultPhone = this.contact.Info.DefaultPhone;
        if (defaultPhone && !defaultPhone.ID && !defaultPhone._createguid) {
            defaultPhone._createguid = this.guidService.guid();
        }

        this.contact['_isDirty'] = true;
    }

    confirm() {
        if (this.hasInvalidFields()) return;
        this.preSave();

        return this.onClose.emit({ action: ConfirmActions.ACCEPT, contact: this.contact });
    }

    cancel() {
        return this.onClose.emit({ action: ConfirmActions.CANCEL });
    }

    deleteContact() {
        this.contact.Deleted = true;
        this.contact['_isDirty'] = true;

        return this.onClose.emit({ action: ConfirmActions.ACCEPT, contact: this.contact });
    }
}
