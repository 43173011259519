<section *ngIf="showSurveyIcon" class="uni-survey-container" title="Spørreundersøkelse">
    <section class="survey-button-filler" (click)="hideShowSurvey(!showSurveyContainer)">
        <p>Tilbakemelding</p>
        <i class="material-icons-outlined"> sentiment_very_satisfied </i>
    </section>

    <section class="survey-questioner-container" *ngIf="showSurveyContainer">
        <uni-rating-survey (onClose)="closeSurvey($event)"></uni-rating-survey>
    </section>
</section>
