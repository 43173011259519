import { Component } from '@angular/core';

@Component({
    selector: 'team-and-association-widget',
    templateUrl: './team-and-association.html',
    styleUrls: ['./team-and-association.sass'],
})
export class TeamAndAssociationWidget {
    constructor() {}
}
