import { catchError, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ConfirmActions, UniModalService, ConfirmModal } from '@uni-framework/uni-modal';
import { SupplierInvoice, PaymentBatch } from '@uni-entities';
import { SupplierInvoiceService } from '@app/services/accounting/supplierInvoiceService';
import { ErrorService } from '@app/services/common/errorService';

export enum ActionOnReload {
    DoNothing = 0,
    JournaledAndSentToBank = 1,
    JournaledAndSentToPaymentList = 2,
    SentToBank = 3,
    SentToPaymentList = 4,
    FailedToJournal = 5,
    FailedToSendToBank = 6,
    FailedToSendToPaymentList = 7,
    ContinueWithTwoFactor = 8,
}

export class ActionContinueWithTwoFactor {
    paymentBatch: PaymentBatch;

    constructor(paymentBatch: PaymentBatch) {
        this.paymentBatch = paymentBatch;
    }
}

// HELPER CLASS FOR JOURNALING AND PAYMENT FUNCTIONS
@Injectable()
export class JournalAndPaymentHelper {
    constructor(
        private supplierInvoiceService: SupplierInvoiceService,
        private modalService: UniModalService,
        private errorSerivce: ErrorService,
    ) {}

    journal(current: SupplierInvoice, ask: boolean): Observable<any> {
        return this.openAskBeforeJournalModal(current, ask).pipe(
            switchMap((response) => {
                return response
                    ? this.supplierInvoiceService.journal(current.ID).pipe(
                          switchMap((res) => of(true)),
                          catchError((err) => {
                              this.errorSerivce.handle(err);
                              return of(false);
                          }),
                      )
                    : of(false);
            }),
            catchError(() => of(null)),
        );
    }

    openAskBeforeJournalModal(current: SupplierInvoice, ask?) {
        return !ask
            ? of(true)
            : this.modalService
                  .open(ConfirmModal, {
                      header: 'ACCOUNTING.SUPPLIER_INVOICE.BOOK_WITH_SUPPLIER_NAME~' + current.Supplier.Info.Name,
                      message:
                          'ACCOUNTING.SUPPLIER_INVOICE.BOOK_WITH_AMOUNT~' +
                          current.TaxInclusiveAmountCurrency.toFixed(2),
                      buttonLabels: {
                          accept: 'Bokfør',
                          cancel: 'Avbryt',
                      },
                  })
                  .onClose.pipe(switchMap((response) => of(response === ConfirmActions.ACCEPT)));
    }
}
