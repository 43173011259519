import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService } from '@app/authService';
import {
    TemplateType,
    ImportStatement,
    ImportJobName,
    ImportOption,
} from '@app/models/import-central/ImportDialogModel';
import { UniModalService } from '@uni-framework/uni-modal';
import { UserService } from '@app/services/common/userService';
import { DisclaimerModal } from './modals/disclaimer/disclaimer-modal';
import { ImportModal } from './modals/import/import-modal';
import { environment } from 'src/environments/environment';
import { theme, THEMES } from 'src/themes/theme';
import { ImportDefinition } from './customImportService';
import { FeaturePermissionService } from '@app/featurePermissionService';

export interface ImportOptions {
    jobName: string;
    entityType?: string;
    templateType: TemplateType;
    description?: string;
    importModel: {
        DateFormat: string;
        ImportOption?: ImportOption;
        OtherParams?: any;
    };
}

export interface ImportExportPermissions {
    hasAccess?: boolean;
    canImport?: boolean;
    canExport?: boolean;
    canExportWithData?: boolean;
}

const JOB_NAME_MAP = {
    [TemplateType.Product]: ImportJobName.Product,
    [TemplateType.Customer]: ImportJobName.Customer,
    [TemplateType.Supplier]: ImportJobName.Supplier,
    [TemplateType.MainLedger]: ImportJobName.MainLedger,
    [TemplateType.Payroll]: ImportJobName.Payroll,
    [TemplateType.RecurringPayroll]: ImportJobName.RecurringPayroll,
    [TemplateType.Voucher]: ImportJobName.Voucher,
    [TemplateType.Order]: ImportJobName.Order,
    [TemplateType.Invoice]: ImportJobName.Invoice,
    [TemplateType.Saft]: ImportJobName.Saft,
};

const TEMPLATE_FILE_NAME_MAP = {
    [TemplateType.Customer]: 'CustomerTemplateFinal.xlsx',
    [TemplateType.Supplier]: 'SupplierTemplateFinal.xlsx',
    [TemplateType.Product]: 'ProductTemplateFinal.xlsx',
    [TemplateType.MainLedger]: 'MainLedgerTemplate.xlsx',
    [TemplateType.Payroll]: 'PayrollTemplate.xlsx',
    [TemplateType.Voucher]: 'VoucherExcelTemplate.xlsx',
    [TemplateType.Order]: 'OrderTemplate.xlsx',
    [TemplateType.Invoice]: 'InvoiceTemplate.xlsx',
    [TemplateType.RecurringPayroll]: 'RecurringPayrollTemplate.xlsx',
};

@Injectable()
export class ImportService {
    private permissionMap: Map<TemplateType, ImportExportPermissions> = new Map();
    private hasFullAccess: boolean;

    constructor(
        private authService: AuthService,
        private http: HttpClient,
        private router: Router,
        private userService: UserService,
        private modalService: UniModalService,
        private permissionService: FeaturePermissionService,
    ) {
        this.authService.authentication$.subscribe((auth) => {
            this.permissionMap.clear();

            if (auth.user) {
                const uiPermissions: string[] = auth.user['Permissions'] || [];

                // Hack for our no roles == full access situation..
                this.hasFullAccess = !uiPermissions.length;

                if (uiPermissions.length) {
                    this.setPermissions(uiPermissions || []);
                }
            }
        });
    }

    hasAccess(templateType: TemplateType) {
        return this.hasFullAccess || this.permissionMap.get(templateType)?.hasAccess;
    }

    getPermissions(templateType: TemplateType): ImportExportPermissions {
        if (this.hasFullAccess) {
            return {
                hasAccess: true,
                canImport: true,
                canExport: true,
                canExportWithData: true,
            };
        }

        return this.permissionMap.get(templateType) || {};
    }

    getJobName(templateType: TemplateType) {
        return JOB_NAME_MAP[templateType];
    }

    getTemplateUrl(templateType: TemplateType) {
        return `${environment.PUBLIC_FILES_URL}/files/import/${TEMPLATE_FILE_NAME_MAP[templateType]}`;
    }

    getTemplateFileName(templateType: TemplateType) {
        return TEMPLATE_FILE_NAME_MAP[templateType];
    }

    getTemplateWithData(templateType: TemplateType) {
        let url = '';
        if (templateType === TemplateType.RecurringPayroll) {
            url = `/api/biz/salarytrans?action=import-salary-recurringtransactions-template`;
        } else {
            url = `/api/import-central/download-with-data?entity=${templateType}`;
        }
        return this.http.get(url, { responseType: 'blob' });
    }

    checkDisclaimerAgreement(): Observable<boolean> {
        return this.userService.getCurrentUser().pipe(
            switchMap((user) => {
                if (user.HasAgreedToImportDisclaimer) {
                    return of(true);
                } else {
                    return this.modalService.open(DisclaimerModal).onClose;
                }
            }),
        );
    }

    showImportModal(
        templateType: TemplateType,
        showTemplateDownloadAndDisclaimerInfo = false,
        optionalCustomImportDefinition?: ImportDefinition,
    ) {
        this.checkDisclaimerAgreement().subscribe((hasAgreed) => {
            if (hasAgreed) {
                if (templateType === TemplateType.Saft) this.router.navigate(['admin', 'saf-t']);
                else
                    this.openImportModal(
                        templateType,
                        showTemplateDownloadAndDisclaimerInfo,
                        optionalCustomImportDefinition,
                    );
            }
        });
    }

    private openImportModal(
        templateType: TemplateType,
        showTemplateDownloadAndDisclaimerInfo: boolean,
        optionalCustomImportDefinition?: ImportDefinition,
    ) {
        let conditionalStatement = '';
        let formatStatement = '';

        const isUeOrSoftrig = theme.theme === THEMES.UE || theme.theme === THEMES.SOFTRIG;

        if (isUeOrSoftrig && templateType === TemplateType.Product) {
            formatStatement = ImportStatement.ProductFormatStatement;
        }

        if (isUeOrSoftrig && templateType === TemplateType.Customer) {
            formatStatement = ImportStatement.CustomerFormatStatement;
        }

        if (isUeOrSoftrig && templateType === TemplateType.Supplier) {
            formatStatement = ImportStatement.SupplierFormatStatement;
        }

        if (templateType === TemplateType.MainLedger) {
            formatStatement = isUeOrSoftrig ? ImportStatement.MainLedgerFormatStatement : '';
            conditionalStatement = ImportStatement.MainLedgerConditionalStatement;
        }

        if (templateType === TemplateType.Order) {
            conditionalStatement = ImportStatement.OrderConditionalStatement;
            formatStatement = isUeOrSoftrig ? ImportStatement.OrderFormatStatement : '';
        }

        if (templateType === TemplateType.Invoice) {
            conditionalStatement = ImportStatement.InvoiceConditionalStatement;
            formatStatement = isUeOrSoftrig ? ImportStatement.InvoiceFormatStatement : '';
        }

        this.modalService.open(ImportModal, {
            data: {
                jobName: this.getJobName(templateType),
                templateType: templateType,
                conditionalStatement: conditionalStatement,
                formatStatement: formatStatement,
                downloadTemplateUrl: this.getTemplateUrl(templateType),
                showTemplateDownloadAndDisclaimerInfo,
                definition: optionalCustomImportDefinition,
            },
        });
    }

    private setPermissions(uiPermissions: string[]) {
        uiPermissions.forEach((permission) => {
            if (permission.includes('ui_import') && this.permissionService.canShowRoute(permission)) {
                if (permission.includes('customer')) {
                    this.setPermission(TemplateType.Customer, permission);
                } else if (permission.includes('product')) {
                    this.setPermission(TemplateType.Product, permission);
                } else if (permission.includes('supplier')) {
                    this.setPermission(TemplateType.Supplier, permission);
                } else if (permission.includes('ledger')) {
                    this.setPermission(TemplateType.MainLedger, permission);
                } else if (permission.includes('recurringpayroll')) {
                    this.setPermission(TemplateType.RecurringPayroll, permission);
                } else if (permission.includes('payroll')) {
                    this.setPermission(TemplateType.Payroll, permission);
                } else if (permission.includes('employee')) {
                    this.setPermission(TemplateType.Employee, permission);
                } else if (permission.includes('voucher')) {
                    this.setPermission(TemplateType.Voucher, permission);
                } else if (permission.includes('order')) {
                    this.setPermission(TemplateType.Order, permission);
                } else if (permission.includes('invoice')) {
                    this.setPermission(TemplateType.Invoice, permission);
                } else if (permission.includes('saf-t')) {
                    this.setSaftPermission(permission);
                }
            }
        });
    }

    private setPermission(templateType: TemplateType, uiPermission: string) {
        const permission = this.permissionMap.get(templateType) || {};
        permission.hasAccess = true;
        permission.canImport = true;

        if (uiPermission.includes('template')) {
            permission.canExport = true;
        }

        if (uiPermission.includes('data')) {
            permission.canExportWithData = true;
        }

        this.permissionMap.set(templateType, permission);
    }

    private setSaftPermission(uiPermission) {
        const permission = this.permissionMap.get(TemplateType.Saft) || {};
        if (uiPermission.includes('saf-t-import')) {
            permission.hasAccess = true;
            permission.canImport = true;
        } else if (uiPermission.includes('saf-t-export')) {
            permission.hasAccess = true;
            permission.canExport = true;
        }

        this.permissionMap.set(TemplateType.Saft, permission);
    }
    private setEmployeePermission(uiPermission) {
        const permission = this.permissionMap.get(TemplateType.Employee) || {};
        permission.hasAccess = true;
        permission.canImport = false;

        this.permissionMap.set(TemplateType.Employee, permission);
    }
}
