import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ConfirmActions, IModalOptions, IUniModal } from '@uni-framework/uni-modal/interfaces';

@Component({
    selector: 'reset-altinn-account-link-modal',
    templateUrl: './reset-altinn-account-link-modal.html',
    styleUrls: ['./reset-altinn-account-link-modal.sass'],
})
export class ResetAltinnAccountLinkModal implements IUniModal {
    @Input()
    options: IModalOptions = {};

    @Output()
    onClose = new EventEmitter();

    updateOnlyMissing: boolean = false;

    constructor() {}

    ngOnInit() {}

    confirm() {
        this.onClose.emit({ action: ConfirmActions.ACCEPT, updateOnlyMissing: this.updateOnlyMissing });
    }

    close() {
        this.onClose.emit({ action: ConfirmActions.CANCEL });
    }
}
