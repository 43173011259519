import { Component, EventEmitter } from '@angular/core';
import { IModalOptions, IUniModal } from '@uni-framework/uni-modal/interfaces';
import { ReportDefinition } from '@uni-entities';

@Component({
    selector: 'select-reports-modal',
    templateUrl: './select-reports-modal.html',
})
export class SelectReportsModal implements IUniModal {
    options: IModalOptions = {};
    onClose = new EventEmitter();

    reportGroups: { name: string; reports: ReportDefinition[] }[];
    selectedReports: ReportDefinition[];

    ngOnInit() {
        const data = this.options.data || {};
        this.reportGroups = data.reportGroups || [];
        const selectedReports = data.selectedReports || [];

        this.reportGroups.forEach((group) => {
            group.reports = group.reports.map((report) => {
                report['_selected'] = selectedReports.some((r) => r.ID === report.ID);
                return report;
            });
        });
    }

    submit() {
        const selectedReports = [];
        this.reportGroups.forEach((group) => {
            group.reports.forEach((report) => {
                if (report['_selected']) {
                    selectedReports.push(report);
                }
            });
        });

        this.onClose.emit(selectedReports);
    }
}
