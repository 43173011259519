import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, ViewChild } from '@angular/core';
import { NotificationService } from '../notification-service';
import { Notification, NotificationStatus } from '@uni-entities';
import { AuthService } from '@app/authService';
import { UserNotificationSettingsModal } from '../user-notification-settings/user-notification-settings-modal';
import { ConfirmActions, IModalOptions, ConfirmModal, UniModalService } from '@uni-framework/uni-modal';
import { DropdownMenu } from '@uni-framework/ui/dropdown-menu/dropdown-menu';
import { finalize } from 'rxjs/operators';

interface NotificationTypeFilter {
    label: string;
    icon?: string;
    entityType?: string;
    filterText?: string;
    checked?: boolean;
}

const SKIP_AMOUNT = 50;

@Component({
    selector: 'notifications-dropdown',
    templateUrl: './notifications-dropdown.html',
    styleUrls: ['./notifications-dropdown.sass'],
    host: { class: 'uni-redesign' },
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsDropdown {
    @ViewChild(DropdownMenu) filterMenu;

    close: EventEmitter<any> = new EventEmitter();
    busy: boolean = false;
    loadingMoreBusy: boolean = false;

    typeFilters: NotificationTypeFilter[] = [
        {
            label: 'Alle varsler',
        },
        {
            label: 'Godkjenninger',
            entityType: 'Approval',
            filterText: "EntityType eq 'Approval'",
        },
        {
            label: 'Fakturainnboks',
            entityType: 'File',
            filterText: "SourceEntityType eq 'File'",
        },
        {
            label: 'Samlefakturering',
            entityType: 'BatchInvoice',
            filterText: "SourceEntityType eq 'BatchInvoice'",
        },
        {
            label: 'Kommentarer',
            entityType: 'Comment',
            filterText: "SourceEntityType eq 'Comment'",
        },
        {
            label: 'Påminnelser',
            entityType: 'CustomerInvoiceReminder',
            filterText: "SourceEntityType eq 'CustomerInvoiceReminder'",
        },
        {
            label: 'Betalinger',
            entityType: 'Payment',
            filterText: "SourceEntityType eq 'PaymentBatch' or SourceEntityType eq 'BankIntegrationAgreement'",
        },
    ];

    currentCompanyOnly: boolean = true;
    viewUnreadOnly: boolean = false;
    selectedFilters: NotificationTypeFilter[] = [];

    items: any[] = [];
    readItems: any[] = [];
    unreadItems: any[] = [];
    hasNewSection: boolean;
    skipNumber: number = 0;

    constructor(
        public authService: AuthService,
        public notificationService: NotificationService,
        private modalService: UniModalService,
        private changeDetectorRef: ChangeDetectorRef,
    ) {
        try {
            this.currentCompanyOnly = JSON.parse(sessionStorage.getItem('notifications_current_company_only')) ?? false;
            this.viewUnreadOnly = JSON.parse(sessionStorage.getItem('notifications_view_unread_only'));
            const filterEntities = sessionStorage.getItem('notifications_filter_entities')?.split(';');
            this.selectedFilters =
                filterEntities &&
                this.typeFilters.filter((f) => filterEntities.findIndex((fe) => f.entityType === fe) !== -1);
            this.selectedFilters.map((f) => (f.checked = true));
        } catch (e) {}

        if (!this.selectedFilters?.length) {
            this.selectedFilters = [];
            this.typeFilters[0].checked = true;
        }

        this.notificationService.hasUnreadNotifications$.next(false);
        this.getNotifications();
    }

    getNotifications() {
        this.busy = true;
        this.changeDetectorRef.markForCheck();

        const filterString = this.buildFilter();
        this.notificationService
            .getNotifications(filterString, this.skipNumber, SKIP_AMOUNT)
            .pipe(
                finalize(() => {
                    this.busy = false;
                    this.notificationService.markNotificationsRead();
                    this.changeDetectorRef.markForCheck();
                }),
            )
            .subscribe({
                next: (res) => {
                    this.splitItemsIntoRead(res);
                },
                error: (err) => console.error(err),
            });
    }

    handleNotificationChange(notification) {
        this.items.find((n) => n.ID === notification.ID).StatusCode = notification.StatusCode;
    }

    buildFilter() {
        const filters = [];

        if (this.currentCompanyOnly) {
            filters.push(`CompanyKey eq '${this.authService.getCompanyKey()}'`);
        }

        if (this.selectedFilters?.length) {
            const filterString = this.selectedFilters.map((f) => f.filterText).join(' or ');
            filters.push(`(${filterString})`);
        }

        if (this.viewUnreadOnly) {
            filters.push(`StatusCode eq null or StatusCode ne ${NotificationStatus.Read}`);
        }
        return '' + filters.join(' and ');
    }

    splitItemsIntoRead(items: any[] = null, extend: boolean = false) {
        if (extend) {
            this.items = [...this.items, ...items];
        } else if (items !== null) {
            // From markallasread, any other will return an array
            this.items = [...items];
        }

        this.unreadItems = this.items.filter((notification) => notification['_unread']);
        this.readItems = this.items.filter((notification) => !notification['_unread']);

        this.skipNumber += SKIP_AMOUNT;
    }

    setCurrentCompanyOnly(currentCompanyOnly: boolean) {
        this.currentCompanyOnly = currentCompanyOnly;
        this.saveFilters();
        this.getNotifications();
    }

    viewUnreadOnlyChange() {
        this.saveFilters();
        this.getNotifications();
    }

    toggleFilterChecked(filter) {
        if (!filter.entityType) {
            this.selectedFilters = [];
            this.typeFilters.map((f) => (f.checked = false));
            this.typeFilters[0].checked = true;
            this.filterMenu.hide();
            this.changeDetectorRef.markForCheck();

            return;
        }

        filter.checked = !filter.checked;
        this.typeFilters[0].checked = false;
        this.selectedFilters = this.typeFilters.filter((f) => f.checked);
        this.changeDetectorRef.markForCheck();
    }

    onFilterSaveClick() {
        this.filterMenu.hide();
    }

    saveAndGetNewNotifications() {
        this.saveFilters();
        this.getNotifications();
    }

    addMoreNotifications() {
        if (this.items.length !== this.skipNumber) {
            return;
        }

        this.loadingMoreBusy = true;
        this.changeDetectorRef.markForCheck();

        const filterString = this.buildFilter();
        this.notificationService
            .getNotifications(filterString, this.skipNumber, SKIP_AMOUNT)
            .pipe(
                finalize(() => {
                    this.loadingMoreBusy = false;
                    this.changeDetectorRef.markForCheck();
                }),
            )
            .subscribe({
                next: (res) => {
                    this.splitItemsIntoRead(res, true);
                },
                error: (err) => console.error(err),
            });
    }

    markAllAsRead() {
        this.busy = true;
        this.changeDetectorRef.markForCheck();

        this.notificationService
            .markAllAsRead(this.currentCompanyOnly)
            .pipe(
                finalize(() => {
                    this.busy = false;
                    this.splitItemsIntoRead();
                    this.changeDetectorRef.markForCheck();
                }),
            )
            .subscribe({
                next: (readNotifs: Notification[]) => {
                    const ids = new Set(readNotifs.map((n) => n.ID));

                    if (!this.items) return;

                    this.items = this.items.map((notification) => {
                        if (ids.has(notification.ID)) {
                            notification = {
                                ...notification,
                                StatusCode: NotificationStatus.Read,
                            };
                        }

                        return notification;
                    });
                },
            });
    }

    openSettingsModal() {
        this.close.emit(null);
        this.modalService.open(UserNotificationSettingsModal);
    }

    private saveFilters() {
        this.skipNumber = 0;

        sessionStorage.setItem('notifications_view_unread_only', JSON.stringify(this.viewUnreadOnly));
        sessionStorage.setItem('notifications_current_company_only', JSON.stringify(this.currentCompanyOnly));
        if (this.selectedFilters.length > 0) {
            sessionStorage.setItem(
                'notifications_filter_entities',
                this.selectedFilters.map((f) => f.entityType).join(';'),
            );
        } else {
            sessionStorage.removeItem('notifications_filter_entities');
        }
    }

    onNotificationClick(notification: Notification, event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        const element = event.target as Element;

        if (element.tagName.toLowerCase() === 'a') {
            //if element (inside the notification message) is an <a> tag, show redirect confirmation popup
            const redirectUri = element.getAttribute('href');
            this.openRedirectConfirmationModal(redirectUri);
        } else {
            //otherwise emit the event emitter and call "onNotificationClick" (Default behaviour)
            this.close.emit();
            this.notificationService.onNotificationClick(notification);
        }
    }

    openRedirectConfirmationModal(url: string) {
        this.modalService
            .confirm({
                message: `Du er i ferd med å gå til <br /> <i><b>${url}</b></i> <br /> <br /> Ønsker du å fortsette?`,
                buttonLabels: { accept: 'Ja', cancel: 'Nei' },
            })
            .onClose.subscribe((response) => {
                if (response === ConfirmActions.ACCEPT) {
                    window.open(url, '_blank').focus();
                }
            });
    }
}
