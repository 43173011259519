import { Component, EventEmitter } from '@angular/core';
import { IUniModal, IModalOptions, UniModalService } from '@uni-framework/uni-modal';
import { PaymentBatch, PaymentBatchApproveStatus, StatusCodePaymentBatch } from '@uni-entities';
import { ToastService, ToastType } from '@uni-framework/uniToast/toastService';
import { catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { RequestMethod } from '@uni-framework/core/http';
import { PaymentBatchApprovalModalComponent } from '../payment-batch-approval-modal/payment-batch-approval-modal.component';
import { AuthService } from '@app/authService';
import { PaymentBatchService } from '@app/services/accounting/paymentBatchService';
import { PaymentService } from '@app/services/accounting/paymentService';

@Component({
    templateUrl: './bankid-payment-modal.html',
    styleUrls: ['./bankid-payment-modal.sass'],
})
export class BankIDPaymentModal implements IUniModal {
    options: IModalOptions = {};
    onClose = new EventEmitter();

    busy: boolean = true;
    batchID: number;
    paymentBatch: PaymentBatch;
    hashValue: string = '';
    header = 'Betaling til bank';
    message = 'Henter informasjon...';
    icon = '';
    failed = false;
    bankIDError = false;
    error: false;
    aborted: false;
    isFromPaymentList: true; //When true we only revert the payment batch and do not delete the payment in case of an error. This is the safest option

    constructor(
        private paymentBatchService: PaymentBatchService,
        private toastService: ToastService,
        private paymentService: PaymentService,
        private modalService: UniModalService,
        private authService: AuthService,
    ) {}

    ngOnInit() {
        this.batchID = this.options.data?.batchID;
        this.hashValue = this.options.data?.hashValue;
        this.error = this.options.data?.error;
        this.aborted = this.options.data?.aborted;
        this.bankIDError = this.error || this.aborted;
        this.isFromPaymentList = this.options.data?.isFromPaymentList ?? false;
        const expand = this.isFromPaymentList ? null : ['payments'];
        this.paymentBatchService.Get(this.batchID, expand).subscribe(
            (batch: PaymentBatch) => {
                if (this.bankIDError) {
                    this.header = `BankID identifisering ${this.aborted ? 'ble avbrutt' : 'feilet'}`;
                    this.paymentBatch = batch;
                    this.message = '';
                    this.busy = false;
                    this.icon = 'error';
                    this.failed = true;
                } else if (batch.StatusCode <= 45002) {
                    this.message =
                        `Sender ${batch.NumberOfPayments} betaling til banken...` +
                        (batch.NumberOfPayments > 100 ? ' Dette kan ta litt tid, vennligst vent.' : '');
                    this.sendBatchToPayment();
                } else {
                    this.close(false);
                }
            },
            (err) => {
                this.close(false);
            },
        );
    }

    sendBatchToPayment() {
        this.paymentBatchService.sendToPayment(this.batchID, { HashValue: this.hashValue }).subscribe(
            (approvalStatus: PaymentBatchApproveStatus) => {
                if (
                    approvalStatus?.ApprovalsRemaining === 0 ||
                    approvalStatus?.StatusCode === StatusCodePaymentBatch.Approved
                ) {
                    this.message = 'Betaling sendt til bank.';
                    this.icon = 'check_circle';
                } else if (approvalStatus.Success === false) {
                    this.message =
                        'Betaling ble opprettet, men du mangler godkjenningsrettigheter i bank for å sende betalinger. Vennligst dobbelsjekk i nettbanken eller ta kontakt med support';
                    this.icon = 'error';
                    this.failed = true;
                } else {
                    this.paymentBatchService.Get(this.batchID).subscribe((batch) => {
                        this.modalService
                            .open(PaymentBatchApprovalModalComponent, {
                                data: {
                                    paymentBatch: batch,
                                    approvalStatus: approvalStatus,
                                    userID: this.authService.currentUser.ID,
                                },
                            })
                            .onClose.subscribe((response) => {
                                this.header = '';
                                if (response) {
                                    this.message = 'Betalingen(e) ble tildelt for godkjenning.';
                                    this.icon = 'check_circle';
                                } else {
                                    this.message = 'Betalingen(e) ble ikke tildelt en godkjenner.';
                                    this.icon = 'error';
                                }
                            });
                    });
                }

                this.busy = false;
            },
            () => {
                this.message =
                    'Betaling ble opprettet, men noe gikk galt da vi skulle sende filen til bank. Vennligst dobbelsjekk i nettbanken.';
                this.busy = false;
                this.icon = 'error';
                this.failed = true;
            },
        );
    }

    revertPaymentBatch() {
        this.busy = true;
        this.paymentBatchService
            .revertPaymentBatch(this.paymentBatch.ID, this.isFromPaymentList)
            .pipe(
                switchMap(() => {
                    if (!this.isFromPaymentList && this.paymentBatch.Payments?.length) {
                        return this.paymentService.ActionWithBody(
                            null,
                            this.paymentBatch.Payments.map((payment) => payment.ID),
                            'batch-delete-and-credit',
                            RequestMethod.Put,
                            'credit=false',
                        );
                    }
                    return of(true);
                }),
                catchError((err) => {
                    // Could not delete the payment that was created..
                    this.toastService.addToast(
                        'Startet betaling ble ikke slettet',
                        ToastType.bad,
                        0,
                        'Noe gikk galt da vi prøvde å slette betalingen du har startet på. For å slette den, gå til Bank - Utbetalinger',
                    );
                    return of(true);
                }),
            )
            .subscribe(
                () => {
                    this.close(true);
                    this.toastService.addToast(
                        this.isFromPaymentList
                            ? 'Tilbakestillingen av betalingsbunt er fullført'
                            : 'Betaling blir kansellert',
                        ToastType.info,
                        3,
                    );
                },
                (err) => {
                    this.toastService.addToast(
                        'Klarte ikke gjenopprette',
                        ToastType.bad,
                        0,
                        'Noe gikk galt da vi prøvde å gjenopprette betalingen som ikke gikk til banken. Vennligst gå til Utbetalingsbunter og velg "Legg tilbake til ubetalt" på linjenivå',
                    );
                    this.close(true);
                },
            );
    }

    resendPaymentBatch() {
        this.paymentBatchService.redirectToBankID(this.paymentBatch);
    }

    close(value) {
        this.onClose.emit(value);
    }

    canDeactivate() {
        this.revertPaymentBatch();
        return false;
    }
}
