import { Component } from '@angular/core';
import { FeaturePermissionService } from '@app/featurePermissionService';
import { CompanySettingsService } from '@app/services/common/companySettingsService';
import { CompanySettings } from '@uni-entities';
import { BrowserStorageService } from '@uni-framework/core/browserStorageService';
import { ConfirmActions, UniModalService } from '@uni-framework/uni-modal';
import { theme, THEMES } from 'src/themes/theme';
import { StartupTaskService, HAS_ADDED_STARTUPTASKS_MANUALLY } from './startup-task-service';

@Component({
    selector: 'startup-tasks',
    templateUrl: './startup-task-container.html',
    styleUrls: ['startup-task-container.sass'],
})
export class StartupTaskContainer {
    appName = theme.appName;
    companySettings: CompanySettings;
    isDNB = theme.theme === THEMES.EXT02;

    constructor(
        private companySettingsService: CompanySettingsService,
        public service: StartupTaskService,
        private modalService: UniModalService,
        private browserStorageService: BrowserStorageService,
        private permissionService: FeaturePermissionService,
    ) {}

    ngOnInit() {
        this.companySettingsService.getCompanySettings(['BankAccounts']).subscribe({
            next: (cs) => {
                // Hide for companies created before 5. may 2022 by default. This is a quick fix, might need a value on CompanySettings
                if (
                    new Date(cs.CreatedAt).toISOString() < new Date('2022-05-04').toISOString() &&
                    !this.browserStorageService.getItemFromCompany(HAS_ADDED_STARTUPTASKS_MANUALLY)
                ) {
                    this.service.setAndStoreViewMode(3);
                } else {
                    this.companySettings = cs;
                    this.service.init();
                }
            },
        });
    }

    hide() {
        const message = this.permissionService.canShowUiFeature('ui.dashboard.edit')
            ? 'Er du sikker på at du vil skjule veiviseren? Den kan hentes frem igjen via tannhjulet øverst på dashbord og "Legg til eller fjern elementer".'
            : 'Er du sikker på at du vil skjule veiviseren? Du vil ikke kunne hente den frem igjen senere.';

        this.modalService
            .confirm({
                header: 'Skjul veiviser',
                message,
                buttonLabels: {
                    accept: 'Ja, skjul veiviser',
                    cancel: 'Avbryt',
                },
            })
            .onClose.subscribe({
                next: (response) => {
                    if (response === ConfirmActions.ACCEPT) {
                        this.service.setAndStoreViewMode(3);
                    }
                },
            });
    }
}
