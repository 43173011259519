import { Component, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { DashboardDataService } from '../../../dashboard-data.service';
import { rigDate } from '@app/components/common/utils/rig-date';
import { Chart } from 'chart.js';
import { CurrencyService } from '@app/services/common/currencyService';

@Component({
    selector: 'currency-widget',
    templateUrl: './currency-widget.html',
    styleUrls: ['./currency-widget.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrencyWidget {
    @ViewChild('canvas', { static: false })
    private canvas: ElementRef<HTMLCanvasElement>;

    chartConfig;

    loading = true;
    hasData = false;
    showChart = true;
    data = [];
    chartData = [];

    currentCurrencyID: number = 2;
    currencyCodes = [];

    chartRef: Chart;
    dateLabel = '';
    dateLabelOld = '';

    selectedCodes = [
        { ID: 2, Name: 'USD' },
        { ID: 3, Name: 'GBP' },
        { ID: 4, Name: 'EUR' },
        { ID: 5, Name: 'SEK' },
        { ID: 6, Name: 'DKK' },
        { ID: 11, Name: 'CNY' },
    ];

    constructor(
        private cdr: ChangeDetectorRef,
        private currencyService: CurrencyService,
        private dataService: DashboardDataService,
    ) {
        this.currentCurrencyID = parseInt(localStorage.getItem('currencyWidgetLastID'), 10) || this.currentCurrencyID;
    }

    ngOnInit() {
        this.currencyService.getAllExchangeRates(1, new Date()).subscribe(
            (currencyCodes) => {
                this.hasData = true;
                this.currencyCodes = currencyCodes.sort((a, b) =>
                    a.FromCurrencyCode.ID > b.FromCurrencyCode.ID ? 1 : -1,
                );
                const referenceObject = currencyCodes[0];
                this.dateLabel = rigDate(referenceObject.RateDate).format('DD MMM');
                this.dateLabelOld = rigDate(referenceObject.RateDateOld).format('DD MMM');
                this.getData();
            },
            (err) => {
                this.hasData = false;
            },
        );
    }

    getData() {
        this.dataService.get(this.getEndpoint(), false).subscribe(
            (currentRates) => {
                this.data = currentRates.Data;
                this.chartData = this.data.map((cr) => (cr.ExchangeRate / (cr.Factor || 1)).toFixed(4));
                this.chartConfig = this.getChartConfig();
                this.loading = false;

                this.chartRef?.destroy();

                setTimeout(() => {
                    if (this.canvas) {
                        this.chartRef = new Chart(this.canvas.nativeElement, this.chartConfig);
                    }
                }, 200);
                this.cdr.markForCheck();
            },
            (err) => (this.showChart = false),
        );
    }

    reloadChart() {
        this.chartRef?.destroy();
        setTimeout(() => {
            this.chartRef = new Chart(this.canvas.nativeElement, this.chartConfig);
        }, 200);
        this.cdr.markForCheck();
    }

    showCode(code) {
        this.currentCurrencyID = code.ID;
        localStorage.setItem('currencyWidgetLastID', code.ID + '');
        this.getData();
    }

    getChartConfig() {
        return {
            type: 'line',
            data: {
                labels: this.data.map((d) => rigDate(d.CurrencyDate).format('DD MMMM YYYY')),
                datasets: [
                    {
                        label: this.data[0].Name + ` (${this.data[0].Code})`,
                        data: this.chartData,
                        borderColor: '#3e95cd',
                        backgroundColor: '#E8F1F9',
                        fill: true,
                        borderWidth: 1,
                    },
                ],
            },
            options: {
                elements: {
                    point: {
                        radius: 1,
                    },
                },
                plugins: {
                    legend: { display: false },
                    title: {
                        display: true,
                        position: 'bottom',
                        font: {
                            weight: 'bold',
                            size: 13,
                            lineHeight: 1.4,
                        },
                        text: [
                            this.data[0].Name + ` (${this.data[0].Code})`,
                            'Nyeste kurs (' +
                                rigDate(this.data[this.data.length - 1].CurrencyDate).format('DD MMMM') +
                                ') - ' +
                                this.chartData[this.chartData.length - 1],
                        ],
                    },
                },
                scales: {
                    x: {
                        grid: {
                            display: false,
                            drawBorder: false,
                        },
                        ticks: {
                            display: false,
                        },
                    },
                    y: {
                        grid: { borderDash: [4, 4] },
                        ticks: {
                            maxTicksLimit: 8,
                        },
                    },
                },
            },
        };
    }

    getEndpoint() {
        const date = rigDate(new Date()).subtract(60, 'day').toISOString();
        return (
            `/api/statistics?model=Currency&select=CurrencyDate as CurrencyDate,ExchangeRate as ExchangeRate,FromCurrencyCodeID as FromCurrencyCodeID,FromCurrencyCode.Name as Name,` +
            `FromCurrencyCode.Code as Code,Factor as Factor` +
            `&expand=FromCurrencyCode` +
            `&filter=FromCurrencyCodeID eq ${this.currentCurrencyID} and CurrencyDate gt '${date}'` +
            `&orderby=CurrencyDate`
        );
    }
}
