import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CustomerInvoice } from '@uni-entities';
import { theme } from 'src/themes/theme';
import { dougnutTextPlugin } from '@app/chartjs-setup';
import { NumberFormat } from '@app/services/common/numberFormatService';
import { CustomerInvoiceService } from '@app/services/sales/customerInvoiceService';

@Component({
    selector: 'unpaid',
    templateUrl: './unpaid.html',
    styleUrls: ['./unpaid.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnpaidWidget {
    dataSubscription: Subscription;

    loading = true;
    hasData = false;
    viewMode: 'overview' | 'invoices' = 'overview';

    overviewLoaded = false;
    invoicesLoaded = false;

    overviewData = [];
    totalUnpaid: number;
    chartConfig;
    invoices: CustomerInvoice[];

    constructor(
        private router: Router,
        private cdr: ChangeDetectorRef,
        private numberFormatService: NumberFormat,
        private invoiceService: CustomerInvoiceService,
    ) {}

    ngOnInit() {
        this.initOverview();
    }

    ngOnDestroy() {
        this.dataSubscription?.unsubscribe();
    }

    onViewModeChange() {
        if (this.viewMode === 'overview' && !this.overviewLoaded) {
            this.initOverview();
        }

        if (this.viewMode === 'invoices' && !this.invoicesLoaded) {
            this.loadInvoices();
        }
    }

    private initOverview() {
        this.dataSubscription?.unsubscribe();
        this.dataSubscription = this.invoiceService.getUnpaidWidgetOverviewData().subscribe((res) => {
            this.hasData = res && !!(res.notOverdue || res.thirtyDays || res.sixtyDays || res.overSixtyDays);

            if (this.hasData) {
                const colors = theme.widgets.unpaidWidgetColors;

                this.overviewData = [
                    { label: 'Over 60 dager', value: res.overSixtyDays || 0 },
                    { label: '31-60 dager', value: res.sixtyDays || 0 },
                    { label: '1-30 dager', value: res.thirtyDays || 0 },
                    { label: 'Ikke forfalt', value: res.notOverdue || 0 },
                ].map((item: any, index) => {
                    item.chartColor = colors[index].chartColor;
                    item.backgroundColor = colors[index].backgroundColor;
                    item.textColor = colors[index].textColor;

                    const borderColor = colors[index].borderColor;
                    item.border = borderColor ? `1px solid ${borderColor}` : 'none';

                    return item;
                });

                this.totalUnpaid = this.overviewData.reduce((sum, item) => (sum += item.value || 0), 0);
                this.chartConfig = this.getChartConfig();

                this.overviewLoaded = true;
            }

            this.loading = false;
            this.cdr.markForCheck();
        });
    }

    private loadInvoices() {
        this.dataSubscription?.unsubscribe();
        this.dataSubscription = this.invoiceService.getUnpaidWidgetOverdueInvoices().subscribe((invoices) => {
            this.invoices = invoices;
            this.invoicesLoaded = true;
            this.cdr.markForCheck();
        });
    }

    onInvoiceClick(invoice) {
        this.router.navigateByUrl('/sales/invoices/' + invoice.ID);
    }

    private getChartConfig() {
        return {
            type: 'pie',
            data: {
                datasets: [
                    {
                        data: this.overviewData.map((item) => item.value),
                        backgroundColor: this.overviewData.map((item) => item.chartColor),
                        label: '',
                        borderColor: '#fff',
                        hoverBorderColor: '#fff',
                    },
                ],
                labels: this.overviewData.map((item) => item.label),
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                cutout: 90,
                animation: { animateScale: true },
                elements: { arc: { borderWidth: 8 } },
                plugins: {
                    legend: { display: false },
                    tooltip: { enabled: false },
                    doughnutText: {
                        header: 'Sum utestående',
                        text: this.numberFormatService.asMoney(this.totalUnpaid),
                    },
                },
            },
            plugins: [dougnutTextPlugin],
        };
    }
}
