import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProductService } from '@app/services/common/productService';
import { SupplierGoods } from '@uni-entities';
import { AutocompleteOptions } from '@uni-framework/ui/autocomplete/autocomplete';
import { Subject, timer } from 'rxjs';

@Component({
    selector: 'goods-list',
    templateUrl: './goodsList.html',
    styleUrls: ['./goodsList.sass'],
})
export class GoodsList {
    @Input() public goods: SupplierGoods[];
    @Input() public productReadOnly: boolean = false;
    @Input() public highlightedGood: Subject<SupplierGoods> = new Subject<SupplierGoods>();

    @Input() public isNewGoodsNewProduct: boolean = false;
    @Input() public isNewGoodsWithProduct: boolean = false;
    @Input() public isExistingGoods: boolean = false;

    @Output() public goodChanged: EventEmitter<SupplierGoods> = new EventEmitter<SupplierGoods>(true);
    @Output() public pushtoNewProduct: EventEmitter<SupplierGoods> = new EventEmitter<SupplierGoods>(true);
    @Output() public pushToExistingProduct: EventEmitter<SupplierGoods> = new EventEmitter<SupplierGoods>(true);

    autocompleteOptions: AutocompleteOptions;

    highlight: string = '';

    constructor(private productService: ProductService) {}

    ngOnInit(): void {
        const config = this.productService.getAutocompleteConfig();
        this.autocompleteOptions = {
            clearInputOnSelect: false,
            lookup: config.search,
            displayFunction: config.template,
        };

        this.highlightedGood.subscribe((good) => {
            const goodFromList = this.goods.find((x) => x.GoodsNumber === good.GoodsNumber);
            if (goodFromList) {
                this.highlight = good.GoodsNumber;
                timer(800).subscribe(() => {
                    this.highlight = '';
                });
            }
        });
    }

    onProductChange(good) {
        this.goodChanged.emit(good);
    }

    toExistingProduct(good) {
        this.pushToExistingProduct.emit(good);
    }

    toNewProduct(good) {
        this.pushtoNewProduct.emit(good);
    }
}
