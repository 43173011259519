import { Component, EventEmitter } from '@angular/core';
import { ErrorService } from '@app/services/common/errorService';
import { StatisticsService } from '@app/services/common/statisticsService';
import { EmployeeLeaveService } from '@app/services/salary/employee-leave.service';
import { EmployeeLeave, Leavetype } from '@uni-entities';
import { UniTableColumn, UniTableColumnType, UniTableConfig } from '@uni-framework/ui/unitable';
import { IModalOptions, IUniModal, UniModalService } from '@uni-framework/uni-modal';
import { ToastService, ToastType } from '@uni-framework/uniToast/toastService';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { EmployeeLeaveImportModalComponent } from '../employee-leave-import-modal/employee-leave-import-modal.component';
import { FinancialYearService } from '@app/services/accounting/financialYearService';

@Component({
    selector: 'mass-registration-modal',
    templateUrl: './mass-registration-modal.html',
    styleUrls: ['./mass-registration-modal.sass'],
})
export class MassLeaveRegistationModal implements IUniModal {
    options: IModalOptions = {};
    onClose = new EventEmitter<Boolean>();

    employeeLeaveArray: any[] = [];
    tableConfig: UniTableConfig;
    busy = false;
    showWarning = false;
    errors: any[] = [];

    line: number;

    constructor(
        private employeeLeaveService: EmployeeLeaveService,
        private uniModalService: UniModalService,
        private statisticsService: StatisticsService,
        private errorService: ErrorService,
        private toast: ToastService,
        private activeFinancialYear: FinancialYearService,
    ) {}

    ngOnInit() {
        this.setUpRegistrationTable();
    }

    save() {
        const filteredSaveArray = this.employeeLeaveArray.filter((leave) => !leave._isEmpty);

        // Leavetype.Leave has expired in 2020
        if (
            this.activeFinancialYear.getActiveFinancialYear().Year >= 2020 &&
            filteredSaveArray.some((x) => x.LeaveType === Leavetype.Leave)
        ) {
            this.toast.addToast(`Du har prøvd å bruke en type permisjon som er utgått, velg en annen.`);
            return;
        }
        this.busy = true;
        this.employeeLeaveService.saveMultipleLeave(filteredSaveArray).subscribe({
            next: (response) => {
                if (response) {
                    this.errors = response.map((res) => {
                        this.employeeLeaveArray[res.InputIndex]._hasError = true;
                        res.text = this.getErrorText(res.ErrorType);
                        return res;
                    });

                    this.employeeLeaveArray = [...this.employeeLeaveArray];
                    this.showWarning = true;
                    this.busy = false;
                } else {
                    this.toast.addToast(
                        'Fravær opprettet',
                        ToastType.good,
                        6,
                        `${filteredSaveArray.length} linjer med fravær opprettet på ansatte`,
                    );
                    this.onClose.emit(true);
                }
            },
            error: (error) => {
                this.errorService.handle(error);
                this.busy = false;
            },
        });
    }

    handleChange() {
        this.employeeLeaveArray = [...this.employeeLeaveArray];
    }

    setUpRegistrationTable() {
        this.tableConfig = new UniTableConfig('register_employee_absence_table', true, false)
            .setDeleteButton(true)
            .setDefaultRowData({
                ID: 0,
                LeavePercent: 100,
                _hasError: false,
            })
            .setColumns([
                new UniTableColumn('_line', 'Linje', UniTableColumnType.Number)
                    .setEditable(false)
                    .setAlignment('left')
                    .setTemplate((row) => row._originalIndex + 1),
                new UniTableColumn('Employee', 'Ansatt', UniTableColumnType.Lookup, true)
                    .setTemplate((row) =>
                        row?.Employee ? row.Employee?.EmployeeNumber + '. ' + row.Employee?.Name : '',
                    )
                    .setOptions({
                        itemTemplate: (selectedItem) =>
                            selectedItem && `${selectedItem.EmployeeNumber}. ${selectedItem.Name}`,
                        lookupFunction: (query: string) =>
                            this.statisticsService.GetAllUnwrapped(
                                `model=Employment&select=Employment.ID as ID,EmployeeID as EmployeeID,EmployeeNumber as EmployeeNumber,JobName as JobName,BusinessRelationInfo.Name as Name&filter=Standard eq 'true' and ( startswith(EmployeeNumber, '${query}') or contains(BusinessRelationInfo.Name, '${query}') )&top=50&expand=Employee.BusinessRelationInfo`,
                            ),
                    }),

                new UniTableColumn('Type', 'Type fravær', UniTableColumnType.Select)
                    .setOptions({
                        itemTemplate: (option) => option.text,
                        resource: this.employeeLeaveService.getAllTypesForRegistration(),
                    })
                    .setTemplate((row) => row?.Type?.text || ''),

                new UniTableColumn('Employment', 'Arbeidsforhold', UniTableColumnType.Lookup, true)
                    .setTemplate((row) => row.Employment?.JobName || '')
                    .setOptions({
                        itemTemplate: (selectedItem) => selectedItem?.JobName || '',
                        lookupFunction: (query: string, row) => {
                            if (!row?.Employee?.EmployeeID) {
                                return of([]);
                            } else {
                                return this.statisticsService.GetAllUnwrapped(
                                    `model=Employment&select=ID as ID,EmployeeID as EmployeeID,JobName as JobName&filter=EmployeeID eq ${row.Employee.EmployeeID} and contains(JobName, '${query}')&top=50`,
                                );
                            }
                        },
                    }),
                new UniTableColumn('FromDate', 'Fra', UniTableColumnType.LocalDate),
                new UniTableColumn('ToDate', 'Til', UniTableColumnType.LocalDate),
                new UniTableColumn('LeavePercent', 'Prosent', UniTableColumnType.Number).setEditable(
                    (row) => row.LeaveType !== Leavetype.Sick_child && row.LeaveType !== Leavetype.Self_report,
                ),
                new UniTableColumn('Description', 'Beskrivelse', UniTableColumnType.Text),
            ])
            .setConditionalRowCls((row) => (row._hasError ? 'warning-background' : ''))
            .setChangeCallback((change) => {
                const row: EmployeeLeave = change.rowModel;

                if (change?.field === 'Type') {
                    row.LeaveType = change.newValue?.ID || 0;

                    if (row.LeaveType === Leavetype.Sick_child || row.LeaveType === Leavetype.Self_report) {
                        row.LeavePercent = 100;
                    }
                }

                if (change.field === 'Employee') {
                    row.EmploymentID = change.newValue?.ID || 0;
                    row.Employment = <any>{
                        ID: change.newValue?.ID || 0,
                        JobName: change.newValue?.JobName || '',
                    };
                }
                return row;
            });
    }

    import() {
        this.uniModalService
            .open(EmployeeLeaveImportModalComponent)
            .onClose.pipe(
                map((leaves) => {
                    if (leaves) {
                        leaves.forEach((leave) => {
                            //Match format used by table employeeLeaveArray
                            if (leave.Employment?.Employee) {
                                leave['Employee'] = {
                                    EmployeeID: leave.Employment.EmployeeID,
                                    EmployeeNumber: leave.Employment.EmployeeNumber,
                                    JobName: leave.Employment.JobName,
                                    Name: leave.Employment.Employee?.BusinessRelationInfo?.Name,
                                };
                            }
                            leave['Type'] = {
                                ID: leave.LeaveType,
                                text: this.employeeLeaveService
                                    .getAllTypesForRegistration()
                                    .find((t) => t.ID == leave.LeaveType)?.text,
                            };
                        });
                        return leaves;
                    }
                    return null;
                }),
            )
            .subscribe({
                next: (result) => {
                    if (result) {
                        this.employeeLeaveArray = result;
                    }
                },
                error: (err) => this.errorService.handle(err),
            });
    }

    private getErrorText(errorEnum: number): string {
        switch (errorEnum) {
            case 0:
                return 'Det finnes allerede registrert fravær på denne dagen';
            case 1:
                return 'Dagene for fraværstypen er brukt opp';
            case 2:
                return 'Det finnes andre fravær på arbeidsforholdet i importen på samme dag';
            case 3:
                return 'Finner ikke fraværstypen';
            default:
                return 'Validering feilet';
        }
    }
}
