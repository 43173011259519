import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { take, filter } from 'rxjs/operators';
export interface IUniEntityEditorFocus {
    layoutTitle: string;
    fieldProperty: string;
}
@Injectable()
export class UniEntityEditorFocusService {
    private focus$: BehaviorSubject<IUniEntityEditorFocus> = new BehaviorSubject(null);
    constructor() {}

    public setFocus(event: IUniEntityEditorFocus) {
        this.focus$.next(event);
    }

    public getFocus(): Observable<IUniEntityEditorFocus> {
        return this.focus$.pipe(take(1));
    }

    public clearFocus(layoutTitles: string[]) {
        this.getFocus()
            .pipe(filter((focus) => layoutTitles.some((title) => title === focus.layoutTitle)))
            .subscribe(() => this.setFocus(null));
    }
}
