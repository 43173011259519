import { forkJoin, from, Observable, of } from 'rxjs';

import { map, catchError, switchMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CompanySettings, Travel, TypeOfIntegration, WageDeductionDueToHolidayType, WageType } from '@uni-entities';
import { UniHttp } from '@uni-framework/core/http';
import { ToastService, ToastType } from '@uni-framework/uniToast/toastService';
import { ApiKeyService } from '../common/apikeyService';
import { StatisticsService } from '../common/statisticsService';
import { CompanySettingsService } from '../common/companySettingsService';
import { AuthService } from '@app/authService';

@Injectable({ providedIn: 'root' })
export class SalaryService {
    cachedQuery = {};
    endpoint = 'wagetypes';

    constructor(
        protected http: UniHttp,
        private apiKeyService: ApiKeyService,
        private statisticsService: StatisticsService,
        private _toastService: ToastService,
        private companySettingsService: CompanySettingsService,
        public authService: AuthService,
    ) {}

    hasValidSalarySettings(): Observable<boolean> {
        if (this.authService.activeCompany.IsTemplate) {
            return of(true);
        } else {
            return forkJoin([
                this.companySettingsService.getCompanySettings().pipe(
                    switchMap((settings: CompanySettings) => {
                        if (settings.CompanyTypeID === 30) {
                            return of(true);
                        } else {
                            return this.statisticsService
                                .GetAllUnwrapped(
                                    `model=SubEntity&select=ID as ID,OrgNumber as OrgNumber,SuperiorOrganizationID as SuperiorOrganizationID`,
                                )
                                .pipe(map((data: any[]) => data.length > 1));
                        }
                    }),
                ),
                this.statisticsService
                    .GetAllUnwrapped(
                        `model=companysettings&select=TaxBankAccountID as TaxBankAccountID,CompanyBankAccountID as CompanyBankAccountID`,
                    )
                    .pipe(map((data: any[]) => !!data[0].CompanyBankAccountID && !!data[0].TaxBankAccountID)),
                this.statisticsService
                    .GetAllUnwrapped('model=CompanyVacationRate')
                    .pipe(map((data: any[]) => !!data.length)),
                this.statisticsService
                    .GetAllUnwrapped(`model=WageType&select=count(ID) as count`)
                    .pipe(map((data: any[]) => !!data[0]?.count)),
            ]).pipe(
                switchMap(([hasSubEntities, hasSalaryAccounts, hasVacationRates, hasWageTypes]) =>
                    of(hasSubEntities && hasSalaryAccounts && hasVacationRates && hasWageTypes),
                ),
            );
        }
    }

    travelTextImport(): Observable<Travel[]> {
        return this.apiKeyService.getApiKey(TypeOfIntegration.TravelAndExpenses).pipe(
            switchMap((apiKey) =>
                this.http
                    .asPOST()
                    .usingBusinessDomain()
                    .withEndPoint(`travels?action=traveltext&apikeyID=${apiKey.ID}`)
                    .send()
                    .pipe(map((response) => response.body)),
            ),
            catchError((err) => {
                console.error(err);
                return of([]);
            }),
        );
    }

    getAllWageTypes(): Observable<WageType[]> {
        const cache = this.cachedQuery[this.endpoint];
        if (cache) {
            return from([cache]);
        }

        return this.http
            .asGET()
            .usingBusinessDomain()
            .withEndPoint(this.endpoint)
            .send()
            .pipe(map((res) => res.body))
            .pipe(
                tap((res) => {
                    this.cachedQuery[this.endpoint] = res;
                }),
            );
    }

    informAboutChangedAgaZone(year: number, period: number) {
        const reminded = localStorage.getItem('changedagazone_notificaton');
        if ((!reminded || reminded === 'false') && year === 2022 && period <= 3) {
            this.statisticsService
                .GetAllUnwrapped(
                    `model=SubEntity&select=OrgNumber as Orgnumber,MunicipalityNo as MunicipalityNo` +
                        `&filter=(MunicipalityNo in (1151,1144) and AgaZone ne 2) or (MunicipalityNo in (5054,1528,1545,4629,1252,4224,1026) and AgaZone ne 3) or (MunicipalityNo in (5013,5014) and AgaZone ne 4) or (MunicipalityNo in (4212,0912,1160,1515,1141) and AgaZone ne 1) or (MunicipalityNo in (5041) and AgaZone ne 5)`,
                )
                .subscribe((subentities) => {
                    if (subentities.length > 0) {
                        let subEntityText = 'Virksomhet ';
                        subentities.forEach((subentity) => {
                            subEntityText += `${subentity.Orgnumber} i kommune ${subentity.MunicipalityNo}, `;
                        });
                        const toastAction = [
                            {
                                label: 'Ikke minn meg igjen',
                                click: () => {
                                    localStorage.setItem('changedagazone_notificaton', 'true');
                                },
                            },
                        ];

                        this._toastService.addToast(
                            'Arbeidsgiveravgiftsone er endret for kommune',
                            ToastType.info,
                            20,
                            subEntityText +
                                ' har skiftet arbeidsgiveravgiftsone i 2022. Vennligst kontroller før innsending av A-melding',
                            toastAction,
                        );
                    }
                });
        }
    }
}
