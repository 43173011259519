<section role="dialog" class="uni-modal">
    <header>{{ headerText }}</header>

    <article *ngIf="!returnedFromAuth">
        <section class="text-center">
            Vi må kontrollere at du har rettigheter på selskapet. Logg inn med BankID for å fullføre verifiseringen.
        </section>

        <section class="flex-column align-center mt-4">
            <svg width="117" height="23" viewBox="0 0 140 22" fill="white" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M7.5 3.5H1.5C0.7 3.5 0 2.8 0 2C0 1.2 0.7 0.5 1.5 0.5H7.5C8.3 0.5 9 1.2 9 2C9 2.8 8.3 3.5 7.5 3.5Z"
                    fill="#4C1C7B"
                />
                <path
                    d="M7.5 15.5H1.5C0.7 15.5 0 14.8 0 14C0 13.2 0.7 12.5 1.5 12.5H7.5C8.3 12.5 9 13.2 9 14C9 14.8 8.3 15.5 7.5 15.5Z"
                    fill="#4C1C7B"
                />
                <path
                    d="M7.5 21.5H1.5C0.7 21.5 0 20.8 0 20C0 19.2 0.7 18.5 1.5 18.5H7.5C8.3 18.5 9 19.2 9 20C9 20.8 8.3 21.5 7.5 21.5Z"
                    fill="#4C1C7B"
                />
                <path
                    d="M19.5 9.5H13.5C12.7 9.5 12 8.8 12 8C12 7.2 12.7 6.5 13.5 6.5H19.5C20.3 6.5 21 7.2 21 8C21 8.8 20.3 9.5 19.5 9.5Z"
                    fill="#4C1C7B"
                />
                <path
                    d="M19.5 15.5H13.5C12.7 15.5 12 14.8 12 14C12 13.2 12.7 12.5 13.5 12.5H19.5C20.3 12.5 21 13.2 21 14C21 14.8 20.3 15.5 19.5 15.5Z"
                    fill="#4C1C7B"
                />
                <path
                    d="M31.5 3.5H25.5C24.7 3.5 24 2.8 24 2C24 1.2 24.7 0.5 25.5 0.5H31.5C32.3 0.5 33 1.2 33 2C33 2.8 32.3 3.5 31.5 3.5Z"
                    fill="#4C1C7B"
                />
                <path
                    d="M31.5 9.5H25.5C24.7 9.5 24 8.8 24 8C24 7.2 24.7 6.5 25.5 6.5H31.5C32.3 6.5 33 7.2 33 8C33 8.8 32.3 9.5 31.5 9.5Z"
                    fill="#4C1C7B"
                />
                <path
                    d="M31.5 21.5H25.5C24.7 21.5 24 20.8 24 20C24 19.2 24.7 18.5 25.5 18.5H31.5C32.3 18.5 33 19.2 33 20C33 20.8 32.3 21.5 31.5 21.5Z"
                    fill="#4C1C7B"
                />
                <path
                    d="M45.8 13.6C45.8 16.8 47.6 18.5 49.7 18.5C51.6 18.5 53.7 16.9 53.7 13.6C53.7 10.3 51.7 8.8 49.8 8.8C47.7 8.8 45.8 10.3 45.8 13.6ZM45.8 0.5V8.3C46.7 6.9 48.3 5.5 51 5.5C54 5.5 57.6 8 57.6 13.5C57.6 19.2 54.2 22 50.7 22C48.7 22 47 21.1 45.9 19.3V21.5H42V0.5H45.8Z"
                    fill="#4C1C7B"
                />
                <path
                    d="M63.3 13.7C63.3 17 65.3 18.5 67.2 18.5C69.3 18.5 71.1 17.1 71.1 13.7C71.1 10.4 69.3 8.80002 67.2 8.80002C65.4 8.80002 63.3 10.3 63.3 13.7ZM59.5 13.5C59.5 8.10002 63 5.40002 66.5 5.40002C68.4 5.40002 70 6.10002 71.2 7.80002V5.80002H75V21.5H71.2V19.3C70.1 20.8 68.5 21.9 66 21.9C63 21.9 59.5 19.3 59.5 13.5Z"
                    fill="#4C1C7B"
                />
                <path
                    d="M82.5002 12.6V21.5H78.7002V5.9H82.5002V8.2C83.6002 6.2 85.6002 5.5 87.3002 5.5C90.0002 5.5 92.6002 6.9 92.6002 11.1V21.5H88.8002V12.5C88.8002 9.9 87.5002 8.9 85.8002 8.9C83.9002 9 82.5002 10.1 82.5002 12.6Z"
                    fill="#4C1C7B"
                />
                <path
                    d="M96.2002 0.5V21.5H100V17.7L102.1 15.1L106.4 21.5H110.6L104.4 12.4L109.8 5.9H105.5L100 13.1V0.5H96.2002Z"
                    fill="#4C1C7B"
                />
                <path d="M118.2 1.20001H114.3V21.5H118.2V1.20001Z" fill="#4C1C7B" />
                <path
                    d="M129.5 1.20001H122.1V21.5H129.5C135 21.5 139.6 18.2 139.6 11.3C139.6 4.50001 135 1.20001 129.5 1.20001ZM129.5 17.9H126V4.80001H129.5C132.7 4.80001 135.4 6.90001 135.4 11.4C135.4 15.8 132.7 17.9 129.5 17.9Z"
                    fill="#4C1C7B"
                />
            </svg>

            <button class="c2a mt-2" (click)="validate()">Verifiser med BankID</button>
        </section>
    </article>

    <article *ngIf="returnedFromAuth">
        <section *ngIf="busy" class="modal-spinner">
            <mat-spinner class="c2a"></mat-spinner>
        </section>

        <section class="flex-column align-center" *ngIf="!busy">
            <uni-illustration [name]="illustration" />

            <section class="text-center">
                {{ returnedText }}
            </section>
        </section>
    </article>

    <footer>
        <button class="secondary" (click)="close()" *ngIf="!busy">
            {{ returnedFromAuth ? 'Lukk' : 'Avbryt' }}
        </button>
    </footer>
</section>
