import { Injectable } from '@angular/core';
import { BizHttp } from '../../../../framework/core/http/BizHttp';
import { UniHttp } from '../../../../framework/core/http/http';
import { CompanyVacationRate, WageDeductionDueToHolidayType } from '../../../unientities';
import { Observable } from 'rxjs';

export interface IVacationPaySettings {
    vacationWeeks?: number;
    rate: number;
    rate60: number;
    wageDeductionDueToHoliday: WageDeductionDueToHolidayType;
}

@Injectable({ providedIn: 'root' })
export class CompanyVacationRateService extends BizHttp<CompanyVacationRate> {
    constructor(http: UniHttp) {
        super(http);
        this.relativeURL = CompanyVacationRate.RelativeUrl;
        this.entityType = CompanyVacationRate.EntityType;
    }

    public getCurrentRates(year: number = null): Observable<CompanyVacationRate> {
        return super.GetAction(null, 'current', year ? `year=${year}` : '');
    }

    public setVacationWeekValues(vacationPaySettings: IVacationPaySettings, vacationWeeks: number) {
        if (vacationWeeks == 5) {
            vacationPaySettings.rate = 12;
            vacationPaySettings.rate60 = 2.3;
            vacationPaySettings.wageDeductionDueToHoliday = WageDeductionDueToHolidayType.Deduct4PartsOf26;
        } else if (vacationWeeks == 4) {
            vacationPaySettings.rate = 10.2;
            vacationPaySettings.rate60 = 2.3;
            vacationPaySettings.wageDeductionDueToHoliday = WageDeductionDueToHolidayType.Add1PartOf26;
        }
    }
}
