import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { DashboardDataService } from '../../../dashboard-data.service';
import { AuthService } from '@app/authService';
import { of, forkJoin, Observable, Subject } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { Project } from '@uni-entities';
import { Router } from '@angular/router';
import { ProjectService } from '@app/services/common/projectService';

@Component({
    selector: 'project-cost-widget',
    templateUrl: './project-cost-widget.html',
    styleUrls: ['./project-cost-widget.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectCostWidget {
    currentProject: Project;
    onDestroy$ = new Subject();

    values = {
        costProducts: 0,
        costOthers: 0,
        costSalary: 0,
        costTotal: 0,
        financeCost: 0,
    };

    constructor(
        private cdr: ChangeDetectorRef,
        private dataService: DashboardDataService,
        private authService: AuthService,
        private projectService: ProjectService,
        private router: Router,
    ) {}

    ngOnInit() {
        this.projectService.currentProject.pipe(takeUntil(this.onDestroy$)).subscribe((project) => {
            if (project) {
                this.currentProject = project;
                this.loadData();
            }
            this.cdr.markForCheck();
        });
    }

    resetValues() {
        this.values = {
            costProducts: 0,
            costOthers: 0,
            costSalary: 0,
            costTotal: 0,
            financeCost: 0,
        };
    }

    goToDetailsView() {
        this.router.navigateByUrl(
            `/accounting/accountingreports/dimension?type=1&id=${this.currentProject.ID}&number=${this.currentProject.ProjectNumber}&name=${this.currentProject.Name}`,
        );
    }

    private loadData() {
        const costEndpoint =
            `/api/statistics?model=JournalEntryLine&filter=project.ID eq ${this.currentProject.ID} and TopLevelAccountGroup.GroupNumber gt 3` +
            `&expand=Dimensions.Project,Account.TopLevelAccountGroup` +
            `&select=sum(JournalEntryLine.Amount) as SumAmount,` +
            `TopLevelAccountGroup.Name as TopLevelAccountGroupName,TopLevelAccountGroup.GroupNumber as TopLevelAccountGroupGroupNumber`;

        this.dataService.get(costEndpoint).subscribe((res) => {
            this.resetValues();
            res?.Data.forEach((element) => {
                element.SumAmount = element.SumAmount | 0;

                if (parseInt(element.TopLevelAccountGroupGroupNumber) === 4) {
                    this.values.costProducts += element.SumAmount;
                } else if (parseInt(element.TopLevelAccountGroupGroupNumber) === 5) {
                    this.values.costSalary = element.SumAmount;
                } else if (
                    parseInt(element.TopLevelAccountGroupGroupNumber) === 6 ||
                    parseInt(element.TopLevelAccountGroupGroupNumber) === 7
                ) {
                    this.values.costOthers += element.SumAmount;
                } else if (parseInt(element.TopLevelAccountGroupGroupNumber) === 8) {
                    this.values.financeCost += element.SumAmount;
                }
                this.values.costTotal += element.SumAmount;
            });

            this.cdr.markForCheck();
        });
    }
}
