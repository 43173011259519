<section class="uni-modal employee-leave-modal" [ngClass]="{ single: step === 2 }">
    <header>{{ options?.header || 'Permisjoner' }}</header>

    <article>
        <section *ngIf="isBusy" class="modal-spinner">
            <mat-spinner class="c2a"></mat-spinner>
        </section>

        <ng-container *ngIf="step === 1">
            <employee-search-component
                [leavetype]="employeeLeave?.LeaveType"
                (employeeSelect)="onEmployeeSelected($event)"
                (setBusy)="setBusy($event)"
            ></employee-search-component>
        </ng-container>

        <ng-container *ngIf="step === 2">
            <section
                class="alert info"
                *ngIf="!isHidden && (employeeLeave.LeaveType < 7 || employeeLeave.LeaveType > 100)"
            >
                <i class="material-icons-outlined">info</i>
                <span
                    >Permisjoner som legges inn her rapporteres i a-melding. En permisjon som er rapportert i a-melding
                    kan ikke slettes. Er permisjonen feilrapportert, settes sluttdato lik startdato.</span
                >
                <i class="material-icons close-button" role="button" (click)="this.isHidden = true">close</i>
            </section>

            <section class="alert good" style="transition: height 0.4s ease" *ngIf="infoObject">
                <i class="material-icons">support</i>
                <section class="flex-column" style="width: 100%">
                    <span class="flex read-more">
                        <span>{{ infoObject.title }}</span>
                        <a class="ml-3 color-c2a" (click)="expanded = !expanded">
                            {{ expanded ? 'Vis mindre' : 'Les mer' }}</a
                        >
                    </span>
                    <section class="minimized" [class.expanded]="expanded">
                        <section *ngFor="let text of infoObject.infotexts">
                            <p>
                                {{ text.text }}
                                <span>
                                    <a *ngIf="text.link?.blank" [href]="text.link.blank" target="_blank">{{
                                        text.link.text
                                    }}</a>
                                    <a
                                        *ngIf="text.link?.url"
                                        [routerLink]="[text.link.url]"
                                        [queryParams]="text.link.params"
                                        (click)="close()"
                                        >{{ text.link.text }}</a
                                    >
                                </span>
                            </p>
                        </section>
                    </section>
                </section>
            </section>

            <employee-leave-counter-info
                [employeeLeave]="employeeLeave"
                [employee]="employee"
                [selfReportIsLocked]="selfReportIsLocked"
                (hasError)="updateDisabled($event)"
            ></employee-leave-counter-info>

            <uni-form
                *ngIf="!selfReportIsLocked"
                [config]="{ showLabelAbove: true, autoFocus: true }"
                [model]="employeeLeave"
                [fields]="employeeLeaveFields"
                (changeEvent)="change()"
            >
            </uni-form>

            <section class="minimized" [class.expanded]="!!errorMsg && !hasError">
                <small class="color-bad"> {{ errorMsg }} </small>
            </section>

            <section class="flex" *ngIf="selfReportIsLocked">
                <i class="material-icons color-c2a mr-2">info</i>
                <section class="flex-column locked-info">
                    <span> Registrere fravær når egenmelding er inndratt </span>
                    <span>
                        Rettighetene til å la den ansatte føre egenmeldinger kan endres på ansattoversikt under fravær.
                        For å gå til denne ansatte kan du <a (click)="goToEmployeeCard()">trykke her.</a> <br /><br />

                        Om du ikke ønsker å gi tilbake mulighet til å føre egenmelding, kan du velge å føre et annet
                        type fravær på den ansatte. For eksempel permisjon med eller uten lønn. <br /><br />

                        Ønsker du mer informasjon om reglene for egenmelding, er det tilgjengelig på nav sine
                        <a
                            target="_blank"
                            href="https://www.nav.no/no/person/arbeid/sykmeldt-arbeidsavklaringspenger-og-yrkesskade/sykmelding-ulike-former/egenmelding#chapter-1"
                            >nettsider</a
                        >
                    </span>
                </section>
            </section>
        </ng-container>
    </article>
    <footer>
        <button class="bad secondary pull-left" (click)="deleteLeave()" *ngIf="step === 2 && employeeLeave?.ID">
            Slett fravær
        </button>
        <button [ngClass]="!employeeLeave?.ID ? 'pull-left' : ''" (click)="close()">Avbryt</button>
        <button class="c2a secondary" *ngIf="step === 2 && !employeeLeave?.ID" (click)="goBack()">Tilbake</button>
        <button *ngIf="!selfReportIsLocked && step !== 1" (click)="save()" [disabled]="hasError" class="c2a">
            Lagre
        </button>
    </footer>
</section>
