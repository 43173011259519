<section role="dialog" class="uni-modal">
    <header>Sett kobling mellom regnskap og årsavslutning</header>

    <article>
        <section>
            <uni-alert-component
                [type]="'info'"
                [icon]="'info'"
                [title]="'Gjelder koblinger for standard kontoplan'"
                [text]="
                    'Valgene under har ikke tilbakevirkende kraft, og påvirker ikke egendefinerte hovedbokskontoer.'
                "
                [isOpen]="true"
                [showCloseButton]="false"
            ></uni-alert-component>

            <label class="weight-500">
                <span>Velg hvordan du vil at systemet skal sette koblinger</span>
            </label>
            <uni-radio-group class="vertical ml-2 mt-1" [(ngModel)]="updateOnlyMissing">
                <uni-radio-button [value]="false"
                    >Nullstill og la systemet legge inn standard koblinger
                </uni-radio-button>
                <uni-radio-button [value]="true"
                    >La systemet legge inn standard koblinger der det mangler</uni-radio-button
                >
            </uni-radio-group>
        </section>
    </article>

    <footer>
        <button class="secondary pull-right" (click)="close()">Avbryt</button>
        <button class="c2a" (click)="confirm()">Sett koblinger</button>
    </footer>
</section>
