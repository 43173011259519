import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BizHttp } from '../../../framework/core/http/BizHttp';
import { ExternalOffer } from '../../unientities';
import { UniHttp } from '../../../framework/core/http/http';
import { ElsaProductService } from '../elsa/elsaProductService';
import { ElsaPurchaseService } from '../elsa/elsaPurchasesService';
import { ElsaProduct, ElsaPurchaseStatus } from '@app/models';

@Injectable({ providedIn: 'root' })
export class ExternalOfferService extends BizHttp<ExternalOffer> {
    constructor(
        http: UniHttp,
        private elsaProductService: ElsaProductService,
        private elsaPurchaseService: ElsaPurchaseService,
    ) {
        super(http);
        this.relativeURL = ExternalOffer.RelativeUrl;
        this.entityType = ExternalOffer.EntityType;
    }

    public getExternalOfferById(Id: number, companyKey: string): Observable<any> {
        return this.http
            .asGET()
            .usingBusinessDomain()
            .withHeader('CompanyKey', companyKey)
            .withEndPoint(`${this.relativeURL}/${Id}`)
            .send({}, null, false)
            .pipe(map((response) => response.body));
    }

    public getExternalOffers(entityID: number, entityType: string): Observable<ExternalOffer[]> {
        return this.GetAction(null, 'get-external-offers', `entityId=${entityID}&entityType=${entityType}`);
    }

    public getActiveInvoiceSalesVendors(): Observable<ElsaProduct[]> {
        return new Observable((observer) => {
            // Invoice sale product category name
            const categoryName = 'Fakturasalg';

            // Getting all invoice sale products
            this.elsaProductService.GetAll(`CategoryName eq '${categoryName}'`).subscribe((products) => {
                // Getting all invoice sale products
                const elsaPurchaseObservableList = products.map((product) =>
                    this.elsaPurchaseService.getPurchaseByProductName(product.Name),
                );

                forkJoin(elsaPurchaseObservableList).subscribe((elsaPurchases) => {
                    // Getting only the active products
                    const activeProducts = [];

                    elsaPurchases.forEach((elsaPurchase, i) => {
                        if (
                            elsaPurchase !== null &&
                            (elsaPurchase.PurchaseStatus === null ||
                                elsaPurchase.PurchaseStatus === ElsaPurchaseStatus.Accepted)
                        ) {
                            activeProducts.push(products[i]);
                        }
                    });
                    observer.next(activeProducts);
                    observer.complete();
                });
            });
        });
    }
}
