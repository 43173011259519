<section class="header">
    <h1>Nye</h1>
    <button #toggle class="secondary pull-right">
        {{ activeFilter.label }}
        <i class="material-icons pad-left">expand_more</i>
    </button>

    <dropdown-menu [trigger]="toggle">
        <ng-template>
            <section *ngFor="let filter of periodFilters" (click)="setActiveFilter(filter)" class="dropdown-menu-item">
                {{ filter.label }}
            </section>
        </ng-template>
    </dropdown-menu>
</section>

<section class="content">
    <ul class="widget-link-list">
        <a *ngFor="let item of items" [routerLink]="item.link">
            <span>{{ item.label | translate }}</span>
            <span class="pull-right">{{ item.hasAccess ? item.count : 'Mangler tilgang' }}</span>
        </a>
    </ul>
</section>
