import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'bankid-svg',
    templateUrl: './bankid.component.svg',
    styles: [':host {display: flex}'],
})
export class BankIdSvgComponent implements OnInit {
    @Input() r: number = 255;
    @Input() g: number = 255;
    @Input() b: number = 255;
    fillColor = `white`;
    showDots = true;

    ngOnInit() {
        this.fillColor = `rgb(${this.r}, ${this.g}, ${this.b})`;
    }

    changeColor(r?, b?, g?) {
        if (r) this.r = r;
        if (b) this.b = b;
        if (g) this.g = g;

        this.fillColor = `rgb(${this.r}, ${this.g}, ${this.b})`;
    }
}
