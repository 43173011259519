import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AccountService } from '@app/services/accounting/accountService';
import { FieldType, PaymentMethod } from '@uni-entities';
import { UniFieldLayout, UniForm } from '@uni-framework/ui/uniform';
import { ConfirmActions, IModalOptions, IUniModal } from '@uni-framework/uni-modal';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'payment-method-registration-modal',
    templateUrl: './payment-method-registration-modal.html',
    styleUrls: ['./payment-method-registration-modal.sass'],
})
export class PaymentMethodRegistrationModal implements IUniModal {
    @ViewChild(UniForm) form: UniForm;
    @Input() options: IModalOptions = {};
    @Output() onClose = new EventEmitter();

    paymentMethod: PaymentMethod;
    editMode: boolean;
    fields$ = new BehaviorSubject<UniFieldLayout[]>([]);

    constructor(private accountService: AccountService) {}

    ngOnInit() {
        this.fields$.next(this.getFormFields());

        const defaultRowModel = {
            Name: '',
            AccountID: null,
        };
        this.editMode = this.options?.data?.editMode || false;
        this.paymentMethod = this.options?.data?.paymentMethod || defaultRowModel;
        this.paymentMethod['_isDirty'] = true;
    }

    ngOnDestroy() {
        this.fields$.complete();
    }

    getFormFields() {
        let fields = [];
        fields = [
            {
                Property: 'Name',
                Label: 'Navn på betalingsmåte',
                FieldType: FieldType.TEXT,
                Required: true,
            },
            {
                Property: 'AccountID',
                Label: 'Føres mot regnskapskonto',
                FieldType: FieldType.AUTOCOMPLETE,
                Options: this.accountService.getSearchConfigUniForm('ID'),
                Required: true,
            },
        ];
        return fields;
    }

    async confirm() {
        const isValid = await this.form.forceValidation();
        if (isValid) {
            return this.onClose.emit({ action: ConfirmActions.ACCEPT, paymentMethod: this.paymentMethod });
        }
    }

    cancel() {
        return this.onClose.emit({ action: ConfirmActions.CANCEL });
    }
}
