import { CurrentUserWithout2FADetails } from '@app/authService';
import { WorkOrder } from './workorder';
import { LocalDate } from '@uni-entities';

export enum WizardSource {
    CustomerHours = 0,
    OrderHours = 1,
    ProjectHours = 2,
}

export interface IWizardOptions {
    currentUser: CurrentUserWithout2FADetails;
    filterByUserID: number;
    source: WizardSource;
    periodFrom: LocalDate;
    periodTo: LocalDate;
    selectedCustomers: Array<any>;
    selectedProducts: Array<any>;
    orders: Array<WorkOrder>;
    mergeBy: MergeByEnum;
    addComment?: boolean;
    addItemsDirectly?: boolean;
    splitOnDimension?: boolean;
}

export enum MergeByEnum {
    mergeByWorktypeAndText = 0,
    mergeByWorktype = 1,
    mergeByProduct = 2,
    mergeByText = 3,
}
