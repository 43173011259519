import {
    Component,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    HostListener,
    EventEmitter,
    ViewChild,
} from '@angular/core';
import { IUniModal, IModalOptions } from '@uni-framework/uni-modal';
import { LocalDate } from '@uni-entities';
import { WorkEditor } from './workeditor';
import { TimeSheet, TimesheetService } from '@app/services/timetracking/timesheetService';
import { ErrorService } from '@app/services/common/errorService';
import { ToastService, ToastType } from '@uni-framework/uniToast/toastService';

@Component({
    selector: 'uni-time-modal',
    template: `
        <section class="uni-modal">
            <header>Rediger timer</header>

            <article [attr.aria-busy]="busy" id="uniTableWrapper">
                <workeditor class="workEditor" [order]="options.data.order" [timesheet]="timesheet"> </workeditor>

                <div class="flex align-center justify-end gap-1 mt-2 color-c2a">
                    <i class="material-icons">work_outline</i>
                    <strong class="text-small"
                        >Totalsum: {{ timesheet?.totals?.Minutes | min2hours: 'decimal' }}</strong
                    >
                </div>
            </article>

            <footer>
                <button (click)="close('cancel')" class="secondary">Avbryt</button>

                <button class="c2a" (click)="close('ok')">Lagre</button>
            </footer>
        </section>
    `,
    styleUrls: ['./popupeditor.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniTimeModal implements IUniModal {
    @ViewChild(WorkEditor, { static: true }) editor: WorkEditor;

    options: IModalOptions;
    onClose = new EventEmitter();

    timesheet: TimeSheet;
    date: Date;
    busy: boolean = false;

    constructor(
        private timesheetService: TimesheetService,
        private cdr: ChangeDetectorRef,
        private errorService: ErrorService,
        private toastService: ToastService,
    ) {}

    public ngOnInit() {
        // setting height of other modal to cover the other modal
        if (this.options.data.height) {
            const element = document.getElementById('uniTableWrapper');
            element.setAttribute('style', 'height:' + this.options.data.height + ';');
        }

        const ts = this.timesheetService.newTimeSheet(this.options.data.relation);
        if (this.timesheet) {
            this.timesheet.items = [];
        }
        this.date = this.options.data.date;
        this.setBusy(true);
        ts.loadItemsByPeriod(this.date, this.date).subscribe((x) => {
            this.editor.EmptyRowDetails.Date = new LocalDate(this.date);
            this.timesheet = ts;
            this.setBusy(false);
        });
    }

    public close(src: 'ok' | 'cancel') {
        if (src === 'ok') {
            this.editor.closeEditor();
            setTimeout(() => this.save(), 10);
            return;
        }
        this.onClose.emit(false);
        this.cdr.markForCheck();
    }

    private save() {
        if (this.timesheet.items.some((x) => !x.Minutes && !x.EndTime)) {
            this.toastService.addToast(
                'Mangler påkrevd felt',
                ToastType.warn,
                10,
                'Slutt eller Timer må være fylt ut før man lagrer',
            );
            return;
        }
        this.setBusy(true);
        this.timesheet
            .saveItems(true)
            .subscribe(
                () => this.onClose.emit(true),
                (err) => {
                    this.errorService.handle(err);
                },
            )
            .add(() => this.setBusy(false));
    }

    private setBusy(busy: boolean = true) {
        this.busy = busy;
        this.cdr.markForCheck();
    }

    @HostListener('keydown', ['$event'])
    public keyHandler(event: KeyboardEvent) {
        switch (event.keyCode) {
            case 27: // ESC
                this.close('cancel');
                break;
            case 83: // S
                if (event.ctrlKey) {
                    event.preventDefault();
                    this.close('ok');
                }
                break;
        }
    }
}
