<section role="dialog" class="uni-modal" style="width: 40rem">
    <header>Du er i mål</header>

    <article>
        <p>Du har fullført de viktigste stegene for å komme igang med {{ appName }}</p>
        <uni-illustration-deprecated name="happy"></uni-illustration-deprecated>
    </article>

    <footer class="center">
        <button class="secondary pull-left" (click)="onClose.emit(false)">Lukk</button>
        <button class="c2a" (click)="onClose.emit(true)">Skjul kom i gang</button>
    </footer>
</section>
