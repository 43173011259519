import { NgModule } from '@angular/core';
import { UniFrameworkModule } from '@uni-framework/frameworkModule';
import { UniEntityEditorComponent } from './uni-entity-editor.component';
import { UniEntityEditorSectionComponent } from './uni-entity-editor-group/uni-entity-editor-section.component';
import { UniEntityEditorFocusService } from './shared/services/uni-entity-editor-focus.service';

@NgModule({
    imports: [UniFrameworkModule],
    declarations: [UniEntityEditorComponent, UniEntityEditorSectionComponent],
    exports: [UniEntityEditorComponent],
    providers: [UniEntityEditorFocusService],
})
export class UniEntityEditorModule {}
