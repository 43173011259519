<section *ngIf="supportUsers.length > 0" class="navbar-alert" (click)="showMore()">
    <span class="eye-icon">
        <svg width="18" height="18" viewBox="0 0 20 19" fill="none">
            <path
                d="M23.379 10.38c-2.68-2.946-7.035-5.946-11.38-5.88C7.656 4.435 3.302 7.435.625 10.38l-.002.003a2.413 2.413 0 000 3.23l.002.002C3.24 16.497 7.584 19.566 12 19.498c4.416.068 8.76-3 11.379-5.883l.002-.002a2.413 2.413 0 000-3.23l-.002-.003zM1.735 12.608a.913.913 0 010-1.22c2.563-2.82 6.54-5.45 10.252-5.387h.025c3.713-.063 7.69 2.567 10.256 5.387a.913.913 0 010 1.22c-2.511 2.763-6.477 5.454-10.256 5.39h-.025c-3.779.064-7.743-2.627-10.252-5.39zM12 9a2.999 2.999 0 013 3l.75.001H15A3 3 0 1112 9zm3.183-.182A4.499 4.499 0 0116.5 12L12 7.5c1.194 0 2.339.474 3.183 1.318zM11.999 16.5A4.5 4.5 0 0016.5 12L12 7.5a4.5 4.5 0 000 9z"
                fill="currentColor"
            ></path>
        </svg>
    </span>
</section>

<section
    *ngIf="supportUsers.length > 0 && showDialog"
    class="show-more-dialog"
    (clickOutside)="showDialog = false"
    [@slideInOut]
>
    <section class="dialog-body">
        <div style="font-weight: 500">{{ supportUsers[0].DisplayName }}</div>
        <span>{{ this.supportUsers[0]['_supportTypeText'] }} har midlertidig tilgang til ditt regnskap</span>
    </section>
    <footer>
        <button class="c2a" (click)="deactivateSupportUser()" [disabled]="!isAdmin">Avslutt tilgang</button>
    </footer>
</section>
