import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AuthService } from '@app/authService';
import { ElsaProductService } from '@app/services/elsa/elsaProductService';
import { ElsaPurchaseService } from '@app/services/elsa/elsaPurchasesService';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { theme, THEMES } from 'src/themes/theme';

const SR_Bank_BIC = 'SPRONO22';

enum ChatBotProvider {
    None = 0,
    Boost = 10,
    Zendesk = 20,
    DialogHub = 30,
}

@Component({
    selector: 'support-chat',
    templateUrl: './support-chat.html',
    styleUrls: ['./support-chat.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupportChat {
    isUniEconomy = theme.theme === THEMES.UE;

    chatReady: boolean;
    showChat: () => void;
    createSupportCase: () => void;

    constructor(
        private authService: AuthService,
        private elsaPurchaseService: ElsaPurchaseService,
        private elsaProductService: ElsaProductService,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        if (
            theme.theme === THEMES.SR &&
            this.authService.publicSettings.ChatBotProvider === ChatBotProvider.DialogHub
        ) {
            this.initDialogHubChat();
        } else if (this.isUniEconomy || theme.theme === THEMES.SR) {
            this.initBoostChat();
        }

        if (theme.theme === THEMES.EXT02) {
            this.initZendeskChat();
        }
    }

    private initBoostChat() {
        // temporary check for boost code, 'bigger than 40' should be safe since guid is 36 or 38 chars.
        let boostCode;
        if (sessionStorage.getItem('code') && sessionStorage.getItem('code').length > 40) {
            boostCode = decodeURIComponent(sessionStorage.getItem('code'));
        }

        let conversationId = sessionStorage.getItem('boostConversationId');
        const BIC = this.authService.publicSettings?.BIC || '';
        // UE and SR has their own servers, other SB1 banks share a different server
        const server = this.isUniEconomy ? 'unimicro' : BIC === SR_Bank_BIC ? '435984srpoc' : '487016sb1regnskap';

        const script = document.createElement('script');
        script.type = 'application/javascript';
        script.src = `https://${server}.boost.ai/chatPanel/chatPanel.js`;
        script.onload = () => {
            const chatPanelConfiguration = {
                apiUrlBase: `https://${server}.boost.ai/api`,
                conversationId: conversationId,
                pace: 'fast',
                hyperlinksTargetBlank: true,
                filterValues: [BIC],
            };

            const chatPanel = window['boostChatPanel'] && window['boostChatPanel'](chatPanelConfiguration);

            if (chatPanel) {
                chatPanel.addEventListener('conversationIdChanged', (event) => {
                    conversationId = event.detail.conversationId;
                    sessionStorage.setItem('boostConversationId', conversationId);

                    if (boostCode) {
                        setTimeout(() => {
                            chatPanel.loginEvent({ authContent: boostCode, authType: 'openid' });

                            // boost code is only valid once, remove it after we've used it
                            boostCode = '';
                            sessionStorage.removeItem('code');
                        }, 20);
                    }
                });

                if (boostCode) {
                    chatPanel.show();
                }

                this.chatReady = true;
                this.showChat = () => chatPanel.show();
                this.createSupportCase = () => chatPanel.sendMessage('Opprett supportsak');

                this.cdr.markForCheck();
            }
        };

        document.getElementsByTagName('head')[0].appendChild(script);
    }

    private canShowAzetsChat(): Observable<boolean> {
        const license = this.authService.currentUser.License;
        const contractType = license?.ContractType?.TypeID;
        const filter = `ProductType eq 'AccountingAgencyCompany'`;

        return forkJoin([
            this.elsaProductService.getProductsOnContractTypes(contractType, filter, 'id, name'),
            this.elsaPurchaseService.getAll(),
        ]).pipe(
            map(([products, purchases]) => {
                const azetsProduct = products.find((p) => p.Name.toLowerCase().includes('azets insight'));
                const hasPurchasedAzets = purchases.some((p) => p.ProductID === azetsProduct?.ID);
                const hasAnyAgencyPurchase = purchases.some((purchase) =>
                    products.some((product) => product.ID === purchase.ProductID),
                );
                return hasPurchasedAzets || (!hasAnyAgencyPurchase && !license?.CustomerInfo?.HasExternalAccountant);
            }),
            catchError(() => of(false)),
        );
    }

    private initDialogHubChat() {
        const script = document.createElement('script');
        script.type = 'application/javascript';
        script.src = `https://www.sparebank1.no/bank/dialoghub/static/integration.js?fid=${this.authService.publicSettings.FinancialInstitutionID}&market=bm`;

        script.onerror = () => {
            // Fallback in case bank is missing FinancialInstitutionID or something else goes wrong
            this.initBoostChat();
        };

        document.getElementsByTagName('head')[0].appendChild(script);
    }

    private initZendeskChat() {
        const script = document.createElement('script');
        script.type = 'application/javascript';
        script.id = 'ze-snippet';
        script.src = 'https://static.zdassets.com/ekr/snippet.js?key=26dce926-7676-4403-bf9c-5abe6748f65b';

        script.onload = () => {
            // If the zendesk api is available we hide their default launcher and use our own instead.
            // If we're unable to access the api it'll just show the default launch button.
            if (window['zE']) {
                const zendeskApi = (...args) => window['zE'](...args);

                zendeskApi('webWidget', 'hide');

                zendeskApi('webWidget:on', 'close', () => {
                    zendeskApi('webWidget', 'hide');
                });

                this.chatReady = true;
                this.showChat = () => {
                    zendeskApi('webWidget', 'show');
                    zendeskApi('webWidget', 'open');
                };

                this.cdr.markForCheck();
            }
        };

        document.getElementsByTagName('head')[0].appendChild(script);

        this.canShowAzetsChat().subscribe((canShowAzetsChat) => {
            const departments = ['SystemstøtteDNB'];
            if (canShowAzetsChat) {
                departments.push('Regnskapsfører @Azets');
            }

            window.zESettings = {
                webWidget: {
                    chat: {
                        departments: { enabled: departments },
                    },
                },
            };
        });
    }
}
