<section class="header">
    <h1>Banksaldo</h1>
</section>

<section class="content">
    <widget-empty-state *ngIf="onboardingState === 'none'" class="horizontal">
        For å se saldo på bankkonto trenger du en autobankavtale med banken. <br />
        <button (click)="runOnboarding()" class="c2a">Koble sammen bank og regnskap</button>
    </widget-empty-state>

    <widget-empty-state *ngIf="onboardingState === 'pending'" class="horizontal">
        Du har bestilt kobling mellom regnskap og bank. Du får en bekreftelse på epost når bestillingen er fullført.
        <br /><br />
        Denne prosessen kan ta opp til tre virkedager. Dersom du ikke har mottatt bekreftelse eller ble avbrutt kan du
        <a (click)="runOnboarding()">starte på nytt her</a>.
    </widget-empty-state>

    <widget-empty-state *ngIf="onboardingState === 'connectAccounts'" class="horizontal">
        Vi har mottatt kontoinformasjon fra banken. Vennligst hjelp oss å knytte riktige kontoer til DNB Regnskap.<br />

        <button (click)="runOnboarding()" class="c2a">Sett opp kontoer</button>
    </widget-empty-state>

    <widget-empty-state *ngIf="onboardingState === 'error'" [imageUrl]="errorImageUrl" class="horizontal">
        <h2><i class="material-icons color-bad">error</i> Vi klarte ikke hente din saldo</h2>

        <p class="widget-error-message">Prøv igjen, eller ta kontakt med support</p>
        <button class="c2a" (click)="getOnboardingState()">Prøv igjen</button>
    </widget-empty-state>

    <widget-empty-state *ngIf="onboardingState === 'refresh'" class="horizontal">
        {{
            isIntegrationOwner
                ? 'Det er for lenge siden sist du identifiserte deg med BankID, og vi mottar derfor ikke oppdatert informasjon fra banken lenger. Trykk på knappen for å identifisere deg på nytt, og oppdatere koblingen mot bank.'
                : 'Det er for lenge siden sist personen som opprettet API-avtalen identifiserte seg med BankID, og vi mottar derfor ikke oppdatert informasjon fra banken lenger. Ta kontakt med personen som opprettet API-avtalen for å oppdatere koblingen mot bank. '
        }}<br />

        <button (click)="runOnboarding()" *ngIf="isIntegrationOwner" class="c2a">Oppdater kobling med bank</button>
    </widget-empty-state>

    <bank-balance-widget-content
        *ngIf="onboardingState === 'complete'"
        [bankIntegrationAgreement]="brunoApiBankAgreement"
    ></bank-balance-widget-content>
</section>
