<!--All goods to standard product-->
<table *ngIf="goods.length && isExistingGoods">
    <tr>
        <th class="th-info">Produkt fra leverandør</th>
        <th class="th-arrow"></th>
        <th class="th-product">Produkt</th>
    </tr>
    <tr
        *ngFor="let good of goods"
        [ngStyle]="{
            'background-color':
                highlightedGood.GoodsNumber === good.GoodsNumber ? 'var(--table-row-select-background)' : '',
        }"
    >
        <td class="td-info">
            <b>{{ good.GoodsNumber }} - {{ good.GoodsName }}</b>
        </td>

        <td class="td-arrow">
            <i class="material-icons-outlined arrow"> east </i>
        </td>

        <td class="td-product">
            <autocomplete
                [options]="autocompleteOptions"
                [(value)]="good.Product"
                (valueChange)="onProductChange(good)"
                [readonly]="productReadOnly"
            >
            </autocomplete>
        </td>
    </tr>
</table>

<!--New good, Create a new product-->
<table class="new-products" *ngIf="goods.length && isNewGoodsNewProduct">
    <tr>
        <th class="th-info">Produkt fra leverandør</th>
        <th class="th-arrow"></th>
        <th class="th-number">Produktnummer</th>
        <th class="th-name">Navn</th>
        <th class="th-button"></th>
    </tr>
    <tr
        *ngFor="let good of goods"
        [ngStyle]="{
            'background-color':
                highlightedGood.GoodsNumber === good.GoodsNumber ? 'var(--table-row-select-background)' : '',
        }"
    >
        <td class="td-info" [title]="good.GoodsName">
            <b>{{ good.GoodsNumber }} - {{ good.GoodsName }} </b>
        </td>

        <td class="td-arrow">
            <i class="material-icons-outlined arrow"> east </i>
        </td>

        <td class="td-number">
            <input
                type="text"
                [disabled]="productReadOnly"
                [ngClass]="good['_invalidPartName'] ? 'validation-error' : ''"
                [(ngModel)]="good['_productPartName']"
                (ngModelChange)="onProductChange(good)"
                placeholder="Neste ledig v/ blank"
            />
            <span *ngIf="good['_invalidPartName']">Nummeret er i bruk</span>
        </td>

        <td class="td-name">
            <input
                type="text"
                [disabled]="productReadOnly"
                [ngClass]="good['_missingName'] ? 'validation-error' : ''"
                [(ngModel)]="good['_productName']"
                (ngModelChange)="onProductChange(good)"
            />
            <span *ngIf="good['_missingName']">Mangler navn</span>
        </td>

        <td class="td-move">
            <button class="goods-item-move" (click)="toExistingProduct(good)" title="Flytt linje tilbake">
                <i class="material-icons-outlined"> vertical_align_bottom </i>
            </button>
        </td>
    </tr>
</table>

<!--New good, Product exists-->
<table *ngIf="goods.length && isNewGoodsWithProduct">
    <tr>
        <th class="th-info">Produkt fra leverandør</th>
        <th class="th-arrow"></th>
        <th class="th-product">Produkt</th>
        <th class="th-button"></th>
    </tr>
    <tr
        *ngFor="let good of goods"
        [ngStyle]="{
            'background-color':
                highlightedGood.GoodsNumber === good.GoodsNumber ? 'var(--table-row-select-background)' : '',
        }"
    >
        <td class="td-info">
            <b>{{ good.GoodsNumber }} - {{ good.GoodsName }}</b>
        </td>

        <td class="td-arrow">
            <i class="material-icons-outlined arrow"> east </i>
        </td>

        <td class="td-product">
            <autocomplete
                [options]="autocompleteOptions"
                [(value)]="good.Product"
                (valueChange)="onProductChange(good)"
                [readonly]="productReadOnly"
            >
            </autocomplete>
        </td>

        <td class="td-move">
            <button *ngIf="!productReadOnly" class="goods-item-move" title="Velg linje" (click)="toNewProduct(good)">
                <i class="material-icons-outlined"> vertical_align_top </i>
            </button>
        </td>
    </tr>
</table>
