<section class="header">
    <h1>{{ calendarMonthName }}</h1>

    <section class="month-selector">
        <button (click)="prevMonth()" class="icon-button">
            <i class="material-icons">chevron_left</i>
        </button>

        <button (click)="nextMonth()" class="icon-button">
            <i class="material-icons">chevron_right</i>
        </button>
    </section>

    <button class="tertiary c2a pull-right" routerLink="/timetracking/timeentry">Registrer timer</button>
</section>

<section *ngIf="!loading" class="content flex-row">
    <widget-empty-state *ngIf="missingWorker">
        Det ser ikke ut som brukeren din er satt opp for timeføring på dette selskapet.
        <button class="c2a" [attr.aria-busy]="activating" (click)="activateWorker()">Aktiver timeføring</button>
    </widget-empty-state>

    <ng-container *ngIf="!missingWorker && calendar">
        <table>
            <thead>
                <tr>
                    <th class="week-number">
                        <span>Uke</span>
                    </th>
                    <th *ngFor="let day of dayLabels">{{ day }}</th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let week of calendar; let idx = index">
                    <td class="week-number">{{ week[0]?.weekNumber }}</td>
                    <td *ngFor="let day of week" (click)="openTimeEntryDialog(day)" class="day">
                        <span [ngClass]="day.classList">
                            {{ day.date | date: 'd' }}
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>

        <section class="flex-section">
            <span>Timesaldo</span>
            <span class="sum" [ngClass]="flexBalance >= 0 ? 'good' : 'bad'">{{ flexBalance }}</span>

            <section class="flex-balance-change">
                <i *ngIf="flexBalanceChangeInPeriod === 0" class="material-icons">trending_flat</i>
                <i *ngIf="flexBalanceChangeInPeriod > 0" class="material-icons good">trending_up</i>
                <i *ngIf="flexBalanceChangeInPeriod < 0" class="material-icons bad">trending_down</i>

                <span>
                    {{ flexBalanceChangeInPeriod >= 0 ? '+' : '' }}
                    {{ flexBalanceChangeInPeriod }}
                    timer i {{ calendarMonthName }}
                </span>
            </section>
        </section>
    </ng-container>
</section>
