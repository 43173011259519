<section class="uni-modal">
    <header>Overlappende fravær</header>
    <article [attr.aria-busy]="busy">
        <section class="alert warn flex">
            <i class="material-icons warn">warning</i>
            <section>
                Du forsøker å legge inn fravær på en fraværsdag som allerede har fravær. Det kan kun være et fravær
                aktivt per dag, du kan under enten endre start-/sluttdato på fraværene eller slette et av de.
            </section>
        </section>

        <section>
            <label class="uni-label label-above">
                <span>Ansatt</span>
                <a
                    class="label-value-text"
                    (click)="goToEmployeeOverview()"
                    title="Lukk modal og gå til ansattoversikt"
                >
                    {{ employee.DisplayName || employee?.BusinessRelationInfo?.Name || employee?.Name }}
                </a>
            </label>

            <label class="uni-label label-above">
                <span>Arbeidsforhold</span>
                <span class="label-value-text">
                    {{ this.options.data?.currentEmployeeLeave?.Employment?.JobName || 'Ikke tilgjengelig' }}
                </span>
            </label>
        </section>

        <section *ngFor="let leave of leaves">
            <section class="leave-header">
                <span class="flex"> {{ leave.Name }} </span>
                <span class="flex"> {{ leave.Description }} </span>
            </section>
            <section class="employee-leave-dates">
                <section>
                    <uni-form
                        [config]="{ showLabelAbove: true }"
                        [fields]="fields"
                        [model]="leave"
                        (changeEvent)="change()"
                    >
                    </uni-form>
                    <section class="warning-section">
                        <section>
                            <section *ngIf="leave.OverlappingFromDateText" class="overlap-error">
                                <i class="material-icons warn">warning</i>
                                <span>{{ leave.OverlappingFromDateText }}</span>
                            </section>
                        </section>
                        <section>
                            <section *ngIf="leave.OverlappingToDateText" class="overlap-error">
                                <i class="material-icons warn">warning</i>
                                <span>{{ leave.OverlappingToDateText }}</span>
                            </section>
                        </section>
                    </section>
                </section>
                <i class="material-icons-outlined trash-can" (click)="removeLeave(leave)">delete</i>
            </section>
        </section>
    </article>
    <footer>
        <button class="secondary" (click)="onClose.emit()">Avbryt</button>
        <button class="c2a" [disabled]="hasOverlap || busy" (click)="save()">Lagre endring</button>
    </footer>
</section>
