import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorService } from '@app/services/common/errorService';
import { EmployeeLeaveService } from '@app/services/salary/employee-leave.service';
import { Employee, EmployeeLeave, FieldType } from '@uni-entities';
import { IModalOptions, IUniModal } from '@uni-framework/uni-modal';
import { rigDate } from '@app/components/common/utils/rig-date';
import { concat } from 'rxjs';

@Component({
    selector: 'uni-overlapping-leave-modal',
    templateUrl: './overlapping-leave-modal.html',
    styleUrls: ['./overlapping-leave-modal.sass'],
})
export class OverlappingLeaveModal implements IUniModal, OnInit {
    @Output() onClose = new EventEmitter();
    options: IModalOptions = {};
    employee: any;

    busy: boolean;
    hasOverlap: boolean;

    fields = [];
    leaves: any[] = [];
    deletedLeaves: any[] = [];
    types: { ID: number; text: string }[];

    constructor(
        private employeeLeaveService: EmployeeLeaveService,
        private errorService: ErrorService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.types = this.employeeLeaveService.getAllTypes();
        this.employee = this.options?.data?.employee;
        this.setFields();

        this.employeeLeaveService.GetAll(`filter=ID in (${this.options.data.overlappingLeavesIDs})`).subscribe({
            next: (leaves: EmployeeLeave[]) => {
                this.leaves = [this.options.data.currentEmployeeLeave, ...leaves];
                this.leaves.forEach((leave) => {
                    leave.FromDate = rigDate(leave.FromDate).format('YYYY-MM-DD');
                    leave.ToDate = rigDate(leave.ToDate).format('YYYY-MM-DD');
                    leave.Name = this.types.find((x) => x.ID === leave.LeaveType).text;
                });

                this.leaves[0].Name += ' (Ny)';

                this.leaves = [...this.filterDuplicates()];

                this.updateOverlaps();
            },
        });
    }

    filterDuplicates(): any[] {
        for (var i = 0; i < this.leaves.length; i++) {
            let current = this.leaves[i];

            if (!current._duplicate) {
                for (let k = i + 1; k < this.leaves.length; k++) {
                    let checker = this.leaves[k];
                    if (
                        checker.FromDate === current.FromDate &&
                        checker.ToDate === current.ToDate &&
                        checker.LeaveType === current.LeaveType
                    ) {
                        checker._duplicate = true;
                    }
                }
            }
        }

        return this.leaves.filter((leave) => !leave._duplicate);
    }

    change(): void {
        this.updateOverlaps();
    }

    goToEmployeeOverview() {
        this.onClose.emit(false);
        this.router.navigateByUrl(`/salary/absence/employee-leave-overview/${this.employee.ID}`);
    }

    save() {
        let saveObsList$ = [];

        this.deletedLeaves.forEach((leave) => {
            saveObsList$.push(this.employeeLeaveService.Remove(leave.ID));
        });

        this.leaves.forEach((leave) => {
            if (leave.ID) {
                saveObsList$.push(this.employeeLeaveService.Put(leave.ID, leave));
            }
        });

        this.leaves.forEach((leave) => {
            if (!leave.ID) {
                saveObsList$.push(this.employeeLeaveService.Post(leave));
            }
        });

        this.busy = true;
        concat(...saveObsList$)
            .subscribe({
                error: (error) => this.errorService.handle(error),
                complete: () => this.onClose.emit(),
            })
            .add(() => (this.busy = false));
    }

    removeLeave(leave) {
        if (leave.ID) {
            this.deletedLeaves.push(leave);
        }

        this.leaves = this.leaves.filter((l) => l.ID !== leave.ID);
        this.updateOverlaps();
    }

    private setFields(): void {
        this.fields = [
            {
                Property: 'FromDate',
                FieldType: FieldType.LOCAL_DATE_PICKER,
                Label: 'Fra dato',
                Classes: 'half-width',
            },
            {
                Property: 'ToDate',
                FieldType: FieldType.LOCAL_DATE_PICKER,
                Label: 'Til dato',
                Classes: 'half-width',
            },
        ];
    }

    private updateOverlaps(): void {
        let current: any;
        let newList = [];
        while (this.leaves.length) {
            current = this.leaves.pop();
            current.OverlappingFromDateText =
                this.getOverlappingErrorText(current.FromDate, newList) ??
                this.getOverlappingErrorText(current.FromDate, this.leaves);
            current.OverlappingToDateText =
                this.getOverlappingErrorText(current.ToDate, newList) ??
                this.getOverlappingErrorText(current.ToDate, this.leaves);
            newList.push(current);
        }
        this.leaves = newList.reverse();
        this.hasOverlap = this.leaves.some((x) => x.OverlappingFromDateText || x.OverlappingToDateText);
    }

    private getOverlappingErrorText(currentDate: any, employeeLeaves: any[]): any {
        let result = null;
        employeeLeaves.forEach((leave) => {
            if (
                rigDate(currentDate).isSame(leave.FromDate) ||
                rigDate(currentDate).isSame(leave.ToDate) ||
                rigDate(currentDate).isBetween(leave.FromDate, leave.ToDate)
            ) {
                result = `Overlapper med ${this.types.find((t) => t.ID === leave.LeaveType).text}`;
            }
        });
        return result;
    }
}
