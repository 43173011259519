import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { DashboardDataService } from '../../../dashboard-data.service';
import { Subscription, of, Observable, Subject, forkJoin } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { theme } from 'src/themes/theme';
import { Project } from '@uni-entities';
import { Router } from '@angular/router';
import { NumberFormat } from '@app/services/common/numberFormatService';
import { ProjectService } from '@app/services/common/projectService';

@Component({
    selector: 'project-result-widget',
    templateUrl: './project-result-widget.html',
    styleUrls: ['./project-result-widget.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectResultWidget {
    dataSubscription: Subscription;
    colors = theme.widgets?.project_result_colors || theme.widgets.pie_colors;

    loading: boolean = true;
    data: any[];
    chartConfig: any;

    onDestroy$ = new Subject();
    currentProject: Project;
    noProjectError: boolean = false;
    currentChartData;
    monthAndYearDataInBarChart;

    private MONTHS: string[] = [
        'Januar',
        'Februar',
        'Mars',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Desember',
    ];
    private MONTHS_SHORT: string[] = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'Mai',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Okt',
        'Nov',
        'Des',
    ];
    private MONTHS_NUMERIC: string[] = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

    constructor(
        private cdr: ChangeDetectorRef,
        private projectService: ProjectService,
        private dataService: DashboardDataService,
        private format: NumberFormat,
        private router: Router,
    ) {}

    ngOnInit() {
        this.projectService.currentProject.pipe(takeUntil(this.onDestroy$)).subscribe((project) => {
            if (project) {
                this.currentProject = project;
                this.noProjectError = false;
                this.initChart();
            } else {
                this.noProjectError = true;
            }
            this.cdr.markForCheck();
        });
    }

    ngOnDestroy() {
        this.dataSubscription?.unsubscribe();
        this.onDestroy$.next(undefined);
        this.onDestroy$.complete();
    }

    loadData() {
        let query =
            `/api/statistics?model=JournalEntryLine&filter=project.ID eq ${this.currentProject.ID} and TopLevelAccountGroup.GroupNumber gt 2` +
            `&expand=Dimensions.Project,Account.TopLevelAccountGroup` +
            `&select=sum(JournalEntryLine.Amount) as SumAmount,` +
            `sum(casewhen(TopLevelAccountGroup.GroupNumber eq 3\\,JournalEntryLine.Amount\\,0)) as SumIncome,` +
            `sum(casewhen(TopLevelAccountGroup.GroupNumber gt 3\\,JournalEntryLine.Amount\\,0)) as SumCost,` +
            `sum(casewhen(TopLevelAccountGroup.GroupNumber ge 3\\,JournalEntryLine.Amount\\,0)) as SumResult,` +
            `year(FinancialDate) as year,month(FinancialDate) as month&orderby=year(financialdate),month(financialdate)`;

        return this.dataService.get(query).pipe(
            catchError((err) => {
                console.error(err);
                return of([]);
            }),
        );
    }

    formatDataAndDrawChart(budgetData: any[]) {
        let chartdata = this.fillGaps(budgetData);
        this.chartConfig = this.getEmptyResultChart();
        this.currentChartData = chartdata;

        this.monthAndYearDataInBarChart = [];
        this.chartConfig.data.labels = [];
        this.chartConfig.data.datasets[0].data = [];
        this.chartConfig.data.datasets[1].data = [];
        this.chartConfig.data.datasets[2].data = [];

        let labelMonths =
            chartdata.length < 5 ? this.MONTHS : chartdata.length > 8 ? this.MONTHS_NUMERIC : this.MONTHS_SHORT;
        const labelSeperator = chartdata.length > 8 ? '-' : ' ';

        chartdata = chartdata.filter((item) => item.month >= 1);

        chartdata.forEach((element) => {
            this.chartConfig.data.labels.push(`${labelMonths[element.month - 1]}${labelSeperator}${element.year}`);
            this.chartConfig.data.datasets[0].data.push(element.SumIncome * -1);
            this.chartConfig.data.datasets[1].data.push(element.SumCost * -1);
            this.chartConfig.data.datasets[2].data.push(element.SumResult * -1);
        });

        this.loading = false;
        this.cdr.markForCheck();
    }

    initChart() {
        this.dataSubscription?.unsubscribe();
        this.dataSubscription = this.loadData().subscribe((res) => {
            this.formatDataAndDrawChart(res.Data);
        });
    }

    private fillGaps(list: any[]) {
        if (list.length === 0) {
            let currentMonth = new Date().getMonth();
            currentMonth =
                new Date().getMonth() === 1
                    ? currentMonth++
                    : new Date().getMonth() === 12
                      ? currentMonth--
                      : currentMonth;
            list.push(this.getEmptyDummies(currentMonth - 1, new Date().getFullYear()));
            list.push(this.getEmptyDummies(currentMonth, new Date().getFullYear()));
            list.push(this.getEmptyDummies(currentMonth + 1, new Date().getFullYear()));
        } else if (list.length === 1) {
            const currentMonth = list[0].month;
            if (currentMonth === 1) {
                list.unshift(this.getEmptyDummies(12, list[0].year - 1));
                list.push(this.getEmptyDummies(currentMonth + 1, list[0].year));
            } else if (currentMonth === 12) {
                list.unshift(this.getEmptyDummies(currentMonth - 1, list[0].year));
                list.push(this.getEmptyDummies(1, list[0].year + 1));
            } else {
                list.unshift(this.getEmptyDummies(currentMonth - 1, list[0].year));
                list.push(this.getEmptyDummies(currentMonth + 1, list[0].year));
            }
        } else if (list.length === 2) {
            const currentMonth = list[0].month;
            if (currentMonth === 1) {
                list.unshift(this.getEmptyDummies(12, list[0].year - 1));
            } else {
                list.unshift(this.getEmptyDummies(currentMonth - 1, list[0].year));
            }
        } else {
            let index = 1;
            let monthShouldBe: number = list[0].month === 12 ? 1 : list[0].month + 1;
            let yearShouldBe: number = list[0].year;

            let notAllFilled: boolean = true;

            let rounds = 0;

            while (notAllFilled && rounds < 15) {
                if (list[index].month !== monthShouldBe || list[index].year !== yearShouldBe) {
                    list.splice(index, 0, this.getEmptyDummies(monthShouldBe, yearShouldBe));
                }
                index++;

                if (monthShouldBe === 12) {
                    monthShouldBe = 1;
                    yearShouldBe++;
                } else {
                    monthShouldBe++;
                }

                notAllFilled = !(
                    yearShouldBe === list[list.length - 1].year && monthShouldBe === list[list.length - 1].month
                );
                rounds++;
            }
        }
        return list;
    }

    private getEmptyDummies(month: number, year: number) {
        return {
            SumAmount: 0,
            SumCost: 0,
            SumIncome: 0,
            SumResult: 0,
            month: month,
            year: year,
        };
    }

    private getEmptyResultChart() {
        return {
            type: 'bar',
            data: {
                labels: [],
                datasets: [
                    {
                        label: 'Inntekter',
                        data: [],
                        backgroundColor: this.colors[0],
                        borderWidth: 1,
                    },
                    {
                        label: 'Utgifter',
                        data: [],
                        backgroundColor: this.colors[1],
                        borderWidth: 1,
                    },
                    {
                        label: 'Resultat',
                        data: [],
                        borderColor: this.colors[2],
                        backgroundColor: '#638bb3',
                        borderWidth: 1,
                        type: 'line',
                        fill: false,
                        options: {
                            fill: false,
                        },
                    },
                ],
            },
            options: {
                legend: {
                    position: 'bottom',
                },
                layout: {
                    padding: {
                        bottom: 20,
                    },
                },
                onClick: () => {
                    const url =
                        '/accounting/accountingreports/dimension?type=1' +
                        `&id=${this.currentProject.ID}` +
                        `&number=${this.currentProject.ProjectNumber}` +
                        `&name=${this.currentProject.Name}`;

                    this.router.navigateByUrl(url);
                },
            },
        };
    }
}
