import { Component, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { take } from 'rxjs/operators';

import { NotificationService } from './notification-service';
import { NotificationsDropdown } from './notifications-dropdown/notifications-dropdown';
import { ToastService, ToastType } from '@uni-framework/uniToast/toastService';
import { SignalRService } from '@app/services/common/signal-r.service';
import { AuthService } from '@app/authService';
import { ChatBoxService } from '../chat-box/chat-box.service';
import { UniIcon } from '@uni-framework/ui/icon/uni-icon';
import { NotificationStatus } from '@uni-entities';
import { Subscription } from 'rxjs';
import { CompanyService } from '@app/services/common/companyService';
import { UniTranslationService } from '@app/services/common/translationService';

@Component({
    selector: 'notifications',
    templateUrl: './notifications.html',
    styleUrls: ['./notifications.sass'],
})
export class Notifications {
    @ViewChild(UniIcon, { static: true }) toggleRef: UniIcon;

    overlayRef: OverlayRef;
    dropdownPortal: ComponentPortal<NotificationsDropdown>;
    _lastRoleRefresh: number;

    pushSubscription: Subscription;

    constructor(
        private overlay: Overlay,
        private cdr: ChangeDetectorRef,
        public notificationService: NotificationService,
        public signalRService: SignalRService,
        private toastService: ToastService,
        private authService: AuthService,
        private router: Router,
        private companyService: CompanyService,
        private chatBoxService: ChatBoxService,
        private translationService: UniTranslationService,
    ) {
        this.initNotificationUnreadCount();
    }

    ngAfterViewInit() {
        this.dropdownPortal = new ComponentPortal(NotificationsDropdown);

        this.pushSubscription = this.signalRService.pushMessage$.subscribe((message: any) => {
            if (message && message.entityType === 'notification') {
                if (message?.cargo?.statusCode === NotificationStatus.Read) {
                    return;
                }

                const sourceEntityType = message.cargo?.sourceEntityType;
                if (sourceEntityType === 'Comment') {
                    this.handleCommentNotification(message.cargo);
                } else if (sourceEntityType === 'ExternalOffer') {
                    this.handleExternalOfferNotification(message.cargo);
                } else {
                    const entityType = message.cargo && message.cargo.entityType;
                    switch (entityType) {
                        case 'Approval':
                            this.handleApprovalNotification(message.cargo);
                            break;
                        case 'UserRole':
                            this.handleUserRoleNotificiation(message.cargo);
                            break;
                        case 'BatchInvoice':
                            this.handleBatchInvoiceNotification(message.cargo);
                            break;
                        case 'LicensePurchase':
                            this.handleLicensePurchaseNotification(message.cargo);
                            break;
                    }
                }

                // Invalidating the cache of notification service to make sure the new notifications are fetched
                this.notificationService.invalidateCache();
                this.notificationService.hasUnreadNotifications$.next(true);
                this.cdr.markForCheck();
            }
        });

        const position = this.overlay
            .position()
            .flexibleConnectedTo(this.toggleRef.elementRef.nativeElement)
            .withPositions([{ originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top' }]);

        this.overlayRef = this.overlay.create({
            positionStrategy: position,
            hasBackdrop: true,
            backdropClass: 'cdk-overlay-transparent-backdrop',
            disposeOnNavigation: true,
        });

        this.overlayRef.backdropClick().subscribe(() => this.toggle());
    }

    ngOnDestroy() {
        this.pushSubscription?.unsubscribe();
    }

    initNotificationUnreadCount() {
        this.notificationService.getLatestNotification().subscribe(
            (notification) => {
                if (notification && notification['_unread']) {
                    this.notificationService.hasUnreadNotifications$.next(true);
                }
            },
            (err) => console.error(err),
        );
    }

    private handleLicensePurchaseNotification(body) {
        this.companyService.GetAll().subscribe(
            (companies) => {
                const company = (companies || []).find((c) => c.Key === body.companyKey);
                if (!company) return;

                this.toastService.toast({
                    title: `Ny melding`,
                    message: `Selskap: ${body.companyName}<br>${body.message}`,
                    type: ToastType.info,
                    duration: 20,
                });
            },
            () => {
                /* Fail silently */
            },
        );
    }

    private handleCommentNotification(body) {
        // Chat boxes across companies doesn't work very well. Ignoring these for now.
        if (body.companyKey !== this.authService.activeCompany.Key) {
            return;
        }

        // Mostly copy paste from notification-service. ChatBoxService should probably handle this..
        const businessObject = {
            EntityID: body.entityID,
            EntityType: body.entityType,
            CompanyKey: body.companyKey,
        };

        const chatboxObjects = this.chatBoxService.businessObjects.value;
        const chatBoxExists = chatboxObjects.find((object) => {
            return (
                object.EntityID === businessObject.EntityID &&
                object.EntityType.toLowerCase() === businessObject.EntityType.toLowerCase()
            );
        });

        if (!chatBoxExists) {
            chatboxObjects.push(businessObject);
            this.chatBoxService.businessObjects.next(chatboxObjects);
        }
    }

    private handleExternalOfferNotification(body) {
        this.companyService.GetAll().subscribe(
            async (companies) => {
                const company = (companies || []).find((c) => c.Key === body.companyKey);
                if (!company) {
                    return;
                }
                const url = await this.notificationService.getExternalOfferRoute(body);
                const header = `${body.senderDisplayName} har sendt et tilbud`;
                const message = body.message;
                const action = [
                    {
                        label: 'Tilbud',
                        click: () => {
                            const companyKey = body.companyKey;
                            const activeCompanyKey = this.authService.getCompanyKey();
                            if (companyKey === activeCompanyKey) {
                                this.router.navigateByUrl(url);
                            } else {
                                this.authService.setActiveCompany(company, url);
                            }
                        },
                    },
                ];

                this.toastService.toast({
                    title: header,
                    message: `Selskap: ${body.companyName}<br>${message}`,
                    type: ToastType.info,
                    duration: 10,
                    actions: action,
                });
            },
            () => {
                /* Fail silently */
            },
        );
    }

    private handleUserRoleNotificiation(body) {
        if (this._lastRoleRefresh && new Date().getTime() - this._lastRoleRefresh < 3000) {
            return;
        }

        this._lastRoleRefresh = new Date().getTime();

        this.toastService.toast({
            title: `Tilgangsnivået ditt har blitt endret av ${body.senderDisplayName}`,
            message: 'De nye tilgangene blir tilgjengelig neste gang applikasjonen laster',
            type: ToastType.info,
            duration: 0,
            actions: [
                {
                    label: 'Last på nytt nå',
                    click: () => window.location.reload(),
                },
            ],
        });
    }

    private handleApprovalNotification(body) {
        this.companyService.GetAll().subscribe(
            (companies) => {
                const company = (companies || []).find((c) => c.Key === body.companyKey);
                if (!company) {
                    return;
                }

                let url = `/assignments/approvals?id=${body.sourceEntityID}`;
                let message = body.message;
                let entityLabel = body.sourceEntityType;

                if (body.sourceEntityType === 'SupplierInvoice') {
                    entityLabel = 'leverandørfaktura';
                    message = `Faktura: ${body.message}`;
                    const route = `/accounting/bills/${body.sourceEntityID}`;
                    if (this.authService.canActivateRoute(this.authService.currentUser, route)) {
                        url = route;
                    }
                } else if (body.sourceEntityType === 'WorkItemGroup') {
                    entityLabel = 'timeliste';
                } else if (body.sourceEntityType === 'PayrollRun') {
                    entityLabel = this.translationService.translate('ENTITIES.PayrollRun');
                }

                let header = `${body.senderDisplayName} har spurt deg om godkjenning`;
                let action;
                let toastType;

                if (body.message.includes('Rejected')) {
                    header = `${body.senderDisplayName} har avvist en ${entityLabel}`;
                    message = body.message.replace('Rejected', 'Avvist');
                    toastType = ToastType.warn;
                } else if (body.message.includes('Approved')) {
                    header = `${body.senderDisplayName} har godkjent en ${entityLabel}`;
                    message = body.message.replace('Approved', 'Godkjent');
                } else if (body.message.includes('Reassigned')) {
                    header = `${body.senderDisplayName} har tilordnet ${entityLabel} på nytt`;
                    message = body.message.replace('Reassigned', 'Omfordelt');
                } else {
                    action = [
                        {
                            label: 'Gå til godkjenning',
                            click: () => {
                                const companyKey = body.companyKey;
                                const activeCompanyKey = this.authService.getCompanyKey();
                                if (companyKey === activeCompanyKey) {
                                    this.router.navigateByUrl(url);
                                } else {
                                    this.authService.setActiveCompany(company, url);
                                }
                            },
                        },
                    ];
                }

                this.toastService.toast({
                    title: header,
                    message: `Selskap: ${body.companyName}<br>${message}`,
                    type: toastType || ToastType.info,
                    duration: 10,
                    actions: action,
                });
            },
            () => {
                /* Fail silently */
            },
        );
    }

    private handleBatchInvoiceNotification(body) {
        const messageSplit = body.message && body.message.split('\n');
        if (messageSplit && messageSplit.length) {
            this.toastService.toast({
                title: messageSplit[0],
                message: messageSplit.slice(1).join('<br>'),
                type: ToastType.info,
                duration: 10,
            });
        }
    }

    toggle() {
        if (this.overlayRef.hasAttached()) {
            this.overlayRef.detach();
            this.notificationService.markNotificationsRead();
        } else {
            const componentRef = this.overlayRef.attach(this.dropdownPortal);
            componentRef.instance.close.pipe(take(1)).subscribe(() => {
                this.toggle();
            });
        }
    }
}
