<section role="dialog" class="uni-modal">
    <header>Tildel faktura</header>

    <article>
        <section *ngIf="busy" class="modal-spinner">
            <mat-spinner class="c2a"></mat-spinner>
        </section>

        <mat-radio-group class="horizontal" [(ngModel)]="assignmentMode">
            <mat-radio-button value="user">Brukere</mat-radio-button>
            <mat-radio-button value="rule">Regel</mat-radio-button>
            <mat-radio-button value="team">Team</mat-radio-button>
        </mat-radio-group>

        <label *ngIf="assignmentMode === 'user'">
            <span>Velg brukere</span>

            <ng-select
                [(ngModel)]="userIDs"
                [items]="users"
                [multiple]="true"
                [searchFn]="userSearchFn"
                appendTo="body"
                bindValue="ID"
            >
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                    <div class="ng-value" *ngFor="let item of items | slice: 0 : 3">
                        <span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
                        <span class="ng-value-label">{{ item.DisplayName || item.Email || item.UserName }}</span>
                    </div>

                    <div class="ng-value" *ngIf="items.length > 3">
                        <span class="ng-value-label">og {{ items.length - 3 }} til</span>
                    </div>
                </ng-template>

                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                    {{ item.DisplayName || item.Email || item.UserName }}
                </ng-template>
            </ng-select>
        </label>

        <label *ngIf="assignmentMode === 'team'">
            <span>Velg team</span>

            <ng-select [(ngModel)]="teamID" [items]="teams" appendTo="body" bindLabel="Name" bindValue="ID">
            </ng-select>
        </label>

        <label *ngIf="assignmentMode === 'rule'">
            <span>Velg regel</span>

            <ng-select [(ngModel)]="approvalRule" [items]="approvalRules" appendTo="body" bindLabel="Description">
            </ng-select>

            <section *ngIf="approvalRule" class="w-100 h-100 mt-2">
                <approval-detail [item]="approvalRule['_detailFormat']"></approval-detail>
            </section>
        </label>

        <label>
            <span>Kommentar</span>
            <textarea [(ngModel)]="message"></textarea>
        </label>

        <small class="validation-message" *ngIf="validationMessage">{{ validationMessage }}</small>
    </article>

    <footer>
        <button class="secondary" (click)="onClose.emit()">Avbryt</button>
        <button class="c2a" (click)="submit()">Tildel</button>
    </footer>
</section>
