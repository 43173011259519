<section class="uni-modal">
    <header>Utsending av {{ isSingleReminder ? 'purring' : reminderIDs.length + ' purringer' }}</header>

    <article *ngIf="busy">
        <section class="modal-spinner">
            <mat-spinner class="c2a"></mat-spinner>
        </section>
    </article>

    <article *ngIf="!busy">
        <section *ngIf="alreadySentWarning" class="alert warn">
            <i class="material-icons color-warn">warning</i>
            <span>{{ alreadySentWarning }}</span>
        </section>

        <!-- Ledger value is less then rest amount on invoice -->
        <section *ngIf="!hasPaymentsMatched">
            <section class="alert warn">
                <i class="material-icons">warning</i>
                <span>
                    Det er purringer i utvalget hvor kundereskontro ikke matcher utestående fakturabeløp. Det kan være
                    du har mottatt betalinger som ikke er lukket mot faktura.
                </span>
            </section>
        </section>

        <!-- Has distribution plan -->
        <section *ngIf="hasDistributionPlan">
            <section *ngIf="canDistributeAllRemindersUsingPlan" class="alert good">
                <i class="material-icons">check_circle</i>
                <span
                    >{{ isSingleReminder ? 'Purringen' : 'Alle purringene' }} kan sendes med aktiv utsendingsplan</span
                >
            </section>

            <ng-container *ngIf="!canDistributeAllRemindersUsingPlan">
                <!-- Might not be able to send all using plan -->
                <p *ngIf="canDistributeAllRemindersUsingPlan === null">
                    Purringene vil bli sendt etter aktiv utsendingsplan.<br /><br />
                    Dersom en purring mangler nødvendig informasjon for å sende med valgt utsendingsplan vil følgende
                    prioriteringsliste benyttes:
                </p>

                <!-- Unable to send all using plan -->
                <section *ngIf="canDistributeAllRemindersUsingPlan !== null">
                    <section class="alert warn">
                        <i class="material-icons color-warn">warning</i>
                        <span
                            >{{ isSingleReminder ? 'Purringen' : 'En eller flere purringer' }} mangler nødvendig
                            informasjon for aktiv utsendingsplan.</span
                        >
                    </section>

                    <p>Purringer uten nødvendig informasjon sendes etter følgende prioriteringsliste:</p>
                </section>
            </ng-container>
        </section>

        <!-- No distribution plan -->
        <section *ngIf="!hasDistributionPlan">
            <section class="alert warn">
                <i class="material-icons">warning</i>
                <span>
                    Det er ikke satt opp noen
                    <a routerLink="/settings/distribution" (click)="onClose.emit()">utsendelsesplan</a>
                    for purringer
                </span>
            </section>

            <p>{{ isSingleReminder ? 'Purringen' : 'Purringene' }} vil bli sendt etter følgende prioriteringsliste:</p>
        </section>

        <!-- Distribution options -->
        <section class="distribution-options" *ngIf="!canDistributeAllRemindersUsingPlan">
            <rig-checkbox
                [(ngModel)]="sendByEmail"
                [disabled]="sendByEmailDisabled"
                matTooltip="Ikke mulig. Sannsynligvis fordi e-post adresse mangler på purringene"
                [matTooltipDisabled]="!sendByEmailDisabled"
            >
                Send til e-post registrert på purringen
                <i *ngIf="sendByEmailDisabled" class="material-icons option-unavailable">error</i>
            </rig-checkbox>

            <rig-checkbox
                [(ngModel)]="sendByPrint"
                [disabled]="sendByPrintDisabled"
                matTooltip="Ikke mulig. Sannsynligvis fordi adresse mangler på purringene, eller fordi utvidelsen fakturaprint ikke er aktivert på selskapet"
                [matTooltipDisabled]="!sendByPrintDisabled"
            >
                Send med Fakturaprint til kundes post adresse (transaksjonskostnad)
                <i *ngIf="sendByPrintDisabled" class="material-icons option-unavailable">error</i>
            </rig-checkbox>

            <rig-checkbox [(ngModel)]="sendToCaseHandler">
                Send til <strong>{{ caseHandlerEmail }}</strong> for manuell behandling
            </rig-checkbox>

            <p>Dersom flere alternativer er avkrysset vil første mulige benyttes.</p>
        </section>
    </article>

    <footer *ngIf="!busy">
        <button class="secondary" (click)="onClose.emit()">{{ options?.buttonLabels?.cancel || 'Avbryt' }}</button>
        <button class="c2a" (click)="send()">Send {{ isSingleReminder ? 'purring' : 'purringer' }}</button>
    </footer>
</section>
