import { Component, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { CustomerInvoiceService } from '@app/services/sales/customerInvoiceService';
import { Subscription } from 'rxjs';
import { theme } from 'src/themes/theme';

@Component({
    selector: 'unpaid-per-customer',
    templateUrl: './unpaid-per-customer.html',
    styleUrls: ['./unpaid-per-customer.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnpaidPerCustomer {
    loading = true;
    data;
    chartConfig;

    dataSubscription: Subscription;

    constructor(
        private cdr: ChangeDetectorRef,
        private invoiceService: CustomerInvoiceService,
    ) {}

    ngOnInit() {
        this.dataSubscription = this.invoiceService.getUnpaidPerCustomerWidgetData().subscribe(
            ([customerSums, totalUnpaid]) => {
                const hasData = customerSums?.some((customer) => !!customer.RestAmount);
                if (hasData) {
                    let rest = totalUnpaid[0]?.RestAmount || 0;

                    customerSums.forEach((customer) => {
                        rest -= customer.RestAmount || 0;
                    });

                    if (rest > 0) {
                        customerSums.push({
                            Name: 'Resterende',
                            RestAmount: rest,
                        });
                    }

                    this.data = customerSums;
                    this.chartConfig = this.getChartConfig();
                }

                this.loading = false;
                this.cdr.markForCheck();
            },
            (err) => {
                console.error(err);
                this.loading = false;
                this.cdr.markForCheck();
            },
        );
    }

    ngOnDestroy() {
        this.dataSubscription?.unsubscribe();
    }

    private getChartConfig() {
        return {
            type: 'pie',
            data: {
                labels: this.data.map((item) => item.Name),
                datasets: [
                    {
                        data: this.data.map((item) => item.RestAmount),
                        backgroundColor: theme.widgets.pie_colors,
                        borderColor: '#fff',
                        hoverBorderColor: '#fff',
                    },
                ],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                animation: {
                    animateScale: true,
                },
                elements: {
                    arc: { borderWidth: 2 },
                },
            },
        };
    }
}
