<uni-icon [icon]="'user'" [matMenuTriggerFor]="userMenu" matTooltip="Min profil"></uni-icon>

<mat-menu #userMenu="matMenu" yPosition="below" [overlapTrigger]="false">
    <ng-template matMenuContent>
        <section class="navbar-user-dropdown" (click)="$event.stopPropagation()">
            <section class="info-section">
                <h2>{{ user?.DisplayName }}</h2>
            </section>

            <section class="info-section">
                <section class="info-field" *ngIf="user?.PhoneNumber">
                    <span>Telefon:</span>
                    <span>{{ user?.PhoneNumber }}</span>
                </section>

                <section class="info-field">
                    <span>Epost:</span>
                    <span>{{ user?.Email }}</span>
                </section>
            </section>

            <section class="info-section">
                <section class="info-field">
                    <span>Lisens:</span>
                    <span>{{ contractType }}</span>
                </section>

                <section class="info-field" *ngIf="!isExt02">
                    <span>Brukertype:</span>
                    <span>{{ userLicenseType }}</span>
                </section>
            </section>

            <a routerLink="license-info" (click)="userMenu.close.emit()">
                <i class="material-icons license-info">link</i>
                Lisensinformasjon
            </a>

            <a routerLink="settings/user" (click)="userMenu.close.emit()">
                <i class="material-icons">settings</i>
                Brukerinnstillinger
            </a>

            <a style="margin-bottom: 2rem" (click)="openUserNotificationSettings()">
                <i class="material-icons">notifications</i>
                Varslingsinnstillinger
            </a>

            <a (click)="logOut()">
                <i class="material-icons">exit_to_app</i>
                Logg ut
            </a>
        </section>
    </ng-template>
</mat-menu>
