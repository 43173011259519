<section role="dialog" class="uni-modal">
    <header>Registrer fravær</header>

    <article>
        <section *ngIf="busy" class="modal-spinner">
            <mat-spinner class="c2a"></mat-spinner>
        </section>
        <section class="alert warn" *ngIf="showWarning">
            <i class="material-icons">info</i>
            <section>
                <span
                    >Kunne ikke lagre fordi noen linjer ikke validerte. Disse linjene er markert under.
                    <strong><a (click)="showErrors = !showErrors">Se grunnlag for valideringsfeil</a> </strong>
                </span>
                <section class="error-listing" [class.expanded]="showErrors">
                    <span class="flex mt-1" *ngFor="let error of errors">
                        <strong>Linje {{ error.InputIndex + 1 }}: {{ error.text }}</strong>
                    </span>
                </section>
            </section>
        </section>
        <ag-grid-wrapper
            class="borders leave-array"
            [config]="tableConfig"
            [(resource)]="employeeLeaveArray"
            (rowChange)="handleChange()"
        >
        </ag-grid-wrapper>
    </article>

    <footer>
        <button class="secondary pull-left" (click)="onClose.emit(false)">Avbryt</button>
        <button class="secondary" (click)="import()">Import fra Excel</button>

        <button class="c2a" (click)="save()">Bekreft</button>
    </footer>
</section>
