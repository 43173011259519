<section role="dialog" class="uni-modal">
    <header>Historikk på AGA sone endringer</header>
    <article class="modal-content">
        <simple-table *ngIf="subEntityHistoricAga.length">
            <thead>
                <tr>
                    <th>Aga sone</th>
                    <th>Beregningsregel aga</th>
                    <th>Gyldig til</th>
                    <th>&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of subEntityHistoricAga">
                    <td>{{ item.AgaZoneName }}</td>
                    <td>{{ item.SectorName }}</td>
                    <td>{{ item.UsedUntil | date: 'dd.MM.yyyy' }}</td>
                    <td>
                        <button class="icon-button" (click)="deleteHistoricAga(item.ID)">
                            <i class="material-icons-outlined">delete</i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </simple-table>
        <section *ngIf="!subEntityHistoricAga?.length">Fant ingen historikk</section>
    </article>
    <footer>
        <button (click)="close()" class="c2a">Ok</button>
    </footer>
</section>
