<section class="uni-modal">
    <header></header>
    <article>
        <section class="image-wrapper">
            <uni-illustration name="documentation" />
        </section>
        <section>
            @if (isSrEnvironment || isUnimicro) {
                @if (journalEntriesCount < maxJournalEntries) {
                    <p class="title">
                        Du har nå bokført {{ journalEntriesCount }} av {{ maxJournalEntries }} bilag, som er maksimalt
                        antall i pakken {{ currentContractType }}.
                    </p>

                    <p>
                        Når du har bokført {{ maxJournalEntries }} bilag oppgraderes du automatisk til pakken
                        {{ targetContractType }}
                    </p>
                }

                @if (journalEntriesCount >= maxJournalEntries) {
                    <p class="title">
                        Du har nådd grensen på maksimalt {{ maxJournalEntries }} bilag i pakken
                        {{ currentContractType }}.
                    </p>

                    <p>
                        Du vil automatisk bli oppgradert til pakken {{ targetContractType }} fra og med i morgen, men du
                        kan allerede nå bokføre ubegrenset antall bilag uten ekstra kostnad.
                    </p>
                }
            }

            @if (isDnbEnvironment) {
                @if (
                    currentContractType?.toLowerCase() === 'holding' || currentContractType?.toLowerCase() === 'level5'
                ) {
                    <p class="title">
                        Du har nå bokført {{ journalEntriesCount }} av {{ maxJournalEntries }} bilag som er inkludert i
                        {{ currentContractType }}-pakken
                    </p>
                    <p>
                        Det koster 25 kr pr bilag over {{ maxJournalEntries }} bilag. <br /><br />Du kan oppgradere
                        pakken i Markedsplassen, <br />men da faktureres selskapet månedlig fra datoen det ble
                        oppgradert
                    </p>
                } @else if (journalEntriesCount < maxJournalEntries) {
                    <p class="title">
                        Du har nå bokført {{ journalEntriesCount }} av {{ maxJournalEntries }} bilag som er inkludert i
                        {{ currentContractType }}-pakken
                    </p>

                    <p>
                        Etter {{ maxJournalEntries }} bilag vil hvert bilag koste 25 kr. <br />Det vil lønne seg for deg
                        å oppgradere til {{ targetContractType || 'Pluss' }}. <br /><br />Du kan oppgradere allerede nå
                        og få tilgang til en rekke andre/nye funksjoner i DNB Regnskap. <br /><br />NB: Hvis du er i
                        gratisperiode blir du ikke belastet.
                    </p>
                } @else {
                    @if (openedFromNavbar) {
                        <p class="title">
                            Du har nå nådd grensen på {{ maxJournalEntries }} bilag inkluderte i
                            {{ currentContractType }}-pakken
                        </p>
                        <p>
                            Fra nå vil hvert bilag koste 25 kr.
                            <br />Det vil lønne seg for deg å oppgradere til {{ targetContractType || 'Pluss' }}.
                            <br /><br />NB: Hvis du er i gratisperiode blir du ikke belastet.
                        </p>
                    } @else {
                        <p class="title">
                            Du har pakken {{ currentContractType }} som inkluderer {{ maxJournalEntries }} bilag per år,
                            deretter koster det 25 kr per bilag
                        </p>
                        <p>
                            Ønsker du å oppgradere pakken og få tilgang til flere funksjoner i DNB Regnskap kan du gjøre
                            det her ved å klikke på Les mer, og velge hvilken pakke du vil oppgradere til
                        </p>
                    }
                }
            }

            @if (!openedFromNavbar) {
                <rig-checkbox [(ngModel)]="dontShowAgain">Ikke vis denne igjen</rig-checkbox>
            }
        </section>
    </article>

    <footer class="center">
        <button (click)="onClose.emit()" class="secondary">
            {{ isDnbEnvironment ? 'Nei takk' : 'Lukk' }}
        </button>

        @if (isDnbEnvironment) {
            <button (click)="navigateToMarketplace()" class="c2a">
                {{ journalEntriesCount >= maxJournalEntries && openedFromNavbar ? 'Les mer' : 'Oppgrader' }}
            </button>
        }
    </footer>
</section>
