<section class="header">
    <h1>10 største kunder</h1>

    <section class="pull-right flex">
        <rig-switch *ngIf="!loading && data?.length" [(ngModel)]="includeMva" labelPosition="before" class="mr-2">
            Inkludert mva
        </rig-switch>

        <button #toggle class="secondary">
            {{ year }}
            <i class="material-icons">expand_more</i>
        </button>
    </section>
    <dropdown-menu [trigger]="toggle">
        <ng-template>
            <section class="dropdown-menu-item center" *ngFor="let year of years" (click)="onYearSelected(year)">
                {{ year }}
            </section>
        </ng-template>
    </dropdown-menu>
</section>

<section class="content">
    <widget-empty-state *ngIf="!loading && !data?.length">
        Det ser ikke ut som du har noen aktive kunder.
    </widget-empty-state>
    <simple-table *ngIf="!loading && data?.length" class="selectable">
        <thead>
            <tr>
                <th>Kunde</th>
                <th class="align-right">Fakturert {{ year }}</th>
                <th class="align-right">Fakturert {{ year - 1 }}</th>
                <th class="align-right">{{ includeMva ? 'Utestående' : '' }}</th>
            </tr>
        </thead>

        <tbody>
            <tr *ngFor="let item of data" (click)="goToCustomer(item)">
                <td>{{ item.CustomerNumber }} - {{ item.Name }}</td>
                <td class="align-right">
                    {{ (includeMva ? item.SumThisYear : item.SumThisYearWithoutMva) | uninumberformat: 'money' }}
                </td>
                <td class="align-right">
                    {{ (includeMva ? item.SumLastYear : item.SumLastYearWithoutMva) | uninumberformat: 'money' }}
                </td>
                <td class="align-right" *ngIf="includeMva">{{ item.SumUnpaid | uninumberformat: 'money' }}</td>
                <td *ngIf="!includeMva"></td>
            </tr>
        </tbody>
    </simple-table>
</section>
