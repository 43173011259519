<section role="dialog" class="uni-modal" style="width: 33.5rem">
    <header>Prisoversikt</header>

    <article>
        <step-price-table [product]="product"></step-price-table>
    </article>

    <footer>
        <button class="secondary" (click)="onClose.emit()">Lukk</button>
    </footer>
</section>
