<section role="dialog" class="uni-modal">
    <header>Betingelser for Import</header>
    <article>
        <p>
            Ved benyttelse av verktøyet for import av data aksepterer brukeren på vegne av lisenseier at dette er på
            eget ansvar. Systemleverandør er ikke ansvarlig for eventuelle økonomiske tap, direkte eller indirekte, som
            følge av feil, tap av data eller mangler i programmet.
        </p>

        <p>
            Feil og mangler i verktøyet, samt eventuelle feil som oppstår i etterkant av bruk av programmet, er ikke
            omfattet av supportavtalen. Hvis du som kunde trenger hjelp til rydde opp etter en import er dette en
            tjeneste som vil bli belastet per time etter medgått tid fra systemleverandør sin side.
        </p>

        <p>Systemleverandørens kundekonsulenter kan bistå ved import av data.</p>

        <p *ngIf="!isBank">
            <a href="https://hjelp.unimicro.no/konsulent" target="_blank">Bestill konsulenttime her</a>
        </p>

        <div [attr.aria-busy]="loading">
            <rig-checkbox [(ngModel)]="termsAccepted" *ngIf="!hasAgreed">
                Jeg godtar vilkår og betingelser
            </rig-checkbox>

            <div class="alert good" style="margin-top: 1rem" *ngIf="hasAgreed">
                Disse vilkårene og betingelsene ble godtatt av deg den {{ agreedDate }}
            </div>
        </div>
    </article>

    <footer>
        <button class="secondary" (click)="onClose.emit()" [disabled]="saving">Lukk</button>
        <button class="c2a" *ngIf="!hasAgreed" (click)="save()" [disabled]="!termsAccepted" [attr.aria-busy]="saving">
            Bekreft
        </button>
    </footer>
</section>
