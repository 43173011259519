import { Component, Input } from '@angular/core';
import { StartupTaskService, StartupTask } from '../startup-task-service';
import { FirstInvoiceWizardModal } from '@app/components/common/modals/first-invoice-wizard/first-invoice-wizard';
import { UniModalService } from '@uni-framework/uni-modal';
import { CompanySettings } from '@uni-entities';
import { ToastService, ToastType } from '@uni-framework/uniToast/toastService';
import { ErrorService } from '@app/services/common/errorService';
import { NumberSeriesService } from '@app/services/common/numberSeriesService';
import { BrowserStorageService } from '@uni-framework/core/browserStorageService';

@Component({
    selector: 'start-invoicing-task',
    template: `<startup-task [task]="task" (tileClicked)="runTask()"> </startup-task>`,
})
export class StartInvoingTask {
    @Input()
    public task: StartupTask;

    @Input()
    public companySettings: CompanySettings;

    numberSeries = [];

    constructor(
        private service: StartupTaskService,
        private modalService: UniModalService,
        private toastService: ToastService,
        private errorService: ErrorService,
        private browserStorage: BrowserStorageService,
        private numberSeriesService: NumberSeriesService,
    ) {}

    ngOnInit() {
        this.numberSeriesService.getNamedNumberSeries('Customer Invoice number series').subscribe({
            next: (numberSeries) => {
                this.numberSeries = numberSeries;
            },
            error: (err) => this.errorService.handle(err),
        });
    }

    runTask() {
        const defaultNumberSeriesForInvoice =
            this.numberSeries.find((ns) => ns.IsDefaultForTask) || this.numberSeries[0];

        if (!defaultNumberSeriesForInvoice) {
            this.toastService.addToast(
                'Mangler aktiv nummerserie for faktura',
                ToastType.warn,
                8,
                'Du kan ikke fakturere uten en aktiv nummerserie for faktura. Gå til nummerserier for å sette opp ny standard nummerserie',
            );
            return;
        }

        this.modalService
            .open(FirstInvoiceWizardModal, {
                data: {
                    companySettings: this.companySettings,
                    numberSerie: defaultNumberSeriesForInvoice,
                    modalService: this.modalService,
                },
                closeOnClickOutside: false,
            })
            .onClose.subscribe({
                next: (response) => {
                    if (response) {
                        this.service.markTaskAsCompleted(this.task);
                        this.browserStorage.setItemOnCompany('INVOICE_WIZARD_COMPLETED', 'true');
                    }
                },
            });
    }
}
