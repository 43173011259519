import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ConfirmActions, IModalOptions, IUniModal, UniModalService } from '@uni-framework/uni-modal';
import { CommonModule } from '@angular/common';
import { FieldType, UniFieldLayout, UniFormModule } from '@uni-framework/ui/uniform';
import { UniFrameworkModule } from '@uni-framework/frameworkModule';
import { CompanySalary, CompanyVacationRate, LocalDate } from '@uni-entities';
import { Observable, forkJoin, map, of, switchMap } from 'rxjs';
import { ToastService, ToastTime, ToastType } from '@uni-framework/uniToast/toastService';
import { CompanySalaryService } from '@app/services/salary/companySalary/companySalaryService';
import { rigDate } from '@app/components/common/utils/rig-date';
import { AccountService } from '@app/services/accounting/accountService';
import { FinancialYearService } from '@app/services/accounting/financialYearService';
import { ErrorService } from '@app/services/common/errorService';
import {
    IVacationPaySettings,
    CompanyVacationRateService,
} from '@app/services/salary/companySalary/companyVacationRateService';
import { VacationpayLineService } from '@app/services/salary/payrollRun/vacationpayLineService';
import { UniTableConfig, UniTableColumn, UniTableColumnType } from '@uni-framework/ui/unitable';

@Component({
    selector: 'uni-vacation-pay-settings-modal',
    templateUrl: './vacation-pay-settings-modal.component.html',
    styleUrls: ['./vacation-pay-settings-modal.component.sass'],
    standalone: true,
    imports: [CommonModule, UniFormModule, UniFrameworkModule],
})
export class VacationPaySettingsModal implements IUniModal, OnInit {
    @Input() options: IModalOptions;
    @Output() onClose: EventEmitter<any[]> = new EventEmitter();

    isBusy: boolean;
    isDirty: boolean;

    companySalary: CompanySalary;
    companyVacationRates: CompanyVacationRate[] = [];
    companyVacationRatesTableConfig: UniTableConfig;
    advancedSettingsFields: UniFieldLayout[] = [];
    vacationPaySettings: IVacationPaySettings;

    wageDeductionDueToHolidayList: any[] = [];

    private activeYear: number;
    private currentCompanyVacationRate: CompanyVacationRate;

    constructor(
        private companySalaryService: CompanySalaryService,
        private companyVacationRateService: CompanyVacationRateService,
        private accountService: AccountService,
        private financialYearService: FinancialYearService,
        private modalService: UniModalService,
        private toastService: ToastService,
        private vacationPayLineService: VacationpayLineService,
        private errorService: ErrorService,
    ) {}

    ngOnInit(): void {
        this.isBusy = true;
        this.activeYear = this.financialYearService.getActiveYear();

        forkJoin([
            this.companySalaryService.getCompanySalary(),
            this.companyVacationRateService.GetAll('orderby=FromDate desc'),
            this.companyVacationRateService.getCurrentRates(this.activeYear),
        ])
            .subscribe({
                next: (response: any) => {
                    const [companysalary, companyvacationrates, currentcompanyvacationrate] = response;
                    this.companySalary = companysalary;
                    this.companyVacationRates = companyvacationrates;
                    this.currentCompanyVacationRate = currentcompanyvacationrate;

                    this.wageDeductionDueToHolidayList = this.vacationPayLineService.getWageDeductionDueToHoliday(
                        this.companySalary.HasTwoLineVacationPay,
                    );
                    this.vacationPaySettings = {
                        rate: this.currentCompanyVacationRate.Rate,
                        rate60: this.currentCompanyVacationRate.Rate60,
                        wageDeductionDueToHoliday: companysalary.WageDeductionDueToHoliday,
                    };
                    this.setTableConfig();
                    this.setLayout();
                },
            })
            .add(() => {
                this.isBusy = false;
            });
    }

    close() {
        this.onClose.emit();
    }

    save() {
        const saveObs: Observable<any>[] = [];
        saveObs.push(
            this.companySalary['_isDirty']
                ? this.companySalaryService.Put(this.companySalary.ID, this.companySalary)
                : of(this.companySalary),
        );

        this.companyVacationRates
            .filter((rate) => rate['_isDirty'] && !rate['_isEmpty'])
            .forEach((vacationRate) => {
                if (vacationRate.ID > 0) {
                    saveObs.push(this.companyVacationRateService.Put(vacationRate.ID, vacationRate));
                } else {
                    saveObs.push(this.companyVacationRateService.Post(vacationRate));
                }
            });

        forkJoin(saveObs).subscribe({
            next: () => {
                this.toastService.addToast('Lagret', ToastType.info, ToastTime.medium);
                this.onClose.emit([this.vacationPaySettings, this.companySalary]);
            },
            error: (error) => {
                this.errorService.handle(error);
            },
        });
    }

    setTableConfig() {
        const rateCol = new UniTableColumn('Rate', 'Feriepengesats', UniTableColumnType.Percent);
        const rate60Col = new UniTableColumn('Rate60', 'Tilleggssats over 60 år', UniTableColumnType.Percent);
        const dateCol = new UniTableColumn(
            'FromDate',
            'Gjelder fra opptjeningsår',
            UniTableColumnType.Text,
        ).setTemplate((rowModel) => {
            return rowModel.FromDate ? rigDate(rowModel.FromDate).format('YYYY') : '';
        });

        this.companyVacationRatesTableConfig = new UniTableConfig('salary.settings.companyVacationRates', true)
            .setColumns([rateCol, rate60Col, dateCol])
            .setSortable(false)
            .setPageable(this.companyVacationRates.length > 10)
            .setCopyFromCellAbove(false)
            .setDeleteButton(true)
            .setChangeCallback((event) => {
                const row = event.rowModel;
                if (event.field === 'FromDate') {
                    row.FromDate = row.FromDate
                        ? new LocalDate(rigDate(row.FromDate).format('YYYY') + '-01-01')
                        : new LocalDate(this.activeYear - 1 + '-01-01');
                    if (
                        this.companyVacationRates.some(
                            (x) =>
                                rigDate(x.FromDate).format('YYYY') === rigDate(row.FromDate).format('YYYY') &&
                                x.ID !== row.ID,
                        )
                    ) {
                        this.toastService.addToast(
                            'Like år',
                            ToastType.bad,
                            ToastTime.medium,
                            `Sats for år ${rigDate(row.FromDate).format('YYYY')} finnes fra før`,
                        );
                    }
                }
                if (event.field === 'Rate60') {
                    row.Rate60 = row.Rate60 ? row.Rate60 : this.currentCompanyVacationRate.Rate60;
                }
                if (event.field === 'Rate') {
                    row.Rate = row.Rate ? row.Rate : this.currentCompanyVacationRate.Rate;
                }
                return row;
            });
    }

    vacationPayAdvancedSettingsChange(event) {
        if (event['HasTwoLineVacationPay']) {
            this.wageDeductionDueToHolidayList = this.vacationPayLineService.getWageDeductionDueToHoliday(
                event.HasTwoLineVacationPay.currentValue,
            );
            this.setLayout();
        }
        if (event['WageDeductionDueToHoliday']) {
            this.vacationPaySettings.wageDeductionDueToHoliday = this.companySalary.WageDeductionDueToHoliday;
        }

        this.companySalary['_isDirty'] = true;
        this.isDirty = true;
    }

    onRowChanged(rowModel: CompanyVacationRate) {
        this.isDirty = true;
    }

    onRowDeleted(rowModel: CompanyVacationRate) {
        if (rowModel['_isEmpty']) {
            return;
        }
        if (isNaN(rowModel.ID)) {
            return;
        }

        this.modalService
            .confirm({
                header: 'Slette sats',
                message: `Er du sikker på at du vil slette sats for år ${rigDate(rowModel.FromDate).format('YYYY')}`,
                buttonLabels: {
                    accept: 'Ja, slett sats',
                    reject: 'Nei, behold sats',
                },
            })
            .onClose.pipe(
                switchMap((result: ConfirmActions) => {
                    if (result === ConfirmActions.ACCEPT) {
                        return this.companyVacationRateService.Remove(rowModel.ID).pipe(map((result) => result.ok));
                    }
                    return of(false);
                }),
                switchMap((removed) => {
                    let rates = this.companyVacationRates;
                    if (removed) {
                        rates = rates.filter((obj) => obj.ID !== rowModel.ID);
                    } else {
                        rates = this.companyVacationRates.map((item) => {
                            if (item.ID === rowModel.ID) {
                                item.Deleted = false;
                                item['_isDirty'] = false;
                            }
                            return item;
                        });
                    }
                    return of(rates);
                }),
            )
            .subscribe((result: CompanyVacationRate[]) => {
                this.companyVacationRates = result;
                this.setTableConfig();
            });
    }

    private setLayout() {
        this.advancedSettingsFields = [
            <UniFieldLayout>{
                Label: 'Trekk i fastlønn for ferie føres som',
                Property: 'HasTwoLineVacationPay',
                FieldType: FieldType.DROPDOWN,
                Tooltip: {
                    Text: 'Dette valget påvirker hvordan feriepenger blir ført i lønnsavregningen og hvordan det rapporteres via a-meldingen. Ved å velge at trekk i fastlønn som en post vil alle ansatte med fast månedslønn få trekk i lønn på lønnsavregning, samt all lønn rapporteres med beskrivelse for “Trekk i lønn for ferie” i a-meldingen.',
                },
                Options: {
                    hideDeleteButton: true,
                    searchable: false,
                    valueProperty: 'value',
                    displayProperty: 'label',
                    source: [
                        { label: 'Månedslønn og trekk for alle feriedager (2 poster)', value: true },
                        { label: 'Sum av månedslønn minus trekk for feriedager (1 post)', value: false },
                    ],
                },
                Classes: 'full-width',
            },
            <UniFieldLayout>{
                Label: 'Beregning av trekk i fastlønn for ferie',
                Property: 'WageDeductionDueToHoliday',
                FieldType: FieldType.DROPDOWN,
                Options: {
                    source: this.wageDeductionDueToHolidayList,
                    valueProperty: 'id',
                    template: (item) => item.name,
                    subTextTemplate: (item) => item.description,
                    searchable: false,
                    hideDeleteButton: true,
                },
                Tooltip: {
                    Text: 'Ferielovens minstekrav er trekk ved beregningen 25/26 (1/26), mens tariffestet trekk beregnes etter 30/26 (-4/26). I følge arbeidsmiljøloven er lørdag en del av arbeidsuken. Dersom selskapet er unntatt fra 6 dagers arbeidsuke benyttes beregningene der 5 dagers arbeidsuke er oppgitt som grunnlag.',
                },
            },

            <UniFieldLayout>{
                Label: 'Kostnadskonto for feriepenger',
                Property: 'MainAccountCostVacation',
                FieldType: FieldType.AUTOCOMPLETE,
                Options: this.accountService.getSearchConfigUniForm('AccountNumber'),
                Classes: 'half-width',
                Tooltip: {
                    Text: 'Når lønn blir utbetalt blir feriepenger kostnadsført på denne hovedbokskontoen i regnskapet.',
                },
            },
            <UniFieldLayout>{
                Label: 'Konto for avsatt feriepenger',
                Property: 'MainAccountAllocatedVacation',
                FieldType: FieldType.AUTOCOMPLETE,
                Options: this.accountService.getSearchConfigUniForm('AccountNumber'),
                Classes: 'half-width',
                Tooltip: {
                    Text: 'Feriepenger opptjenes året før de utbetales. Derfor brukes en hovedbokskonto for å avsatte feriepenger i regnskapet.',
                },
            },
            <UniFieldLayout>{
                Label: 'Ignorer grunnbeløp',
                Property: 'AllowOver6G',
                FieldType: FieldType.CHECKBOX,
                Tooltip: {
                    Text: 'Dette vil kun påvirke ansatte over 60 år. Ved å sette hake vil systemet beregne tilleggssats på hele feriepengegrunnlaget, og ikke bare opp til 6G slik lovens minstekrav tilsier.',
                },
            },
        ];
    }
}
