<section role="dialog" class="uni-modal">
    <header>Altinn systempålogging</header>

    <article>
        <section *ngIf="busy" class="modal-spinner">
            <mat-spinner class="c2a"></mat-spinner>
        </section>

        <altinn-settings-component
            [hideCheckButton]="true"
            (dataLoaded)="onDataLoaded()"
            (checkValidated)="onCheckValidated($event)"
            #altinnComponent
        ></altinn-settings-component>

        <a href="https://www.altinn.no/ui/profile" target="_blank">
            Har du ikke ID eller er usikker på om systemet er satt opp mot Altinn, trykk her
        </a>
    </article>

    <footer class="center">
        <button (click)="onClose.emit(false)" class="secondary">Lukk</button>
        <button *ngIf="altinnComponent?.isDirty" class="c2a" (click)="saveAltinnSettings()">Lagre innstillinger</button>
        <button *ngIf="!altinnComponent?.isDirty" [disabled]="!canCheckedLogin" class="c2a" (click)="check()">
            Valider innlogging
        </button>
    </footer>
</section>
