import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'redirect-to-unimicro',
    templateUrl: './redirect-to-unimicro.html',
    styleUrls: ['./redirect-to-unimicro.sass'],
})
export class RedirectToUnimicro implements OnInit {
    secondsRemaining = 9;
    unimicroUrl =
        location.host === 'unieconomy.no'
            ? 'https://app.unimicro.no'
            : `https://${location.host.replace('unieconomy', 'unimicro')}`;

    hasSeenThisPage = localStorage.getItem('redirect-to-unimicro-seen');

    constructor() {}

    ngOnInit() {
        if (this.hasSeenThisPage) {
            window.location.href = this.unimicroUrl;
        }
        localStorage.setItem('redirect-to-unimicro-seen', 'true');

        const timer = setInterval(() => {
            this.secondsRemaining--;
            if (this.secondsRemaining <= 0) {
                clearInterval(timer);
                window.location.href = this.unimicroUrl;
            }
        }, 1000);
    }
}
