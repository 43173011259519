import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CurrencyCode } from '@uni-entities';
import { UniHttp } from '@uni-framework/core/http/http';
import { BizHttp } from '@uni-framework/core/http';

@Injectable({ providedIn: 'root' })
export class CurrencyCodeService extends BizHttp<CurrencyCode> {
    private codes: CurrencyCode[];
    relativeURL = 'currencycodes';

    constructor(protected http: UniHttp) {
        super(http);
    }

    getAll(query?: string): Observable<CurrencyCode[]> {
        if (this.codes?.length) {
            return of(this.codes);
        } else {
            return this.http
                .asGET()
                .usingBusinessDomain()
                .withEndPoint('currencycodes' + (query ? `?${query}` : ''))
                .send()
                .pipe(
                    map((res) => res.body),
                    tap((codes) => (this.codes = codes)),
                );
        }
    }

    get(ID: number): Observable<CurrencyCode> {
        if (!ID) return of(new CurrencyCode());
        const code = this.codes?.find((c) => c.ID === ID);

        if (code) {
            return of(code);
        } else {
            return this.http
                .asGET()
                .usingBusinessDomain()
                .withEndPoint(`currencycodes/${ID}`)
                .send()
                .pipe(map((res) => res.body));
        }
    }
}
