<span *ngIf="recommended" class="recommended-tag">Mest for pengene</span>

<section *ngIf="product?.Price" class="price">
    <strong>{{ product.Price }}</strong>
    kr/mnd
</section>

<section class="description">
    <h1>{{ contractType.Label }}</h1>
    <p>{{ product?.Description }}</p>
</section>

<ul *ngIf="bulletPoints?.length" [ngStyle]="{ 'grid-template-rows': cssGridTemplateRows }">
    <li *ngFor="let bulletPoint of bulletPoints">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M23.827 1.672a.75.75 0 00-1.152-.96L10.412 15.43a.984.984 0 01-1.557-.058l-3.743-5.24a.75.75 0 10-1.22.872l3.743 5.24a2.484 2.484 0 003.93.146L23.826 1.672zM1.5 12.802a9.214 9.214 0 019.255-9.255c1.454 0 2.894.312 4.112.921a.75.75 0 10.67-1.341c-1.45-.725-3.123-1.08-4.782-1.08A10.714 10.714 0 000 12.802a10.714 10.714 0 0010.755 10.756A10.714 10.714 0 0021.51 12.802c0-.842-.12-1.671-.233-2.346a.75.75 0 10-1.48.246c.11.659.213 1.387.213 2.1a9.214 9.214 0 01-9.256 9.256A9.214 9.214 0 011.5 12.802z"
                />
            </g>
            <defs />
        </svg>
        {{ bulletPoint }}
    </li>
</ul>

<section class="footer">
    <div [matTooltip]="disabledReason" matTooltipClass="tooltip-linebreak">
        <button
            (click)="selected.emit()"
            [ngClass]="buttonClass || recommended ? 'c2a' : 'secondary'"
            [disabled]="disabled || active"
        >
            {{ buttonLabel || 'Velg pakke' }}
        </button>
    </div>

    <section *ngIf="active">Aktiv pakke</section>
</section>
