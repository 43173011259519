<uni-form
    *ngIf="altinn"
    [config]="{ showLabelAbove: true }"
    [fields]="fields"
    [model]="altinn"
    (changeEvent)="isDirty = true"
>
</uni-form>
<section class="flex">
    <button
        *ngIf="!hideCheckButton && isDirty"
        class="ml-1 c2a secondary"
        [attr.aria-busy]="busy"
        (click)="save()"
        alt="Lagre innstillingene for altinn"
    >
        Lagre innstillinger
    </button>

    <button
        *ngIf="!hideCheckButton && !isDirty"
        class="ml-1 c2a secondary"
        [attr.aria-busy]="busy"
        (click)="pingAltinn()"
        [disabled]="hasFailedLoginAttempt"
        alt="Test kommunikasjon mot altinn"
    >
        Test innlogging
    </button>

    <small class="bad ml-2"> {{ loginErr }} </small>
</section>
