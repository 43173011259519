<section class="flex gap-2" [class.vertical-layout]="verticalLayout">
    <ag-grid-wrapper
        *ngIf="showFileList && files?.length"
        class="borders"
        [resource]="files"
        [config]="tableConfig"
        (rowDelete)="onRowDeleted($event)"
        (rowClick)="onAttachmentClicked($event)"
        (rowSelectionChange)="onRowSelectionChange($event)"
    >
    </ag-grid-wrapper>

    <file-input
        [class.flex-1]="!files?.length"
        [layout]="!files?.length || verticalLayout ? 'large' : 'small'"
        [entityType]="entity"
        [entityID]="entityID"
        [canUseInbox]="true"
        (filesUploaded)="onFilesUploaded($event)"
    >
    </file-input>
</section>
