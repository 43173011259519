<section class="header">
    <h1>Kostnader</h1>
    <button #toggle class="secondary pull-right">
        {{ year }}
        <i class="material-icons">expand_more</i>
    </button>

    <dropdown-menu [trigger]="toggle">
        <ng-template>
            <section class="dropdown-menu-item center" *ngFor="let year of years" (click)="onYearSelected(year)">
                {{ year }}
            </section>
        </ng-template>
    </dropdown-menu>
</section>

<section *ngIf="!loading" class="content flex-row">
    <widget-empty-state *ngIf="!hasData"> Det ser ikke ut som du har noen kostnader i {{ year }}. </widget-empty-state>

    <widget-pie-chart *ngIf="hasData" [chartConfig]="chartConfig"></widget-pie-chart>
</section>

<section *ngIf="!loading && hasData" class="footer center border-top">
    <section class="footer-sum-item">
        <span class="value"> {{ totalAmount | uninumberformat: 'money' }} </span>
        <span class="label">Sum kostnader</span>
    </section>
</section>
