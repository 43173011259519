<section *ngIf="categories?.length" class="toggle-list">
    <a (click)="expanded = !expanded">
        Sammenlign funksjoner
        <i class="material-icons" [class.expanded]="expanded && categories.length">expand_more</i>
    </a>
</section>
<section class="comparison-container" *ngIf="expanded && !busy && categories.length" [@slideInOut]>
    <table class="table">
        <thead class="comparison-header">
            <th width="5%"></th>
            <th width="30%"></th>
            <th scope="col" *ngFor="let contracttype of activeContractTypes" class="center" width="20%">
                {{ contracttype.Label }}
                <br />
                <span style="font-weight: 400">
                    {{ contracttype['_MainPackageProduct'] ? contracttype['_MainPackageProduct'].Price : '-' }} kr/mnd
                </span>
            </th>
        </thead>

        <tbody *ngFor="let category of categories">
            <tr class="category-row">
                <th></th>
                <th scope="row" style="float: left">
                    {{ category.Name }}
                </th>
                <th *ngFor="let contracttype of activeContractTypes"></th>
            </tr>
            <tr *ngFor="let feature of category.Features">
                <td></td>
                <td>
                    {{ feature.Text }}
                    <div *ngIf="feature.Tooltip?.length" class="feature-tooltip" [matTooltip]="feature.Tooltip">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.72021 8.18775C7.72021 6.90587 8.68858 5.9375 9.97046 5.9375C11.2523 5.9375 12.2207 6.90587 12.2207 8.18775C12.2207 9.46963 11.2523 10.438 9.97046 10.438C9.71158 10.438 9.50171 10.6479 9.50171 10.9067V12.5726C9.50171 12.8315 9.71158 13.0414 9.97046 13.0414C10.2293 13.0414 10.4392 12.8315 10.4392 12.5726V11.3434C12.0056 11.1259 13.1582 9.82768 13.1582 8.18775C13.1582 6.3881 11.7701 5 9.97046 5C8.17081 5 6.78271 6.3881 6.78271 8.18775C6.78271 8.44663 6.99258 8.6565 7.25146 8.6565C7.51035 8.6565 7.72021 8.44663 7.72021 8.18775ZM9.97057 14.9988C10.3818 14.9988 10.656 14.7246 10.656 14.3133C10.656 13.9021 10.3818 13.6279 9.97057 13.6279C9.55932 13.6279 9.28516 13.9021 9.28516 14.3133C9.28516 14.7246 9.55932 14.9988 9.97057 14.9988Z"
                                fill="currentColor"
                            />
                            <circle cx="10" cy="10" r="9.5" stroke="currentColor" />
                        </svg>
                    </div>
                </td>
                <td *ngFor="let checked of feature.Checkmarks" class="center">
                    <svg
                        *ngIf="checked"
                        width="20"
                        height="20"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M2.61309 8.7943C2.3745 8.4557 1.9066 8.37462 1.568 8.61321C1.2294 8.8518 1.14833 9.3197 1.38691 9.6583L2.61309 8.7943ZM4.45 12.7033L5.07323 12.2861L5.06825 12.2786L5.06309 12.2713L4.45 12.7033ZM6.157 12.7543L5.5686 12.2892L5.56414 12.2949L5.55979 12.3006L6.157 12.7543ZM14.5884 3.29636C14.8452 2.9714 14.79 2.49975 14.4651 2.2429C14.1401 1.98605 13.6684 2.04127 13.4116 2.36624L14.5884 3.29636ZM1.38691 9.6583L3.83691 13.1353L5.06309 12.2713L2.61309 8.7943L1.38691 9.6583ZM3.82677 13.1205C4.15013 13.6036 4.68696 13.9006 5.26797 13.9179L5.31277 12.4186C5.2162 12.4157 5.12698 12.3664 5.07323 12.2861L3.82677 13.1205ZM5.26797 13.9179C5.84899 13.9353 6.40259 13.6709 6.75421 13.208L5.55979 12.3006C5.50134 12.3775 5.40933 12.4215 5.31277 12.4186L5.26797 13.9179ZM6.7454 13.2194L14.5884 3.29636L13.4116 2.36624L5.5686 12.2892L6.7454 13.2194Z"
                            fill="currentColor"
                        />
                    </svg>
                </td>
            </tr>
        </tbody>
    </table>
</section>
