import { take } from 'rxjs/operators';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { IModalOptions, IUniModal, UniModalService } from '@uni-framework/uni-modal';
import { ToastService, ToastType } from '@uni-framework/uniToast/toastService';
import { CompanySettings, BankAccount, StatusCode } from '@app/unientities';
import { FieldType, CurrentUserSession } from '@uni-entities';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { AuthService } from '@app/authService';
import { CompanyBankAccountModal } from '../bank-account-modal/company-bank-account-modal';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { BankAgreementServiceProvider } from '@app/models/autobank-models';
import { BankService } from '@app/services/accounting/bankService';
import { PaymentBatchService } from '@app/services/accounting/paymentBatchService';
import { CompanySettingsService } from '@app/services/common/companySettingsService';
import { ErrorService } from '@app/services/common/errorService';
import { ModulusService } from '@app/services/common/modulusService';
import { NumberFormat } from '@app/services/common/numberFormatService';
import { ElsaAgreementService } from '@app/services/elsa/elsaAgreementService';
import { ElsaContractService } from '@app/services/elsa/elsaContractService';
import { ElsaProductService } from '@app/services/elsa/elsaProductService';
import { ElsaPurchaseService } from '@app/services/elsa/elsaPurchasesService';
import { WizardStep } from '@uni-framework/ui/design-system/wizard/wizard';

@Component({
    selector: 'bank-init-modal',
    templateUrl: './bank-init-modal.html',
    styleUrls: ['./bank-init-modal.sass'],
})
export class BankInitModal implements IUniModal, OnInit {
    @Input() options = <IModalOptions>{};
    @Output() onClose = new EventEmitter<boolean>();

    steps: WizardStep[] = [
        { label: 'Intro', value: 0 },
        { label: 'Avtalevilkår', value: 1 },
        { label: 'Godkjenne betalinger', value: 2 },
        { label: 'Driftskonto*', value: 3 },
        { label: 'Lønnskonto', value: 4 },
        { label: 'Skattetrekkskonto', value: 5 },
        { label: 'Sikkerhet*', value: 6 },
        { label: 'Ferdigstilling', value: 7 },
    ];
    currentStep: WizardStep = this.steps[0];
    companySettings: CompanySettings;

    errorMsg: string = '';
    payload: any = {
        Phone: '',
        Password: '',
        Password2: '',
        IsInbound: true,
        IsOutgoing: true,
        IsBankBalance: true,
        IsBankStatement: true,
        BankApproval: true,
        ServiceProvider: BankAgreementServiceProvider.ZData,
    };
    currentUser: CurrentUserSession;
    agreement: any;
    busy: boolean = false;
    isDirty: boolean = false;
    dataLoaded: boolean = false;
    isNextStepValid: boolean = true;
    accounts = [
        {
            label: 'Driftskonto',
            field: 'CompanyBankAccount',
            type: 'company',
            defaultAccount: 1920,
        },
        {
            label: 'Lønnskonto',
            field: 'SalaryBankAccount',
            type: 'salary',
            defaultAccount: null,
        },
        {
            label: 'Skattetrekkskonto',
            field: 'TaxBankAccount',
            type: 'tax',
            defaultAccount: 1950,
        },
    ];

    companySettings$ = new BehaviorSubject<CompanySettings>(null);
    hasBoughtAutobank: boolean = false;
    fields$ = new BehaviorSubject([]);
    bankName: string;
    forceSameBank = false;
    bankAgreementUrl: SafeResourceUrl;
    hasReadAgreement = false;
    serviceProvider: BankAgreementServiceProvider;

    constructor(
        private companySettingsService: CompanySettingsService,
        private modalService: UniModalService,
        private toastService: ToastService,
        private bankService: BankService,
        private elsaPurchasesService: ElsaPurchaseService,
        private elsaProductService: ElsaProductService,
        private errorService: ErrorService,
        private authService: AuthService,
        private elsaContractService: ElsaContractService,
        private elsaAgreementService: ElsaAgreementService,
        private sanitizer: DomSanitizer,
        private numberFormat: NumberFormat,
        private modulusService: ModulusService,
        private paymentBatchService: PaymentBatchService,
    ) {}

    ngOnInit() {
        if (
            !this.options.data?.cs?.OrganizationNumber ||
            !this.modulusService.isValidOrgNr(this.options.data?.cs?.OrganizationNumber)
        ) {
            this.errorMsg =
                '*Mangler gyldig organisasjonsnummer. Du må gå til firmaoppsett og ' +
                'registrere dette før du kan koble sammen bank og regnskap.';
            this.isNextStepValid = false;
            return;
        }

        this.bankName = this.authService.publicSettings?.BankName || 'SpareBank 1 SR-Bank';
        this.currentUser = this.options.data.user;
        this.payload.Phone = this.currentUser.PhoneNumber;

        forkJoin([
            this.elsaContractService.getCurrentContractType(this.currentUser.License?.ContractType?.TypeName),
            this.elsaAgreementService.getByType('Bank'),
            this.bankService.getDefaultServiceProvider(),
        ]).subscribe(([contracttype, agreement, defaultServiceProvider]) => {
            this.forceSameBank = !!contracttype?.ForceSameBank;
            this.bankAgreementUrl = this.sanitizer.bypassSecurityTrustResourceUrl(agreement?.DownloadUrl);
            this.serviceProvider = defaultServiceProvider;
            this.payload.ServiceProvider = defaultServiceProvider;
            if (this.serviceProvider === 4) {
                this.steps.splice(6, 1);
            }

            if (this.serviceProvider === 1) {
                this.steps.splice(2, 1);
            }
            this.steps = [...this.steps];
        });

        this.elsaPurchasesService.getPurchaseByProductName('Autobank').subscribe(
            (response) => {
                this.hasBoughtAutobank = !!response;
                this.initiateRegistrationModal();
            },
            (err) => {
                this.hasBoughtAutobank = false;
                this.initiateRegistrationModal();
            },
        );
    }

    initiateRegistrationModal() {
        if (this.hasBoughtAutobank) {
            this.reloadCompanySettings();
        } else {
            this.elsaProductService.GetAll(`Name eq 'Autobank'`).subscribe(
                (products) => {
                    if (products && products.length) {
                        const payload = [{ ID: null, ProductID: products[0].ID }];
                        this.elsaPurchasesService.massUpdate(payload).subscribe(() => {
                            this.reloadCompanySettings();
                        });
                    } else {
                        // This could be a big error ?? Autobank not present in Elsa
                        this.toastService.addToast('Klarte ikke hente nødvendig informasjon. Prøv igjen');
                        this.close();
                    }
                },
                (err) => {
                    this.toastService.addToast('Klarte ikke hente nødvendig informasjon. Prøv igjen');
                    this.close();
                },
            );
        }
    }

    reloadCompanySettings() {
        this.companySettingsService
            .getCompanySettings([
                'CompanyBankAccount',
                'CompanyBankAccount.Bank',
                'SalaryBankAccount',
                'SalaryBankAccount.Bank',
                'TaxBankAccount',
                'TaxBankAccount.Bank',
                'BankAccounts',
            ])
            .subscribe(
                (companySettings) => {
                    this.companySettings = companySettings;
                    this.companySettings$.next(companySettings);
                    this.dataLoaded = true;
                    this.busy = false;
                },
                (err) => {
                    this.busy = false;
                },
            );
    }

    step(): void {
        const onStep = () => {
            if (!this.checkNextStepValid()) {
                return;
            }

            const index = this.steps.findIndex((step) => step.value === this.currentStep.value);
            this.currentStep = this.steps[index + 1];

            this.errorMsg = '';
            if (this.currentStep.value >= 3 && this.currentStep.value <= 5) {
                this.fields$.next(this.setUpUniForm());
            }
        };

        if (this.isDirty) {
            this.saveAccount().subscribe(
                (companySettings) => {
                    this.toastService.addToast(
                        this.accounts[this.currentStep.value - 3].label + ' oppdatert i firmaoppsett',
                        ToastType.good,
                        5,
                    );
                    this.reloadCompanySettings();
                    this.isDirty = false;
                    onStep();
                },
                (err) => {
                    this.busy = false;
                },
            );
        } else {
            onStep();
        }
    }

    prev(): void {
        const index = this.steps.findIndex((step) => step.value === this.currentStep.value);
        this.currentStep = this.steps[index - 1];

        this.errorMsg = '';

        if (this.currentStep.value >= 3 && this.currentStep.value <= 5) {
            this.fields$.next(this.setUpUniForm());
        }
    }

    checkNextStepValid(): boolean {
        switch (this.currentStep.value) {
            case 0:
                return this.isNextStepValid;
            case 1:
                return this.hasReadAgreement;
            case 3:
                return !!this.companySettings$.value?.CompanyBankAccountID;
            default:
                return true;
        }
    }

    saveAccount() {
        const objToSave: any = {
            ID: this.companySettings$.value.ID,
        };
        const stringValueFromStep = ['CompanyBankAccount', 'SalaryBankAccount', 'TaxBankAccount'];

        if (this.companySettings$.value[stringValueFromStep[this.currentStep.value - 3] + 'ID']) {
            objToSave[stringValueFromStep[this.currentStep.value - 3] + 'ID'] =
                this.companySettings$.value[stringValueFromStep[this.currentStep.value - 3] + 'ID'];
        } else {
            objToSave[stringValueFromStep[this.currentStep.value - 3]] =
                this.companySettings$.value[stringValueFromStep[this.currentStep.value - 3]];
            objToSave[stringValueFromStep[this.currentStep.value - 3] + 'ID'] = null;
        }
        this.busy = true;
        return this.companySettingsService.Put(this.companySettings$.value.ID, objToSave);
    }

    postAutobankUser() {
        this.busy = true;
        if (
            this.serviceProvider === BankAgreementServiceProvider.ZdataV3 ||
            (this.validatePassword() && this.isValidPhoneNumber(this.payload.Phone))
        ) {
            this.bankService.createInitialAgreement(this.payload).subscribe(
                (agreement) => {
                    this.orderPreApprovedPayments(agreement);
                    this.agreement = agreement;
                    const failedAccounts = Object.getOwnPropertyNames(agreement.BankAccountResults || {}).filter(
                        (x) => !agreement.BankAccountResults[x],
                    );
                    if (failedAccounts.length > 0) {
                        this.toastService.addToast(
                            'En eller flere bankkontoer ble ikke onboardet hos Aritma. ' +
                                'Ta kontakt med support for å få de onboardet',
                        );
                    }
                    this.busy = false;
                    this.currentStep = this.steps[this.steps.length - 1];
                },
                (err) => {
                    this.busy = false;
                    this.errorService.handle(err);
                },
            );
        } else {
            this.busy = false;
        }
    }

    private orderPreApprovedPayments(agreement: any) {
        if (!this.payload.BankApproval) {
            if (agreement.ServiceProvider === BankAgreementServiceProvider.Bruno) {
                let url =
                    'https://www.dnb.no/bedrift/dagligbank/betaling/tjenestebutikken?erp=DNBRegnskap&utbetalingerval=true';
                window.open(url);
            } else if (agreement.ServiceProvider === BankAgreementServiceProvider.ZdataV3) {
                // ZDataV3 + DNB account
                this.paymentBatchService.getAutoBankAgreement(agreement.ID).subscribe((agreementWithBank) => {
                    if (agreementWithBank.BankAccount?.Bank?.BIC?.indexOf('DNBANOKK') !== -1) {
                        let url =
                            'https://www.dnb.no/bedrift/dagligbank/betaling/tjenestebutikken?erp=ZData&kontoinfoval=true&utbetalingerval=true';
                        window.open(url);
                    }
                });
            }

            agreement.PreApprovedBankPayments = 700003;
        }
    }

    private validatePassword(): boolean {
        this.errorMsg = '';

        if (this.payload.Password !== this.payload.Password2) {
            this.errorMsg = 'Passordene er ikke like';
            return false;
        }

        if (this.payload.Password.length < 10) {
            this.errorMsg = 'Passordet må være minst 10 tegn langt';
            return false;
        }

        if (!/[a-z]/.test(this.payload.Password)) {
            this.errorMsg = 'Passord må ha minst 1 liten bokstav [a-z]';
            return false;
        }
        if (!/[A-Z]/.test(this.payload.Password)) {
            this.errorMsg = 'Passord må ha minst 1 stor bokstav [A-Z]';
            return false;
        }
        if (!/[\d]/.test(this.payload.Password)) {
            this.errorMsg = 'Passord må ha minst ett tall [0-9]';
            return false;
        }

        if (!/[\@\#\$\%\^\&\*\-_\\+\=\[\]\{\}\|\\\:\‘\,\.\?\/\`\~\“\(\)\;]/.test(this.payload.Password)) {
            this.errorMsg = 'Passord må ha minst ett tegn som ikke er en bostav eller et tall';
            return false;
        }

        return true;
    }

    private isValidPhoneNumber(phone) {
        const test1 = /^\d{8}$/.test(phone);
        const test2 = /^0047\d{8}$/.test(phone);
        const test3 = /^\+47\d{8}$/.test(phone);

        if (test1 || test2 || test3) {
            return true;
        } else {
            this.errorMsg = 'Telefonnummer må være et gyldig norsk telefonnummer';
            return false;
        }
    }

    close() {
        this.onClose.emit(this.agreement);
    }

    setUpUniForm() {
        const accountType = this.accounts[this.currentStep.value - 3];
        return [
            {
                EntityType: 'Supplier',
                Property: 'Info.BankAccounts',
                FieldType: FieldType.MULTIVALUE,
                Label: accountType.label,
                Options: {
                    entity: 'BankAccount',
                    listProperty: 'BankAccounts',
                    OptionsFilter: (x) => x.StatusCode != StatusCode.InActive,
                    template: (field) => this.numberFormat.asBankAcct(field?.AccountNumber),
                    linkProperty: 'ID',
                    storeResultInProperty: accountType.field,
                    storeIdInProperty: accountType.field + 'ID',
                    editor: (bankaccount: BankAccount) => {
                        if ((bankaccount && !bankaccount.ID) || !bankaccount) {
                            bankaccount = bankaccount || new BankAccount();
                            bankaccount['_createguid'] = this.companySettingsService.getNewGuid();
                            bankaccount.BankAccountType = accountType.type;
                            bankaccount.CompanySettingsID = 1;
                            bankaccount.BusinessRelationID = 0;
                            bankaccount.ID = 0;
                        }
                        const modal = this.modalService.open(CompanyBankAccountModal, {
                            data: {
                                bankAccount: bankaccount,
                            },
                            modalConfig: {
                                lockAccountType: !!accountType.type,
                            },
                            header: bankaccount?.ID ? 'Rediger bankkonto' : 'Legg til bankkonto',
                            closeOnClickOutside: false,
                        });

                        return modal.onClose.pipe(take(1)).toPromise();
                    },
                },
            },
        ];
    }
}
