<section role="dialog" class="uni-modal">
    <header>Innstillinger for feriepenger</header>
    <article>
        <uni-form
            [config]="{ showLabelAbove: true }"
            [fields]="advancedSettingsFields"
            [model]="companySalary"
            (changeEvent)="vacationPayAdvancedSettingsChange($event)"
        >
        </uni-form>

        <section>
            <ag-grid-wrapper
                [config]="companyVacationRatesTableConfig"
                [(resource)]="companyVacationRates"
                (rowDelete)="onRowDeleted($event)"
                (rowChange)="onRowChanged($event)"
            >
            </ag-grid-wrapper>
        </section>
    </article>
    <footer>
        <button class="secondary" (click)="close()">Avbryt</button>
        <button class="c2a" (click)="close()" [disabled]="false" [hidden]="isDirty">Lukk</button>
        <button class="c2a" (click)="save()" [attr.aria-busy]="isBusy" [disabled]="false" [hidden]="!isDirty">
            Lagre
        </button>
    </footer>
</section>
