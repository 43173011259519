<nav class="flex-column" [class.collapsed]="sidebarCollapsed$ | async">
    <ul uniScrollbar class="sidebar-menu flex-1">
        <li *ngFor="let section of linkSections; let idx = index">
            <sidebar-section
                [section]="section"
                [expanded]="(expandedSection$ | async)?.name === section.name"
                [sidebarCollapsed]="sidebarCollapsed$ | async"
            >
            </sidebar-section>
        </li>
    </ul>

    <button class="edit-button unstyled mt-auto" (click)="showMegaMenu()" aria-label="Edit visible menu items">
        <i class="material-icons">edit</i>
        <span [class.visually-hidden]="sidebarCollapsed$ | async">Rediger meny</span>
    </button>
</nav>
