import { Injectable } from '@angular/core';
import { BizHttp } from '../../../framework/core/http/BizHttp';
import { SubEntityHistoricAga } from '../../unientities';
import { UniHttp } from '../../../framework/core/http/http';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SubEntityHistoricAgaService extends BizHttp<SubEntityHistoricAga> {
    constructor(protected http: UniHttp) {
        super(http);

        this.relativeURL = SubEntityHistoricAga.RelativeUrl;
        this.entityType = SubEntityHistoricAga.EntityType;
        this.DefaultOrderBy = null;
    }

    public save(SubEntityHistoricAga: SubEntityHistoricAga): Observable<SubEntityHistoricAga> {
        return SubEntityHistoricAga.ID
            ? this.Put(SubEntityHistoricAga.ID, SubEntityHistoricAga)
            : this.Post(SubEntityHistoricAga);
    }
}
