<section *ngIf="customer" class="uni-card shadow">
    <section class="uni-card-content">
        <h1>{{ headerText }}</h1>

        <form [formGroup]="customerDetailsForm">
            <label class="uni-label">
                <span>Navn</span>
                <input uniAutoFocus type="text" formControlName="ContactPerson" />
            </label>

            <label class="uni-label">
                <span>Epost</span>
                <input type="text" formControlName="ContactEmail" />
            </label>

            <label class="uni-label">
                <span>Telefon</span>
                <input type="text" formControlName="ContactPhone" />
            </label>

            <label *ngIf="customerDetailsForm.controls['PersonalNumber']" class="uni-label">
                <span>Personnummer</span>
                <input type="text" formControlName="PersonalNumber" />
            </label>

            <label *ngIf="customerDetailsForm.controls['BankUserID']" class="uni-label">
                <span>
                    Bruker-identifikator i nettbank bedrift
                    <uni-tooltip
                        [text]="
                            'Dette er brukerID som du har mottatt for å logge deg inn i bedriftsnettbanken. Har du ikke Nettbank Bedrift kan du la være å fylle ut dette feltet.'
                        "
                    >
                    </uni-tooltip>
                </span>
                <input type="text" formControlName="BankUserID" />
            </label>

            <rig-checkbox *ngIf="customerDetailsForm.controls['IsBankCustomer']" formControlName="IsBankCustomer">
                Jeg er allerede bedriftskunde i {{ bankName }}
            </rig-checkbox>
        </form>

        <rig-checkbox [(ngModel)]="termsAgreed" [disabled]="activationInProgress" *ngIf="showTerms">
            Jeg har lest og godtar
            <a [href]="licenseAgreementUrl" target="_blank" rel="noopener noreferrer">lisensavtalen</a>.
        </rig-checkbox>
    </section>

    <section class="uni-card-footer">
        <button class="secondary pull-left" (click)="back.emit()">Tilbake</button>

        <button
            class="c2a"
            [disabled]="!termsAgreed"
            [attr.aria-busy]="activationInProgress"
            (click)="activateContract()"
        >
            {{ submitButtonText }}
        </button>
    </section>
</section>
