import { IModalOptions, IUniModal } from '@uni-framework/uni-modal';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Product } from '@uni-entities';
import { AutocompleteOptions } from '@uni-framework/ui/autocomplete/autocomplete';
import { ProductService } from '@app/services/common/productService';
@Component({
    selector: 'select-Product-For-Goods-Modal',
    templateUrl: './selectProductForGoodsModal.html',
    styleUrls: ['./selectProductForGoodsModal.sass'],
})
export class SelectProductForGoodsModal implements IUniModal {
    @Input() options: IModalOptions;
    @Output() onClose: EventEmitter<Product> = new EventEmitter<Product>(true);

    product: Product;
    autocompleteOptions: AutocompleteOptions;

    constructor(private productService: ProductService) {}

    ngOnInit(): void {
        this.product = this.options.data.product;

        const config = this.productService.getAutocompleteConfig();
        this.autocompleteOptions = {
            clearInputOnSelect: false,
            lookup: config.search,
            displayFunction: config.template,
        };
    }

    accept() {
        this.onClose.emit(this.product);
    }

    close() {
        this.onClose.emit(null);
    }
}
