import { IBizReportLayout, IBizReportRenderer, IBizReportSettings } from './IBizReport';

export class ExcelReportRenderer implements IBizReportRenderer {
    workbook;
    rows = [];
    numberFormat = '#,##0.00;[Red]-#,##0.00';

    constructor(
        excelJS,
        private layout: IBizReportLayout,
        settings: IBizReportSettings,
    ) {
        this.workbook = new excelJS.Workbook();
        this.setupNumberFormat(settings);
    }

    setupNumberFormat(settings: IBizReportSettings) {
        const decimals = settings.decimalPlaces ?? 2;
        if (decimals >= 0) {
            if (decimals == 0) {
                this.numberFormat = `#,##0;[Red]-#,##0`;
            } else {
                const nulls = '0'.repeat(decimals);
                this.numberFormat = `#,##0.${nulls};[Red]-#,##0.${nulls}`;
            }
        }
    }

    formatNumber(value: any): string {
        return value.toFixed(2);
    }

    getFileExtension(): string {
        return '.xlsx';
    }

    async getBuffer(
        title: string,
        subTitle: string,
        companyName: string,
        callBack?: (wb: any) => void,
    ): Promise<string> {
        const workbook = await this.createExcelWorkbook(title, subTitle, companyName);
        if (callBack) {
            callBack(workbook);
        }
        return await workbook.xlsx.writeBuffer();
    }

    private async createExcelWorkbook(title: string, subTitle: string, companyName: string) {
        const worksheet = this.workbook.addWorksheet(title, {
            pageSetup: { fitToWidth: 1, fitToHeight: 9999 },
        });

        this.addTableHeader(worksheet);

        for (var i = 1; i < this.rows.length; i++) {
            const src = this.rows[i];
            const isGroupHeader = src.class === 'groupheader';
            const isGroupFooter = src.class === 'groupfooter';
            const isSubTotal = src.class === 'subtotalfooter';
            const isReportFooter = src.class === 'reportfooter';

            // Spacing above headers
            if (isGroupHeader || isReportFooter || isSubTotal) {
                worksheet.addRow().commit();
            }

            const data = this.getRow(i);
            const row = worksheet.addRow(data);

            // Styling
            if (isGroupHeader || isReportFooter || isSubTotal) {
                row.font = { size: 11, bold: true };
            }
            if (isGroupFooter) {
                row.font = { bold: true };
            }

            row.commit();
        }

        this.addRequiredHighlights(worksheet);

        if (title) this.addTitle(worksheet, title, subTitle, companyName);

        return this.workbook;
    }

    addRequiredHighlights(worksheet: any) {
        for (let i = 0; i < this.layout.Columns.length; i++) {
            const col = this.layout.Columns[i];
            if (col.Required) {
                const cell = worksheet.getCell(1, i + 1);
                cell.style.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '20FFD030' } };
            }
        }
    }

    private addTitle(worksheet, title: string, subTitle: string, companyName: string) {
        worksheet.spliceRows(1, 0, [], [], [], []);
        worksheet.getCell(1, 1).value = title;
        worksheet.getCell(2, 1).value = companyName;
        worksheet.getCell(3, 1).value = subTitle;
        worksheet.getCell(1, 1).style.font = { size: 17, bold: true };
        worksheet.getCell(2, 1).style.font = { size: 15, bold: true };
        worksheet.getCell(3, 1).style.font = { size: 11, bold: true };
        worksheet.views = [{ state: 'frozen', xSplit: 0, ySplit: 5 }];
    }

    private addNote(worksheet, cell: string, note: string) {
        worksheet.getCell(cell).note = note;
    }

    private addTableHeader(worksheet) {
        const firstRow = this.rows[0];
        const columns = [];

        for (var i = 0; i < firstRow.children.length; i++) {
            const lay = this.layout.Columns[i];
            const col = <any>{
                id: i + 1,
                header: this.removeSortIcons(firstRow.children[i].text),
            };
            // Estimate width
            let width = 0;
            if (lay.Max) width = lay.Max * 1.5;
            switch (lay.Format) {
                case 'money':
                    width = 12;
                    col.style = { numFmt: this.numberFormat, alignment: { vertical: 'middle', horizontal: 'right' } };
                    break;
                case 'date':
                    width = 11;
                    col.style = { alignment: { vertical: 'middle', horizontal: 'center' } };
                    break;
                case 'percent':
                    width = 7;
                    col.style = { alignment: { vertical: 'middle', horizontal: 'center' } };
                    break;
            }
            if (width) {
                col['width'] = width;
            }
            columns.push(col);
        }

        worksheet.columns = columns;
    }

    private removeSortIcons(value: string): string {
        if (value === undefined || value === '') return value;
        let result = value;
        const icons = ['▲', '▼'];
        icons.forEach((icon) => {
            if (value && value.indexOf(icon) >= 0) {
                result = result.replace(icon, '');
            }
        });
        return result;
    }

    private getRow(index: number): any {
        const data = this.rows[index].children;
        const output = [];
        let dataIndex = 0;
        for (var ci = 0; ci < this.layout.Columns.length; ci++) {
            const col = this.layout.Columns[ci];
            if (!col) continue;
            const cell = data[dataIndex++];
            if (!cell) continue;
            let value = cell.text;
            switch (col.Format) {
                case 'money':
                    value = value ? parseFloat(value) : 0;
                    break;
                case 'date':
                    break;
                case 'percent':
                    break;
            }

            output[ci + 1] = value;

            if (cell.colspan) {
                ci += cell.colspan - 1;
            }
        }
        return output;
    }

    createText(value: string) {
        return { type: 'text', text: value };
    }

    createElement(value: string) {
        const el = { type: value, children: [] };
        switch (value) {
            case 'tr':
                this.rows.push(el);
                break;
        }
        return el;
    }

    appendChild(el: any, child: any) {
        if (child.type === 'text') {
            if (el.type === 'span') {
                el.parent.text = child.text;
                return;
            }
            el.text = child.text;
            return;
        }
        child.parent = el;
        el.children.push(child);
    }

    setAttribute(el: any, attr: string, value: string) {
        if (attr === 'colspan') {
            el.colspan = parseInt(value);
        }
    }

    addClass(el: any, name: string) {
        el.class = name;
    }

    setStyle(el: any, style: string, value: string) {}

    getCsvData(title: string, subTitle: string): string {
        return '';
    }
}
