import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IModalOptions, IUniModal } from '@uni-framework/uni-modal';
import { theme } from 'src/themes/theme';

@Component({
    selector: 'tasks-complete-modal',
    templateUrl: './complete-modal.html',
    styleUrls: ['./complete-modal.sass'],
})
export class TasksCompleteModal implements IUniModal {
    options = <IModalOptions>{};
    onClose = new EventEmitter<any>();

    appName = theme.appName;

    constructor() {}
}
