import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { StartupTaskService, StartupTask } from '../startup-task-service';
import { JournalEntryTypes } from '@app/models/accounting/JournalEntryTypes';
import { JournalEntryLineDraftService } from '@app/services/accounting/journalEntryLineDraftService';

@Component({
    selector: 'register-opening-balance-task',
    template: `<startup-task [task]="task" (tileClicked)="runTask()"> </startup-task>`,
})
export class RegisterOpeningBalanceTask {
    @Input()
    public task: StartupTask;

    constructor(
        private service: StartupTaskService,
        private router: Router,
        private journalEntryLineDraftService: JournalEntryLineDraftService,
    ) {}

    ngOnChanges(change) {
        if (change['task'] && change['task'].currentValue) {
            if (!this.task.completed) {
                this.journalEntryLineDraftService
                    .getNewestFromTypes([JournalEntryTypes.OpeningBalance, JournalEntryTypes.IncomingBalance])
                    .subscribe({
                        next: (line) => (line ? this.service.markTaskAsCompleted(this.task) : null),
                    });
            }
        }
    }

    runTask() {
        this.router.navigateByUrl('/settings/accounting/incoming-balance/choice');
    }
}
