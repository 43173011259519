<section class="header">
    <h1>Kostnadsoversikt</h1>
</section>

<section class="content">
    <ul class="widget-link-list">
        <a (click)="goToDetailsView()">
            <span> Varekostnader </span>
            <span class="pull-right" [ngClass]="values.costProducts < 0 ? 'good' : 'bad'">
                {{ values.costProducts | uninumberformat: 'money' }}
            </span>
        </a>

        <a (click)="goToDetailsView()">
            <span> Lønnskostnader </span>
            <span class="pull-right" [ngClass]="values.costSalary < 0 ? 'good' : 'bad'">
                {{ values.costSalary | uninumberformat: 'money' }}</span
            >
        </a>

        <a (click)="goToDetailsView()">
            <span> Andre driftskostnader </span>
            <span class="pull-right" [ngClass]="values.costOthers < 0 ? 'good' : 'bad'">
                {{ values.costOthers | uninumberformat: 'money' }}
            </span>
        </a>

        <a (click)="goToDetailsView()">
            <span> Finansinntekt/kostader </span>
            <span class="pull-right" [ngClass]="values.financeCost < 0 ? 'good' : 'bad'">
                {{ values.financeCost | uninumberformat: 'money' }}
            </span>
        </a>

        <a (click)="goToDetailsView()">
            <span> Kostnader totalt </span>
            <span class="pull-right" [ngClass]="values.costTotal < 0 ? 'good' : 'bad'">
                {{ values.costTotal | uninumberformat: 'money' }}
            </span>
        </a>
    </ul>
</section>
