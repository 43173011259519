<section role="dialog" class="uni-modal" style="width: 37.5rem">
    <header>{{ editMode ? 'Rediger betalingsmåte' : 'Opprett ny betalingsmåte' }}</header>
    <section>
        <uni-form
            [fields]="fields$"
            [config]="{ autofocus: true, showLabelAbove: true, required: true }"
            [model]="paymentMethod"
        >
        </uni-form>
    </section>
    <footer>
        <button (click)="cancel()" class="secondary">Avbryt</button>

        <button class="c2a" (click)="confirm()">
            {{ editMode ? 'Lagre endring' : 'Opprett betalingsmåte' }}
        </button>
    </footer>
</section>
