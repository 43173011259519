<section role="dialog" class="uni-modal">
    <rig-wizard [steps]="steps" [activeStepValue]="currentStep.value">
        <section *ngIf="busy" class="modal-spinner">
            <mat-spinner class="c2a"></mat-spinner>
        </section>

        <h1 class="wizard-header">Slik setter du opp autobank</h1>

        <article class="step" *ngIf="currentStep.value === 0">
            <h2 class="step-header">
                Velkommen til vår veiviser for <br />
                autobank
            </h2>
            <section class="mt-1 mb-4">
                <h3>Koble sammen bank og regnskap</h3>
                <p>
                    Autobank gjør det mulig for SpareBank 1 Regnskap å snakke sammen med nettbanken. Bankkontoer, saldo
                    og transaksjoner er synlige i regnskapssystemet, og du legger opp utbetalinger i nettbanken direkte
                    fra SpareBank 1 Regnskap. Sammenkoblingen gjør også selve regnskapsførselen enklere for deg. For å
                    komme i gang med denne tjenesten må du gjennom denne veiviseren for oppsett av bankkontoer og
                    opprettelse av passord.
                </p>
            </section>
            <small style="color: var(--color-bad)"> {{ errorMsg }} </small>
        </article>

        <article class="step" *ngIf="currentStep.value === 1">
            <h2 class="step-header">Avtalevilkår</h2>
            <section class="mt-1 mb-4">
                <ng-container *ngIf="bankAgreementUrl">
                    <object [data]="bankAgreementUrl" type="application/pdf">
                        <a [href]="bankAgreementUrl">Avtalevilkår</a>
                    </object>
                    <br />

                    <rig-checkbox [(ngModel)]="hasReadAgreement"> Godta vilkår og avtaler </rig-checkbox>
                </ng-container>
                <p *ngIf="!bankAgreementUrl">Fant ingen avtalevilkår. Kontakt systemansvarlig.</p>
            </section>
        </article>

        <article class="step" *ngIf="currentStep.value === 2">
            <h2 class="step-header">Godkjenning av betalinger</h2>
            <section class="mt-1 mb-4">
                <p>
                    Her velger du metoden for å godkjenne betalinger: velger du regnskapsgodkjente betalinger kan du
                    forhåndsgodkjenne betalingene, ellers må du ettergodkjenne betalingene i nettbanken.
                </p>

                <uni-radio-group [(ngModel)]="payload.BankApproval" (ngModelChange)="step.Limit = 0">
                    <uni-radio-button [value]="false">Regnskapsgodkjente betalinger</uni-radio-button>
                    <uni-radio-button [value]="true">Ettergodkjente betalinger i nettbanken</uni-radio-button>
                </uni-radio-group>
            </section>
        </article>

        <article class="step" *ngIf="currentStep.value === 3">
            <h2 class="step-header">Driftkonto</h2>
            <section class="mt-1 mb-4">
                <p>
                    Her velger du hvilken bankkonto som brukes som driftskonto. Denne er <strong>påkrevd</strong> for å
                    fortsette. <br />
                    Om du allerede har ordnet dette, trykk Neste.
                </p>
                <uni-form
                    [model]="companySettings$"
                    [fields]="fields$"
                    [config]="{ showLabelAbove: true, autoFocus: true }"
                    (changeEvent)="isDirty = true"
                >
                </uni-form>
            </section>
        </article>

        <article class="step" *ngIf="currentStep.value === 4">
            <h2 class="step-header">Lønnskonto</h2>
            <section class="mt-1 mb-4">
                <p>
                    Her kan du velge å sette opp egen konto for eventuelle lønnsutbetalinger. Hvis ikke du setter opp en
                    konto her, vil systemet bruke konto satt som Driftskonto. Trykk neste for å fortsette.
                </p>
                <uni-form
                    [model]="companySettings$"
                    [fields]="fields$"
                    [config]="{ showLabelAbove: true, autoFocus: true }"
                    (changeEvent)="isDirty = true"
                >
                </uni-form>
            </section>
        </article>

        <article class="step" *ngIf="currentStep.value === 5">
            <h2 class="step-header">Skattetrekkskonto</h2>
            <section class="mt-1 mb-4">
                <p>
                    Her kan du sette opp en konto for eventuelle skattetrekk. Denne konto er ikke påkrevd, så om du
                    ønsker å hoppe over, er det bare å trykke Neste.
                </p>
                <uni-form
                    [model]="companySettings$"
                    [fields]="fields$"
                    [config]="{ showLabelAbove: true, autoFocus: true }"
                    (changeEvent)="isDirty = true"
                >
                </uni-form>
            </section>
        </article>

        <article class="step" *ngIf="currentStep.value === 6">
            <h2 class="step-header">Sikkerhetsinnstillinger</h2>
            <section class="mt-1 mb-4">
                <p>
                    Her oppretter du et passord for autobank. Passordet brukes til å bekrefte registrerte utbetalinger i
                    systemet. Dette passordet må ikke forveksles med passordet du bruker ved pålogging i
                    regnskapssystemet eller nettbanken.
                </p>

                <label class="uni-label">
                    <span>Telefonnummer</span>
                    <input type="text" [(ngModel)]="payload.Phone" />
                </label>

                <label class="uni-label">
                    <span>Passord</span>
                    <input type="password" [(ngModel)]="payload.Password" />
                </label>

                <label class="uni-label">
                    <span>Bekreft passord</span>
                    <input type="password" [(ngModel)]="payload.Password2" />
                </label>
                <small
                    >Passord må være minst 10 tegn og inneholde minst 1 stor bokstav [A-Z], 1 liten bokstav [a-z], et
                    tall [0-9] og et tegn</small
                >
                <br />
                <small class="color-bad"> {{ errorMsg }} </small>
            </section>
        </article>

        <article class="step" *ngIf="currentStep.value === 7">
            <i class="material-icons">thumb_up</i>
            <h2 class="step-header">Avtale opprettet</h2>
            <p class="mt-1 mb-4">Oppsett fullført. Trykk ferdig for å begynne å bruke bankmodulen.</p>
        </article>

        <rig-wizard-footer>
            <button *ngIf="currentStep.value === 0" class="secondary pull-left" (click)="close()">
                Gjennomfør senere
            </button>
            <button
                class="secondary"
                *ngIf="currentStep.value > 0 && currentStep.value < 7"
                (click)="prev()"
                [disabled]="isDirty"
            >
                Tilbake
            </button>

            <button
                *ngIf="currentStep.value === 6 || (currentStep.value === 5 && serviceProvider === 4)"
                class="c2a"
                (click)="postAutobankUser()"
            >
                Opprett autobank
            </button>

            <button
                *ngIf="currentStep.value < 5 || (currentStep.value === 5 && serviceProvider !== 4)"
                class="c2a"
                [disabled]="!isNextStepValid"
                (click)="step()"
            >
                Neste
            </button>

            <button class="c2a" *ngIf="this.currentStep.value === 7" (click)="close()">Ferdig</button>
        </rig-wizard-footer>
    </rig-wizard>
</section>
