<ng-container *ngIf="contractTypes?.length">
    <a #trigger tabindex="-1">
        <span *ngIf="currentType">{{ preText }} {{ currentType.Label }}</span>
        <span *ngIf="!currentType">Test produktpakke</span>

        <i class="material-icons">expand_more</i>
    </a>

    <dropdown-menu [trigger]="trigger" minWidth="8rem">
        <ng-template>
            <section
                *ngFor="let type of contractTypes"
                (click)="onContractTypeSelected(type)"
                class="dropdown-menu-item"
            >
                {{ preText }} {{ type.Label }}
            </section>
        </ng-template>
    </dropdown-menu>
</ng-container>
