import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, of, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AuthService } from '@app/authService';

import { THEMES, theme } from 'src/themes/theme';
import { dougnutTextPlugin } from '@app/chartjs-setup';
import { Router } from '@angular/router';
import { UniModalService } from '@uni-framework/uni-modal';
import { BankAccount, BankIntegrationAgreement } from '@uni-entities';
import { UniReconciliationStartDateModal } from '@app/components/common/modals/reconciliation-startdate-modal';
import { BankAgreementServiceProvider } from '@app/models/autobank-models';
import { BankAccountService } from '@app/services/accounting/bankAccountService';
import { PaymentBatchService } from '@app/services/accounting/paymentBatchService';
import { NumberFormat } from '@app/services/common/numberFormatService';
import { BankService } from '@app/services/accounting/bankService';
import { CompanySettingsService } from '@app/services/common/companySettingsService';
import { UniHttp } from '@uni-framework/core/http';

interface AccountBalanceData {
    accountName: string;
    accountNumber: string;
    balanceAvailable: number;
    balanceBooked: number;
    isMainAccountBalance: boolean;
    MainAccountName: string;
    bankAccountID?: number;
}

@Component({
    selector: 'bank-balance-widget-content',
    templateUrl: './bank-balance-content.html',
    styleUrls: ['./bank-balance-content.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BankBalanceWidgetContent {
    @Input() bankIntegrationAgreement: BankIntegrationAgreement;

    colors = theme.widgets.pie_colors;

    accountBalanceItems: AccountBalanceData[];
    failedPaymentCount: number;
    incomingPaymentCount: number;
    reconciliationCount: number;
    approvePaymentsCount: number;

    missingData: boolean;
    showAllIsUpdated: boolean;
    needsToSetBankAgreementStartDate: boolean;
    chartConfig;
    refreshBusy = false;

    constructor(
        private cdr: ChangeDetectorRef,
        private authService: AuthService,
        private http: HttpClient,
        private bankAccountService: BankAccountService,
        private numberFormatter: NumberFormat,
        private router: Router,
        private modalService: UniModalService,
        private paymentBatchService: PaymentBatchService,
        private bankService: BankService,
        private uniHttp: UniHttp,
    ) {}

    ngOnInit() {
        this.getData();
    }

    private getData() {
        forkJoin([
            this.getAccountBalances(),
            this.getReconciliationCount(),
            this.getPaymentCounts(),
            this.getApprovalsCount(),
        ]).subscribe(
            ([
                balances,
                reconciliationCount = 0,
                {
                    numberOutgoingPayment = 0,
                    notLinkedIncomingPayment = 0,
                    failedOutgoingPayment = 0,
                    numberIncomingPayment = 0,
                },
                approvalCount = 0,
            ]) => {
                if (balances && balances.length) {
                    this.accountBalanceItems = balances;
                    this.chartConfig = this.getChartConfig();
                    this.missingData = false;
                    this.reconciliationCount = reconciliationCount;
                } else {
                    this.missingData = true;
                }
                this.failedPaymentCount = numberOutgoingPayment ? failedOutgoingPayment : -1;
                this.incomingPaymentCount = numberIncomingPayment ? notLinkedIncomingPayment : -1;
                this.approvePaymentsCount = approvalCount;

                if (this.bankIntegrationAgreement) {
                    this.needsToSetBankAgreementStartDate = !this.bankIntegrationAgreement.BankStatementStartDate;
                }

                this.showAllIsUpdated =
                    this.failedPaymentCount <= 0 &&
                    this.incomingPaymentCount <= 0 &&
                    this.reconciliationCount <= 0 &&
                    !this.needsToSetBankAgreementStartDate;
                this.refreshBusy = false;
                this.cdr.markForCheck();
            },
            (err) => {
                console.error(err);
                this.missingData = true;
                this.refreshBusy = false;
                this.cdr.markForCheck();
            },
        );
    }

    gotoFailedPayments() {
        this.router.navigateByUrl('/bank/ticker?code=payment_list&filter=payment_list');
    }

    gotoIncomingPayments() {
        this.router.navigateByUrl('/bank/ticker?code=bank_list&filter=incomming_without_match');
    }

    openSetStartDateModal() {
        if (!this.bankIntegrationAgreement) {
            return;
        }

        if (!this.bankIntegrationAgreement.BankStatementStartDate) {
            this.modalService
                .open(UniReconciliationStartDateModal, { data: { agreement: this.bankIntegrationAgreement } })
                .onClose.subscribe(() => {
                    forkJoin([
                        this.getAccountBalances(),
                        this.getReconciliationCount(),
                        this.paymentBatchService.checkAutoBankAgreement(),
                    ]).subscribe(([balances, reconciliationCount, agreements]) => {
                        if (balances && balances.length) {
                            this.accountBalanceItems = balances;
                            this.chartConfig = this.getChartConfig();
                            this.missingData = false;
                            this.reconciliationCount = reconciliationCount;
                        } else {
                            this.missingData = true;
                        }

                        this.bankIntegrationAgreement = agreements?.find(
                            (a) => a.ServiceProvider === BankAgreementServiceProvider.BrunoApi,
                        );

                        if (this.bankIntegrationAgreement) {
                            this.needsToSetBankAgreementStartDate =
                                !this.bankIntegrationAgreement.BankStatementStartDate;
                        }

                        this.showAllIsUpdated =
                            this.failedPaymentCount <= 0 &&
                            this.incomingPaymentCount <= 0 &&
                            this.reconciliationCount <= 0 &&
                            !this.needsToSetBankAgreementStartDate;

                        this.cdr.markForCheck();
                    });
                    return;
                });
        }
    }

    refresh(ID: number) {
        this.refreshBusy = true;
        const obs =
            theme.theme === THEMES.EIKA //tmp code until biz routes support cors with allowcredentials
                ? this.uniHttp
                      .asPOST()
                      .withCredentials()
                      .usingRootDomain()
                      .withEndPoint(`bank/summon-bank-balance-update-eika/${ID}`)
                      .send({}, null, false)
                      .pipe(map((res) => res.body))
                : this.bankAccountService.PutAction(ID, 'summon-update');
        obs.subscribe({
            next: (res) => {
                if (res) {
                    this.bankAccountService
                        .GetUntil(
                            { ID: ID, expand: ['BankAccountSettings'], interval: 2000, timeout: 10000 },
                            (account: BankAccount) => !account.BankAccountSettings.SummonUpdate,
                        )
                        .subscribe({
                            next: () => {
                                this.getData();
                            },
                            error: (error) => {
                                this.getData();
                            },
                        });
                } else {
                    this.refreshBusy = false;
                    this.cdr.markForCheck();
                }
            },
            error: (error) => {
                this.refreshBusy = false;
                this.cdr.markForCheck();
            },
        });
    }

    gotoApprovals() {
        this.router.navigateByUrl('/assignments/approvals?showCompleted=false&showAllUsers=true');
    }

    private getApprovalsCount(): Observable<number> {
        const endpoint = `/api/statistics?model=task&select=count(task.id) as total&join=task.modelid eq model.id and task.entityid eq paymentbatch.id&filter=model.name eq 'paymentbatch' and task.statuscode eq 50020 and paymentbatch.deleted eq 0&wrap=false`;

        return this.http.get(endpoint).pipe(
            catchError(() => of(null)),
            map((res) => {
                if (res && res[0]) {
                    return res[0].total;
                } else {
                    return 0;
                }
            }),
        );
    }

    private getAccountBalances() {
        if (this.authService.activeCompany?.IsTest) {
            return this.bankAccountService.getBankBalance(1).pipe(
                map((res) => [
                    {
                        accountName: 'Testkonto',
                        accountNumber: res.AccountNumber,
                        balanceAvailable: res.BalanceAvailable,
                        balanceBooked: res.BalanceBooked,
                        isMainAccountBalance: false,
                        MainAccountName: '',
                    },
                ]),
            );
        }

        return this.bankAccountService.getAllBankBalances().pipe(
            map((balances: any[]) => {
                const data: AccountBalanceData[] = [];

                balances.forEach((balance) => {
                    data.push({
                        accountName: balance.AccountName,
                        accountNumber: this.numberFormatter.asBankAcct(balance.AccountNumber),
                        balanceAvailable: balance.BalanceAvailable,
                        balanceBooked: balance.BalanceBooked,
                        isMainAccountBalance: balance.IsMainAccountBalance,
                        MainAccountName: balance.MainAccountName,
                        bankAccountID: balance.BankAccountID,
                    });
                });

                return data;
            }),
        );
    }

    private getReconciliationCount(): Observable<number> {
        return this.bankService.getBankAccountsForReconciliation().pipe(
            catchError(() => of(null)),
            map((res) => {
                let total = 0;
                let closed = 0;
                let accountingposts = 0;

                res.map((acc) => {
                    total += acc.Total;
                    closed += acc.Closed;
                    accountingposts += acc.AccountingPosts;
                });

                const num = Math.max(total - closed, accountingposts);
                if (total || accountingposts) {
                    return num ? num : -1;
                } else {
                    return 0;
                }
            }),
        );
    }

    private getPaymentCounts(): Observable<{
        numberOutgoingPayment: number;
        numberIncomingPayment: number;
        notLinkedIncomingPayment: number;
        failedOutgoingPayment: number;
    }> {
        const endpoint =
            '/api/statistics?model=Payment&wrap=false' +
            '&select=sum(casewhen((' +
            "Payment.IsCustomerPayment eq 'true' " +
            'and Payment.PaymentBatchID gt 0 ' +
            'and Payment.StatusCode eq 44018' +
            '),1,0)) as notLinkedIncomingPayment,' +
            'sum(casewhen((' +
            "Payment.IsCustomerPayment eq 'true' " +
            'and Payment.PaymentBatchID gt 0' +
            '),1,0)) as numberIncomingPayment,' +
            'sum(casewhen((' +
            "Payment.IsCustomerPayment eq 'false' " +
            'and (Payment.StatusCode eq 44012 ' +
            'or Payment.StatusCode eq 44003 ' +
            'or Payment.StatusCode eq 44021) ' +
            "and Payment.isPaymentClaim eq 'false' " +
            "and Payment.isPaymentCancellationRequest eq 'false'" +
            '),1,0)) as failedOutgoingPayment,' +
            'sum(casewhen((' +
            "Payment.IsCustomerPayment eq 'false' " +
            "and Payment.isPaymentClaim eq 'false' " +
            "and Payment.isPaymentCancellationRequest eq 'false' " +
            'and Payment.PaymentBatchID gt 0' +
            '),1,0)) as numberOutgoingPayment';
        return this.http.get(endpoint).pipe(
            catchError(() => {
                return of([
                    {
                        numberOutgoingPayment: 0,
                        numberIncomingPayment: 0,
                        failedOutgoingPayment: 0,
                        notLinkedIncomingPayment: 0,
                    },
                ]);
            }),
            map((res) => res && res[0]),
        );
    }

    private getChartConfig() {
        const totalAmount = this.accountBalanceItems.reduce((sum, item) => {
            return (sum += item.isMainAccountBalance ? item.balanceBooked : item.balanceAvailable || 0);
        }, 0);

        return {
            type: 'pie',
            plugins: [dougnutTextPlugin],
            data: {
                datasets: [
                    {
                        data: this.accountBalanceItems.map((item) =>
                            item.isMainAccountBalance ? item.balanceBooked : item.balanceAvailable,
                        ),
                        backgroundColor: this.colors,
                        label: '',
                        borderColor: '#fff',
                        hoverBorderColor: '#fff',
                    },
                ],
                labels: this.accountBalanceItems.map((item) => item.accountName),
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                cutoutPercentage: 72,
                elements: { arc: { borderWidth: 8 } },
                animation: { animateScale: true },
                plugins: {
                    legend: { display: false },
                    tooltip: { enabled: false },
                    doughnutText: {
                        header: 'Sum',
                        text: this.numberFormatter.asMoney(totalAmount),
                    },
                },
            },
        };
    }
}
