<section class="header">
    <h1>Huskeliste</h1>

    <button class="tertiary c2a pull-right" (click)="createOrEditTask()" tabindex="0">Opprett huskelapp</button>
</section>

<section class="content" id="reminder-list">
    <section *ngFor="let item of publicDueDates" class="reminder-item duedate-item">
        <i class="material-icons-outlined">event</i>

        <span class="description">{{ item.AdditionalInfo }}</span>
        <span class="date pull-right" [ngClass]="item['_class']" [title]="item.Deadline | date: 'dd.MM.yyyy'">
            {{ item['_daysLabel'] }}
        </span>
    </section>

    <a
        *ngFor="let task of tasks; let i = index"
        class="reminder-item unstyled-link task-span-title"
        (click)="createOrEditTask(task)"
    >
        <i class="material-icons-outlined">note</i>
        <span>{{ task.Title }}</span>
    </a>

    <!-- Invoice approvals -->
    <a *ngIf="invoiceApprovals?.length" (click)="goToInvoiceApprovals()" class="reminder-item unstyled-link">
        <i class="material-icons-outlined">thumb_up_alt</i>
        <span
            >{{ invoiceApprovals.length }} {{ invoiceApprovals.length > 1 ? 'regninger' : 'regning' }} å godkjenne</span
        >
    </a>

    <!-- Timesheet approvals -->
    <a *ngIf="timesheetApprovals?.length" routerLink="/assignments/approvals" class="reminder-item unstyled-link">
        <i class="material-icons-outlined">event_available</i>
        <span
            >{{ timesheetApprovals.length }} {{ timesheetApprovals.length > 1 ? 'timelister' : 'timeliste' }} å
            godkjenne</span
        >
    </a>

    <!-- Inbox -->
    <a *ngIf="counters.inbox" (click)="goToInbox()" class="reminder-item unstyled-link">
        <i class="material-icons-outlined">mail</i>
        <span>{{ counters.inbox }} {{ counters.inbox > 1 ? 'filer' : 'fil' }} i innboks</span>
    </a>

    <!-- Invoices ready for payment -->
    <a
        *ngIf="counters.invoicesReadyForPayment"
        class="reminder-item unstyled-link"
        routerLink="/bank/ticker"
        [queryParams]="{ code: 'payment_list' }"
    >
        <i class="material-icons-outlined">credit_card</i>
        <span>
            {{ counters.invoicesReadyForPayment }}
            {{ counters.invoicesReadyForPayment > 1 ? 'regninger' : 'regning' }}
            til betaling
        </span>
    </a>

    <!-- Invoices ready for reminding -->
    <a *ngIf="counters.invoicesReadyForReminder" routerLink="/sales/reminders" class="reminder-item unstyled-link">
        <i class="material-icons-outlined">add_alert</i>
        <span>
            {{ counters.invoicesReadyForReminder }}
            {{ counters.invoicesReadyForReminder > 1 ? 'fakturaer' : 'fakturaer' }}
            å purre på
        </span>
    </a>
</section>
