import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FinancialYearService } from '@app/services/accounting/financialYearService';
import { ErrorService } from '@app/services/common/errorService';
import { CompanyVacationRateService } from '@app/services/salary/companySalary/companyVacationRateService';
import { IVacationPaySummary, VacationpayLineService } from '@app/services/salary/payrollRun/vacationpayLineService';
import { SummaryOptions, SummaryTileType } from '@uni-framework/ui/summary/summary-component';
import { ConfirmActions, IModalOptions, IUniModal, UniModalService } from '@uni-framework/uni-modal';
import { ToastService, ToastTime, ToastType } from '@uni-framework/uniToast/toastService';
import { VacationPaySettingsModal } from '../vacation-pay-settings-modal/vacation-pay-settings-modal.component';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { BasicAmount, VacationPayLine } from '@uni-entities';
import { IRowChangeEvent, UniTableColumn, UniTableColumnType, UniTableConfig } from '@uni-framework/ui/unitable';
import { Observable, concat, forkJoin, of, pipe } from 'rxjs';
import { StatisticsService } from '@app/services/common/statisticsService';
import { BasicAmountService } from '@app/services/salary/basicAmount/basicAmountService';
import { rigDate } from '@app/components/common/utils/rig-date';
import { UniNumberFormatPipe } from '@uni-framework/pipes/uniNumberFormatPipe';
import { ISelectConfig } from '@uni-framework/ui/uniform';
import { UniMath } from '@uni-framework/core/uniMath';
import { ComboButtonAction } from '@uni-framework/ui/combo-button/combo-button';
import { AgGridWrapper } from '@uni-framework/ui/ag-grid/ag-grid-wrapper';
import { VacationPaySingleEditModalComponent } from '../vacation-pay-single-edit-modal/vacation-pay-single-edit-modal.component';

@Component({
    selector: 'uni-vacation-pay-modal',
    templateUrl: './vacation-pay-modal.component.html',
    styleUrls: ['./vacation-pay-modal.component.sass'],
})
export class VacationPayModal implements IUniModal, OnInit {
    @Input() options: IModalOptions;
    @Output() onClose: EventEmitter<boolean> = new EventEmitter();

    @ViewChild(AgGridWrapper, { static: true }) private table: AgGridWrapper;

    summaryOptions: SummaryOptions;
    summaryData: IVacationPaySummary = null;
    vacationpayBasis: VacationPayLine[] = [];
    vacationpayBasisTableConfig: UniTableConfig;

    activeYear: number;
    currentFinancialYear: number;

    payrollRunID: number;

    isBusy: boolean;
    isDirty: boolean;
    isAnySelected: boolean;

    selectYearConfig: ISelectConfig;
    selectYears: Array<any> = [];
    basicAmounts: Array<BasicAmount> = [];

    vacationPayFilter = {
        employeesWithoutVacationPay: false,
        vacationWeek6: false,
        percentPayout: 100,
    };
    showAllEmployee: boolean;
    saveActions: ComboButtonAction[] = [];
    mainAction: ComboButtonAction;

    constructor(
        private companyVacationRateService: CompanyVacationRateService,
        private financialYearService: FinancialYearService,
        private modalService: UniModalService,
        private toastService: ToastService,
        private vacationPayLineService: VacationpayLineService,
        private basicamountService: BasicAmountService,
        private numberPipe: UniNumberFormatPipe,
        private statisticsService: StatisticsService,
        private errorService: ErrorService,
    ) {}

    ngOnInit(): void {
        this.isBusy = true;
        this.currentFinancialYear = this.financialYearService.getActiveYear();
        this.activeYear = this.currentFinancialYear - 1;
        this.showAllEmployee = false;
        this.payrollRunID = (this.options && this.options.data && this.options.data.ID) ?? 0;
        this.vacationPayFilter.vacationWeek6 =
            localStorage.getItem('setSixthWeekSelected') === 'true' || !localStorage.getItem('setSixthWeekSelected');
        this.setSaveActions();

        forkJoin([
            this.getSums(),
            this.basicamountService.GetAll(
                'filter=year(fromdate) ge ' + (this.currentFinancialYear - 5) + '&orderby=fromdate desc',
            ),
            this.getVacationPayData(),
        ])
            .subscribe({
                next: (data) => {
                    this.basicAmounts = data[1];
                    this.setSummaryData(data[0]);
                    const currentYear = new Date().getFullYear();

                    for (let i = 0; i < 3; i++) {
                        this.selectYears.push({ Year: currentYear - i });
                    }

                    this.selectYearConfig = {
                        valueProperty: 'Year',
                        displayProperty: 'Year',
                        searchable: false,
                        hideDeleteButton: true,
                    };
                    this.vacationPayTableSetup();
                    this.vacationpayBasis = data[2];
                },
                error: (error) => {
                    this.errorService.handle(error);
                },
            })
            .add(() => (this.isBusy = false));
    }

    createVacationPay() {
        const selectedVacationPayLines = this.table.getSelectedRows();
        if (selectedVacationPayLines.length === 0) {
            return;
        }
        this.openVacationPayCreationConfirmModal(selectedVacationPayLines)
            .pipe(
                filter((response) => response === ConfirmActions.ACCEPT),
                switchMap(() => {
                    this.canCreateVacationLine();
                    this.isBusy = true;
                    return this.vacationPayLineService.toSalary(
                        this.activeYear,
                        this.payrollRunID,
                        selectedVacationPayLines,
                    );
                }),
            )
            .subscribe({
                next: (response) => {
                    this.onClose.emit(true);
                },
                error: (error) => {
                    this.errorService.handle(error);
                },
            })
            .add(() => (this.isBusy = false));
    }

    saveVacationPay() {
        this.isBusy = true;
        const saveObservables: Observable<any>[] = [];
        let vacationPayLineForSave = this.vacationpayBasis.filter((line) => !!line['_isDirty'] && line.ID === 0);
        this.vacationpayBasis
            .filter((line) => !!line['_isDirty'] && line.ID != 0)
            .map((update) => {
                saveObservables.push(this.vacationPayLineService.Put(update.ID, update));
            });
        if (vacationPayLineForSave.length)
            saveObservables.push(this.vacationPayLineService.Post(vacationPayLineForSave));

        concat(...saveObservables)
            .subscribe({
                error: (error) => {
                    this.errorService.handle(error);
                },
                complete: () => {
                    this.toastService.addToast('Feriepengelinjer lagret!', ToastType.good, ToastTime.short);
                    this.isDirty = false;
                    if (!this.vacationpayBasis.some((line) => line['_withdrawalChanged'])) {
                        //Do not refresh if withdrawal has changed
                        this.refreshData();
                    }
                    this.setSaveActions();
                },
            })
            .add(() => (this.isBusy = false));
    }

    onPercentPayoutChanged() {
        let percent: number = this.vacationPayFilter.percentPayout;
        if (isNaN(percent) || percent > 100 || percent < 1) {
            percent = 100;
        }
        this.vacationpayBasis = this.vacationpayBasis.map((row) => this.updateVacationPayAndWithdrawal(row));
    }

    onPillChange() {
        if (this.vacationPayFilter.employeesWithoutVacationPay != this.showAllEmployee) {
            //need to fetch data
            this.canDeactivate().subscribe((canDeactivate) => {
                if (canDeactivate) {
                    this.showAllEmployee = this.vacationPayFilter.employeesWithoutVacationPay;
                    this.refreshData();
                }
            });
        } else {
            //Update array only
            this.canDeactivate().subscribe((canDeactivate) => {
                if (canDeactivate) {
                    this.vacationpayBasis = this.modifyVacationPayDataFor6Weeks(this.vacationpayBasis);
                    localStorage.setItem('setSixthWeekSelected', this.vacationPayFilter.vacationWeek6.toString());
                }
            });
        }
    }

    onYearDropdownChange(value) {
        this.canDeactivate().subscribe((canDeactivate) => {
            if (canDeactivate) {
                this.activeYear = value.Year;
                this.refreshData(true);
            } else {
                this.activeYear = this.activeYear;
            }
        });
    }

    refreshData(hasYearChange: boolean = false) {
        this.isBusy = true;
        forkJoin([this.getVacationPayData(), hasYearChange ? this.getSums() : of(this.summaryData)])
            .subscribe((data) => {
                this.vacationpayBasis = data[0];
                this.setSummaryData(data[1]);
                this.vacationPayTableSetup();
            })
            .add(() => (this.isBusy = false));
    }

    openVacationSettingsModal(data: boolean = true) {
        this.modalService.open(VacationPaySettingsModal, { data: data }).onClose.subscribe({
            next: (vacationPaySettings: any[]) => {
                if (vacationPaySettings) {
                    this.summaryData.rate = vacationPaySettings[0].rate;
                    this.summaryData.rate60 = vacationPaySettings[0].rate60;
                    this.summaryData.wageDeductionDueToHolidayDescription =
                        this.vacationPayLineService.getWageDeductionDueToHolidayName(
                            vacationPaySettings[0].wageDeductionDueToHoliday ?? 0,
                            vacationPaySettings[1].HasTwoLineVacationPay,
                        );
                    this.summaryData.allowOver6G = vacationPaySettings[1].AllowOver6G;
                    this.summaryOptions = this.getSummaryConfig(this.summaryData);
                    this.refreshData();
                }
            },
        });
    }

    public canDeactivate(): Observable<boolean> {
        if (!this.isDirty) {
            return of(true);
        }
        return this.modalService.openUnsavedChangesModal().onClose.pipe(
            map((result) => {
                if (result === ConfirmActions.ACCEPT) {
                    this.saveVacationPay();
                } else if (result === ConfirmActions.REJECT) {
                    this.isDirty = false;
                }
                return result !== ConfirmActions.CANCEL;
            }),
        );
    }

    public rowChanged(event: IRowChangeEvent) {
        if (event.field === 'ManualVacationPayBase' || event.field === '_Rate') {
            this.isDirty = true;
        }
        this.setSaveActions();
    }

    public onRowSelectionChange(event) {
        this.isAnySelected = !!this.table.getSelectedRows().length;
        this.setSaveActions();
    }

    public openSingleEditModal(vacationPayLine: VacationPayLine): void {
        this.modalService
            .open(VacationPaySingleEditModalComponent, {
                data: {
                    VacationPayLine: vacationPayLine,
                    SummaryData: this.summaryData,
                    PercentPayout: this.vacationPayFilter.percentPayout,
                    VacationWeek6: this.vacationPayFilter.vacationWeek6,
                    ActiveYear: this.activeYear,
                    PayrollRunID: this.payrollRunID,
                },
            })
            .onClose.subscribe((result: VacationPayLine) => {
                if (result) {
                    this.table.updateRow(result['_originalIndex'], result);
                    this.isDirty = result['_isDirty'];
                    this.setSaveActions();
                }
            });
    }

    private setSummaryData(summaryData) {
        this.summaryData = summaryData;
        const currentBasicAmount = this.getCurrentBasicAmount();
        this.summaryData.basicAmountDesc = this.numberPipe.transform(currentBasicAmount?.BasicAmountPrYear, 'money');
        this.summaryData.basicAmount = currentBasicAmount?.BasicAmountPrYear;
        this.summaryOptions = this.getSummaryConfig(this.summaryData);
    }

    private getCurrentBasicAmount() {
        return (
            this.basicAmounts.find((item) => rigDate(item.FromDate).year() === this.activeYear) || this.basicAmounts[0]
        );
    }

    private openVacationPayCreationConfirmModal(vacationPayLines: VacationPayLine[]): Observable<ConfirmActions> {
        let totalPayout = vacationPayLines.reduce((sum, row) => sum + row.Withdrawal, 0);
        return this.modalService.confirm({
            header: 'Opprett feriepengeposter',
            message:
                'Vennligst bekreft overføring av feriepengeposter til lønnsavregning ' +
                this.payrollRunID +
                ` - Totalsum kr ${UniMath.useFirstTwoDecimals(totalPayout)}`,
            buttonLabels: {
                accept: 'Overfør',
                cancel: 'Avbryt',
            },
        }).onClose;
    }

    private canCreateVacationLine(): void {
        if (this.isEarlierPay(this.currentFinancialYear, this.activeYear)) {
            return;
        }
        const rows: VacationPayLine[] = this.table.getSelectedRows();
        let msg = '';
        const missingEarlierPayments = rows.filter((row) => !!row.MissingEarlierVacationPay);

        if (missingEarlierPayments.length) {
            const last = missingEarlierPayments.pop();

            msg += missingEarlierPayments
                .map((row) => `${row.Employee.EmployeeNumber} - ${row.Employee.BusinessRelationInfo.Name}`)
                .join(', ');

            msg += `${msg ? ' og ' : ''}${last.Employee.EmployeeNumber} - ${last.Employee.BusinessRelationInfo.Name}`;

            msg += ', har utbetalinger fra tidligere år som venter og blir derfor ikke med i denne overføringen';
        }
        if (msg) {
            this.toastService.addToast('Mangler tidligere utbetaling', ToastType.warn, ToastTime.forever, msg);
        }
    }

    private setSaveActions() {
        this.saveActions = [
            {
                label: 'Lag feriepengeposter',
                action: () => this.createVacationPay(),
                disabled: !this.payrollRunID || !this.isAnySelected,
            },
            {
                label: 'Lagre',
                action: () => this.saveVacationPay(),
                disabled: !this.isDirty,
            },
        ];
        this.mainAction = this.payrollRunID && !this.isDirty ? this.saveActions[0] : this.saveActions[1];
    }

    private getSums(): Observable<IVacationPaySummary> {
        return forkJoin([
            this.companyVacationRateService.getCurrentRates(this.activeYear),
            this.statisticsService
                .GetAllUnwrapped(
                    'model=CompanySalary' +
                        '&select=MainAccountCostVacation as MainAccountCostVacation,ref.accountname as MainAccountCostVacationName,MainAccountAllocatedVacation as MainAccountAllocatedVacation,ref2.accountname as MainAccountAllocatedVacationName,' +
                        'HasTwoLineVacationPay as HasTwoLineVacationPay,WageDeductionDueToHoliday as WageDeductionDueToHoliday,AllowOver6G as AllowOver6G' +
                        '&join=companysalary.MainAccountCostVacation eq Account.AccountNumber as ref and companysalary.MainAccountAllocatedVacation eq Account.AccountNumber as ref2',
                )
                .pipe(map((data) => data[0])),
        ]).pipe(
            map(([currentRates, companySalary]) => {
                let summary: IVacationPaySummary = {
                    rate: currentRates.Rate,
                    rate60: currentRates.Rate60,
                    wageDeductionDueToHolidayDescription: this.vacationPayLineService.getWageDeductionDueToHolidayName(
                        companySalary.WageDeductionDueToHoliday ?? 0,
                        companySalary.HasTwoLineVacationPay,
                    ),
                    hasTwoLineVacationPayDescription: companySalary.HasTwoLineVacationPay
                        ? 'Månedslønn og trekk for alle feriedager (2 poster)'
                        : 'Sum av månedslønn minus trekk for feriedager (1 post)',
                    basicAmountDesc: '',
                    basicAmount: 0,
                    allowOver6G: companySalary.AllowOver6G,
                    mainAccountCostVacationDescription:
                        companySalary.MainAccountCostVacation + ' ' + companySalary.MainAccountCostVacationName,
                    mainAccountAllocatedVacationDescription:
                        companySalary.MainAccountAllocatedVacation +
                        ' ' +
                        companySalary.MainAccountAllocatedVacationName,
                };
                return summary;
            }),
        );
    }

    private getVacationPayData() {
        return this.vacationPayLineService
            .getVacationpayBasis(this.activeYear, this.payrollRunID, this.showAllEmployee)
            .pipe(
                map((vacationPayLineData) => {
                    return this.modifyVacationPayDataFor6Weeks(vacationPayLineData);
                }),
            );
    }

    private modifyVacationPayDataFor6Weeks(vacationPayLineData: VacationPayLine[]) {
        if (vacationPayLineData) {
            return vacationPayLineData.map((x) => {
                if (this.empOver60(x) && this.vacationPayFilter.vacationWeek6) {
                    x['_IncludeSixthWeek'] = 'Ja';
                    x['_Rate'] = x.Rate60;
                    x['_VacationPay'] = x.VacationPay60;
                } else {
                    x['_IncludeSixthWeek'] = 'Nei';
                    x['_Rate'] = x.Rate;
                    x['_VacationPay'] = x.VacationPay;
                }
                return this.updateVacationPayAndWithdrawal(x);
            });
        }
    }

    private getSummaryConfig(data): SummaryOptions {
        return {
            data,
            tiles: [
                {
                    field: 'rate',
                    header: `Standard sats for ${this.activeYear}`,
                    type: SummaryTileType.NUMBER,
                    suffix: '%',
                },
                {
                    field: 'rate60',
                    header: 'Tillegg for over 60 år',
                    type: SummaryTileType.NUMBER,
                    suffix: '%',
                },
                {
                    field: 'hasTwoLineVacationPayDescription',
                    header: 'Trekk i fastlønn for ferie føres som',
                    title: data.hasTwoLineVacationPayDescription,
                },

                {
                    field: 'mainAccountCostVacationDescription',
                    header: 'Konto for kostnad på feriepenger',
                    title: data.mainAccountCostVacationDescription,
                },
                {
                    field: 'basicAmountDesc',
                    header: `Grunnbeløp (G) for ${this.activeYear}`,
                    suffix: ' kr',
                },
                {
                    field: '', // Empty cell.
                },
                {
                    field: 'wageDeductionDueToHolidayDescription',
                    header: 'Beregning av trekk i fastlønn',
                    title: data.wageDeductionDueToHolidayDescription,
                },
                {
                    field: 'mainAccountAllocatedVacationDescription',
                    header: 'Konto for avsatte feriepenger',
                    title: data.mainAccountAllocatedVacationDescription,
                },
            ],
        };
    }

    private vacationPayTableSetup() {
        const nrCol = new UniTableColumn(
            'Employee.EmployeeNumber',
            'Nr',
            UniTableColumnType.Text,
            false,
        ).setTooltipResolver((rowModel: VacationPayLine) => {
            if (this.empOver60(rowModel)) {
                return {
                    type: 'info',
                    text: 'Ansatt er over 60 år',
                };
            }
        });
        const nameCol = new UniTableColumn(
            'Employee.BusinessRelationInfo.Name',
            'Navn',
            UniTableColumnType.Text,
            false,
        );
        const systemGrunnlagCol = new UniTableColumn(
            'SystemVacationPayBase',
            'Gr.lag system',
            UniTableColumnType.Money,
            false,
        );
        const manuellGrunnlagCol = new UniTableColumn(
            'ManualVacationPayBase',
            'Gr.lag manuelt',
            UniTableColumnType.Money,
            true,
        );

        const rateCol = new UniTableColumn(
            '_Rate',
            `Sats (${this.activeYear})`,
            UniTableColumnType.Money,
            (row) => !this.isEarlierPayline(row),
        ).setTemplate((row: VacationPayLine) => {
            if (row['_isEmpty']) {
                return;
            }
            if (this.empOver60(row) && this.vacationPayFilter.vacationWeek6) {
                return '' + row.Rate60;
            } else {
                return '' + row.Rate;
            }
        });
        const sixthCol = new UniTableColumn('_IncludeSixthWeek', '6.ferieuke', UniTableColumnType.Text, false);

        const vacationPayCol = new UniTableColumn(
            '_VacationPay',
            'Feriepenger',
            UniTableColumnType.Money,
            false,
        ).setTemplate((row: VacationPayLine) => {
            if (row['_isEmpty']) {
                return;
            }

            return '' + UniMath.useFirstTwoDecimals(row['_VacationPay']);
        });
        const earlierPayCol = new UniTableColumn(
            'PaidVacationPay',
            'Tidl utbetalt',
            UniTableColumnType.Money,
            false,
        ).setTemplate((row: VacationPayLine) => '' + UniMath.useFirstTwoDecimals(row.PaidVacationPay));
        const payoutCol = new UniTableColumn('Withdrawal', 'Utbetales', UniTableColumnType.Money, this.payrollRunID > 0)
            .setWidth('6rem')
            .setTemplate((row: VacationPayLine) => '' + (UniMath.useFirstTwoDecimals(row.Withdrawal) || ''));

        this.vacationpayBasisTableConfig = new UniTableConfig('salary.vacationpayModalTable', false, true, 20)
            .setColumns([
                nrCol,
                nameCol,
                systemGrunnlagCol,
                manuellGrunnlagCol,
                rateCol,
                sixthCol,
                vacationPayCol,
                earlierPayCol,
                payoutCol,
            ])
            .setSearchable(true)
            .setMultiRowSelect(this.payrollRunID > 0)
            .setAutoAddNewRow(false)
            .setSortable(false)
            .setDefaultOrderBy('Employee.EmployeeNumber', 0)
            .setChangeCallback((event) => {
                const row: VacationPayLine = event.rowModel;
                row['_isDirty'] = true;

                if (event.field === 'Withdrawal') {
                    row['_rowSelected'] = true;
                }
                if (event.field === '_Rate') {
                    row['_rateChanged'] = true;
                }
                if (
                    event.field === '_Rate' ||
                    event.field === 'ManualVacationPayBase' ||
                    event.field === '_IncludeSixthWeek'
                ) {
                    this.updateVacationPayAndWithdrawal(row);
                }
                return row;
            });
    }

    private empOver60(rowModel: VacationPayLine) {
        if (!rowModel.Employee) {
            return false;
        }
        const empAge = rowModel.Year - new Date(rowModel.Employee.BirthDate).getFullYear();
        if (empAge >= 59) {
            if (this.activeYear === rowModel.Year) {
                return true;
            } else {
                return empAge >= 60;
            }
        }
    }

    private isEarlierPayline(row: VacationPayLine) {
        if (!row) {
            return false;
        }
        return this.isEarlierPay(this.activeYear, row.Year);
    }

    private isEarlierPay(currentYear: number, baseYear: number) {
        return baseYear < currentYear - 1;
    }

    private updateVacationPayAndWithdrawal(row: VacationPayLine) {
        this.updateVacationPay(row);
        row.Withdrawal = this.getWidthdrawal(row);
        return row;
    }

    private getWidthdrawal(row: VacationPayLine) {
        return ((row['_VacationPay'] - row.PaidVacationPay) * this.vacationPayFilter.percentPayout) / 100;
    }

    private updateVacationPay(row: VacationPayLine) {
        row['_IncludeSixthWeek'] = this.vacationPayFilter.vacationWeek6 && this.empOver60(row) ? 'Ja' : 'Nei';
        if (!this.isEarlierPayline(row) && row['_isDirty']) {
            return this.locallyCalcVacationPay(row);
        }
        row['_VacationPay'] =
            this.vacationPayFilter.vacationWeek6 && this.empOver60(row) ? row.VacationPay60 : row.VacationPay;
        return row;
    }

    private locallyCalcVacationPay(row: VacationPayLine) {
        const vacBase = row.ManualVacationPayBase + row.SystemVacationPayBase;
        const limitBasicAmount = this.summaryData.basicAmount * 6;
        this.updateAndSetRate(row, row['_rateChanged']);
        if (this.vacationPayFilter.vacationWeek6 && this.empOver60(row)) {
            if (vacBase > limitBasicAmount && !this.summaryData.allowOver6G) {
                row['_VacationPay'] = row.VacationPay60 =
                    this.vacationPayLineService.calcVacation(vacBase, row.Rate) +
                    this.vacationPayLineService.calcVacation(limitBasicAmount, row.Rate60 - row.Rate);
            } else {
                row['_VacationPay'] = row.VacationPay60 = this.vacationPayLineService.calcVacation(vacBase, row.Rate60);
            }
        } else {
            row['_IncludeSixthWeek'] = 'Nei';
            row['_VacationPay'] = row.VacationPay = this.vacationPayLineService.calcVacation(vacBase, row['_Rate']);
        }
        return row;
    }

    private updateAndSetRate(row: VacationPayLine, setManually: boolean) {
        const isOver60: boolean = this.empOver60(row);
        if (setManually) {
            // '_Rate'-column changed
            if (isOver60 && this.vacationPayFilter.vacationWeek6) {
                row.Rate = row['_Rate'];
                row['_Rate'] = row['_Rate'] + this.summaryData.rate60;
                row.Rate60 = row['_Rate'];
            } else {
                row.Rate = row['_Rate'];
                row.Rate60 = row['_Rate'] + this.summaryData.rate60;
            }
        } else {
            if (isOver60 && this.vacationPayFilter.vacationWeek6) {
                row['_Rate'] = row.Rate60;
            } else {
                row['_Rate'] = row.Rate;
            }
        }
        return row;
    }
}
