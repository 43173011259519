<section class="uni-container">
    <section *ngIf="busy" class="modal-spinner">
        <mat-spinner class="c2a"></mat-spinner>
    </section>

    <ag-grid-wrapper
        *ngIf="apikeys && apikeys.length > 0"
        [resource]="apikeys"
        [config]="apikeysConfig"
        (rowDelete)="onRowDeleted($event)"
    >
    </ag-grid-wrapper>

    <button class="secondary" (click)="openApikeyModal()">Legg til integrasjon</button>
</section>
