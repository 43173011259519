import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthService } from '@app/authService';
import { ActionOnReload } from '@app/components/accounting/supplier-invoice/journal-and-pay-helper';
import { BankAgreementServiceProvider } from '@app/models/autobank-models';
import { PaymentBatchService } from '@app/services/accounting/paymentBatchService';
import { PaymentService } from '@app/services/accounting/paymentService';
import { ZDataPaymentService } from '@app/services/bank/ZDataPaymentService';
import { ErrorService } from '@app/services/common/errorService';
import { UserRoleService } from '@app/services/common/userRoleService';
import { UserService } from '@app/services/common/userService';
import {
    InvoicePaymentData,
    PaymentBatch,
    PaymentBatchApproveStatus,
    PreApprovedBankPayments,
    StatusCodeBankIntegrationAgreement,
    StatusCodePaymentBatch,
    SupplierInvoice,
} from '@uni-entities';
import { RequestMethod } from '@uni-framework/core/http';
import { UniModalService } from '@uni-framework/uni-modal';
import { forkJoin, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { PaymentBatchApprovalModalComponent } from '../modals/payment-batch-approval-modal/payment-batch-approval-modal.component';
import { MD5 } from '../utils/utils';
import { THEMES, theme } from 'src/themes/theme';

/*
    TO DEVELOPERS!
    If you add anything to this interface, remember to make it optional if
    you are not adding it from every instance that use this component..
*/
export interface IRGBMetadataInput {
    RGBHeader: string;
    RGBInfoText: string;
    RGBTwoFactorHeader: string;
    RGbTWoFactorInfoText: string;
    ToPaymentListHeader: string;
    ToPaymentListInfoText: string;
}

interface IValueItem {
    selected: boolean;
    label: string;
    infoText: string;
    value: PaymentOption;
    disabled: boolean;
}

export enum PaymentOption {
    ToBank = 1,
    ToPaymentList = 2,
}

@Component({
    selector: 'rgb-component',
    templateUrl: './rgb-component.html',
    styleUrls: ['./rgb-component.sass'],
})
export class RGBComponent {
    @Input() metadata: IRGBMetadataInput = {
        RGBHeader: 'RGB.SEND_INVOICE_TO_BANK',
        RGBInfoText: 'RGB.SEND_INVOICE_TO_BANK_INFOTEXT',
        RGBTwoFactorHeader: 'RGB.SEND_INVOICE_TO_BANK_TWOFACTOR_HEADER',
        RGbTWoFactorInfoText: 'RGB.SEND_INVOICE_TO_BANK_TWOFACTOR_INFOTEXT',
        ToPaymentListHeader: 'RGB.SEND_TO_PAYMENTLIST',
        ToPaymentListInfoText: 'RGB.SEND_INVOICE_TO_PAYMENTLIST_INFOTEXT',
    };

    // Emitted when rgb-compoenent is ready. Emits true if user has rgb, otherwise false
    @Output() onDataLoaded = new EventEmitter<boolean>();

    @Output() onSuccessWithPayload = new EventEmitter<any>();
    @Output() onErrorWithMessage = new EventEmitter<string>();

    supportsBankIDTwoFactor: boolean = false;
    isZDataActiveRGB: boolean = false;

    isZDataV3ActiveAgreement: boolean = false;
    isDnbActiveAgreement: boolean = false;

    canPayButNotVerified = false;
    verification: any;
    disabledTitle = 'Du må ha aktiv autobankavtale for å sende betaling rett til bank.';
    approveInBankText = '';

    VALUE_ITEMS: IValueItem[] = [];

    constructor(
        private paymentBatchService: PaymentBatchService,
        private paymentService: PaymentService,
        private zdataPaymentService: ZDataPaymentService,
        private authService: AuthService,
        private userRoleService: UserRoleService,
        private userService: UserService,
        private errorSerivce: ErrorService,
        private modalService: UniModalService,
    ) {
        forkJoin([
            this.paymentBatchService.checkAutoBankAgreement(),
            this.userRoleService.GetAll(`filter=userid eq ${this.authService.currentUser.ID}`),
            this.userService.get2FADetails(),
        ]).subscribe(
            ([agreements, roles, twoFactorDetails]) => {
                // This is used for Zdata (Aritma)
                this.isZDataActiveRGB = agreements?.some(
                    (a) =>
                        a.ServiceProvider === BankAgreementServiceProvider.ZdataV3 &&
                        a.StatusCode === StatusCodeBankIntegrationAgreement.Active &&
                        (a.PreApprovedBankPayments === PreApprovedBankPayments.Active ||
                            a.PreApprovedBankPayments === PreApprovedBankPayments.WaitForBankCancel),
                );

                this.isZDataV3ActiveAgreement = agreements?.some(
                    (a) =>
                        a.ServiceProvider === BankAgreementServiceProvider.ZdataV3 &&
                        a.StatusCode === StatusCodeBankIntegrationAgreement.Active,
                );

                this.isDnbActiveAgreement = agreements?.some(
                    (a) =>
                        a.ServiceProvider === BankAgreementServiceProvider.Bruno &&
                        a.StatusCode === StatusCodeBankIntegrationAgreement.Active,
                );

                // This is used for direct bankAgreement and Eika in combination with artima
                this.supportsBankIDTwoFactor = this.paymentService.supportsBankIDTwoFactor(agreements);

                let verificationObs = this.isZDataActiveRGB ? this.userService.getBankIdVerification() : of(null);

                verificationObs.subscribe(
                    (verification) => {
                        this.verification = verification;
                        const isAllowedToPayDirectly =
                            this.hasRoleToPayDirectly(roles) &&
                            (this.isZDataV3ActiveAgreement || this.isDnbActiveAgreement);
                        const isZDataTwoFactorNotCompleted =
                            this.isZDataActiveRGB && !twoFactorDetails?.TwoFactorEnabled;
                        const isZDataBankIDNotVerified = this.isZDataActiveRGB && !verification?.IsVerified;

                        this.VALUE_ITEMS = this.getValueItems();

                        if (!isAllowedToPayDirectly || isZDataTwoFactorNotCompleted || isZDataBankIDNotVerified) {
                            this.VALUE_ITEMS[0].disabled = true;
                            this.valueItemSelected(this.VALUE_ITEMS[1]);

                            if (isZDataTwoFactorNotCompleted && theme.theme !== THEMES.EIKA) {
                                this.disabledTitle =
                                    'To-faktor må være påskrudd for at din bruker skal få tilgang til regnskapsgodkjente betalinger. Dette finner du under Min profil - Brukerinnstillinger.';
                            }

                            if (isZDataBankIDNotVerified) {
                                this.canPayButNotVerified = true;
                                this.disabledTitle =
                                    'Din bruker er ikke verfisert med BankID hos Aritma eller 90 dager har gått slik at verfiseringen har utløpt.';
                            }
                        }
                        this.onDataLoaded.emit(
                            !(!isAllowedToPayDirectly || isZDataTwoFactorNotCompleted || isZDataBankIDNotVerified),
                        );
                    },
                    (err) => {
                        this.errorSerivce.handle(err);
                        this.onDataLoaded.emit(false);
                        this.VALUE_ITEMS = this.getValueItems();

                        this.VALUE_ITEMS[0].disabled = true;
                        this.valueItemSelected(this.VALUE_ITEMS[1]);
                    },
                );
            },
            (err) => {
                this.errorSerivce.handle(err);
                this.onDataLoaded.emit(false);
                this.VALUE_ITEMS = this.getValueItems();

                this.VALUE_ITEMS[0].disabled = true;
                this.valueItemSelected(this.VALUE_ITEMS[1]);
            },
        );
    }

    /**
     *
     * @param transactionIDs
     * @param onBankIDResponseURL !!only set this param when the return url has a on redirect handler!!
     */
    sendPaymentsToBank(transactionIDs: string[], onBankIDResponseURL?: string) {
        // If the user can directly approve with bankID
        const filter = this.generateTransactionFilterString(transactionIDs);
        // Needs error handling
        this.paymentService
            .getHashForPayments(filter, '')
            .pipe(switchMap((hash) => of(hash)))
            .pipe(catchError((x) => of('')))
            .subscribe((hash) => {
                const hashAndFilter = `&hash=${hash}&filter=${filter}`;

                this.paymentService
                    .createPaymentBatchForAll(false, hashAndFilter)
                    .pipe(
                        switchMap((response) => {
                            if (this.isHangfireJobResponse(response)) {
                                return this.paymentBatchService.waitUntilJobCompleted(response.ID).pipe(
                                    switchMap((batchJobResponse) => {
                                        return this.verifyJobSucsess(batchJobResponse) && batchJobResponse.Result.ID > 0
                                            ? of(batchJobResponse.Result)
                                            : of({});
                                    }),
                                );
                            } else {
                                return of(response);
                            }
                        }),
                    )
                    .subscribe(
                        (paymentBatch: PaymentBatch) => {
                            if (!paymentBatch?.ID) {
                                this.onErrorWithMessage.emit('Kunne ikke opprette betalingsbunt');
                            }

                            if (this.supportsBankIDTwoFactor) {
                                this.paymentBatchService.redirectToBankID(paymentBatch, onBankIDResponseURL);
                            } else {
                                if (this.isZDataActiveRGB) {
                                    this.zdataPaymentService
                                        .sendPaymentWithTwoFactor(paymentBatch)
                                        .then((approvalStatus: PaymentBatchApproveStatus) => {
                                            if (approvalStatus) {
                                                if (approvalStatus.StatusCode === StatusCodePaymentBatch.ForApproval) {
                                                    this.modalService
                                                        .open(PaymentBatchApprovalModalComponent, {
                                                            data: {
                                                                paymentBatch: paymentBatch,
                                                                approvalStatus: approvalStatus,
                                                                userID: this.authService.currentUser.ID,
                                                            },
                                                            closeOnClickOutside: false,
                                                        })
                                                        .onClose.subscribe((response) => {
                                                            if (response) {
                                                                this.onSuccessWithPayload.emit(
                                                                    'Tildelt og sendt til bank',
                                                                );
                                                            } else {
                                                                this.revert(paymentBatch);
                                                                this.onErrorWithMessage.emit(
                                                                    'Tildeling av godkjennere ble avbrutt',
                                                                );
                                                            }
                                                        });
                                                } else {
                                                    this.onSuccessWithPayload.emit('');
                                                }
                                            } else {
                                                this.revert(paymentBatch);
                                            }
                                        })
                                        .catch((err) => {
                                            if (err) {
                                                this.errorSerivce.handle(err);
                                            }
                                            this.revert(paymentBatch);
                                        });
                                } else {
                                    const body = {
                                        Code: null,
                                        Password: null,
                                    };
                                    this.paymentBatchService
                                        .sendToPayment(paymentBatch.ID, body)
                                        .subscribe((approvalStatus: PaymentBatchApproveStatus) => {
                                            if (approvalStatus.StatusCode === StatusCodePaymentBatch.ForApproval) {
                                                this.modalService
                                                    .open(PaymentBatchApprovalModalComponent, {
                                                        data: {
                                                            paymentBatch: paymentBatch,
                                                            approvalStatus: approvalStatus,
                                                            userID: this.authService.currentUser.ID,
                                                        },
                                                        closeOnClickOutside: false,
                                                    })
                                                    .onClose.subscribe((response) => {
                                                        if (response) {
                                                            this.onSuccessWithPayload.emit('Tildelt og sendt til bank');
                                                        } else {
                                                            this.onErrorWithMessage.emit(
                                                                'Tildeling av godkjennere ble avbrutt',
                                                            );
                                                        }
                                                    });
                                            } else {
                                                this.onSuccessWithPayload.emit('');
                                            }
                                        }),
                                        (err) => {
                                            this.onErrorWithMessage.emit('Klarte ikke å sende betalingsbunt');
                                            this.errorSerivce.handle(err);
                                        };
                                }
                            }
                        },
                        (err) => {
                            this.onErrorWithMessage.emit('Klarte ikke opprette betalingsbunt');
                            this.errorSerivce.handle(err);
                        },
                    );
            });
    }

    // This function will revert paymentbatches if ZData two-factor failed
    revert(paymentBatch: PaymentBatch) {
        let message = 'Betaling avbrutt';
        this.paymentBatchService
            .revertPaymentBatch(paymentBatch.ID, false)
            .pipe(
                switchMap(() => {
                    if (paymentBatch.Payments?.length) {
                        return this.paymentService.ActionWithBody(
                            null,
                            paymentBatch.Payments.map((payment) => payment.ID),
                            'batch-delete-and-credit',
                            RequestMethod.Put,
                            'credit=false',
                        );
                    }
                    return of(true);
                }),
                catchError((err) => {
                    // Could not delete the payment that was created..
                    message =
                        'Noe gikk galt da vi prøvde å slette betalingen du har startet på. For å slette den, gå til Bank - Utbetalinger';
                    return of(true);
                }),
            )
            .subscribe(
                () => {
                    this.onErrorWithMessage.emit(message);
                },
                (err) => {
                    this.onErrorWithMessage.emit(
                        'Noe gikk galt da vi prøvde å gjenopprette betalingen som ikke gikk til banken. Vennligst gå til Utbetalingsbunter og velg "Legg tilbake til ubetalt" på linjenivå',
                    );
                },
            );
    }

    valueItemSelected(item: any) {
        if (item.selected || item.disabled) {
            return;
        } else {
            this.VALUE_ITEMS.forEach((i) => (i.selected = false));
            item.selected = true;
        }
    }

    private getValueItems(): IValueItem[] {
        this.approveInBankText =
            (this.isZDataV3ActiveAgreement && this.isZDataActiveRGB) || this.supportsBankIDTwoFactor
                ? ''
                : 'Du må logge deg på nettbanken din for å godkjenne utbetalingen.';
        const items = [
            {
                selected: true,
                label: this.metadata.RGBHeader,
                infoText: this.metadata.RGBInfoText,
                value: PaymentOption.ToBank,
                disabled: false,
            },
            {
                selected: false,
                label: this.metadata.ToPaymentListHeader,
                infoText: this.metadata.ToPaymentListInfoText,
                value: PaymentOption.ToPaymentList,
                disabled: false,
            },
        ];

        if (this.supportsBankIDTwoFactor) {
            items.splice(0, 1, {
                selected: true,
                label: this.metadata.RGBTwoFactorHeader,
                infoText: this.metadata.RGbTWoFactorInfoText,
                value: PaymentOption.ToBank,
                disabled: false,
            });
        }

        return items;
    }

    goToVerifyWithBankID() {
        if (!this.verification?.ClientID) {
            this.onErrorWithMessage.emit(
                'Klarte ikke finne bruker som skal verifiseres. Om din bruker har tilgang til regnskapsgodkjente betalinger, prøv å lukk dialog og start igjen.',
            );
            this.canPayButNotVerified = false;
            return;
        }
        window.location.href = this.userService.getBankIdRedirectUrl(window.location.href, this.verification.ClientID);
    }

    generateTransactionFilterString(transactionIDs: string[]): string {
        return transactionIDs.map((id) => `Payment.TransactionID eq '${id}'`).join(' or ');
    }

    sendAutobankPayment(payments: number[], onlyToPayment: boolean = false) {
        this.paymentBatchService.sendAutobankPayment({ Code: null, Password: null, PaymentIds: payments }).subscribe(
            () => {
                this.onSuccessWithPayload.emit(ActionOnReload.SentToBank);
            },
            (err) => {
                this.onErrorWithMessage.emit('Klarte ikke opprette betalingsbunt');
                this.errorSerivce.handle(err);
            },
        );
    }

    generateHashForInvoicePaymentData(invoicePaymentData: InvoicePaymentData, supplierInvoice: SupplierInvoice) {
        const string =
            invoicePaymentData.Amount.toFixed(2) +
            ';' +
            invoicePaymentData.AmountCurrency.toFixed(2) +
            ';' +
            invoicePaymentData.CurrencyCodeID.toString() +
            ';' +
            supplierInvoice.BankAccount.AccountNumber +
            ';' +
            (supplierInvoice.BankAccount.IBAN === null ? '' : supplierInvoice.BankAccount.IBAN) +
            '|';
        return MD5(string);
    }

    // So that parent component can lock RGB option. RGB criteria could be met, but other issues are making the
    // option invalid. Initial reason why we need this is that aga payment can create payments with different
    // from accounts, and this is not supported by ZData (Aritma) yet..
    remoteLockRGBOption() {
        this.VALUE_ITEMS[0].disabled = true;
        this.valueItemSelected(this.VALUE_ITEMS[1]);
    }

    remoteOpenRGBOption() {
        this.VALUE_ITEMS[0].disabled = false;
    }

    private hasRoleToPayDirectly(roles): boolean {
        return roles?.some(
            (role) =>
                role.SharedRoleName === 'Bank.Admin' ||
                role.SharedRoleName === 'Bank.Payment' ||
                role.SharedRoleName === 'Administrator' ||
                role.SharedRoleName === 'Accounting.Admin' ||
                role.SharedRoleName === 'Payroll.Admin',
        );
    }

    private isHangfireJobResponse(response: any): boolean {
        return (response && response?.Value && response?.Value?.ProgressUrl) || (response && response?.ProgressUrl);
    }

    private verifyJobSucsess(jobResponse) {
        return jobResponse && !jobResponse?.HasError && jobResponse?.Result;
    }
}
