<section role="dialog" class="uni-modal">
    <header>Betingelser for import av faktura</header>
    <article>
        <p>
            For å opprette faktura basert på fil må du godta at systemet overstyrer fakturanummerserien. Ved å godta
            dette vil ikke systemet sørge for at fakturanummer er løpende.
        </p>

        <div>
            <rig-checkbox [(ngModel)]="termsAccepted"> Jeg godtar vilkår og betingelser </rig-checkbox>
        </div>
    </article>

    <footer>
        <button class="secondary" (click)="onClose.emit()" [disabled]="saving">Lukk</button>
        <button class="c2a" (click)="save()" [disabled]="!termsAccepted" [attr.aria-busy]="saving">Bekreft</button>
    </footer>
</section>
