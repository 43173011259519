import { Component, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IModalOptions, IUniModal } from '@uni-framework/uni-modal';
import { AuditLog } from '@uni-entities';

import { rigDate } from '@app/components/common/utils/rig-date';
import { ErrorService } from '@app/services/common/errorService';
import { UserService } from '@app/services/common/userService';
import { THEMES, theme } from 'src/themes/theme';
import { CurrentUserWithout2FADetails } from '@app/authService';

@Component({
    selector: 'disclaimer-modal',
    templateUrl: './disclaimer-modal.html',
    styleUrls: ['./disclaimer-modal.sass'],
})
export class DisclaimerModal implements IUniModal {
    options: IModalOptions = {};
    onClose = new EventEmitter();

    user: CurrentUserWithout2FADetails;
    hasAgreed: boolean = false;
    agreedDate: string;

    termsAccepted: boolean = false;

    loading: boolean = true;
    saving: boolean = false;

    isBank: boolean = theme.theme === THEMES.SR || theme.theme === THEMES.EXT02 || theme.theme === THEMES.EIKA;

    constructor(
        private http: HttpClient,
        private userService: UserService,
        private errorService: ErrorService,
    ) {
        this.userService.getCurrentUser().subscribe(
            (user) => {
                if (user) {
                    this.user = user;
                    this.hasAgreed = user.HasAgreedToImportDisclaimer;

                    if (this.hasAgreed) {
                        this.getDisclaimerAuditLog().subscribe((auditLog) => {
                            const latest = auditLog?.pop();
                            this.agreedDate = latest && rigDate(latest.CreatedAt).format('DD.MM.YYYY');
                            this.loading = false;
                        });
                    } else {
                        this.loading = false;
                    }
                }
            },
            (err) => {
                this.loading = false;
                this.errorService.handle(err);
            },
        );
    }

    private getDisclaimerAuditLog() {
        const url = `/api/biz/auditlogs?filter=EntityType eq 'User' and EntityID eq ${this.user.ID} and Field eq 'HasAgreedToImportDisclaimer'`;
        return this.http.get<AuditLog[]>(url);
    }

    save() {
        this.saving = true;
        this.userService
            .Put(this.user.ID, {
                ID: this.user.ID,
                HasAgreedToImportDisclaimer: this.termsAccepted,
            })
            .subscribe(
                (res) => {
                    if (res.HasAgreedToImportDisclaimer) {
                        this.onClose.emit(true);
                    } else {
                        this.errorService.handle('En feil oppstod, vennligst prøv igjen senere');
                        this.saving = false;
                    }
                },
                (err) => {
                    this.errorService.handle(err);
                    this.saving = false;
                },
            );
    }
}
