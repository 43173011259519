export class ImportDialogModel {
    Url: string;
    CompanyKey: string;
    CompanyName: string;
    ImportFileType: ImportFileType;
    ImportOption: ImportOption;
}

export enum ImportFileType {
    StandardizedExcelFormat,
    StandardUniFormat,
    StandardizedCSVFormat,
}

export enum ImportOption {
    Skip,
    Override,
    Duplicate,
}

export enum VoucherOptions {
    Draft,
    Post,
}

export enum OrderOptions {
    Draft,
    Post,
}

export enum InvoiceOptions {
    V3,
    Ue,
}

export enum TemplateType {
    All,
    Customer,
    Product,
    Supplier,
    MainLedger,
    Payroll,
    Saft,
    Voucher,
    Order,
    Invoice,
    Employee,
    RecurringPayroll,
}

export interface ImportDateFormat {
    name: string;
    type: string;
}

export const IMPORT_DATE_FORMATS: ImportDateFormat[] = [
    { name: 'ddMMyy', type: 'ddMMyy' },
    { name: 'dd.MM.yyyy', type: 'dd.MM.yyyy' },
    { name: 'dd/MM/yyyy', type: 'dd/MM/yyyy' },
    { name: 'MM.dd.yyyy', type: 'MM.dd.yyyy' },
    { name: 'MM/dd/yyyy', type: 'MM/dd/yyyy' },
];

export enum ImportJobName {
    Customer = 'CustomerImportJob',
    Product = 'ProductImportJob',
    Supplier = 'SupplierImportJob',
    MainLedger = 'MainLedgerImportJob',
    Payroll = 'PayrollmportJob',
    Saft = 'ImportSaft',
    Voucher = 'VoucherImportJob',
    Order = 'OrderImportJob',
    Invoice = 'InvoiceImportJob',
    RecurringPayroll = 'SalaryRecurringTransactionImportJob',
}

export enum ImportStatement {
    ProductFormatStatement = `Importen støtter også Uni standard format (*.txt, rectype '70'). For bruk til import fra Uni økonomi V3.(NB! Salgskonto på varen setter mva-kode. Importen håndterer bare priser med eks.mva, varer med mva-kode '1' vil få feil pris)`,
    ProductOverwriteStatement = `Excelimporten beholder gammel data på produktene hvis feltene er tomme. Dersom du ønsker å slette data i felter kan dette gjøres ved å sette et minustegn (-). `,

    CustomerFormatStatement = `Importen støtter også Uni standard format (*.txt, rectype '30'). For bruk til import fra Uni økonomi V3.`,

    SupplierFormatStatement = `Importen støtter også Uni standard format (*.txt, rectype '40'). For bruk til import fra Uni økonomi V3.`,

    MainLedgerConditionalStatement = 'Importen støtter kontoplan med nummer mellom 1000 - 8999, kontoer utenfor denne serien blir ignorert.',
    MainLedgerFormatStatement = `Importen støtter også Uni standard format (*.txt, rectype '20'). For bruk til import fra Uni økonomi V3.`,

    OrderConditionalStatement = 'Hvis ordrenr. fra fil eksisterer i systemet fra før så vil importen gi nye ordrenr. basert på neste ledige.',
    OrderFormatStatement = `Importen støtter også Uni standard formatet (*.txt, rectype '85','86')`,

    InvoiceConditionalStatement = 'Fakturaene kan ikke ha samme fakturanummer som en eksisterende faktura.',
    InvoiceFormatStatement = `Importen støtter også Uni standard formatet (*.txt, rectype '80','81')`,
}
