import { Component, Input } from '@angular/core';
import { CompanyBankAccountModal } from '../../../../common/modals/bank-account-modal/company-bank-account-modal';
import { UniModalService } from '@uni-framework/uni-modal';
import { BankAccount, CompanySettings } from '@uni-entities';
import { StartupTaskService } from '../../startup-task-service';
import { ImageModal } from '@app/components/common/modals/ImageModal';
import { Router } from '@angular/router';
import { theme, THEMES } from 'src/themes/theme';
import { CompanySettingsService } from '@app/services/common/companySettingsService';

@Component({
    selector: 'startup-shortcuts',
    templateUrl: './startup-shortcuts.html',
    styleUrls: ['./startup-shortcuts.sass'],
})
export class StartupTaskShortcuts {
    @Input()
    public companySettings: CompanySettings;

    shortCuts = [
        { icon: 'account_balance', label: 'Legg til bankkonto', value: 1 },
        { icon: 'maps_ugc', label: 'Legg til logo', value: 2 },
        { icon: 'group_add', label: 'Inviter brukere', value: 3 },
        { icon: 'bar_chart', label: 'Importer data', value: 4 },
    ];

    constructor(
        public service: StartupTaskService,
        private modalService: UniModalService,
        private companySettingsService: CompanySettingsService,
        private router: Router,
    ) {
        if (theme.theme === THEMES.EXT02) {
            this.shortCuts = [
                { icon: 'account_balance', label: 'Bankavstemming', value: 7 },
                { icon: 'group_add', label: 'Inviter brukere', value: 3 },
                { icon: 'help_outline', label: 'Hjelp og veileding', value: 5 },
                { icon: 'verified', label: 'Regnskapsfører', value: 6 },
            ];
        }
    }

    onShortCutClicked(shortcut) {
        switch (shortcut.value) {
            case 1:
                this.addBankAccount();
                break;
            case 2:
                this.addLogo();
                break;
            case 3:
                this.router.navigateByUrl('/settings/users');
                break;
            case 4:
                this.router.navigateByUrl('/import/page');
                break;
            case 5:
                window.open('https://hjelp.dnbregnskap.dnb.no/no/', '_blank').focus();
                break;
            case 6:
                this.router.navigateByUrl('/marketplace/agencies');
                break;
            case 7:
                this.router.navigateByUrl('/bank/reconciliation?tabIndex=0');
                break;
        }
    }

    addBankAccount() {
        const bankaccount = <BankAccount>{};
        bankaccount.BankAccountType = 'company';

        this.modalService.open(CompanyBankAccountModal, {
            data: {
                bankAccount: bankaccount,
                isFirst: !this.companySettings.CompanyBankAccountID,
            },
            header: 'Legg til bankkonto',
            closeOnClickOutside: false,
        });
    }

    addLogo() {
        const options = {
            data: {
                entity: 'CompanySettings',
                entityID: 1,
            },
        };
        this.modalService.open(ImageModal, options).onClose.subscribe({
            next: (value) => {
                const fileID = (value?.files && value.files[0]?.ID) || 0;
                if (this.companySettings.LogoFileID !== fileID) {
                    this.companySettingsService.Put(1, { ID: 1, LogoFileID: fileID }).subscribe();
                }
            },
        });
    }
}
