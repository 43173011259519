import { Component, EventEmitter } from '@angular/core';
import { FieldType } from '@uni-framework/ui/uniform/index';
import { AccrualPeriod, InvoiceAccrualDefinition, LocalDate } from '@uni-entities';
import { ToastService, ToastType } from '@uni-framework/uniToast/toastService';
import { IUniModal, IModalOptions } from '@uni-framework/uni-modal';
import { forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { JournalEntryService } from '@app/services/accounting/journalEntryService';
import { AccountService } from '@app/services/accounting/accountService';
import { rigDate } from '../../utils/rig-date';

interface IPeriodError {
    periodNotValidError: boolean;
    periodLockedError: boolean;
    fromPeriodLocked: boolean;
    toPeriodLocked: boolean;
}

@Component({
    selector: 'accrual-definition-modal',
    templateUrl: './accrual-definition-modal.html',
})
export class AccrualDefinitionModal implements IUniModal {
    options: IModalOptions;
    onClose = new EventEmitter();

    model: InvoiceAccrualDefinition | any;
    accrualFields = [];
    shiftStartPeriod = [];
    accountFields = [];

    shiftStartPeriodNumber: number;
    accrualMonthInfoText: string;
    shiftPeriodStartInfoText: string;

    originalAccrualPeriods: Array<AccrualPeriod>;

    buttonsDisabled: boolean = false;
    hideRemoveButton: boolean = false;

    periodError: IPeriodError = {
        periodNotValidError: false,
        periodLockedError: false,
        fromPeriodLocked: false,
        toPeriodLocked: false,
    };

    accrualStartDate: string;
    accrualMonth: string;
    accrualEndDate: string;

    constructor(
        private toastService: ToastService,
        private accountService: AccountService,
    ) {}

    ngOnInit() {
        let accrual = null;
        if (this.options.data.accrual) {
            accrual = { ...this.options.data.accrual };
        }
        this.hideRemoveButton = this.options.data.hideRemoveButton;
        const accrualStartDate = this.options.data.accrualStartDate;
        this.accrualStartDate = rigDate(this.options.data.accrualStartDate).format('DD.MM.YY');
        this.accrualMonth = rigDate(this.options.data.accrualStartDate).format('MMM YY');
        this.accrualEndDate = rigDate(this.options.data.accrualStartDate)
            .add(this.options?.data.numberOfPeriods - 1, 'month')
            .format('MMM YY');

        if (!(accrual || accrualStartDate)) {
            this.toastService.addToast('Periodisering', ToastType.bad, 10, 'Mangler informasjon om beløp og dato!');
            this.onClose.emit(false);
            return;
        }
        this.setUpAccrual(accrual, accrualStartDate);
        this.setupForm();
    }

    onSaveClick() {
        if (this.validateAccrual()) {
            this.model['PeriodYear'] = this.model['StartPeriod'].year;
            this.model['StartPeriod'] = this.model['StartPeriod'].month;
            this.onClose.emit({
                action: 'ok',
                model: this.model,
            });
        }
    }

    onFormChange(event) {
        if (event['StartPeriod'] || event['_periodTo']) {
            let start: LocalDate;
            let end: LocalDate;

            if (event['StartPeriod']) {
                start = event['StartPeriod'].currentValue;
                end = this.model['_periodTo'];
            } else if (event['_periodTo']) {
                start = this.model['StartPeriod'];
                end = event['_periodTo'].currentValue;
            }
            let startPeriod = new Date(rigDate(start).toLocaleString());
            let endPeriod = new Date(rigDate(end).toLocaleString());
            this.model['NumberOfPeriods'] = this.getNumberOfPeriods(startPeriod, endPeriod);
            this.model = { ...this.model };
        }
    }

    private validateAccrual(): boolean {
        let valid = true;

        if (this.model.NumberOfPeriods <= 0 || this.model.NumberOfPeriods > 120) {
            this.toastService.addToast(
                'Periodisering',
                ToastType.bad,
                10,
                'Periodisering må være minst 1 periode, og maks 120 perioder fra første periode',
            );

            valid = false;
        }

        if (!this.model.BalanceAccountID) {
            this.toastService.addToast('Periodisering', ToastType.bad, 10, 'Periodiseringen mangler balansekonto');
            valid = false;
        }
        if (!this.model.ResultAccountID) {
            this.toastService.addToast('Periodisering', ToastType.bad, 10, 'Periodiseringen mangler resultatkonto');
            valid = false;
        }
        return valid;
    }

    private setUpAccrual(accrual, accrualStartDate) {
        let startPeriod;
        let endPeriod;
        if (!accrual) {
            accrual = {};
            accrual['_createguid'] = this.accountService.getNewGuid();
            accrual['StartPeriod'] = new LocalDate(new Date(accrualStartDate.year, accrualStartDate.month, 1));
            accrual['_periodTo'] = new LocalDate(rigDate(accrual['StartPeriod']).add(2, 'month').toLocaleString());
            startPeriod = new Date(rigDate(accrual['StartPeriod']).toLocaleString());
            endPeriod = new Date(rigDate(accrual['_periodTo']).toLocaleString());
            accrual['NumberOfPeriods'] = this.getNumberOfPeriods(startPeriod, endPeriod);
            accrual['ShiftStartPeriod'] = 0;
        } else {
            accrual['StartPeriod'] = new LocalDate(new Date(accrual['PeriodYear'], accrual['StartPeriod'] - 1, 1));
            accrual['_periodTo'] = new LocalDate(
                rigDate(accrual['StartPeriod'])
                    .add(accrual['NumberOfPeriods'] - 1, 'month')
                    .toLocaleString(),
            );
        }
        (accrual['_placeHolderStartPeriod'] = 'Fakturamåned'), (this.model = { ...accrual });
        this.shiftStartPeriodNumber = accrual['ShiftStartPeriod'];

        if (this.options?.data.nextInvoiceDate) {
            this.setAccrualMonthInfoText();
        }
    }

    private getNumberOfPeriods(start, end): number {
        if (this.options.data?.parent === 'recurring-invoice') {
            return this.options.data?.numberOfPeriods;
        }
        let months;
        months = (end.getFullYear() - start.getFullYear()) * 12;
        months -= start.getMonth();
        months += end.getMonth();
        months += 1;
        return months <= 0 ? 0 : months;
    }

    private setupForm() {
        this.accrualFields = this.getAccrualFields();
        this.shiftStartPeriod = this.getShiftStartPeriodField();
        this.accountFields = this.getAccountFields();

        const isAccrualAccrued = this.model?.JournalEntryLineDraftID > 0;
        if (isAccrualAccrued) {
            this.toastService.addToast(
                'Periodisering',
                ToastType.warn,
                8,
                'Denne periodiseringen er allerede periodisert, og kan ikke redigere ytterligere',
            );

            this.buttonsDisabled = true;
        } else {
            this.buttonsDisabled = false;
        }

        const model = this.model;

        let balanceAccountLookup = of([]);
        let resultAccountLookup = of([]);

        if (!model.BalanceAccountID) {
            balanceAccountLookup = this.accountLookup(`AccountNumber eq 2900`);
        }

        if (!model.ResultAccountID) {
            const defaultAccountID = this.options.data.companySettings?.DefaultAccrualAccountID;
            const resultAccountFilter = defaultAccountID ? `ID eq ${defaultAccountID}` : `AccountNumber eq 3901`;
            resultAccountLookup = this.accountLookup(resultAccountFilter);
        }

        forkJoin([balanceAccountLookup, resultAccountLookup]).subscribe(([balanceAccount, resultAccount]) => {
            if (balanceAccount && balanceAccount[0]) {
                model.BalanceAccountID = balanceAccount[0].ID;
            }

            if (resultAccount && resultAccount[0]) {
                model.ResultAccountID = resultAccount[0].ID;
            }
            this.model = { ...model };
        });
    }

    private getAccrualFields() {
        const periodNotValidError = this.periodError.periodNotValidError;
        const periodLockedError = this.periodError.periodLockedError;
        const fromPeriodLocked = this.periodError.fromPeriodLocked;
        const toPeriodLocked = this.periodError.toPeriodLocked;

        return [
            {
                Property: '_placeHolderStartPeriod',
                FieldType: FieldType.TEXT,
                Label: 'Periodiseringsstart',
                ReadOnly: true,
                Hidden: this.options.data?.parent !== 'recurring-invoice',
            },
            {
                EntityType: 'InvoiceAccrualDefinition',
                Property: 'StartPeriod',
                FieldType: FieldType.MONTH_PICKER,
                Label: 'Periodiseringsstart',
                Classes: periodNotValidError || (periodLockedError && fromPeriodLocked) ? 'error' : '',
                Hidden: this.options.data?.parent === 'recurring-invoice',
            },
            {
                EntityType: 'InvoiceAccrualDefinition',
                Property: '_periodTo',
                FieldType: FieldType.MONTH_PICKER,
                Label: 'Periodiseringsslutt',
                Hidden: this.options.data?.parent === 'recurring-invoice',
                Classes: periodNotValidError || (periodLockedError && toPeriodLocked) ? 'error' : '',
            },
            {
                EntityType: 'InvoiceAccrualDefinition',
                Property: 'NumberOfPeriods',
                FieldType: FieldType.NUMERIC,
                ReadOnly: this.options.data?.parent !== 'recurring-invoice',
                Label: 'Antall Perioder',
            },
        ];
    }

    private getShiftStartPeriodField() {
        return [
            {
                EntityType: 'InvoiceAccrualDefinition',
                Property: 'ShiftStartPeriod',
                FieldType: FieldType.DROPDOWN,
                ReadOnly: !this.hideRemoveButton,
                Label: 'Utsett periodiseringsstart',
                Options: {
                    hideDeleteButton: true,
                    searchable: false,
                    valueProperty: 'Number',
                    displayProperty: 'Text',
                    source: [
                        { Number: 0, Text: 'Ingen utsettelse' },
                        { Number: 1, Text: '1 måned' },
                        { Number: 2, Text: '2 måneder' },
                        { Number: 3, Text: '3 måneder' },
                    ],
                },
            },
        ];
    }

    private getAccountFields() {
        return [
            {
                EntityType: 'InvoiceAccrualDefinition',
                Property: 'BalanceAccountID',
                Label: 'Balansekonto',
                Sectionheader: 'Periodiseringskonto',
                ReadOnly: !this.hideRemoveButton,
                Tooltip: {
                    Text:
                        'Normalt brukes en konto i 17-serien for forskuddsbetalte kostnader og påløpte inntekter, ' +
                        'og en konto i 29-serien brukes for påløpte kostnader og uopptjente inntekter',
                },
                Section: 2,
                FieldType: FieldType.AUTOCOMPLETE,
                Options: this.getAccountSearchConfig(),
            },
            {
                EntityType: 'InvoiceAccrualDefinition',
                Property: 'ResultAccountID',
                Label: 'Resultatkonto',
                Sectionheader: 'Periodiseringskonto',
                ReadOnly: !this.hideRemoveButton,
                Section: 2,
                FieldType: FieldType.AUTOCOMPLETE,
                Options: this.getAccountSearchConfig(),
            },
        ];
    }

    private getAccountSearchConfig() {
        return {
            valueProperty: 'ID',
            debounceTime: 200,
            template: (account) => account && `${account.AccountNumber} - ${account.AccountName}`,
            search: (searchText: string) => {
                let filter = 'Account.Visible eq 1 and isnull(Account.AccountID,0) eq 0';

                if (searchText?.length) {
                    const searchFilter = !isNaN(parseInt(searchText[0]))
                        ? `startswith(Account.AccountNumber,'${searchText}')`
                        : `contains(Account.AccountName,'${searchText}')`;

                    filter += ` and ( ${searchFilter} )`;
                } // add 17xx filter

                return this.accountLookup(filter).pipe(
                    map((accounts) => {
                        // Put exact account number match on top of result list
                        if (accounts?.length > 1 && searchText?.length === 4 && !isNaN(parseInt(searchText))) {
                            const exactMatchIndex = accounts.findIndex(
                                (acc) => acc.AccountNumber === parseInt(searchText),
                            );
                            if (exactMatchIndex > 0) {
                                accounts.splice(0, 0, accounts.splice(exactMatchIndex, 1)[0]);
                            }
                        }
                        return accounts;
                    }),
                );
            },
            getDefaultData: (modelValue) => {
                return modelValue ? this.accountLookup(`ID eq ${modelValue}`) : of([]);
            },
        };
    }

    private accountLookup(filter: string) {
        const selects = ['ID as ID', 'AccountNumber as AccountNumber', 'AccountName as AccountName'];

        const odata = `model=Account&select=${selects.join(',')}&filter=${filter}&orderby=AccountNumber&top=25`;
        return this.accountService.cachedSearch(odata);
    }

    private setAccrualMonthInfoText(event?: any) {
        const nextInvoiceMonthIndex = this.options?.data.nextInvoiceDate?.month
            ? this.options?.data.nextInvoiceDate?.month
            : new Date(this.options?.data.nextInvoiceDate).getMonth();

        const numberOfPeriods = this.options?.data.numberOfPeriods;
        const shiftStartPeriod = !event?.ShiftStartPeriod.currentValue
            ? this.shiftStartPeriodNumber
            : event.ShiftStartPeriod.currentValue;

        let startMonthIndex = nextInvoiceMonthIndex + shiftStartPeriod;
        if (startMonthIndex > 11) {
            startMonthIndex = startMonthIndex - 12;
        }

        const month = new Date();
        month.setMonth(startMonthIndex);

        const startMonthName = month.toLocaleString([], {
            month: 'long',
        });

        let endMonthIndex = startMonthIndex + numberOfPeriods - 1;
        if (endMonthIndex > 11) {
            endMonthIndex = endMonthIndex - 12;
        }
        const endMonth = new Date();
        endMonth.setMonth(endMonthIndex);

        const endMonthName = endMonth.toLocaleString([], {
            month: 'long',
        });

        this.accrualMonthInfoText =
            startMonthIndex != endMonthIndex ? startMonthName + ' - ' + endMonthName : startMonthName;

        this.shiftPeriodStartInfoText =
            shiftStartPeriod === 0
                ? 'Ingen utsettelse'
                : shiftStartPeriod === 1
                  ? shiftStartPeriod + ' måned'
                  : shiftStartPeriod + ' måneder';
    }
}
