<div
    #listElement
    class="link-list flex-col rounded custom-scrollbar"
    [class.open]="open"
    [class.popover]="popover"
    [ngStyle]="popoverStyles$ | async"
    (focusout)="onFocusOut()"
>
    <ng-content></ng-content>
</div>
