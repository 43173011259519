<section role="dialog" class="uni-modal">
    <header>Opprett nytt selskap</header>
    <article>
        <section *ngIf="busy" class="modal-spinner">
            <mat-spinner class="c2a"></mat-spinner>
        </section>

        <section class="alert info">
            <i class="material-icons">info_outline</i>
            Det kan påløpe ekstra kostnad per måned.
        </section>

        <mat-stepper
            [linear]="true"
            (selectionChange)="currentStep = $event.selectedIndex"
            #stepper
            [@.disabled]="true"
        >
            <mat-step label="Selskapsinformasjon" [completed]="companyInfo.valid">
                <uni-select-company
                    *ngIf="currentStep === steps.selectCompany"
                    [(companyInfo)]="companyInfo"
                    (companyInfoChange)="onCompanyInfoChange()"
                ></uni-select-company>
            </mat-step>

            <mat-step label="Malklient" *ngIf="templateCompanies?.length && !companyInfo?.isTemplate">
                <uni-select-template
                    [templateCompanies]="templateCompanies"
                    [(selectedTemplateCompany)]="selectedTemplateCompany"
                ></uni-select-template>
            </mat-step>
        </mat-stepper>
    </article>

    <footer>
        <button class="secondary pull-left" (click)="close()">Avbryt</button>

        <button class="secondary" (click)="stepper.previous()" [disabled]="currentStep === 0">Tilbake</button>

        <button
            class="c2a"
            *ngIf="currentStep < lastStep"
            (click)="stepper.next()"
            [disabled]="!stepper.selected?.completed"
        >
            Neste
        </button>

        <button class="c2a" *ngIf="currentStep === lastStep" (click)="createCompany()">Opprett selskap</button>
    </footer>
</section>
