import { Component, Input, ViewChild } from '@angular/core';
import { UniTableConfig, UniTableColumn, UniTableColumnType } from '@uni-framework/ui/unitable';
import { IWizardOptions } from './wizardoptions';
import { AgGridWrapper } from '@uni-framework/ui/ag-grid/ag-grid-wrapper';
import { InvoiceHourService } from '@app/services/timetracking/invoice-hours.service';

@Component({
    selector: 'workitem-transfer-wizard-products',
    template: `
        <ag-grid-wrapper class="borders" *ngIf="initialized" [(resource)]="data" [config]="tableConfig">
        </ag-grid-wrapper>
    `,
})
export class WorkitemTransferWizardProducts {
    @Input() options: IWizardOptions;
    @ViewChild(AgGridWrapper) public table: AgGridWrapper;
    initialized = false;
    tableConfig = this.createTableConfig();

    data: any[];

    constructor(private invoiceHourService: InvoiceHourService) {}

    get selectedItems() {
        return this.table.getSelectedRows();
    }

    canProceed(): { ok: boolean; msg?: string } {
        if (!this.selectedItems?.length) {
            return { ok: false, msg: 'Du har ikke valgt noe som kan overføres.' };
        }

        return this.selectedItems.some((item) => !item.ProductID)
            ? { ok: false, msg: 'Du må angi produkt/pris for alle timearter som skal overføres.' }
            : { ok: true };
    }

    refresh() {
        this.initialized = true;
        this.invoiceHourService.getWorkTypeWithProducts(this.options).subscribe((res) => {
            this.data = (res || []).map((item) => {
                item['_rowSelected'] = !!item.PartName;
                return item;
            });
        });
    }

    private createTableConfig(): UniTableConfig {
        const cols = [
            new UniTableColumn('WorkTypeID', 'Art.nr.', UniTableColumnType.Number).setVisible(false),
            new UniTableColumn('WorktypeName', 'Timeart').setEditable(false),
            new UniTableColumn('PartName', 'Produktnr.', UniTableColumnType.Lookup)
                .setDisplayField('PartName')
                .setOptions({
                    itemTemplate: (item) => `${item['PartName']} - ${item['Name']}`,
                    lookupFunction: (txt) => this.invoiceHourService.lookupProduct(txt),
                }),
            new UniTableColumn('ProductName', 'Produktnavn').setEditable(false),
            new UniTableColumn('PriceExVat', 'Pris', UniTableColumnType.Money),
        ];

        return (
            new UniTableConfig('timetracking.transfer-wizard-products', true, false)
                .setColumns(cols)
                .setAutoAddNewRow(false)
                .setMultiRowSelect(true)
                .setColumnMenuVisible(false)
                .setHideRowCount(true)
                .setChangeCallback((event) => this.invoiceHourService.onEditChange(event))
                // Use custom row selection to avoid losing selection state when editing the data
                .setCustomRowSelection({
                    isRowSelected: (row) => row['_rowSelected'],
                    onSelectionChange: (event) => {
                        event?.changes?.forEach((change) => {
                            change.row['_rowSelected'] = change.selected;
                        });
                    },
                })
        );
    }
}
