<section class="startup-shortcuts flex-column">
    <section
        class="startup-shortcut flex align-center justify-center border"
        *ngFor="let shortcut of shortCuts"
        [title]="shortcut.label"
        (click)="onShortCutClicked(shortcut)"
    >
        <i class="material-icons icon"> {{ shortcut.icon }} </i>
        <span class="ellipsis"> {{ shortcut.label }} </span>
        <i class="material-icons goto"> chevron_right </i>
    </section>
</section>
