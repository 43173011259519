import { Component, EventEmitter } from '@angular/core';
import { IModalOptions, IUniModal } from '@uni-framework/uni-modal/interfaces';
import { FieldType } from '@uni-framework/ui/uniform';
import { BankService } from '@app/services/accounting/bankService';
import { BankStatementService } from '@app/services/bank/bankStatementService';
import { ErrorService } from '@app/services/common/errorService';

@Component({
    selector: 'uni-reconciliation-startdate-modal',
    styles: [
        `
            p {
                padding: 0 0.5rem 0.5rem;
            }
            header {
                padding-left: 3rem;
            }
        `,
    ],
    template: `
        <section role="dialog" class="uni-modal xs">
            <header>Sett dato for oppstart med bankavstemming</header>

            <article>
                <section *ngIf="busy" class="modal-spinner">
                    <mat-spinner class="c2a"></mat-spinner>
                </section>

                <p>
                    Her setter du dato for når bankavstemming skal hente transaksjonsdata fra bankkontoen din. Har du
                    direkte integrasjon med banken er det viktig at du setter ønsket oppstartsdato her.
                </p>

                <p>Du kan endre dette i Bankinnstillinger senere.</p>

                <uni-form
                    [config]="{ autoFocus: true, showLabelsAbove: true }"
                    [fields]="fields"
                    [model]="reconciliationStartDate"
                >
                </uni-form>

                <footer class="center">
                    <button (click)="close()" class="c2a secondary">Avbryt</button>
                    <button (click)="save()" class="c2a">Lagre</button>
                </footer>
            </article>
        </section>
    `,
})
export class UniReconciliationStartDateModal implements IUniModal {
    options: IModalOptions = {};
    onClose = new EventEmitter(true);

    agreement: any;
    busy = false;

    fields = [
        {
            Property: 'StartDate',
            Label: 'Legg inn oppstartsdato',
            FieldType: FieldType.LOCAL_DATE_PICKER,
        },
    ];

    reconciliationStartDate = {
        StartDate: null,
    };

    constructor(
        private bankService: BankService,
        private bankStatmentService: BankStatementService,
        private errorService: ErrorService,
    ) {
        const firstOfMonth = new Date();
        firstOfMonth.setDate(1);
        this.reconciliationStartDate.StartDate = firstOfMonth;
    }

    ngOnInit() {
        this.agreement = this.options.data.agreement;
    }

    save() {
        if (!this.reconciliationStartDate.StartDate) {
            this.close();
            return;
        }

        this.busy = true;
        this.agreement.BankStatementStartDate = this.reconciliationStartDate.StartDate;

        this.bankService.updateBankIntegrationAgreement(this.agreement.ID, this.agreement).subscribe(
            () => {
                this.bankStatmentService.syncStatements(this.agreement.BankStatementStartDate).subscribe(
                    (res) => {
                        if (res?.NumberOfStatements) {
                            this.onClose.emit(true);
                            return;
                        }
                        this.busy = false;
                        this.close();
                    },
                    (err) => {
                        this.errorService.handle(err);
                        this.busy = false;
                    },
                );
            },
            (err) => {
                this.errorService.handle(err);
                this.busy = false;
            },
        );
    }

    close() {
        this.onClose.emit(false);
    }
}
