import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Input } from '@angular/core';
import { NavbarLinkService } from './navbar-link-service';
import { AuthService, CurrentUserWithout2FADetails } from '@app/authService';
import { TabService } from './tabstrip/tabService';
import { ContractLicenseType, LicenseEntityStatus } from '@uni-entities';

import { SmartSearchService } from '../smart-search/smart-search.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { rigDate } from '@app/components/common/utils/rig-date';

import { theme, ThemeData, THEMES } from 'src/themes/theme';
import { UserService } from '@app/services/common/userService';
import { SidebarStateService } from '../sidebar/sidebar-state';
import { APP_METADATA } from 'src/environments/metadata';

@Component({
    selector: 'uni-navbar',
    templateUrl: './navbar.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniNavbar {
    @Input() deactivateFunctions: boolean = false;

    theme = theme;
    logoUrl = 'config/dist/theme/assets/navbar_logo.svg?v=' + APP_METADATA.APP_VERSION;
    // appVersion = APP_METADATA.APP_VERSION;

    isSrEnvironment = theme.theme === THEMES.SR;
    isDnbEnvironment = theme.theme === THEMES.EXT02;
    isUnimicro = theme.theme === THEMES.UE;
    isEika = theme.theme === THEMES.EIKA;
    // Hide top logo in training environment so SC-team can inject custom logo later if needed
    isTraining = theme.theme === THEMES.SOFTRIG_TRAINING;

    showDemoPackageSelector: boolean;
    isSupportUser: boolean;
    isRoamingUser: boolean;

    user: CurrentUserWithout2FADetails;
    licenseRole: string;
    hasActiveContract: boolean;
    isTemplateCompany: boolean;
    isTestCompany: boolean;
    isArchived: boolean;

    isDemo: boolean;
    demoExpired: boolean;
    demoDaysRemainingText: string;

    settingsLinks: any[] = [];
    adminLinks: any[] = [];
    toolLinks: any[] = [];
    currentContractType: number;
    currentContractID: number;
    journalEntriesCounterEnabled: boolean;

    onDestroy$ = new Subject();

    bankLink: string;

    constructor(
        public authService: AuthService,
        public userService: UserService,
        public navbarService: NavbarLinkService,
        public sidebarStateService: SidebarStateService,
        public tabService: TabService,
        public cdr: ChangeDetectorRef,
        private smartSearchService: SmartSearchService,
    ) {
        this.authService.authentication$.pipe(takeUntil(this.onDestroy$)).subscribe((auth) => {
            if (auth?.activeCompany) {
                const licenseInfo = auth.user.License;
                this.hasActiveContract = auth.hasActiveContract;
                this.isTemplateCompany = auth.activeCompany.IsTemplate;
                this.isTestCompany = auth.activeCompany.IsTest;
                this.isDemo = auth.isDemo;
                this.isSupportUser = licenseInfo?.UserType?.TypeName === 'Support';
                this.isRoamingUser =
                    licenseInfo?.CustomerInfo?.IsRoamingUser || licenseInfo?.UserType?.TypeName === 'Accountant';
                this.isArchived = licenseInfo?.Company?.StatusCode === LicenseEntityStatus.Archived;
                this.currentContractType = licenseInfo?.ContractType?.TypeID;
                this.currentContractID = licenseInfo?.Company.ContractID;
                this.showDemoPackageSelector =
                    (auth.isDemo || this.isSupportUser || this.isRoamingUser) &&
                    (theme.theme === THEMES.EXT02 || theme.theme === THEMES.SR || theme.theme === THEMES.EIKA);

                if (auth.isDemo) {
                    const contract = licenseInfo?.ContractType || <ContractLicenseType>{};
                    const daysRemaining = rigDate(contract.TrialExpiration).diff(rigDate(), 'days');
                    if (daysRemaining > 0) {
                        const daysWording = daysRemaining === 1 ? 'dag' : 'dager';
                        this.demoDaysRemainingText = `(${daysRemaining} ${daysWording} igjen)`;
                    } else {
                        this.demoExpired = true;
                        this.demoDaysRemainingText = '';
                    }
                }

                if (this.isDnbEnvironment || this.isSrEnvironment || this.isUnimicro) {
                    this.journalEntriesCounterEnabled = true;
                }

                if (this.isEika) {
                    const bankRegNr = (auth.user as any).License?.CustomerInfo?.Department?.DepartmentIdentifierCode;
                    if (bankRegNr) {
                        this.bankLink = `https://www.eikaregnskap.no/okonomi-unimicro-dmz-ws/rest/resource/sso/${bankRegNr}/netbank`;
                    } else {
                        this.bankLink = undefined;
                    }
                }

                this.cdr.markForCheck();
            }
        });
    }

    public ngOnInit() {
        this.userService.getCurrentUser().subscribe((user) => {
            this.user = user;

            const licenseRoles: string[] = [];
            if (user['License'] && user['License'].ContractType) {
                if (user['License'].ContractType.TypeName) {
                    licenseRoles.push(user['License'].ContractType.TypeName);
                }
            }
            if (user['License'] && user['License'].UserType) {
                if (user['License'].UserType.TypeName) {
                    licenseRoles.push(user['License'].UserType.TypeName);
                }
            }

            this.licenseRole = licenseRoles.join('/');
        });

        this.navbarService.settingsSection$.subscribe((linkSections) => {
            this.settingsLinks = [];
            this.adminLinks = [];
            this.toolLinks = [];

            try {
                this.settingsLinks = linkSections[0].linkGroups[0].links;
                this.toolLinks = linkSections[0].linkGroups[1].links;
                this.adminLinks = linkSections[0].linkGroups[2].links;
            } catch (e) {
                /* dont care, just means the user doesnt have settings permissions */
            }

            this.cdr.markForCheck();
        });
    }

    ngOnDestroy() {
        this.onDestroy$.next(undefined);
        this.onDestroy$.complete();
    }

    openSearch() {
        this.smartSearchService.open(false, true);
    }
}
