<section class="uni-modal">
    <header>{{ header }}</header>

    <article>
        <section *ngIf="busy" class="modal-spinner">
            <mat-spinner class="c2a"></mat-spinner>
        </section>

        <section *ngIf="externalLookupOptions" class="external-search">
            <autocomplete
                [options]="externalLookupOptions"
                [initSearchValue]="initSearchValue"
                (valueChange)="onExternalSearchSelected($event)"
            >
            </autocomplete>
        </section>

        <uni-alert-component [isOpen]="!!entityID" [type]="'warn'" [icon]="'warning'">
            <button class="tertiary c2a" (click)="goToEntity()" *ngIf="!!entityID">
                {{ entity === 'supplier' ? 'Leverandøren' : 'Kunden' }} finnes allerede
            </button>
        </uni-alert-component>

        <uni-form
            [config]="{ autofocus: false, showLabelAbove: true }"
            [fields]="fields$"
            [model]="model$"
            (changeEvent)="onChangeEvent($event)"
        >
        </uni-form>
    </article>

    <footer>
        <button (click)="onClose.emit()" class="secondary">Avbryt</button>

        <button *ngIf="showSaveAsLead" (click)="save(true)" class="secondary pull-right">
            Lagre som potensiell kunde
        </button>

        <button (click)="save()" class="c2a" (keydown.tab)="$event.preventDefault()">Lagre</button>
    </footer>
</section>
