<section class="uni-modal">
    <header>
        <i class="material-icons" [ngClass]="failed ? 'color-warn' : 'color-good'">{{ icon }}</i>
        {{ header }}
    </header>

    <article>
        <section *ngIf="busy" class="modal-spinner">
            <mat-spinner class="c2a"></mat-spinner>
        </section>

        <section *ngIf="!bankIDError">{{ message }}</section>

        <section *ngIf="bankIDError">
            Ønsker du å sende {{ paymentBatch?.NumberOfPayments }} betalinger med totalsum
            <strong>{{ paymentBatch?.TotalAmount | uninumberformat: 'money' }}</strong>
            til bank og godkjenne med BankID igjen?
        </section>
    </article>

    <footer>
        <button *ngIf="!bankIDError" (click)="close(true)" class="secondary c2a">Lukk</button>

        <ng-container *ngIf="bankIDError">
            <button class="secondary" [disabled]="busy" (click)="revertPaymentBatch()">Nei, avbryt</button>
            <button class="c2a" [disabled]="busy" (click)="resendPaymentBatch()">Ja, prøv igjen</button>
        </ng-container>
    </footer>
</section>
