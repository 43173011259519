<section class="rating-container">
    <header>
        <i class="material-icons" (click)="close()" title="Minimer">close</i>
    </header>

    <section class="text-and-rating-section">
        <span>Din mening er viktig for oss!</span>
        <span class="question"> Hvor fornøyd er du med {{ appName }}? </span>
        <section class="rating-options">
            <section *ngFor="let rating of ratingOptions" (click)="onRatingSelected(rating)" title="{{ rating.label }}">
                <i class="material-icons-outlined" [class.rating-selected]="rating.selected"> {{ rating.icon }} </i>
                <span *ngIf="rating.selected"> {{ rating.label }} </span>
            </section>
        </section>
    </section>

    <section class="comment-section" title="Tilbakemelding">
        <textarea placeholder="Fortell oss gjerne hvorfor (valgfritt)" [(ngModel)]="responseToSend.Comment"></textarea>
    </section>

    <footer>
        <rig-checkbox [(ngModel)]="responseToSend.Anonymous"> Send anonymt </rig-checkbox>
        <button class="c2a" (click)="sendSurvey()" title="Send inn svar">Send</button>
    </footer>
</section>
