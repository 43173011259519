<section role="dialog" class="uni-modal">
    <header>Overføring av timer</header>

    <article [attr.aria-busy]="busy">
        <div class="stepheader">{{ steps[step].label }}:</div>

        <section class="wizard-step-container" [hidden]="step !== 0">
            <uni-form [fields]="fields$" [model]="model$" [config]="formConfig$" (changeEvent)="onFormChange($event)">
            </uni-form>
        </section>

        <section class="wizard-step-container" [hidden]="step !== 1">
            <workitem-transfer-wizard-filter [options]="wizardOptions"></workitem-transfer-wizard-filter>
        </section>

        <section class="wizard-step-container" [hidden]="step !== 2">
            <workitem-transfer-wizard-products [options]="wizardOptions"></workitem-transfer-wizard-products>
        </section>

        <section class="wizard-step-container" [hidden]="step !== 3">
            <workitem-transfer-wizard-preview [options]="wizardOptions"></workitem-transfer-wizard-preview>
        </section>

        <section class="wizard-step-container" [hidden]="step !== 4 || workInProgress">
            Trykk "Fullfør" for å starte generering av {{ wizardOptions?.orders.length }} ordre(r).
        </section>

        <section class="wizard-step-container" *ngIf="workInProgress">
            {{ workIndex }} / {{ wizardOptions?.orders?.length }}
            <div [attr.aria-busy]="transferBusy">...</div>
        </section>
    </article>

    <footer>
        <button class="secondary pull-left" [disabled]="workInProgress" (click)="cancel()">Avbryt</button>

        <span style="margin-right: 2rem">Steg {{ step + 1 }} av {{ steps.length }}</span>

        <button *ngIf="step > 0" class="secondary" [disabled]="workInProgress" (click)="goBack()">Tilbake</button>

        <button class="c2a" [disabled]="workInProgress" id="good_button_ok" (click)="accept()">
            {{ step < steps.length - 1 ? 'Videre' : 'Fullfør' }}
        </button>
    </footer>
</section>
