import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UniFrameworkModule } from '@uni-framework/frameworkModule';

import { DashboardNew } from './dashboard';
import { Widget, WIDGET_COMPONENTS } from './widgets';
import { WidgetChart } from './widgets/charts/widget-chart';
import { WidgetPieChart } from './widgets/charts/widget-pie-chart';
import { WidgetSelectorDialog } from './widget-selector-dialog/widget-selector-dialog';
import { DashboardDataService } from './dashboard-data.service';
import { WidgetEmptyState } from './widgets/widget-empty-state';
import { LiquidityPaymentModal } from './widgets/charts/liquidity/payment-modal/liquidity-payment-modal';
import { RecentEmployeesService } from './widgets/misc/recent-employees/recent-employees.service';
import { RecentPayrollRunsService } from './widgets/misc/recent-payroll-runs/recent-payroll-runs.service';
import { EmployeeWidgetService } from './widgets/misc/employees/shared/services/employee-widget.service';
import { SalaryShortcutsService } from './widgets/misc/salary-shortcuts/salary-shortcuts.service';
import { SurveyService } from '../surveys/survey.service';
import { UniSurvey } from '../surveys/survey.component';
import { RatingSurveyComponent } from '../surveys/rating-survey/rating-survey.component';
import { CMSBanner } from './banner-ad/banner-ad';
import { StartupTaskContainer } from '../startup-task/startup-task-container';
import { TaskTemplate } from '../startup-task/tasks/task-template';
import { RegisterOpeningBalanceTask } from '../startup-task/tasks/register-opening-balance';
import { StartupTaskShortcuts } from '../startup-task/tasks/startup-shortcuts/startup-shortcuts';
import { StartupTaskService } from '../startup-task/startup-task-service';
import { ConnectToAltinnTask } from '../startup-task/tasks/connect-to-altinn';
import { StartInvoingTask } from '../startup-task/tasks/start-invoicing';
import { ActivateEHFTask } from '../startup-task/tasks/activate-ehf';
import { TasksCompleteModal } from '../startup-task/complete-modal/complete-modal';
import { FirstProductTask } from '../startup-task/tasks/first-product';

@NgModule({
    imports: [CommonModule, UniFrameworkModule, CMSBanner],
    declarations: [
        DashboardNew,
        WidgetChart,
        WidgetPieChart,
        Widget,
        WidgetSelectorDialog,
        WidgetEmptyState,
        LiquidityPaymentModal,
        ...WIDGET_COMPONENTS,
        UniSurvey,
        RatingSurveyComponent,
        StartupTaskContainer,
        TaskTemplate,
        RegisterOpeningBalanceTask,
        StartupTaskShortcuts,
        ConnectToAltinnTask,
        StartInvoingTask,
        ActivateEHFTask,
        FirstProductTask,
        TasksCompleteModal,
    ],
    providers: [
        DashboardDataService,
        RecentEmployeesService,
        RecentPayrollRunsService,
        EmployeeWidgetService,
        SalaryShortcutsService,
        SurveyService,
        StartupTaskService,
    ],
    exports: [DashboardNew, WidgetEmptyState],
})
export class DashboardModule {}
