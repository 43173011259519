<section role="dialog" class="uni-modal" style="width: 70rem">
    <header>
        {{
            newGoodsNewProduct.length || newGoodsExistingProduct.length
                ? 'Denne fakturaen har ukjente produkter'
                : 'Endre kobling på produkter'
        }}
    </header>

    <section *ngIf="showHelpBanner" class="alert good info-banner">
        <div *ngIf="!expandHelpBanner">
            Hvordan bruker jeg produktkoblinger? <a (click)="expandHelpBanner = !expandHelpBanner">Vis mer</a>
        </div>
        <div *ngIf="expandHelpBanner">
            Leverandørens produkter på fakturaen må knyttes til et produkt i ditt produktregister for å kunne
            viderefaktureres. Du kan velge å knytte alle leverandør produktene til et produkt, da vil alle få samme
            produktnummer. Du har også mulighet til å opprette nye eller knytte dem til eksisterende produkter en og en.
            Du finner mer informasjon ved å holde musepekeren over informasjonssymbolet ved hver overskrift.
            <a>Se hjelpevideo</a>
            <a (click)="expandHelpBanner = !expandHelpBanner">Vis mindre</a>
        </div>
    </section>

    <article class="">
        <section>
            <div class="connect-checkbox-container">
                <rig-checkbox class="standard-select" [checked]="useStandardProduct" (change)="setUseStandardProduct()">
                    Knytt alle leverandørproduktene til standard produkt for viderefakturering
                </rig-checkbox>
                <i class="material-icons-outlined info-icon" [uniTooltip]="standardSelectTooltipTemplate">info</i>
            </div>

            <div *ngIf="useStandardProduct" class="standard-goodsnumber-info">
                <span
                    ><i>Produkt: {{ standardProduct?.PartName }} - {{ standardProduct?.Name }}</i></span
                >
                <a class="pull-right" (click)="selectStandardProduct()"> Endre </a>
            </div>
        </section>

        <section class="goods-containers">
            <!--Editing existing goods, or all goods to standard product-->
            <goods-list
                [isExistingGoods]="true"
                [goods]="goods"
                (goodChanged)="onGoodsChanged($event)"
                [productReadOnly]="useStandardProduct"
                [highlightedGood]="highlightedGood"
            >
            </goods-list>

            <!--New good, Create a new product-->
            <div *ngIf="newGoodsNewProduct.length">
                <div class="connect-checkbox-container">
                    <p>Opprett nytt produkt:</p>
                    <i class="material-icons-outlined info-icon" [uniTooltip]="existingProductTooltipTemplate">info</i>
                </div>
                <goods-list
                    [isNewGoodsNewProduct]="true"
                    [goods]="newGoodsNewProduct"
                    (goodChanged)="onGoodsChanged($event)"
                    (pushToExistingProduct)="pushToExistingProduct($event)"
                    [productReadOnly]="useStandardProduct"
                    [highlightedGood]="highlightedGood"
                >
                </goods-list>
            </div>

            <!--New good, Product exists-->
            <div *ngIf="newGoodsExistingProduct.length">
                <div class="connect-checkbox-container">
                    <p>Koble til eksisterende produkt:</p>
                    <i class="material-icons-outlined info-icon" [uniTooltip]="newProductTooltipTemplate">info</i>
                </div>
                <goods-list
                    [isNewGoodsWithProduct]="true"
                    [goods]="newGoodsExistingProduct"
                    (goodChanged)="onGoodsChanged($event)"
                    (pushtoNewProduct)="pushToNoProduct($event)"
                    [productReadOnly]="useStandardProduct"
                    [highlightedGood]="highlightedGood"
                >
                </goods-list>
            </div>
        </section>
    </article>
    <footer>
        <button (click)="close()" class="secondary">Avbryt</button>
        <button (click)="accept()" class="c2a pull-right" [disabled]="hasErrors">Lagre</button>
    </footer>
</section>

<ng-template #standardSelectTooltipTemplate>
    <section class="custom-tooltip">
        Her kan du knytte alle leverandørproduktene til det samme produktet i ditt produktregister.
    </section>
</ng-template>

<ng-template #newProductTooltipTemplate>
    <section class="custom-tooltip">
        Her ligger produktene som knyttes til eksisterende produkter. <br /><br />
        Du kan søke mot produktregisteret ditt i feltet for produkt.
        <div style="display: flex; margin-top: 1.5rem">
            <button class="goods-item-move pull-right">
                <i class="material-icons-outlined"> vertical_align_top </i>
            </button>
            <div style="margin-left: 1rem">
                Ønsker du å opprette et nytt produkt for dette leverandørproduktet kan det flyttes opp til opprett ny
                med denne knappen.
            </div>
        </div>
    </section>
</ng-template>

<ng-template #existingProductTooltipTemplate>
    <section class="custom-tooltip">
        Her ligger produktene som opprettes i ditt produktregister. <br /><br />
        Du kan enten velge et produktnummer eller la produktnummer stå blankt for å få tildelt produktnummer automatisk.
        <div style="display: flex; margin-top: 1.5rem">
            <button class="goods-item-move pull-right">
                <i class="material-icons-outlined"> vertical_align_bottom </i>
            </button>
            <div style="margin-left: 1rem">
                Ønsker du å knytte et leverandørprodukt til et eksisterende produkt kan du bruke denne knappen.
            </div>
        </div>
    </section>
</ng-template>
