<uni-toolbar [config]="{ title: 'Fakturere/overføre timer' }" [saveactions]="saveactions"> </uni-toolbar>

<section class="application">
    <ag-grid-wrapper
        class="borders"
        [resource]="dataSource"
        [config]="tableConfig"
        (rowSelectionChange)="onRowSelected($event)"
    >
    </ag-grid-wrapper>
</section>
