import { NgModule } from '@angular/core';

import { NotificationService, UserNotificationSettingsService } from './notification-service';
import { Notifications } from './notifications';
import { NotificationsDropdown } from './notifications-dropdown/notifications-dropdown';
import { NotificationItem } from './notification-item/notification-item';
import { UniFrameworkModule } from '@uni-framework/frameworkModule';
import { UserNotificationSettingsModal } from './user-notification-settings/user-notification-settings-modal';
@NgModule({
    imports: [UniFrameworkModule],
    declarations: [Notifications, NotificationsDropdown, NotificationItem, UserNotificationSettingsModal],
    providers: [NotificationService, UserNotificationSettingsService],
    exports: [Notifications],
})
export class NotificationsModule {}
