import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Subscription, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { theme } from 'src/themes/theme';
import { DashboardDataService } from '../../../dashboard-data.service';

interface IncomeStatementRow {
    ID: number;
    GroupNumber: number;
    Sum: number;
    IsSubTotal: boolean;
    Period1: number;
    Period2: number;
    Period3: number;
    Period4: number;
    Period5: number;
    Period6: number;
    Period7: number;
    Period8: number;
    Period9: number;
    Period10: number;
    Period11: number;
    Period12: number;
}
interface ChartItem {
    PeriodNo: number;
    Sum: number;
    Income: number;
    Cost: number;
}

@Component({
    selector: 'operating-profits',
    templateUrl: './operating-profits.html',
    styleUrls: ['./operating-profits.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperatingProfitsWidget {
    colors = [
        theme.widgets.operating_profits.income,
        theme.widgets.operating_profits.cost,
        theme.widgets.operating_profits.result,
    ];

    year = new Date().getFullYear();
    years = [this.year, this.year - 1, this.year - 2, this.year - 3];

    loading = true;
    hasData = false;

    data: { PeriodNo: number; Sum: number; Income: number; Cost: number }[];
    legend: { label: string; value: number; color: string }[];

    showAccumulatedResult: boolean;
    dataSubscription: Subscription;
    chartConfig;
    tooltip;

    constructor(
        private cdr: ChangeDetectorRef,
        private dataService: DashboardDataService,
    ) {}

    ngOnInit() {
        this.initChart();
    }

    ngOnDestroy() {
        this.dataSubscription?.unsubscribe();
    }

    setShowAccumulatedResult(showAccumulatedResult) {
        this.showAccumulatedResult = showAccumulatedResult;
        this.chartConfig = this.getChartConfig();
        this.cdr.markForCheck();
    }

    initChart() {
        this.dataSubscription?.unsubscribe();
        this.dataSubscription = this.loadData().subscribe((data) => {
            data = this.convertToChartData(data);

            this.hasData = data?.length && data.some((item) => item.Sum || item.Income || item.Cost);

            if (this.hasData) {
                this.data = data || [];
                this.updateLegend();

                this.chartConfig = this.getChartConfig();
            }

            this.loading = false;
            this.cdr.markForCheck();
        });
    }

    convertToChartData(data: Array<IncomeStatementRow>): Array<ChartItem> {
        var output: ChartItem[] = [];
        for (let i = 0; i < 12; i++) output.push({ PeriodNo: i + 1, Sum: 0, Income: 0, Cost: 0 });
        for (const row of data) {
            if (row.IsSubTotal) continue;
            if (row.ID >= 800) continue;
            const isIncome = row.ID === 300;
            for (let i = 0; i < 12; i++) {
                const sum = -row['Period' + (i + 1)];
                output[i].Cost += isIncome ? 0 : sum;
                output[i].Income += isIncome ? sum : 0;
                output[i].Sum += sum;
            }
        }

        return output;
    }

    private loadData() {
        const endpoint = `/api/biz/accounts?action=profit-and-loss-grouped&financialyear=${this.year}`;
        return this.dataService.get(endpoint).pipe(
            catchError((err) => {
                console.error(err);
                return of([]);
            }),
        );
    }

    private updateLegend() {
        let incomeSum = 0,
            costSum = 0,
            resultSum = 0;

        this.data.forEach((item) => {
            incomeSum += item.Income || 0;
            costSum += item.Cost || 0;
            resultSum += item.Sum || 0;
        });

        costSum = costSum * -1;

        this.legend = [
            { label: 'Inntekter', value: incomeSum, color: this.colors[0] },
            { label: 'Kostnader', value: costSum, color: this.colors[1] },
            { label: 'Resultat', value: resultSum, color: this.colors[2] },
        ];
    }

    private getChartConfig() {
        const resultData = [];
        let accumulatedSum = 0;

        this.data.forEach((item) => {
            if (this.showAccumulatedResult) {
                accumulatedSum += item.Sum || 0;
                resultData.push(accumulatedSum);
            } else {
                resultData.push(item.Sum || 0);
            }
        });

        return {
            type: 'roundedBarChart',
            data: {
                labels: ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Des'],
                datasets: [
                    {
                        label: 'Resultat',
                        data: resultData,
                        borderColor: '#b3b3b3',
                        pointBackgroundColor: this.colors[2],
                        borderWidth: 1,
                        pointBorderWidth: 0,
                        pointRadius: 4,
                        tension: 0,
                        type: 'line',
                        fill: false,
                        options: {
                            fill: false,
                        },
                    },
                    {
                        label: 'Inntekter',
                        data: this.data.map((item) => item.Income),
                        backgroundColor: this.colors[0],
                        borderWidth: 0,
                        stack: 1,
                        barThickness: 16,
                    },
                    {
                        label: 'Kostnader',
                        data: this.data.map((item) => item.Cost),
                        backgroundColor: this.colors[1],
                        borderWidth: 0,
                        stack: 1,
                        barThickness: 16,
                    },
                ],
            },
            options: {
                elements: { point: { radius: 1 } },
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: { display: false },
                    tooltip: {
                        enabled: false,
                        mode: 'index',
                        position: 'nearest',
                        external: (context) => {
                            const tooltip = context.tooltip;
                            if (tooltip.opacity && tooltip.dataPoints?.length) {
                                this.tooltip = {
                                    result: tooltip.dataPoints[0].raw || 0,
                                    income: tooltip.dataPoints[1].raw || 0,
                                    cost: (+tooltip.dataPoints[2].raw || 0) * -1,
                                    style: {
                                        top: tooltip.caretY + 'px',
                                        left: tooltip.caretX + 'px',
                                        opacity: '1',
                                    },
                                };
                            } else {
                                this.tooltip = undefined;
                            }

                            this.cdr.markForCheck();
                        },
                    },
                },
                scales: {
                    y: {
                        grid: { borderDash: [4, 4] },
                        ticks: {
                            maxTicksLimit: 8,
                            callback: function (value) {
                                value = +value;
                                if (value === 0 || (value < 999 && value > -999)) {
                                    return value;
                                } else if (value > -1000000 && value < 1000000) {
                                    return value / 1000 + 'k';
                                } else if (value <= -1000000 || value >= 1000000) {
                                    return value / 1000000 + 'm';
                                } else {
                                    return value;
                                }
                            },
                        },
                    },
                    x: {
                        grid: {
                            display: false,
                            drawBorder: false,
                        },
                    },
                },
            },
        };
    }

    onYearSelected(year) {
        this.year = year;
        this.initChart();
    }
}
