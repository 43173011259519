<section class="redirect-container">
    <section class="content-container flex-column align-center justify-center">
        <div class="full-logo">
            <img src="/config/dist/theme/assets/unimicro-logo-full.svg" alt="Full logo" />
        </div>

        <section class="info-text">
            Uni Economy er nå Unimicro!
            <br />Vi har byttet adresse til app.unimicro.no. <br />Du vil bli videresendt om {{ secondsRemaining }}
            {{ secondsRemaining > 1 ? 'sekunder' : 'sekund' }}.
        </section>

        <a href="{{ unimicroUrl }}" class="button"> Ta meg dit nå </a>

        <div class="confetti">
            <img src="/config/dist/theme/assets/redirect-confetti.svg" alt="Confetti" />
        </div>
    </section>

    <div class="corner-logo">
        <img src="/config/dist/theme/assets/unimicro-logo-o.svg" alt="Small logo" />
    </div>
</section>
