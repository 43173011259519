<i
    (click)="close()"
    class="close-icon material-icons"
    matTooltip="Du kan også lukke menyen med escape knappen på tastaturet"
    matTooltipPosition="left"
    matTooltipClass="megamenu-close-tooltip"
>
    close
</i>

<header>
    <img [src]="logoUrl" alt="logo" />

    <input #searchInput type="search" [(ngModel)]="searchString" placeholder="Søk etter menyelement" />
</header>

<section class="link-groups" [class.bottom-padding]="isDirty">
    <section class="link-group" *ngFor="let section of filteredLinkSections; let i = index">
        <ng-container *ngFor="let sub of section">
            <span>
                {{ sub.name | translate }}
            </span>
            <ul class="link-list">
                <ng-container *ngFor="let group of sub.linkGroups">
                    <li *ngFor="let link of group.links" [ngClass]="getClass(link)">
                        <rig-checkbox
                            [(ngModel)]="link.activeInSidebar"
                            (ngModelChange)="updateDirtyState()"
                            class="text-small"
                        >
                            {{ link.name | translate }}
                        </rig-checkbox>
                    </li>
                </ng-container>
            </ul>
        </ng-container>
    </section>

    <button (click)="resetMenu()" class="secondary reset-button">Tilbakestill meny</button>
</section>

<section class="bottom-save-section" *ngIf="isDirty">
    <span>Du har ulagrede endringer</span>
    <button class="secondary" (click)="resetChanges()">Forkast endringer</button>
    <button class="c2a" (click)="saveMenuStructure()">Lagre endringer</button>
</section>
