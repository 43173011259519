import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { theme, THEMES } from 'src/themes/theme';
import { COMPANY_CREATION_STEPS } from '@app/components/common/company-creation-wizard/company-creation-steps.enum';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class CelebrusService {
    private autoTrackingIgnore = ['sign-up', 'prelogin-'];

    private lastTrackedUrl: string;
    private isLocalhost = location.hostname === 'localhost';

    constructor(private router: Router) {}

    init() {
        if (theme.theme === THEMES.EXT02 && !this.isLocalhost) {
            const scriptEl = window.document.createElement('script');

            let scriptSrc = '//assets.adobedtm.com/372a0e79ce5a/c26f6ad1e558/launch-bbfa54cc781b-development.min.js';

            if (environment.useProdMode) {
                scriptSrc = '//assets.adobedtm.com/372a0e79ce5a/c26f6ad1e558/launch-8488bfbf9669.min.js';
            }

            scriptEl.src = scriptSrc;
            scriptEl.async = true;

            window.document.head.appendChild(scriptEl);

            // Push PageLoaded event to tracking stack when navigation completes
            this.router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    const url = this.router.url;

                    const shouldTrack =
                        !!url && url !== '/reload' && !this.autoTrackingIgnore.some((ignore) => url.includes(ignore));

                    if (shouldTrack) {
                        this.logPageView();
                    }
                }
            });
        }
    }

    getCelebrusObject(name: string, step: string, step_type: string, step_index: string) {
        return {
            application: 'DNB Regnskap',
            name,
            step,
            type: 'Funnel',
            step_type,
            step_index,
        };
    }

    logPageView() {
        const url = this.router.url
            .replace('/#/', '/')
            .split('?')[0]
            .replace(/\/\d+(\/|)/g, '/id');
        if (url !== this.lastTrackedUrl) {
            this.lastTrackedUrl = url;
            this.useDataLayer('PageLoaded', {
                info: {
                    destination_url: location.origin + url,
                },
            });
        }
    }

    useDataLayer(event: string, page: any = null, products: any[] = null) {
        window.dnbDataLayer = window.dnbDataLayer || [];
        window.dnbDataLayer.push({ event, page, products });
    }

    logCompanyCreationStep(step: COMPANY_CREATION_STEPS) {
        if (step === COMPANY_CREATION_STEPS.CONTRACT_TYPE) {
            this.useDataLayer('PageLoaded', {
                process: this.getCelebrusObject(
                    'DNB Regnskap - Register Company',
                    'Choose package',
                    'Start,Price',
                    '0',
                ),
            });
        }

        // Email flow
        if (step === COMPANY_CREATION_STEPS.COMPANY_STEP1) {
            this.useDataLayer('PageLoaded', {
                process: this.getCelebrusObject(
                    'DNB Regnskap - Register Company',
                    'About the company',
                    'Other step',
                    '1',
                ),
            });
        }

        if (step === COMPANY_CREATION_STEPS.COMPANY_STEP2) {
            this.useDataLayer('PageLoaded', {
                process: this.getCelebrusObject(
                    'DNB Regnskap - Register Company',
                    'Company settings',
                    'Other step',
                    '2',
                ),
            });
        }

        if (step === COMPANY_CREATION_STEPS.CONTRACT_ACTIVATION) {
            this.useDataLayer('PageLoaded', {
                process: this.getCelebrusObject(
                    'DNB Regnskap - Register Company',
                    'Contact information',
                    'Other step',
                    '3',
                ),
            });
        }

        // BankId flow
        if (step === COMPANY_CREATION_STEPS.BANKID_STEP1) {
            this.useDataLayer('PageLoaded', {
                process: this.getCelebrusObject(
                    'DNB Regnskap - Register Company',
                    'Find your company',
                    'Other step',
                    '1',
                ),
            });
        }

        if (step === COMPANY_CREATION_STEPS.BANKID_STEP2) {
            this.useDataLayer('PageLoaded', {
                process: this.getCelebrusObject(
                    'DNB Regnskap - Register Company',
                    'About the company',
                    'Other step',
                    '2',
                ),
            });
        }
        if (step === COMPANY_CREATION_STEPS.BANKID_STEP3) {
            this.useDataLayer('PageLoaded', {
                process: this.getCelebrusObject(
                    'DNB Regnskap - Register Company',
                    'Connect accounts',
                    'Other step',
                    '3',
                ),
            });
        }
    }

    logCompanyCreationReceipt() {
        this.useDataLayer('PageLoaded', {
            process: this.getCelebrusObject('DNB Regnskap - Register Company', 'Receipt', 'Receipt', '4'),
        });
    }
}
