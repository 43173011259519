import {
    Component,
    Input,
    ElementRef,
    ViewChild,
    HostBinding,
    Output,
    OnInit,
    EventEmitter,
    SecurityContext,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { AutocompleteOptions } from '@uni-framework/ui/autocomplete/autocomplete';
import { AuthService } from '@app/authService';
import { map } from 'rxjs/operators';
import { cloneDeep, get } from 'lodash-es';
import { forkJoin } from 'rxjs';
import { THEMES, theme } from 'src/themes/theme';
import { ElsaContractType, ElsaContract, ContractType } from '@app/models/elsa-models';
import { ToastService, ToastType } from '@uni-framework/uniToast/toastService';
import { COMPANY_CREATION_STEPS } from './company-creation-steps.enum';
import { OnboardingBankService } from '@app/services/bank/onboardingBankService';
import { environment } from 'src/environments/environment';
import { BrunoOnboardingService } from '@app/services/bank/BrunoOnboardingService';
import { CompanyService } from '@app/services/common/companyService';
import { ErrorService } from '@app/services/common/errorService';
import { InitService } from '@app/services/common/initService';
import { ModulusService } from '@app/services/common/modulusService';
import { ElsaAgreementService } from '@app/services/elsa/elsaAgreementService';
import { ElsaContractService } from '@app/services/elsa/elsaContractService';
import { ElsaCustomersService } from '@app/services/elsa/elsaCustomersService';
import { DomSanitizer } from '@angular/platform-browser';
import { CelebrusService } from '@app/services/analytics-services/celebrus-service';

enum TaxMandatoryType {
    NotTaxMandatory = 1,
    FutureTaxMandatory = 2,
    TaxMandatory = 3,
}

enum ApprovalLocation {
    InApp = 1,
    InBank = 2,
}

@Component({
    selector: 'company-creation-wizard',
    templateUrl: './company-creation-wizard.html',
    styleUrls: ['./company-creation-wizard.sass'],
})
export class CompanyCreationWizard implements OnInit {
    @HostBinding('class.ext02-colors') isExt02Env = theme.theme === THEMES.EXT02;
    @HostBinding('class.sb1-colors') isSb1 = theme.theme === THEMES.SR;

    @ViewChild('companyNameInput') companyNameInput: ElementRef<HTMLInputElement>;

    @Input() contractID: number;
    @Input() includeContractActivation: boolean;
    @Input() isContractActivation: boolean;
    @Input() createDemoCompany: boolean;
    @Input() bankIdFlow: boolean;
    @Input() showTryDemoLink = false;
    @Input() modal = false;
    @Input() modalContractType: number = null;
    @Input() modalBureauCustomer = false;
    @Input() selectedContractType: number;
    @Input() orgNumber: string;

    @Output() closeModal = new EventEmitter();

    busy = false;
    appName = theme.appName;
    contractActivated: boolean;
    isBrunoEnv = theme.theme === THEMES.EXT02;
    busyCreatingCompany: boolean;
    autocompleteOptions: AutocompleteOptions;
    contract: ElsaContract;
    contractTypes: ElsaContractType[];
    taxType = TaxMandatoryType;
    sb1UserAgreements: any[];
    busyGettingSb1UserAgreements = false;
    selectedSb1UserAgreement: any;
    customerDetails: any;

    step1Form = new UntypedFormGroup({
        CompanyName: new UntypedFormControl('', Validators.required),
        Address: new UntypedFormControl('', Validators.required),
        PostalCode: new UntypedFormControl('', Validators.required),
        City: new UntypedFormControl('', Validators.required),
        Country: new UntypedFormControl('', Validators.required),
        CountryCode: new UntypedFormControl('', Validators.required),
        OrganizationNumber: new UntypedFormControl('', Validators.required),
    });

    step2Form = new UntypedFormGroup({
        // AccountNumber added in constructor (needs env check)
        TaxMandatoryType: new UntypedFormControl(undefined, Validators.required),
    });

    step2FormBankId = new UntypedFormGroup({
        TaxMandatoryType: new UntypedFormControl(undefined, Validators.required),
    });
    step2FormBankIdCopy = null;

    step3FormBankId = new UntypedFormGroup({
        ApprovalLocation: new UntypedFormControl(ApprovalLocation.InApp, Validators.required),
    });

    paymentsInApp = true;
    approvalLocation = ApprovalLocation;

    isEnk: boolean;
    companyName: string;

    STEPS = COMPANY_CREATION_STEPS;
    currentStep: number;
    companyCreationFailed = false;

    priceListLink: string;

    customerAgreementExists: boolean;
    editMode = false;
    busyActivating = false;

    companyCreationHeader = 'Registrerer selskap';

    temporaryDisableBIC = false;

    validationFailedTexts: string[] = [];

    constructor(
        private authService: AuthService,
        private http: HttpClient,
        private modulusService: ModulusService,
        private initService: InitService,
        private errorService: ErrorService,
        private companyService: CompanyService,
        private elsaContractService: ElsaContractService,
        private elsaCustomerService: ElsaCustomersService,
        private elsaAgreementService: ElsaAgreementService,
        private toastService: ToastService,
        private brunoOnboardingService: BrunoOnboardingService,
        private onboardingBankService: OnboardingBankService,
        private domSanitizer: DomSanitizer,
        private celebrusService: CelebrusService,
    ) {}

    ngOnInit() {
        this.autocompleteOptions = {
            canClearValue: false,
            clearInputOnSelect: true,
            autofocus: true,
            displayField: 'navn',
            placeholder: this.isSb1
                ? 'Legg inn organisasjonsnummer'
                : 'Hent selskapsdetaljer fra Brønnøysundregistrene',
            lookup: (value) => this.orgNumberLookup(value),
        };

        if (this.isBrunoEnv && !this.bankIdFlow) {
            this.temporaryDisableBIC =
                window.location.hash.toLowerCase().includes('init') || this.includeContractActivation;
            this.step2Form.addControl('AccountNumber', new UntypedFormControl('', Validators.required));
        }

        if (!this.modal && this.bankIdFlow) {
            this.step2FormBankId.addControl('ContactPerson', new UntypedFormControl('', Validators.required));
            this.step2FormBankId.addControl('ContactEmail', new UntypedFormControl('', Validators.required));
            if (this.isSb1) {
                this.step2FormBankId.addControl('ContactPhone', new UntypedFormControl('', Validators.required));
            }
        }

        if (this.authService.publicSettings?.PriceListUrl) {
            this.priceListLink = this.authService.publicSettings.PriceListUrl;
        } else if (theme.theme === THEMES.UE) {
            this.priceListLink = 'https://info.unieconomy.no/priser';
        } else if (theme.theme === THEMES.SR) {
            this.priceListLink = 'https://www.sparebank1.no/nb/sr-bank/bedrift/kundeservice/bestill/prisliste.html';
        } else if (theme.theme === THEMES.EXT02) {
            this.priceListLink = 'https://www.dnb.no/bedrift/dagligbank/prisliste#regnskap';
        }
    }

    ngOnChanges() {
        if (this.modal) {
            if (this.bankIdFlow) {
                this.setBankIdFlowInitialStep();
            } else {
                this.currentStep = COMPANY_CREATION_STEPS.COMPANY_STEP1;
            }
            this.customerAgreementExists = true;
            return;
        }

        this.busy = true;
        if (this.contractID && this.contractID > 0) {
            forkJoin([
                this.elsaContractService.getAll(),
                this.elsaContractService.getOnboardingContractTypes(),
                this.elsaAgreementService.getCustomerAgreement(),
            ]).subscribe({
                next: ([contracts, contractTypes, agreement]) => {
                    this.customerAgreementExists = !!agreement?.DownloadUrl?.length;
                    this.contract = contracts?.find((c) => c.ID === this.contractID);
                    this.contractTypes = contractTypes || [];

                    this.currentStep =
                        this.contractTypes.length && !this.createDemoCompany
                            ? COMPANY_CREATION_STEPS.CONTRACT_TYPE
                            : COMPANY_CREATION_STEPS.COMPANY_STEP1;

                    this.logToCelebrus();

                    if (this.selectedContractType > 0) {
                        this.onContractTypeSelected(+this.selectedContractType);
                    }
                    this.busy = false;
                },
                error: (err) => {
                    console.error(err);
                    this.busy = false;
                },
            });
        }
    }

    setBankIdFlowInitialStep() {
        if (!this.isSb1) {
            this.currentStep = COMPANY_CREATION_STEPS.BANKID_STEP1;
            return;
        }
        this.busyGettingSb1UserAgreements = true;
        this.initService
            .getEndUserAgreements()
            .subscribe({
                next: (res: any[]) => {
                    this.sb1UserAgreements = res;
                    if (this.sb1UserAgreements?.length === 0) {
                        this.currentStep = COMPANY_CREATION_STEPS.BANKID_STEP1;
                    } else if (this.sb1UserAgreements?.length === 1) {
                        this.onSb1UserAgreementSelected(this.sb1UserAgreements[0]);
                    } else {
                        this.currentStep = COMPANY_CREATION_STEPS.SB1_BANKID_STEP1;
                    }
                },
                error: (err) => {
                    console.error(err);
                    this.currentStep = COMPANY_CREATION_STEPS.BANKID_STEP1;
                },
            })
            .add(() => (this.busyGettingSb1UserAgreements = false));
    }

    onSb1UserAgreementSelected(selectedUserAgreement) {
        this.selectedSb1UserAgreement = selectedUserAgreement;
        const orgNumber = this.selectedSb1UserAgreement.OrganizationNumber;
        if (orgNumber?.length) {
            this.orgNumberLookup(orgNumber).subscribe({
                next: (res) => this.onBrRegCompanyChange(res[0]),
                error: (err) => console.error(err),
            });
        } else {
            this.currentStep = COMPANY_CREATION_STEPS.BANKID_STEP1;
        }
    }

    logToCelebrus() {
        if (theme.theme === THEMES.EXT02) {
            this.celebrusService.logCompanyCreationStep(this.currentStep);
        }
    }

    onContractTypeSelected(contracttype: number) {
        this.selectedContractType = contracttype;
        const orgNumber = this.orgNumber ?? this.contract?.Customer?.OrgNumber;

        if (orgNumber?.length) {
            this.orgNumber = orgNumber;
            this.orgNumberLookup(orgNumber).subscribe({
                next: (res) => this.onBrRegCompanyChange(res[0]),
                error: (err) => console.error(err),
            });
        } else {
            if (this.bankIdFlow) {
                this.setBankIdFlowInitialStep();
            } else {
                this.currentStep = COMPANY_CREATION_STEPS.COMPANY_STEP1;
            }
            this.logToCelebrus();
        }
    }

    private orgNumberLookup(query) {
        const orgNumber = query.replace(/\ /g, '');
        const url = this.modulusService.isValidOrgNr(orgNumber)
            ? `https://data.brreg.no/enhetsregisteret/api/enheter/${orgNumber}`
            : `https://data.brreg.no/enhetsregisteret/api/enheter?navn=${encodeURI(query)}`;

        return this.http.get(url).pipe(
            map((res) => {
                if (res['_embedded'] && res['_embedded'].enheter) {
                    return res['_embedded'].enheter;
                } else if (res['organisasjonsnummer']) {
                    return [res];
                } else {
                    return [];
                }
            }),
        );
    }

    onBrRegCompanyChange(brRegCompany) {
        if (!brRegCompany) {
            if (this.bankIdFlow && this.currentStep === COMPANY_CREATION_STEPS.BANKID_STEP1) {
                this.toastService.addToast(
                    'Ingen treff',
                    ToastType.info,
                    6,
                    'Fant ingen selskaper med dette organisasjonsnummeret.',
                );
            }
            this.currentStep = this.bankIdFlow
                ? COMPANY_CREATION_STEPS.BANKID_STEP1
                : COMPANY_CREATION_STEPS.COMPANY_STEP1;
            return;
        }

        this.isEnk = get(brRegCompany, 'organisasjonsform.kode') === 'ENK';

        if (!get(brRegCompany, 'forretningsadresse')) {
            this.step1Form.patchValue({
                CompanyName: brRegCompany.navn,
                Address: get(brRegCompany, 'postadresse.adresse[0]', ''),
                PostalCode: get(brRegCompany, 'postadresse.postnummer'),
                City: get(brRegCompany, 'postadresse.poststed'),
                Country: get(brRegCompany, 'postadresse.land'),
                CountryCode: get(brRegCompany, 'postadresse.landkode'),
                OrganizationNumber: brRegCompany.organisasjonsnummer,
            });
        } else {
            this.step1Form.patchValue({
                CompanyName: brRegCompany.navn,
                Address: get(brRegCompany, 'forretningsadresse.adresse[0]', ''),
                PostalCode: get(brRegCompany, 'forretningsadresse.postnummer'),
                City: get(brRegCompany, 'forretningsadresse.poststed'),
                Country: get(brRegCompany, 'forretningsadresse.land'),
                CountryCode: get(brRegCompany, 'forretningsadresse.landkode'),
                OrganizationNumber: brRegCompany.organisasjonsnummer,
            });
        }

        this.step2Form.controls.TaxMandatoryType.setValue(
            brRegCompany.registrertIMvaregisteret === true ? this.taxType.TaxMandatory : this.taxType.NotTaxMandatory,
        );

        this.step2FormBankId.controls.TaxMandatoryType.setValue(
            brRegCompany.registrertIMvaregisteret === true ? this.taxType.TaxMandatory : this.taxType.NotTaxMandatory,
        );

        if (this.bankIdFlow) {
            this.step1FormSubmit();
        } else {
            this.currentStep = COMPANY_CREATION_STEPS.COMPANY_STEP1;
        }

        setTimeout(() => this.companyNameInput?.nativeElement?.focus());
    }

    backFromBankIdStep1() {
        if (this.isBrunoEnv) {
            this.currentStep = COMPANY_CREATION_STEPS.CONTRACT_TYPE;
            return;
        }

        if (this.isSb1) {
            if (this.sb1UserAgreements?.length > 1 && !this.selectedSb1UserAgreement) {
                this.currentStep = COMPANY_CREATION_STEPS.SB1_BANKID_STEP1;
            } else {
                this.currentStep = COMPANY_CREATION_STEPS.BANKID_STEP2;
            }
        }
    }

    backFromBankIdStep2() {
        if (this.sb1UserAgreements?.length > 1) {
            this.currentStep = COMPANY_CREATION_STEPS.SB1_BANKID_STEP1;
        } else {
            this.currentStep = COMPANY_CREATION_STEPS.BANKID_STEP1;
        }
    }

    step1FormSubmit() {
        if (!this.step1Form.get('Country').value) {
            this.step1Form.controls.Country.setValue('Norge');
        }
        if (!this.step1Form.get('CountryCode').value) {
            this.step1Form.controls.CountryCode.setValue('NO');
        }
        this.step1Form.markAllAsTouched();
        if (this.step1Form.valid) {
            if (this.bankIdFlow) {
                this.patchInitialValuesForContactInfo();
                this.currentStep = COMPANY_CREATION_STEPS.BANKID_STEP2;
            } else {
                this.currentStep = COMPANY_CREATION_STEPS.COMPANY_STEP2;
            }

            this.logToCelebrus();

            if (
                this.step2Form.controls['AccountNumber'] &&
                this.contract?.Customer?.ProspectID !== null &&
                !this.modal
            ) {
                this.getAccountNumberFromProspect();
            }
        } else if (this.bankIdFlow && this.currentStep === COMPANY_CREATION_STEPS.BANKID_STEP2) {
            this.editMode = true;
        }
    }

    async patchInitialValuesForContactInfo() {
        if (this.contract?.Customer) {
            this.step2FormBankId.patchValue(this.contract.Customer);
        } else {
            this.step2FormBankId.patchValue({
                ContactPerson: this.authService.currentUser?.DisplayName,
                ContactEmail: this.authService.currentUser?.Email,
            });
        }

        if (this.isSb1) {
            const twoFactorDetails = await this.initService.getCurrentUserInfo().toPromise();
            let phoneNumber = twoFactorDetails?.authPhoneNumber;
            // split on hyphen if it includes country codes, i.e '47-13371337'
            if (phoneNumber?.includes('-')) {
                phoneNumber = phoneNumber.split('-')[1];
            }
            if (phoneNumber) {
                this.step2FormBankId.patchValue({ ContactPhone: phoneNumber });
            }
        }
    }

    onStepTo3() {
        if (this.isSb1) {
            this.bankIdFlowSubmit();
            return;
        }

        if (this.isBrunoEnv) {
            this.celebrusService.useDataLayer('PageLoaded', {
                process: this.celebrusService.getCelebrusObject(
                    'DNB Regnskap - Register Company',
                    'About the company',
                    'Other step',
                    '2',
                ),
            });
        }

        this.currentStep = COMPANY_CREATION_STEPS.BANKID_STEP3;
    }

    getAccountNumberFromProspect() {
        this.step2Form.disable();
        this.elsaCustomerService
            .getCustomerProspect(this.contract.Customer.ProspectID, this.contract.Customer.ID, 'AccountNumber')
            .subscribe({
                next: (prospect) => {
                    this.step2Form.controls.AccountNumber.setValue(prospect?.AccountNumber || '');
                    this.step2Form.enable();
                },
                error: () => this.step2Form.enable(),
            });
    }

    step2FormSubmit() {
        this.step2Form.markAllAsTouched();
        if (this.step2Form.valid) {
            if (this.step2Form.controls['AccountNumber'] && !this.temporaryDisableBIC) {
                this.initService.getBicFromAccountNumber(this.step2Form.get('AccountNumber').value).subscribe({
                    next: (bic) => {
                        if (bic && bic === this.authService.publicSettings?.BIC) {
                            this.step2FormSubmitNextStep();
                        } else {
                            this.toastService.addToast(
                                'Kontonummer',
                                ToastType.info,
                                5,
                                'Kontoen må være en DNB konto',
                            );
                            return;
                        }
                    },
                    error: () => {
                        this.toastService.addToast('Kontonummer', ToastType.info, 5, 'Ugyldig kontonummer');
                        return;
                    },
                });
            } else {
                this.step2FormSubmitNextStep();
            }
        }
    }

    step2FormSubmitNextStep() {
        if (this.includeContractActivation && !this.contractActivated) {
            const companyData = this.step1Form.value;
            this.orgNumber = companyData.OrganizationNumber.replace(/\ /g, '');
            this.companyName = companyData.CompanyName;

            this.currentStep = COMPANY_CREATION_STEPS.CONTRACT_ACTIVATION;
            this.logToCelebrus();
        } else {
            this.createCompany();
        }
    }

    bankIdFlowSubmit() {
        this.step1Form.markAllAsTouched();
        this.step2FormBankId.markAllAsTouched();

        if (this.step1Form.valid && this.step2FormBankId.valid) {
            this.orgNumber = this.step1Form.value.OrganizationNumber.replace(/\ /g, '');

            // used to automatically connect to bank after company creation
            if (this.paymentsInApp && this.isBrunoEnv) {
                const approval =
                    this.step3FormBankId.value.ApprovalLocation === ApprovalLocation.InApp ? 'InApp' : 'InBank';
                localStorage.setItem('bankIdFlow-Approval', `${this.orgNumber},${approval}`);
            }

            if (this.includeContractActivation) {
                this.companyName = this.step1Form.value.CompanyName;
                const customerDetails = this.step2FormBankId.value;
                customerDetails.Name = this.companyName;
                customerDetails.OrgNumber = this.orgNumber;

                this.busyActivating = true;
                this.step1Form.disable();
                this.step2FormBankId.disable();

                this.elsaContractService
                    .activate(this.contractID, customerDetails, this.selectedContractType)
                    .subscribe({
                        next: () => {
                            if (
                                customerDetails?.IsBankCustomer === false &&
                                this.authService.publicSettings?.BankCustomerUrl
                            ) {
                                let url = this.authService.publicSettings.BankCustomerUrl;
                                if (this.orgNumber) {
                                    url += `?bm-orgNumber=${this.orgNumber}`;
                                }
                                window.open(url, '_blank');
                            }
                            this.busyActivating = false;
                            this.step1Form.enable();
                            this.step2FormBankId.enable();

                            this.customerDetails = customerDetails;
                            this.createCompany();
                        },
                        error: (err) => {
                            this.errorService.handle(err);
                            this.busyActivating = false;
                            this.step1Form.enable();
                            this.step2FormBankId.enable();
                        },
                    });
            } else {
                this.createCompany();
            }
        } else {
            this.currentStep = COMPANY_CREATION_STEPS.BANKID_STEP2;
            this.editMode = true;
        }
    }

    onContractActivated(customerDetailsFromContractActivation: any) {
        this.customerDetails = customerDetailsFromContractActivation;
        this.contractActivated = true;
        this.createCompany();
    }

    createCompany() {
        const companyDetails = this.step1Form.value;
        const step2FormDetails = this.bankIdFlow ? this.step2FormBankId.value : this.step2Form.value;

        this.busyCreatingCompany = true;
        this.companyCreationFailed = false;
        this.companyCreationHeader = 'Registrerer selskap';

        const taxType = step2FormDetails.TaxMandatoryType;
        const includeVat = taxType === this.taxType.TaxMandatory;
        companyDetails.TaxMandatoryType = taxType;
        companyDetails.OrganizationNumber = companyDetails.OrganizationNumber.replace(/\ /g, '');
        companyDetails.Email = this.modal ? this.authService.currentUser?.Email : this.customerDetails?.ContactEmail;

        this.initService.getCompanyTemplate(this.isEnk, includeVat).subscribe((template) => {
            const companySettings = companyDetails;
            if (step2FormDetails.AccountNumber) {
                companySettings.CompanyBankAccount = {
                    AccountNumber: step2FormDetails.AccountNumber,
                };
            }

            const body = {
                CompanyName: companyDetails.CompanyName,
                ContractID: this.contractID,
                CompanySettings: companyDetails,
                TemplateCompanyKey: template?.Key,
                IsTest: !!this.createDemoCompany,
            };

            const setPendingStatus = this.shouldSetPendingStatus(body);

            this.initService.createCompany(body, setPendingStatus).subscribe({
                next: () => this.checkCompanyCreationStatus(body.CompanyName),
                error: (err) => {
                    if (err.status === 409) {
                        this.checkCompanyCreationStatus(body.CompanyName);
                        return;
                    }

                    const errorMessageLower = this.errorService.extractMessage(err)?.toLowerCase();
                    if (errorMessageLower.includes('user has insufficient access to register company')) {
                        this.goToValidationFailedAndSetTexts();
                        return;
                    }

                    this.errorService.handle(err);
                    this.currentStep = this.bankIdFlow
                        ? COMPANY_CREATION_STEPS.BANKID_STEP2
                        : COMPANY_CREATION_STEPS.COMPANY_STEP1;
                    this.companyCreationFailed = true;
                    this.busyCreatingCompany = false;
                },
            });
        });
    }

    private shouldSetPendingStatus(body): boolean {
        if (!this.isSb1) {
            return false;
        }

        if (body.IsTest || this.modalContractType === 11 || this.modalBureauCustomer) {
            return false;
        }

        const orgNumberExistInSb1UserAgreements = this.sb1UserAgreements?.some((agreement) =>
            agreement.OrganizationNumber?.includes(body.CompanySettings.OrganizationNumber),
        );

        if (this.selectedSb1UserAgreement && orgNumberExistInSb1UserAgreements) {
            return false;
        }

        return true;
    }

    private checkCompanyCreationStatus(companyName: string) {
        this.initService.getCompanies().subscribe({
            next: (companies) => {
                const nameLowerCase = companyName.toLowerCase();
                const company = (companies || []).find((c) => {
                    // Includes instead of equality check here because the name given
                    // to the company will in some cases be "DEMO <companyname>"
                    return (c.Name || '').toLowerCase().includes(nameLowerCase);
                });

                if (company) {
                    if (this.bankIdFlow) {
                        this.createBankAgreement(company);
                    } else {
                        this.celebrusService.logCompanyCreationReceipt();
                        this.setActiveCompany(company);
                    }
                } else {
                    setTimeout(() => this.checkCompanyCreationStatus(companyName), 3000);
                }
            },
            error: () => setTimeout(() => this.checkCompanyCreationStatus(companyName), 3000),
        });
    }

    createBankAgreement(company) {
        if (this.isBrunoEnv) {
            if (company.IsTest) {
                this.setActiveCompany(company);
                return;
            }

            this.companyCreationHeader = 'Selskapet kobles med banken';

            this.brunoOnboardingService.createAgreementBankID(company.OrganizationNumber, company.Key).subscribe({
                next: () => {
                    this.celebrusService.logCompanyCreationReceipt();
                    this.setActiveCompany(company);
                },
                error: (err) => {
                    this.setActiveCompany(company);
                    console.error(err);
                },
            });
        }

        if (this.isSb1) {
            const requests = [];
            if (!company.IsTest) {
                requests.push(
                    this.onboardingBankService.createAgreementBankID(company.OrganizationNumber, company.Key),
                );
            }

            if (!this.modal && !this.authService.currentUser?.License?.UserLicenseAgreement?.HasAgreedToLicense) {
                requests.push(this.initService.acceptUserAgreement(company.Key));
            }

            if (requests.length === 0) {
                this.setActiveCompany(company);
                return;
            }

            forkJoin(requests).subscribe({
                next: () => {
                    this.setActiveCompany(company);
                },
                error: (err) => {
                    this.setActiveCompany(company);
                    console.error(err);
                },
            });
        }
    }

    setActiveCompany(company) {
        if (this.isBrunoEnv) {
            // DNB company has been created and signup is complete, send a reciept event
            const name = this.bankIdFlow ? 'DNB Regnskap - Register Company' : 'Signup post mail';
            this.celebrusService.useDataLayer('PageLoaded', {
                process: this.celebrusService.getCelebrusObject(name, 'Receipt', 'Receipt', '4'),
            });
        }

        if (!this.modal && this.selectedContractType === ContractType.Bureau) {
            this.companyCreationHeader = 'Kobler og videresender til Unimicro Byrå';
            const byraUrl = environment.authority?.includes('dev-login')
                ? 'https://dev-byra.unimicro.no'
                : 'https://byra.unimicro.no';
            setTimeout(() => {
                window.open(byraUrl, '_self');
            }, 5000);
        } else {
            this.busyCreatingCompany = false;
            this.companyService.invalidateCache();
            this.authService.setActiveCompany(company, '/');
        }

        if (this.modal) {
            this.closeModal.emit();
        }
    }

    onChangeCompanyClicked() {
        if (this.sb1UserAgreements?.length > 1) {
            this.currentStep = COMPANY_CREATION_STEPS.SB1_BANKID_STEP1;
        } else {
            this.currentStep = COMPANY_CREATION_STEPS.BANKID_STEP1;
        }
    }

    onEditContactInfoClicked() {
        this.editMode = true;
        this.step2FormBankIdCopy = cloneDeep(this.step2FormBankId);
    }

    resetStep2FormBankId() {
        this.step2FormBankId.patchValue(this.step2FormBankIdCopy?.value);
        this.editMode = false;
    }

    goToValidationFailedAndSetTexts() {
        const bankName =
            this.domSanitizer.sanitize(SecurityContext.HTML, this.authService.publicSettings?.BankName) ||
            theme.appProvider;
        this.authService.publicSettings?.ValidationMethods?.forEach((method) => {
            if (method.Name === 'BrregRole') {
                this.validationFailedTexts.unshift(
                    `Oppdater Brønnøysundregisteret med en rolle tilknyttet deg, og opprett avtale i <strong>${this.appName}</strong> på nytt.`,
                    `Be en <strong>annen person</strong> med rolle på foretaket om å etablere avtale i ${this.appName}.`,
                );
            }

            if (method.Name === 'BankAccess') {
                this.validationFailedTexts.push(
                    `Oppdater kundeforholdet i ${bankName} slik at du får en rolle i banken, og opprett avtale i <strong>${this.appName}</strong> på nytt.`,
                    `Dersom foretaket ikke er kunde i ${bankName}, søk om å etablere kundeforhold.`,
                );
            }
        });

        this.currentStep = COMPANY_CREATION_STEPS.VALIDATION_FAILED;
        this.busyCreatingCompany = false;
    }

    // another solution?
    reloadPage() {
        window.location.reload();
    }
}
