import { Component, EventEmitter } from '@angular/core';
import { ErrorService } from '@app/services/common/errorService';
import { FileService } from '@app/services/common/fileService';
import { EmployeeLeaveService } from '@app/services/salary/employee-leave.service';
import { EmployeeLeave } from '@uni-entities';
import { IModalOptions, IUniModal } from '@uni-framework/uni-modal';
import { map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'uni-employee-leave-import-modal',
    templateUrl: './employee-leave-import-modal.component.html',
    styleUrls: ['./employee-leave-import-modal.component.sass'],
})
export class EmployeeLeaveImportModalComponent implements IUniModal {
    options: IModalOptions = {};
    onClose = new EventEmitter<EmployeeLeave[]>();

    TEMPLATE_FILE_NAME = 'EmployeeLeaveImportTemplate.xlsx';
    href = `${environment.PUBLIC_FILES_URL}/files/import/${this.TEMPLATE_FILE_NAME}`;
    busy: boolean;
    errorMessage: string;
    file: File;
    isValidFileFormat: boolean = true;

    constructor(
        private employeeLeaveService: EmployeeLeaveService,
        private fileService: FileService,
        private errorService: ErrorService,
    ) {}

    ngOnInit(): void {}

    onFilesChange(files: File[]) {
        this.file = files && files[0];

        if (this.file) {
            const type = this.file?.name?.toLowerCase().split(/[.]+/).pop();
            this.isValidFileFormat = type === 'xlsx';
        }
    }

    importFile() {
        if (!this.file || this.errorMessage) {
            this.errorService.handle(this.errorMessage || 'Ingen fil valgt');
            return;
        }
        this.busy = true;
        this.fileService
            .upload(this.file)
            .pipe(
                map((file) => <number>file.ExternalId),
                switchMap((fileID) => this.employeeLeaveService.importEmployeeLeaveFromExcel(fileID)),
            )
            .subscribe({
                next: (leaves) => {
                    this.busy = false;
                    this.onClose.next(leaves);
                },
                error: (err) => {
                    this.errorService.handle(err), (this.busy = false);
                },
            });
    }
}
