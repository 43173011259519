import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { Router } from '@angular/router';
import { INavbarLinkSection } from '../../navbar/navbar-links-common';
import { SidebarStateService } from '../sidebar-state';
import { AnalyticsService } from '@app/services/analytics-services/analytics.service';

@Component({
    selector: 'sidebar-section',
    templateUrl: './sidebar-section.html',
    styleUrls: ['./sidebar-section.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarSection {
    @Input() section: INavbarLinkSection;
    @Input() expanded: boolean;
    @Input() sidebarCollapsed: boolean;

    analytics = inject(AnalyticsService);

    hovering: boolean;
    timeoutId;

    constructor(
        private router: Router,
        public state: SidebarStateService,
    ) {}

    onButtonClick() {
        setTimeout(() => {
            if (this.section.isOnlyLinkSection) {
                this.state.setExpandedSection(undefined);
                this.router.navigateByUrl(this.section.url);
            } else if (!this.hovering || !this.sidebarCollapsed) {
                this.state.setExpandedSection(this.expanded ? undefined : this.section);
            }
        });
    }

    onIconClick(event: MouseEvent) {
        this.router.navigateByUrl(this.section.url);
        event.stopPropagation();
    }

    setHovering(hovering: boolean) {
        if (!this.sidebarCollapsed) return;

        this.hovering = hovering;

        clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(() => {
            this.state.updatePopoverVisibility(this.section, this.hovering);
        }, 150);
    }

    track(url: any) {
        this.analytics.trackSidebarEvent(url);
    }
}
