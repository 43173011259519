import { Component, Input, Output, EventEmitter, HostBinding } from '@angular/core';
import { ElsaContractType, ElsaProduct, ElsaProductType } from '@app/models';
import { theme, THEMES } from 'src/themes/theme';

@Component({
    selector: 'contract-type-card',
    templateUrl: './contract-type-card.html',
    styleUrls: ['./contract-type-card.sass'],
})
export class ContractTypeCard {
    @Input() contractType: ElsaContractType;
    @Input() disabled: boolean;
    @Input() disabledReason?: string;
    @Input() active: boolean;
    @Input() buttonClass: string;
    @Input() buttonLabel: string;

    @HostBinding('class.recommended')
    @Input()
    recommended: boolean;

    @Output() selected = new EventEmitter();
    @HostBinding('class.ext02-signup') isExt02Env = theme.theme === THEMES.EXT02;

    product: ElsaProduct;
    bulletPoints: string[];

    cssGridTemplateRows: string;

    ngOnChanges(changes) {
        if (changes['contractType'] && this.contractType) {
            this.bulletPoints = (this.contractType.BulletPoints || [])
                .filter((item) => !!item.Text)
                .map((item) => item.Text);

            this.cssGridTemplateRows = `repeat(${this.bulletPoints.length}, max-content)`;

            const productContractType = this.contractType.ProductContractTypes.find((pct) => {
                return pct.Product?.ProductType === ElsaProductType.Package;
            });

            if (productContractType) {
                this.product = productContractType.Product;
            }
        }
    }
}
