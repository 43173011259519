import { Component, EventEmitter, ViewChild } from '@angular/core';
import { UniForm, FieldType } from '@uni-framework/ui/uniform/index';
import { Accrual, AccrualPeriod, LocalDate, Period } from '@uni-entities';
import { ToastService, ToastType } from '@uni-framework/uniToast/toastService';
import { IUniModal, IModalOptions } from '@uni-framework/uni-modal';
import { BehaviorSubject, forkJoin, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { rigDate } from '@app/components/common/utils/rig-date';
import { AccountService } from '@app/services/accounting/accountService';
import { JournalEntryService } from '@app/services/accounting/journalEntryService';

interface IPeriodError {
    periodNotValidError: boolean;
    periodLockedError: boolean;
    fromPeriodLocked: boolean;
    toPeriodLocked: boolean;
}

@Component({
    selector: 'accrual-modal-v2',
    templateUrl: './accrual-modal-v2.html',
})
export class AccrualModalV2 implements IUniModal {
    @ViewChild('form', { static: true }) form: UniForm;

    options: IModalOptions;
    onClose = new EventEmitter();

    modalConfig: any = {};

    model$ = new BehaviorSubject(null);
    fields$ = new BehaviorSubject([]);

    originalAccrualPeriods: Array<AccrualPeriod>;

    buttonsDisabled: boolean = false;
    hideRemoveButton: boolean = false;

    currentFinancialYearPeriods: Array<Period> = [];
    lockedDateSelected: boolean = false;
    private isJournalEntry: boolean = false;

    periodError: IPeriodError = {
        periodNotValidError: false,
        periodLockedError: false,
        fromPeriodLocked: false,
        toPeriodLocked: false,
    };

    constructor(
        private toastService: ToastService,
        private accountService: AccountService,
        private journalEntryService: JournalEntryService,
    ) {}

    ngOnDestroy() {
        this.model$.complete();
        this.fields$.complete();
    }

    onSaveClick() {
        if (this.validateAccrual()) {
            if (this.originalAccrualPeriods) {
                const deletedAccrualPeriods = this.originalAccrualPeriods.filter(
                    (x) =>
                        x.ID &&
                        x.ID !== 0 &&
                        !this.modalConfig.model.Periods.find(
                            (y) => x.AccountYear === y.AccountYear && x.PeriodNo === y.PeriodNo,
                        ),
                );

                deletedAccrualPeriods.forEach((deletedPeriod) => {
                    deletedPeriod.Deleted = true;
                    this.modalConfig.model.Periods.push(deletedPeriod);
                });
            }

            this.modalConfig.model.Periods.forEach((item) => {
                item.Amount = 0;
            });
            this.onClose.emit({
                action: 'ok',
                model: this.modalConfig.model,
            });
        }
    }

    private getDefaultPeriods(accrualAmount: number, startYear: number, startPeriod: number): Array<AccrualPeriod> {
        const accrualPeriodAmount: number = accrualAmount / 3;
        let accrualPeriods: Array<AccrualPeriod> = [];

        const ap1: AccrualPeriod = new AccrualPeriod();
        ap1.Amount = accrualPeriodAmount;
        ap1.StatusCode = 33001;
        ap1.AccountYear = startYear;
        ap1.PeriodNo = startPeriod;

        const ap2: AccrualPeriod = new AccrualPeriod();
        ap2.Amount = accrualPeriodAmount;
        ap2.StatusCode = 33001;
        if (ap1.PeriodNo > 11) {
            ap2.AccountYear = ap1.AccountYear + 1;
            ap2.PeriodNo = 1;
        } else {
            ap2.AccountYear = ap1.AccountYear;
            ap2.PeriodNo = ap1.PeriodNo + 1;
        }

        const ap3: AccrualPeriod = new AccrualPeriod();
        ap3.Amount = accrualPeriodAmount;
        ap3.StatusCode = 33001;
        if (ap2.PeriodNo > 11) {
            ap3.AccountYear = ap2.AccountYear + 1;
            ap3.PeriodNo = 1;
        } else {
            ap3.AccountYear = ap2.AccountYear;
            ap3.PeriodNo = ap2.PeriodNo + 1;
        }

        accrualPeriods = [ap1, ap2, ap3];
        accrualPeriods.forEach((period) => (period['_createguid'] = this.accountService.getNewGuid()));
        return accrualPeriods;
    }

    public onFormChange(event) {
        if (event['_periodFrom'] || event['_periodTo']) {
            let start: LocalDate;
            let end: LocalDate;

            if (event['_periodFrom']) {
                start = event['_periodFrom'].currentValue;
                end = this.modalConfig.model['_periodTo'];
            } else if (event['_periodTo']) {
                start = this.modalConfig.model['_periodFrom'];
                end = event['_periodTo'].currentValue;
            }

            this.validatePeriods(start, end);
            this.changeRecalculatePeriods();
        }
    }

    public ngOnInit() {
        let accrual = this.options.data.accrual;
        this.isJournalEntry = this.options.data.isJournalEntry;
        this.hideRemoveButton = this.options.data.hideRemoveButton || false;
        const accrualAmount = this.options.data.accrualAmount;
        const accrualStartDate = this.options.data.accrualStartDate;
        const journalEntryLineDraft = this.options.data.journalEntryLineDraft;

        if (!journalEntryLineDraft && !accrual && (!accrualStartDate || !accrualAmount)) {
            setTimeout(() => {
                this.toastService.addToast('Periodisering', ToastType.bad, 10, 'Mangler informasjon om beløp og dato!');
                this.onClose.emit(false);
            });
            return;
        }

        if (!accrual) {
            accrual = new Accrual();
            accrual.AccrualJournalEntryMode = 0;
            accrual['_createguid'] = this.accountService.getNewGuid();
            accrual.AccrualAmount = accrualAmount;

            if (!journalEntryLineDraft) {
                // set this value to the id of the object in getAccrualPeriodsOptions
                // array to change default period value
                if (accrualStartDate) {
                    accrual['_financialDate'] = accrualStartDate;
                    accrual.Periods = this.getDefaultPeriods(
                        accrualAmount,
                        accrualStartDate.year,
                        accrualStartDate.month + 1,
                    );
                    accrual['_periodAmount'] = parseFloat(accrual.Periods[0].Amount).toFixed(2);
                    accrual['_periodFrom'] = new LocalDate(new Date(accrualStartDate.year, accrualStartDate.month, 1));
                    accrual['_periodTo'] = new LocalDate(
                        rigDate(accrual['_periodFrom']).add(2, 'month').toLocaleString(),
                    );
                }
            } else {
                const startYear: number = journalEntryLineDraft.FinancialDate.year;

                const startPeriod: number = journalEntryLineDraft.FinancialDate.month;
                if (journalEntryLineDraft.FinancialDate) {
                    accrual.Periods = this.getDefaultPeriods(accrualAmount, startYear, startPeriod);
                    accrual['_financialDate'] = journalEntryLineDraft.FinancialDate;
                    accrual['_periodAmount'] = parseFloat(accrual.Periods[0].Amount).toFixed(2);
                    accrual['_periodFrom'] = new LocalDate(
                        new Date(
                            journalEntryLineDraft.FinancialDate.year,
                            journalEntryLineDraft.FinancialDate.month,
                            1,
                        ),
                    );
                    accrual['_periodTo'] = new LocalDate(
                        rigDate(accrual['_periodFrom']).add(2, 'month').toLocaleString(),
                    );
                }
            }
        } else {
            if (accrual.JournalEntryLineDraftID > 0) {
                accrual = this.createNewAccrualBasedOnOldOne(accrual);
            }

            this.originalAccrualPeriods = JSON.parse(JSON.stringify(accrual.Periods));

            // just incase amount is changed recalculate period amounts
            accrual['_periodAmount'] = (accrual.AccrualAmount / accrual.Periods.length).toFixed(2);
            accrual.Periods.forEach((period) => {
                period.Amount = accrual['_periodAmount'];
            });

            // recalculate periodFrom & periodTo from existing periods
            accrual['_periodFrom'] = new LocalDate(
                new Date(accrual.Periods[0].AccountYear, accrual.Periods[0].PeriodNo - 1, 1),
            );
            accrual['_periodTo'] = new LocalDate(
                new Date(
                    accrual.Periods[accrual.Periods.length - 1].AccountYear,
                    accrual.Periods[accrual.Periods.length - 1].PeriodNo - 1,
                    1,
                ),
            );

            if (!accrual['financialDate']) {
                if (accrual.JournalEntryLineDraft) {
                    accrual['_financialDate'] = accrual.JournalEntryLineDraft.FinancialDate;
                } else if (journalEntryLineDraft && journalEntryLineDraft.FinancialDate) {
                    accrual['_financialDate'] = journalEntryLineDraft.FinancialDate;
                    accrual['_periodAmount'] = parseFloat(accrual.Periods[0].Amount).toFixed(2);
                } else if (accrualStartDate) {
                    let startYear: number;
                    if (accrual.Periods.length > 0) {
                        startYear = Math.min(...accrual.Periods.map((x) => x.AccountYear));
                    } else {
                        startYear = accrualStartDate.year;
                    }
                    accrual['_financialDate'] = accrualStartDate;
                } else {
                    accrual['_financialDate'] = accrualStartDate;

                    if (accrual.ID) {
                        this.toastService.addToast(
                            'Periodisering',
                            ToastType.bad,
                            10,
                            'Denne periodiseringen mangler en bilagslinjekladd!',
                        );
                    }
                }
            }
        }

        accrual['_numberOfPeriods'] = accrual.Periods.length;

        this.modalConfig.model = accrual;
        this.model$.next(this.modalConfig.model);

        this.setupForm();

        if (this.modalConfig.model) {
            this.validatePeriods();
            this.changeRecalculatePeriods();
        }
    }

    public isLockedPeriod(periodYear, periodNumber): boolean {
        const tempDate = this.journalEntryService.getAccountingLockedDate();

        if (tempDate) {
            const lockedDate = new LocalDate(tempDate.toString());
            const lockedMonth = lockedDate.month;
            const lockedYear = lockedDate.year;

            if (periodYear < lockedYear) {
                return true;
            } else if (periodYear > lockedYear) {
                return false;
            } else if (periodNumber <= lockedMonth) {
                // periodYear = lockedYear, so check periodNumber vs lockedMonth
                return true;
            }
        }

        // no locked date set, so this period cant be locked
        return false;
    }

    private setupForm() {
        this.fields$.next(this.getFields());
        this.modalConfig.modelJournalEntryModes = this.getAccrualJournalEntryModes();

        if (this.isAccrualAccrued()) {
            this.toastService.addToast(
                'Periodisering',
                ToastType.warn,
                8,
                'Denne periodiseringen er allerede periodisert, og kan ikke redigere ytterligere',
            );

            this.buttonsDisabled = true;
        } else {
            this.buttonsDisabled = false;
        }

        setTimeout(() => {
            const section = this.form.section(1);
            if (section && !section.isOpen) {
                section.toggle();
            }

            const model = this.model$.getValue();

            const isBill = document.querySelector('uni-bill');
            const isJournalEntry = document.querySelector('journalentries') || this.isJournalEntry;

            let balanceAccountLookup;
            let resultAccountLookup;

            if (isBill || isJournalEntry) {
                if (!model.BalanceAccountID) {
                    balanceAccountLookup = this.accountLookup(`SaftMappingAccount.AccountID eq 1749`);
                }
            } else {
                if (!model.BalanceAccountID) {
                    balanceAccountLookup = this.accountLookup(`SaftMappingAccount.AccountID eq 2900`);
                }

                if (!model.ResultAccountID) {
                    const defaultAccountID = this.options.data.companySettings?.DefaultAccrualAccountID;
                    const resultAccountFilter = defaultAccountID
                        ? `ID eq ${defaultAccountID}`
                        : `AccountNumber eq 3901`;
                    resultAccountLookup = this.accountLookup(resultAccountFilter);
                }
            }

            forkJoin<[any, any]>([balanceAccountLookup || of([]), resultAccountLookup || of([])])
                .pipe(
                    switchMap(([balanceAccount, resultAccount]) => {
                        if (balanceAccount.length == 1) {
                            return of([balanceAccount, resultAccount]);
                        } else if (balanceAccount.length > 1) {
                            const filter = isBill || isJournalEntry ? 'AccountNumber eq 1749' : 'AccountNumber eq 2900';
                            return this.accountLookup(filter).pipe(map((account) => [account, resultAccount]));
                        }

                        return of([null, resultAccount]);
                    }),
                )
                .subscribe(([balanceAccount, resultAccount]) => {
                    if (balanceAccount && balanceAccount[0]) {
                        model.BalanceAccountID = balanceAccount[0].ID;
                    }

                    if (resultAccount && resultAccount[0]) {
                        model.ResultAccountID = resultAccount[0].ID;
                    }

                    this.model$.next(model);
                });
        }, 200);
    }

    private validatePeriods(start?: LocalDate, end?: LocalDate): void {
        this.periodError.periodNotValidError = false;
        this.periodError.periodLockedError = false;
        this.periodError.fromPeriodLocked = false;
        this.periodError.toPeriodLocked = false;

        start = start ?? this.modalConfig.model['_periodFrom'];
        end = end ?? this.modalConfig.model['_periodTo'];

        if (rigDate(end).diff(rigDate(start), 'month') < 0) {
            this.periodError.periodNotValidError = true;
        } else if (rigDate(end).diff(rigDate(start), 'month') + 1 > 120) {
            this.modalConfig.model['_periodTo'] = new LocalDate(rigDate(start).add(119, 'month').toLocaleString());
            this.toastService.addToast(
                'Periodisering',
                ToastType.warn,
                5,
                'Begresninger i hvor langt frem et bilag kan periodiseres ' +
                    'i kombinasjon med valgte første periode gjorde at ' +
                    'beløpet kun kunne fordeles på 120 perioder.',
            );
        }

        if (this.isLockedPeriod(start.year, start.month)) {
            this.periodError.fromPeriodLocked = true;
        }

        if (this.isLockedPeriod(end.year, end.month)) {
            this.periodError.toPeriodLocked = true;
        }

        if (this.periodError.fromPeriodLocked || this.periodError.toPeriodLocked) {
            this.periodError.periodLockedError = true;
        }

        this.model$.next(this.modalConfig.model);
        this.fields$.next(this.getFields());
    }

    private isAccrualAccrued(): boolean {
        return this.modalConfig?.model?.JournalEntryLineDraftID > 0;
    }

    private getAccrualJournalEntryModes(): Array<AccrualJournalEntryMode> {
        return [
            { ID: 0, Name: 'Per år' },
            { ID: 2, Name: 'Per måned' },
        ];
    }

    private isAccrualPeriodsEqualOrLessThan120(periods: Array<AccrualPeriod>): boolean {
        const numberOfPeriods: number = periods.length;
        const firstPeriod: AccrualPeriod = periods[0];
        const lastPeriod: AccrualPeriod = periods[numberOfPeriods - 1];

        if (!periods || periods.length < 2) {
            return true;
        }

        if (lastPeriod.AccountYear - firstPeriod.AccountYear < 10) {
            return true;
        }

        if (lastPeriod.PeriodNo < firstPeriod.PeriodNo) {
            return true;
        }

        return false;
    }

    private createNewAccrualBasedOnOldOne(accrual: Accrual) {
        return <Accrual>{
            AccrualAmount: accrual.AccrualAmount,
            AccrualJournalEntryMode: accrual.AccrualJournalEntryMode,
            BalanceAccountID: accrual.BalanceAccountID,
            Periods: accrual.Periods.map((period) => {
                return <AccrualPeriod>{
                    Amount: period.Amount,
                    StatusCode: 33001,
                    AccountYear: period.AccountYear,
                    PeriodNo: period.PeriodNo,
                    _createguid: this.accountService.getNewGuid(),
                };
            }),
            _createguid: this.accountService.getNewGuid(),
        };
    }

    validateAccrual(): boolean {
        const model = this.modalConfig.model;
        let valid = true;

        if (!model.Periods.length || !this.isAccrualPeriodsEqualOrLessThan120(model.Periods)) {
            this.toastService.addToast(
                'Periodisering',
                ToastType.bad,
                10,
                'Periodisering må være minst 1 periode, og maks 120 perioder frem fra første periode',
            );

            valid = false;
        }

        if (!model.BalanceAccountID) {
            this.toastService.addToast('Periodisering', ToastType.bad, 10, 'Periodiseringen mangler balansekonto');
            valid = false;
        }

        return valid;
    }

    private changeRecalculatePeriods(): void {
        const accrualPeriods: Array<AccrualPeriod> = new Array<AccrualPeriod>();
        let start = rigDate(this.modalConfig.model['_periodFrom']);
        let end = rigDate(this.modalConfig.model['_periodTo']);

        while (start <= end) {
            const ap: AccrualPeriod = new AccrualPeriod();
            ap.AccountYear = start.year();
            ap.PeriodNo = start.month() + 1;
            ap.StatusCode = 33001;
            accrualPeriods.push(ap);

            start.add(1, 'month');
        }

        const newAccrualPeriods = accrualPeriods.filter((period) => {
            return !this.modalConfig.model.Periods.find((x) => {
                return period.AccountYear === x.AccountYear && period.PeriodNo === x.PeriodNo;
            });
        });

        // remove those periods that are not selected
        this.modalConfig.model.Periods = this.modalConfig.model.Periods.filter((period) => {
            return accrualPeriods.find((x) => {
                return period.AccountYear === x.AccountYear && period.PeriodNo === x.PeriodNo;
            });
        });
        newAccrualPeriods.forEach((period) => (period['_createguid'] = this.accountService.getNewGuid()));

        this.modalConfig.model.Periods = this.modalConfig.model.Periods.concat(newAccrualPeriods);

        this.modalConfig.model['_numberOfPeriods'] = accrualPeriods.length;
        if (this.modalConfig.model.Periods.length > 0) {
            this.modalConfig.model['_periodAmount'] = (
                this.modalConfig.model.AccrualAmount / this.modalConfig.model.Periods.length
            ).toFixed(2);
            this.modalConfig.model.Periods.forEach((item) => {
                item.Amount = this.modalConfig.model['_periodAmount'];
            });
        } else {
            this.modalConfig.model['_periodAmount'] = null;
        }

        this.model$.next(this.modalConfig.model);
    }

    private getFields() {
        const periodNotValidError = this.periodError.periodNotValidError;
        const periodLockedError = this.periodError.periodLockedError;
        const fromPeriodLocked = this.periodError.fromPeriodLocked;
        const toPeriodLocked = this.periodError.toPeriodLocked;

        return [
            {
                EntityType: 'Accrual',
                Property: 'AccrualAmount',
                FieldType: FieldType.NUMERIC,
                ReadOnly: true,
                Label: 'Periodiseringsbeløp',
                FieldSet: 0,
                Section: 0,
            },
            {
                EntityType: 'Accrual',
                Property: '_numberOfPeriods',
                FieldType: FieldType.NUMERIC,
                ReadOnly: true,
                Label: 'Antall Perioder',
                FieldSet: 0,
                Section: 0,
            },
            {
                EntityType: 'Accrual',
                Property: '_periodAmount',
                FieldType: FieldType.NUMERIC,
                ReadOnly: true,
                Label: 'Periodebeløp',
                FieldSet: 0,
                Section: 0,
            },
            {
                EntityType: 'Accrual',
                Property: '_periodFrom',
                FieldType: FieldType.MONTH_PICKER,
                Label: 'Periode fra',
                FieldSet: 0,
                Section: 0,
                Classes: periodNotValidError || (periodLockedError && fromPeriodLocked) ? 'error' : '',
            },
            {
                EntityType: 'Accrual',
                Property: '_periodTo',
                FieldType: FieldType.MONTH_PICKER,
                Label: 'Periode til',
                FieldSet: 0,
                Section: 0,
                Classes: periodNotValidError || (periodLockedError && toPeriodLocked) ? 'error' : '',
            },
            {
                EntityType: 'Accrual',
                Property: 'BalanceAccountID',
                Label: 'Balansekonto',
                Sectionheader: 'Periodiseringskonto',
                Tooltip: {
                    Text:
                        'Normalt brukes en konto i 17-serien for forskuddsbetalte kostnader og påløpte inntekter, ' +
                        'og en konto i 29-serien brukes for påløpte kostnader og uopptjente inntekter',
                },
                Section: 1,
                FieldType: FieldType.AUTOCOMPLETE,
                Options: this.getAccountSearchConfig(),
            },
            {
                EntityType: 'Accrual',
                Property: 'ResultAccountID',
                Label: 'Resultatkonto',
                Sectionheader: 'Periodiseringskonto',
                Section: 1,
                Hidden:
                    !!document.querySelector('uni-bill') ||
                    !!document.querySelector('journalentries') ||
                    this.isJournalEntry,
                FieldType: FieldType.AUTOCOMPLETE,
                Options: this.getAccountSearchConfig(),
            },
        ];
    }

    private getAccountSearchConfig() {
        return {
            valueProperty: 'ID',
            debounceTime: 200,
            template: (account) => account && `${account.AccountNumber} - ${account.AccountName}`,
            search: (searchText: string) => {
                let filter = 'Account.Visible eq 1 and isnull(Account.AccountID,0) eq 0';

                if (searchText?.length) {
                    const searchFilter = !isNaN(parseInt(searchText[0]))
                        ? `startswith(Account.AccountNumber,'${searchText}')`
                        : `contains(Account.AccountName,'${searchText}')`;

                    filter += ` and ( ${searchFilter} )`;
                } else {
                    // add 17xx filter
                }

                return this.accountLookup(filter).pipe(
                    map((accounts) => {
                        // Put exact account number match on top of result list
                        if (accounts?.length > 1 && searchText?.length === 4 && !isNaN(parseInt(searchText))) {
                            const exactMatchIndex = accounts.findIndex(
                                (acc) => acc.AccountNumber === parseInt(searchText),
                            );
                            if (exactMatchIndex > 0) {
                                accounts.splice(0, 0, accounts.splice(exactMatchIndex, 1)[0]);
                            }
                        }
                        return accounts;
                    }),
                );
            },
            getDefaultData: (modelValue) => {
                return modelValue ? this.accountLookup(`ID eq ${modelValue}`) : of([]);
            },
        };
    }

    private accountLookup(filter: string) {
        const selects = ['ID as ID', 'AccountNumber as AccountNumber', 'AccountName as AccountName'];

        const odata = `model=Account&select=${selects.join(',')}&filter=${filter}&orderby=AccountNumber&top=25&join=Account.SaftMappingAccountID eq SaftMappingAccount.ID`;
        return this.accountService.cachedSearch(odata);
    }
}

export class AccrualJournalEntryMode {
    public ID: number;
    public Name: string; // eslint-disable-line
}
