import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UniFrameworkModule } from '@uni-framework/frameworkModule';

import { ImportService } from './importService';
import { ImportJobService } from './import-job-service';

import { DisclaimerModal } from './modals/disclaimer/disclaimer-modal';
import { ImportModal } from './modals/import/import-modal';

import { InvoiceDisclaimerModal } from './modals/invoice-disclaimer/invoice-disclaimer-modal';
import { CustomImportComponent } from './modals/custom/custom-import-component';
import { CustomImportService } from './customImportService';

@NgModule({
    imports: [CommonModule, UniFrameworkModule],
    providers: [ImportService, ImportJobService, CustomImportService],
    declarations: [ImportModal, DisclaimerModal, InvoiceDisclaimerModal, CustomImportComponent],
    exports: [],
})
export class ImportModule {}
