import { Component, Output, EventEmitter } from '@angular/core';
import { CompanySettings, User } from '@uni-entities';
import { ActivationEnum } from '../../../../models/activationEnum';
import { forkJoin } from 'rxjs';
import { ElsaProduct, ElsaPurchase, ElsaPurchaseStatus } from '@app/models';
import { theme } from 'src/themes/theme';
import { IModalOptions, IUniModal } from '@uni-framework/uni-modal';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastService, ToastType } from '@uni-framework/uniToast/toastService';
import { AuthService, CurrentUserWithout2FADetails } from '@app/authService';
import { ErrorService } from '@app/services/common/errorService';
import { IntegrationServerCaller } from '@app/services/common/integrationServerCaller';
import { UserService } from '@app/services/common/userService';
import { ElsaPurchaseService } from '@app/services/elsa/elsaPurchasesService';

interface TravelTextInitModel {
    OrgNumber: string;
    CompanyName: string;
    CompanyEmail: string;
    Address: string;
    Zip: string;
    City: string;
    UserName: string;
    UserEmail: string;
    UserPhoneNumber: string;
}

interface TravelTextInviteUsers {
    Name: string;
    Email: string;
    Deleted?: boolean;
}

interface TravelTextCompany {
    EntityName: string;
    RoleType: string;
    RoleForId: number;
}

enum TravelTextSteps {
    INIT,
    ALREADY_CUSTOMER,
    LOGIN,
    CHOOSE_COMPANY,
    REGISTER_COMPANY,
    REGISTER_USER,
    COMPANY_CREATED,
    //INVITE_USERS,
}

@Component({
    selector: 'purchase-traveltext-modal',
    templateUrl: './purchase-traveltext-modal.html',
    styleUrls: ['./purchase-traveltext-modal.sass'],
})
export class PurchaseTraveltextModal implements IUniModal {
    @Output() onClose = new EventEmitter();

    options: IModalOptions = {};

    company: CompanySettings;
    user: CurrentUserWithout2FADetails;
    users: User[];
    travelTextUsers: any[];

    model: TravelTextInitModel = <TravelTextInitModel>{};
    invalidInputErrorMsg: string;

    appName = theme.appName;
    product: ElsaProduct;
    loginUrl = 'https://web.traveltext.no';
    eulaUrl = 'https://web.traveltext.no/eula';
    googlePlayUrl = 'https://play.google.com/store/apps/details?id=no.traveltext.traveltextapp';
    appStoreUrl = 'https://itunes.apple.com/no/app/traveltext-mobile/id1445561601?mt=8';

    steps = TravelTextSteps;
    currentStep = TravelTextSteps.INIT;
    busy = false;

    travelTextLoginForm = new UntypedFormGroup({
        Email: new UntypedFormControl('', Validators.required),
        Password: new UntypedFormControl('', Validators.required),
    });

    travelTextToken: string;
    travelTextCompanies: TravelTextCompany[] = [];

    selectedTravelTextCompany: TravelTextCompany;
    orgNrAvailable = false;

    elsaPurchaseStatus = ElsaPurchaseStatus;

    constructor(
        private userService: UserService,
        private errorService: ErrorService,
        private purchaseService: ElsaPurchaseService,
        private integrationService: IntegrationServerCaller,
        private toastService: ToastService,
        private authService: AuthService,
    ) {}

    ngOnInit() {
        if (this.options?.data?.product) {
            this.product = this.options.data.product;
        }

        if (this.options?.data?.companySettings) {
            this.company = this.options.data.companySettings;
        }

        if (this.product['_isBought']) {
            // we should never get here until we have a solution for inviting users

            // setTimeout because emitting onClose in ngOnInit throws an ObjectUnsubscribedError
            setTimeout(() => {
                this.onClose.emit(false);
            });
            //this.initChooseUsers();
        } else {
            this.checkOrgNrAvailable();
        }
    }

    checkOrgNrAvailable() {
        if (!this.company.OrganizationNumber) {
            this.currentStep = this.steps.ALREADY_CUSTOMER;
            return;
        }

        this.busy = true;
        this.integrationService.travelTextOrgNrAvailable(this.company.OrganizationNumber).subscribe({
            next: (available) => {
                this.busy = false;
                this.orgNrAvailable = available;
                this.currentStep = available ? this.steps.ALREADY_CUSTOMER : this.steps.LOGIN;
            },
            error: () => {
                this.currentStep = this.steps.ALREADY_CUSTOMER;
                this.busy = false;
            },
        });
    }

    initActivationModel() {
        this.userService.getCurrentUser().subscribe({
            next: (res) => {
                this.user = res;

                this.model = {
                    OrgNumber: this.company.OrganizationNumber,
                    CompanyName: this.company.CompanyName,
                    CompanyEmail: this.company.DefaultEmail?.EmailAddress || this.user.Email,
                    Address: this.company.DefaultAddress?.AddressLine1,
                    Zip: this.company.DefaultAddress?.PostalCode,
                    City: this.company.DefaultAddress?.City,
                    UserName: this.user.DisplayName,
                    UserEmail: this.user.Email,
                    UserPhoneNumber: this.user.PhoneNumber,
                };

                this.currentStep = this.steps.REGISTER_COMPANY;
            },
            error: (err) => {
                this.errorService.handle(err);
            },
        });
    }

    initChooseUsers() {
        // todo: fetch traveltext companyID before we fetch traveltext users
        // we need the companyID to be able to invite users
        //
        // there is currently no way of fetching this ID

        this.busy = true;
        forkJoin([this.userService.GetAll(), this.integrationService.getTravelTextUsers()]).subscribe({
            next: (res) => {
                this.users = res[0].filter((user) => user.Email && user.StatusCode !== 110000);
                this.travelTextUsers = res[1];

                this.users.map((user) => {
                    this.travelTextUsers.forEach((ttUser) => {
                        if (user.Email === ttUser.Email) {
                            return (user['_selected'] = true);
                        }
                    });
                });
                this.busy = false;
            },
            error: (err) => {
                this.errorService.handle(err);
                this.busy = false;
            },
        });
    }

    goToRegisterUser() {
        this.currentStep = this.steps.REGISTER_USER;
        this.invalidInputErrorMsg = '';
    }

    checkInputs() {
        if (!this.model.UserName || !this.model.UserEmail || !this.model.UserPhoneNumber) {
            this.invalidInputErrorMsg = '* Alle feltene er påkrevd';
        } else if (!/^\d+$/.test(this.model.UserPhoneNumber)) {
            this.invalidInputErrorMsg = '* Mobilnummer kan kun inneholde tall';
        } else if (!this.isValidEmailAddress(this.model.UserEmail)) {
            this.invalidInputErrorMsg = '* Ugyldig epostadresse';
        } else {
            this.invalidInputErrorMsg = '';
            this.purchaseInElsa(false, true);
        }
    }

    isValidEmailAddress(email: string): boolean {
        // <something>@<something>.<something>
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(String(email).toLowerCase());
    }

    purchaseInElsa(setIntegration: boolean, activateNewCompany: boolean) {
        this.busy = true;
        const purchase: ElsaPurchase = {
            ID: null,
            ProductID: this.product.ID,
        };
        this.purchaseService.massUpdate([purchase]).subscribe({
            next: () => {
                if (setIntegration) {
                    this.setTravelTextIntegration();
                    return;
                }

                if (activateNewCompany) {
                    // the integration is set in backend
                    this.activateNewCompany();
                    return;
                }
            },
            error: (err) => {
                this.errorService.handle(err);
                this.busy = false;
            },
        });
    }

    setTravelTextIntegration() {
        const payload = {
            CompanyName: this.selectedTravelTextCompany.EntityName,
            CompanyKey: this.authService.activeCompany.Key,
            CompanyId: this.selectedTravelTextCompany.RoleForId,
        };

        this.integrationService.setTravelTextIntegration(payload, this.travelTextToken).subscribe({
            next: () => this.handleActivationCompleted(),
            error: (err) => {
                this.getAndRemovePurchase();
                this.errorService.handle(err);
            },
        });
    }

    activateNewCompany() {
        this.busy = true;
        this.integrationService.registerTravelText(this.model).subscribe({
            next: () => this.handleActivationCompleted(),
            error: (err) => {
                this.getAndRemovePurchase();
                this.errorService.handle(err);
            },
        });
    }

    handleActivationCompleted() {
        // mutate the product so that it gets the correct status out in the lists
        this.product['_isBought'] = true;
        this.product['_purchaseStatus'] = this.elsaPurchaseStatus.Accepted;
        this.busy = false;
        this.currentStep = this.steps.COMPANY_CREATED;
    }

    getAndRemovePurchase() {
        this.purchaseService.getPurchaseByProductName(this.product.Name).subscribe({
            next: (purchase) => {
                if (purchase) {
                    this.removeElsaPurchase(purchase);
                } else {
                    this.busy = false;
                }
            },
            error: () => (this.busy = false),
        });
    }

    removeElsaPurchase(purchase: ElsaPurchase) {
        purchase.Deleted = true;
        this.purchaseService.massUpdate([purchase]).subscribe({
            next: () => {
                this.busy = false;
            },
            error: (err) => {
                this.errorService.handle(err);
                this.busy = false;
            },
        });
    }

    inviteUsers() {
        this.busy = true;

        const travelTextUsers: TravelTextInviteUsers[] = this.users.map((user) => {
            return {
                Name: user.DisplayName,
                Email: user.Email,
                Deleted: !user['_selected'],
            };
        });

        this.integrationService.inviteUsersTravelText(travelTextUsers).subscribe({
            next: () => {
                this.busy = false;
                this.onClose.emit(false);
            },
            error: (err) => {
                this.errorService.handle(err);
                this.busy = false;
            },
        });
    }

    travelTextLogin() {
        const payload = this.travelTextLoginForm.value;

        this.travelTextLoginForm.disable();
        this.busy = true;

        this.integrationService.travelTextLogin(payload).subscribe({
            next: (token: string) => {
                this.travelTextLoginForm.enable();
                this.travelTextToken = JSON.parse(token);
                this.goToChooseCompany();
            },
            error: () => {
                this.toastService.addToast(
                    'Feil brukernavn eller passord',
                    ToastType.warn,
                    4,
                    'Vennligst sjekk informasjonen, og prøv igjen',
                );
                this.travelTextLoginForm.enable();
                this.busy = false;
            },
        });
    }

    goToChooseCompany() {
        this.busy = true;
        this.integrationService.getTravelTextUserConfig(this.travelTextToken).subscribe({
            next: (userInfo: TravelTextCompany[]) => {
                this.travelTextCompanies = userInfo || [];
                if (this.travelTextCompanies.length === 0) {
                    this.toastService.addToast(
                        'Ingen selskaper',
                        ToastType.warn,
                        5,
                        `Vi fant ingen selskaper i ${this.product.Name}`,
                    );
                }
                this.currentStep = this.steps.CHOOSE_COMPANY;
                this.busy = false;
            },
            error: () => {
                this.busy = false;
                this.toastService.addToast(
                    'Noe gikk galt',
                    ToastType.warn,
                    5,
                    `Klarte ikke hente selskaper fra ${this.product.Name}`,
                );
            },
        });
    }

    goToChooseUsers() {
        if (this.product['_isBought']) {
            this.initChooseUsers();
        }
    }

    close() {
        const activationCode = this.product['_isBought'] ? ActivationEnum.ACTIVATED : ActivationEnum.NOT_ACTIVATED;
        this.onClose.emit(activationCode);
    }

    openLinkInNewTab(url: string) {
        window.open(url, '_blank');
    }
}
