<section class="header">
    <h1>Utestående per kunde</h1>
</section>

<section class="content">
    <widget-empty-state *ngIf="!loading && !data?.length">
        Det ser ikke ut som du har noe utestående.
    </widget-empty-state>

    <widget-pie-chart *ngIf="!loading && data?.length" [chartConfig]="chartConfig"></widget-pie-chart>
</section>
