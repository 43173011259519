import { Component, EventEmitter, Output } from '@angular/core';
import { theme } from 'src/themes/theme';
import { SurveyService } from '../survey.service';
import { ToastService, ToastType } from '@uni-framework/uniToast/toastService';

interface ISatisfactionSurveyResponse {
    UserIdentifier: string;
    Score: number;
    Comment: string;
    FeedbackTime?: Date;
    Anonymous?: boolean;
}

@Component({
    selector: 'uni-rating-survey',
    templateUrl: './rating-survey.component.html',
    styleUrls: ['./rating-survey.component.sass'],
})
export class RatingSurveyComponent {
    @Output() public onClose = new EventEmitter<boolean>();

    appName = theme.appName;

    responseToSend: ISatisfactionSurveyResponse = {
        UserIdentifier: this.surveyService.getGlobalID(),
        Comment: '',
        Score: 0,
        Anonymous: false,
    };

    ratingOptions = [
        { label: 'Veldig misfornøyd', icon: 'sentiment_very_dissatisfied', selected: false, value: 1 },
        { label: 'Misfornøyd', icon: 'sentiment_dissatisfied', selected: false, value: 2 },
        { label: 'Nøytral', icon: 'sentiment_neutral', selected: false, value: 3 },
        { label: 'Fornøyd', icon: 'sentiment_satisfied_alt', selected: false, value: 4 },
        { label: 'Veldig fornøyd', icon: 'sentiment_very_satisfied', selected: false, value: 5 },
    ];

    constructor(
        private surveyService: SurveyService,
        private toast: ToastService,
    ) {}

    onRatingSelected(rating) {
        this.ratingOptions.forEach((r) => (r.selected = false));
        rating.selected = true;
        this.responseToSend.Score = rating.value;
    }

    sendSurvey() {
        if (!this.responseToSend.Score) {
            return;
        }

        this.surveyService.postSurveyAnswer(this.responseToSend).subscribe(
            (res) => {
                if (res.Score !== 0) {
                    this.toast.addToast(
                        'Tilbakemelding sendt',
                        ToastType.good,
                        8,
                        'Takk for at du deltok, din mening er viktig for oss.',
                    );
                }
                this.onClose.emit(false);
            },
            (err) => {
                this.toast.addToast(
                    'Tilbakemelding feilet',
                    ToastType.warn,
                    8,
                    'Vi setter pris på at du ønsker å gi tilbakemelding, men noe gikk galt. Prøv igjen senere.',
                );
                this.onClose.emit(true);
            },
        );
    }

    close() {
        this.onClose.emit(true);
    }
}
