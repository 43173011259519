<header class="header">
    <h1>{{ 'DASHBOARD.RECENT_PAYROLL_RUNS.HEADER' | translate }}</h1>
</header>
<article class="content">
    <widget-empty-state *ngIf="ready && !model?.length">
        {{ 'DASHBOARD.RECENT_PAYROLL_RUNS.EMPTY' | translate }}
    </widget-empty-state>
    <simple-table *ngIf="ready && model?.length" class="selectable">
        <thead>
            <tr>
                <th>{{ 'DASHBOARD.RECENT_PAYROLL_RUNS.PAYROLL_COLUMN' | translate }}</th>
                <th>{{ 'DASHBOARD.RECENT_PAYROLL_RUNS.STATUS_COLUMN' | translate }}</th>
                <th>{{ 'DASHBOARD.RECENT_PAYROLL_RUNS.PAY_DATE_COLUMN' | translate }}</th>
            </tr>
        </thead>

        <tbody>
            <tr *ngFor="let item of model" (click)="goToRun(item)">
                <td>{{ item.id }} - {{ item.name }}</td>
                <td>{{ item.status }}</td>
                <td>{{ item.payDate }}</td>
            </tr>
        </tbody>
    </simple-table>
</article>
