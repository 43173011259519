import { Component, EventEmitter, ViewChild } from '@angular/core';
import {
    ShortUserNotificationSetting,
    UserNotificationSettingsMap,
    UserNotificationSettingsService,
} from '../notification-service';
import { IModalOptions, IUniModal } from '@uni-framework/uni-modal';
import { UserNotificationSettings } from '@uni-entities';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { SimpleTable } from '@uni-framework/ui/simple-table/simple-table';
import { ErrorService } from '@app/services/common/errorService';
import { UniTranslationService } from '@app/services/common/translationService';

@Component({
    selector: 'user-notification-settings-modal',
    templateUrl: './user-notification-settings-modal.html',
    styleUrls: ['./user-notification-settings-modal.sass'],
})
export class UserNotificationSettingsModal implements IUniModal {
    @ViewChild(SimpleTable) table: SimpleTable;

    options: IModalOptions = {};
    onClose = new EventEmitter();

    busy = true;
    settings: ShortUserNotificationSetting[] = [];
    subscribedToAllEmailNotifications: boolean;
    subscribedToAllWebNotifications: boolean;

    queryParamSubscription: Subscription;
    querySelectedNotificationType: string;

    constructor(
        public userNotificationSettingsService: UserNotificationSettingsService,
        private translationService: UniTranslationService,
        private activatedRoute: ActivatedRoute,
        private errorService: ErrorService,
    ) {}

    ngOnInit(): void {
        this.userNotificationSettingsService.getAll(true).subscribe({
            next: (settings) => {
                this.handleGetAllNotificationSettings(settings);
                this.scrollToSelectedIndex();
                this.busy = false;
            },
            error: (err) => {
                this.errorService.handle(err);
                this.busy = false;
            },
        });

        this.queryParamSubscription = this.activatedRoute.queryParamMap.subscribe((params) => {
            this.querySelectedNotificationType = params.get('notificationType') || '';
            this.scrollToSelectedIndex();
        });
    }

    ngOnDestroy(): void {
        this.queryParamSubscription?.unsubscribe();
    }

    scrollToSelectedIndex(): void {
        if (this.querySelectedNotificationType && this.settings) {
            // Sortes the same way it's sorted in the table to find the correct index to jump to
            const selectedSettingIndex = this.settings.findIndex(
                ({ NotificationType }) => NotificationType === this.querySelectedNotificationType,
            );

            if (selectedSettingIndex > -1) {
                this.table.scrollToIndex(selectedSettingIndex);
            }
        }
    }

    toggle(setting: ShortUserNotificationSetting, type: 'IgnoreEmail' | 'IgnoreNotification'): void {
        const oldSetting = setting[type];
        setting[type] = !setting[type];

        let request: Observable<UserNotificationSettings>;

        // Set it to Busy when we post, so we don't hit a constraint
        if (setting.ID) {
            request = this.userNotificationSettingsService.put(setting.ID, setting);
        } else {
            setting.busy = true;
            request = this.userNotificationSettingsService.post(setting);
        }

        request.subscribe({
            next: (res: UserNotificationSettings) => {
                setting.ID = res.ID;
                setting.busy = false;
                this.updateCheckkAllValues();
            },
            error: (err) => {
                this.errorService.handle(err);
                setting.busy = false;
                setting[type] = oldSetting;
            },
        });
    }

    onAllEmailsToggled() {
        this.toggleAll('IgnoreEmail', !this.subscribedToAllEmailNotifications);
    }

    onAllWebToggled() {
        this.toggleAll('IgnoreNotification', !this.subscribedToAllWebNotifications);
    }

    toggleAll(type: 'IgnoreEmail' | 'IgnoreNotification', blockAll: boolean): void {
        if (this.settings.every((s) => s[type] === blockAll)) {
            // All are already the toggle value, ignore.
            return;
        }

        const request = blockAll
            ? this.userNotificationSettingsService.blockAll(type)
            : this.userNotificationSettingsService.unblockAll(type);

        this.busy = true;
        request.subscribe({
            next: (settings) => {
                this.handleBlockAllResponse(settings);
                this.settings.forEach((s) => (s[type] = blockAll));
                this.busy = false;
            },
            error: (err) => {
                this.errorService.handle(err);
                this.busy = false;
            },
        });
    }

    private handleBlockAllResponse(settings: UserNotificationSettingsMap): void {
        for (let setting of this.settings) {
            const newSetting = settings[setting.NotificationType];

            setting.ID = newSetting.ID;

            // Map all 'ignore' attributes to our stored setting
            for (let key in newSetting) {
                if (key.startsWith('Ignore')) {
                    setting[key] = newSetting[key];
                }
            }
        }

        this.updateCheckkAllValues();
    }

    private updateCheckkAllValues(): void {
        // Flip the "All" checkboxes if they're all checked. not super efficient,
        // but there arent a lot of notifications
        this.subscribedToAllEmailNotifications = this.settings.every((s) => !s.IgnoreEmail);
        this.subscribedToAllWebNotifications = this.settings.every((s) => !s.IgnoreNotification);
    }

    private handleGetAllNotificationSettings(settings: UserNotificationSettingsMap): void {
        for (let key in settings) {
            settings[key].displayName = this.translateKey(key);
            settings[key].NotificationType = key;
            settings[key].busy = false;
        }

        // Input from API is a map of notificationtype to settings, we just need the setting
        this.settings = Object.values(settings).sort((a, b) => (a?.displayName ?? '').localeCompare(b?.displayName));
        this.updateCheckkAllValues();
    }

    private translateKey(key: string): string {
        const supplierInvoiceLabel = this.translationService.translate('ENTITIES.SupplierInvoice');

        switch (key) {
            case 'SupplierInvoiceForApproval':
                return `Godkjenn ${supplierInvoiceLabel}`; // = 10001, //somebody needs to approve the invoice
            case 'SupplierInvoiceApproved':
                return `${supplierInvoiceLabel} godkjent`; // = 10002,
            case 'SupplierInvoiceRejected':
                return `${supplierInvoiceLabel} avvist`; // = 10003,
            case 'SupplierInvoiceDue':
                return `${supplierInvoiceLabel} utgår`; // = 10004, //the stored invoice due date is due and status is not paid...
            case 'PaymentBatchForApproval':
                return 'Godkjenn betaling'; // = 11001, //somebody needs to apporve the payment
            case 'PaymentBatchApproved':
                return 'Betaling godkjent'; // = 11002,
            case 'PaymentBatchRejected':
                return 'Betaling avvist'; // = 11003,
            case 'PaymentBatchFailed':
                return 'Betaling feilet';
            case 'PaymentDue':
                return 'Betaling utgår'; // = 11004, //the stored payment is created but has not status completed...
            case 'PayrollForApproval':
                return 'Godkjenn lønnskjøring'; // = 12001, //sombody needs to approve the payroll
            case 'PayrollApproved':
                return 'Lønnskjøring godkjent'; // = 12002,
            case 'PayrollRejected':
                return 'Lønnskjøring avvist'; // = 12003,
            case 'ExternalOfferForApproval':
                return 'Godkjenn eksternt tilbud'; // = 13001,
            case 'ExternalOfferApproved':
                return 'Eksternt tilbud godkjent'; // = 13002,
            case 'ExternalOfferRejected':
                return 'Eksternt tilbud avvist'; // = 13003,
            case 'WorkItemGroupForApproval':
                return 'Godkjenn timeskjema'; // = 14001, //timesheets for approval
            case 'WorkItemGroupApproved':
                return 'Timeskjema godkjent'; // = 14002,
            case 'WorkItemGroupRejected':
                return 'Timeskjema avvist'; // = 14003,
            case 'CustomerInvoiceReminder':
                return 'Faktura påminnelse';
            case 'File':
                return 'Filer';
            case 'Comment':
                return 'Kommentarer';
            case 'BatchInvoice':
                return 'Fakturaer bunker';
            case 'LicensePurchase':
                return 'Lisenskjøp';
            case 'Sharing':
                return 'Utsendelse';
            case 'SharingFailed':
                return 'Utsendelse feilet';
            case 'BankIntegrationAgreementActivated':
                return 'Bankintegrasjonsavtale aktivert';
            case 'BankIntegrationAgreementDeActivated':
                return 'Bankintegrasjonsavtale deaktivert';
            case 'BankIntegrationAgreementRGBActivated':
                return 'Regnskapsgodkjentebetalinger aktivert';
            case 'BankIntegrationAgreementRGBDeActivated':
                return 'Regnskapsgodkjentebetalinger deaktivert';
            case 'EHFActivated':
                return 'EHF aktivert';
            case 'ExternalOfferPaymentReleased':
                return 'Eksternt tilbud betalt';
            case 'ExternalOfferPaymentStopped':
                return 'Eksternt tilbud stoppet';
            case 'LicensePurchaseActivateProduct':
                return 'Produktlisens aktivert';
            case 'LicensePurchasePurchased':
                return 'Lisens kjøpt';

            default:
                return `${key}`;
        }
    }
}
