import { IBizReportRenderer } from './IBizReport';

export class CsvReportRenderer implements IBizReportRenderer {
    rows = [];

    getFileExtension(): string {
        return '.csv';
    }

    formatNumber(value: any): string {
        return value ? value.toFixed(2).replace('.', ',') : '0';
    }

    createText(value: string) {
        return { type: 'text', text: value };
    }

    createElement(value: string) {
        const el = { type: value, children: [] };
        switch (value) {
            case 'tr':
                this.rows.push(el);
                break;
        }
        return el;
    }

    appendChild(el: any, child: any) {
        if (child.type === 'text') {
            if (el.type === 'span') {
                el.parent.text = child.text;
                return;
            }
            el.text = child.text;
            return;
        }
        child.parent = el;
        el.children.push(child);
    }

    setAttribute(el: any, attr: string, value: string) {
        if (attr === 'colspan') {
            el.colspan = parseInt(value);
        }
    }

    addClass(el: any, name: string) {}

    setStyle(el: any, style: string, value: string) {}

    async getBuffer(title: string, subTitle: string, companyName: string): Promise<string> {
        return Promise.resolve(undefined);
    }

    getCsvData(title: string, subTitle: string, companyName: string): string {
        const rows = [];
        const divider = ';';
        rows.push(title);
        rows.push(subTitle);
        rows.push('');
        for (var i = 0; i < this.rows.length; i++) {
            const row = this.rows[i];
            const cells = [];
            for (var ii = 0; ii < row.children.length; ii++) {
                const cell = row.children[ii];
                cells.push(cell.text);
                if (cell.colspan) {
                    for (var ci = 0; ci < cell.colspan - 1; ci++) {
                        cells.push('');
                    }
                }
            }
            rows.push(cells.join(divider));
        }
        return rows.join('\n');
    }
}
