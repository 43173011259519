<section class="uni-card">
    <section class="uni-card-header borderless">
        <h1>Brukeren din mangler roller</h1>
    </section>

    <section class="uni-card-content">
        <section class="alert warn">
            <i class="material-icons">warning</i>
            <span>Brukeren din er satt opp uten roller/tilganger i dette selskapet.</span>
        </section>

        <p>
            Listen under viser brukere med administratortilgang på selskapet.
            <br />De kan hjelpe med å få satt opp riktige roller under Innstillinger -> Brukere
        </p>

        <mat-spinner *ngIf="busy" class="c2a" diameter="35"></mat-spinner>

        <simple-table class="borders custom-scrollbar" *ngIf="admins?.length && !busy">
            <thead>
                <tr>
                    <th>Administrator</th>
                    <th>Epost</th>
                    <th>Telefon</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let admin of admins">
                    <td>{{ admin.DisplayName }}</td>
                    <td>
                        <a href="mailto:{{ admin.Email }}">{{ admin.Email }}</a>
                    </td>
                    <td>{{ admin.PhoneNumber }}</td>
                </tr>
            </tbody>
        </simple-table>
    </section>
</section>
