import { Injectable } from '@angular/core';
import { INavbarLinkSection } from '../navbar/navbar-links-common';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SidebarStateService {
    sidebarCollapsed$ = new BehaviorSubject<boolean>(true);
    activeRouteSection$ = new BehaviorSubject<INavbarLinkSection>(undefined);
    expandedSection$ = new BehaviorSubject<INavbarLinkSection>(undefined);

    constructor() {
        this.sidebarCollapsed$.next(localStorage.getItem('sidebar_collapsed') === 'true');

        this.sidebarCollapsed$.subscribe((collapsed) => {
            localStorage.setItem('sidebar_collapsed', collapsed.toString());
            if (collapsed) {
                this.expandedSection$.next(undefined);
            } else {
                this.expandedSection$.next(this.activeRouteSection$.value);
            }
        });

        this.activeRouteSection$.subscribe((section) => {
            if (!this.sidebarCollapsed$.value) {
                this.expandedSection$.next(section);
            }
        });
    }

    ngOnDestroy() {
        this.sidebarCollapsed$.complete();
        this.activeRouteSection$.complete();
        this.expandedSection$.complete();
    }

    toggle() {
        this.sidebarCollapsed$.next(!this.sidebarCollapsed$.value);
    }

    setActiveSection(section: INavbarLinkSection) {
        this.activeRouteSection$.next(section);
    }

    setExpandedSection(section: INavbarLinkSection) {
        this.expandedSection$.next(section);
    }

    updatePopoverVisibility(section: INavbarLinkSection, visible: boolean) {
        if (visible) {
            this.expandedSection$.next(section);
        }

        /*
            Check if the section reporting it's being closed
            is currently the one we have visible before clearing
            expandedSection$. This is to prevent race conditions
            since each section checks their hover state with
            independent debounce timeouts.
        */
        if (!visible && section === this.expandedSection$.value) {
            this.expandedSection$.next(undefined);
        }
    }
}
