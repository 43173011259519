import { NgModule, ModuleWithProviders } from '@angular/core';
import { UniNavbar } from './navbar/navbar';
import { UniSidebar } from './sidebar/sidebar';
import { NavbarCreateNew } from './navbar/create-new/navbar-create-new';
import { UniTabStrip } from './navbar/tabstrip/tabstrip';
import { UniTabstripHelp } from './navbar/tabstrip/help';
import { UniCompanyDropdown } from './navbar/company-dropdown/companyDropdown';
import { UniFrameworkModule } from '../../../framework/frameworkModule';
import { YearModal } from './navbar/company-dropdown/yearModal';
import { NavbarLinkService } from './navbar/navbar-link-service';

import { NavbarUserDropdown } from './navbar/user-dropdown/user-dropdown';

import { UniCompanySearch } from './smart-search/company-search/company-search';
import { UniSmartSearch } from './smart-search/smart-search';
import { UniSmartSearchItem } from './smart-search/smart-search-item';
import { SmartSearchService } from './smart-search/smart-search.service';
import { SmartSearchDataService } from './smart-search/smart-search-data.service';

import { NotificationsModule } from './notifications/notifications.module';

import { ChatBoxComponent } from './chat-box/chat-box.component';
import { UniMegaMenu } from './navbar/mega-menu/mega-menu';
import { SupportAccessIndicator } from './navbar/support-access-indicator/support-access-indicator';
import { DemoPackageSelector } from './navbar/demo-package-selector/demo-package-selector';
import { SupportChat } from './support-chat/support-chat';
import { JournalEntryCounter } from './navbar/journal-entry-counter/journal-entry-counter';
import { SidebarLinkList } from './sidebar/link-list/sidebar-link-list';
import { SidebarSection } from './sidebar/sidebar-section/sidebar-section';
import { SidebarStateService } from './sidebar/sidebar-state';

@NgModule({
    imports: [UniFrameworkModule, NotificationsModule],
    declarations: [
        UniNavbar,

        // REVISIT: move sidebar stuff to separate module?
        UniSidebar,
        SidebarLinkList,
        SidebarSection,
        UniMegaMenu,

        NavbarCreateNew,
        UniTabStrip,
        UniTabstripHelp,
        UniCompanyDropdown,
        NavbarUserDropdown,
        YearModal,
        UniCompanySearch,
        UniSmartSearch,
        UniSmartSearchItem,
        ChatBoxComponent,
        SupportAccessIndicator,
        DemoPackageSelector,
        SupportChat,
        JournalEntryCounter,
    ],
    providers: [SidebarStateService, SmartSearchService, SmartSearchDataService],
    exports: [
        UniNavbar,
        UniSidebar,
        NavbarCreateNew,
        UniCompanyDropdown,
        YearModal,
        ChatBoxComponent,
        SupportAccessIndicator,
        UniTabStrip,
    ],
})
export class LayoutModule {
    static forRoot(): ModuleWithProviders<LayoutModule> {
        return {
            ngModule: LayoutModule,
            providers: [NavbarLinkService],
        };
    }
}
