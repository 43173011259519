<section class="uni-modal" role="dialog">
    <header>
        Feriepenger for {{ vacationPayLine.Employee.EmployeeNumber }} -
        {{ vacationPayLine.Employee.BusinessRelationInfo.Name }}
    </header>
    <article>
        <uni-form
            class="two-column-layout"
            [config]="{ autofocus: true, showLabelAbove: true }"
            [fields]="vacationPayLineLayout"
            [model]="vacationPayLine"
            (changeEvent)="change($event)"
        >
        </uni-form>
    </article>
    <footer>
        <button class="secondary" (click)="close()" [disabled]="busy">Avbryt</button>
        <button class="c2a" (click)="updateList()" [disabled]="busy">Oppdater</button>
    </footer>
</section>
