<section role="dialog" class="uni-modal">
    <header>{{ options?.header || 'Legg til betaling' }}</header>

    <article>
        <section class="alert info" *ngIf="(model$ | async)?._SupplierHasDirectDebit">
            <i class="material-icons">info</i>
            <span>Denne leverandøren er satt opp med avtalegiro. Ønsker du likevel å opprette en utbetaling?</span>
        </section>

        <section class="flex align-center gap-2 border rounded mb-2 receiver-tag" *ngIf="compactLayout">
            <i class="material-icons">people_outline</i>
            <span>
                Mottaker:
                <strong> {{ model?.BusinessRelation?.Name || '' }} </strong>
            </span>
        </section>

        <uni-form class="two-column-layout" [config]="{ showLabelAbove: true }" [fields]="fields$" [model]="model$">
        </uni-form>

        <section *ngIf="options?.warning">
            {{ options.warning }}
        </section>
    </article>

    <footer>
        <button (click)="close('cancel')" class="secondary">Avbryt</button>
        <button (click)="close('ok')" class="c2a">
            {{ options?.buttonLabels?.accept || 'Legg til betaling' }}
        </button>
    </footer>
</section>
