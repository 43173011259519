import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { EmployeeLeaveService } from '@app/services/salary/employee-leave.service';
import { Employee, EmployeeLeave, Leavetype } from '@uni-entities';
import { rigDate } from '@app/components/common/utils/rig-date';

enum SelfReportWarningLevel {
    NoWarning = 1,
    SoftWarning = 2,
    HardWarning = 3,
    Closed = 4,
}

@Component({
    selector: 'employee-leave-counter-info',
    templateUrl: './employee-leave-counter-info.html',
    styleUrls: ['./employee-leave-counter-info.sass'],
})
export class EmployeeLeaveCounterInfo implements OnChanges {
    @Input() employeeLeave: any;
    @Input() employee: any;
    @Input() selfReportIsLocked: boolean;
    @Output() hasError = new EventEmitter<boolean>();

    invalidSelfReport: boolean;

    selfReportCounter;
    sickChildCounter;
    sickLeaveCounter;

    selfReportLevel: SelfReportWarningLevel;
    periodeString = '';
    lockedToString = '';
    exceedsMaxDays: boolean;

    constructor(private employeeLeaveService: EmployeeLeaveService) {}

    ngOnChanges(changes: SimpleChanges): void {
        this.selfReportLevel = SelfReportWarningLevel.NoWarning;
        this.getCountDataFromType();
        this.employeeLeave = { ...this.employeeLeave };
        this.employeeLeave.ToDate = rigDate(this.employeeLeave.ToDate).startOf('D');
        this.employeeLeave.FromDate = rigDate(this.employeeLeave.FromDate).startOf('D');
        this.periodeString =
            rigDate(this.employeeLeave.FromDate).add(-1, 'years').format('DD.MM.YYYY') +
            ' - ' +
            rigDate(this.employeeLeave.FromDate).format('DD.MM.YYYY');
    }

    getCountDataFromType() {
        switch (this.employeeLeave.LeaveType) {
            case Leavetype.Self_report:
                this.getData('count-self-report-days-in-period').subscribe({
                    next: (counter) => {
                        let hasError: boolean;
                        this.exceedsMaxDays =
                            counter.NumberOfDaysInPeriodAllowed <
                            rigDate(this.employeeLeave.ToDate ?? this.employeeLeave.FromDate).diff(
                                rigDate(this.employeeLeave.FromDate),
                                'day',
                            ) +
                                1;
                        this.selfReportCounter = counter;
                        const availablePeriods = counter.NumberOfPeriodsAllowed - counter.NumberOfPeriods;
                        const availableDays = counter.TotalNumberOfDaysAllowed - counter.TotalNumberOfDays;
                        hasError = this.selfReportCounter?.PeriodIsLocked || this.exceedsMaxDays;
                        if (this.selfReportIsLocked) {
                            this.lockedToString = rigDate(this.employee.DeniedSickDays).format('DD.MM.YYYY');
                            this.selfReportLevel = SelfReportWarningLevel.SoftWarning;
                        } else if (availablePeriods === 2 || availableDays === 2) {
                            this.selfReportLevel = SelfReportWarningLevel.SoftWarning;
                        } else if (availablePeriods === 1 || availableDays === 1) {
                            this.selfReportLevel = SelfReportWarningLevel.HardWarning;
                        } else if (availablePeriods < 1 || availableDays < 1) {
                            this.selfReportLevel = SelfReportWarningLevel.Closed;
                            hasError = true;
                        }

                        if (this.invalidSelfReport !== hasError || hasError) {
                            this.hasError.emit(hasError);
                            this.invalidSelfReport = hasError;
                        }
                    },
                });
                break;
            case Leavetype.Sick_child:
                this.getData('count-sick-child-days-in-period').subscribe({
                    next: (counter) => {
                        this.sickChildCounter = counter;
                    },
                });
                break;
            case Leavetype.Sick_leave_paid:
                this.getData('count-sick-leave-days-in-period').subscribe({
                    next: (counter) => {
                        this.sickLeaveCounter = counter;
                    },
                });
                break;
        }
    }

    getData(action: string) {
        return this.employeeLeaveService.GetAction(
            null,
            action,
            `employeeID=${this.employee.ID}&startDate=${rigDate(this.employeeLeave.FromDate).add(-1, 'years').format('YYYY-MM-DD')}&endDate=${rigDate(this.employeeLeave.FromDate).format('YYYY-MM-DD')}`,
        );
    }
}
