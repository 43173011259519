<uni-mega-menu *ngIf="navbarService.megaMenuVisible$ | async"></uni-mega-menu>

<section class="navbar">
    <section class="navbar-left">
        <section class="logo-and-hamburger flex align-center">
            <button
                (click)="sidebarStateService.toggle()"
                class="navbar-toggle unstyled outline-outside"
                aria-label="Expand/collapse sidebar"
            >
                <i class="material-icons hamburger-toggle"> menu </i>
            </button>

            <a routerLink="/" class="flex align-center" *ngIf="!isTraining">
                <img [src]="logoUrl" alt="logo" />
            </a>
        </section>

        <uni-company-dropdown [lockYearSelector]="deactivateFunctions"></uni-company-dropdown>

        <section
            *ngIf="isTestCompany && isSrEnvironment"
            class="navbar-alert"
            matTooltip="Dette er et demoselskap. Ved aktivering av kontrakt må nytt selskap opprettes, og alle data på dette selskapet vil bli slettet"
        >
            Kun for testing
        </section>

        <demo-package-selector
            *ngIf="showDemoPackageSelector"
            [isSupportUser]="isSupportUser"
            [isRoamingUser]="isRoamingUser"
        ></demo-package-selector>

        <support-access-indicator *ngIf="!isDnbEnvironment"></support-access-indicator>

        <section
            *ngIf="isArchived"
            class="navbar-alert"
            matTooltip="Dette selskapet er arkivert for oppbevaringsplikt."
        >
            ARKIVERT
        </section>
    </section>

    <section class="navbar-right">
        <section
            *ngIf="hasActiveContract && isDemo && !demoExpired"
            class="navbar-alert"
            routerLink="/contract-activation"
        >
            Demo
            <span class="demo-days-remaining">{{ demoDaysRemainingText || '' }}</span>
            <a class="ml-1">{{ isSrEnvironment ? ' Bestill SpareBank 1 Regnskap' : 'Opprett abonnement' }}</a>
        </section>

        <section *ngIf="isDemo && demoExpired" class="navbar-alert bad">Prøveperiode utløpt</section>

        <journal-entry-counter
            *ngIf="journalEntriesCounterEnabled"
            [contractType]="currentContractType"
            [contractID]="currentContractID"
        ></journal-entry-counter>

        <section
            *ngIf="isTemplateCompany"
            class="navbar-alert"
            matTooltip="Denne klienten er merket som mal. Det vil si at man ved oppretting av nye klienter kan kopiere data og innstillinger fra den. Man bør derfor kun registrere data som skal kopieres til nye klienter."
        >
            MALKLIENT
        </section>

        @if (bankLink) {
            <a [href]="bankLink" target="_blank" class="bank-link border flex align-center text-sm">
                Gå til nettbank
                <i aria-hidden="true" class="material-icons">launch</i>
            </a>
        }

        <uni-icon
            *ngIf="hasActiveContract && !deactivateFunctions"
            [icon]="'search'"
            (click)="openSearch()"
            matTooltip="Søk etter funksjon/tjeneste"
        >
        </uni-icon>

        <uni-icon
            *ngIf="hasActiveContract && !deactivateFunctions"
            [icon]="'company'"
            role="link"
            routerLink="bureau"
            matTooltip="Mine selskaper"
        >
        </uni-icon>

        <navbar-create-new *ngIf="hasActiveContract && !deactivateFunctions"></navbar-create-new>

        <notifications *ngIf="!deactivateFunctions" title=""></notifications>

        <section
            class="navbar-settings"
            *ngIf="hasActiveContract && (settingsLinks?.length || adminLinks?.length) && !deactivateFunctions"
        >
            <uni-icon #settingsToggle [icon]="'settings'" title="Innstillinger" matTooltip="Innstillinger"></uni-icon>

            <dropdown-menu [trigger]="settingsToggle" minWidth="14rem">
                <ng-template>
                    <ng-container>
                        <section class="settings-dropdown-container">
                            <section *ngIf="settingsLinks?.length" class="settings-links">
                                <span class="dropdown-menu-header settings-header">Innstillinger</span>
                                <a
                                    class="dropdown-menu-item"
                                    *ngFor="let link of settingsLinks"
                                    [routerLink]="link.url"
                                >
                                    {{ link.name | translate }}
                                </a>
                            </section>

                            <section *ngIf="toolLinks?.length" class="settings-links">
                                <span class="dropdown-menu-header settings-header">Verktøy</span>
                                <a class="dropdown-menu-item" *ngFor="let link of toolLinks" [routerLink]="link.url">
                                    {{ link.name | translate }}
                                </a>
                            </section>

                            <section *ngIf="adminLinks?.length" class="admin-links">
                                <span class="dropdown-menu-header settings-header">Admin</span>
                                <a class="dropdown-menu-item" *ngFor="let link of adminLinks" [routerLink]="link.url">
                                    {{ link.name | translate }}
                                </a>
                            </section>
                        </section>
                    </ng-container>

                    <section class="settings-overview-link">
                        <a routerLink="settings"> Oversikt innstillinger </a>
                    </section>
                </ng-template>
            </dropdown-menu>
        </section>

        <navbar-user-dropdown></navbar-user-dropdown>

        <uni-tabstrip-help *ngIf="!deactivateFunctions"></uni-tabstrip-help>
    </section>
</section>
