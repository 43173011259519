import { Component, ViewChild, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { IModalOptions, IUniModal } from '@uni-framework/uni-modal';
import { CompanySettings, Company } from '@uni-entities';
import { CompanyInfo } from './select-company/select-company.component';
import { ToastService, ToastType } from '@uni-framework/uniToast/toastService';
import { CompanyService } from '@app/services/common/companyService';
import { ErrorService } from '@app/services/common/errorService';

export enum STEPS {
    selectCompany = 0,
    selectTemplate = 1,
}

@Component({
    selector: 'uni-new-company-modal',
    templateUrl: './newCompanyModal.html',
    styleUrls: ['./newCompanyModal.sass'],
})
export class UniNewCompanyModal implements IUniModal, OnInit {
    @ViewChild(MatStepper, { static: true }) stepper: MatStepper;

    @Input() options = <IModalOptions>{};
    @Output() onClose = new EventEmitter<Company>();

    steps = STEPS;
    busy = false;
    currentStep = STEPS.selectCompany;
    lastStep = STEPS.selectTemplate;

    templateCompanies: Company[] = [];

    contractID: number;
    contractType: number;
    selectedTemplateCompany: Company;

    companyInfo = <CompanyInfo>{
        companySettings: <CompanySettings>{ DefaultAddress: {} },
        isTemplate: false,
    };

    constructor(
        private errorService: ErrorService,
        private companyService: CompanyService,
        private toastService: ToastService,
    ) {}

    ngOnInit() {
        this.contractID = this.options?.data?.contractID;
        this.contractType = this.options?.data?.contractType;

        this.busy = true;

        this.companyService.GetAll().subscribe({
            next: (res: Company[]) => {
                this.templateCompanies = (res || []).filter((c) => c.IsTemplate);
                this.busy = false;
            },
            error: (err) => {
                this.errorService.handle(err);
                this.busy = false;
            },
        });
    }

    onCompanyInfoChange() {
        const hasTemplateCompanies = this.templateCompanies && this.templateCompanies.length;
        const creatingTemplate = this.companyInfo && this.companyInfo.isTemplate;
        this.lastStep = hasTemplateCompanies && !creatingTemplate ? STEPS.selectTemplate : STEPS.selectCompany;
    }

    createCompany() {
        this.busy = true;
        if (this.selectedTemplateCompany) {
            this.companyService
                .createFromTemplate(
                    this.selectedTemplateCompany.Key,
                    this.companyInfo.companySettings,
                    null,
                    this.contractID,
                )
                .subscribe({
                    next: () => {
                        this.toastService.addToast(
                            'Oppretting fra malklient startet',
                            ToastType.good,
                            10,
                            'Denne jobben tar vanligvis et par minutter. Du vil få epost når klienten er klar til bruk',
                        );

                        this.onClose.emit();
                    },
                    error: (err) => {
                        this.errorService.handle(err);
                        this.busy = false;
                    },
                });
        } else {
            const companySettings = this.companyInfo.isTemplate ? null : this.companyInfo.companySettings;
            this.companyService
                .createCompany(
                    this.companyInfo.companySettings.CompanyName,
                    companySettings,
                    this.contractID,
                    null,
                    this.companyInfo.isTemplate,
                )
                .subscribe({
                    next: (companyResponse: Company) => {
                        this.onClose.emit(companyResponse);
                    },
                    error: (err) => {
                        this.errorService.handle(err);
                        this.busy = false;
                    },
                });
        }
    }

    close() {
        this.onClose.emit();
    }
}
