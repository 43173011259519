<section class="header">
    <h1>Reiseregninger</h1>
</section>

<section class="content">
    <widget-empty-state *ngIf="!loading && !travels?.length">
        <a routerLink="/salary/travels" class="travel-item"> Ingen ubehandlede reiseregninger </a>
    </widget-empty-state>

    <section *ngIf="!loading && travels?.length" id="travel-list">
        <a *ngFor="let travel of travels" routerLink="/salary/travels" class="travel-item unstyled-link">
            <section class="travel-info">
                <span>{{ travel.Description }}</span>
                <span class="name">{{ travel.Name }}</span>
            </section>

            <section class="type">{{ travel._costTypeText }}</section>
        </a>
    </section>
</section>
