<section class="header">
    <h1>Balansefordeling</h1>
</section>

<section class="content">
    <widget-empty-state *ngIf="!loading && !data?.length"> Mangler data. </widget-empty-state>

    <widget-pie-chart
        *ngIf="!loading && data?.length"
        [chartConfig]="chartConfig"
        (legendItemVisibilityChange)="onLegendItemVisibilityChange($event)"
    >
    </widget-pie-chart>
</section>
