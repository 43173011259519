<section class="teams-content flex">
    <section class="text-container flex-column">
        <h3>Velkommen til DNB Regnskap for lag og foreninger</h3>
        <p>På DNBs hjelpesider finner du tips og råd for å tilpasse <br />DNB Regnskap til ditt lag</p>

        <section class="link-section flex-column flex-1">
            <header>Tilpass regnskapet</header>
            <a
                href="https://hjelp.dnbregnskap.dnb.no/no/article/lag-og-forening-inngaende-balanse-19knx5s/"
                target="_blank"
                title="Gå til hjelpeside for å lære mer om inngående balanse"
            >
                Registrer hva laget eier
            </a>
            <a
                href="https://hjelp.dnbregnskap.dnb.no/no/article/lag-og-forening-tilpasse-kontoplan-1j2rkw8/"
                target="_blank"
                title="Gå til hjelpeside for å lære mer om å tilpasse kontoplan"
            >
                Legge til og endre navn på kontoene</a
            >
            <a
                href="https://hjelp.dnbregnskap.dnb.no/no/article/lag-og-forening-betale-nar-to-ma-godkjenne-1lb1u4i/"
                target="_blank"
                title="Gå til hjelpeside for å lære mer betalinger og godkjenninger"
            >
                Registrere flere brukere
            </a>

            <a
                href="https://hjelp.dnbregnskap.dnb.no/no/article/lag-og-forening-kom-i-gang-ue9ss5/"
                target="_blank"
                title="Gå til hjelpeside for å lære mer om regnskapsføring"
            >
                Gode råd for føring av regnskap
            </a>
        </section>
    </section>

    <section class="image-container">
        <img [src]="'config/themes/ext02/assets/team-and-association.svg'" alt="Illustration indicating teamwork" />
    </section>
</section>
