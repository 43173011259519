<section role="dialog" class="uni-modal small">
    <header>Velg produktnummer for viderefaktuering</header>
    <article>
        <section class="message">
            Under kan du gjøre endringer på standard produkt for viderefakturering, som vil gjelde for de varene som du
            har valgt. Om du vil permanent endre til annet produkt for hver gang du skal knytte leverandørvarer til
            produkt, må du endre dette i Salgsinstillinger -> Viderefaktuering.
        </section>

        <label>
            <span><b>Produkt:</b></span>
            <autocomplete [options]="autocompleteOptions" [(value)]="product"> </autocomplete>
        </label>
    </article>
    <footer>
        <button (click)="close()" class="secondary">Avbryt</button>
        <button (click)="accept()" class="c2a pull-right">Velg</button>
    </footer>
</section>
