import {
    Component,
    Input,
    SimpleChanges,
    ChangeDetectionStrategy,
    Output,
    EventEmitter,
    OnChanges,
} from '@angular/core';
import {
    IUniEditorButton,
    IUniEditorLayout,
} from '@app/components/common/uni-entity-editor/uni-editor-layout.interface';
import { IUniEditorChangeEvent } from '@app/components/common/uni-entity-editor/uni-editor-change-event.interface';
import { UniForm, UniField, FieldType } from '@uni-framework/ui/uniform';
import { UniEntityEditorFocusService } from '@app/components/common/uni-entity-editor/shared/services/uni-entity-editor-focus.service';
import { filter } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { UniTranslationService } from '@app/services/common/translationService';

@Component({
    selector: 'uni-entity-editor-section',
    templateUrl: './uni-entity-editor-section.component.html',
    styleUrls: ['./uni-entity-editor-section.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniEntityEditorSectionComponent implements OnChanges {
    @Input() layout: IUniEditorLayout;
    @Input() model: any;
    @Input() buttons: IUniEditorButton[];
    @Output() changeEvent: EventEmitter<IUniEditorChangeEvent> = new EventEmitter<IUniEditorChangeEvent>();
    translatedLayout: IUniEditorLayout;

    formModel$ = new BehaviorSubject({});

    constructor(
        private translationService: UniTranslationService,
        private focusService: UniEntityEditorFocusService,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['layout']) {
            this.translatedLayout = this.getTranslatedLayout(this.layout);
        }

        if (changes['model']) {
            this.formModel$.next(this.model);
        }
    }

    ngOnDestroy() {
        this.formModel$.complete();
    }

    onChange(event: SimpleChanges) {
        this.setFocusForFieldWithNoFocusEvent(event);
        this.changeEvent.emit({
            model: { ...this.model },
            changes: { ...event },
        });
    }

    onFormReady(uniForm: UniForm) {
        this.focusService
            .getFocus()
            .pipe(
                filter((focus) => !!focus),
                filter((focus) =>
                    this.layout.title
                        ? this.layout.title === focus.layoutTitle
                        : this.layout.fieldGroups
                              .reduce((acc, curr) => [...acc, ...curr.fields], [])
                              .some((field) => field.Property === focus.fieldProperty),
                ),
            )
            .subscribe((focus) => uniForm.field(focus?.fieldProperty)?.focus());
    }

    onFocus(uniField: UniField) {
        this.focusService.setFocus({
            layoutTitle: this.layout.title,
            fieldProperty: uniField.field.Property,
        });
    }

    onButtonClick(index: number) {
        this.buttons[index].click();
    }

    private getTranslatedLayout(layout: IUniEditorLayout): IUniEditorLayout {
        return {
            ...layout,
            fieldGroups: layout.fieldGroups.map((group) => ({
                ...group,
                fields: group.fields.map((field) => {
                    this.translationService.translate(field.Label);
                    return field;
                }),
            })),
            title: this.translationService.translate(layout.title),
            descriptions:
                layout.descriptions.map((d) => ({
                    ...d,
                    title: this.translationService.translate(d.title),
                    text: this.translationService.translate(d.text),
                })) || [],
        };
    }

    private setFocusForFieldWithNoFocusEvent(event: SimpleChanges) {
        const focusKey = Object.keys(event).find((key) =>
            this.layout.fieldGroups
                .reduce((acc, curr) => [...acc, ...curr.fields], [])
                .some(
                    (field) =>
                        field.Property === key &&
                        (field.FieldType === FieldType.CHECKBOX || field.FieldType === FieldType.CHECKBOXGROUP),
                ),
        );
        if (focusKey) {
            this.focusService.setFocus({
                layoutTitle: this.layout.title,
                fieldProperty: focusKey,
            });
        }
    }
}
