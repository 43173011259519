import { Component, EventEmitter } from '@angular/core';
import { IModalOptions, IUniModal } from '@uni-framework/uni-modal';
import { ImportService } from '../../importService';
import { CompanySettingsService } from '@app/services/common/companySettingsService';
import { ErrorService } from '@app/services/common/errorService';

@Component({
    selector: 'invoice-disclaimer-modal',
    templateUrl: './invoice-disclaimer-modal.html',
    styleUrls: ['./invoice-disclaimer-modal.sass'],
})
export class InvoiceDisclaimerModal implements IUniModal {
    options: IModalOptions = {};
    onClose = new EventEmitter();

    termsAccepted: boolean = false;
    saving: boolean = false;

    constructor(
        private errorService: ErrorService,
        private companySettingsService: CompanySettingsService,
    ) {}

    save() {
        this.saving = true;
        this.companySettingsService.PutAction(null, 'preset-customerInvoiceNumber').subscribe(
            () => {
                this.onClose.emit(true);
            },
            (err) => {
                this.errorService.handle(err);
                this.saving = false;
            },
        );
    }
}
