import { take } from 'rxjs/operators';
import { Component, EventEmitter, SimpleChange, ViewChild } from '@angular/core';
import {
    IUniModal,
    IModalOptions,
    UniModalService,
    UniEmailModal,
    UniPhoneModal,
    UniAddressModal,
} from '@uni-framework/uni-modal';
import { Customer, Supplier, Address, StatusCode, NumberSeries } from '@uni-entities';
import { BehaviorSubject } from 'rxjs';
import { AutocompleteOptions } from '@uni-framework/ui/autocomplete/autocomplete';
import { FieldType, UniForm } from '@uni-framework/ui/uniform';
import { FeaturePermissionService } from '@app/featurePermissionService';
import { Router } from '@angular/router';
import { SupplierService } from '@app/services/accounting/supplierService';
import { BrRegService } from '@app/services/common/brRegService';
import { ErrorService } from '@app/services/common/errorService';
import { NumberSeriesService } from '@app/services/common/numberSeriesService';
import { AddressService } from '@app/services/sales/addressService';
import { CustomerService } from '@app/services/sales/customerService';

@Component({
    selector: 'customer-and-supplier-edit-modal',
    templateUrl: './customer-and-supplier-edit-modal.html',
    styleUrls: ['./customer-and-supplier-edit-modal.sass'],
})
export class CustomerAndSupplierEditModal implements IUniModal {
    @ViewChild(UniForm, { static: true }) form: UniForm;

    options: IModalOptions = {};
    onClose = new EventEmitter();

    busy: boolean = true;
    entity: 'customer' | 'supplier';
    header: string = '';

    isNewEntity: boolean;
    showSaveAsLead: boolean;

    model$ = new BehaviorSubject<Customer | Supplier>(null);
    fields$ = new BehaviorSubject([]);

    numberSeries: NumberSeries[];

    isDirty: boolean;
    externalLookupOptions: AutocompleteOptions;

    initSearchValue: string;
    entityID: number;

    service: CustomerService | SupplierService;

    constructor(
        private modalService: UniModalService,
        private errorService: ErrorService,
        private customerService: CustomerService,
        private supplierService: SupplierService,
        private addressService: AddressService,
        private brRegService: BrRegService,
        private permissionService: FeaturePermissionService,
        private numberSeriesService: NumberSeriesService,
        private router: Router,
    ) {}

    ngOnInit() {
        const entity = this.options.data?.model;
        this.entity = this.options.data?.entity;
        this.isNewEntity = !entity;
        this.header = this.getHeader();

        this.service = this.entity === 'customer' ? this.customerService : this.supplierService;

        if (entity) {
            entity._copyToShippingAddress = false;
            this.model$.next(entity);
            this.initNumberSeries();
        } else {
            this.initNewEntity();
        }

        if (this.options.data?.search) {
            this.initSearchValue = this.options.data?.search;
        }
    }

    ngOnDestroy() {
        this.model$.complete();
        this.fields$.complete();
    }

    initNewEntity() {
        this.showSaveAsLead =
            this.permissionService.canShowUiFeature('ui.sales.customer.lead') && this.entity === 'customer';
        this.externalLookupOptions = this.brRegService.getLookupConfig();

        this.customerService.GetNewEntity(['Info.Phones', 'Info.Addresses', 'Info.Emails']).subscribe(
            (res) => {
                res._copyToShippingAddress = false;
                this.model$.next(res);
                this.initNumberSeries();
            },
            (err) => {
                this.errorService.handle(err);
                this.busy = false;
            },
        );
    }

    initNumberSeries() {
        const namedNumberSeries =
            this.entity === 'customer' ? 'Customer Account number series' : 'Supplier Account number series';
        const numberSeriesSearch = this.entity === 'customer' ? 'Customer number series' : 'Supplier number series';

        this.numberSeriesService.getNamedNumberSeries(namedNumberSeries).subscribe(
            (response) => {
                this.numberSeries = this.numberSeriesService.setNumberSeriesDisplayName(response);
                const numberSerie = this.numberSeries.find((x) => x.Name === numberSeriesSearch);
                if (numberSerie) {
                    this.numberSeriesChange(numberSerie);
                }

                this.fields$.next(this.getFormFields());
                this.busy = false;
            },
            (err) => {
                this.errorService.handle(err);
                this.busy = false;
            },
        );
    }

    numberSeriesChange(selectedSerie) {
        const entity = this.model$.getValue();
        entity.SubAccountNumberSeriesID = selectedSerie.ID;
        this.model$.next(entity);
    }

    onExternalSearchSelected(item) {
        if (!item) {
            return;
        }

        const model = this.model$.value;

        const entity = this.brRegService.mapBrRegDataToEntity(item, model || {}, model['_copyToShippingAddress']);

        this.service.GetAll(`filter=contains(OrgNumber,'${entity.OrgNumber}')`).subscribe(
            (response: Customer[] | Supplier[]) => {
                this.entityID = response[0] && response[0].ID;
            },
            (err) => this.errorService.handle(err),
        );

        this.isDirty = true;
        this.model$.next(entity);

        setTimeout(() => {
            if (this.form) {
                this.form.focus();
            }
        });
    }

    goToEntity() {
        const url =
            this.entity === 'customer' ? `/sales/customer/${this.entityID}` : `/accounting/suppliers/${this.entityID}`;

        this.onClose.emit();
        this.router.navigateByUrl(url);
    }

    onChangeEvent(change: SimpleChange) {
        this.isDirty = true;
        const model = this.model$.value;

        if (change['OrgNumber'] && change['OrgNumber'].currentValue) {
            this.service.GetAll(`filter=contains(OrgNumber,'${change['OrgNumber'].currentValue}')`).subscribe(
                (response: Customer[] | Supplier[]) => {
                    this.entityID = response[0] && response[0].ID;
                },
                (err) => this.errorService.handle(err),
            );
        }

        if (change['_copyToShippingAddress']) {
            this.fields$.next(this.getFormFields());
        }
    }

    save(saveAsLead = false) {
        if (!this.isDirty) {
            this.onClose.emit();
        }

        const entity = this.model$.value;
        if (saveAsLead) {
            entity.StatusCode = StatusCode.Pending;
        }

        if (entity['_copyToShippingAddress']) {
            entity.Info.ShippingAddress = entity.Info.InvoiceAddress;
            entity.Info.ShippingAddressID = entity.Info.InvoiceAddressID;
        }

        this.busy = true;

        const saveRequest = !!entity.ID ? this.service.Put(entity.ID, entity) : this.service.Post(entity);

        saveRequest.subscribe(
            (res) => this.onClose.emit(res),
            (err) => {
                this.errorService.handle(err);
                this.busy = false;
            },
        );
    }

    getFormFields() {
        const model = this.model$.getValue();
        return [
            {
                Property: 'Info.Name',
                Label: 'Navn',
                Classes: 'half-width',
            },
            {
                Property: 'OrgNumber',
                Label: 'Organisasjonsnummer',
                Classes: 'half-width',
            },
            {
                Property: 'Info.InvoiceAddress',
                FieldType: FieldType.MULTIVALUE,
                Label: 'Fakturaadresse',
                Options: {
                    listProperty: 'Info.Addresses',
                    displayValue: 'AddressLine1',
                    linkProperty: 'ID',
                    storeResultInProperty: 'Info.InvoiceAddress',
                    storeIdInProperty: 'Info.InvoiceAddressID',
                    editor: (value) => {
                        const modal = this.modalService.open(UniAddressModal, {
                            data: value || {},
                            header: 'Fakturaadresse',
                        });

                        return modal.onClose.pipe(take(1)).toPromise();
                    },
                    display: (address: Address) => {
                        return this.addressService.displayAddress(address);
                    },
                },
            },
            {
                Property: '_copyToShippingAddress',
                Label: 'Bruk fakturaadresse som leveringsadresse',
                FieldType: FieldType.CHECKBOX,
            },
            {
                Property: 'Info.ShippingAddress',
                FieldType: FieldType.MULTIVALUE,
                Label: 'Leveringsadresse',
                Hidden: model['_copyToShippingAddress'],
                Options: {
                    listProperty: 'Info.Addresses',
                    displayValue: 'AddressLine1',
                    linkProperty: 'ID',
                    storeResultInProperty: 'Info.ShippingAddress',
                    storeIdInProperty: 'Info.ShippingAddressID',
                    editor: (value) => {
                        const modal = this.modalService.open(UniAddressModal, {
                            data: value || {},
                            header: 'Levereringsadresse',
                        });

                        return modal.onClose.pipe(take(1)).toPromise();
                    },
                    display: (address: Address) => {
                        return this.addressService.displayAddress(address);
                    },
                },
                Tooltip: {
                    Text: 'Leveringsadresse vil dukke opp som eget adressefelt på blanketten. Dette er valgfritt å benytte.',
                },
            },
            {
                Property: 'Info.DefaultEmail',
                FieldType: FieldType.MULTIVALUE,
                Label: 'E-postadresse',
                Classes: 'half-width',
                Options: {
                    listProperty: 'Info.Emails',
                    displayValue: 'EmailAddress',
                    linkProperty: 'ID',
                    storeResultInProperty: 'Info.DefaultEmail',
                    storeIdInProperty: 'Info.DefaultEmailID',
                    editor: (value) => {
                        const modal = this.modalService.open(UniEmailModal, { data: value || {} });
                        return modal.onClose.pipe(take(1)).toPromise();
                    },
                },
            },
            {
                Property: 'Info.DefaultPhone',
                FieldType: FieldType.MULTIVALUE,
                Label: 'Telefonnummer',
                Classes: 'half-width',
                Options: {
                    listProperty: 'Info.Phones',
                    displayValue: 'Number',
                    linkProperty: 'ID',
                    storeResultInProperty: 'Info.DefaultPhone',
                    storeIdInProperty: 'Info.DefaultPhoneID',
                    editor: (value) => {
                        const modal = this.modalService.open(UniPhoneModal, { data: value || {} });
                        return modal.onClose.pipe(take(1)).toPromise();
                    },
                },
            },
            {
                Property: 'SubAccountNumberSeriesID',
                FieldType: FieldType.DROPDOWN,
                Label: 'Nummerserie',
                Hidden: this.numberSeries.length <= 1 || !this.isNewEntity,
                Options: {
                    source: this.numberSeries,
                    valueProperty: 'ID',
                    displayProperty: 'DisplayName',
                    debounceTime: 200,
                },
            },
        ];
    }

    getHeader(): string {
        if (this.entity === 'customer') {
            return this.isNewEntity ? 'Ny kunde' : 'Rediger kunde';
        } else {
            return this.isNewEntity ? 'Ny leverandør' : 'Rediger leverandør';
        }
    }
}
