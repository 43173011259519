<section class="uni-modal">
    <header>
        {{ headerText }}
    </header>
    <article [attr.aria-busy]="busy">
        <label>
            <section *ngIf="!isDisabled">
                <section class="alert warn" *ngIf="!isSuccess && approvalStatus.Message">
                    <i class="material-icons">info</i>
                    <span *ngIf="approvalStatus.Message === 'verify'"
                        >Betalingsbunt er opprettet og sendt til bank, men ikke godkjent grunnet BankID ikke er
                        verifisert. Du kan eventuelt tildele deg selv og prøve igjen eller valg en annet
                        godkjenner</span
                    >
                    <span *ngIf="approvalStatus.Message === '2fa'"
                        >Betalingsbunt er opprettet og sendt til bank, men ikke godkjent grunnet to-faktor har utløpt.
                        Du kan eventuelt tildele deg selv og prøve igjen eller valg en annet godkjenner</span
                    >
                    <span *ngIf="approvalStatus.Message !== 'verify' && approvalStatus.Message !== '2fa'"
                        >Betalingsbunt er opprettet og sendt til bank, men ikke godkjent grunnet:
                        {{ approvalStatus.Message }}</span
                    >
                </section>
                <section class="alert info" *ngIf="isSuccess">
                    <i class="material-icons">info</i>
                    <span
                        >Betalingen(e) er opprettet, men krever flere godkjenninger før den kan sendes til banken</span
                    >
                </section>

                <section class="alert warn" *ngIf="!users?.length && !busy">
                    <span
                        >Det ser ikke ut til at du har en bruker som kan godkjenne, opprett en ny bruker eller tildel
                        rettigheter til en av de eksisterende brukerne.
                        <a (click)="gotoUserSettings()">Til brukerinnstillinger</a></span
                    >
                </section>

                <section *ngIf="users?.length">
                    <span
                        >Velg hvem som skal godkjenne
                        <i
                            class="material-icons"
                            matTooltip="Dersom du velger flere personer vil betalingen gjennomføres etter at den første har godkjent. Grunnen til å velge mer enn en godkjenner kan for eksempel være at den som vanligvis godkjenner er på ferie."
                            >info</i
                        ></span
                    >
                    <ng-select
                        style="margin-top: 0.5rem"
                        [(ngModel)]="userIDs"
                        [items]="users"
                        [multiple]="true"
                        [searchFn]="userSearchFn"
                        appendTo="body"
                        bindValue="ID"
                    >
                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                            <div class="ng-value" *ngFor="let item of items | slice: 0 : 3">
                                <span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
                                <span class="ng-value-label">{{
                                    item.DisplayName || item.Email || item.UserName
                                }}</span>
                            </div>

                            <div class="ng-value" *ngIf="items.length > 3">
                                <span class="ng-value-label">og {{ items.length - 3 }} til</span>
                            </div>
                        </ng-template>

                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            {{ item.DisplayName || item.Email || item.UserName }}
                        </ng-template>
                    </ng-select>
                    <span *ngIf="userIDs?.length < approvalsRemaining"
                        ><i
                            >* Velg minst {{ approvalsRemaining }}
                            {{ approvalsRemaining === 1 ? 'godkjenner' : 'godkjennere' }}</i
                        ></span
                    >
                </section>

                <label *ngIf="users?.length && !busy">
                    <span>Kommentar</span>
                    <textarea [(ngModel)]="message"></textarea>
                </label>
            </section>

            <section *ngIf="isDisabled && !busy">
                <section class="alert warn">
                    <i class="material-icons">info</i>
                    <span
                        >Betalinger for ditt foretak krever godkjenning av flere personer. Denne funksjonen er ikke
                        tilgjengelig i ditt abonnement. Vennligst oppgrader <a (click)="gotoMarketplace()">her</a></span
                    >
                </section>
            </section>
        </label>
    </article>
    <footer>
        <button class="secondary" (click)="onClose.emit(false)">Avbryt</button>
        <button class="c2a" (click)="assign()" [disabled]="busy || userIDs?.length < approvalsRemaining || isDisabled">
            Tildel
        </button>
    </footer>
</section>
