<section *ngIf="chatReady" class="chat-toggle" (click)="showChat()" role="button" aria-label="Support chat">
    <svg
        *ngIf="isUniEconomy"
        style="width: 19px; margin-bottom: 6px"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="m15.602 6.337 1.427-2.579a.588.588 0 0 0 .053-.429.563.563 0 0 0-.252-.343.521.521 0 0 0-.41-.056.545.545 0 0 0-.329.263l-1.443 2.622a9.271 9.271 0 0 0-4.049-.883 9.283 9.283 0 0 0-4.03.975L5.066 3.191a.546.546 0 0 0-.33-.264.522.522 0 0 0-.41.056.563.563 0 0 0-.254.344.588.588 0 0 0 .054.43l1.49 2.7c-2.052 1.349-3.378 3.428-3.378 5.5 0 3.499 3.787 6.335 8.46 6.335 4.673 0 8.463-2.836 8.463-6.335 0-2.132-1.404-4.27-3.559-5.62ZM10.543 17.02c-3.786 0-6.87-2.072-6.87-4.61 0-2.54 1.286-5.112 6.87-5.112 6.16 0 6.87 2.565 6.87 5.112 0 2.546-3.076 4.61-6.87 4.61Z"
            fill="#fff"
        />
        <path
            d="M7.505 12.457c.481 0 .87-.526.87-1.175 0-.65-.389-1.176-.87-1.176s-.87.527-.87 1.176c0 .649.39 1.175.87 1.175ZM14.008 12.457c.481 0 .871-.526.871-1.175 0-.65-.39-1.176-.87-1.176-.482 0-.871.527-.871 1.176 0 .649.39 1.175.87 1.175ZM10.797 15.686c-1.035 0-1.877-.729-1.877-1.623a.29.29 0 0 1 .08-.2.265.265 0 0 1 .19-.083c.073 0 .141.03.192.083a.29.29 0 0 1 .08.2c0 .584.598 1.058 1.335 1.058.738 0 1.345-.473 1.345-1.056a.29.29 0 0 1 .08-.2.265.265 0 0 1 .382 0 .29.29 0 0 1 .08.2c0 .894-.85 1.621-1.887 1.621Z"
            fill="#fff"
        />
    </svg>
    <svg
        *ngIf="!isUniEconomy"
        style="width: 16px; margin-bottom: 8px"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.144.563h7.712c2.453 0 4.44 1.969 4.44 4.398v4.004c0 2.43-1.987 4.398-4.44 4.398h-1.35l-4.537 3.795c-.238.197-.504.28-.759.28-.573 0-1.086-.419-1.086-.887v-3.188a4.402 4.402 0 0 1-4.42-4.398V4.961c0-2.43 1.988-4.399 4.44-4.399Zm0 1.699c-1.503 0-2.726 1.21-2.726 2.7v4.003a2.705 2.705 0 0 0 2.705 2.7H6.84v3.379l3.559-2.978.48-.402h1.978c1.503 0 2.725-1.21 2.725-2.699V4.961c0-1.488-1.222-2.699-2.725-2.699H5.144Z"
            fill="#fff"
        />
    </svg>

    <span>Chat</span>
</section>
