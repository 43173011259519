import { IModalOptions, IUniModal, UniModalService } from '@uni-framework/uni-modal';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CompanyAccountingSettings, Product, SupplierGoods } from '@uni-entities';
import { SelectProductForGoodsModal } from '../selectProductForGoodsModal/selectProductForGoodsModal';
import { Subject, timer } from 'rxjs';
import { StatisticsService } from '@app/services/common/statisticsService';

@Component({
    selector: 'Change-Supplier-Goods-Modal',
    templateUrl: './changeSupplierGoodsModal.html',
    styleUrls: ['./changeSupplierGoodsModal.sass'],
})
export class ChangeSupplierGoodsModal implements IUniModal {
    @Input() options: IModalOptions;
    @Output() onClose: EventEmitter<any> = new EventEmitter<any>(true);

    goods: SupplierGoods[] = [];
    newGoodsExistingProduct: SupplierGoods[] = [];
    newGoodsNewProduct: SupplierGoods[] = [];

    highlightedGood: Subject<SupplierGoods> = new Subject<SupplierGoods>();
    hasErrors: boolean = false;

    usedPartNames: string[];

    showHelpBanner: boolean = false;
    expandHelpBanner: boolean = false;

    useStandardProduct: boolean = false;
    companyAccountingSettings: CompanyAccountingSettings;
    standardProduct: Product;

    constructor(
        private modalService: UniModalService,
        private statisticsService: StatisticsService,
    ) {}

    ngOnInit(): void {
        this.goods = this.options.data.goods.filter((x: SupplierGoods) => x.ID > 0);
        this.newGoodsExistingProduct = this.options.data.goods.filter((x: SupplierGoods) => x.ID === 0 && x.ProductID);
        this.newGoodsNewProduct = this.options.data.goods.filter((x: SupplierGoods) => x.ID === 0 && !x.ProductID);

        this.newGoodsNewProduct.forEach((good) => {
            good['_newGoodNoProduct'] = true;
            good['_productPartName'] = good.GoodsNumber;
            good['_productName'] = good.GoodsName;
        });

        this.newGoodsExistingProduct.forEach((good) => {
            good['_newGoodWithProduct'] = true;
            good['_product'] = good.Product;
            good['_productPartName'] = '';
            good['_productName'] = good.GoodsName;
        });

        this.companyAccountingSettings = this.options.data.companyAccountingSettings;

        this.standardProduct = this.companyAccountingSettings?.ReInvoicingTurnoverProduct;

        this.showHelpBanner = this.newGoodsNewProduct.length > 0 || this.newGoodsExistingProduct.length > 0;

        this.statisticsService.GetAll('model=Product&select=PartName').subscribe((partNames) => {
            this.usedPartNames = partNames.Data.map((x) => x.ProductPartName.toLowerCase());
            if (this.companyAccountingSettings.UseStandardProduct) {
                this.setUseStandardProduct();
            }
        });
    }

    accept() {
        this.newGoodsNewProduct.forEach((good) => {
            const product = new Product();

            product.PartName = good?.Product?.PartName ?? good['_productPartName'];
            product.Name = good?.Product?.Name ?? good['_productName'];

            good.Product = product;
        });

        const goods = this.allGoods();
        this.onClose.emit(goods);
    }

    close() {
        this.onClose.emit(null);
    }

    selectStandardProduct() {
        this.modalService
            .open(SelectProductForGoodsModal, {
                data: {
                    product: this.standardProduct,
                },
            })
            .onClose.subscribe((account) => {
                if (account) {
                    this.standardProduct = account;

                    if (this.useStandardProduct) {
                        this.setStandardProductOnAll();
                    }
                }
            });
    }

    allGoods() {
        return this.goods.concat(this.newGoodsNewProduct).concat(this.newGoodsExistingProduct);
    }

    onGoodsChanged(changedGoods) {
        const good = this.allGoods().find((x) => x.GoodsNumber === changedGoods.GoodsNumber);

        good.Product = changedGoods?.Product;
        good.ProductID = changedGoods?.Product?.ID;

        good['_productPartName'] = changedGoods['_productPartName'];
        good['_productName'] = changedGoods['_productName'];

        this.validateGoods();
    }

    validateGoods() {
        const goods = this.allGoods();
        goods.forEach((good) => {
            good['_hasError'] = false;
            if (!good.Product && (!good.ProductID || good.ProductID === 0)) {
                good['_invalidPartName'] = false;
                if (this.partNameUsed(good['_productPartName'])) {
                    good['_invalidPartName'] = true;
                    good['_hasError'] = true;
                }

                good['_missingName'] = false;
                if (good['_productName'] === '') {
                    good['_missingName'] = true;
                    good['_hasError'] = true;
                }
            }
        });
        this.hasErrors = goods.filter((x) => x['_hasError']).length > 0;
    }

    partNameUsed(partName) {
        return this.usedPartNames.find((x) => x === (partName || '').toLowerCase());
    }

    setUseStandardProduct() {
        this.useStandardProduct = !this.useStandardProduct;

        if (this.useStandardProduct) {
            this.setStandardProductOnAll();
        } else {
            this.removeStandardProductOnAll();
        }
    }

    pushToExistingProduct(good) {
        // Moves a new good to list with selection of exisitng products to bind with
        good['_productName'] = '';
        good['_productPartName'] = '';

        this.newGoodsExistingProduct.unshift(good);
        this.newGoodsNewProduct = this.newGoodsNewProduct.filter((x) => x.GoodsNumber !== good.GoodsNumber);

        this.newGoodsExistingProduct.forEach((x) => {
            if (!x.ProductID || !x.Product) {
                x.Product = x['_product'] ?? this.standardProduct;
                x.ProductID = x['_product']?.ID ?? this.standardProduct.ID;
            }
        });

        this.validateGoods();
        timer(100).subscribe(() => this.highlightedGood.next(good));
    }

    pushToNoProduct(good: SupplierGoods) {
        // Moves a new good to list with product creation option
        good['_productName'] = good.GoodsName;
        good['_productPartName'] = this.partNameUsed(good.GoodsNumber) ? '' : good.GoodsNumber;

        this.newGoodsNewProduct.push(good);
        this.newGoodsExistingProduct = this.newGoodsExistingProduct.filter((x) => x.GoodsNumber !== good.GoodsNumber);

        this.newGoodsNewProduct.forEach((x) => {
            if (x.ProductID || x.Product) {
                x.Product = null;
                x.ProductID = 0;
            }
        });

        this.validateGoods();
        timer(100).subscribe(() => this.highlightedGood.next(good));
    }

    private setStandardProductOnAll() {
        // Sets one product on all goods, and make backup if setAll is unselected
        this.goods.forEach((good) => (good['_product'] = good.Product));

        this.goods = this.allGoods();

        this.newGoodsExistingProduct = [];
        this.newGoodsNewProduct = [];

        this.goods.forEach((good) => {
            good.ProductID = this.standardProduct.ID;
            good.Product = this.standardProduct;
        });

        this.validateGoods();
    }

    private removeStandardProductOnAll() {
        // Will just reset to state before selected standard product for all
        this.goods.forEach((x) => {
            x.ProductID = 0;
            x.Product = null;
        });

        this.newGoodsNewProduct = this.goods.filter((good) => good['_newGoodNoProduct']);
        this.newGoodsExistingProduct = this.goods.filter((good) => good['_newGoodWithProduct']);

        this.goods = this.goods.filter((good) => !good['_newGoodNoProduct'] && !good['_newGoodWithProduct']);

        this.goods.forEach((good) => {
            good.Product = good['_product'];
            good.ProductID = good['_product']?.ID;
        });

        this.newGoodsExistingProduct.forEach((good) => {
            good.Product = good['_product'];
            good.ProductID = good['_product']?.ID;
        });

        this.newGoodsNewProduct.forEach((good) => {
            good['_productName'] = good.GoodsName;
            good['_productPartName'] = this.partNameUsed(good.GoodsNumber) ? '' : good.GoodsNumber;
        });

        this.validateGoods();
    }
}
