<section class="header">
    <h1>Inn- og utbetalinger</h1>
</section>

<section *ngIf="!loading" class="content">
    <widget-empty-state *ngIf="!hasData">
        Det ser ikke ut som det er registrert noen betalinger de siste 12 månedene.
    </widget-empty-state>

    <ng-container *ngIf="hasData">
        <widget-chart [chartConfig]="chartConfig"></widget-chart>

        <!-- Legend -->
        <section class="chart-legend horizontal">
            <section class="chart-legend-item">
                <span class="indicator" [style.background]="colors[0]"></span>
                <span class="label">Innbetalt: {{ sumIncoming | uninumberformat: 'money' }}</span>
            </section>

            <section class="chart-legend-item">
                <span class="indicator bordered" [style.background]="colors[1]"></span>
                <span class="label">Utbetalt: {{ sumOutgoing | uninumberformat: 'money' }}</span>
            </section>
        </section>

        <!-- Tooltip (visible on bar hover) -->
        <section class="chart-tooltip" *ngIf="tooltip" [ngStyle]="tooltip.style">
            <span>{{ tooltip.label }}</span>

            <section class="data-row">
                <span>Totalt innbetalt</span>
                <span>{{ tooltip.incoming.Sum | uninumberformat: 'money' }}</span>
            </section>

            <section class="data-row">
                <span>Antall innbetalinger</span>
                <span>{{ tooltip.incoming.Count || 0 }}</span>
            </section>

            <section class="data-row">
                <span>Totalt utbetalt</span>
                <span>{{ tooltip.outgoing.Sum | uninumberformat: 'money' }}</span>
            </section>

            <section class="data-row">
                <span>Antall utbetalinger</span>
                <span>{{ tooltip.outgoing.Count || 0 }}</span>
            </section>
        </section>
    </ng-container>
</section>
