<simple-table class="step-price-table">
    <thead>
        <tr>
            <th>{{ amountHeader }}</th>
            <th>{{ priceHeader }}</th>
        </tr>
    </thead>

    <tbody>
        <tr *ngFor="let step of product.ProductPrices[0].Steps">
            <td>{{ step['_amount'] }}</td>
            <td>{{ step.Price }} kr</td>
        </tr>
    </tbody>
</simple-table>
