import { Component, Input } from '@angular/core';
import { UniModalService } from '@uni-framework/uni-modal';
import { StartupTaskService, StartupTask } from '../startup-task-service';
import { AltinnSettingsModal } from '../../modals/altinn-settings-modal/connect-to-altinn-modal';

@Component({
    selector: 'connect-to-altinn-task',
    template: `<startup-task [task]="task" (tileClicked)="runTask()"> </startup-task>`,
})
export class ConnectToAltinnTask {
    @Input()
    public task: StartupTask;

    constructor(
        private service: StartupTaskService,
        private modalService: UniModalService,
    ) {}

    runTask() {
        // this.service.markTaskAsCompleted(this.task);
        this.modalService.open(AltinnSettingsModal).onClose.subscribe({
            next: (response) => {
                if (response) {
                    this.service.markTaskAsCompleted(this.task);
                }
            },
        });
    }
}
