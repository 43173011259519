import { Component } from '@angular/core';
import { SurveyService } from './survey.service';

@Component({
    selector: 'uni-survey',
    templateUrl: './survey.component.html',
    styleUrls: ['./survey.component.sass'],
})
export class UniSurvey {
    showSurveyIcon: boolean = false;
    showSurveyContainer: boolean = false;
    steps = 1;

    constructor(private surveyService: SurveyService) {
        this.surveyService.shouldShowSurvey().subscribe(
            (response) => {
                this.showSurveyIcon = response && window.innerHeight > 500;
                if (this.showSurveyIcon) {
                    setTimeout(() => {
                        if (window.innerHeight < 725) {
                            const elem = document.getElementById('survey_component');
                            elem.style.top = '5rem';
                        }
                    });
                }
            },
            (err) => {
                this.showSurveyIcon = false;
            },
        );
    }

    hideShowSurvey(show: boolean) {
        this.showSurveyContainer = show;
    }

    closeSurvey(showIcon: boolean = true) {
        this.showSurveyContainer = false;
        this.showSurveyIcon = showIcon;
    }
}
