<section class="header">
    <h1>Ubetalte regninger</h1>
</section>

<section *ngIf="!loading" class="content flex-row">
    <widget-empty-state *ngIf="!hasData"> Det ser ikke ut som du har noen ubetalte regninger. </widget-empty-state>

    <widget-chart *ngIf="hasData" [chartConfig]="chartConfig"></widget-chart>
</section>

<section *ngIf="!loading && hasData" class="footer border-top">
    <section class="footer-sum-item">
        <span class="value">{{ notOverdue | uninumberformat: 'money' }}</span>
        <span class="label">
            <span class="indicator" [style.background]="colors[0]"></span>
            Ikke forfalt
        </span>
    </section>

    <section class="footer-sum-item">
        <span class="value">{{ overdue | uninumberformat: 'money' }}</span>
        <span class="label">
            <span class="indicator" [style.background]="colors[1]"></span>
            Forfalt
        </span>
    </section>
</section>
