import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/authService';
import { ElsaPurchase, ElsaPurchaseStatus } from '@app/models';
import { BankAgreementServiceProvider } from '@app/models/autobank-models';
import { PaymentBatchService } from '@app/services/accounting/paymentBatchService';
import { ElsaPurchaseService } from '@app/services/elsa/elsaPurchasesService';
import { BankIntegrationAgreement, StatusCodeBankIntegrationAgreement } from '@uni-entities';
import { UniAutobankAgreementModal, UniModalService } from '@uni-framework/uni-modal';
import { theme, THEMES } from 'src/themes/theme';

@Component({
    selector: 'bank-balance',
    templateUrl: './bank-balance.html',
    styleUrls: ['./bank-balance.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BankBalanceWidget {
    onboardingState: 'none' | 'complete' | 'pending';

    constructor(
        private cdr: ChangeDetectorRef,
        private elsaPurchasesService: ElsaPurchaseService,
        private paymentBatchService: PaymentBatchService,
        private modalService: UniModalService,
        private authService: AuthService,
        private router: Router,
    ) {}

    ngOnInit() {
        if (this.authService.activeCompany.IsTest) {
            this.onboardingState = 'complete';
            this.cdr.markForCheck();
        } else {
            this.getData();
        }
    }

    getData() {
        this.paymentBatchService.checkAutoBankAgreement().subscribe((agreements: BankIntegrationAgreement[]) => {
            const activeAgreements = agreements?.filter(
                (x) => x.StatusCode === StatusCodeBankIntegrationAgreement.Active,
            );
            const pendingAgreement = agreements?.filter(
                (x) => x.StatusCode === StatusCodeBankIntegrationAgreement.Pending,
            );
            if (activeAgreements?.length) {
                this.elsaPurchasesService.getPurchaseByProductName('Autobank').subscribe((autobankPurchase) => {
                    activeAgreements.forEach((ag) => {
                        if (
                            (ag.IsBankBalance && ag.ServiceProvider === BankAgreementServiceProvider.External) ||
                            (autobankPurchase && ag.ServiceProvider === BankAgreementServiceProvider.ZdataV3)
                        ) {
                            this.onboardingState = 'complete';
                            this.cdr.markForCheck();
                        }
                    });
                });
            } else if (pendingAgreement?.length) {
                this.onboardingState = 'pending';
                this.cdr.markForCheck();
            } else {
                this.onboardingState = 'none';
                this.cdr.markForCheck();
            }
        });
    }

    connectBank() {
        if (theme.theme === THEMES.SR) {
            this.router.navigateByUrl('/bank/ticker');
        } else if (this.onboardingState === 'none') {
            this.router.navigateByUrl('/marketplace/modules');
        } else {
            this.modalService
                .open(UniAutobankAgreementModal, {
                    data: { agreements: [] },
                    closeOnClickOutside: false,
                })
                .onClose.subscribe((res) => {
                    if (res) {
                        this.getData();
                    }
                });
        }
    }
}
