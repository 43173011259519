<section *ngIf="comments" class="chat-box" [class.minimized]="minimized">
    <section class="header" (click)="toggleMinimized()">
        <span class="title" (click)="!minimized && navigateToBusinessObject($event)">
            {{ businessObject.EntityType | entitytypeTranslation }} (ID {{ businessObject.EntityID }})
        </span>

        <span class="unread-indicator" *ngIf="minimized && unreadCount > 0">{{ unreadCount }}</span>
        <i
            *ngIf="!minimized || !unreadCount"
            class="material-icons close-button"
            role="button"
            (click)="closeChatBox($event)"
            >close</i
        >
    </section>

    <section class="messages" *ngIf="!minimized" #chatContainer>
        <section class="scroll-container">
            <section
                *ngFor="let comment of comments"
                class="message-row"
                [class.from-current-user]="comment?.Author?.GlobalIdentity === authService.currentUser?.GlobalIdentity"
            >
                <uni-avatar
                    class="avatar"
                    [matTooltip]="comment?.Author?.DisplayName"
                    [name]="comment?.Author?.DisplayName"
                ></uni-avatar>

                <section class="message">
                    <section class="message-text" [innerHTML]="comment?.Text"></section>
                    <small>{{ comment?.CreatedAt | date: 'd. MMM HH:mm' : '' : 'nb' }}</small>
                </section>
            </section>
        </section>
    </section>

    <section *ngIf="!minimized" class="footer">
        <section
            #list
            class="mention-dropdown-list dropdown-menu"
            role="listbox"
            tabindex="-1"
            [attr.aria-expanded]="filteredUsers?.length > 0"
        >
            <section
                class="dropdown-menu-item"
                *ngFor="let user of filteredUsers; let idx = index"
                (mouseover)="focusIndex = idx"
                (click)="userSelected()"
                [attr.aria-selected]="focusIndex === idx"
            >
                {{ user?.DisplayName }}
            </section>
        </section>
        <textarea
            #inputElement
            type="text"
            class="message-input"
            placeholder="Skriv en melding..."
            [formControl]="inputControl"
            (keydown)="onKeyDown($event)"
        ></textarea>
    </section>
</section>
