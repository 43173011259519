import { Component, Input, Output, EventEmitter, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Notification, NotificationStatus, NotificationType } from '@uni-entities';
import { rigDate } from '@app/components/common/utils/rig-date';
import { EntitytypeTranslationPipe } from '@uni-framework/pipes/entitytype-translation.pipe';
import { NotificationService } from '../notification-service';
import { ToastService, ToastType } from '@uni-framework/uniToast/toastService';

@Component({
    selector: 'notification-item',
    templateUrl: './notification-item.html',
    styleUrls: ['./notification-item.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationItem implements OnInit {
    @Input() notification: Notification;
    @Output() notificationChange = new EventEmitter<Notification>();
    notificationType: 'approval' | 'inbox' | 'mention' | 'reminder' | 'approved' | 'rejected';
    notificationText: string;
    icon: string;
    timestamp: string;
    toggled: boolean;

    constructor(
        private entitytypeTranslationPipe: EntitytypeTranslationPipe,
        private notificationService: NotificationService,
        private toastService: ToastService,
    ) {}

    ngOnInit(): void {
        if (this.notification?.Title && this.notification?.Title !== ' ') {
            this.notification.Message = this.notification.Message.replace(this.notification.Title, '');
        }

        this.toggled = this.notification && this.notification?.StatusCode !== NotificationStatus.Read;
    }

    ngOnChanges() {
        if (this.notification) {
            this.notification.Message = this.notification.Message.replace(/&#197;/g, 'Å')
                .replace(/&#229;/g, 'å')
                .replace(/&#198;/g, 'Æ')
                .replace(/&#230;/g, 'æ')
                .replace(/&#216;/g, 'Ø')
                .replace(/&#248;/g, 'ø');

            // Setting it first lets the old handler overwrite it incase it triggers
            if (this.notification?.Title && this.notification?.Title !== ' ') {
                // First \n is used as a seperator between the title and the message
                this.notificationText = this.notification.Message.replace(this.notification.Title, '').trim();
            } else {
                this.notificationText = this.notification.Message;
            }

            const createdAt = rigDate(this.notification.CreatedAt);
            if (createdAt.isValid()) {
                this.timestamp = createdAt.fromNow();
            }

            switch (Number(this.notification.NotificationType)) {
                case NotificationType.ExternalOfferApproved:
                case NotificationType.PaymentBatchApproved:
                case NotificationType.PayrollApproved:
                case NotificationType.SupplierInvoiceApproved:
                    this.notificationType = 'approved';
                    this.icon = 'thumbs_up';
                    break;
                case NotificationType.ExternalOfferRejected:
                case NotificationType.PaymentBatchRejected:
                case NotificationType.PayrollRejected:
                case NotificationType.SupplierInvoiceRejected:
                    this.notificationType = 'rejected';
                    this.icon = 'thumbs_down';
                    break;
                case NotificationType.ExternalOfferForApproval:
                case NotificationType.PaymentBatchForApproval:
                case NotificationType.PayrollForApproval:
                case NotificationType.SupplierInvoiceForApproval:
                    this.notificationType = 'approval';
                    this.icon = 'thumbs_up';
                    break;
                case NotificationType.PaymentDue:
                case NotificationType.SupplierInvoiceDue:
                    this.notificationType = 'reminder';
                    this.icon = 'bell_filled';
                    break;
                case NotificationType.PaymentBatchFailed:
                case NotificationType.SharingFailed:
                    // Unsure about the icon
                    this.notificationType = 'rejected';
                    this.icon = 'warn_circle';
                    break;
                default:
                    this.oldNotificationHandler();
                    break;
            }
        }
        this.toggled = this.notification && this.notification?.StatusCode !== NotificationStatus.Read;

        // Fallbacks
        if (!this.notificationType) {
            this.notificationType = 'reminder';
        }

        if (!this.icon) {
            this.icon = 'bell_filled';
        }

        // // Use text as title if there's no title
        // if (!this.notification.Title) {
        //     this.notification.Title = this.notificationText
        //     this.notificationText = null;
        // }
    }

    markAsRead() {
        this.notificationService.markAsRead(this.notification.ID).subscribe({
            next: (notification) => {
                if (notification) {
                    this.notification = notification;
                    this.toggled = this.notification && this.notification?.StatusCode !== NotificationStatus.Read;
                    this.notificationChange.emit(this.notification);
                }
            },
            error: (error) =>
                this.toastService.addToast('Klarte ikke markere notifikasjonen som lest', ToastType.warn, 4, error),
        });
    }

    markAsUnread() {
        this.notificationService.markAsUnread(this.notification.ID).subscribe({
            next: (notification) => {
                if (notification) {
                    this.notification = notification;
                    this.toggled = this.notification && this.notification?.StatusCode !== NotificationStatus.Read;
                    this.notificationChange.emit(this.notification);
                }
            },
            error: (error) =>
                this.toastService.addToast('Klarte ikke markere notifikasjonen som ulest', ToastType.warn, 4, error),
        });
    }

    handleChecked() {
        if (this.notification.StatusCode === NotificationStatus.Read) {
            this.markAsUnread();
        } else {
            this.markAsRead();
        }
    }

    private oldNotificationHandler() {
        console.warn(
            `Notification type ${this.notification.NotificationType} with source ${this.notification.SourceEntityType} is not supported`,
        );
        const sourceEntityType = this.notification.SourceEntityType;
        const sourceEntityID = this.notification.SourceEntityID;
        const entityType = this.notification.EntityType;
        const entityID = this.notification.EntityID;

        if (sourceEntityType === 'Comment') {
            this.notificationType = 'mention';
            this.notificationText = `Du har blitt nevnt i en kommentar på ${this.entitytypeTranslationPipe.transform(entityType)} med ID ${entityID}`;
            this.icon = 'comment';
        }

        if (sourceEntityType === 'File') {
            this.notificationType = 'inbox';
            this.icon = 'incoming_mail';

            if (this.notification['_count'] > 1) {
                this.notificationText = `${this.notification['_count']} nye dokumenter i innboks`;
            } else {
                this.notificationText = `Nytt dokument i innboks`;
            }
        }

        if (entityType === 'Approval') {
            /*
                JØRGEN: 18.02.2022

                Small hack to show rejected and approved entity as rejected, not as an assigned task..
                Hack is needed since entitytype = Approval and only tell that it is rejected or approved entity
                is the start of message string. Notifications need body on the type..
            */
            if (this.notification.Message.startsWith('Rejected')) {
                if (sourceEntityType === 'WorkItemGroup') {
                    this.notificationText = `Innsendte timer ble avvist av ${this.notification.SenderDisplayName || 'godkjenner'}.`;
                } else {
                    let entityName = this.entitytypeTranslationPipe.transform(sourceEntityType);
                    entityName = entityName
                        ? entityName.charAt(0).toUpperCase() + entityName.substr(1, entityName.length)
                        : 'Entitet';

                    this.notificationText = `${entityName} med ID ${sourceEntityID} ble avvist av ${this.notification.SenderDisplayName || 'godkjenner'}.`;
                }

                this.notificationType = 'reminder';
                this.icon = 'thumbs_down';
                // APPROVED
            } else if (this.notification.Message.startsWith('Approved')) {
                if (sourceEntityType === 'WorkItemGroup') {
                    this.notificationText = `Innsendte timer ble godkjent av ${this.notification.SenderDisplayName || 'godkjenner'}.`;
                } else {
                    let entityName = this.entitytypeTranslationPipe.transform(sourceEntityType);
                    entityName = entityName
                        ? entityName.charAt(0).toUpperCase() + entityName.substr(1, entityName.length)
                        : 'Entitet';

                    this.notificationText = `${entityName} med ID ${sourceEntityID} ble godkjent av ${this.notification.SenderDisplayName || 'godkjenner'}.`;
                }

                this.notificationType = 'approved';
                this.icon = 'thumbs_up';
            } else {
                this.notificationType = 'approval';
                this.notificationText = `Du har blitt bedt om å godkjenne ${this.entitytypeTranslationPipe.transform(
                    sourceEntityType,
                )} med ID ${sourceEntityID}`;
                this.icon = 'thumbs_up';
            }
        } else if (entityType === 'CustomerInvoiceReminder') {
            this.notificationType = 'reminder';
            this.icon = 'bell_filled';
            this.notificationText = this.notification.Message;
            if (!this.notification.Message) {
                this.notificationText = 'Faktura er klar til inkasso';
            }
        } else if (
            entityType === 'ApiKey' ||
            entityType === 'LicensePurchase' ||
            entityType === 'BatchInvoice' ||
            entityType === 'BankIntegrationAgreement'
        ) {
            this.notificationType = 'inbox';
            this.icon = 'bot';
        }

        // All Contract varsler (iallefall i dev) er feilmeldinger, som ikke er relevante for brukeren.
        if (!this.notificationText && this.notification.Message && this.notification.EntityType !== 'Contract') {
            this.notificationText = this.notification.Message;
        }
    }
}
