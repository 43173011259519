import { ImportDefinition } from '@app/components/common/import/customImportService';

const list: ImportDefinition[] = [
    {
        title: 'Eiendeler',
        iconName: 'house',
        importCategory: 'Accounting',
        filename: 'EiendelsMal',
        entityName: 'asset',
        endpoint: '/api/biz/assets',
        fields: [
            { name: 'Navn', src: 'Name', label: 'Navn', isUnique: true, isRequired: true },
            {
                name: 'Kjopsdato',
                src: 'PurchaseDate',
                label: 'Kjøpsdato',
                dataType: 'date',
                isRequired: true,
                note: ['Dataformat: vanlig dato (excel)'],
            },
            { name: 'Kjopsbelop', src: 'PurchaseAmount', label: 'Kjøpspris', dataType: 'money', isRequired: true },
            {
                name: 'Balansekonto',
                src: 'BalanceAccount.Accountnumber',
                label: 'Balansekonto',
                dataType: 'int',
                isRequired: true,
                lookup: { label: 'Hovedbok', model: 'Account', property: 'AccountNumber' },
            },
            {
                name: 'InngaendeRegnskapsmessigVerdi',
                src: 'NetFinancialValue',
                label: 'Inngående regnskapsmessig verdi',
                dataType: 'money',
                isRequired: true,
                note: ['Inngående regnskapsmessig verdi'],
            },
            {
                name: 'AvskrivesFra',
                src: 'DepreciationStartDate',
                label: 'Avskrives fra dato',
                dataType: 'date',
                isRequired: true,
                note: ['Dataformat: vanlig dato (excel)'],
            },
            {
                name: 'Levetid',
                src: 'Lifetime',
                label: 'Levetid',
                dataType: 'int',
                isRequired: true,
                note: [`Levetid oppgis i antall måneder.`],
            },
            {
                name: 'Saldogruppe',
                src: 'AssetGroupCode',
                label: 'Saldogruppe',
                isRequired: true,
                note: [
                    'A - Kontormaskiner o.l.',
                    'B - Ervervet forretningsverdi',
                    'C1 - Vogntog, lastebiler, busser, varebiler mv.',
                    'C2 - Varebil elektrisk kraft',
                    'D - Personbiler, maskiner og inventar mv.',
                    'E - Skip, rigger mv.',
                    'F - Fly, helikopter',
                    'G - Anlegg for overføring og distribusjon av el. kraft og elektroteknisk utrustning i kraftforetak',
                    'H1 - Bygg og anlegg, hoteller mv.',
                    'H2 - Husdyrbygg i landbruket',
                    'H3 - Bygninger/anlegg hvor brukstiden må antas å ikke overstige 20 år',
                    'H4 - Kostnader til etablering av frukt- og bærfelt, som ikke har kunnet bli avskrevet gjennom levetiden',
                    'I - Forretningsbygg',
                    'J - Tekniske installasjoner i forretningsbygg og andre næringsbygg',
                    'X - Ikke avskrivbare eiendeler',
                ],
            },
            {
                name: 'Avkskrivningskonto',
                src: 'DepreciationAccount.Accountnumber',
                label: 'Avskrivningskonto',
                dataType: 'int',
                lookup: { label: 'Hovedbok', model: 'Account', property: 'AccountNumber' },
            },
            { name: 'Utrangeringsverdi', src: 'ScrapValue', label: 'Utrangeringsverdi', dataType: 'money' },
            {
                name: 'Prosjekt',
                src: 'Dimensions.Project.ProjectNumber',
                label: 'Prosjekt',
                lookup: { label: 'Prosjekt', model: 'Project', property: 'ProjectNumber' },
            },
            {
                name: 'Avdeling',
                src: 'Dimensions.Department.DepartmentNumber',
                label: 'Avdeling',
                lookup: { label: 'Avdeling', model: 'Department', property: 'DepartmentNumber' },
            },
        ],
    },
];

export const CustomImportTemplates = list;
