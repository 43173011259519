import { Component, EventEmitter } from '@angular/core';
import { IModalOptions, IUniModal } from '@uni-framework/uni-modal/interfaces';
import { ElsaProduct } from '@app/models';

@Component({
    selector: 'step-price-modal',
    templateUrl: './step-price-modal.html',
    styleUrls: ['./step-price-modal.sass'],
})
export class StepPriceModal implements IUniModal {
    options: IModalOptions = {};
    onClose = new EventEmitter();

    product: ElsaProduct;

    constructor() {}

    ngOnInit() {
        this.product = this.options.data;
    }
}
