import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@app/authService';
import { UserService } from '@app/services/common/userService';
import { User } from '@uni-entities';
import { Subscription } from 'rxjs';

@Component({
    selector: 'missing-products-banner',
    templateUrl: './missing-products-banner.html',
    styleUrls: ['./missing-products-banner.sass'],
})
export class MissingProductsBanner implements OnInit, OnDestroy {
    admins: User[];
    busy: boolean;
    authenticationSubscription: Subscription;

    constructor(
        private userService: UserService,
        private authService: AuthService,
    ) {}

    ngOnInit() {
        this.authenticationSubscription = this.authService.authentication$?.subscribe((authDetails) => {
            this.initAdminList();
        });
    }

    ngOnDestroy() {
        this.authenticationSubscription?.unsubscribe();
    }

    initAdminList() {
        this.busy = true;
        this.userService.getAdmins().subscribe({
            next: (admins) => {
                this.busy = false;
                this.admins = (admins || []).filter((admin) => !admin.Protected);
            },
            error: (err) => {
                console.error(err);
                this.busy = false;
            },
        });
    }
}
