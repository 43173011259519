<section role="dialog" class="uni-modal uni-redesign">
    <ng-container *ngIf="currentStep === steps.INIT">
        <header>Aktiver {{ product.Label }}</header>
        <article>
            <section *ngIf="busy" class="modal-spinner">
                <mat-spinner class="c2a"></mat-spinner>
            </section>
        </article>
    </ng-container>

    <ng-container *ngIf="currentStep === steps.ALREADY_CUSTOMER">
        <header>Aktiver {{ product.Label }}</header>

        <article>Er du allerede kunde i {{ product.Label }}?</article>

        <footer>
            <button class="secondary pull-left" (click)="close()">Avbryt</button>

            <button class="secondary" (click)="initActivationModel()">Nei</button>

            <button class="c2a" (click)="currentStep = steps.LOGIN">Ja</button>
        </footer>
    </ng-container>

    <ng-container *ngIf="currentStep === steps.LOGIN">
        <header>Aktiver {{ product.Label }}</header>

        <section *ngIf="busy" class="modal-spinner">
            <mat-spinner class="c2a"></mat-spinner>
        </section>

        <article>
            <span *ngIf="!orgNrAvailable"
                >Det kan se ut som dette selskapet allerede finnes i {{ product.Label }}.<br
            /></span>
            Vennligst logg inn med ditt {{ product.Label }} brukernavn og passord.

            <article style="padding-top: 2rem">
                <form #f="ngForm" [formGroup]="travelTextLoginForm" (ngSubmit)="travelTextLogin()">
                    <label class="uni-label label-left" uniAutoFocus>
                        <span>Brukernavn</span>
                        <input type="text" formControlName="Email" autocomplete="username" />
                    </label>

                    <label class="uni-label label-left">
                        <span>Passord</span>
                        <input type="password" formControlName="Password" autocomplete="new-password" />
                    </label>

                    <button
                        type="submit"
                        style="display: none"
                        [attr.aria-busy]="busy"
                        [disabled]="busy || !travelTextLoginForm.valid"
                    >
                        Logg inn
                    </button>
                </form>
            </article>
        </article>

        <footer>
            <button class="secondary pull-left" (click)="close()">Avbryt</button>

            <button
                class="c2a"
                type="submit"
                (click)="travelTextLogin()"
                [disabled]="!travelTextLoginForm.valid || busy"
            >
                Logg inn
            </button>
        </footer>
    </ng-container>

    <ng-container *ngIf="currentStep === steps.CHOOSE_COMPANY">
        <header>Aktiver {{ product.Label }}</header>

        <article>Velg selskapet du vil aktivere</article>

        <section class="uni-selection-list">
            <section *ngIf="busy" class="modal-spinner">
                <mat-spinner class="c2a"></mat-spinner>
            </section>

            <label
                *ngFor="let company of travelTextCompanies"
                class="selection-list-item"
                (click)="selectedTravelTextCompany = company"
            >
                <mat-radio-button
                    (click)="$event.preventDefault()"
                    [checked]="selectedTravelTextCompany === company"
                ></mat-radio-button>
                {{ company.EntityName }}
            </label>
        </section>

        <footer>
            <button class="secondary pull-left" (click)="close()">Avbryt</button>

            <button class="c2a" (click)="purchaseInElsa(true, false)" [disabled]="!selectedTravelTextCompany || busy">
                Aktiver
            </button>
        </footer>
    </ng-container>

    <ng-container *ngIf="currentStep === steps.REGISTER_COMPANY">
        <header>Aktiver {{ product.Label }}</header>

        <article>
            Opplysningene under vil sendes til {{ product.Label }} for aktivering av programmet. Vennligst se over at
            opplysningene er riktige før du fortsetter. Eventuelle endringer gjøres under firmainnstillinger.
        </article>

        <article style="padding-top: 2rem">
            <label class="uni-label label-left">
                <span>Organisasjonsnummer</span>
                <input type="text" [(ngModel)]="model.OrgNumber" readonly />
            </label>

            <label class="uni-label label-left">
                <span>Firmanavn</span>
                <input type="text" [(ngModel)]="model.CompanyName" readonly />
            </label>

            <label class="uni-label label-left">
                <span>Kontakt e-post</span>
                <input type="text" [(ngModel)]="model.CompanyEmail" readonly />
            </label>

            <label class="uni-label label-left">
                <span>Adresse</span>
                <input type="text" [(ngModel)]="model.Address" readonly />
            </label>

            <label class="uni-label label-left">
                <span>Postnummer</span>
                <input type="text" [(ngModel)]="model.Zip" readonly />
            </label>

            <label class="uni-label label-left">
                <span>Poststed</span>
                <input type="text" [(ngModel)]="model.City" readonly />
            </label>

            <a (click)="openLinkInNewTab(eulaUrl)">Les betingelser</a>
        </article>

        <footer>
            <button class="secondary pull-left" (click)="close()">Avbryt</button>

            <button class="c2a" (click)="goToRegisterUser()">Neste</button>
        </footer>
    </ng-container>

    <ng-container *ngIf="currentStep === steps.REGISTER_USER && !busy">
        <header>Opprett administratorbruker i {{ product.Label }}</header>

        <article>
            Når du har fylt ut dette skjemaet vil du motta brukernavn og passord til
            {{ product.Label }} på epost.
            <!--  og kan selv invitere nye brukere inn til programmet. -->
        </article>

        <article style="padding-top: 2rem">
            <label class="uni-label label-left">
                <span>Navn*</span>
                <input type="text" [(ngModel)]="model.UserName" />
            </label>
            <label class="uni-label label-left">
                <span>Epost*</span>
                <input type="text" [(ngModel)]="model.UserEmail" />
            </label>
            <label class="uni-label label-left">
                <span>Mobilnummer*</span>
                <input type="text" [(ngModel)]="model.UserPhoneNumber" />
            </label>
            <p *ngIf="invalidInputErrorMsg?.length" class="invalid-input">{{ invalidInputErrorMsg }}</p>
        </article>

        <footer>
            <button class="secondary pull-left" (click)="close()">Avbryt</button>

            <button class="secondary" (click)="currentStep = steps.REGISTER_COMPANY">Tilbake</button>

            <button class="c2a" (click)="checkInputs()">Aktiver</button>
        </footer>
    </ng-container>

    <ng-container *ngIf="busy && currentStep === steps.REGISTER_USER">
        <header style="padding-bottom: 8rem">Oppretter selskap og bruker i {{ product.Label }}...</header>
        <section class="modal-spinner-no-opacity">
            <mat-spinner class="c2a"></mat-spinner>
        </section>
    </ng-container>

    <ng-container *ngIf="currentStep === steps.COMPANY_CREATED">
        <header>Velkommen som bruker av {{ product.Label }}</header>

        <article>
            {{ product.Label }} er nå aktivert, og du kan logge deg på web eller vår app. <br /><br />Dine godkjente
            reiser og utlegg vil automatisk bli overført til {{ appName }}. <br /><br />Logg inn på web:
            <a (click)="openLinkInNewTab(loginUrl)">
                {{ loginUrl }}
            </a>
            <br />Last ned vår {{ product.Label }} app:
            <a (click)="openLinkInNewTab(googlePlayUrl)">Google Play</a> eller
            <a (click)="openLinkInNewTab(appStoreUrl)">App Store</a>
        </article>

        <footer class="center">
            <button class="secondary" (click)="close()">Lukk</button>
        </footer>
    </ng-container>
</section>
