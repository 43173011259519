import { Component, EventEmitter, HostListener, Input, Output, SimpleChange } from '@angular/core';
import { AuthService } from '@app/authService';
import { PushMessage } from '@app/models';
import { SignalRService } from '@app/services/common/signal-r.service';
import { StatisticsService } from '@app/services/common/statisticsService';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'signalr-eventlistener',
    template: '<ng-container> </ng-container>',
})
export class SignlREventListenerComponent {
    @Input() id: any;
    @Input() entity: string;
    @Input() sensor: string;
    @Input() dependsOnAutomation: boolean;
    @Output() events = new EventEmitter<{ message: PushMessage; sensor?: any }>();

    private pushSubscription: Subscription;
    private listeningOnID: any;
    private listeningOnCompanyKey: string;
    private matchingEventPlan: { ID?: number; ModelFilter?: string };
    onDestroy$ = new Subject();

    constructor(
        private signalRService: SignalRService,
        private authService: AuthService,
        private statisticsService: StatisticsService,
    ) {}

    ngOnDestroy() {
        this.teardownSignalRListener();
        this.onDestroy$.next(undefined);
        this.onDestroy$.complete();
    }

    ngOnChanges(changes) {
        if (changes['id']) {
            var change = <SimpleChange>changes['id'];
            this.startListening(this.entity, change.currentValue);
        }
    }

    startListening(model: string, id: number) {
        if (this.listeningOnID === id) return;
        this.unregister();
        if (!id) return;

        if (this.dependsOnAutomation) {
            this.getMatchingEventPlans(model).subscribe((result) => {
                if (result?.length > 0) {
                    this.matchingEventPlan = result[0];
                    const planID = result[0]['ID'];
                    if (planID && planID > 0) {
                        this.createListener(id);
                        return;
                    }
                }
                this.matchingEventPlan = {};
            });
        } else {
            this.createListener(id);
        }
    }

    getMatchingEventPlans(model: string): Observable<any> {
        if (this.matchingEventPlan) {
            return of([this.matchingEventPlan]);
        }
        return this.statisticsService.GetAllUnwrapped(
            'model=eventplan&select=ID as ID,ModelFilter as ModelFilter' +
                `&filter=active eq 1 and modelfilter in ('*','${model}')` +
                '&top=1',
        );
    }

    createListener(id: number) {
        if (this.signalRService.hubConnection) {
            this.listeningOnID = id;
            this.listeningOnCompanyKey = this.authService.getCompanyKey();
            this.signalRService.register(<PushMessage>{
                entityType: this.entity,
                entityID: id,
                companyKey: this.listeningOnCompanyKey,
            });
        }

        if (this.pushSubscription) {
            this.pushSubscription?.unsubscribe();
        }

        this.pushSubscription = this.signalRService.pushMessage$
            .pipe(takeUntil(this.onDestroy$), debounceTime(500))
            .subscribe((message: PushMessage) => {
                if (!message) return;

                const isMatchingType = message.entityType?.toLowerCase() === this.entity?.toLowerCase();
                const isMatchingID = message.entityID === this.listeningOnID;

                if (isMatchingType && isMatchingID) {
                    // Fetch updated sensor value?
                    if (this.sensor) {
                        this.fetchSensorValue(this.sensor, this.entity, this.id).subscribe((data) => {
                            const update = data?.length ? data[0] : undefined;
                            message['update'] = update;
                            this.events.emit({ message: message, sensor: update });
                        });
                    } else {
                        this.events.emit({ message: message });
                    }
                }
            });
    }

    fetchSensorValue(sensor: string, model: string, id: number): Observable<any[]> {
        if (!sensor) return of([]);
        const query = this.buildSensorQuery(sensor, model, id);
        return this.statisticsService.GetAllUnwrapped(query);
    }

    buildSensorQuery(sensor: string, model: string, id: number) {
        // sensor examplevalue: "StatusCode,UpdatedAt,JournalEntry.UpdatedAt"
        const expand: string[] = [];
        const fields = sensor.split(',');
        const select: string[] = [];
        for (var field of fields) {
            let fieldName = field;
            if (fieldName.indexOf('.') > 0) {
                const parts = fieldName.split('.');
                if (expand.indexOf(parts[0]) < 0) expand.push(parts[0]);
            }
            select.push(`${fieldName} as ${fieldName}`);
        }
        return `model=${model}&filter=ID eq ${id}&select=${select.join(',')}&expand=${expand.join(',')}`;
    }

    teardownSignalRListener() {
        this.pushSubscription?.unsubscribe();
        this.unregister();
    }

    unregister() {
        if (this.signalRService.hubConnection && this.listeningOnID) {
            this.signalRService.unregister(<PushMessage>{
                entityType: this.entity,
                entityID: this.listeningOnID,
                companyKey: this.listeningOnCompanyKey,
            });
        }
        this.listeningOnID = 0;
    }
}
