import { Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { UniHttp } from '../../../framework/core/http/http';
import { AuthService } from '../../authService';
import { ErrorService } from './errorService';

@Injectable({ providedIn: 'root' })
export class ApiModelService {
    private models: ApiModel[];
    private modules: ModuleConfig[];

    constructor(
        private uniHttpService: UniHttp,
        private errorService: ErrorService,
        protected authService: AuthService,
        private http: HttpClient,
    ) {
        if (this.authService) {
            this.authService.authentication$.subscribe((change) => this.invalidateCache());
        }
    }

    public getModel(name: string): ApiModel {
        if (this.models) {
            return this.models.find((x) => x.Name === name);
        }

        return null;
    }

    public getModels(): Array<ApiModel> {
        this.models.forEach((x) => {
            x.Expanded = false;
            x.Selected = false;
        });
        return this.models;
    }

    public getModules(): ModuleConfig[] {
        this.modules.forEach((x) => {
            x.Expanded = false;
            x.ModelList.forEach((m) => {
                m.Expanded = false;
                m.Selected = false;
            });
        });

        return this.modules;
    }

    private invalidateCache() {
        this.models = null;
    }

    /**
     * @deprecated
     *
     * Marked as deprecated because it loads a gigantic json file, potentially putting a lot
     * of stress on the api.
     *
     * Please consider loading only the specific model you need, or finding a more effecient
     * way to load multiple models.
     *
     */
    public deprecated_loadModelCache(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (!this.models) {
                // get models and modelconfig from API and add it to the cache
                forkJoin(
                    this.uniHttpService
                        .usingMetadataDomain()
                        .asGET()
                        .withEndPoint('allmodels')
                        .send()
                        .pipe(map((response) => response.body)),
                    this.http.get<UniModuleAndModelSetup>('assets/modelconfig/modelconfig.json', { observe: 'body' }),
                ).subscribe(
                    (data) => {
                        const models: Array<any> = data[0];
                        const setup: UniModuleAndModelSetup = data[1];

                        const otherModule = <ModuleConfig>{};
                        otherModule.Name = 'Other';
                        otherModule.Translated = 'Annet';
                        otherModule.ModelList = [];

                        // set up models and module
                        models.forEach((model) => {
                            const modules = setup.Modules.filter(
                                (x) => x.Models && x.Models.indexOf(model.Name) !== -1,
                            );

                            if (modules.length > 0) {
                                modules.forEach((module) => {
                                    if (!module.ModelList) {
                                        module.ModelList = [];
                                    }

                                    module.ModelList.push(model);
                                });
                            } else {
                                otherModule.ModelList.push(model);
                            }
                        });

                        setup.Modules.push(otherModule);

                        this.models = models;
                        this.modules = setup.Modules;

                        resolve(true);
                    },
                    (err) => {
                        this.errorService.handle(err);
                        reject(err);
                    },
                );
            } else {
                resolve(true);
            }
        });
    }
}

export interface ApiModel {
    Name: string;
    Fields: any;
    Expanded?: boolean;
    Selected?: boolean;
}

export interface UniModuleAndModelSetup {
    Modules: Array<ModuleConfig>;
    Models: Array<ModelConfig>;
}

export interface ModuleConfig {
    Name: string;
    Translated: string;
    Models: Array<string>;
    ModelList: Array<any>;
    Expanded: boolean;
}

export interface ModelConfig {
    Name: string;
    Translated: string;
    Url: string;
}
