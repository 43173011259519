import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Contact } from '@uni-entities';
import { CellComponentEventHandlers, UniTableColumn } from '@uni-framework/ui/unitable';

@Component({
    selector: 'main-contact',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <section (click)="$event.stopPropagation()">
            <span *ngIf="isMainContact" class="uni-chip info">Hovedkontakt</span>
            <a *ngIf="canSetAsMain" (click)="mainContactChange(row)">Sett som hovedkontakt</a>
        </section>
    `,
})
export class MainContactCell {
    @Input() row: Contact;

    column: UniTableColumn;
    eventHandlers: CellComponentEventHandlers;
    isMainContact: boolean;
    canSetAsMain: boolean;

    ngOnInit() {
        this.eventHandlers = this.column?.cellComponentEventHandlers || {};
        this.isMainContact = typeof this.row['_maincontact'] === 'boolean' && this.row['_maincontact'];
        this.canSetAsMain = this.row.ID && (typeof this.row['_maincontact'] !== 'boolean' || !this.row['_maincontact']);
    }

    mainContactChange(row) {
        if (this.eventHandlers.onMainContactChange) {
            this.eventHandlers.onMainContactChange(row);
        }
    }
}
