import { first, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Notification, NotificationType, UserNotificationSettings } from '@uni-entities';
import { UniHttp } from '@uni-framework/core/http/http';
import { BizHttp } from '@uni-framework/core/http/BizHttp';
import { rigDate } from '@app/components/common/utils/rig-date';
import { BehaviorSubject, Observable } from 'rxjs';
import {
    accountingRouteMap,
    salaryRouteMap,
    salesRouteMap,
    timetrackingRouteMap,
    commonRouteMap,
    externalOfferRouteMap,
} from './entity-route-map';
import { ChatBoxService } from '../chat-box/chat-box.service';
import { AuthService } from '@app/authService';
import { Router } from '@angular/router';
import { ToastType, ToastService } from '@uni-framework/uniToast/toastService';
import { theme, THEMES } from 'src/themes/theme';
import { CompanyService } from '@app/services/common/companyService';
import { ExternalOfferService } from '@app/services/sales/externalOfferService';

@Injectable()
export class NotificationService extends BizHttp<Notification> {
    readTimestamp: Date;
    hasUnreadNotifications$ = new BehaviorSubject(false);

    constructor(
        uniHttp: UniHttp,
        private chatBoxService: ChatBoxService,
        private authService: AuthService,
        private router: Router,
        private companyService: CompanyService,
        private toastService: ToastService,
        private externalOfferService: ExternalOfferService,
    ) {
        super(uniHttp);
        this.relativeURL = 'notifications';
        this.DefaultOrderBy = 'CreatedAt desc';

        try {
            this.readTimestamp = JSON.parse(localStorage.getItem('notifications_read_timestamp'));
        } catch (e) {}

        if (!this.readTimestamp) {
            this.readTimestamp = new Date();
            localStorage.setItem('notifications_read_timestamp', JSON.stringify(this.readTimestamp));
        }
    }

    markNotificationsRead() {
        this.readTimestamp = new Date();
        localStorage.setItem('notifications_read_timestamp', JSON.stringify(this.readTimestamp));
    }

    getNotifications(filter?: string, skip: number = 0, skip_step = 50) {
        let query = `top=${skip_step}&skip=${skip}`;
        if (filter) {
            query += '&filter=' + filter;
        }

        return this.GetAll(query).pipe(
            map((notifications: Notification[]) => {
                notifications = notifications.map((notification) => this.setNotificationMetadata(notification));
                return this.groupInboxNotifications(notifications);
            }),
        );
    }

    getLatestNotification() {
        return this.GetAll('top=1').pipe(
            map((notifications) => {
                const notification = notifications && notifications[0];
                return notification && this.setNotificationMetadata(notification);
            }),
        );
    }

    markAllAsRead(currentCompanyOnly: boolean) {
        return this.Action(
            null,
            'mark-all-as-read',
            currentCompanyOnly ? `companyKey=${this.authService.getCompanyKey()}` : null,
        );
    }

    markAsRead(ID: number) {
        return this.Action(ID, 'mark-as-read');
    }

    markAsUnread(ID: number) {
        return this.Action(ID, 'mark-as-unread');
    }

    async onNotificationClick(notification: Notification) {
        const notificationRoute = await this.getNotificationRoute(notification);

        await this.markAsRead(notification.ID)
            .toPromise()
            .catch(() => {});

        // Check if we're already logged into the company or if we need to change
        if (notification.CompanyKey === this.authService.getCompanyKey()) {
            if (notification.SourceEntityType === 'Comment') {
                this.chatBoxService.addBusinessObject({
                    EntityID: notification.EntityID,
                    EntityType: notification.EntityType,
                    CompanyKey: notification.CompanyKey,
                });
            }

            this.router.navigateByUrl(notificationRoute);
        } else {
            this.companyService.GetAll().subscribe(
                (companies) => {
                    const company = companies.find(
                        (c) => c.Key?.toLowerCase() === notification.CompanyKey?.toLowerCase(),
                    );
                    if (company) {
                        if (notification.SourceEntityType === 'Comment') {
                            this.chatBoxService.addBusinessObject({
                                EntityType: notification.EntityType,
                                EntityID: notification.EntityID,
                                CompanyKey: notification.CompanyKey,
                            });
                        } else {
                            this.authService.setActiveCompany(company, notificationRoute);
                        }
                    } else {
                        this.toastService.addToast(
                            'Mistet tilgang',
                            ToastType.warn,
                            10,
                            `Det ser ut som du nå mangler tilgang til ${notification.CompanyName},
                            vennligst kontakt selskapets administrator.`,
                        );
                    }
                },
                (err) => console.error(err),
            );
        }
    }

    private async getNotificationRoute(notification: Notification) {
        const entityType = notification.EntityType.toLowerCase();
        const sourceEntityType = notification.SourceEntityType.toLowerCase();
        let route = '';

        /*
            JØRGEN: 18.02.2022

            Small hack to get correct route for rejected and approved entity
            Hack is needed since entitytype = Approval and only tell that it is rejected or approved entity
            is the start of message string. Notifications need body on the type..
        */
        if (
            notification.EntityType === 'Approval' &&
            notification.SourceEntityType === 'WorkItemGroup' &&
            (notification.Message.startsWith('Rejected') || notification.Message.startsWith('Approved'))
        ) {
            route = '/timetracking/timeentry?mode=Timeliste';
        } else if (
            notification.EntityType === 'Approval' &&
            notification.SourceEntityType === 'SupplierInvoice' &&
            (notification.Message.startsWith('Rejected') || notification.Message.startsWith('Approved'))
        ) {
            route = '/accounting/supplier-invoice/' + notification.SourceEntityID;
        } else if (accountingRouteMap[entityType]) {
            route = '/accounting/' + accountingRouteMap[entityType];
        } else if (salesRouteMap[entityType]) {
            route = '/sales/' + salesRouteMap[entityType];
        } else if (salaryRouteMap[entityType]) {
            route = '/salary/' + salaryRouteMap[entityType];
        } else if (timetrackingRouteMap[entityType]) {
            route = '/timetracking/' + timetrackingRouteMap[entityType];
        } else if (commonRouteMap[entityType]) {
            route = commonRouteMap[entityType];
        } else if (notification.EntityType === 'File' && notification.SenderDisplayName === 'Uni Micro AP') {
            if (theme.theme === THEMES.SR) {
                route = '/accounting/inbox';
            } else {
                route =
                    notification['_count'] === 1 ? '/accounting/bills/0?fileid=:id' : '/accounting/bills?filter=Inbox';
            }
        }

        if (sourceEntityType === 'externaloffer') {
            route = await this.getExternalOfferRoute(notification);
            if (route) return route;
        }

        if (!route) {
            route = '';
        }

        return route.replace(/:id/i, notification.EntityID.toString());
    }

    private setNotificationMetadata(notification: Notification): Notification {
        const createdAt = rigDate(notification.CreatedAt);
        if (createdAt.isValid()) {
            notification['_timestamp'] = createdAt.fromNow();

            if (createdAt.isAfter(rigDate(this.readTimestamp))) {
                notification['_unread'] = true;
            }
        }

        return notification;
    }

    private groupInboxNotifications(notifications: Notification[]): Notification[] {
        const grouped: Notification[] = [];

        notifications.forEach((notification) => {
            if (notification.SourceEntityType === 'File') {
                const previousNotificiation = grouped[grouped.length - 1];
                if (
                    previousNotificiation &&
                    previousNotificiation.SourceEntityType === 'File' &&
                    previousNotificiation.CompanyKey === notification.CompanyKey &&
                    previousNotificiation['_timestamp'] === notification['_timestamp']
                ) {
                    previousNotificiation['_count']++;
                } else {
                    notification['_count'] = 1;
                    grouped.push(notification);
                }
            } else {
                grouped.push(notification);
            }
        });

        return grouped;
    }

    public getExternalOfferRoute(notification: Notification): Promise<string> {
        return new Promise(async (resolve) => {
            let companyKey = this.authService.activeCompany?.Key;

            if (notification.CompanyKey !== companyKey) {
                const companies = await this.companyService.GetAll().pipe(first()).toPromise();
                const notificationCompany = companies.find((c) => c.Key === notification.CompanyKey);
                companyKey = notificationCompany?.Key;
            }

            this.externalOfferService
                .getExternalOfferById(notification.SourceEntityID, companyKey)
                .subscribe((offer) => {
                    if (offer) {
                        const route = externalOfferRouteMap[offer.EntityType.toLowerCase()];
                        resolve(route.replace(/:id/i, offer.EntityID.toString()));
                    }
                    resolve(null);
                });
        });
    }
}

export type ShortUserNotificationSetting = {
    IgnoreEmail: boolean;
    IgnoreNotification: boolean;
    ID?: number;
    NotificationType?: string | number | NotificationType;
    displayName?: string;
    busy?: boolean;
};
export type UserNotificationSettingsMap = { [NotificationsType: string]: ShortUserNotificationSetting };

@Injectable()
export class UserNotificationSettingsService extends BizHttp<UserNotificationSettings> {
    constructor(uniHttp: UniHttp) {
        super(uniHttp);
        this.relativeURL = 'usernotificationsettings';
    }

    getAll(withTemplate = true): Observable<UserNotificationSettingsMap> {
        return this.GetAction(null, 'get-settings', `excludeMissingTemplates=${withTemplate}`);
    }

    post(settings: UserNotificationSettings | ShortUserNotificationSetting): Observable<UserNotificationSettings> {
        return this.Post(settings);
    }

    put(
        id: number,
        settings: UserNotificationSettings | ShortUserNotificationSetting,
    ): Observable<UserNotificationSettings> {
        return this.Put(id, settings);
    }

    unblockAll(type?: string): Observable<UserNotificationSettingsMap> {
        return this.PutAction(null, 'unblock-all', type ? `notificationType=${type}` : undefined);
    }

    blockAll(type?: string): Observable<UserNotificationSettingsMap> {
        return this.PutAction(null, 'block-all', type ? `notificationType=${type}` : undefined);
    }
}
