import { Component, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Employee, Leavetype } from '@uni-entities';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import PerfectScrollbar from 'perfect-scrollbar';
import { StatisticsService } from '@app/services/common/statisticsService';

@Component({
    selector: 'employee-search-component',
    templateUrl: './employee-search-component.html',
    styleUrls: ['./employee-leave-modal.component.sass'],
})
export class EmployeeSearchComponent {
    @Input() leavetype: Leavetype;

    @Output() employeeSelect = new EventEmitter<Employee>();
    @Output() setBusy = new EventEmitter<boolean>();

    scrollbar: PerfectScrollbar;
    searchControl: UntypedFormControl = new UntypedFormControl('');
    searchSubscription: Subscription;
    employees: any[] = [];
    filteredEmployees: any[] = [];

    visibleNumber = 50;

    constructor(private statisticsService: StatisticsService) {}

    ngOnInit() {
        this.loadEmployees();

        this.searchSubscription = this.searchControl.valueChanges
            .pipe(debounceTime(200))
            .subscribe(() => this.filterEmployees());
    }

    filterEmployees() {
        this.filteredEmployees = this.employees
            .filter((employee) => {
                if (this.searchControl?.value) {
                    const searchText = this.searchControl.value.toLowerCase();
                    const displayName = employee.DisplayName?.toLowerCase() || '';
                    const employeeNumber = employee.EmployeeNumber.toString().toLowerCase();
                    return displayName.includes(searchText) || employeeNumber.includes(searchText);
                }

                return true;
            })
            .slice(0, this.visibleNumber);

        setTimeout(() => {
            if (this.scrollbar) {
                this.scrollbar.update();
            }
        });
    }

    ngAfterViewInit() {
        this.scrollbar = new PerfectScrollbar('#item-list');
    }

    onUserScrolledToBottom() {
        this.visibleNumber += 25;
        this.filterEmployees();
    }

    loadEmployees() {
        let filterString = `( ( isnull(EndDate,'') eq '' or EndDate gt getdate() ) and isnull(Employment.ID,0) gt 0 )`;
        let expandString = 'BusinessRelationInfo,Employment';

        if (this.leavetype === Leavetype.Sick_child) {
            expandString += ',Children';
            filterString += ` and isnull(Children.ID,0) gt 0`;
        }

        const queryString = `model=Employee&select=ID as ID,EndDate as EndDate,EmployeeNumber as EmployeeNumber,DeniedSickDays as DeniedSickDays,BusinessRelationInfo.Name as DisplayName&filter=${filterString}&expand=${expandString}`;
        this.statisticsService.GetAllUnwrapped(queryString).subscribe({
            next: (employees) => {
                this.employees = employees;
                this.filterEmployees();
                this.setBusy.emit(false);
            },
        });
    }
}
