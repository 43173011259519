<section role="dialog" class="uni-modal">
    <header>Import av fravær fra excel</header>

    <article>
        <section *ngIf="busy" class="modal-spinner">
            <mat-spinner class="c2a"></mat-spinner>
        </section>
        <section>
            Første gang anbefaler vi å laste ned malen ved å trykke på Last ned mal. Malen inneholder beskrivelse for
            hvordan den skal fylles ut.<br /><br />
            Når du har lagt inn fraværet i Excel malen kan du importere det ved å trykke på Importer fil.<br />
        </section>

        <label class="uni-label upload-label">
            <span>Filopplasting</span>

            <section class="file-container">
                <file-input
                    accept=".xlsx, .txt, .csv"
                    [upload]="false"
                    [multiple]="false"
                    (filesChange)="onFilesChange($event)"
                >
                </file-input>
            </section>
        </label>
        <section class="alert error" *ngIf="!isValidFileFormat">
            Vennligst legg til en gyldig fil (.xlsx) for å importere
        </section>
    </article>

    <footer>
        <button class="secondary pull-left" (click)="onClose.emit(null)">Avbryt</button>
        <a class="button secondary" href="{{ href }}" target="_self"> Last ned mal </a>
        <button class="c2a" (click)="importFile()">Importer fil</button>
    </footer>
</section>
