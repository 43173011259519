import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { ElsaContractType } from '@app/models';
import { filter, map, mergeMap, takeUntil } from 'rxjs/operators';
import { ElsaContractService } from '@app/services/elsa/elsaContractService';
import { BrowserStorageService } from '@uni-framework/core/browserStorageService';
import { Subject, of } from 'rxjs';
import { FinancialYearService } from '@app/services/accounting/financialYearService';
import { StatisticsService } from '@app/services/common/statisticsService';
import { UniModalService } from '@uni-framework/uni-modal';
import { JournalEntryCounterModal } from '@app/components/common/modals/journal-entry-counter-modal/journal-entry-counter-modal';
import { theme, THEMES } from 'src/themes/theme';

@Component({
    selector: 'journal-entry-counter',
    templateUrl: './journal-entry-counter.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JournalEntryCounter implements OnDestroy, OnChanges {
    @Input() contractType: number;
    @Input() contractID: number;

    isSrEnvironment = theme.theme === THEMES.SR;
    isDnbEnvironment = theme.theme === THEMES.EXT02;
    isUnimicro = theme.theme === THEMES.UE;

    contractTypes: ElsaContractType[] = [];
    journalEntriesCount: number;
    journalEntriesWarningLevel?: number;
    maxJournalEntries?: number;
    currentContractType?: string;
    journalEntriesTargetContractType?: string;
    counterText: string;
    onDestroy$ = new Subject();

    constructor(
        private elsaContractService: ElsaContractService,
        private browserStorage: BrowserStorageService,
        private financialYearService: FinancialYearService,
        private statisticsService: StatisticsService,
        private modalService: UniModalService,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnChanges(changes) {
        if (changes?.contractID) {
            this.handleJournalEntries(this.contractType);
        }
    }

    ngOnDestroy() {
        this.onDestroy$.next(undefined);
        this.onDestroy$.complete();
    }

    handleJournalEntries(contractTypeId: number) {
        this.elsaContractService
            .getContractTypes()
            .pipe(
                takeUntil(this.onDestroy$),
                map((contractTypes) => {
                    this.contractTypes = contractTypes;
                    const currentContractType = contractTypes.find(
                        (contractType) => contractType.ContractType === contractTypeId,
                    );
                    if (!currentContractType.MaxJournalEntries) {
                        // reset count to hide the alert, because everything below won't run if we're inside this if
                        this.journalEntriesCount = 0;
                        this.cdr.markForCheck();
                    }
                    return currentContractType;
                }),
                filter((contractType) => contractType.MaxJournalEntries > 0),
                mergeMap((contractType) => {
                    const activeYear = this.financialYearService.getActiveYear();
                    if (activeYear < 2022) return of(null);
                    return this.getJournalEntriesCount(activeYear).pipe(
                        map((journalEntriesCount) => ({ contractType, journalEntriesCount })),
                    );
                }),
            )
            .subscribe((res) => {
                if (!res) {
                    this.journalEntriesCount = 0;
                    this.cdr.markForCheck();
                    return;
                }
                this.journalEntriesCount = res.journalEntriesCount;
                this.maxJournalEntries = res.contractType.MaxJournalEntries;
                this.journalEntriesWarningLevel = Math.floor(res.contractType.MaxJournalEntries * 0.667);
                this.currentContractType = res.contractType.Label || res.contractType.Name;
                const dontShowAgain = this.browserStorage.getItemFromCompany('dontShowJournalEntriesCount');

                if (this.journalEntriesCount >= this.journalEntriesWarningLevel && !dontShowAgain) {
                    const targetContractType = this.contractTypes.find(
                        (contractType) =>
                            contractType.ContractType === res.contractType.TargetContractTypeOnMaxJournalEntries,
                    );
                    this.journalEntriesTargetContractType = targetContractType?.Label || targetContractType?.Name;
                    this.openJournalEntiresCountModal(false);
                }

                this.cdr.markForCheck();
            });
    }

    getJournalEntriesCount(activeYear: number) {
        const query =
            'model=JournalEntry' +
            '&select=count(id)' +
            `&filter=isnull(JournalEntryNumber,0) gt 0 and FinancialYear.Year eq ${activeYear}` +
            '&expand=FinancialYear';

        return this.statisticsService.GetAll(query).pipe(
            map((journalEntriesCount) => {
                return +journalEntriesCount.Data[0].countid;
            }),
        );
    }

    openJournalEntiresCountModal(openedFromNavbar: boolean) {
        this.modalService.open(JournalEntryCounterModal, {
            data: {
                journalEntriesCount: this.journalEntriesCount,
                maxJournalEntries: this.maxJournalEntries,
                currentContractType: this.currentContractType,
                targetContractType: this.journalEntriesTargetContractType,
                isSrEnvironment: this.isSrEnvironment,
                isDnbEnvironment: this.isDnbEnvironment,
                isUnimicro: this.isUnimicro,
                openedFromNavbar: openedFromNavbar,
            },
        });
    }
}
