import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { NavbarLinkService } from '../navbar/navbar-link-service';
import { INavbarLink, INavbarLinkSection } from '../navbar/navbar-links-common';
import { cloneDeep } from 'lodash-es';
import { TabService } from '../navbar/tabstrip/tabService';
import { SidebarStateService } from './sidebar-state';
import { Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'uni-sidebar',
    templateUrl: './sidebar.html',
    styleUrls: ['./sidebar.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniSidebar {
    linkSections: INavbarLinkSection[] = [];

    sidebarCollapsed$ = this.sidebarStateService.sidebarCollapsed$.asObservable();
    expandedSection$ = this.sidebarStateService.expandedSection$.asObservable();

    private onDestroy$ = new Subject();

    constructor(
        private breakpointObserver: BreakpointObserver,
        private navbarService: NavbarLinkService,
        private router: Router,
        public tabService: TabService,
        public sidebarStateService: SidebarStateService,
        private cdr: ChangeDetectorRef,
    ) {
        this.navbarService.linkSections$.pipe(takeUntil(this.onDestroy$)).subscribe((sections) => {
            this.linkSections = cloneDeep(sections).filter((section) => {
                section.linkGroups = section.linkGroups.filter((group) => {
                    group.links = group.links.filter((link) => {
                        return link.activeInSidebar;
                    });

                    if (group.links.length) {
                        return group;
                    }
                });

                if (section.isOnlyLinkSection || section.linkGroups.length) {
                    return section;
                }
            });

            this.setActiveSection();
            this.cdr.markForCheck();
        });

        this.router.events.pipe(takeUntil(this.onDestroy$)).subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.setActiveSection();
            }
        });
    }

    ngAfterViewInit() {
        setTimeout(() => this.initBreakpointObserver());
    }

    ngOnDestroy() {
        this.onDestroy$.next(undefined);
        this.onDestroy$.complete();
    }

    private initBreakpointObserver() {
        let hasSetInitialState = false;

        this.breakpointObserver
            .observe(['(max-width: 1010px)'])
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((state: BreakpointState) => {
                if (!hasSetInitialState) {
                    // On first emit we only want to collapse if necessary, not expand if there is enough space.
                    // This is done to avoid overriding user preference for collapsed sidebars on large screens.
                    if (state.matches) {
                        this.sidebarStateService.sidebarCollapsed$.next(true);
                    }

                    hasSetInitialState = true;
                    return;
                }

                this.sidebarStateService.sidebarCollapsed$.next(state.matches);
            });
    }

    private setActiveSection() {
        try {
            let section: INavbarLinkSection;
            if (this.router.url === '/') {
                section = this.linkSections[0];
            } else {
                const sectionsWithSameBase = this.linkSections.filter((section) => {
                    return this.router.url.split('/')[1] === section.url.replace('/', '');
                });

                if (sectionsWithSameBase.length > 1) {
                    const bestMatchingSection = this.getBestMatchingSection(sectionsWithSameBase);
                    if (bestMatchingSection) {
                        section = this.linkSections.find((sec) => sec.name === bestMatchingSection.name);
                    }
                } else if (sectionsWithSameBase.length === 1) {
                    section = this.linkSections.find((sec) => sec.name === sectionsWithSameBase[0].name);
                }
            }

            if (section && !section.isOnlyLinkSection) {
                this.sidebarStateService.activeRouteSection$.next(section);
            }
        } catch (e) {
            console.error(e);
        }
    }

    private getBestMatchingSection(sections: INavbarLinkSection[]) {
        const route = this.router.url.split('?')[0];

        const matchingCharacterCount = (url: string) => {
            if (!url) {
                return 0;
            }

            if (route.includes(url)) {
                return url.length;
            }

            let indexOfMistmatch = [...url].findIndex((char, index) => char !== route.charAt(index));
            return indexOfMistmatch;
        };

        let bestMatch: INavbarLinkSection;
        let bestMatchingScore = 0;

        sections.forEach((section) => {
            const links: INavbarLink[] = section.linkGroups.reduce((links, group) => [...links, ...group.links], []);
            links.forEach((link) => {
                const matchingScore = matchingCharacterCount(link.url);
                if (matchingScore > bestMatchingScore) {
                    bestMatch = section;
                    bestMatchingScore = matchingScore;
                }
            });
        });

        return bestMatch || sections[0];
    }

    showMegaMenu() {
        this.navbarService.megaMenuVisible$.next(true);
    }
}
