<section class="list-container">
    <section class="search-row">
        <input [formControl]="searchControl" type="search" placeholder="Søk etter bruker" />
    </section>

    <ul id="item-list" (scrolledToBottom)="onUserScrolledToBottom()">
        <li
            class="flex align-center"
            *ngFor="let employee of filteredEmployees; let i = index"
            (click)="employeeSelect.emit(employee)"
        >
            <div class="avatar">
                <uni-avatar [name]="employee.DisplayName || employee.Email"></uni-avatar>
                <span
                    *ngIf="employee['_statusClass']"
                    class="status-indicator"
                    [ngClass]="employee['_statusClass']"
                ></span>
            </div>

            <section class="flex-column ml-2">
                <strong>{{ employee.EmployeeNumber }} - {{ employee.DisplayName }}</strong>
            </section>
        </li>
    </ul>
    <section *ngIf="leavetype === 11" class="sick-child-info">
        Finner du ikke ansatte? Listen viser bare ansatte som har registrert barn. Barn kan registreres på ansattkortet.
    </section>
</section>
