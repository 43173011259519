import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CustomImportService, ImportDefinition, ImportField, ImportLookup } from '../../customImportService';
import { loadExcelJS } from '@app/components/common/utils/excel/excel';
import { getNewGuid, parseDate, takeTop } from '@app/components/common/utils/utils';

export class ImportResponse {
    numSuccessFull: number;
    numSkipped: number;
    numInvalid: number;
    numErrors: number;
    numTotal: number;
    errorMessage?: string;
    canceled = false;

    constructor(
        numSuccess: number,
        numTotal: number,
        numErrors: number = 0,
        numSkipped: number = 0,
        msg?: string,
        invalid?: number,
    ) {
        this.numSuccessFull = numSuccess;
        this.numErrors = numErrors;
        this.numSkipped = numSkipped;
        this.numTotal = numTotal;
        if (invalid || invalid === 0) this.numInvalid = invalid;
        this.errorMessage = msg ?? '';
    }

    cancel(): ImportResponse {
        this.canceled = true;
        return this;
    }
}

export interface CustomImportOptions {
    skipExisting: boolean;
}

@Component({
    selector: 'custom-import-component',
    templateUrl: './custom-import-component.html',
    styleUrls: ['./custom-import-component.sass'],
})
export class CustomImportComponent {
    @Input() definition: ImportDefinition;
    @Output() completed = new EventEmitter<boolean>();

    barMode: 'indeterminate' | 'determinate' = 'indeterminate';
    percentComplete = 0;
    state: ImportResponse;
    busy = true;
    pleaseCancel = false;
    importCompletedSuccessfully = false;
    lastErrorMessage: string;
    logg = [];

    constructor(private customImportService: CustomImportService) {}

    Cancel() {
        if (this.busy) {
            this.pleaseCancel = true;
        } else {
            this.completed.emit(this.importCompletedSuccessfully);
        }
    }

    async importFile(file: File, def: ImportDefinition, options?: CustomImportOptions): Promise<ImportResponse> {
        this.state = undefined;
        this.busy = false;
        this.lastErrorMessage = '';
        this.logg = [];
        this.pleaseCancel = false;
        this.percentComplete = 0;
        this.barMode = 'indeterminate';

        this.addLog(`Import av fil: "${file.name}"`, false, true);

        if (!this.checkFileSize(file, 1000 * 1000)) {
            return;
        }

        this.busy = true;

        const result = await this.parseLocalFile(file, def);
        const parsed: Array<any> = result.data;
        let state: ImportResponse = new ImportResponse(0, 0, 0, 0);
        if (parsed?.length > 1) {
            state = await this.importParsedData(parsed, def, options);
            if (!this.lastErrorMessage) {
                this.state = state;
            }
        } else {
            state.errorMessage = result.msg
                ? result.msg
                : 'Fant ingen data å importere. Sjekk at kolonnetitlene stemmer med malen.';
            this.lastErrorMessage = state.errorMessage;
        }

        this.busy = false;
        return state;
    }

    addLog(msg: string, isError = false, highlight = false) {
        this.logg.push({ text: msg, isError: isError, highlight: highlight });
    }

    async importParsedData(
        parsed: any[],
        def: ImportDefinition,
        options?: CustomImportOptions,
    ): Promise<ImportResponse> {
        const state = new ImportResponse(0, parsed.length, 0, 0);

        this.addLog(`Filen inneholder ${parsed.length} linjer (inkl. overskrift)`);

        // Does the template have unique columns?
        const templateHasUniqueColumns = def.fields.findIndex((f) => f.isUnique) >= 0;

        // Find columns that match
        const columnMap = parsed[0].map((headerCell) =>
            headerCell ? def.fields.find((f) => f.name.toLowerCase() == headerCell?.toLowerCase()) : undefined,
        );

        const nCols = columnMap?.filter((f) => !!f)?.length ?? 0;
        // this.addLog(`Fant ${nCols} overskrifter som stemmer med mal`);

        if (nCols <= 0) {
            this.lastErrorMessage = 'Fant ingen data å importere. Sjekk at kolonnetitlene stemmer med malen.';
            return;
        }

        // Check for unique columns?
        if (templateHasUniqueColumns) {
            const nUniqueMatch = columnMap?.filter((f) => !!f?.isUnique)?.length ?? 0;
            if (nUniqueMatch <= 0) {
                this.addLog(`Fant ingen nøkkelkolonner (f.eks: ${def.fields.find((f) => f.isUnique)?.name})`, true);
                this.lastErrorMessage = 'Fant ingen nøkkelkolonner i filen. Sjekk at kolonnetitlene stemmer med malen';
                return;
            }
        }

        // Check required columns:
        var missingCols = [];
        for (let fld of def.fields) {
            if (fld.isRequired && columnMap.findIndex((f) => f && f.name.toLowerCase() == fld.name.toLowerCase()) < 0) {
                this.addLog(`Fant ikke ${fld.name} (${fld.label})`, true);
                missingCols.push(fld);
            }
        }
        if (missingCols.length > 0) {
            this.lastErrorMessage = `Det mangler ${missingCols.length} påkrevde kolonner i filen; ${missingCols.map((c) => c.name).join(', ')}`;
            return;
        }

        this.addLog(`Kontrollerer ${parsed.length - 1} rader med data`);

        var hasErrors = false;

        // Convert all rows to objects
        let nMissingRequiredFields = 0;
        let missingFields = new Map<string, ImportField>();
        const foreignKeys: Map<string, ForeignKey> = new Map();
        const data = [];
        for (var i = 1; i < parsed.length; i++) {
            const res = this.convertRowToEntity(parsed[i], columnMap, foreignKeys);
            if (res.entity) {
                data.push(res.entity);
            } else {
                if (!missingFields.has(res.missing.name)) {
                    missingFields.set(res.missing.name, res.missing);
                }
                nMissingRequiredFields++;
            }
        }

        if (nMissingRequiredFields > 0) {
            state.numInvalid = nMissingRequiredFields;
            this.addLog(`${nMissingRequiredFields} rader ble ignorert pga. manglende påkrevde felt`, true);
            const fldNames = [];
            missingFields.forEach((fld, key) => fldNames.push(`${fld.name} (${fld.label})`));
            this.addLog(`Følgende kolonner manglet verdier: ${fldNames.join(', ')}`, true);
        }

        if (this.pleaseCancel) return state.cancel();

        // Resolve all foreignkeys (lookupvalues)
        hasErrors = !(await this.fetchAllForeignKeys(foreignKeys));
        if (hasErrors) {
            this.lastErrorMessage = 'Filen kan ikke importeres. Sjekk loggen for detaljer';
            return state;
        }

        if (this.pleaseCancel) return state.cancel();

        const result = await this.importToApi(data, def, options);

        result.numInvalid = nMissingRequiredFields;

        return result;
    }

    async importToApi(data: any[], def: ImportDefinition, options?: CustomImportOptions): Promise<ImportResponse> {
        var state = new ImportResponse(0, data.length, 0, 0);

        const updateList = [];
        const uniqueColumn = def.fields.find((f) => !!f?.isUnique);
        if (uniqueColumn) {
            // Find existing data
            this.addLog(`Kontrollerer ${data.length} eksisterende ${def.title} på ${uniqueColumn.label}`);
            const hasDimensions = !!def.fields.find((f) => f.src.toLowerCase().indexOf('dimensions.') === 0);
            const xTraField = hasDimensions ? 'DimensionsID' : undefined;
            const existing = await this.queryForKeys(data, def.entityName, uniqueColumn.src, 20, undefined, xTraField);
            if (existing && existing.length > 0) {
                existing.forEach((x) => {
                    const ix = data.findIndex((d) => d[uniqueColumn.src] == x[uniqueColumn.src]);
                    if (ix >= 0) {
                        const rows = data.splice(ix, 1);
                        if (rows && rows.length > 0) {
                            // copy ID
                            const row = rows[0];
                            row['ID'] = x['ID'];
                            // Copy existing dimensions reference?
                            if (hasDimensions && x['DimensionsID'] && row['Dimensions']) {
                                row['Dimensions'].ID = x['DimensionsID'];
                            }
                            // todo: copy more values?
                            updateList.push(row);
                        }
                    }
                });
            }
        }

        if (this.pleaseCancel) return state.cancel();

        var nTotal = data.length + (options.skipExisting ? 0 : updateList.length);
        this.barMode = 'determinate';

        // Insert new ones?
        if (data?.length > 0) {
            for (let index = 0; index < data.length; index++) {
                this.percentComplete = ((index + 1) / nTotal) * 100;

                // POST new entry to API
                const result = await this.customImportService.postEntity(def.endpoint, data[index]).toPromise();

                if (result.success) {
                    this.addLog(
                        `Opprettet ${uniqueColumn ? data[index][uniqueColumn.src] : `ny med ID = ${result.result?.ID}`}`,
                    );
                    state.numSuccessFull++;
                } else {
                    this.addLog(`Feil: ${result.msg}`, true);
                    const name = data[index][uniqueColumn.src];
                    this.addLog(`Feil ved oppretting av: '${name}' : ${result.msg}`, true);
                    if (++state.numErrors > 10) break;
                }
                if (this.pleaseCancel) return state.cancel();
            }
        }

        // Update existing?
        if (updateList.length > 0 && !options.skipExisting) {
            for (let index = 0; index < updateList.length; index++) {
                const record = updateList[index];
                const id = record.ID;
                const route = def.endpoint + '/' + id;
                const name = record[uniqueColumn.src];

                this.percentComplete = ((data.length + (index + 1)) / nTotal) * 100;

                // PUT updated entry to API
                const result = await this.customImportService.putEntity(route, record).toPromise();

                if (result.success) {
                    this.addLog(`Oppdatert: ${name}`);
                    state.numSuccessFull++;
                } else {
                    this.addLog(`Feil ved oppdatering av: ${id} - '${name}' : ${result.msg}`, true);
                    if (++state.numErrors > 10) break;
                }
                if (this.pleaseCancel) return state.cancel();
            }
        } else if (updateList.length > 0) {
            state.numSkipped = updateList.length;
            this.addLog(`Hoppet over ${updateList.length} eksisterende ${def.title}`);
        }

        return state;
    }

    async chunkFunc(arr: any[], size: number, callBack: (chunk: any[]) => {}) {
        if (!arr || arr.length == 0) return;
        let chunk = arr.length > size ? [] : arr;
        if (arr.length > size) {
            for (let row of arr) {
                chunk.push(row);
                if (chunk.length >= size) {
                    await callBack(chunk);
                    chunk = [];
                }
            }
        }
        if (chunk.length > 0) await callBack(chunk);
    }

    async queryForKeys(
        keys: Array<any>,
        model: string,
        property: string,
        chunkSize = 50,
        rowProperty?: string,
        additionalProperty?: string,
    ): Promise<Array<any>> {
        const result = [];
        rowProperty ??= property;
        await this.chunkFunc(keys, chunkSize, async (chunk) => {
            let route = `?model=${model}&select=ID as ID,${property} as ${property}`;
            if (additionalProperty) route += `,${additionalProperty} as ${additionalProperty}`;
            let isAlphaNumeric: boolean = this.anyNonNumericKeys(chunk);
            const values = chunk.map((m) => (isAlphaNumeric ? `'${m[rowProperty]}'` : m.key.toFixed(0))).join(',');
            route += `&filter=${property} in (${values})`;
            const items = await this.customImportService.getStatisticsQuery(route + '&wrap=false').toPromise();
            if (items && items.length) result.push(...items);
        });
        return result;
    }

    async fetchAllForeignKeys(foreignKeys: Map<string, ForeignKey>): Promise<boolean> {
        let response = true;

        if (foreignKeys.size > 0) {
            for (var model of foreignKeys.keys()) {
                const noMatch = [];
                const fk = foreignKeys.get(model);
                this.addLog('Oppslag mot ' + fk.label);
                const result = await this.queryForKeys(fk.values, model, fk.field, 50, 'key');
                if (result && result.length > 0) {
                    for (var i = 0; i < fk.values.length; i++) {
                        const fkValue = fk.values[i];
                        let match = result.find((r) => r[fk.field] == fkValue.key);
                        if (match) {
                            for (var propertyName in fkValue.refs) {
                                const relatedData = fkValue.refs[propertyName];
                                relatedData.forEach((r) => (r[propertyName] = match.ID));
                            }
                        } else {
                            noMatch.push(fkValue.key);
                        }
                    }
                } else {
                    noMatch.push(...fk.values.map((v) => v.key));
                }
                if (noMatch.length > 0) {
                    this.addLog(
                        `Fant ikke følgende ${fk.label}: ${takeTop(noMatch, 50).join(', ') + (noMatch.length > 50 ? '...' : '')}`,
                        true,
                    );
                    response = false;
                }
            }
        }

        return response;
    }

    anyNonNumericKeys(values: Array<{ key: any }>): boolean {
        for (var i = 0; i < values.length; i++) if (!(Number(values[i].key) === values[i].key)) return true;
        return false;
    }

    convertRowToEntity(
        row: any[],
        columnMap: ImportField[],
        foreignKeys: Map<string, ForeignKey>,
    ): { entity: any; missing?: ImportField } {
        const entity: any = {};
        for (var colIndex = 0; colIndex < columnMap.length; colIndex++) {
            const fld = columnMap[colIndex];
            if (!fld) continue; // No mapping for this column

            let value = row[colIndex];

            // Handle formulas
            if (value && value.result) {
                value = value.result;
            } // No data ?
            else if (!value && value !== 0) {
                if (fld.isRequired) return { entity: undefined, missing: fld };
                else continue;
            } // Shared formulas (no values here..)
            else if (value.sharedFormula || value.formula) {
                if (fld.isRequired) return { entity: undefined, missing: fld };
                else continue;
            }

            // Map foreignkeys
            if (fld.lookup) {
                let fkModel: ForeignKey = foreignKeys.get(fld.lookup.model);
                if (!fkModel) {
                    // New model
                    fkModel = { field: fld.lookup.property, values: [], label: fld.lookup.label };
                    foreignKeys.set(fld.lookup.model, fkModel);
                }
                // Set the current value as foreignkey (temporary)
                // and add reference to it for later (when we fetch the real keys)
                const fk = this.setTemporaryForeignKey(entity, fld, value);
                const keyValues: { key: any; refs: any } = fkModel.values.find((v) => v.key == value);
                if (keyValues) {
                    let list = keyValues.refs[fk.field];
                    if (!list) {
                        keyValues.refs[fk.field] = [fk.target];
                    } else {
                        list.push(fk.target);
                    }
                } else {
                    const x = { key: value, refs: {} };
                    x.refs[fk.field] = [fk.target];
                    fkModel.values.push(x);
                }
            } else {
                if (fld.dataType === 'date') {
                    const dx = parseDate(<any>value);
                    if (dx) {
                        entity[fld.src] = dx.toLocaleDateString('en-ca');
                        continue;
                    }
                }
                entity[fld.src] = value;
            }
        }

        return { entity: entity };
    }

    setTemporaryForeignKey(entity: any, fld: ImportField, tempValue: any): { target: any; field: string } {
        // Autodetect foreignkey-details:
        // example: "Dimensions.Project.ProjectNumber" => { target: entity.Dimension, field: "ProjectID" }
        // example: "BalanceAccount.AccountNumber" => { target: entity, field: "BalanceAccountID" }

        let parts: string[];
        if (!fld.srcParts) {
            fld.srcParts = fld.src.split('.');
        }
        parts = fld.srcParts;
        let path = parts[0] + 'ID';
        if (parts.length == 1) {
            entity[fld.src] = tempValue;
        } else if (parts.length == 2) {
            entity[path] = tempValue;
        } else if (parts.length == 3) {
            const step1 = entity[parts[0]] ?? { _createguid: getNewGuid() };
            const fkField = parts[1] + 'ID';
            step1[fkField] = tempValue;
            entity[parts[0]] = step1;
            path = parts[0] + '.' + fkField;
            return { target: step1, field: fkField };
        }
        return { target: entity, field: path };
    }

    checkFileSize(file: File, maxFileSizeInBytes: number) {
        if (file.size > maxFileSizeInBytes) {
            const mb = (file.size / 1000000).toFixed(1);
            const maxInMb = (maxFileSizeInBytes / 1000000).toFixed(1);
            this.lastErrorMessage = `Filen på ${mb} mb er for stor! Maks filstørrelse er ${maxInMb} mb.`;
            return false;
        }
        return true;
    }

    async parseLocalFile(file: File, def: ImportDefinition): Promise<{ data: any[]; msg?: string }> {
        const excelJS = await loadExcelJS();
        let data = [];
        if (excelJS) {
            return await this.loadFirstExcelWorkbook(excelJS, file, def);
        }
        return { data: data };
    }

    parseError(err) {
        let msg = '';
        if (err?.message) {
            msg = <string>err.message;
        }
        if (msg.indexOf('is this a zip file')) {
            msg = 'Klarte ikke å tolke denne Excel filen. Kontroller at den har korrekt xslx format.';
        }
        this.lastErrorMessage = msg;
    }

    loadFirstExcelWorkbook(excelJS, file: File, def: ImportDefinition): Promise<{ data: any[]; msg?: string }> {
        return new Promise((resolve, reject) => {
            const wb = new excelJS.Workbook();
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onerror = (event) => {
                resolve({
                    data: [],
                    msg:
                        (event.target?.error?.toString() ?? 'Det oppstod en feil ved lesing av filen.') +
                        ' Tips: Prøv å legge til filen på nytt.',
                });
            };
            reader.onload = () => {
                const buffer = reader.result;
                wb.xlsx
                    .load(buffer)
                    .catch((err) => this.parseError(err))
                    .then((workbook) => {
                        const data = [];
                        if (!workbook) {
                            resolve({ data: data });
                            return;
                        }
                        workbook.eachSheet((sheet, id) => {
                            var columnHeadersFound = false;
                            sheet.eachRow((row, rowIndex) => {
                                // Look for first row that has more than one column
                                // and matches atleast 2 fields in the layout
                                if (!columnHeadersFound) {
                                    if (row?.cellCount >= 2) {
                                        var matches = row.values.reduce((sum, item) => {
                                            return sum + (def.fields.findIndex((i) => i.name == item) >= 0 ? 1 : 0);
                                        }, 0);
                                        columnHeadersFound = matches >= 2;
                                    }
                                    if (!columnHeadersFound) return;
                                }
                                data.push(row.values);
                            });
                            resolve({ data: data });
                            return;
                        });
                    });
            };
        });
    }
}

interface ForeignKey {
    field: string;
    label: string;
    values: any[];
}
