import { Component, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { AuthService, CurrentUserWithout2FADetails } from '@app/authService';
import { UniModalService } from '@uni-framework/uni-modal';
import { ElsaUserLicenseType } from '@app/models';
import { THEMES, theme } from 'src/themes/theme';
import { UserNotificationSettingsModal } from '../../notifications/user-notification-settings/user-notification-settings-modal';
import { ElsaContractService } from '@app/services/elsa/elsaContractService';

@Component({
    selector: 'navbar-user-dropdown',
    templateUrl: './user-dropdown.html',
    styleUrls: ['./user-dropdown.sass'],
})
export class NavbarUserDropdown {
    @ViewChild(MatMenuTrigger, { static: true }) trigger: MatMenuTrigger;

    user: CurrentUserWithout2FADetails;
    contractType = '';
    userLicenseType = '';
    isExt02 = theme.theme === THEMES.EXT02;

    constructor(
        private modalService: UniModalService,
        private authService: AuthService,
        private elsaContractService: ElsaContractService,
    ) {
        this.authService.authentication$.subscribe((auth) => {
            if (auth && auth.user) {
                const user = auth.user;

                if (user['License'] && user['License'].ContractType) {
                    if (user['License'].ContractType.TypeName) {
                        this.elsaContractService
                            .getContractTypesLabel(user['License'].ContractType.TypeName)
                            .subscribe((label) => (this.contractType = label));
                    }
                }

                if (user['License'] && user['License'].UserType) {
                    this.userLicenseType = this.getUserLicenseTypeName(user['License'].UserType.TypeID);
                }

                this.user = user;
            }
        });
    }

    public logOut() {
        this.authService.clearAuthAndGotoLogin();
    }

    public openUserNotificationSettings() {
        this.trigger?.closeMenu();
        this.modalService.open(UserNotificationSettingsModal);
    }

    getUserLicenseTypeName(userLicenseType: number) {
        switch (userLicenseType) {
            case ElsaUserLicenseType.Standard:
                return 'Standard';
            case ElsaUserLicenseType.Accountant:
                return 'Regnskapsfører';
            case ElsaUserLicenseType.Revision:
                return 'Revisor';
            case ElsaUserLicenseType.Training:
                return 'Skole/opplæring';
            case ElsaUserLicenseType.Support:
                return 'Support';
            case ElsaUserLicenseType.Internal:
                return 'Intern';
            case ElsaUserLicenseType.Server:
                return 'Server';
            default:
                return '';
        }
    }
}
