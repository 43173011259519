<section class="uni-card">
    <ng-container *ngIf="admins?.length; else nonAdminTemplate">
        <section class="uni-card-header borderless">
            <h1>Brukeren din mangler produkter</h1>
        </section>

        <section class="uni-card-content">
            <section class="alert warn">
                <i class="material-icons">warning</i>
                <span>Du har ingen aktive produkter på din bruker.</span>
            </section>

            <p>Vennligst kontakt en selskapsadministrator for å aktivere produkter for din bruker.</p>

            <mat-spinner *ngIf="busy" class="c2a" diameter="35"></mat-spinner>

            <simple-table class="borders custom-scrollbar" *ngIf="!busy">
                <thead>
                    <tr>
                        <th>Administrator</th>
                        <th>Epost</th>
                        <th>Telefon</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let admin of admins">
                        <td>{{ admin.DisplayName }}</td>
                        <td>
                            <a href="mailto:{{ admin.Email }}">{{ admin.Email }}</a>
                        </td>
                        <td>{{ admin.PhoneNumber }}</td>
                    </tr>
                </tbody>
            </simple-table>
        </section>
    </ng-container>
</section>

<ng-template #nonAdminTemplate>
    <section class="uni-card-header borderless">
        <h1>Brukeren din mangler tilgang</h1>
    </section>

    <section class="uni-card-content">
        <section class="alert warn">
            <i class="material-icons">warning</i>
            <span
                >Vi finner ingen selskapsadministrator som kan gi deg tilgang. <br />Vennligst kontakt support, så skal
                vi hjelpe deg videre.
            </span>
        </section>
    </section>
</ng-template>
