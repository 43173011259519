<article class="container" *ngIf="!isFirstTimeUser">
    <div class="header flex border-bottom">
        <h1>{{ 'DASHBOARD.ACTIVE_EMPLOYEES.HEADER' | translate }}</h1>
        <span class="employee-count ml-auto">{{ activeEmployees }}</span>
    </div>

    <div class="gender-counts flex align-center justify-center mt-3 mb-auto">
        <div class="flex-column">
            <div class="icon female flex align-center justify-center" aria-hidden>
                <uni-icon icon="user" [svgSize]="26"></uni-icon>
            </div>

            <span class="employee-count">{{ femaleEmployees }}</span>
            <span class="color-subdued">Kvinner</span>
        </div>

        <div class="flex-column">
            <div class="icon male flex align-center justify-center" aria-hidden>
                <uni-icon icon="user" [svgSize]="26"></uni-icon>
            </div>

            <span class="employee-count">{{ maleEmployees }}</span>
            <span class="color-subdued">Menn</span>
        </div>
    </div>

    <div class="counters">
        <div class="flex align-center justify-between">
            <span class="color-subdued">{{ 'DASHBOARD.ACTIVE_EMPLOYEES.HIRED_THIS_YEAR' | translate }}</span>
            <span class="count">
                <strong>{{ startDateThisYear }}</strong> stk
            </span>
        </div>

        <div class="flex align-center justify-between">
            <span class="color-subdued">{{ 'DASHBOARD.ACTIVE_EMPLOYEES.ENDDATE_THIS_YEAR' | translate }}</span>
            <span class="count">
                <strong>{{ endDateThisYear }}</strong> stk
            </span>
        </div>

        <div class="total flex align-center justify-between">
            <span>{{ 'DASHBOARD.ACTIVE_EMPLOYEES.FULL_TIME_EQUIVALENTS' | translate }}</span>
            <span class="count">
                <strong>{{ fullTimeEquivalents }}</strong> stk
            </span>
        </div>
    </div>
</article>

<!-- Empty state -->
<header class="header" *ngIf="isFirstTimeUser">
    <h1>{{ 'DASHBOARD.ACTIVE_EMPLOYEES.HEADER' | translate }}</h1>
</header>

<article *ngIf="isFirstTimeUser">
    <widget-empty-state>
        <section *ngIf="isFirstTimeUser">
            <p>{{ 'DASHBOARD.ACTIVE_EMPLOYEES.EMPTY_MESSAGE' | translate }}</p>
        </section>
        <section *ngIf="isFirstTimeUser">
            <button class="c2a" (click)="openStandardVacationPayModal()">
                {{ 'DASHBOARD.ACTIVE_EMPLOYEES.EMPTY_STATE_BUTTON' | translate }}
            </button>
        </section>
    </widget-empty-state>
</article>
