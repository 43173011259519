import { finalize } from 'rxjs/operators';
import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthService } from '@app/authService';
import { BankIntegrationAgreement } from '@uni-entities';
import { BrunoBankIdOnboardingModal, BrunoBankOnboardingModal, UniModalService } from '@uni-framework/uni-modal';
import { BankAgreementServiceProvider } from '@app/models/autobank-models';
import { BankAccountService } from '@app/services/accounting/bankAccountService';
import { PaymentBatchService } from '@app/services/accounting/paymentBatchService';
import { BrunoOnboardingService } from '@app/services/bank/BrunoOnboardingService';

@Component({
    selector: 'ext02-bank-balance',
    templateUrl: './ext02-bank-balance.html',
    styleUrls: ['./ext02-bank-balance.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Ext02BankBalanceWidget {
    componentDestroyed$ = new Subject();
    agreement: BankIntegrationAgreement;
    onboardingState: 'none' | 'pending' | 'connectAccounts' | 'complete' | 'refresh' | 'error';
    svgLoaded = true;
    preferCamt053 = false;
    brunoApiBankAgreement: BankIntegrationAgreement;

    bankIdRequiresRenew: boolean = false;
    isIntegrationOwner: boolean;

    errorImageUrl = 'config/dist/theme/assets/ext02-illustration-lost-connection-circle.svg';
    constructor(
        private authService: AuthService,
        private onboardingService: BrunoOnboardingService,
        private cdr: ChangeDetectorRef,
        private modalService: UniModalService,
        private paymentBatchService: PaymentBatchService,
        private bankAccountService: BankAccountService,
    ) {}

    ngOnInit() {
        if (this.authService.activeCompany?.IsTest) {
            this.onboardingState = 'complete';
        } else {
            this.getOnboardingState();
        }

        this.onboardingService.agreementStatusChanged.subscribe(() => {
            this.getOnboardingState();
        });
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(undefined);
        this.componentDestroyed$.complete();
        this.onboardingService.agreementStatusChanged.complete();
    }

    private getOnboardingState() {
        this.paymentBatchService.checkAutoBankAgreement().subscribe((agreements) => {
            const brunoApiAgreement = agreements?.find(
                (a) => a.ServiceProvider === BankAgreementServiceProvider.BrunoApi,
            );
            this.preferCamt053 = brunoApiAgreement?.IsBankBalance === false;

            const balanceAgreement = agreements?.find(
                (a) =>
                    (a.IsBankBalance && a.ServiceProvider === BankAgreementServiceProvider.BrunoApi) ||
                    (a.IsBankBalance && a.ServiceProvider === BankAgreementServiceProvider.Bruno),
            );

            if (balanceAgreement) {
                this.brunoApiBankAgreement = brunoApiAgreement;

                this.agreement = balanceAgreement;
                if (this.onboardingService.isPendingAgreement(balanceAgreement)) {
                    this.onboardingState = 'pending';
                } else {
                    if (this.onboardingService.hasNewAccountInfo(balanceAgreement)) {
                        this.onboardingState = 'connectAccounts';
                    } else {
                        const shouldReload = localStorage.getItem('shouldReloadBankAccountBalances') === 'true';
                        this.bankAccountService
                            .getAllBankBalances(shouldReload)
                            .pipe(
                                finalize(() => {
                                    this.cdr.markForCheck();
                                    localStorage.removeItem('shouldReloadBankAccountBalances');
                                }),
                            )
                            .subscribe({
                                next: (balances: any[]) => {
                                    if (balances?.some((b) => b.IsBankIDExpired)) {
                                        this.onboardingState = 'refresh';
                                        this.isIntegrationOwner = balances.some((b) => b.IsIntegrationOwner);
                                    } else {
                                        this.onboardingState = 'complete';
                                    }
                                },
                                error: () => {
                                    this.onboardingState = 'error';
                                    this.cdr.markForCheck();
                                },
                            });
                        return;
                    }
                }
            } else {
                this.onboardingState = 'none';
            }
            this.cdr.markForCheck();
        });
    }

    runOnboarding() {
        if (this.onboardingState === 'none' && !this.preferCamt053) {
            this.modalService.open(BrunoBankIdOnboardingModal);
        } else if (this.onboardingState === 'refresh') {
            localStorage.setItem('shouldReloadBankAccountBalances', 'true');
            if (this.authService.decodedToken()?.idp === 'bankiddnbsso') {
                this.authService.hardResetAuthenticateWithIdp();
            } else {
                this.authService.authenticateWithIdp('bankiddnbsso');
            }
        } else if (this.onboardingState === 'pending') {
            this.onboardingService.restartOnboarding(this.agreement);
        } else if (this.onboardingState === 'connectAccounts') {
            this.onboardingService.connectBankAccounts().subscribe((configSaved) => {
                if (configSaved) {
                    this.getOnboardingState();
                }
            });
        } else if (this.preferCamt053) {
            this.modalService.open(BrunoBankOnboardingModal);
        }
    }
}
