<section class="uni-modal uni-redesign subscribe-modal" role="dialog">
    <header class="subscribe-modal-header">
        <section *ngIf="!isConsentStep" class="product-header">
            <section class="product-icon" *ngIf="product.IconReference">
                <img src="{{ product.IconReference }}" />
            </section>

            <section>
                <section class="name-row">
                    <strong>{{ product.Label }}</strong>
                    <label
                        *ngIf="!!product['_purchaseStatus']"
                        [ngClass]="{
                            'integration-status': true,
                            'active-status': product['_purchaseStatus'] === elsaPurchaseStatusEnum.Accepted,
                            'pending-status': product['_purchaseStatus'] === elsaPurchaseStatusEnum.Pending,
                            'consent-required-status':
                                product['_purchaseStatus'] === elsaPurchaseStatusEnum.ConsentRequired,
                            'rejected-status': product['_purchaseStatus'] === elsaPurchaseStatusEnum.Rejected,
                        }"
                    >
                        {{ product['_purchaseStatus'] | productpurchasestatus }}
                    </label>
                </section>

                <section class="price-row" *ngIf="product['_hasStepPrice']">
                    Stegvis prismodell. <button class="link" (click)="openStepPriceModal()">Se stegpriser</button>
                </section>

                <section class="price-row" *ngIf="!product['_hasStepPrice'] && product['_price']">
                    <strong>{{ product['_price'] }} kr</strong>
                    {{ product['_priceText'] }}
                </section>
            </section>
        </section>
    </header>

    <article *ngIf="!isConsentStep" class="scrollable">
        <section class="product-description">{{ product.Description }}</section>

        <!-- New markdown CMS -->
        <section *ngIf="htmlContent" class="html-content">
            <section class="product-information" [innerHTML]="htmlContent"></section>
            <section *ngIf="videoMarkup" class="video-container" [innerHTML]="videoMarkup | keepHtml"></section>
        </section>

        <!-- Old CMS  -->
        <ng-container *ngIf="!htmlContent">
            <!-- <p>{{product.Description}}</p> -->
            <section *ngIf="!htmlContent && product.HtmlContent" [innerHTML]="product.HtmlContent | keepHtml"></section>
        </ng-container>

        <a *ngIf="product?.ButtonLink" (click)="openLink(product.ButtonLink)" class="read-more-link">
            {{ product.ButtonLabel || 'Les mer' }}
        </a>
    </article>

    <section *ngIf="isConsentStep" style="display: grid">
        <i class="material-icons">info</i>
        <h1>Viktig informasjon</h1>

        <article style="text-align: center" [innerHTML]="htmlContent"></article>
    </section>

    <!-- has valid clientID is a temp fix -->
    <footer class="center" *ngIf="action">
        <button (click)="onClose.emit()" class="secondary" style="margin-right: 10px">
            {{ isConsentStep ? 'Avbryt' : 'Lukk' }}
        </button>

        <div [matTooltip]="tooltipText">
            <button class="c2a" (click)="onPurchaseClick()" [disabled]="!canPurchaseProducts || !hasOrgNr">
                {{ isConsentStep ? 'Godta' : action.label }}
            </button>
        </div>
    </footer>
</section>
