import { Pipe, PipeTransform } from '@angular/core';
import { rigDate } from '@app/components/common/utils/rig-date';

interface ITime {
    hours: number;
    minutes: number;
    preSign: string;
    decimal: number;
}

export var SYSTEMTYPES = [
    { id: 1, label: 'Timer' },
    { id: 8, label: 'Utbetalt flex' },
    { id: 9, label: 'Fri med lønn' },
    { id: 10, label: 'Fri' },
    { id: 11, label: 'Fri (flex)' },
    { id: 12, label: 'Overtid' },
    { id: 13, label: 'Ferie' },
    { id: 20, label: 'Sykdom' },
];

@Pipe({
    name: 'isotime',
})
export class IsoTimePipe implements PipeTransform {
    public transform(value: any, format: string) {
        if (!value) {
            return '';
        }
        var v = value;
        if (typeof value === 'object') {
            if (!(value instanceof Date)) {
                v = value.toString();
            }
        }
        var fmt = !!format ? format.substr(0, 1) : undefined;
        if (!!format) {
            switch (fmt) {
                case 'U':
                    format = format.substr(1);
                    break;
                default:
                    fmt = undefined;
            }
        }
        var ret = rigDate(v).format(format || 'DD.MM.YYYY');
        if (fmt) {
            switch (fmt) {
                case 'U':
                    return ret.toUpperCase();
            }
        }
        return ret;
    }
}

@Pipe({
    name: 'hours',
})
export class HoursPipe implements PipeTransform {
    public transform(value: any, format?: string) {
        if (!value) {
            return '';
        }
        return value.toFixed(1);
    }
}

@Pipe({
    name: 'numpipe',
})
export class NumberPipe implements PipeTransform {
    public transform(value: any, format?: string) {
        if (!value) {
            return '';
        }
        return value.toFixed(format ? parseInt(format) : 1);
    }
}

@Pipe({
    name: 'min2hours',
})
export class MinutesToHoursPipe implements PipeTransform {
    public transform(value: any, format?: string) {
        const parsed = this.parse(value);
        switch (format) {
            case 'short':
                return this.shortFmt(parsed);
            case 'decimal':
                return this.decFmt(parsed);
            case 'decimal0':
                return !!value ? this.decFmt(parsed) : '';
            case 'decimal00':
                return !!value ? parsed.decimal.toFixed(2) : '';
            case 'decimal-':
                return !!value ? this.decFmt(parsed) + 't' : '-';
            case 'int':
                return !!value ? parsed.decimal.toFixed(0) : '';
            case 'money2':
                return !!value
                    ? parsed.decimal.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                    : '';
            case 'money':
                return !!value
                    ? parsed.decimal.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })
                    : '';
            default:
                return this.longFmt(parsed);
        }
    }

    private numberWithCommas(x) {
        return x
            .toString()
            .replace()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }

    private parse(value: any): ITime {
        var defaultValue = { hours: 0, minutes: 0, preSign: '', decimal: 0 };
        if (value === null) {
            return defaultValue;
        }
        if (!value) {
            return defaultValue;
        }
        var hours = 0;
        var minutes = parseInt(value);
        var dec = parseFloat((minutes / 60).toFixed(1));
        var preSign = '';
        if (minutes < 0) {
            minutes = -minutes;
            preSign = '-';
        }
        if (minutes === 0) {
            return defaultValue;
        }
        if (minutes >= 60) {
            hours = Math.floor(minutes / 60);
            minutes = minutes % 60;
        }
        return { hours: hours, minutes: minutes, preSign: preSign, decimal: dec };
    }

    private longFmt(time: ITime): string {
        return (
            time.preSign +
            (time.hours > 0 ? time.hours + ' timer ' : '') +
            (time.minutes !== 0 ? (time.hours > 0 ? ' og ' : '') + time.minutes + ' minutter' : '')
        );
    }

    private shortFmt(time: ITime): string {
        if (time.hours === 0 && time.minutes === 0) {
            return '';
        }
        return time.preSign + time.hours + ' : ' + (time.minutes < 10 ? '0' : '') + time.minutes;
    }

    private decFmt(time: ITime): string {
        return time.decimal.toFixed(1);
    }
}

@Pipe({
    name: 'worktypesystemtype',
})
export class WorkTypeSystemTypePipe implements PipeTransform {
    public transform(value: any, format: string) {
        var iVal = parseInt(value);
        for (var i = 0; i < SYSTEMTYPES.length; i++) {
            if (SYSTEMTYPES[i].id === iVal) {
                return SYSTEMTYPES[i].label;
            }
        }
        return value || '';
    }
}
