import {
    Chart,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    LineController,
    PieController,
    CategoryScale,
    LinearScale,
    Legend,
    Title,
    Tooltip,
    FontSpec,
} from 'chart.js';

import ChartDataLabels from 'chartjs-plugin-datalabels';

export function initChartJS() {
    Chart.register(
        ArcElement,
        LineElement,
        BarElement,
        PointElement,
        BarController,
        LineController,
        PieController,
        CategoryScale,
        LinearScale,
        Legend,
        Title,
        Tooltip,

        ChartDataLabels,
        RoundedBarChart,
    );

    // Set default options
    const style = getComputedStyle(document.body);
    const font = style.getPropertyValue('--font-family');

    // Chart.defaults.global.defaultFontFamily = font;
    Chart.defaults.plugins.datalabels.display = false;

    (<any>Chart.defaults.font).family = font;
    Chart.defaults.maintainAspectRatio = false;

    // Chart.defaults.plugins.tooltip.bodyFont.size = 14;
    Chart.defaults.plugins.tooltip.padding = 8;
    Chart.defaults.plugins.tooltip.displayColors = false;
    Chart.defaults.plugins.tooltip.backgroundColor = 'rgba(0, 0, 0, 0.9)';

    // Chart.defaults.plugins.legend.labels.font.size = 14;
    Chart.defaults.plugins.legend.labels.font = <FontSpec>{
        size: 14,
    };
    Chart.defaults.plugins.legend.labels.color = '#2B2B2B';
    Chart.defaults.plugins.legend.labels.boxWidth = 10;
    Chart.defaults.plugins.legend.labels.usePointStyle = true;
    Chart.defaults.plugins.legend.labels.padding = 10;
}

// Rounded bar chart type
class RoundedBarChart extends BarController {
    initialize() {
        try {
            this.chart.data.datasets.forEach((dataset) => {
                if (!dataset.type || dataset.type === 'bar') {
                    dataset['borderRadius'] = (context) => {
                        if (context.raw > 0) {
                            return {
                                topLeft: 4,
                                topRight: 4,
                            };
                        } else if (context.raw < 0) {
                            return {
                                bottomLeft: 4,
                                bottomRight: 4,
                            };
                        }
                    };
                }
            });
        } catch (e) {
            console.error(e);
        }

        super.initialize();
    }
}

RoundedBarChart.id = 'roundedBarChart';
RoundedBarChart.defaults = BarController.defaults;

// Doughnut text plugin
export const dougnutTextPlugin = {
    id: 'dougnutText',
    beforeDatasetDraw: (chart) => {
        const options = chart?.config?.options?.plugins?.doughnutText;
        if (options && (options.header || options.text)) {
            const ctx = chart.ctx;
            const fontFamily = getComputedStyle(document.body).getPropertyValue('--font-family');

            if (options.header) {
                ctx.font = `400 16px ${fontFamily}`;
                const textX = Math.round((chart.width - ctx.measureText(options.header).width) / 2);
                const textY = chart.height / 2 - 10;
                ctx.fillText(options.header, textX, textY);
            }

            if (options.text) {
                ctx.font = `500 17px ${fontFamily}`;
                const textX = Math.round((chart.width - ctx.measureText(options.text).width) / 2);
                let textY = chart.height / 2;
                if (options.header) {
                    textY += 10;
                }

                ctx.fillText(options.text, textX, textY);
            }
        }
    },
};
