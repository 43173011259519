<section class="header flex justify-between" *ngIf="widgetData?.title">
    <h1>{{ widgetData?.title }}</h1>
    <i
        class="material-icons pointer"
        [title]="widgetData?.closeIconTitle || 'Skjul denne informasjonen'"
        (click)="close()"
        >close</i
    >
</section>

<section
    class="content flex"
    [class.small]="widgetData.size === 'small'"
    [class.reverse]="widgetData?.illustrationAlignment === 'left'"
    *ngIf="widgetData"
>
    <section class="text-container flex-column cms-content justify-between h-100 custom-scrollbar">
        <cms-wrapper [cmsData]="widgetData" [class.sr-custom]="isSR"></cms-wrapper>
    </section>

    @if (!!widgetData.illustration && (!widgetData.illustration_version || widgetData.illustration_version === 'v1')) {
        <section class="image-container flex align-center justify-center">
            @if (path) {
                <uni-illustration-deprecated [path]="path"></uni-illustration-deprecated>
            } @else {
                <uni-illustration [name]="widgetData.illustration"></uni-illustration>
            }
        </section>
    }

    @if (widgetData.mediaField && widgetData.illustration_version !== 'v1') {
        <section class="image-container flex align-center justify-center">
            <cms-illustration-v2 class="horizontal" [item]="widgetData"></cms-illustration-v2>
        </section>
    }
</section>
