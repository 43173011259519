import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { computePosition, flip, offset, shift, size } from '@floating-ui/dom';
import { Subject } from 'rxjs';

@Component({
    selector: 'sidebar-link-list',
    templateUrl: './sidebar-link-list.html',
    styleUrls: ['./sidebar-link-list.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarLinkList {
    @ViewChild('listElement') listElement: ElementRef<HTMLElement>;

    @Input() popover: boolean;
    @Input() anchorElement: HTMLElement;
    @Input() open: boolean;

    @Output() close = new EventEmitter();

    popoverStyles$ = new Subject();

    ngOnChanges() {
        // console.log(this.open, this.popover);

        if (!this.open) {
            this.popoverStyles$.next(undefined);
        }

        if (this.popover && this.open && this.anchorElement) {
            this.checkPopover();
        }
    }

    ngAfterViewInit() {
        if (this.popover && this.open) {
            this.checkPopover();
        }
    }

    ngOnDestroy() {
        this.popoverStyles$.complete();
    }

    onFocusOut() {
        // Timeout because some browsers will have document.activeElement
        // set to <body> when this event runs.
        setTimeout(() => {
            const listLostFocus = !this.listElement?.nativeElement?.contains(document.activeElement);
            if (this.popover && listLostFocus) {
                this.close.emit();
            }
        });
    }

    private checkPopover() {
        setTimeout(() => {
            if (this.open && this.anchorElement && this.listElement?.nativeElement) {
                this.initPopover();
            }
        });
    }

    private async initPopover() {
        let maxHeight;

        const position = await computePosition(this.anchorElement, this.listElement.nativeElement, {
            strategy: 'fixed',
            placement: 'right-start',
            middleware: [
                flip(),
                // shift(),
                offset(8),
                size({
                    apply({ availableHeight }) {
                        maxHeight = availableHeight;
                    },
                }),
            ],
        });

        this.popoverStyles$.next({
            position: position.strategy,
            left: `${Math.round(position.x)}px`,
            top: `${Math.round(position.y)}px`,
            visibility: 'visible',
            'max-height': maxHeight - 10 + 'px',
        });
    }
}
