import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Altinn, FieldType } from '../../../unientities';
import { Observable, of } from 'rxjs';
import { ToastService, ToastType, ToastTime } from '@uni-framework/uniToast/toastService';
import { map, switchMap } from 'rxjs/operators';
import { AltinnExtended, AltinnIntegrationService } from '@app/services/common/altinnIntegrationService';
import { ErrorService } from '@app/services/common/errorService';
import { UniFieldLayout } from '@uni-framework/ui/uniform';

@Component({
    selector: 'altinn-settings-component',
    templateUrl: './altinn-component.html',
    styleUrls: ['./altinn-component.sass'],
})
export class AltinnSettingsComponent implements OnInit {
    @Input() hideCheckButton: boolean;
    @Output() dataLoaded = new EventEmitter<any>();
    @Output() checkValidated = new EventEmitter<boolean>();

    fields: UniFieldLayout[] = [];
    altinn: AltinnExtended;
    busy: boolean;

    loginErr: string = '';
    isDirty: boolean;
    hasFailedLoginAttempt: boolean;

    constructor(
        private altinnService: AltinnIntegrationService,
        private errorService: ErrorService,
        private toastService: ToastService,
    ) {}

    ngOnInit() {
        this.getAltinnLoginData();
    }

    getAltinnLoginData(): void {
        this.altinnService
            .getAltinnSystemLoginData()
            .subscribe({
                next: (altinn: AltinnExtended) => {
                    this.altinn = altinn;
                    this.setLayout();
                },
                error: (err) => this.errorService.handle(err),
            })
            .add(() => this.dataLoaded.emit());
    }

    saveAltinnSettings(): Observable<boolean> {
        const altinn = this.altinn;
        this.busy = true;
        altinn.Language = altinn.Language ?? this.altinnService.languages[0].ID;

        const saveObs$ = altinn.ID ? this.altinnService.Put(altinn.ID, altinn) : this.altinnService.Post(altinn);

        return saveObs$.pipe(
            switchMap((retrievedAltinn: Altinn) =>
                altinn.SystemPw
                    ? this.altinnService.setPassword(altinn.SystemPw).pipe(
                          map((x) => {
                              retrievedAltinn.SystemPw = x;
                              return retrievedAltinn;
                          }),
                      )
                    : of(retrievedAltinn),
            ),
            map((response: AltinnExtended) => {
                this.isDirty = false;
                this.altinn = response;
                this.loginErr = '';
                this.hasFailedLoginAttempt = false;
                this.busy = false;
                this.setLayout();
                return true;
            }),
        );
    }

    save(): void {
        this.saveAltinnSettings().subscribe({
            error: (error) => this.errorService.handle(error),
        });
    }

    pingAltinn(): void {
        this.busy = true;
        this.altinnService
            .checkLogin()
            .subscribe({
                next: (res) => {
                    if (res === true) {
                        this.toastService.addToast(
                            'Sjekk',
                            ToastType.good,
                            ToastTime.medium,
                            'Testen kommuniserte med Altinn',
                        );
                        this.checkValidated.emit(true);
                    } else {
                        this.loginErr = 'Test av kommunikasjon med Altinn feilet';
                        this.toastService.addToast('Sjekk', ToastType.bad, ToastTime.medium, this.loginErr);
                        this.checkValidated.emit(false);
                    }
                    this.hasFailedLoginAttempt = true;
                },
                error: (err) => {
                    this.checkValidated.emit(false);
                    this.errorService.handle(err);
                    this.loginErr = 'Test av kommunikasjon feilet';
                },
            })
            .add(() => (this.busy = false));
    }

    private setLayout(): void {
        this.fields = <UniFieldLayout[]>[
            {
                EntityType: 'Altinn',
                Property: 'SystemID',
                Label: 'ID fra Altinn',
                Description: 'Description',
                Tooltip: {
                    Text: 'Tall, Id fås av Altinn ved oppsett av datasystem (minst 6 tegn)',
                },
                Legend: 'Legend',
            },
            {
                EntityType: 'Altinn',
                Property: 'SystemPw',
                FieldType: FieldType.PASSWORD,
                Label: 'Passord',
                Placeholder: this.altinn['HasSystemPw'] ? '********' : '',
                Tooltip: {
                    Text: 'Samme passord som ble satt opp i Altinn ved oppsett datasystem',
                },
            },
            {
                EntityType: 'Altinn',
                Property: 'Language',
                FieldType: FieldType.DROPDOWN,
                Label: 'Foretrukket språk',
                Description: '',
                Tooltip: {
                    Text: 'Her kan en velge det foretrukne språket for dette firmaet for Altinn (nynorsk, bokmål, samisk, engelsk)',
                },
                IsLookUp: true,
                Options: {
                    source: this.altinnService.languages,
                    template: (obj) => `${obj.id} - ${obj.name}`,
                    valueProperty: 'ID',
                    displayProperty: 'text',
                },
            },
        ];
    }
}
