import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService, CurrentUserWithout2FADetails } from '@app/authService';
import { environment } from 'src/environments/environment';
import { PushMessage } from '@app/models';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';

@Injectable({ providedIn: 'root' })
export class SignalRService {
    user: CurrentUserWithout2FADetails;
    userGlobalIdentity: string;
    currentCompanyKey: string;
    notifications: string[] = [];
    pushMessage$: BehaviorSubject<PushMessage> = new BehaviorSubject(null);
    registry: Map<string, number> = new Map<string, number>();

    connected = false;
    retryConnectionCounter = 0;

    public hubConnection: HubConnection;

    constructor(private authService: AuthService) {
        this.authService.token$.subscribe((token) => {
            if (token && !this.connected) {
                this.startConnection();
            }
        });
        this.authService.authentication$.subscribe((auth) => {
            if (auth && auth.user) {
                this.user = auth.user;
                this.userGlobalIdentity = auth.user.GlobalIdentity;
                this.currentCompanyKey = auth.activeCompany.Key;
            } else if (this.hubConnection) {
                this.hubConnection.stop();
                delete this.hubConnection;
            }
        });
    }

    register(filter: PushMessage) {
        if (!filter) return;
        if (!this.hubConnection) return;
        if (this.adjustCounter(filter, 1) > 1) return;
        this.hubConnection.invoke('RegisterListener', filter).catch((err) => console.error(err));
    }

    unregister(filter: PushMessage) {
        if (!filter) return;
        if (!this.hubConnection) return;
        if (this.adjustCounter(filter, -1) > 0) return;
        this.hubConnection.invoke('UnRegisterListener', filter).catch((err) => console.error(err));
    }

    adjustCounter(filter: PushMessage, value: number): number {
        const key = `${filter.companyKey}.${filter.entityType?.toLowerCase()}.${filter.entityID}`;
        let current = this.registry.has(key) ? this.registry.get(key) : 0;
        current += value;
        if (current < 0) return current;
        this.registry.set(key, current);
        return current;
    }

    addGlobalListener() {
        this.hubConnection.on('Notify', (message: PushMessage) => {
            if (message) {
                if (Array.isArray(message)) {
                    message = message[message.length - 1];
                }
                this.pushMessage$.next(message);
            }
        });
    }

    startConnection() {
        this.retryConnectionCounter = 0;
        this.hubConnection = new HubConnectionBuilder()
            .withUrl(environment.SIGNALR_PUSHHUB_URL, { accessTokenFactory: () => this.authService.jwt })
            .build();

        this.start();

        this.hubConnection.onclose(() => {
            this.connected = false;
            setTimeout(() => this.start(), 1000);
        });

        this.hubConnection.on('disconnected', function () {
            this.connected = false;
            setTimeout(function () {
                this.start();
            }, 5000); // Restart connection after 5 seconds.
        });
    }

    async start() {
        if (!this.connected && this.hubConnection) {
            try {
                await this.hubConnection.start();
                this.connected = true;
                this.addGlobalListener();
            } catch (err) {
                // console.log('Error while starting SignalR connection');
                if (this.retryConnectionCounter < 5) {
                    // console.log('DEBUG:: attempting to reconnect, attempt nr: ' + this.retryConnectionCounter);
                    setTimeout(() => this.start(), 5000);
                    this.retryConnectionCounter++;
                } else {
                    // console.log('Tried to reconnect too many times');
                    delete this.hubConnection;
                }
            }
        }
    }
}
