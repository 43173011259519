<section [class.empty]="!tabs?.length" class="tabstrip flex align-center">
    <span class="tabstrip-description" *ngIf="tabs?.length"> Nylig besøkt: </span>

    <section class="links flex-1 h-100 flex overflow-hidden">
        <a
            *ngFor="let tab of tabs; let idx = index"
            class="unstyled-link"
            [ngClass]="{ active: tab.active }"
            (click)="activateTab(idx)"
            (contextmenu)="openContextMenu($event, idx)"
            (mousedown)="onMouseDown(idx, $event)"
        >
            <span class="ellipsis">{{ tab.name | translate }}</span>
            <i (click)="closeTab(idx, $event)" class="material-icons close-tab">close</i>
        </a>
    </section>

    <section
        class="dropdown-menu right-click-menu"
        *ngIf="tabContextMenu"
        (clickOutside)="closeContextMenu()"
        [ngStyle]="{
            left: tabContextMenu.left + 'px',
            top: tabContextMenu.top + 'px',
        }"
    >
        <section class="dropdown-menu-item" (click)="contextMenuClick(tabContextMenu.index, 'tab')">Lukk fane</section>
        <section class="dropdown-menu-item" (click)="contextMenuClick(tabContextMenu.index, 'left')">
            Lukk faner til venstre
        </section>
        <section class="dropdown-menu-item" (click)="contextMenuClick(tabContextMenu.index, 'right')">
            Lukk faner til høyre
        </section>
        <section class="dropdown-menu-item" (click)="contextMenuClick(tabContextMenu.index, 'others')">
            Lukk alle andre faner
        </section>
    </section>
</section>
