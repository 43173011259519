<ng-container *ngIf="!busyCreatingCompany">
    <section *ngIf="customerAgreementExists === false" class="missing-agreement alert warn">
        Systemet mangler oppsett for lisensavtale. Vennligst kontakt systemansvarlig.
    </section>

    <ng-container *ngIf="customerAgreementExists">
        <!-- Contract type selection -->
        <section class="contract-types" *ngIf="currentStep === STEPS.CONTRACT_TYPE && !busyGettingSb1UserAgreements">
            <section class="contract-activation-text" *ngIf="isSb1 && isContractActivation">
                <h1>Velg pakken som passer best</h1>
                <p>
                    Det er enkelt å bytte mellom pakkene hvis behovet endrer seg. Du betaler bare for det antall brukere
                    du tregner, uansett pakke. Lønn og timeregistrering betaler du per ansatt
                </p>

                <a *ngIf="priceListLink" class="price-list-link" [href]="priceListLink" target="_blank">
                    Se alle priser
                </a>
            </section>

            <a
                *ngIf="priceListLink && !(isSb1 && isContractActivation)"
                class="price-list-link"
                [href]="priceListLink"
                target="_blank"
            >
                <i class="material-icons">launch</i>
                Se flere priser
            </a>
            <section class="contract-type-grid">
                <contract-type-card
                    *ngFor="let contractType of contractTypes"
                    buttonClass="{{ isSb1 && isContractActivation ? 'c2a' : '' }}"
                    [contractType]="contractType"
                    [recommended]="contractType.Name === 'Plus' && isBrunoEnv"
                    (selected)="onContractTypeSelected(contractType.ContractType)"
                >
                </contract-type-card>
            </section>

            <a class="try-demo-link" *ngIf="isBrunoEnv && showTryDemoLink" routerLink="/init/register-company?type=demo"
                >Jeg vil heller prøve et demo selskap</a
            >

            <contract-types-comparison [useExt02SignupTheme]="isBrunoEnv"></contract-types-comparison>
        </section>

        <ng-container *ngIf="!bankIdFlow">
            <!-- Company form part 1 -->
            <section *ngIf="currentStep === STEPS.COMPANY_STEP1" class="uni-card shadow">
                <section class="uni-card-content">
                    <h1>Selskapsinformasjon</h1>

                    <autocomplete
                        class="company-autocomplete"
                        [options]="autocompleteOptions"
                        (valueChange)="onBrRegCompanyChange($event)"
                    >
                    </autocomplete>

                    <section *ngIf="companyCreationFailed" class="alert bad">
                        Selskapsoppretting feilet. Vennligst sjekk at detaljene er korrekt og prøv på nytt.
                    </section>

                    <form [formGroup]="step1Form">
                        <label #companyNameInput class="uni-label">
                            <span>Firmanavn</span>
                            <input type="text" formControlName="CompanyName" />
                        </label>

                        <label class="uni-label">
                            <span>Organisasjonsnummer</span>
                            <input type="text" formControlName="OrganizationNumber" />
                        </label>

                        <label class="uni-label">
                            <span>Adresse</span>
                            <input type="text" formControlName="Address" />
                        </label>

                        <section class="input-row">
                            <label class="uni-label">
                                <span>Postnummer</span>
                                <input type="text" formControlName="PostalCode" />
                            </label>

                            <label class="uni-label">
                                <span>Poststed</span>
                                <input type="text" formControlName="City" />
                            </label>
                        </section>
                    </form>
                </section>

                <section class="uni-card-footer">
                    <button
                        class="secondary pull-left"
                        *ngIf="includeContractActivation && contractTypes?.length && !modal"
                        (click)="currentStep = STEPS.CONTRACT_TYPE"
                    >
                        Tilbake
                    </button>

                    <button *ngIf="modal" class="secondary pull-left" (click)="closeModal.emit()">Avbryt</button>

                    <button class="c2a" (click)="step1FormSubmit()" (keydown.tab)="$event.preventDefault()">
                        Neste
                    </button>
                </section>
            </section>

            <!-- Company form part 2 -->
            <section *ngIf="currentStep === STEPS.COMPANY_STEP2" class="uni-card shadow">
                <section class="uni-card-content">
                    <h1>Selskapsinnstillinger</h1>

                    <form [formGroup]="step2Form" uniAutoFocus>
                        <label *ngIf="step2Form.controls['AccountNumber']" class="uni-label">
                            <span>Driftskonto</span>
                            <input type="text" formControlName="AccountNumber" />
                            <section *ngIf="isBrunoEnv && !temporaryDisableBIC" class="alert good bruno-account-alert">
                                <i class="material-icons">info_outline</i>
                                <span>Kontoen må være en DNB konto</span>
                            </section>
                        </label>

                        <label class="uni-label">
                            <span>Skal selskapet betale moms?</span>
                            <mat-radio-group class="horizontal" formControlName="TaxMandatoryType">
                                <mat-radio-button [value]="taxType.TaxMandatory">Ja</mat-radio-button>
                                <mat-radio-button [value]="taxType.NotTaxMandatory">Nei</mat-radio-button>
                                <mat-radio-button [value]="taxType.FutureTaxMandatory"
                                    >Planlegger over 50.000 i mva-pliktig omsetning innenfor 12
                                    måneder</mat-radio-button
                                >
                            </mat-radio-group>
                        </label>
                    </form>
                </section>

                <section class="uni-card-footer">
                    <button class="secondary pull-left" (click)="currentStep = STEPS.COMPANY_STEP1">Tilbake</button>

                    <button
                        class="c2a"
                        [disabled]="!step2Form.valid"
                        (click)="step2FormSubmit()"
                        (keydown.tab)="$event.preventDefault()"
                    >
                        {{ includeContractActivation && !contractActivated ? 'Neste' : 'Opprett selskap' }}
                    </button>
                </section>
            </section>

            <!-- Contract activation -->
            <contract-activation-wizard
                *ngIf="currentStep === STEPS.CONTRACT_ACTIVATION"
                [contractID]="contractID"
                [companyName]="companyName"
                [orgNumber]="orgNumber"
                [contractType]="selectedContractType"
                [customer]="contract.Customer"
                (contractActivated)="onContractActivated($event)"
                (back)="currentStep = STEPS.COMPANY_STEP2"
            >
            </contract-activation-wizard>
        </ng-container>

        <ng-container *ngIf="bankIdFlow && !busy">
            <!-- SB1 BANK ID STEPS -->
            <ng-container *ngIf="!busyGettingSb1UserAgreements">
                <section *ngIf="currentStep === STEPS.SB1_BANKID_STEP1" class="sr-bank-id-step-container">
                    <h1>Velg bedrift</h1>
                    <p class="sub-heading">
                        Vi ser at du er tilknyttet flere bedrifter. Velg den du ønsker å registrere.
                    </p>
                    <div *ngIf="sb1UserAgreements" class="select-buttons-container">
                        <button
                            *ngFor="let agreement of sb1UserAgreements"
                            class="select-button"
                            (click)="onSb1UserAgreementSelected(agreement)"
                        >
                            <span title="{{ agreement?.OrganizationName }}">
                                {{ agreement?.OrganizationName }}
                            </span>
                            <i class="material-icons">keyboard_arrow_right</i>
                        </button>
                    </div>
                    <p>Finner du ikke bedriften? <a (click)="currentStep = STEPS.BANKID_STEP1">Velg bedrift her</a></p>
                </section>
            </ng-container>

            <!-- NORMAL BANK ID STEPS -->
            <section
                *ngIf="currentStep === STEPS.BANKID_STEP1"
                [ngClass]="isSb1 ? 'sr-bank-id-step-container' : 'uni-card shadow bankid'"
            >
                <section class="uni-card-content">
                    <h1>{{ modal ? 'Nytt selskap' : 'Velg bedrift' }}</h1>
                    <section class="init-subtitle" *ngIf="!isContractActivation && !modal && !isSb1">
                        Påloggingen var vellykket!
                    </section>

                    <section class="mb-2">
                        <span *ngIf="!isContractActivation && !modal" class="sub-heading">
                            {{ 'ONBOARDING.BANKID_STEP1_SUB_HEADING' | translate }}
                        </span>
                    </section>

                    <label class="uni-label" *ngIf="isSb1">
                        <span>{{ 'ONBOARDING.ORG_NUMBER' | translate }}</span>
                    </label>
                    <autocomplete [options]="autocompleteOptions" (valueChange)="onBrRegCompanyChange($event)">
                    </autocomplete>
                </section>

                <section class="uni-card-footer">
                    <button *ngIf="!modal" class="secondary" (click)="backFromBankIdStep1()">Tilbake</button>

                    <button *ngIf="modal" class="secondary" (click)="closeModal.emit()">Avbryt</button>

                    <button
                        class="c2a"
                        (click)="currentStep = STEPS.BANKID_STEP2"
                        (keydown.tab)="$event.preventDefault()"
                        [disabled]="!step1Form.valid"
                    >
                        {{ 'ONBOARDING.BANKID_STEP1_MAIN_BTN_TEXT' | translate }}
                    </button>
                </section>
            </section>

            <section
                *ngIf="currentStep === STEPS.BANKID_STEP2"
                [ngClass]="isSb1 ? 'sr-bank-id-step-container' : 'uni-card shadow bankid wide'"
            >
                <section class="uni-card-content">
                    <h1 *ngIf="!editMode">{{ 'ONBOARDING.BANKID_STEP2_HEADING' | translate }}</h1>
                    <h1 *ngIf="editMode">Rediger informasjon</h1>

                    <section *ngIf="companyCreationFailed" class="alert bad">
                        Selskapsoppretting feilet. Vennligst sjekk at detaljene er korrekt og prøv på nytt.
                    </section>

                    <section *ngIf="!isSb1 || (isSb1 && !editMode)" class="sub-heading">
                        {{ 'ONBOARDING.BANKID_STEP2_SUB_HEADING' | translate }}
                    </section>
                    <section *ngIf="isSb1 && editMode" class="sub-heading">
                        {{ 'ONBOARDING.BANKID_STEP2_SUB_HEADING_EDIT_MODE' | translate }}
                    </section>

                    <section *ngIf="!isSb1 || (isSb1 && !editMode)" class="info-box">
                        <button *ngIf="!editMode && !isSb1" class="edit-info tertiary c2a" (click)="editMode = true">
                            Rediger
                        </button>
                        <button
                            *ngIf="!editMode && isSb1"
                            class="edit-info tertiary c2a"
                            (click)="onChangeCompanyClicked()"
                        >
                            <i class="material-icons">loop</i> Endre bedrift
                        </button>

                        <autocomplete
                            *ngIf="editMode"
                            class="company-autocomplete"
                            [options]="autocompleteOptions"
                            (valueChange)="onBrRegCompanyChange($event)"
                        >
                        </autocomplete>

                        <form [formGroup]="step1Form">
                            <section class="info-field">
                                <strong>{{ 'ONBOARDING.COMPANY_NAME' | translate }}:</strong>
                                <span *ngIf="!editMode">{{ step1Form.value.CompanyName }}</span>
                                <input *ngIf="editMode" type="text" formControlName="CompanyName" />
                            </section>

                            <section class="info-field">
                                <strong>{{ 'ONBOARDING.ORG_NUMBER' | translate }}:</strong>
                                <span *ngIf="!editMode">{{ step1Form.value.OrganizationNumber }}</span>
                                <input *ngIf="editMode" type="text" formControlName="OrganizationNumber" />
                            </section>

                            <section class="info-field" *ngIf="!isSb1">
                                <strong>{{ 'ONBOARDING.ADDRESS' | translate }}:</strong>
                                <span *ngIf="!editMode"
                                    >{{ step1Form.value.Address }}, {{ step1Form.value.PostalCode }}
                                    {{ step1Form.value.City }}</span
                                >
                                <input *ngIf="editMode" type="text" formControlName="Address" />
                            </section>

                            <section class="info-field" *ngIf="editMode && !isSb1">
                                <strong>{{ 'ONBOARDING.POSTAL_CODE' | translate }}:</strong>
                                <input type="text" formControlName="PostalCode" />
                            </section>

                            <section class="info-field" *ngIf="editMode && !isSb1">
                                <strong>{{ 'ONBOARDING.CITY' | translate }}:</strong>
                                <input type="text" formControlName="City" />
                            </section>
                        </form>
                    </section>

                    <section
                        *ngIf="!modal"
                        class="info-box mb-2"
                        [ngClass]="{ 'info-box-transparent': isSb1 && editMode }"
                    >
                        <button *ngIf="!editMode" class="edit-info tertiary c2a" (click)="onEditContactInfoClicked()">
                            <i class="material-icons" *ngIf="isSb1">create</i> Rediger
                        </button>

                        <form [formGroup]="step2FormBankId">
                            <section class="info-field">
                                <strong>{{ 'ONBOARDING.CONTACT_PERSON' | translate }}:</strong>
                                <span *ngIf="!editMode">{{ step2FormBankId.value.ContactPerson }}</span>
                                <input *ngIf="editMode" type="text" formControlName="ContactPerson" />
                            </section>

                            <section class="info-field">
                                <strong>{{ 'ONBOARDING.EMAIL' | translate }}:</strong>
                                <span *ngIf="!editMode">{{ step2FormBankId.value.ContactEmail }}</span>
                                <input *ngIf="editMode" type="text" formControlName="ContactEmail" />
                            </section>

                            <section class="info-field" *ngIf="isSb1">
                                <strong>{{ 'ONBOARDING.CONTACT_PHONE' | translate }}:</strong>
                                <span *ngIf="!editMode">{{ step2FormBankId.value.ContactPhone ?? '-' }}</span>
                                <input *ngIf="editMode" type="text" formControlName="ContactPhone" />
                            </section>
                        </form>
                    </section>

                    <form [formGroup]="step2FormBankId" class="mva-checkboxes" *ngIf="!isSb1 || (isSb1 && !editMode)">
                        <label class="uni-label">
                            <span>Skal selskapet betale moms?</span>
                            <mat-radio-group class="horizontal" formControlName="TaxMandatoryType">
                                <mat-radio-button [value]="taxType.TaxMandatory">Ja</mat-radio-button>
                                <mat-radio-button [value]="taxType.NotTaxMandatory">Nei</mat-radio-button>
                                <mat-radio-button [value]="taxType.FutureTaxMandatory"
                                    >Planlegger over 50.000 i mva-pliktig omsetning innenfor 12
                                    måneder</mat-radio-button
                                >
                            </mat-radio-group>
                        </label>
                    </form>
                </section>

                <section class="uni-card-footer" *ngIf="!isSb1 || (isSb1 && !editMode)">
                    <button
                        *ngIf="contract?.Customer?.OrgNumber?.length || (!modal && isSb1)"
                        class="secondary"
                        (click)="backFromBankIdStep2()"
                    >
                        Tilbake
                    </button>

                    <button class="c2a" (click)="onStepTo3()" [disabled]="!step2FormBankId.valid || !step1Form.valid">
                        <span class="small-button-text" hidden="true"> Videre </span>
                        <span class="big-button-text"> Aktiver kundeforhold </span>
                    </button>
                </section>

                <section class="uni-card-footer" *ngIf="isSb1 && editMode">
                    <button class="secondary" (click)="resetStep2FormBankId()">Avbryt</button>
                    <button class="c2a" (click)="editMode = false">Lagre endringer</button>
                </section>
            </section>

            <section *ngIf="currentStep === STEPS.BANKID_STEP3" class="uni-card shadow bankid">
                <section class="uni-card-content">
                    <h1>Koble kontoer</h1>
                    <section class="left mb-2">
                        Når du nå logger på med BankID vil du få tilgang til alle dine kontoer og banktransaksjoner rett
                        inn i bankavstemming.
                    </section>

                    <section class="left">
                        <strong> Ønsker du å gjennomføre betalinger rett fra DNB Regnskap? </strong>
                    </section>

                    <label class="uni-label mb-15">
                        <span style="font-size: 18px; margin-bottom: 8px">Betalinger</span>
                        <rig-checkbox [(ngModel)]="paymentsInApp">Ja takk, det vil jeg</rig-checkbox>
                    </label>

                    <ng-container *ngIf="paymentsInApp">
                        <section class="mb-15">
                            <form [formGroup]="step3FormBankId">
                                <label class="uni-label">
                                    <span style="font-size: 18px; margin-bottom: 8px">Godkjenning av betalinger</span>
                                    <mat-radio-group class="vertical" formControlName="ApprovalLocation">
                                        <mat-radio-button
                                            [value]="approvalLocation.InApp"
                                            style="margin-bottom: 0.5rem"
                                        >
                                            Jeg vil godkjenne i regnskapssystemet
                                        </mat-radio-button>

                                        <mat-radio-button [value]="approvalLocation.InBank">
                                            Jeg vil godkjenne i nettbanken
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </label>
                            </form>
                        </section>

                        <section class="alert warn">
                            <span>
                                <strong>Viktig ved bruk av regnskapsgodkjente betalinger</strong>
                                <br />Den som godkjenner betalinger i regnskapet, må være bruker i den integrerte
                                nettbanken og ha rettigheter på kontoene det betales fra.
                            </span>
                        </section>
                    </ng-container>
                </section>

                <section class="uni-card-footer">
                    <button class="secondary" (click)="currentStep = STEPS.BANKID_STEP2">Tilbake</button>

                    <button class="c2a big-button" (click)="bankIdFlowSubmit()" [attr.aria-busy]="busyActivating">
                        Koble kontoer&nbsp;
                        <span class="big-button-text"> med BankID </span>
                    </button>
                </section>
            </section>
        </ng-container>

        <section *ngIf="currentStep === STEPS.VALIDATION_FAILED" class="uni-card shadow">
            <section class="uni-card-content">
                <h1 class="justify-center mb-1">Vi kunne ikke finne rettighetene dine på selskapet</h1>
                <section class="mb-1">
                    Det kan se ut som om du mangler rettigheter til {{ companyName || 'selskapet' }} i Brønnøysund eller
                    i banken.
                </section>

                <section class="mb-1">
                    <strong style="font-size: 18px">Følgende alternativer finnes</strong>
                </section>

                <section class="mb-1">
                    For å fortsette aktiveringen av selskapet i {{ appName }} kan du gjøre et av følgende steg:
                </section>

                <section class="mb-1">
                    <ul class="validation-options">
                        <li *ngFor="let validationText of validationFailedTexts" [innerHTML]="validationText"></li>
                    </ul>
                </section>

                <section style="margin-bottom: -1rem" *ngIf="isBrunoEnv">
                    Du kan lese mer om rettigheter
                    <a
                        href="https://hjelp.dnbregnskap.dnb.no/no/article/rettigheter-til-a-etablere-selskap-i-dnb-regnskap-1054guj/"
                        target="_blank"
                        >her</a
                    >
                </section>
            </section>

            <section class="uni-card-footer pull-left mb-1">
                <button class="secondary" (click)="reloadPage()">Tilbake til selskapsvelger</button>
            </section>
        </section>
    </ng-container>
</ng-container>

<section *ngIf="busyCreatingCompany" class="uni-card shadow">
    <section class="uni-card-content">
        <h1>
            <mat-spinner class="c2a" [diameter]="24"></mat-spinner>
            {{ companyCreationHeader }}
        </h1>

        <p>
            Vi setter nå opp systemet med innstillinger og data du trenger for å komme i gang. Denne prosessen kan ta
            litt tid.
            <br /><br />Du vil bli logget på selskapet så snart det er klart til bruk.
        </p>
    </section>
</section>
