import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { loadExcelJS } from '@app/components/common/utils/excel/excel';
import { IBizReport, IBizReportSettings } from '@app/components/reports/bizreport/IBizReport';
import { ExcelReportRenderer } from '@app/components/reports/bizreport/excelreportrenderer';
import { CsvReportRenderer } from '@app/components/reports/bizreport/csvreportrenderer';
import { ReportData, ReportDataSets } from '@app/components/reports/bizreport/ReportData';
import { ReportRender } from '@app/components/reports/bizreport/ReportRenderer';
import { exportToFile } from '../utils/utils';
import { Observable, from, of } from 'rxjs';
import { CompanySettingsService } from '@app/services/common/companySettingsService';
import { HttpClient } from '@angular/common/http';
import { UniHttp } from '@uni-framework/core/http';
import { CustomImportTemplates } from './custom-import-templates';

export interface ImportDefinition {
    title: string;
    importCategory: 'Accounting' | 'Sales' | 'Salary' | 'Other';
    filename: string;
    endpoint: string;
    entityName: string;
    fields: Array<ImportField>;
    iconName: string;
}

export interface ImportField {
    name: string;
    src: string;
    label: string;
    dataType?: string;
    lookup?: ImportLookup;
    isUnique?: boolean;
    isRequired?: boolean;
    note?: string[];
    // internals
    srcParts?: string[];
}

export interface ImportLookup {
    label: string;
    model: string;
    property: string;
}

export interface ApiResult {
    result?: any;
    success?: boolean;
    msg?: string;
}

@Injectable()
export class CustomImportService {
    constructor(
        private http: HttpClient,
        private uniHttp: UniHttp,
        private companySettingsService: CompanySettingsService,
    ) {}

    getTemplates(): ImportDefinition[] {
        return CustomImportTemplates;
    }

    async getTemplateFromDefinition(def: ImportDefinition, includeData = false) {
        const report: IBizReport = {
            Name: def.title,
            Title: def.title,
            Data: { routes: [{ data: this.buildRouteFromDefinition(def) }] },
            Input: [],
            Layouts: [
                {
                    Name: 'Default',
                    Label: 'Default',
                    Dataset: 'data',
                    Type: 'Table',
                    Summary: 'none',
                    Columns: def.fields.map((field) => {
                        const size = Math.min(15, Math.max(10, field.name.length));
                        return {
                            Name: field.name,
                            Source: field.src,
                            Label: field.name,
                            Format: field.dataType,
                            Max: size,
                            Required: field.isRequired,
                        };
                    }),
                },
            ],
        };

        const settings: IBizReportSettings = { financialYear: 2 };
        const excelJS = await loadExcelJS();
        const excel = excelJS ? new ExcelReportRenderer(excelJS, report.Layouts[0], settings) : new CsvReportRenderer();

        const title = def.title;
        const subTitle = '';
        const companyName = includeData
            ? (await this.companySettingsService.getCompanySettings().toPromise())?.CompanyName
            : '';

        let empty = from([new ReportDataSets([{}])]);
        let obs = includeData ? ReportData.FetchData(report, this.http, settings) : empty;

        obs.subscribe(async (exportData) => {
            const renderer = new ReportRender(report, exportData, settings);
            renderer.numberFormat = <any>{
                format(value) {
                    return excel.formatNumber(value);
                },
            };
            renderer.createElements(<any>excel, settings.layoutIndex);
            const fileName = def.filename + excel.getFileExtension();
            const binData = await excel.getBuffer(title, subTitle, companyName, (wb) => {
                if (wb?.worksheets && wb.worksheets[0]) {
                    const ws = wb.worksheets[0];
                    for (const [index, fld] of def.fields.entries()) {
                        if (fld.note && fld.note.length > 0) {
                            const cell = ws.getRow(5).getCell(index + 1);
                            const notes = fld.note.map((line) => {
                                return { text: line + '\n' };
                            });
                            if (notes.length > 3) {
                                notes.unshift(<any>{
                                    text: 'Forklaring: (åpne notat for å se hele)\n',
                                    font: { bold: true },
                                });
                            }
                            cell.note = {
                                texts: notes,
                                margins: {
                                    insetmode: 'custom',
                                    inset: [0.25, 0.25, 0.35, 0.35],
                                },
                                editAs: 'twoCells',
                            };
                        }
                    }
                }
            });
            const txtData = !!binData ? undefined : excel.getCsvData(title, subTitle, companyName);
            exportToFile(txtData, fileName, false, binData);
        });
    }

    buildRouteFromDefinition(def: ImportDefinition): string {
        const expands = [];
        const fields = [];
        for (var i = 0; i < def.fields.length; i++) {
            const field = def.fields[i];
            // Detect expand
            let src = field.src;
            if (field.src.includes('.')) {
                // Extract expand
                let parts = field.src.split('.');
                parts.splice(parts.length - 1, 1);
                const expand = parts.join('.');
                if (expands.indexOf(expand) < 0) expands.push(expand);
                // Extract column src (only the two last parts)
                parts = field.src.split('.');
                src = parts[parts.length - 2] + '.' + parts[parts.length - 1];
            }
            fields.push(`${src} as ${field.name}`);
        }

        let route =
            `/api/statistics?model=${def.entityName}` +
            `&select=${fields.join(',')}` +
            (expands && expands.length > 0 ? '&expand=' + expands.join(',') : '') +
            '&wrap=false';

        return route;
    }

    getStatisticsQuery(url: string) {
        return this.uniHttp
            .asGET()
            .usingStatisticsDomain()
            .withEndPoint(url)
            .send()
            .pipe(map((res) => res.body));
    }

    postEntity(url: string, value: any): Observable<ApiResult> {
        return <Observable<ApiResult>>this.uniHttp
            .asPOST()
            .withDomain('')
            .withEndPoint(url)
            .withBody(value)
            .send()
            .pipe(
                map((res) => ({ success: true, result: res.body })),
                catchError(this.handlError),
            );
    }

    putEntity(url: string, value: any): Observable<ApiResult> {
        return <Observable<ApiResult>>this.uniHttp
            .asPUT()
            .withDomain('')
            .withEndPoint(url)
            .withBody(value)
            .send()
            .pipe(
                map((res) => ({ success: true, result: res.body })),
                catchError(this.handlError),
            );
    }

    handlError(ex): any {
        const msg = ex.error?.Message ?? (ex.error?.Messages ? ex.error?.Messages[0].Message : ex.message);
        return of({
            success: false,
            msg: `${ex.status} ${ex.statusText}: ${msg}`,
        });
    }
}
