import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { ReportDefinition, ReportDefinitionParameter } from '@uni-entities';
import { IModalOptions, IUniModal, UniModalService, UniPreviewModal } from '@uni-framework/uni-modal';
import { rigDate } from '@app/components/common/utils/rig-date';
import { PayrollRunService } from '@app/services/salary/payrollRun/payroll-run.service';
import { ReportDefinitionParameterService } from '@app/services/reports/reportDefinitionParameterService';

@Component({
    template: `
        <section role="dialog" class="uni-modal" style="width: 45rem">
            <header>{{ report.Name }}</header>

            <article>
                <mat-radio-group
                    class="horizontal"
                    style="margin-bottom: 1rem"
                    [(ngModel)]="inputPeriodAsTerm"
                    (ngModelChange)="onRadioChange()"
                >
                    <mat-radio-button [value]="false">Periode</mat-radio-button>
                    <mat-radio-button [value]="true">Termin</mat-radio-button>
                </mat-radio-group>

                <label class="uni-label label-left">
                    <span>Fra {{ inputPeriodAsTerm ? 'termin' : 'periode' }}</span>
                    <input type="text" [(ngModel)]="model.FromPeriod" />
                </label>

                <label class="uni-label label-left">
                    <span>Til {{ inputPeriodAsTerm ? 'termin' : 'periode' }}</span>
                    <input type="text" [(ngModel)]="model.ToPeriod" />
                </label>

                <label class="uni-label label-left">
                    <span>År</span>
                    <input type="text" [(ngModel)]="model.Year" />
                </label>

                <p *ngIf="validationError" class="color-bad" style="margin: 1.5rem 0 0;">{{ validationError }}</p>
            </article>

            <footer>
                <button class="secondary" (click)="onClose.emit()">Avbryt</button>
                <button class="c2a" (click)="showReport()">Vis rapport</button>
            </footer>
        </section>
    `,
})
export class SalaryWithholdingAndAgaModal implements IUniModal {
    @Input() options: IModalOptions = {};
    @Output() onClose = new EventEmitter();

    report: ReportDefinition;
    reportParams: ReportDefinitionParameter[];
    model = {
        FromPeriod: null,
        ToPeriod: null,
        Year: null,
    };

    inputPeriodAsTerm = false;
    validationError: string;

    constructor(
        private reportDefinitionParameterService: ReportDefinitionParameterService,
        private modalService: UniModalService,
        private payrollRunService: PayrollRunService,
    ) {}

    ngOnInit() {
        this.report = this.options?.data || {};

        forkJoin([
            this.reportDefinitionParameterService.GetAll('filter=ReportDefinitionId eq ' + this.report.ID),
            this.payrollRunService.getLatestSettledRun(),
        ]).subscribe(([params, payrollRun]) => {
            this.report['parameters'] = params;

            const period = payrollRun?.PayDate ? rigDate(payrollRun.PayDate).month() : null;
            this.model.FromPeriod = period;
            this.model.ToPeriod = period;
            this.model.Year = rigDate(payrollRun?.PayDate || new Date()).year();
        });
    }

    onRadioChange() {
        this.model.FromPeriod = null;
        this.model.ToPeriod = null;
    }

    showReport() {
        this.validationError = this.validate();
        if (this.validationError) {
            return;
        }

        const model = { ...this.model };
        if (this.inputPeriodAsTerm) {
            model.FromPeriod = model.FromPeriod ? model.FromPeriod * 2 - 1 : 1;
            model.ToPeriod = model.ToPeriod ? model.ToPeriod * 2 : 12;
        } else {
            model.FromPeriod = model.FromPeriod || 1;
            model.ToPeriod = model.ToPeriod || 12;
        }

        this.report['parameters'].forEach((param) => (param.value = model[param.Name]));
        this.modalService.open(UniPreviewModal, { data: this.report });
        this.onClose.emit();
    }

    private validate() {
        if (this.inputPeriodAsTerm && (this.model.FromPeriod > 6 || this.model.ToPeriod > 6)) {
            return 'Termin kan ikke være større enn 6';
        }

        if (!this.inputPeriodAsTerm && (this.model.FromPeriod > 12 || this.model.ToPeriod > 12)) {
            return 'Periode kan ikke være større enn 12';
        }
    }
}
