import { Component, Input, Output, EventEmitter } from '@angular/core';
import { forkJoin } from 'rxjs';
import { IUniModal, IModalOptions, ConfirmActions } from '@uni-framework/uni-modal/interfaces';
import { CompanySettingsService } from '../../../services/common/companySettingsService';
import { rigDate } from '@app/components/common/utils/rig-date';
import { CompanySettings } from '@uni-entities';
import { JournalEntryService } from '@app/services/accounting/journalEntryService';

@Component({
    selector: 'confirm-credited-journalEntry-with-date-modal',
    template: `
        <section role="dialog" class="uni-modal" (keydown.esc)="cancel()">
            <header>
                {{ options.header }}
                <i (click)="cancel()" class="material-icons close-button" role="button"> close </i>
            </header>

            <article>
                <section *ngIf="!disableCreditButton" [innerHtml]="options.message"></section>
                <p class="warn" *ngIf="options.warning">
                    {{ options.warning }}
                </p>
                <p *ngIf="vatLockedDateReformatted && showVatLockedDateInfo">
                    MVA er låst til dato: {{ vatLockedDateReformatted }}
                </p>
                <p *ngIf="accountingLockedDateReformatted && showAccountingLockedInfo">
                    Regnskap er låst til dato: {{ accountingLockedDateReformatted }}
                </p>
                <p>{{ message }}</p>
            </article>

            <footer>
                <button class="cancel" (click)="cancel()">
                    {{ options.buttonLabels.cancel }}
                </button>

                <button
                    *ngIf="options.buttonLabels.reject"
                    class="secondary"
                    (click)="reject()"
                    [disabled]="!formReady"
                >
                    {{ options.buttonLabels.reject }}
                </button>

                <button *ngIf="!disableCreditButton" class="c2a" (click)="accept()" [disabled]="!formReady">
                    {{ options.buttonLabels.accept }}
                </button>
            </footer>
        </section>
    `,
})
export class ConfirmCreditedJournalEntryWithDate implements IUniModal {
    @Input() options: IModalOptions = {};
    @Output() onClose = new EventEmitter();

    public vatLockedDateReformatted: string;
    public accountingLockedDateReformatted: string;
    public message: string;

    public showVatLockedDateInfo: boolean = false;
    public showAccountingLockedInfo: boolean = false;
    public disableCreditButton: boolean = false;

    public formReady: boolean = false;

    constructor(
        private companySettingsService: CompanySettingsService,
        private journalEntryService: JournalEntryService,
    ) {}

    public ngOnInit() {
        if (!this.options.buttonLabels) {
            this.options.buttonLabels = {
                accept: 'Ok',
                cancel: 'Avbryt',
            };
        }

        this.findNonLockedDate();
    }

    private findNonLockedDate() {
        const requests = [];
        requests.push(this.companySettingsService.getCompanySettings());

        // if the journalentryid is defined, get the minimum dates used on journalentrylines
        // for that journalentry - this is used to give the user better information about
        // what will happen if any of the dates are from before one of the lockdates
        if (this.options && this.options.data && this.options.data.JournalEntryID) {
            requests.push(this.journalEntryService.getMinDatesForJournalEntry(this.options.data.JournalEntryID));
            requests.push(this.journalEntryService.Get(this.options.data.JournalEntryID));
        }

        forkJoin<any[]>(requests).subscribe((results) => {
            const companySettings = <CompanySettings>results[0];

            let minVatDate = null;
            let minFinancialDate = null;
            let journalEntryNumber = null;

            if (results.length > 1 && results[1]) {
                minVatDate = (<any>results[1]).MinVatDate;
                minFinancialDate = (<any>results[1]).MinFinancialDate;
                journalEntryNumber = (<any>results[1]).JournalEntryNumber;
            }

            let message: string;

            this.vatLockedDateReformatted = companySettings.VatLockedDate
                ? rigDate(companySettings.VatLockedDate).format('DD.MM.YYYY')
                : null;
            this.accountingLockedDateReformatted = companySettings.AccountingLockedDate
                ? rigDate(companySettings.AccountingLockedDate).format('DD.MM.YYYY')
                : null;

            this.showAccountingLockedInfo = false;
            this.showVatLockedDateInfo = false;

            // check if any daters
            if (
                minFinancialDate &&
                companySettings.AccountingLockedDate &&
                rigDate(minFinancialDate).startOf('day') <= rigDate(companySettings.AccountingLockedDate).startOf('day')
            ) {
                this.showAccountingLockedInfo = true;
            }
            if (
                minVatDate &&
                companySettings.VatLockedDate &&
                rigDate(minVatDate).startOf('day') <= rigDate(companySettings.VatLockedDate).startOf('day')
            ) {
                this.showVatLockedDateInfo = true;
            }

            if (this.showAccountingLockedInfo || this.showVatLockedDateInfo) {
                message =
                    'Dersom du ønsker å kreditere dette bilaget må du låse opp regnskapet for gjeldende periode først.';
                this.options.buttonLabels.cancel = 'Ok';
                this.disableCreditButton = true;
                this.showVatLockedDateInfo = false;
            }

            if (!message) {
                // set todays date if no date is set already
                message = 'Bilaget vil bli kreditert på samme dato som bilagsdatoen. ';
            }

            this.message = message;
            this.formReady = true;
        });
    }

    public accept() {
        return this.onClose.emit({ action: ConfirmActions.ACCEPT });
    }

    public reject() {
        this.onClose.emit({ action: ConfirmActions.REJECT });
    }

    public cancel() {
        this.onClose.emit({ action: ConfirmActions.CANCEL });
    }
}
