import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CompanyBankAccountModal } from '@app/components/common/modals/bank-account-modal/company-bank-account-modal';
import { CompanySettingsService } from '@app/services/common/companySettingsService';
import { ErrorService } from '@app/services/common/errorService';
import { NumberSeriesService } from '@app/services/common/numberSeriesService';
import { BankAccount, CompanySettings, NumberSeries } from '@uni-entities';
import { WizardStep } from '@uni-framework/ui/design-system/wizard/wizard';
import { IModalOptions, IUniModal } from '@uni-framework/uni-modal';
import { theme, THEMES } from 'src/themes/theme';

@Component({
    selector: 'first-invoice-wizard',
    templateUrl: './first-invoice-wizard.html',
    styleUrls: ['./first-invoice-wizard.sass'],
})
export class FirstInvoiceWizardModal implements OnInit, IUniModal {
    @Input() options: IModalOptions = {};
    @Output() onClose = new EventEmitter();

    companySettings: CompanySettings;
    numberSerie: NumberSeries;
    nextInvoiceNumber: number;
    errorMessage: string;
    supportArticleUrl = theme.supportArticleUrls?.invoicing;

    steps: WizardStep[] = [
        { label: 'Intro', value: 0 },
        { label: 'Fakturanummer', value: 1 },
        { label: 'Forfallsdager', value: 2 },
        { label: 'Betalingsinformasjon', value: 3 },
        { label: 'Firmalogo', value: 4 },
        { label: 'Start å fakturere', value: 5 },
    ];

    activeStep = 0;
    isDirty = false;
    busy = false;
    modalService;

    constructor(
        private numberSeriesService: NumberSeriesService,
        private companySettingsService: CompanySettingsService,
        private errorService: ErrorService,
    ) {}

    ngOnInit(): void {
        this.companySettings = this.options.data?.companySettings;
        this.numberSerie = this.options.data?.numberSerie;
        this.nextInvoiceNumber = this.numberSerie?.NextNumber;
        this.modalService = this.options.data?.modalService;

        this.companySettings.LogoHideField = this.companySettings.LogoHideField || 1;
    }

    next() {
        this.busy = true;
        this.onForwardStep()
            .then((response) => {
                if (response) {
                    this.errorMessage = '';
                    this.activeStep++;
                    this.isDirty = false;
                }
            })
            .finally(() => (this.busy = false));
    }

    previous() {
        this.activeStep--;
    }

    addAccount(bankAccount: BankAccount = new BankAccount()) {
        bankAccount.BankAccountType = 'company';
        // Add suggested name to account
        bankAccount.Label = bankAccount.Label || 'Driftskonto';

        this.modalService
            .open(CompanyBankAccountModal, {
                data: {
                    bankAccount: bankAccount,
                    isFirst: !this.companySettings.BankAccounts || this.companySettings.BankAccounts?.length === 0,
                },
                header: bankAccount?.ID ? 'Rediger bankkonto' : 'Legg til bankkonto',
                closeOnClickOutside: false,
            })
            .onClose.subscribe((account) => {
                if (account) {
                    this.companySettings.CompanyBankAccount = account;
                    this.companySettings.CompanyBankAccountID = account.ID;
                    this.companySettingsService.companySettingsObject = this.companySettings;
                }
            });
    }

    onForwardStep(): Promise<boolean> {
        return new Promise((resolve) => {
            switch (this.activeStep) {
                case 1:
                    if (!this.isDirty) {
                        resolve(true);
                    } else {
                        if (
                            isNaN(this.nextInvoiceNumber) ||
                            this.nextInvoiceNumber > this.numberSerie.ToNumber ||
                            this.nextInvoiceNumber < this.numberSerie.FromNumber
                        ) {
                            this.errorMessage = `Ugyldig nummer. Velg nummer innenfor nummerserieintervall: ${this.numberSerie.FromNumber} - ${this.numberSerie.ToNumber}`;
                            resolve(false);
                        } else {
                            this.numberSerie.NextNumber = this.nextInvoiceNumber;
                            this.numberSeriesService.Put(this.numberSerie.ID, this.numberSerie).subscribe(
                                () => {
                                    resolve(true);
                                },
                                (err) => {
                                    this.errorService.handle(err);
                                    resolve(false);
                                },
                            );
                        }
                    }
                    break;
                case 2:
                    if (!this.isDirty) {
                        resolve(true);
                    } else {
                        this.companySettingsService
                            .Put(1, { ID: 1, CustomerCreditDays: this.companySettings.CustomerCreditDays || 0 })
                            .subscribe(
                                () => {
                                    this.companySettingsService.companySettingsObject = this.companySettings;
                                    resolve(true);
                                },
                                (err) => {
                                    this.errorService.handle(err);
                                    this.errorMessage = 'Klarte ikke lagre kredittdager';
                                    resolve(false);
                                },
                            );
                    }
                    break;
                case 3:
                    resolve(true);
                    break;
                case 4:
                    if (!this.isDirty) {
                        resolve(true);
                    } else {
                        this.companySettingsService
                            .Put(1, { ID: 1, LogoHideField: this.companySettings.LogoHideField })
                            .subscribe(
                                () => {
                                    this.companySettingsService.companySettingsObject = this.companySettings;
                                    resolve(true);
                                },
                                (err) => {
                                    this.errorService.handle(err);
                                    this.errorMessage = 'Klarte ikke lagre logovalg for blankett';
                                    resolve(false);
                                },
                            );
                    }
                    break;
                case 5:
                    this.onClose.emit(true);
                    break;
            }

            resolve(true);
        });
    }

    logoFileChanged(files: Array<any>) {
        if (files && files.length > 0 && this.companySettings.LogoFileID !== files[files.length - 1].ID) {
            // update logourl in company object
            this.companySettings.LogoFileID = files[files.length - 1].ID;
            this.companySettingsService
                .PostAction(1, 'update-logo', `logoFileId=${this.companySettings.LogoFileID}`)
                .subscribe(
                    () => {},
                    (err) => {
                        console.error('Klarte ikke laste opp');
                    },
                );
        }
    }

    close() {
        this.onClose.emit(false);
    }
}
