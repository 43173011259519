import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { LiquidityTableDTO, DetailsDTO } from '@uni-entities';
import { DashboardDataService } from '../../../dashboard-data.service';
import { UniModalService } from '@uni-framework/uni-modal';
import { LiquidityPaymentModal } from './payment-modal/liquidity-payment-modal';
import { theme } from 'src/themes/theme';

@Component({
    selector: 'liquidity-widget',
    templateUrl: './liquidity.html',
    styleUrls: ['./liquidity.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LiquidityWidget {
    viewMode: 'table' | 'chart' = 'table';

    loading = true;
    hasData = false;

    chartConfig;

    bankBalanceText: string;
    bankBalance: number;

    periodData: DetailsDTO[];

    constructor(
        private cdr: ChangeDetectorRef,
        private modalService: UniModalService,
        private dataService: DashboardDataService,
    ) {}

    ngOnInit() {
        this.loadDataAndInitChart();
    }

    loadDataAndInitChart(ignoreCache?: boolean) {
        const endpoint = '/api/biz/liquiditypayment?action=getLiquidityTableData';

        this.dataService
            .get(endpoint, ignoreCache)
            .pipe(
                catchError((err) => {
                    console.error(err);
                    return of(null);
                }),
            )
            .subscribe((data: LiquidityTableDTO) => {
                this.hasData = !!data;

                if (this.hasData) {
                    if (data.BankBalanceRefferance === 0) {
                        this.bankBalanceText = 'Kunne ikke hente banksaldo';
                    } else if (data.BankBalanceRefferance === 1) {
                        this.bankBalanceText = 'Saldo hentet fra bankkonto: ';
                        this.bankBalance = data.BankBalance;
                    } else {
                        this.bankBalanceText = 'Saldo hentet fra hovedbokskonto: ';
                        this.bankBalance = data.BankBalance;
                    }

                    this.periodData = data.Period || [];
                    this.chartConfig = this.getChartConfig();
                }

                this.loading = false;
                this.cdr.markForCheck();
            });
    }

    addCustomPayment() {
        this.modalService.open(LiquidityPaymentModal).onClose.subscribe((changes) => {
            if (changes) {
                this.loadDataAndInitChart(true);
            }
        });

        // this.modalService.open(CustomPaymentModal, {
        //     data: { isNew: isNew }
        // }).onClose.subscribe(res => {
        //     if (res) {
        //         this.loadDataAndInitChart(true);
        //     }
        // });
    }

    private getChartConfig() {
        return {
            type: 'line',
            data: {
                labels: ['I dag', '7 dager', '14 dager', '30 dager'],
                datasets: [
                    {
                        label: 'Resultat',
                        data: this.periodData.map((item) => item.Liquidity || 0),
                        backgroundColor: theme.widgets.primary,
                        borderColor: theme.widgets.primary,
                        borderWidth: 4,
                        fill: false,
                        options: {
                            fill: false,
                        },
                        pointBorderColor: 'transparent',
                        pointBackgroundColor: 'transparent',
                    },
                ],
            },
            options: {
                scaleShowVerticalLines: false,
                plugins: {
                    legend: { display: false },
                },
                scales: {
                    y: {
                        ticks: {
                            maxTicksLimit: 6,
                            callback: function (value) {
                                if (value === 0 || (value < 999 && value > -999)) {
                                    return value;
                                } else if (value > -1000000 && value < 1000000) {
                                    return value / 1000 + 'k';
                                } else if (value <= -1000000 || value >= 1000000) {
                                    return value / 1000000 + 'm';
                                } else {
                                    return value;
                                }
                            },
                        },
                    },
                    x: {
                        grid: {
                            display: false,
                            drawBorder: false,
                        },
                    },
                },
            },
        };
    }
}
