import { Component, Input } from '@angular/core';
import { InvoiceHourService } from '@app/services/timetracking/invoice-hours.service';
import { UniTableConfig, UniTableColumn, UniTableColumnType } from '@uni-framework/ui/unitable';
import { IWizardOptions, WizardSource } from './wizardoptions';

@Component({
    selector: 'workitem-transfer-wizard-filter',
    template: `
        <ag-grid-wrapper
            *ngIf="initialized"
            [resource]="data"
            [config]="tableConfig"
            (rowSelectionChange)="selectedRows = $event"
        >
        </ag-grid-wrapper>
    `,
})
export class WorkitemTransferWizardFilter {
    @Input() public options: IWizardOptions;

    tableConfig: UniTableConfig;
    initialized = false;

    selectedRows: any[];
    data: any[];

    constructor(private invoiceHourService: InvoiceHourService) {}

    public refresh() {
        this.initialized = true;
        this.invoiceHourService.getGroupedInvoicableHours(this.options).subscribe((rows) => (this.data = rows || []));
        this.tableConfig = this.createTableConfig();
    }

    canProceed(): { ok: boolean; msg?: string } {
        if (!this.selectedRows?.length) {
            return { ok: false, msg: 'Du har ikke valgt noen som kan overføres.' };
        }

        if (this.options.source === WizardSource.ProjectHours && this.selectedRows.some((row) => !row.CustomerID)) {
            return { ok: false, msg: 'Prosjekt må ha knytning mot kunde for å kunne overføres.' };
        }

        return { ok: true };
    }

    private createTableConfig(): UniTableConfig {
        let cols = [];

        switch (this.options.source) {
            default:
            case WizardSource.CustomerHours:
                cols = [
                    new UniTableColumn('CustomerID', 'Nr.', UniTableColumnType.Number).setVisible(false),
                    new UniTableColumn('CustomerNumber', 'Kundenr.'),
                    new UniTableColumn('CustomerName', 'Navn'),
                ];
                break;

            case WizardSource.OrderHours:
                cols = [
                    new UniTableColumn('OrderID', 'Nr.', UniTableColumnType.Number).setVisible(false),
                    new UniTableColumn('OrderNumber', 'Ordrenr.'),
                    new UniTableColumn('CustomerName', 'Navn'),
                ];
                break;

            case WizardSource.ProjectHours:
                cols = [
                    new UniTableColumn('ProjectID', 'Nr.', UniTableColumnType.Number).setVisible(false),
                    new UniTableColumn('ProjectNumber', 'Prosjektnr.'),
                    new UniTableColumn('ProjectName', 'Navn'),
                    new UniTableColumn('CustomerName', 'Kunde'),
                ];
                break;
        }

        cols.push(
            new UniTableColumn('SumMinutes', 'Timer', UniTableColumnType.Number)
                .setTemplate((row) => (row.SumMinutes ? row.SumMinutes / 60 : 0).toString())
                .setNumberFormat({ decimalLength: 2, decimalSeparator: ',', thousandSeparator: ' ' }),
        );

        return new UniTableConfig('timetracking.transfer-wizard-filter', false, false)
            .setColumns(cols)
            .setMultiRowSelect(true, !!this.options.filterByUserID);
    }
}
