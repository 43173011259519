import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { CustomerInvoiceService } from '@app/services/sales/customerInvoiceService';
import { Subscription } from 'rxjs';

@Component({
    selector: 'top-ten-customers',
    templateUrl: './top-ten-customers.html',
    styleUrls: ['./top-ten-customers.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopTenCustomersWidget {
    year = new Date().getFullYear();
    years = [this.year, this.year - 1, this.year - 2];

    dataSubscription: Subscription;
    loading = true;
    hasData = false;
    includeMva: boolean = true;

    data: {
        ID: number;
        CustomerNumber: number;
        Name: string;
        SumThisYear: number;
        SumLastYear: number;
        SumUnpaid: number;
    }[];

    constructor(
        private cdr: ChangeDetectorRef,
        private invoiceService: CustomerInvoiceService,
        private router: Router,
    ) {}

    ngOnInit() {
        this.loadData();
    }

    ngOnDestroy() {
        this.dataSubscription?.unsubscribe();
    }

    private loadData() {
        this.dataSubscription?.unsubscribe();
        this.dataSubscription = this.invoiceService.getTopTenCustomersWidgetData(this.year).subscribe(
            (res) => {
                this.data = (res || []).sort((a, b) => (b.SumThisYear || 0) - (a.SumThisYear || 0));
                this.loading = false;
                this.cdr.markForCheck();
            },
            (err) => {
                console.error(err);
                this.loading = false;
                this.cdr.markForCheck();
            },
        );
    }

    goToCustomer(item) {
        this.router.navigateByUrl('/sales/customer/' + item.ID);
    }

    onYearSelected(year) {
        this.year = year;
        this.loadData();
    }
}
