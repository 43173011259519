<section role="dialog" class="uni-modal uni-redesign" style="width: 37.5rem">
    <header>{{ editMode ? 'Rediger kontakt' : 'Opprett ny kontakt' }}</header>
    <section class="input-grid">
        <label class="uni-label label-above">
            <span>Navn</span>
            <input
                type="text"
                uniAutoFocus
                [(ngModel)]="contact.Info.Name"
                [class.error]="missingName && !contact.Info.Name"
            />
            <section class="required" [ngClass]="missingName ? 'error' : ''">
                <i class="material-icons color-c2a">info</i>
                <span>Påkrevd</span>
            </section>
        </label>
        <label class="uni-label label-above">
            <span>Rolle</span>
            <input type="text" [(ngModel)]="contact.Role" />
        </label>
        <label class="uni-label label-above">
            <span>Telefon</span>
            <input type="text" [(ngModel)]="contact.Info.DefaultPhone.Number" [class.error]="invalidPhoneNumber" />
            <section *ngIf="invalidPhoneNumber" class="required" [ngClass]="'error'">
                <span>Vennligst skriv inn et gyldig telefonnummer.</span>
            </section>
        </label>
        <label class="uni-label label-above">
            <span>E-post</span>
            <input type="text" [(ngModel)]="contact.Info.DefaultEmail.EmailAddress" [class.error]="invalidEmail" />
            <section *ngIf="invalidEmail" class="required" [ngClass]="'error'">
                <span>Vennligst skriv inn en gyldig e-postaddresse.</span>
            </section>
        </label>
    </section>
    <footer>
        <button (click)="cancel()" class="secondary" [class.pull-left]="editMode">Avbryt</button>

        <button *ngIf="editMode" class="secondary bad" (click)="deleteContact()">
            <i class="material-icons-outlined">delete</i>
            <span style="padding-left: 6px">Slett</span>
        </button>

        <button class="c2a" (click)="confirm()">
            {{ editMode ? 'Lagre endringer' : 'Opprett kontakt' }}
        </button>
    </footer>
</section>
