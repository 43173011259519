import { Component, ChangeDetectorRef, ChangeDetectionStrategy, Input } from '@angular/core';
import { WidgetDefinition } from './models';
import { WIDGET_DEFINITIONS } from './widgets';

import { UniModalService } from '@uni-framework/uni-modal';
import { WidgetSelectorDialog } from './widget-selector-dialog/widget-selector-dialog';
import { DashboardDataService } from './dashboard-data.service';
import { StartupTaskService, STARTUP_TASK_VIEWMODE } from '../startup-task/startup-task-service';
import { cloneDeep } from 'lodash-es';

import Muuri from 'muuri';

import { FeaturePermissionService } from '@app/featurePermissionService';
import { theme, THEMES } from 'src/themes/theme';
import { BrowserStorageService } from '@uni-framework/core/browserStorageService';
import { CMSService } from '@app/services/cms/cms-service';
import { CompanySettingsService } from '@app/services/common/companySettingsService';

export interface DashboardConfig {
    storageKey: string;
    header: string;
    layout: string[] | ((contractType: string) => string[]);
    hideSurveyButton?: boolean;
    hideHeader?: boolean;
}

@Component({
    selector: 'dashboard',
    templateUrl: './dashboard.html',
    styleUrls: ['./dashboard.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardNew {
    @Input() config: DashboardConfig;

    gridInstance: Muuri;

    defaultLayout: string[];
    currentLayout: string[];
    layoutBackup: string[];

    widgetDefinitions: WidgetDefinition[];
    editMode = false;
    hasAccessToCompleteStartupTasks: boolean;
    isInitialLoadComplete = false;

    isUE = theme.theme === THEMES.UE;

    onDragEnd = () => {
        try {
            const layout: string[] = this.gridInstance.getItems().map((item) => {
                return item.getElement().getAttribute('data-id');
            });

            this.currentLayout = layout;
            this.widgetDefinitions = this.widgetDefinitions.map((widgetDef) => {
                widgetDef['_index'] = layout.findIndex((widgetName) => widgetName === widgetDef.name);
                return widgetDef;
            });

            this.cdr.markForCheck();
        } catch (e) {
            console.error(e);
        }
    };

    constructor(
        private permissionService: FeaturePermissionService,
        private cdr: ChangeDetectorRef,
        private modalService: UniModalService,
        private dataService: DashboardDataService,
        public startupTaskService: StartupTaskService,
        private browserStorageService: BrowserStorageService,
        private companySettingsService: CompanySettingsService,
        public cmsService: CMSService,
    ) {
        // Clear dataService cache on init. This means the dashboard will always
        // show "fresh" data, but the service cache still helps avoid too many requests
        // when editing (adding/removing widgets), changing filters etc.
        this.dataService.invalidateCache();
        this.startupTaskService?.viewMode?.next(
            +this.browserStorageService.getItemFromCompany(STARTUP_TASK_VIEWMODE) || 1,
        );
        this.hasAccessToCompleteStartupTasks = this.startupTaskService.checkAccessToCompleteStartupTasks();
    }

    ngOnChanges() {
        if (this.config) {
            this.defaultLayout =
                typeof this.config.layout === 'function'
                    ? this.config.layout(this.permissionService.packageName)
                    : this.config.layout;

            this.currentLayout = cloneDeep(this.getSavedLayout() || this.defaultLayout);
            this.initGrid();
        }
    }

    ngOnDestroy() {
        try {
            this.gridInstance?.off('dragReleaseEnd', this.onDragEnd);
            this.gridInstance?.destroy(true);
        } catch (e) {
            console.error(e);
        }
    }

    initGrid() {
        if (this.gridInstance) {
            this.gridInstance.off('dragReleaseEnd', this.onDragEnd);
            this.gridInstance.destroy();
        }

        this.widgetDefinitions = this.getWidgetDefinitions(this.currentLayout);

        // If there is a saved version of the layout we have to remove the cms widget
        // It could be changed, removed or resized..
        let cmsWidgetIndex = this.widgetDefinitions.findIndex((wd) => wd.name === 'CMS_WIDGET');
        if (cmsWidgetIndex !== -1) {
            this.widgetDefinitions.splice(cmsWidgetIndex, 1);
            this.currentLayout.splice(cmsWidgetIndex, 1);
        }

        const renderDashboard = () => {
            this.isInitialLoadComplete = true;

            this.cdr.markForCheck();
            setTimeout(() => {
                this.gridInstance = new Muuri('#widget-grid', {
                    dragEnabled: this.editMode,
                    layout: {
                        rounding: false,
                        fillGaps: true,
                    },
                });

                this.gridInstance.refreshItems().layout();
                this.gridInstance.on('dragReleaseEnd', this.onDragEnd);
            });
        };

        if (this.isInitialLoadComplete) {
            renderDashboard();
        } else {
            // This timeout is unlikely to run, but it's here in case the CMS requests
            // takes longer than expected to complete. In that case we render the dashboard
            // without a CMS widget first, and then re-render later.
            let cmsMaxWaitTimeout = setTimeout(() => {
                renderDashboard();
            }, 500);

            let renderDashboardAndClearTimeout = () => {
                clearInterval(cmsMaxWaitTimeout);
                renderDashboard();
            };

            const loadCMSValues = () => {
                this.cmsService.getCMSData('widget').subscribe({
                    next: (data) => {
                        if (data) {
                            // Dont show widgets user has hidden
                            if (!this.cmsService.closedWidgets.includes(data._id)) {
                                this.cmsService.widget.next(data);

                                const widgetDef = WIDGET_DEFINITIONS.find((wd) => wd.name === 'CMS_WIDGET');
                                widgetDef.size = data.size || 'large';
                                this.widgetDefinitions.unshift(widgetDef);
                                this.currentLayout.unshift('CMS_WIDGET');
                            }

                            renderDashboardAndClearTimeout();
                        } else {
                            // If no data and not initialized, lets clear the timeout to run instantly
                            !this.isInitialLoadComplete && renderDashboardAndClearTimeout();
                        }
                    },
                    error: () => {
                        // If error and not initialized, lets clear the timeout to run instantly
                        !this.isInitialLoadComplete && renderDashboardAndClearTimeout();
                    },
                });
            };

            this.companySettingsService.getCompanySettings().subscribe({
                next: (cs) => {
                    this.cmsService.companySettings = cs;
                    loadCMSValues();
                },
                error: () => loadCMSValues(),
            });
        }
    }

    startEdit() {
        let cmsWidgetIndex = this.widgetDefinitions.findIndex((wd) => wd.name === 'CMS_WIDGET');
        if (cmsWidgetIndex !== -1) {
            this.widgetDefinitions.splice(cmsWidgetIndex, 1);
            this.currentLayout.splice(cmsWidgetIndex, 1);
        }

        this.layoutBackup = [...this.currentLayout];
        this.editMode = true;
        this.initGrid();
    }

    stopEdit() {
        this.editMode = false;
        this.isInitialLoadComplete = false;
        this.initGrid();
    }

    removeWidget(index: number) {
        const item = this.gridInstance.getItems(index);
        this.currentLayout.splice(index, 1);
        this.widgetDefinitions.splice(index, 1);
        this.gridInstance.remove(item, { removeElements: true });

        this.cdr.markForCheck();
    }

    saveLayoutChanges() {
        const isDefaultLayout =
            this.currentLayout?.length === this.defaultLayout?.length &&
            this.currentLayout.every((item, index) => item === this.defaultLayout[index]);

        if (isDefaultLayout) {
            /*
                If the user tries saving a layout that is identical to the default,
                we should just reset and remove any localStorage data.
                This way the user will get updates we might make to the default later.
            */
            this.resetToDefaultLayout();
        } else {
            localStorage.setItem(this.config.storageKey, JSON.stringify(this.currentLayout));
        }

        this.startupTaskService.setAndStoreViewMode(
            +JSON.parse(this.browserStorageService.getItemFromCompany(STARTUP_TASK_VIEWMODE) || null) || 1,
        );
        this.stopEdit();
    }

    discardLayoutChanges() {
        this.currentLayout = this.layoutBackup;
        this.stopEdit();
    }

    resetToDefaultLayout() {
        localStorage.removeItem(this.config.storageKey);
        this.currentLayout = this.defaultLayout;
        this.stopEdit();
    }

    private getWidgetDefinitions(layout: string[]) {
        return layout
            .map((widgetName, index) => {
                const widgetDef = cloneDeep(WIDGET_DEFINITIONS.find((def) => def.name === widgetName));
                widgetDef['_index'] = index;
                return widgetDef;
            })
            .filter((widgetDef) => {
                return !!widgetDef && this.dataService.canShowWidget(widgetDef);
            });
    }

    private getSavedLayout() {
        try {
            const savedLayout = localStorage.getItem(this.config.storageKey);
            if (savedLayout) {
                return JSON.parse(savedLayout).filter((layout: string) =>
                    WIDGET_DEFINITIONS.some((widget) => widget.name === layout),
                );
            }
        } catch (e) {
            console.error(e);
        }
    }

    openWidgetSelector() {
        this.modalService
            .open(WidgetSelectorDialog, {
                data: this.widgetDefinitions,
            })
            .onClose.subscribe((layout) => {
                if (layout) {
                    this.currentLayout = layout;
                    this.initGrid();
                }
            });
    }
}
