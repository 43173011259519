<widget-empty-state *ngIf="missingData" class="horizontal">
    Det ser ikke ut som vi har mottatt saldoinformasjon. Dersom du nylig har aktivert bankintegrasjonen kan det ta litt
    tid før systemet får data.
</widget-empty-state>

<ng-container *ngIf="!missingData && accountBalanceItems?.length">
    <widget-chart class="chart" [chartConfig]="chartConfig"></widget-chart>

    <section class="accounts">
        <simple-table>
            <thead>
                <tr>
                    <th>Konto</th>
                    <th class="align-right">Saldo</th>
                    <th></th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let item of accountBalanceItems; let idx = index">
                    <td>
                        <span class="pie-indicator" [style.background]="colors[idx]"></span>
                        {{ item.accountName || (item.accountNumber | uninumberformat: 'bankacct') }}
                        <uni-tooltip
                            *ngIf="item.isMainAccountBalance"
                            [type]="'info'"
                            [text]="
                                'Denne bankkontoen har ikke integrasjon mot banken. Saldo er derfor hentet fra bankkontoens hovedbokskonto: ' +
                                item.MainAccountName
                            "
                        >
                        </uni-tooltip>
                    </td>

                    <td class="align-right">
                        {{
                            (item.isMainAccountBalance ? item.balanceBooked : item.balanceAvailable)
                                | uninumberformat: 'money'
                        }}
                    </td>
                    <td class="aligh-right">
                        <button
                            (click)="refresh(item.bankAccountID)"
                            class="icon-button small hover-c2a"
                            *ngIf="item.bankAccountID"
                            [disabled]="refreshBusy"
                            [attr.aria-busy]="refreshBusy"
                        >
                            <i class="material-icons">refresh</i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </simple-table>
    </section>

    <section class="reconciliation-info alert warn">
        <ng-container *ngIf="!showAllIsUpdated">
            <span class="button-wrapper" *ngIf="approvePaymentsCount > 0">
                {{ 'Betalinger til godkjenning' }}

                <button (click)="gotoApprovals()" class="secondary">Til godkjenning</button>
            </span>

            <span class="button-wrapper" *ngIf="failedPaymentCount !== -1">
                {{ failedPaymentCount ? failedPaymentCount + ' utbetalinger avvist' : 'Utbetalinger' }}

                <button *ngIf="failedPaymentCount > 0" (click)="gotoFailedPayments()" class="secondary">
                    Til utbetalinger
                </button>
                <i *ngIf="failedPaymentCount === 0" class="material-icons">task_alt</i>
            </span>

            <span class="button-wrapper" *ngIf="incomingPaymentCount !== -1">
                {{ incomingPaymentCount ? incomingPaymentCount + ' innbetalinger uten kobling' : 'Innbetalinger' }}

                <button *ngIf="incomingPaymentCount > 0" (click)="gotoIncomingPayments()" class="secondary">
                    Til innbetaling
                </button>
                <i *ngIf="incomingPaymentCount === 0" class="material-icons">task_alt</i>
            </span>

            <span class="button-wrapper" *ngIf="reconciliationCount !== -1 && !needsToSetBankAgreementStartDate">
                {{ reconciliationCount ? reconciliationCount + ' poster å avstemme' : 'Bankavstemming' }}

                <button *ngIf="reconciliationCount > 0" routerLink="/bank/reconciliation" class="secondary">
                    Avstem bank
                </button>
                <i *ngIf="reconciliationCount === 0" class="material-icons">task_alt</i>
            </span>

            <span class="button-wrapper" *ngIf="needsToSetBankAgreementStartDate">
                Startdato for bankavstemming

                <button (click)="openSetStartDateModal()" class="secondary">Sett startdato</button>
            </span>
        </ng-container>

        <ng-container *ngIf="showAllIsUpdated">
            <img src="config/dist/theme/assets/bankbalance_widget_illustration.svg" alt="Bank balance illustration" />
            <span style="margin: auto 0">Alle dine bankdata er oppdatert</span>
        </ng-container>
    </section>
</ng-container>
