import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { IInfoBannerConfig } from '@app/components/common/info-banner/info-banner';
import { theme } from 'src/themes/theme';
import { JournalEntryService } from '@app/services/accounting/journalEntryService';
import { CustomerInvoiceService } from '@app/services/sales/customerInvoiceService';

@Component({
    selector: 'invoiced',
    templateUrl: './invoiced.html',
    styleUrls: ['./invoiced.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoicedWidget {
    dataSubscription: Subscription;
    chartConfig;
    colors = [theme.widgets.primary, theme.widgets.bar_foreground];

    loading = true;
    hasData = false;

    labels: string[];
    invoiced: number[];
    paid: number[];

    sumInvoiced: number;
    sumPaid: number;
    sumOverdue: number;

    tooltip;

    infoBannerConfig: IInfoBannerConfig;

    constructor(
        private cdr: ChangeDetectorRef,
        private journalEntryService: JournalEntryService,
        private invoiceService: CustomerInvoiceService,
    ) {}

    ngOnInit() {
        this.dataSubscription = this.invoiceService.getInvoicedWidgetData().subscribe(([invoiceSums, overdue]) => {
            this.hasData = invoiceSums?.length && invoiceSums.some((sum) => !!sum.TaxInclusiveAmount);

            if (this.hasData) {
                // Since we're showing last 12 months we need to reorganize the items.
                // E.g if current month is July then the first bar of the chart will be July last year
                const endMonth = new Date().getMonth() + 1;
                const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Des'];
                labels.push(...labels.splice(0, endMonth));
                this.labels = labels;

                invoiceSums.push(...invoiceSums.splice(0, endMonth));

                this.invoiced = invoiceSums.map((sum) => sum.TaxInclusiveAmount);
                this.paid = invoiceSums.map((sum) => sum.TaxInclusiveAmount - sum.RestAmount);

                this.sumInvoiced = this.invoiced.reduce((sum, value) => (sum += value || 0), 0);
                this.sumPaid = this.paid.reduce((sum, value) => (sum += value || 0), 0);
                this.sumOverdue = overdue[0]?.Sum;

                this.chartConfig = this.getChartConfig();
            }

            this.loading = false;
            this.cdr.markForCheck();
        });

        this.journalEntryService.shouldRegisterForMandetoryTax().subscribe((res: any) => {
            if (res && res?.shouldRegister) {
                this.infoBannerConfig = res.infoBannerConfig;
                this.cdr.markForCheck();
            }
        });
    }

    sortList(a, b) {
        return a.Periode > b.Periode ? 1 : -1;
    }

    ngOnDestroy() {
        this.dataSubscription?.unsubscribe();
    }

    getChartConfig() {
        return {
            type: 'roundedBarChart',
            data: {
                labels: this.labels,
                datasets: [
                    {
                        label: 'Innbetalt',
                        data: this.paid,
                        backgroundColor: this.colors[0],
                        barThickness: 16,
                    },
                    {
                        label: 'Fakturert',
                        data: this.invoiced,
                        backgroundColor: this.colors[1],
                        barThickness: 16,
                    },
                ],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: { display: false },
                    tooltip: {
                        enabled: false,
                        mode: 'index',
                        position: 'nearest',
                        external: (context) => {
                            const tooltip = context.tooltip;
                            if (tooltip.opacity && tooltip.dataPoints?.length) {
                                const data = tooltip.dataPoints;
                                const paid = data[0].raw || 0;
                                const invoiced = data[1].raw || 0;

                                this.tooltip = {
                                    invoiced: invoiced,
                                    paid: paid,
                                    unpaid: invoiced - paid,
                                    style: {
                                        top: tooltip.y + 'px',
                                        left: tooltip.x + 'px',
                                        opacity: '1',
                                    },
                                };
                            } else {
                                this.tooltip = undefined;
                            }

                            this.cdr.markForCheck();
                        },
                    },
                },
                scales: {
                    y: {
                        grid: { borderDash: [4, 4] },
                        ticks: {
                            maxTicksLimit: 8,
                            callback: function (value) {
                                if (value === 0 || (value < 999 && value > -999)) {
                                    return value;
                                } else if (value > -1000000 && value < 1000000) {
                                    return value / 1000 + 'k';
                                } else if (value <= -1000000 || value >= 1000000) {
                                    return value / 1000000 + 'm';
                                } else {
                                    return value;
                                }
                            },
                        },
                    },
                    x: {
                        stacked: true,
                        grid: {
                            display: false,
                            drawBorder: false,
                        },
                    },
                },
            },
        };
    }
}
