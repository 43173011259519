import { NgModule } from '@angular/core';
import { NewAccountModalV2 } from '@app/components/common/modals/new-account-modal-v2/new-account-modal-v2';
import { NewAccountModalV2Service } from '@app/components/common/modals/new-account-modal-v2/new-account-modal-v2.service';
import { UniFrameworkModule } from '@uni-framework/frameworkModule';
import { LayoutModule } from '@app/components/layout/layoutModule';
import { CustomerAndSupplierForm } from '@app/components/common/modals/new-account-modal-v2/customer-and-supplier-form/customer-and-supplier-form';
import { GeneralLedgerAccountForm } from '@app/components/common/modals/new-account-modal-v2/ledger-account-form/general-ledger-account-form';

@NgModule({
    imports: [UniFrameworkModule, LayoutModule],
    declarations: [NewAccountModalV2, CustomerAndSupplierForm, GeneralLedgerAccountForm],
    providers: [NewAccountModalV2Service],
    exports: [NewAccountModalV2],
})
export class NewAccountModalV2Module {}
