import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PayrollRun, PostingSummaryDraft, ReportDefinition } from '@uni-entities';
import { IModalOptions, IUniModal, UniModalService, UniPreviewModal } from '@uni-framework/uni-modal';
import { AutocompleteOptions } from '@uni-framework/ui/autocomplete/autocomplete';
import { ISelectConfig } from '@uni-framework/ui/uniform';
import { SalaryBookingType, PayrollRunService } from '@app/services/salary/payrollRun/payroll-run.service';
import { FinancialYearService } from '@app/services/accounting/financialYearService';

@Component({
    template: `
        <section role="dialog" class="uni-modal" style="width: 45rem">
            <header>{{ report.Name }}</header>

            <article>
                <label class="uni-label label-left">
                    <span>Lønnskjøring</span>
                    <autocomplete [options]="autocompleteOptions" [(value)]="payrollRun"></autocomplete>
                </label>

                <label *ngIf="showBookingTypeSelect" class="uni-label label-left">
                    <span>Type bilag</span>
                    <uni-select
                        [items]="bookingTypes"
                        [(value)]="selectedBookingType"
                        [config]="{ displayProperty: 'name' }"
                    ></uni-select>
                </label>
            </article>

            <footer>
                <button class="secondary" (click)="onClose.emit()">Avbryt</button>
                <button class="c2a" (click)="showReport()">Vis rapport</button>
            </footer>
        </section>
    `,
})
export class SalaryPaymentListModal implements IUniModal {
    @Input() options: IModalOptions = {};
    @Output() onClose = new EventEmitter();

    year: number;
    report: ReportDefinition;

    payrollRun: PayrollRun;

    showBookingTypeSelect: boolean;
    bookingTypes = [
        { type: SalaryBookingType.Dimensions, name: 'Bilag med dimensjoner(prosjekt/avdeling) kun på resultatkontoer' },
        {
            type: SalaryBookingType.DimensionsAndBalance,
            name: 'Bilag med dimensjoner(prosjekt/avdeling)på resultat- og balansekontoer',
        },
        { type: SalaryBookingType.NoDimensions, name: 'Bilag uten dimensjoner(prosjekt/avdeling)' },
    ];

    selectedBookingType = this.bookingTypes[0];

    selectOptions: ISelectConfig = {
        displayProperty: 'name',
    };

    autocompleteOptions: AutocompleteOptions = {
        displayFunction: (item) => `${item?.ID} - ${item?.Description}`,
        lookup: (query) => {
            const filter = `year(PayDate) eq ${this.year} and (startswith(ID, '${query}') or contains(Description, '${query}'))`;
            return this.payrollRunService.GetAll(`filter=${filter}&top=50`);
        },
    };

    constructor(
        private modalService: UniModalService,
        private payrollRunService: PayrollRunService,
        private financialYearService: FinancialYearService,
    ) {}

    ngOnInit() {
        this.year = this.financialYearService.getActiveYear();
        this.report = this.options?.data || {};

        this.showBookingTypeSelect = this.report.Name === 'Konteringssammendrag';

        this.payrollRunService.getLatestSettledRun(this.year).subscribe((payrollRun) => {
            this.payrollRun = payrollRun;

            if (this.showBookingTypeSelect) {
                this.payrollRunService.getPostingSummaryDraft(payrollRun.ID).subscribe((draft) => {
                    const bookingType = this.getBookingTypeFromDraft(draft);
                    this.selectedBookingType =
                        this.bookingTypes.find((item) => item.type === bookingType) || this.bookingTypes[0];
                });
            }
        });
    }

    showReport() {
        this.report['parameters'] = [{ Name: 'RunID', value: this.payrollRun?.ID }];
        if (this.showBookingTypeSelect) {
            this.report['parameters'].push(
                ...[
                    { Name: 'BookingType', value: this.selectedBookingType.type },
                    { Name: 'Refresh', value: true },
                ],
            );
        }

        this.modalService.open(UniPreviewModal, { data: this.report });
        this.onClose.emit();
    }

    private getBookingTypeFromDraft(draft: PostingSummaryDraft): SalaryBookingType {
        if (draft?.draftBasic) {
            return SalaryBookingType.NoDimensions;
        }

        if (draft?.draftWithDimsOnBalance) {
            return SalaryBookingType.DimensionsAndBalance;
        }

        return SalaryBookingType.Dimensions;
    }
}
