<section class="header">
    <h1>Ansatte per stillingskode</h1>
</section>

<section class="content">
    <widget-empty-state *ngIf="!loading && !hasData">
        Det ser ikke ut som du har registrert noen ansatte.
    </widget-empty-state>

    <widget-pie-chart *ngIf="!loading && hasData" [chartConfig]="chartConfig"></widget-pie-chart>
</section>
