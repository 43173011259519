<section role="dialog" class="uni-modal" style="width: 60vw">
    <header>{{ options?.data.title }}</header>
    <article class="accrual-split-view">
        <section class="accrual-form">
            <uni-form #form [config]="{}" [fields]="fields$" [model]="model$" (changeEvent)="onFormChange($event)">
            </uni-form>
            <small *ngIf="periodError.periodNotValidError" class="d-block color-bad">
                Vennligst velg riktige perioder
            </small>
            <small *ngIf="periodError.periodLockedError" class="d-block color-bad"> Periode er sperret </small>
        </section>
    </article>
    <footer>
        <button (click)="onClose.emit()" class="secondary pull-left">Avbryt</button>
        <button
            (click)="onClose.emit({ action: 'remove' })"
            *ngIf="!buttonsDisabled && !hideRemoveButton"
            class="secondary bad"
        >
            Fjern periodisering
        </button>
        <button
            (click)="onSaveClick()"
            *ngIf="!buttonsDisabled"
            [disabled]="lockedDateSelected || periodError.periodNotValidError || periodError.periodLockedError"
            class="c2a"
        >
            Lagre periodisering
        </button>
    </footer>
</section>
