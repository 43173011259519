@if (!busy && !hidden && filteredStatuses?.length) {
    <section class="api-status-container" [class.is-persistent]="isPersistent">
        <section class="header">
            Driftsmeldinger
            @if (!isPersistent) {
                <button class="icon-button" (click)="close()">
                    <i class="material-icons">close</i>
                </button>
            }
        </section>

        <section class="api-status-list-wrapper">
            <ul class="api-status-list">
                @for (status of filteredStatuses; track status) {
                    <li class="api-status-item">
                        @if (status.Type === 0) {
                            <i class="material-icons color-bad">report_problem</i>
                        } @else if (status.Type === 1) {
                            <i class="material-icons color-warn">error_outline</i>
                        } @else {
                            <i class="material-icons color-c2a">info_outline</i>
                        }

                        <section>
                            <strong>{{ status.Service }} </strong>
                            <span class="api-status-message" [innerHtml]="status.Message"></span>
                        </section>
                    </li>
                }
            </ul>
        </section>
    </section>
}
