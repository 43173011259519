import { Component, Input } from '@angular/core';
import { StartupTaskService, StartupTask } from '../startup-task-service';
import { Router } from '@angular/router';
import { AuthService } from '@app/authService';
import { ProductService } from '@app/services/common/productService';

@Component({
    selector: 'first-product-task',
    template: `<startup-task [task]="task" (tileClicked)="runTask()"> </startup-task>`,
})
export class FirstProductTask {
    @Input()
    public task: StartupTask;

    constructor(
        private service: StartupTaskService,
        private productService: ProductService,
        private authService: AuthService,
        private router: Router,
    ) {}

    ngOnChanges(change) {
        if (change['task'] && change['task'].currentValue) {
            if (!this.task.completed) {
                this.productService
                    .GetAll(`filter=createdby eq '${this.authService.currentUser.GlobalIdentity}' &top=1`)
                    .subscribe({
                        next: (products) => {
                            if (products?.length) {
                                this.service.markTaskAsCompleted(this.task);
                            }
                        },
                    });
            }
        }
    }

    runTask() {
        this.router.navigateByUrl('/sales/products/0');
    }
}
