import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { IVacationPaySummary, VacationpayLineService } from '@app/services/salary/payrollRun/vacationpayLineService';
import { VacationPayLine } from '@uni-entities';
import { UniFrameworkModule } from '@uni-framework/frameworkModule';
import { DesignSystemModule } from '@uni-framework/ui/design-system/design-system.module';
import { FieldType, UniFieldLayout } from '@uni-framework/ui/uniform';
import { IModalOptions, IUniModal } from '@uni-framework/uni-modal';

@Component({
    selector: 'uni-vacation-pay-single-edit-modal',
    standalone: true,
    imports: [DesignSystemModule, CommonModule, UniFrameworkModule],
    templateUrl: './vacation-pay-single-edit-modal.component.html',
    styleUrl: './vacation-pay-single-edit-modal.component.sass',
})
export class VacationPaySingleEditModalComponent implements OnInit, IUniModal {
    @Output() onClose: EventEmitter<VacationPayLine> = new EventEmitter();
    @Input() options?: IModalOptions;

    busy: boolean;
    vacationPayLine: VacationPayLine;
    vacationPayLineLayout: UniFieldLayout[];
    vacationWeek6: boolean;
    activeYear: number;
    percentPayout: number;
    isWithdrawalReadOnly: boolean;

    summaryData: IVacationPaySummary = null;

    constructor(private vacationPayLineService: VacationpayLineService) {}

    ngOnInit(): void {
        if (this.options?.data) {
            this.vacationPayLine = this.options?.data?.VacationPayLine;
            this.summaryData = this.options?.data?.SummaryData;
            this.activeYear = this.options?.data?.ActiveYear;
            this.percentPayout = this.options?.data?.PercentPayout;
            this.vacationWeek6 = this.options?.data?.VacationWeek6;
            this.isWithdrawalReadOnly = this.options?.data?.PayrollRunID === 0;
            this.getColumns();
        }
    }

    change(event: SimpleChanges) {
        if (event['_Rate']) {
            this.vacationPayLine['_rateChanged'] = true;
        }
        if (event['Withdrawal']) {
            this.vacationPayLine['_withdrawalChanged'] = true;
        }

        let vacationPayLineCalculated = this.calculateVacationPay(this.vacationPayLine);
        vacationPayLineCalculated['_isDirty'] = true;
        if (!this.vacationPayLine['_withdrawalChanged']) {
            vacationPayLineCalculated.Withdrawal =
                ((vacationPayLineCalculated['_VacationPay'] - vacationPayLineCalculated.PaidVacationPay) *
                    this.percentPayout) /
                100;
        }
        this.vacationPayLine = { ...vacationPayLineCalculated };
    }

    close() {
        this.onClose.emit(null);
    }

    updateList() {
        this.onClose.emit(this.vacationPayLine);
    }

    private calculateVacationPay(vacationPay) {
        const vacBase = vacationPay.ManualVacationPayBase + vacationPay.SystemVacationPayBase;
        const limitBasicAmount = this.summaryData.basicAmount * 6;
        this.updateAndSetRate(vacationPay, vacationPay['_rateChanged']);
        if (this.vacationWeek6 && this.empOver60(vacationPay)) {
            if (vacBase > limitBasicAmount && !this.summaryData.allowOver6G) {
                vacationPay['_VacationPay'] = vacationPay.VacationPay60 =
                    this.vacationPayLineService.calcVacation(vacBase, vacationPay.Rate) +
                    this.vacationPayLineService.calcVacation(limitBasicAmount, vacationPay.Rate60 - vacationPay.Rate);
            } else {
                vacationPay['_VacationPay'] = vacationPay.VacationPay60 = this.vacationPayLineService.calcVacation(
                    vacBase,
                    vacationPay.Rate60,
                );
            }
        } else {
            vacationPay['_IncludeSixthWeek'] = 'Nei';
            vacationPay['_VacationPay'] = vacationPay.VacationPay = this.vacationPayLineService.calcVacation(
                vacBase,
                vacationPay['_Rate'],
            );
        }
        return vacationPay;
    }

    private empOver60(rowModel: VacationPayLine) {
        if (!rowModel.Employee) {
            return false;
        }
        const empAge = rowModel.Year - new Date(rowModel.Employee.BirthDate).getFullYear();
        if (empAge >= 59) {
            if (this.activeYear === rowModel.Year) {
                return true;
            } else {
                return empAge >= 60;
            }
        }
    }

    private updateAndSetRate(row: VacationPayLine, setManually: boolean) {
        const isOver60: boolean = this.empOver60(row);
        if (setManually) {
            // '_Rate'-column changed
            row.Rate = row['_Rate'];
            row.Rate60 = row['_Rate'] + this.summaryData.rate60;
        } else {
            if (isOver60 && this.vacationWeek6) {
                row['_Rate'] = row.Rate60;
            } else {
                row['_Rate'] = row.Rate;
            }
        }
        return row;
    }

    private getColumns() {
        this.vacationPayLineLayout = [
            <UniFieldLayout>{
                Property: 'SystemVacationPayBase',
                FieldType: FieldType.NUMERIC,
                Label: 'Grunnlag system',
                ReadOnly: true,
                Options: {
                    decimalLength: 2,
                    decimalSeparator: ',',
                },
            },
            <UniFieldLayout>{
                Property: 'ManualVacationPayBase',
                FieldType: FieldType.NUMERIC,
                Label: 'Grunnlag manuelt',
                Options: {
                    decimalLength: 2,
                    decimalSeparator: ',',
                },
            },
            <UniFieldLayout>{
                Property: '_IncludeSixthWeek',
                FieldType: FieldType.RADIOGROUP,
                Label: '6. ferieuke',
                ReadOnly: true,
                Options: {
                    source: [
                        { value: 'Nei', text: 'Nei' },
                        { value: 'Ja', text: 'Ja' },
                    ],
                    labelProperty: 'text',
                    valueProperty: 'value',
                    horizontal: true,
                },
            },
            <UniFieldLayout>{
                Property: '_Rate',
                FieldType: FieldType.NUMERIC,
                Label: 'Sats',
                Options: {
                    decimalLength: 2,
                    decimalSeparator: ',',
                },
            },
            <UniFieldLayout>{
                Property: 'VacationPay',
                FieldType: FieldType.NUMERIC,
                Label: 'Feriepenger',
                ReadOnly: true,
                Options: {
                    decimalLength: 2,
                    decimalSeparator: ',',
                },
            },
            <UniFieldLayout>{
                Property: 'PaidVacationPay',
                FieldType: FieldType.NUMERIC,
                Label: 'Tidligere utbetalt',
                ReadOnly: true,
                Options: {
                    decimalLength: 2,
                    decimalSeparator: ',',
                },
            },
            <UniFieldLayout>{
                Property: 'Withdrawal',
                FieldType: FieldType.NUMERIC,
                Label: 'Utbetales',
                ReadOnly: this.isWithdrawalReadOnly,
                Options: {
                    decimalLength: 2,
                    decimalSeparator: ',',
                },
            },
        ];
    }
}
