<section class="header">
    <h1>Banksaldo</h1>
</section>

<section class="content">
    <widget-empty-state
        *ngIf="onboardingState === 'none' || onboardingState === 'pending'"
        class="horizontal align-left"
    >
        For å se saldo på bankkonto trenger du en autobankavtale med banken. <br />
        <button (click)="connectBank()" class="c2a">Koble sammen bank og regnskap</button>
    </widget-empty-state>

    <bank-balance-widget-content *ngIf="onboardingState === 'complete'"></bank-balance-widget-content>
</section>
