<section class="header">
    <h1>Prosjektsammendrag: {{ currentProject?.Name }}</h1>
</section>

<section class="content" *ngIf="!noProjectError && !loading">
    <section class="accounting-grid">
        <section class="grid-part">
            <widget-chart class="chart" [chartConfig]="chartConfig"></widget-chart>
        </section>

        <section class="grid-part">
            <section class="half-part-vertical">
                <span class="part-header"> Inntekter </span>
                <span class="sum" [ngClass]="values.income > 0 ? 'good' : 'bad'" (click)="goToDetailsView(1)">
                    {{ values.income | uninumberformat: 'money' }}
                </span>
            </section>

            <section class="half-part-vertical">
                <span class="part-header"> Andre kostnader </span>
                <span class="sum" (click)="goToDetailsView(1)">
                    {{ values.costOthers | uninumberformat: 'money' }}
                </span>
            </section>
        </section>

        <section class="grid-part">
            <section class="half-part-vertical">
                <span class="part-header"> Fakturerte timer </span>
                <span class="sum" (click)="goToDetailsView(2)"> {{ invoiceHours }} </span>
            </section>

            <section class="half-part-vertical">
                <span class="part-header"> Lønnskostnad </span>
                <span class="sum" (click)="goToDetailsView(1)">
                    {{ values.costSalary | uninumberformat: 'money' }}
                </span>
            </section>
        </section>

        <section class="grid-part">
            <section class="half-part-vertical">
                <span class="part-header"> Ordrereserve </span>
                <span class="sum" [ngClass]="orderReserve > 0 ? 'good' : 'bad'" (click)="goToDetailsView(3)">{{
                    orderReserve | uninumberformat: 'money'
                }}</span>
            </section>

            <section class="half-part-vertical">
                <span class="part-header"> Fakturaer til godkjenning </span>
                <span class="sum" (click)="goToDetailsView(4)"> {{ values.forApproval }} </span>
            </section>
        </section>
    </section>
</section>

<section class="content" *ngIf="noProjectError && !loading">Ingen prosjekt valgt..</section>

<footer *ngIf="!noProjectError && !loading">
    Resultat:
    <span [ngClass]="values.resultPercentage > 0 ? 'good' : 'bad'" (click)="goToDetailsView(1)">
        {{ values.result | uninumberformat: 'money' }} ( {{ values.resultPercentage }}% )
    </span>
</footer>
