import { HttpClient } from '@angular/common/http';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Renderer2,
    SecurityContext,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@app/authService';
import { ApiMessage, ApiMessageType } from '@uni-entities';
import { parse } from 'marked';
import { rigDate } from '@app/components/common/utils/rig-date';
import { Subscription } from 'rxjs';
import { THEMES, theme } from 'src/themes/theme';

@Component({
    selector: 'api-status',
    templateUrl: './api-status.html',
    styleUrls: ['./api-status.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApiStatus {
    hidden: boolean;
    allStatuses: ApiMessage[] = [];
    filteredStatuses: ApiMessage[] = [];
    busy = true;
    routeSubscription: Subscription;
    isDnbEnv = theme.theme === THEMES.EXT02;
    isPersistent = false;

    constructor(
        private http: HttpClient,
        private cdr: ChangeDetectorRef,
        private domSanitizer: DomSanitizer,
        private router: Router,
        private authService: AuthService,
        private renderer: Renderer2,
        private hostElement: ElementRef,
    ) {
        // Creating a route Subscription
        this.routeSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                if (this.isDnbEnv) {
                    this.hidden = event.url.includes('init') && event.url !== '/init/login';
                }

                this.handleApiStatusMessages(this.allStatuses);
                this.cdr.markForCheck();
            }
        });
    }

    ngOnDestroy() {
        // Route subscription will be unsubscribed OnDestroy
        this.routeSubscription?.unsubscribe();
    }

    ngAfterViewInit() {
        this.http.get<ApiMessage[]>('/api/status').subscribe(
            (res) => {
                this.allStatuses = res;
                this.handleApiStatusMessages(res);
                this.busy = false;
                this.cdr.markForCheck();
            },
            (err) => {
                console.error(err);
                this.busy = false;
            },
        );
    }

    handleApiStatusMessages(apiMessages: ApiMessage[]) {
        // Get filtered status messages
        this.filteredStatuses = (apiMessages || [])
            .sort((a, b) => a.Type - b.Type)
            .filter((status) => {
                return this.shouldShowMessage(status);
            });

        // Sanitize status messages..
        // This needs to be typed any because the ApiMessageType is incorrect in unientites.
        // Persistent is 4, should be 3 (Comes as 3 from license-server)
        this.filteredStatuses.forEach((status: any) => {
            try {
                const decoded = decodeURI(status.Message);
                this.isPersistent = this.isPersistent || status.Type === 3;
                status.Message =
                    this.domSanitizer.sanitize(
                        SecurityContext.HTML,
                        parse(decoded).replace('<p>', '<span>').replace('</p>', '</span>'),
                    ) || '';
            } catch (e) {
                console.error(e);
            }
        });
        if (this.isPersistent) {
            this.renderer.addClass(this.hostElement.nativeElement, 'persistent');
        }
    }

    shouldShowMessage(apiMessage: ApiMessage): boolean {
        // Filter based on local storage item
        const hiddenStatusIds = localStorage.getItem('hidden-api-status-ids')?.split(',') || [];
        if (hiddenStatusIds?.includes(apiMessage.ID?.toString())) {
            return false;
        }

        // Filter based on FromDate and ToDate
        if (apiMessage.FromDate && rigDate().isSameOrBefore(rigDate(apiMessage.FromDate), 'day')) {
            return false;
        }
        if (apiMessage.ToDate && rigDate().isSameOrAfter(rigDate(apiMessage.ToDate), 'day')) {
            return false;
        }

        // Filter based on ApplicationURL
        if (apiMessage.ApplicationUrl) {
            const currentRoute = this.router.url?.split('?')[0];
            const applicationUrlSplitted = apiMessage.ApplicationUrl.split('/');
            const currentSplitted = currentRoute.split('/');

            const isMatch = applicationUrlSplitted.every((x, i) => {
                if (x === '*') return true;
                return x === currentSplitted[i];
            });

            if (!isMatch) return false;
        }

        // Filter based on ContractID
        if (apiMessage.ContractIDs) {
            const contractId = this.authService.currentUser?.License?.Company?.ContractID;
            const isMatch = apiMessage.ContractIDs?.split(',')?.includes(contractId?.toString());

            if (!this.authService.currentUser || !isMatch) return false;
        }

        // Filter based on ContractType
        if (apiMessage.ContractTypes) {
            const contractType = this.authService.currentUser?.License?.ContractType;
            const isMatch = apiMessage.ContractTypes?.split(',')?.includes(contractType?.TypeID?.toString());

            if (!this.authService.currentUser || !isMatch) return false;
        }

        // Filter based on CustomerID
        if (apiMessage.CustomerIDs) {
            const customerId = this.authService.currentUser?.License?.CustomerInfo?.CustomerID;
            const isMatch = apiMessage.CustomerIDs?.split(',')?.includes(customerId?.toString());

            if (!this.authService.currentUser || !isMatch) return false;
        }

        return true;
    }

    close() {
        let hiddenStatusIds = localStorage.getItem('hidden-api-status-ids')?.split(',') || [];

        // Filter out statusIDs that don't exist anymore
        hiddenStatusIds = hiddenStatusIds.filter((ID) => this.allStatuses.some((x) => x.ID.toString() === ID));

        // Add the currently visible statuses to the list of hidden IDs
        if (this.filteredStatuses?.length) {
            const filteredStatusIds = this.filteredStatuses?.map((x) => x.ID.toString()) || [];
            hiddenStatusIds.push(...filteredStatusIds);
        }

        localStorage.setItem('hidden-api-status-ids', hiddenStatusIds.join(','));
        this.filteredStatuses = [];
    }
}
