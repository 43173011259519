<section class="header">
    <h1>Driftsresultat</h1>

    <button #resultToggle class="secondary pull-right">
        {{ showAccumulatedResult ? 'Akkumulert resultat' : 'Månedlig resultat' }}
        <i class="material-icons">expand_more</i>
    </button>
    <dropdown-menu [trigger]="resultToggle">
        <ng-template>
            <section class="dropdown-menu-item" (click)="setShowAccumulatedResult(false)">Månedlig resultat</section>
            <section class="dropdown-menu-item" (click)="setShowAccumulatedResult(true)">Akkumulert resultat</section>
        </ng-template>
    </dropdown-menu>

    <button #yearToggle class="secondary">
        {{ year }}
        <i class="material-icons">expand_more</i>
    </button>
    <dropdown-menu [trigger]="yearToggle">
        <ng-template>
            <section class="dropdown-menu-item center" *ngFor="let year of years" (click)="onYearSelected(year)">
                {{ year }}
            </section>
        </ng-template>
    </dropdown-menu>
</section>

<section *ngIf="!loading" class="content">
    <widget-empty-state *ngIf="!hasData">
        Det ser ikke ut som du har noen inntekter eller utgifter i {{ year }}.
    </widget-empty-state>

    <ng-container *ngIf="hasData">
        <widget-chart [chartConfig]="chartConfig"></widget-chart>

        <section class="chart-tooltip" *ngIf="tooltip" [ngStyle]="tooltip.style">
            <section class="data-row">
                <span>Inntekter</span>
                <span>{{ tooltip.income | uninumberformat: 'money' }}</span>
            </section>

            <section class="data-row">
                <span>Kostnader</span>
                <span>{{ tooltip.cost | uninumberformat: 'money' }}</span>
            </section>

            <section class="data-row">
                <span>Resultat</span>
                <span>{{ tooltip.result | uninumberformat: 'money' }}</span>
            </section>
        </section>

        <section class="chart-legend">
            <section *ngFor="let item of legend" class="chart-legend-item">
                <span class="indicator" [style.background]="item.color"></span>
                <span class="label">{{ item.label }}</span>
                <span class="value">{{ item.value | uninumberformat: 'money' }}</span>
            </section>
        </section>
    </ng-container>
</section>
