import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { IModalOptions, IUniModal } from '@uni-framework/uni-modal';
import { AuthService } from '@app/authService';
import { BrowserStorageService } from '@uni-framework/core/browserStorageService';
import { CompanyService } from '@app/services/common/companyService';
import { ErrorService } from '@app/services/common/errorService';

@Component({
    selector: 'uni-company-validation-modal',
    templateUrl: './company-validation-modal.html',
    styleUrls: ['./company-validation-modal.sass'],
})
export class CompanyValidationModal implements IUniModal, OnInit {
    @Input() options = <IModalOptions>{};
    @Output() onClose = new EventEmitter<any>();

    busy = false;

    headerText = 'Verifiser rettigheter';
    returnedText: string;
    illustration: string;
    bankIdIdp: string;
    returnedFromAuth = false;
    validationSuccess = false;
    requestFailed = false;

    constructor(
        private authService: AuthService,
        private browserStorage: BrowserStorageService,
        private companyService: CompanyService,
        private errorService: ErrorService,
    ) {}

    ngOnInit() {
        const data = this.options.data;
        this.bankIdIdp = data.idp;
        this.returnedFromAuth = data.returnedFromAuth;

        if (!this.returnedFromAuth) return;

        this.busy = true;
        this.companyService.validateCompany().subscribe({
            next: (success) => {
                this.validationSuccess = success;
                this.setTexts();
                this.busy = false;
            },
            error: (err) => {
                this.errorService.handle(err);
                this.requestFailed = true;
                this.validationSuccess = false;
                this.setTexts();
                this.busy = false;
            },
        });
    }

    setTexts() {
        if (this.validationSuccess) {
            this.headerText = 'Suksess!';
            this.returnedText = 'Vi har verifisert rettighetene dine på selskapet.';
            this.illustration = 'success';
        } else {
            this.headerText = 'Kunne ikke verifisere';
            this.returnedText = this.requestFailed
                ? 'Noe gikk galt da vi prøvde å verifisere.'
                : 'Vi kan dessverre ikke se at du har rettigheter på selskapet. Ta kontakt med noen som har rettighet.';
            this.illustration = 'empty_state';
        }
    }

    validate() {
        this.browserStorage.setItem('lastNavigationAttempt', `${window.location.hash.split('#')[1]}?validating=true`);
        this.authService.authenticateWithIdp(this.bankIdIdp, true);
    }

    close() {
        this.onClose.emit(this.returnedFromAuth && this.validationSuccess);
    }
}
