import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { BrowserStorageService } from '@uni-framework/core/browserStorageService';
import { IUniModal, IModalOptions } from '@uni-framework/uni-modal';

@Component({
    templateUrl: './journal-entry-counter-modal.html',
    styleUrls: ['./journal-entry-counter-modal.sass'],
})
export class JournalEntryCounterModal implements IUniModal {
    @Input() options: IModalOptions = {};
    @Output() onClose = new EventEmitter();

    journalEntriesCount: number;
    maxJournalEntries: number;
    currentContractType: string;
    targetContractType: string;
    openedFromNavbar: boolean;
    isDnbEnvironment: boolean;
    isSrEnvironment: boolean;
    isUnimicro: boolean;
    dontShowAgain = false;

    constructor(
        private router: Router,
        private browserStorage: BrowserStorageService,
    ) {}

    ngOnInit() {
        this.journalEntriesCount = this.options.data.journalEntriesCount;
        this.maxJournalEntries = this.options.data.maxJournalEntries;
        this.currentContractType = this.options.data.currentContractType;
        this.targetContractType = this.options.data.targetContractType;
        this.openedFromNavbar = !!this.options.data.openedFromNavbar;
        this.isSrEnvironment = !!this.options.data.isSrEnvironment;
        this.isDnbEnvironment = !!this.options.data.isDnbEnvironment;
        this.isUnimicro = !!this.options.data.isUnimicro;
    }

    ngOnDestroy() {
        if (this.dontShowAgain) {
            this.browserStorage.setItemOnCompany('dontShowJournalEntriesCount', true);
        }
    }

    navigateToMarketplace() {
        this.router.navigateByUrl('/marketplace/modules');
        this.onClose.emit();
    }
}
