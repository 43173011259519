import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { theme, THEMES } from 'src/themes/theme';
import { Router } from '@angular/router';

@Component({
    selector: 'product-highlight',
    templateUrl: './product-highlight.html',
    styleUrls: ['./product-highlight.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductHighlightWidget {
    isDnb = theme.theme === THEMES.EXT02;
    busy = true;

    headerAboveIllustration: string;
    headerBelowIllustration: string;
    illustrationUrl: string;
    description: string;
    mainAction: () => void;
    mainActionLabel: string;
    mainActionIcon: string;
    secondaryAction: () => void;
    secondaryActionLabel: string;
    secondaryActionIcon: string;

    constructor(
        private cdr: ChangeDetectorRef,
        private router: Router,
    ) {}

    ngOnInit() {
        this.setupContent();
    }

    setupContent() {
        if (this.isDnb) {
            this.headerAboveIllustration = `Trenger du hjelp med regnskapet?`;
            this.illustrationUrl = '/config/dist/theme/assets/paper-with-checklist.svg';
            this.description = `Gjør du regnskapet selv og synes det er vanskelig?<br>Få hjelp av en av våre samarbeidspartnere.`;
            this.mainAction = () => this.onMainActionClicked();
            this.mainActionLabel = 'Finn regnskapsfører';
        }

        this.busy = false;
        this.cdr.markForCheck();
    }

    onMainActionClicked() {
        if (this.isDnb) {
            this.router.navigateByUrl(`/marketplace/agencies`);
        }
    }
}
