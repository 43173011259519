import { Component, ChangeDetectionStrategy, ChangeDetectorRef, HostBinding, Input } from '@angular/core';
import { ElsaContractType, ElsaProductType, ElsaCategory } from '@app/models';
import { forkJoin } from 'rxjs';
import { trigger, state, style, transition, animate, group } from '@angular/animations';
import { ElsaContractService } from '@app/services/elsa/elsaContractService';

@Component({
    selector: 'contract-types-comparison',
    templateUrl: './contract-types-comparison.html',
    styleUrls: ['./contract-types-comparison.sass'],
    animations: [
        trigger('slideInOut', [
            state('in', style({ height: '*', opacity: 0 })),
            transition(':leave', [
                style({ height: '*', opacity: 1 }),
                group([animate(300, style({ height: 0 })), animate('200ms ease-in-out', style({ opacity: '0' }))]),
            ]),
            transition(':enter', [
                style({ height: '0', opacity: 0 }),
                group([animate(300, style({ height: '*' })), animate('400ms ease-in-out', style({ opacity: '1' }))]),
            ]),
        ]),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContractTypesComparison {
    @HostBinding('class.ext02-signup')
    @Input()
    useExt02SignupTheme: boolean;
    @Input() contractTypes?: ElsaContractType[];

    busy = false;
    activeContractTypes: ElsaContractType[] = [];
    categories: ElsaCategory[] = [];

    expanded = false;

    constructor(
        private elsaContractService: ElsaContractService,
        private changeDetectorRef: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        const requests: any = [this.elsaContractService.getContractTypesCategories()];
        if (!this.contractTypes?.length) {
            requests.push(this.elsaContractService.getOnboardingContractTypes());
        }
        forkJoin(requests).subscribe({
            next: (res) => {
                this.categories = res[0];
                this.activeContractTypes = this.contractTypes?.length ? this.contractTypes : res[1];

                this.setCheckmarks();
                this.findMainPackageProduct();
                this.busy = false;
                this.changeDetectorRef.markForCheck();
            },
            error: () => {
                this.busy = false;
            },
        });
    }

    setCheckmarks() {
        this.categories.forEach((category) => {
            category.Features = category.Features.map((feature) => {
                feature.Checkmarks = this.activeContractTypes.map((type) =>
                    feature.ContainsContractTypes.includes(type.ContractType),
                );
                return feature;
            });
        });
    }

    findMainPackageProduct() {
        this.activeContractTypes.map((type) => {
            type['_MainPackageProduct'] = type.ProductContractTypes.find(
                (pct) => pct?.Product?.ProductType === ElsaProductType.Package,
            );
            type['_MainPackageProduct'] = type['_MainPackageProduct']?.Product;
            return type;
        });
    }
}
