<section class="info-box" [ngClass]="selfReportLevel === 2 ? 'warn' : selfReportLevel >= 3 ? 'bad' : ''">
    <span class="employee-name" *ngIf="!selfReportIsLocked">
        Ansatt: {{ employee.DisplayName || employee?.BusinessRelationInfo?.Name || employee?.Name }}
    </span>
    <span class="employee-name blocked" *ngIf="selfReportIsLocked">
        Retten for egenmeldinger er inndratt for
        {{ employee.DisplayName || employee?.BusinessRelationInfo?.Name || employee?.Name }}
    </span>
    <ng-container *ngIf="selfReportCounter">
        <section class="info-box-line" *ngIf="!selfReportIsLocked">
            <span>
                <strong>Egenmeldinger i perioden: {{ periodeString }} </strong>
            </span>
        </section>

        <section class="info-box-line" *ngIf="selfReportIsLocked">
            <span>
                Låst for egenmeldinger fram til <strong> {{ lockedToString }} </strong></span
            >
        </section>

        <section class="info-box-line">
            <span> Egenmeldingdager siste 16 dager </span>
            <span>
                {{ selfReportCounter.NumberOfDaysInCurrentPeriod }} /
                {{ selfReportCounter.NumberOfDaysInPeriodAllowed }} dager</span
            >
        </section>

        <section class="info-box-line">
            <span> Egenmeldingstilfeller siste 12 mnd </span>
            <span>
                {{ selfReportCounter.NumberOfPeriods }} / {{ selfReportCounter.NumberOfPeriodsAllowed }} tilfeller</span
            >
        </section>

        <section class="info-box-line">
            <span> Egenmeldingsdager siste 12 mnd: </span>
            <span>
                {{ selfReportCounter.TotalNumberOfDays }} / {{ selfReportCounter.TotalNumberOfDaysAllowed }} dager</span
            >
        </section>
    </ng-container>

    <ng-container *ngIf="sickChildCounter">
        <section class="info-box-line">
            <span> Antall årlige omsorgsdager </span>
            <span> {{ sickChildCounter.MaxDays }} </span>
        </section>

        <section class="info-box-line">
            <span> Antall dager brukt</span>
            <span> {{ sickChildCounter.DaysUsed }} </span>
        </section>

        <section class="info-box-line">
            <span> Antall dager tilgjengelig </span>
            <span> {{ sickChildCounter.MaxDays - sickChildCounter.DaysUsed }} </span>
        </section>
    </ng-container>
</section>

<section class="flex color-bad mt-2 mb-2" *ngIf="selfReportLevel === 3">
    <i class="material-icons mr-1">info</i>
    <span style="font-size: 14px"
        >Dette er siste tilgjengelige egenmelding i kvoten på den ansatte. Les mer om retten til egenmelding
        <a
            target="_blank"
            href="https://www.nav.no/no/person/arbeid/sykmeldt-arbeidsavklaringspenger-og-yrkesskade/sykmelding-ulike-former/egenmelding#chapter-1"
            >her</a
        ></span
    >
</section>

<section class="flex color-bad mt-2 mb-2" *ngIf="selfReportLevel === 4">
    <i class="material-icons mr-1">info</i>
    <span style="font-size: 14px"
        >Det er <b>ingen</b> egenmelding i kvoten på den ansatte. Les mer om retten til egenmelding
        <a
            target="_blank"
            href="https://www.nav.no/no/person/arbeid/sykmeldt-arbeidsavklaringspenger-og-yrkesskade/sykmelding-ulike-former/egenmelding#chapter-1"
            >her</a
        ></span
    >
</section>

<section class="flex color-bad mt-2 mb-2" *ngIf="selfReportCounter?.PeriodIsLocked">
    <i class="material-icons mr-1">info</i>
    <span style="font-size: 14px">Inneværende periode er låst</span>
</section>

<section class="flex color-bad mt-2 mb-2" *ngIf="exceedsMaxDays">
    <i class="material-icons mr-1">info</i>
    <span style="font-size: 14px">Overstiger maks egenmeldingsdager i inneværende periode</span>
</section>
