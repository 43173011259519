import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { DashboardDataService } from '../../../dashboard-data.service';
import { Subscription, of, Observable, Subject, forkJoin } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { theme } from 'src/themes/theme';
import { dougnutTextPlugin } from '@app/chartjs-setup';
import { Project } from '@uni-entities';
import { Router } from '@angular/router';
import { NumberFormat } from '@app/services/common/numberFormatService';
import { ProjectService } from '@app/services/common/projectService';

@Component({
    selector: 'project-summary-widget',
    templateUrl: './project-summary-widget.html',
    styleUrls: ['./project-summary-widget.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectSummaryWidget {
    dataSubscription: Subscription;
    colors = theme.widgets.pie_colors;

    loading: boolean = true;
    hasData: boolean;
    data: any[];
    chartConfig: any;

    onDestroy$ = new Subject();
    currentProject: Project;
    noProjectError: boolean = false;
    invoiceHours = 0;
    orderReserve: number = 0;

    values = {
        income: 0,
        result: 0,
        costOthers: 0,
        costSalary: 0,
        costTotal: 0,
        resultPercentage: '',
        forApproval: 0,
    };

    constructor(
        private cdr: ChangeDetectorRef,
        private projectService: ProjectService,
        private dataService: DashboardDataService,
        private format: NumberFormat,
        private router: Router,
    ) {}

    ngOnInit() {
        this.projectService.currentProject.pipe(takeUntil(this.onDestroy$)).subscribe((project) => {
            if (project) {
                this.currentProject = project;
                this.noProjectError = false;
                this.initChart();
            } else {
                this.noProjectError = true;
            }
            this.cdr.markForCheck();
        });
    }

    ngOnDestroy() {
        this.dataSubscription?.unsubscribe();
        this.onDestroy$.next(undefined);
        this.onDestroy$.complete();
    }

    resetValues() {
        this.orderReserve = 0;

        this.values = {
            income: 0,
            result: 0,
            costOthers: 0,
            costSalary: 0,
            costTotal: 0,
            resultPercentage: '',
            forApproval: 0,
        };
    }

    loadData() {
        const hoursEndpoint =
            `/api/statistics?model=customerinvoiceitem` +
            `&select=sum(NumberOfItems) as hours` +
            `&filter=Product.Type eq 2 and Dimensions.ProjectID eq ${this.currentProject.ID}` +
            `&expand=Product,Dimensions`;

        const costEndpoint =
            `/api/statistics?model=JournalEntryLine&filter=project.ID eq ${this.currentProject.ID} and TopLevelAccountGroup.GroupNumber gt 2` +
            `&expand=Dimensions.Project,Account.TopLevelAccountGroup` +
            `&select=sum(JournalEntryLine.Amount) as SumAmount,` +
            `TopLevelAccountGroup.Name as TopLevelAccountGroupName,TopLevelAccountGroup.GroupNumber as TopLevelAccountGroupGroupNumber`;

        const orderReservceQuery = this.dataService.get(
            `/api/statistics?model=customerorder&select=sum(items.SumTotalExVat) as sum,count(id) as counter` +
                `&filter=DefaultDimensions.ProjectID eq ${this.currentProject.ID} and (items.statuscode eq 41102 and ` +
                `(statuscode eq 41002 or statuscode eq 41003))&expand=items,DefaultDimensions`,
        );

        const invoicesForApproval = this.dataService.get(
            `/api/statistics?model=SupplierInvoice&select=count(ID) as sum&filter=StatusCode eq 30102 and ( Project.ID eq ${this.currentProject.ID})&expand=DefaultDimensions.Project`,
        );

        const queries = forkJoin([
            this.dataService.get(hoursEndpoint),
            this.dataService.get(costEndpoint),
            orderReservceQuery,
            invoicesForApproval,
        ]);

        return queries.pipe(
            catchError((err) => {
                console.error(err);
                return of([]);
            }),
        );
    }

    initChart() {
        this.dataSubscription?.unsubscribe();
        this.dataSubscription = this.loadData().subscribe(([hours, costs, orderReserve, forApprovals]) => {
            this.resetValues();
            this.invoiceHours = (hours?.Data[0] && hours?.Data[0]?.hours) || 0;

            this.orderReserve = (orderReserve?.Data && orderReserve?.Data[0] && orderReserve?.Data[0].sum) || 0;

            this.values.forApproval = (forApprovals?.Data && forApprovals?.Data[0] && forApprovals?.Data[0].sum) || 0;

            this.chartConfig = this.getChartConfig(costs.Data || []);

            this.loading = false;
            this.cdr.markForCheck();
        });
    }

    getChartConfig(chartdata) {
        const labels = [];
        const data = [];

        chartdata.forEach((element) => {
            element.SumAmount = element.SumAmount | 0;
            if (element.SumAmount > 0) {
                labels.push(element.TopLevelAccountGroupName);
                data.push(element.SumAmount);
            }

            if (parseInt(element.TopLevelAccountGroupGroupNumber) === 3) {
                this.values.income = element.SumAmount * -1;
                this.values.result += element.SumAmount * -1;
            } else if (parseInt(element.TopLevelAccountGroupGroupNumber) === 4) {
                this.values.costOthers += element.SumAmount;
                this.values.result -= element.SumAmount;
                this.values.costTotal += element.SumAmount;
            } else if (parseInt(element.TopLevelAccountGroupGroupNumber) === 5) {
                this.values.costSalary = element.SumAmount;
                this.values.result -= element.SumAmount;
                this.values.costTotal += element.SumAmount;
            } else if (parseInt(element.TopLevelAccountGroupGroupNumber) === 6) {
                this.values.costOthers += element.SumAmount;
                this.values.result -= element.SumAmount;
                this.values.costTotal += element.SumAmount;
            } else if (parseInt(element.TopLevelAccountGroupGroupNumber) === 7) {
                this.values.costOthers += element.SumAmount;
                this.values.result -= element.SumAmount;
                this.values.costTotal += element.SumAmount;
            } else if (parseInt(element.TopLevelAccountGroupGroupNumber) === 8) {
                this.values.costOthers += element.SumAmount;
                this.values.result -= element.SumAmount;
                this.values.costTotal += element.SumAmount;
            }
        });

        this.values.resultPercentage = this.values.income
            ? (((this.values.income - this.values.costTotal) / this.values.income) * 100).toFixed(2)
            : '0';

        return {
            type: 'pie',
            data: {
                labels,
                datasets: [
                    {
                        data,
                        backgroundColor: theme.widgets.pie_colors,
                        borderColor: '#fff',
                        hoverBorderColor: '#fff',
                    },
                ],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                cutout: 75,
                animation: { animateScale: true },
                elements: { arc: { borderWidth: 8 } },
                plugins: {
                    legend: { display: false },
                    doughnutText: {
                        header: 'Kostnader',
                        text: this.format.asMoney(this.values.costTotal),
                    },
                },
            },
            plugins: [dougnutTextPlugin],
        };
    }

    goToDetailsView(linkNumber: number) {
        switch (linkNumber) {
            case 1:
                this.router.navigateByUrl(
                    `/accounting/accountingreports/dimension?type=1&id=${this.currentProject.ID}&number=${this.currentProject.ProjectNumber}&name=${this.currentProject.Name}`,
                );
                break;
            case 2:
                this.router.navigateByUrl('/sales/invoices');
                break;
            case 3:
                this.router.navigateByUrl('/sales/orders?filter=order_reserves');
                break;
            case 4:
                this.router.navigateByUrl('/accounting/supplier-invoice?filter=forapproval_supplierinvoices');
                break;
        }
    }
}
