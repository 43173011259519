import { Injectable } from '@angular/core';
import { CustomStorage } from '@uni-entities';
import { BizHttp } from '@uni-framework/core/http/BizHttp';
import { UniHttp } from '@uni-framework/core/http/http';

@Injectable({ providedIn: 'root' })
export class CustomStorageService extends BizHttp<CustomStorage> {
    constructor(http: UniHttp) {
        super(http);

        this.relativeURL = CustomStorage.RelativeUrl;
        this.entityType = CustomStorage.EntityType;
        this.DefaultOrderBy = null;

        this.cacheSettings.timeout = 600000; // 10 min
    }
}
