<section role="dialog" class="uni-modal" style="width: 60vw">
    <header>{{ options?.data.title }}</header>
    <article class="accrual-split-view">
        <section
            class="alert info"
            *ngIf="options?.data.title === 'Periodisering på repeterende faktura' && hideRemoveButton"
        >
            <i *ngIf="hideRemoveButton" class="material-icons info color-c2a">info</i>
            <div>
                <b>Periodiseringsinfo</b><br />
                Fakturaen vil automatisk periodiseres for fakturamåned og det antallet perioder som fakturaen gjelder
                for.
                <br />
                <b>Beløp: </b>{{ options?.data.totalSum }}<br />
                <b>Periodesum: </b>{{ (options?.data.totalSum / options?.data.numberOfPeriods).toFixed(2) }}<br />
                <b>Antall perioder: </b>{{ options?.data.numberOfPeriods }}<br />
                <br />
                <b>Eksempel fra neste faktura</b><br />
                <b>Neste fakturadato:</b> {{ options?.data.nextInvoiceDate | date: 'dd.MM.yyyy' }}<br />
                <b>Perioder:</b> {{ accrualMonthInfoText }}<br />
            </div>
        </section>
        <section
            class="alert good"
            *ngIf="options?.data.title === 'Periodisering på repeterende faktura' && !hideRemoveButton"
        >
            <i *ngIf="!hideRemoveButton" class="material-icons check_circle color-good">check_circle</i>
            <div>
                <b>Aktiv periodisering</b> <br />
                Fakturaen periodiseres fra fakturamåned og det antallet perioder som fakturaen gjelder for.
                <br />
                <br />
                <b>Beløp: </b>{{ options?.data.totalSum }}<br />
                <b>Periodesum: </b>{{ (options?.data.totalSum / options?.data.numberOfPeriods).toFixed(2) }}<br />
                <b>Antall perioder: </b>{{ options?.data.numberOfPeriods }}<br />
                <b>Utsatt periodiseringsstart: </b>{{ shiftPeriodStartInfoText }}<br />
                <b>Perioder:</b> {{ accrualMonthInfoText }}<br />
            </div>
        </section>
        <section
            class="accrual-form"
            *ngIf="options?.data.title === 'Periodisering på repeterende faktura' && hideRemoveButton"
        >
            <uni-form
                class="two-column-layout small-gap"
                [config]="{ showLabelAbove: true }"
                [fields]="shiftStartPeriod"
                [model]="model"
                (changeEvent)="setAccrualMonthInfoText($event)"
            >
            </uni-form>
        </section>
        <section class="accrual-form" *ngIf="options?.data.title !== 'Periodisering på repeterende faktura'">
            <uni-form
                class="two-column-layout small-gap"
                [config]="{ showLabelAbove: true }"
                [fields]="accrualFields"
                [model]="model"
                (changeEvent)="onFormChange($event)"
            >
            </uni-form>
            <small *ngIf="periodError.periodNotValidError" class="d-block color-bad">
                Vennligst velg riktige perioder
            </small>
            <small *ngIf="periodError.periodLockedError" class="d-block color-bad"> Periode er sperret </small>
        </section>

        <uni-form
            class="two-column-layout small-gap"
            [config]="{ showLabelAbove: true }"
            [fields]="accountFields"
            [model]="model"
            (changeEvent)="onFormChange($event)"
        >
        </uni-form>
    </article>
    <footer>
        <section *ngIf="options?.data.title !== 'Periodisering på repeterende faktura'">
            <button (click)="onClose.emit()" class="secondary">Avbryt</button>
            <button
                (click)="onClose.emit({ action: 'remove' })"
                *ngIf="!buttonsDisabled && !hideRemoveButton"
                class="secondary bad"
            >
                Fjern periodisering
            </button>
            <button
                (click)="onSaveClick()"
                *ngIf="!buttonsDisabled"
                [disabled]="periodError.periodNotValidError || periodError.periodLockedError"
                class="c2a"
            >
                Lagre periodisering
            </button>
        </section>
        <section *ngIf="options?.data.title === 'Periodisering på repeterende faktura'">
            <button (click)="onClose.emit()" class="secondary">Avbryt</button>
            <button
                (click)="onClose.emit({ action: 'remove' })"
                *ngIf="!buttonsDisabled && !hideRemoveButton"
                class="c2a"
            >
                Deaktiver periodisering
            </button>
            <button
                (click)="onSaveClick()"
                *ngIf="!buttonsDisabled && hideRemoveButton"
                [disabled]="periodError.periodNotValidError || periodError.periodLockedError"
                class="c2a"
            >
                Aktiver periodisering
            </button>
        </section>
    </footer>
</section>
