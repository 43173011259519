import { catchError, finalize } from 'rxjs/operators';
import { Component, Input } from '@angular/core';
import { IWizardOptions } from './wizardoptions';
import { WorkOrder } from './workorder';
import { ErrorService } from '@app/services/common/errorService';
import { IWorkHours, InvoiceHourService } from '@app/services/timetracking/invoice-hours.service';

@Component({
    selector: 'workitem-transfer-wizard-preview',
    templateUrl: './transfer-wizard-preview.html',
})
export class WorkitemTransferWizardPreview {
    @Input() options: IWizardOptions;

    busy = true;
    computing = true;
    mergeOption: string;
    splitOnDimension: boolean = true;
    orderList: Array<WorkOrder> = [];

    private baseList: Array<IWorkHours>;

    constructor(
        private errorService: ErrorService,
        private invoiceHourService: InvoiceHourService,
    ) {}

    onMergeOptionChange() {
        if (this.baseList) {
            this.options.mergeBy = parseInt(this.mergeOption, 10);
            this.options.splitOnDimension = this.splitOnDimension;
            this.orderList.length = 0;
            this.invoiceHourService.processList(this.baseList, this.options).then((result) => {
                this.orderList = result;
            });
            return;
        }
        this.refresh();
    }

    refresh() {
        this.busy = true;
        this.orderList.length = 0;
        this.mergeOption = '' + this.options.mergeBy;
        this.splitOnDimension = this.options.splitOnDimension ?? this.splitOnDimension;
        this.fetchData().subscribe((list) => {
            this.baseList = list;
            this.invoiceHourService.processList(list, this.options).then((result) => {
                this.orderList = result;
                this.busy = false;
                this.computing = false;
            });
        });
    }

    private fetchData() {
        this.busy = true;
        return this.invoiceHourService.getOrderLineBaseData(this.options).pipe(
            finalize(() => (this.busy = false)),
            catchError((err, obs) => this.errorService.handleRxCatch(err, obs)),
        );
    }
}
