import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IModalOptions, IUniModal } from '@uni-framework/uni-modal/interfaces';
import { User, Team, ApprovalRule, ApprovalType } from '@app/unientities';
import { forkJoin, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApprovalRuleService } from '@app/services/common/approvalRuleService';
import { TeamService } from '@app/services/common/teamService';
import { UserService } from '@app/services/common/userService';
import { AssignmentDetails } from '@app/services/accounting/supplierInvoiceService';
import { cloneDeep } from 'lodash-es';

@Component({
    selector: 'bill-assignment-modal',
    templateUrl: './bill-assignment-modal.html',
    styleUrls: ['./bill-assignment-modal.sass'],
})
export class BillAssignmentModal implements IUniModal, OnInit {
    @Input() options: IModalOptions;
    @Output() onClose = new EventEmitter<AssignmentDetails>();

    busy: boolean;
    users: User[] = [];
    teams: Team[] = [];
    approvalRules: ApprovalRule[] = [];

    message: string;
    userIDs: number[] = [];
    teamID: number;
    approvalRule: ApprovalRule;

    validationMessage: string;
    assignmentMode: 'user' | 'team' | 'rule';

    constructor(
        private userService: UserService,
        private teamService: TeamService,
        private approvalRuleService: ApprovalRuleService,
    ) {
        this.assignmentMode = <any>sessionStorage.getItem('bill_assignment_mode') || 'user';

        this.busy = true;
    }

    ngOnInit(): void {
        const requests = this.getRequestArray();
        forkJoin(requests).subscribe((res: any[]) => {
            this.users = (res[0] || []).filter((u) => u.StatusCode === 110001 && !u.Protected);
            this.teams = res[1] || [];
            this.approvalRules = (res[2] || [])
                .filter((rule) => {
                    const steps = rule.Steps || [];

                    // Rules that only have one step with a limit are considered
                    // global rules, and does not make sense to select here
                    return steps.length !== 1 || !steps[0].Limit;
                })
                .map((rule) => ({
                    ...rule,
                    _detailFormat: {
                        steps: rule?.Steps?.map((step) => ({
                            limit: step.Limit,
                            approvalType: parseInt(step.ApprovalType, 10) as ApprovalType,
                            stepNumber: step.StepNumber,
                            // unwrap user objects from step.ApprovalRuleStepUser[].User and step.User, if any are null then return empty array respectively
                            users: [
                                ...(step?.Users?.map((u) => u.User) ?? []),
                                ...(!!step.UserID ? [step.User] : []),
                                ...(step.LookupDepartmentApprover
                                    ? [{ User: { DisplayName: 'Avdelingsgodkjenner' } }]
                                    : []),
                                ...(step.LookupProjectApprover
                                    ? [{ User: { DisplayName: 'Prosjektgodkjenner' } }]
                                    : []),
                            ],
                        })),
                    },
                }));

            this.busy = false;
        });
    }
    private getRequestArray() {
        return [
            this.userService
                .getUsersByRoles(['Approval.Accounting', 'Accounting.Admin', 'Accounting.Approval', 'Administrator'])
                .pipe(catchError(() => of([]))),
            this.teamService.GetAll().pipe(catchError(() => of([]))),
            this.approvalRuleService.GetAll().pipe(
                catchError(() => of([])),
                map((rules) => {
                    rules.forEach((rule) => {
                        rule.Steps = rule.Steps.map((step) => {
                            step['_toDisplay'] = cloneDeep(step.Users);
                            if (step.LookupDepartmentApprover)
                                step['_toDisplay'].push({ User: { DisplayName: 'Avdelingsgodkjenner' } });
                            if (step.LookupProjectApprover)
                                step['_toDisplay'].push({ User: { DisplayName: 'Prosjektgodkjenner' } });
                            return step;
                        });
                    });
                    return rules;
                }),
            ),
        ];
    }

    userSearchFn(searchText: string, user: User) {
        searchText = searchText.toLowerCase();
        return (
            (user.DisplayName || user.UserName)?.toLowerCase().includes(searchText) ||
            user.Email?.toLowerCase().startsWith(searchText)
        );
    }

    submit() {
        this.validationMessage = '';
        const assignmentDetails: AssignmentDetails = {
            Message: this.message,
        };

        if (this.assignmentMode === 'user' && this.userIDs.length) {
            assignmentDetails.UserIDs = this.userIDs;
        } else if (this.assignmentMode === 'team' && this.teamID) {
            assignmentDetails.TeamIDs = [this.teamID];
        } else if (this.assignmentMode === 'rule' && this.approvalRule) {
            assignmentDetails.ApprovalRuleID = this.approvalRule.ID;
        } else {
            this.validationMessage = 'Minimum en bruker/regel/team må være valgt.';
            return;
        }

        sessionStorage.setItem('bill_assignment_mode', this.assignmentMode);
        this.onClose.emit(assignmentDetails);
    }
}
