<section role="dialog" class="uni-modal">
    <rig-wizard [steps]="steps" [activeStepValue]="activeStep">
        <section *ngIf="busy" class="modal-spinner">
            <mat-spinner class="c2a"></mat-spinner>
        </section>

        <section *ngIf="activeStep === 0">
            <h1>Kom i gang med fakturering</h1>

            <div class="flex gap-4">
                <p>
                    I denne veiviseren hjelper vi deg med å sette opp nødvendige funksjoner som ikke er satt opp, for å
                    komme riktig i gang. <br />
                    <br />
                    @if (supportArticleUrl) {
                        Bli bedre kjent med flere av funksjonene ved fakturering
                        <a target="_blank" [href]="supportArticleUrl">på våre supportsider</a>
                    }
                </p>
            </div>
        </section>

        <section *ngIf="activeStep === 1">
            <h1>Fakturanummer</h1>

            <p>
                Systemet setter automatisk første faktura som nr. {{ nextInvoiceNumber }}. Dersom du har fakturert i et
                tidligere system og/eller ønsker å endre nummerserien til å starte på et annet siffer skriver du dette
                inn i feltet under.
            </p>

            <label class="uni-label label-above">
                <span>Sett første fakturanummer i systemet</span>
                <input type="text" [(ngModel)]="nextInvoiceNumber" (change)="isDirty = true" />
            </label>
        </section>

        <section *ngIf="activeStep === 2">
            <h1>Forfallsdager</h1>

            <p>Systemet setter automatisk kredittdager til 14. Dette kan endres under om du ønsker.</p>

            <p>
                Om du har kunder med forskjellige betingelser, kan dette defineres under Innstillinger -> Salg, og
                settes på kundene. De mest brukte verdiene her er 14 og 30 dager
            </p>

            <label class="uni-label label-above">
                <span>Forfallsdager</span>
                <input type="text" [(ngModel)]="companySettings.CustomerCreditDays" (change)="isDirty = true" />
            </label>
        </section>

        <section *ngIf="activeStep === 3">
            <h1>Bank og innbetaliger</h1>

            <ng-container *ngIf="!companySettings.CompanyBankAccount">
                <p>
                    Legg til bankkonto for innbetaling. Dette er viktig for at dine kunder skal kunne betale deg. Den
                    kontoen du legger inn her, vil bli synlig på alle dine fakturaer.
                </p>
                <a class="add-new" (click)="addAccount()"> <i class="material-icons">add</i> Legg til bankkonto </a>
            </ng-container>

            <ng-container *ngIf="companySettings.CompanyBankAccount">
                <section class="edit-and-delete-account-header">
                    <span> Bankkonto: </span>
                    <i
                        class="material-icons-outlined"
                        (click)="addAccount(companySettings.CompanyBankAccount)"
                        title="Rediger bankkonto"
                        >edit</i
                    >
                </section>
                <section class="alert info">
                    <div class="flex-column">
                        <span>
                            <strong>Navn:</strong>
                            {{ companySettings.CompanyBankAccount?.Label }}
                        </span>
                        <span>
                            <strong>Kontonummer:</strong>
                            {{ companySettings.CompanyBankAccount?.AccountNumber }}
                        </span>
                        <span>
                            <strong>Hovedbokskonto:</strong>
                            {{ companySettings.CompanyBankAccount?.Account?.AccountNumber }} -
                            {{ companySettings.CompanyBankAccount?.Account?.AccountName }}
                        </span>
                        <span>
                            <strong>Type: </strong>
                            <ng-container [ngSwitch]="companySettings.CompanyBankAccount?.BankAccountType">
                                <ng-container *ngSwitchCase="'tax'">Skatt</ng-container>
                                <ng-container *ngSwitchCase="'salary'">Lønn</ng-container>
                                <ng-container *ngSwitchDefault>Drift</ng-container>
                            </ng-container>
                        </span>
                    </div>
                </section>
            </ng-container>
        </section>

        <section *ngIf="activeStep === 4">
            <h1>Firmalogo</h1>

            <p>
                Her kan du velge om tilbud, ordre og fakturablanketter skal vise firmanavn, logo, eller begge deler.
                Dersom logoen inneholder firmanavn anbefaler vi å kun vise den. Om du ikke har lastet opp en logo enda
                kan du gjøre det nå.
            </p>

            <mat-radio-group
                [(ngModel)]="companySettings.LogoHideField"
                class="horizontal mb-2"
                (change)="isDirty = true"
            >
                <mat-radio-button [value]="1">Firmanavn</mat-radio-button>
                <mat-radio-button [value]="2">Logo</mat-radio-button>
                <mat-radio-button [value]="3">Firmanavn og logo</mat-radio-button>
            </mat-radio-group>

            <uni-image
                [entity]="'companysettings'"
                [entityID]="1"
                [singleImage]="true"
                (imageDeleted)="onLogoDelete()"
                (fileListReady)="logoFileChanged($event)"
            >
            </uni-image>
        </section>

        <section class="image-and-text-step" *ngIf="activeStep === 5">
            <h1>Klar for fakturering</h1>
            <p>
                <strong>Du har nå fullført oppsett for fakturering!</strong>

                @if (supportArticleUrl) {
                    <br /><br />
                    Ønsker du å bli bedre kjent med fakturaflyten vår, finner du mye god hjelp på våre
                    <a target="_blank" [href]="supportArticleUrl">supportsider.</a> <br /><br />
                }
            </p>
        </section>

        <small *ngIf="errorMessage"> {{ errorMessage }} </small>

        <rig-wizard-footer>
            <button class="secondary pull-left" (click)="close()" *ngIf="activeStep !== 5">Lukk</button>
            <button class="secondary" (click)="previous()" [disabled]="activeStep === 0">Tilbake</button>
            <button class="c2a" (click)="next()">{{ activeStep !== 5 ? 'Neste' : 'Fullfør' }}</button>
        </rig-wizard-footer>
    </rig-wizard>
</section>
