<section class="search-container">
    <input type="search" #searchInput [formControl]="searchControl" placeholder="Søk" spellcheck="false" cdkTrapFocus />

    <section class="help-text flex align-center gap-1">
        <i class="material-icons">find_in_page</i>
        <span>{{ helpText }}</span>
    </section>
</section>

<section class="progress-container">
    <mat-progress-bar *ngIf="loading$ | async" class="uni-progress-bar" mode="indeterminate"> </mat-progress-bar>
</section>

<section #list id="results-container">
    <smart-search-item *ngFor="let item of searchResults" [item]="item" (click)="onItemSelected(item)">
    </smart-search-item>
</section>

<section class="search-footer">
    <small>Søket kan åpnes med tastatur <span>ctrl</span> + <span>space</span></small>
</section>
