<header>
    <section class="top-row">
        <h1>Varsler</h1>

        <div class="buttons">
            <button class="tertiary" #toggleFilterTrigger>
                <i class="material-icons">filter_list</i>
                Filter
            </button>

            <button class="tertiary" style="margin-left: 1.5rem" (click)="openSettingsModal()">
                <i class="material-icons">settings</i>
            </button>
        </div>

        <dropdown-menu
            class="filter-menu"
            (closed)="saveAndGetNewNotifications()"
            [trigger]="toggleFilterTrigger"
            [closeOnClick]="false"
        >
            <ng-template>
                <ul class="notification-filters">
                    <li *ngFor="let filter of typeFilters" [ngClass]="{ selected: filter.checked }">
                        <rig-checkbox [checked]="filter.checked" (change)="toggleFilterChecked(filter)">
                            {{ filter.label }}
                        </rig-checkbox>
                    </li>
                </ul>
                <button class="c2a" (click)="onFilterSaveClick()">Lagre</button>
            </ng-template>
        </dropdown-menu>
    </section>

    <section class="bottom-row">
        <div class="toggle-button-group inline-flex">
            <button
                (click)="setCurrentCompanyOnly(true)"
                class="unstyled flex-0-auto"
                [class.active]="currentCompanyOnly"
            >
                Dette selskapet
            </button>
            <button
                (click)="setCurrentCompanyOnly(false)"
                class="unstyled flex-0-auto"
                [class.active]="!currentCompanyOnly"
            >
                Alle selskap
            </button>
        </div>

        <span>
            <rig-switch class="dark" [(ngModel)]="viewUnreadOnly" (change)="viewUnreadOnlyChange()">
                Vis bare uleste
            </rig-switch>
        </span>
    </section>
</header>

<section *ngIf="busy" class="modal-spinner">
    <mat-spinner class="small"></mat-spinner>
</section>

<article *ngIf="!busy" class="notification-list" (scrolledToBottom)="addMoreNotifications()">
    <section *ngIf="!items.length" class="no-notifications">
        <p>Ingen varsler</p>
    </section>

    <section *ngIf="unreadItems.length" class="header">
        Nye
        <button (click)="markAllAsRead()">Marker alle som lest</button>
    </section>

    <notification-item
        *ngFor="let item of unreadItems; let index = i"
        [notification]="item"
        (notificationChange)="handleNotificationChange($event)"
        (click)="onNotificationClick(item, $event)"
    >
    </notification-item>

    <section *ngIf="readItems.length" class="header">
        Gamle
        <button *ngIf="!unreadItems.length" (click)="markAllAsRead()">Marker alle som lest</button>
    </section>

    <notification-item
        *ngFor="let item of readItems; let index = i"
        [notification]="item"
        (notificationChange)="handleNotificationChange($event)"
        (click)="onNotificationClick(item, $event)"
    >
    </notification-item>

    <section *ngIf="loadingMoreBusy" class="loading-more-spinner flex align-center">
        <section class="notification-icon flex justify-center align-center">
            <i class="material-icons">cached</i>
        </section>

        <section class="notification-body flex-1">
            <section></section>
            <section></section>
        </section>
    </section>
</article>
