import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter, inject } from '@angular/core';
import { AnalyticsEvent, AnalyticsService } from '@app/services/analytics-services/analytics.service';
import { CMSService, CMSWidgetData } from '@app/services/cms/cms-service';
import { THEMES, theme } from 'src/themes/theme';

@Component({
    selector: 'cms-widget',
    templateUrl: './cms-widget.html',
    styleUrls: ['./cms-widget.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CMSWidget {
    @Output() removeWidget = new EventEmitter();

    private analytics = inject(AnalyticsService);

    path: string;
    widgetData: CMSWidgetData;
    isSR = theme.theme === THEMES.SR;

    constructor(
        private cmsService: CMSService,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        this.widgetData = this.cmsService.widget.getValue();
        this.path =
            this.widgetData.illustration && !this.widgetData.illustration_version
                ? `config/dist/theme/assets/${this.widgetData.illustration}.svg`
                : '';
        this.cdr.markForCheck();
        this.analytics.trackCMSEvents(AnalyticsEvent.CMS_WIDGET_SHOWN, this.widgetData._id);
    }

    close() {
        this.cmsService.closeWidget();
        this.removeWidget.emit(true);
        this.analytics.trackCMSEvents(AnalyticsEvent.CMS_WIDGET_CLOSED, this.widgetData._id);
    }
}
