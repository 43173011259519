import { Component, ViewChild, EventEmitter } from '@angular/core';
import { IModalOptions, IUniModal } from '@uni-framework/uni-modal';
import { ToastService, ToastType } from '@uni-framework/uniToast/toastService';
import { AltinnSettingsComponent } from '../../altinn-component/altinn-component';

@Component({
    selector: 'uni-new-company-modal',
    templateUrl: './connect-to-altinn-modal.html',
})
export class AltinnSettingsModal implements IUniModal {
    options = <IModalOptions>{};

    onClose = new EventEmitter<any>();

    @ViewChild('altinnComponent')
    private altinnComponent: AltinnSettingsComponent;

    busy = true;
    canCheckedLogin: boolean = true;

    constructor(private toastService: ToastService) {}

    onDataLoaded() {
        this.busy = false;
    }

    onCheckValidated(validated) {
        if (validated) {
            this.onClose.emit(true);
        }
        this.busy = false;
    }

    check() {
        this.busy = true;
        this.canCheckedLogin = false;
        this.altinnComponent.pingAltinn();
    }

    saveAltinnSettings() {
        this.busy = true;
        this.altinnComponent
            .saveAltinnSettings()
            .subscribe({
                next: () =>
                    this.toastService.addToast(
                        'Innstillinger lagret',
                        ToastType.good,
                        5,
                        'Du kan nå teste tilkoblingen til Altinn',
                    ),
                error: () =>
                    this.toastService.addToast('Feil ved lagring av innstillinger, sjekk feltene', ToastType.warn),
            })
            .add(() => {
                this.busy = false;
                this.canCheckedLogin = true;
            });
    }

    close() {
        this.onClose.emit(false);
    }
}
