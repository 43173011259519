<div class="sidebar-section" (mouseenter)="setHovering(true)" (mouseleave)="setHovering(false)">
    <button
        #btn
        (click)="onButtonClick()"
        class="unstyled w-100 justify-start"
        [attr.aria-expanded]="expanded"
        [attr.aria-controls]="section.name"
        aria-haspopup="true"
    >
        <div class="icon flex align-center justify-center">
            <uni-icon (click)="onIconClick($event)" [icon]="section.icon" matIconClass="material-icons-outlined">
            </uni-icon>
        </div>

        <span>{{ section.name | translate }}</span>
    </button>

    <sidebar-link-list
        *ngIf="!section.isOnlyLinkSection"
        [id]="section.name"
        [open]="expanded"
        [popover]="sidebarCollapsed"
        [anchorElement]="btn"
        [class.full-width]="!sidebarCollapsed"
        (close)="state.setExpandedSection(undefined)"
    >
        <a class="header unstyled-link" [routerLink]="section.url" *ngIf="sidebarCollapsed">
            {{ section.name | translate }}
        </a>

        <ng-container *ngFor="let group of section.linkGroups">
            <rig-link *ngFor="let link of group.links" [link]="link.url" (click)="track(link.url)">
                {{ link.name | translate }}
            </rig-link>
        </ng-container>
    </sidebar-link-list>
</div>
