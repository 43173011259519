import { Injectable } from '@angular/core';
import { BizHttp, UniHttp, RequestMethod } from '@uni-framework/core/http';
import { BankStatement, BankStatementMatch } from '@uni-entities';
import { rigDate } from '@app/components/common/utils/rig-date';
import { toIso } from '@app/components/common/utils/utils';
import { map } from 'rxjs';
import { THEMES, theme } from 'src/themes/theme';

@Injectable({ providedIn: 'root' })
export class BankStatementService extends BizHttp<BankStatement> {
    constructor(http: UniHttp) {
        super(http);
        this.relativeURL = BankStatement.RelativeUrl;
        this.entityType = BankStatement.EntityType;
        this.DefaultOrderBy = null;
    }

    matchItems(matches: BankStatementMatch[]) {
        return this.ActionWithBody(null, matches, 'match-items', RequestMethod.Post);
    }

    suggestMatch(req: { JournalEntries: any[]; BankEntries: any[]; Settings: { MaxDayOffset: number } }) {
        return this.ActionWithBody(null, req, 'suggest-match', RequestMethod.Post);
    }

    getAccountStatus(accountID: number, fromDate?: Date, toDate?: Date, monthly?: boolean) {
        const action = monthly ? 'account-status-monthly' : 'account-status';
        let params = 'accountid=' + accountID;
        if (fromDate) {
            params += `&fromdate=${rigDate(fromDate).format('YYYY-MM-DD')}`;
        }

        if (toDate) {
            params += `&todate=${rigDate(toDate).format('YYYY-MM-DD')}`;
        }

        return this.GetAction(null, action, params);
    }

    getAccountBalance(accountID: number, date?: Date) {
        let params = `accountid=${accountID}`;
        if (date) {
            params += `&date=${rigDate(date).format('YYYY-MM-DD')}`;
        }

        return this.GetAction(null, 'account-balance', params);
    }

    getImportTemplates() {
        return this.GetAction(null, 'templates');
    }

    previewImport(template: any, accountID: number, fileID: number, maxLines: number = 100) {
        let params = `accountid=${accountID}&fileID=${fileID}`;
        if (maxLines) {
            params += `&maxLines=${maxLines}`;
        }

        return this.ActionWithBody(null, template, 'preview', 'post', params);
    }

    import(template: any, accountID: number, bankAccountID: number, fileID: number, maxLines?: number) {
        let params = `accountID=${accountID}&bankAccountID=${bankAccountID}&fileID=${fileID}`;
        if (maxLines) {
            params += `&maxLines=${maxLines}`;
        }

        return this.ActionWithBody(null, template, 'import', 'post', params);
    }

    syncStatements(date?: Date) {
        let fromDate = null;
        if (date) {
            fromDate = 'fromdate=' + toIso(date);
        }
        return this.PostAction(null, 'sync-statements', fromDate);
    }

    //tmp method until biz routes support cors with credentials
    syncStatementsEika(companyKey: string, date?: Date) {
        let endpoint = 'bank/sync-statements-eika/';
        if (date) {
            endpoint += '?startDate=' + toIso(date);
        }

        return this.http
            .asPOST()
            .withCredentials()
            .usingRootDomain()
            .withEndPoint(endpoint)
            .send({}, null, false)
            .pipe(map((res) => res.body));
    }
}
