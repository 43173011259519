import { Component, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { TabService, UniModules } from './tabService';
// import {AuthService} from '../../../../authService';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface IUniTab {
    url: string;
    name: string;
    moduleID?: UniModules;
    active?: boolean;
}

interface ITabContextMenuData {
    top: number;
    left: number;
    index: number;
}

@Component({
    selector: 'uni-tabstrip',
    templateUrl: './tabstrip.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniTabStrip {
    tabs: IUniTab[] = [];
    lastActiveTab: IUniTab;
    tabContextMenu: ITabContextMenuData;

    private onDestroy$ = new Subject();

    constructor(
        private router: Router,
        private tabService: TabService,
        // private authService: AuthService,
        private cdr: ChangeDetectorRef,
    ) {
        // this.authService.companyChange.pipe(
        //     takeUntil(this.onDestroy$)
        // ).subscribe(() => {
        //     this.tabService.removeAllTabs();
        // });
    }

    public ngAfterViewInit() {
        this.tabService.tabs$.pipe(takeUntil(this.onDestroy$)).subscribe((tabs) => {
            this.tabs = tabs || [];
            const activeTab = tabs.find((tab) => tab.active);
            if (activeTab) {
                this.lastActiveTab = activeTab;
            } else if (!this.lastActiveTab) {
                this.lastActiveTab = tabs && tabs[0];
            }

            this.cdr.detectChanges();
        });
    }

    public ngOnDestroy() {
        this.onDestroy$.next(undefined);
        this.onDestroy$.complete();
    }

    public onMouseDown(index: number, event: MouseEvent) {
        if (event.button === 1) {
            // Middle mouse button
            event.preventDefault();
            this.tabService.closeTab(index);
        }
    }

    public openContextMenu(event: MouseEvent, index: number) {
        event.preventDefault();
        this.tabContextMenu = {
            top: event.pageY - event.offsetY + 23,
            left: event.pageX - event.offsetX,
            index: index,
        };

        this.cdr.markForCheck();
    }

    public contextMenuClick(index: number, type: 'tab' | 'left' | 'right' | 'others') {
        this.closeContextMenu();

        switch (type) {
            case 'tab':
                this.tabService.closeTab(index);
                break;
            case 'left':
                this.tabService.closeLeftOf(index);
                break;
            case 'right':
                this.tabService.closeRightOf(index);
                break;
            case 'others':
                this.tabService.closeAllOthers(index);
                break;
        }
    }

    public closeContextMenu() {
        this.tabContextMenu = undefined;
        this.cdr.markForCheck();
    }

    public activateTab(index: number): void {
        this.tabService.activateTab(index);
    }

    public activateHomeTab() {
        this.router.navigateByUrl('/');
    }

    public closeTab(index: number, event?: MouseEvent): void {
        if (event) {
            event.stopPropagation();
        }

        this.tabService.closeTab(index);
    }
}
