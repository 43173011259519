import { Component, Output, EventEmitter } from '@angular/core';
import { AuthService } from '@app/authService';
import { takeUntil } from 'rxjs/operators';
import { ToastService, ToastType } from '@uni-framework/uniToast/toastService';
import { ErrorService } from '@app/services/common/errorService';
import { ElsaCustomersService } from '@app/services/elsa/elsaCustomersService';
import { DesignSystemModule } from '@uni-framework/ui/design-system/design-system.module';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'give-support-access-modal',
    standalone: true,
    imports: [DesignSystemModule, CommonModule, FormsModule],
    template: `
        <section role="dialog" class="uni-modal giveSupportAccessModal">
            <header>Gi supportbruker tilgang</header>

            <article>
                <label class="uni-label">
                    <span>Oppgi navnet til den du ønsker å gi midlertidig tilgang</span>
                    <input type="text" [(ngModel)]="searchInput" />
                </label>

                <section *ngIf="showError" class="alert bad">
                    <i class="material-icons mr-1">error_outline</i>
                    Fant ingen supportbrukere med dette navnet
                </section>

                <rig-checkbox [(ngModel)]="hasAgreedToTerms">
                    Jeg aksepterer at denne brukeren gis midlertidig tilgang til mitt regnskap for å kunne yte support.
                </rig-checkbox>
            </article>

            <footer>
                <button class="secondary" (click)="cancel()">Avbryt</button>

                <button
                    class="c2a"
                    (click)="giveAccess()"
                    [attr.aria-busy]="busy"
                    [disabled]="busy || !hasAgreedToTerms || !searchInput.length"
                >
                    Gi tilgang
                </button>
            </footer>
        </section>
    `,
})
export class GiveSupportAccessModal {
    @Output() onClose = new EventEmitter<any>();

    busy = false;
    searchInput = '';
    hasAgreedToTerms = false;
    showError = false;

    constructor(
        private elsaCustomerService: ElsaCustomersService,
        private authService: AuthService,
        private errorService: ErrorService,
        private toastService: ToastService,
    ) {}

    giveAccess() {
        if (!this.hasAgreedToTerms || !this.searchInput.length) {
            return;
        }

        this.busy = true;
        this.searchInput = this.searchInput.trim();
        this.elsaCustomerService.checkSupportUserExists(this.searchInput).subscribe(
            (exists) => {
                if (exists) {
                    // get companylicenseID from authservice
                    this.authService.authentication$.pipe(takeUntil(this.onClose)).subscribe((auth) => {
                        const user = auth && auth.user;
                        if (user) {
                            // add support user to company
                            this.elsaCustomerService
                                .addSupportUserToCompany(user.License.Company.ID, this.searchInput)
                                .subscribe(
                                    () => {
                                        this.busy = false;
                                        this.toastService.addToast(
                                            this.searchInput + ' har fått tilgang til ditt regnskap',
                                            ToastType.good,
                                            3,
                                        );
                                        this.onClose.emit(true);
                                    },
                                    (err) => {
                                        this.busy = false;
                                        this.errorService.handle(err);
                                    },
                                );
                        }
                    });
                } else {
                    this.showError = true;
                    this.hasAgreedToTerms = false;
                    this.busy = false;
                }
            },
            () => (this.busy = false),
        );
    }

    cancel() {
        this.onClose.emit(false);
    }
}
