<section class="notification-icon flex align-center justify-center" [ngClass]="notificationType">
    <uni-icon [icon]="icon" [svgSize]="20"></uni-icon>
</section>

<section class="notification-body flex-column">
    <span *ngIf="notification.Title" class="title">
        {{ notification.Title }}
    </span>

    <span
        *ngIf="notificationText && notificationText !== notification.Title"
        class="description"
        [class.subdued]="!!notification.Title"
    >
        <span [innerHTML]="notificationText"></span>
    </span>

    <small class="flex">
        {{ timestamp ? timestamp[0].toUpperCase() + timestamp.slice(1) : '' }}
        {{ notification.CompanyName && timestamp ? '-' : '' }}
        {{ notification.CompanyName }}
    </small>
</section>

<button
    class="read-button"
    [matTooltip]="'Marker som ' + (toggled ? '' : 'u') + 'lest'"
    matTooltipPosition="left"
    (click.stopPropagation)="handleChecked()"
>
    <span *ngIf="toggled"></span>
</button>
