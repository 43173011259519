import { HttpClient } from '@angular/common/http';
import { get } from 'lodash-es';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IBizReport, IBizReportSettings } from './IBizReport';

export enum ReportRowType {
    Row = 0,
    GroupHeader = 1,
    GroupFooter = 2,
    SubTotalFooter = 3,
    Summary = 4,
}

export interface IReportRow {
    rowType: ReportRowType;
    rowTitle?: string;
    colSpan?: number;
}

export interface IProcessedData {
    data: IReportRow[];
    hasMoreData: boolean;
}

export class ReportDataSets {
    dataSets: Array<any>;
    hasMorePages: boolean;

    constructor(sets?: Array<any>, pageSize?: number) {
        this.dataSets = sets;
        this.hasMorePages = this.maxRowCount > 0 && this.maxRowCount === pageSize;
    }

    addPage(data: ReportDataSets) {
        if (data.maxRowCount > 0) {
            for (var i = 0; i < data.dataSets.length; i++) {
                this.dataSets[i].push(...data.dataSets[i]);
            }
        }

        this.hasMorePages = data.hasMorePages;
    }

    get numberOfSets(): number {
        return !this.dataSets ? 0 : this.dataSets.length;
    }

    get maxRowCount(): number {
        let max = 0;
        if (!this.dataSets) return 0;
        for (let i = 0; i < this.numberOfSets; i++) if (this.dataSets[i].length > max) max = this.dataSets[i].length;
        return max;
    }
}

enum cellTypes {
    Regular = 1,
    Macro = 2,
}

export class ReportData {
    public static FetchData(
        report: IBizReport,
        http: HttpClient,
        settings: IBizReportSettings,
    ): Observable<ReportDataSets> {
        settings.pageSize = settings.pageSize || 500;
        settings.page = settings.page || 1;

        const layout = report.Layouts[settings.layoutIndex];

        // Build queries (replace input-parameters)
        const queries = this.mapQueries(report, settings);

        // Return all queries as observables
        const pageInfo = layout?.DatasetSupportsPaging
            ? `&top=${settings.pageSize}&skip=${settings.pageSize * (settings.page - 1)}`
            : '';
        return forkJoin(queries.map((q) => http.get(q.query + pageInfo))).pipe(
            map((data) => new ReportDataSets(data, settings.pageSize)),
        );
    }

    public static addParams(base: string, more: string): string {
        if (!!more) return base;
        return base + (base.indexOf('?') > 0 ? '&' : '?') + more;
    }

    // ===============================================================================
    // ProcessData: converts data into array of IReportRow's
    //  (including headers and footers for all groups and summaries)
    //  Could be moved to backend for consistent expressionhandling and paging
    // ===============================================================================
    public static ProcessData(report: IBizReport, settings: IBizReportSettings, state: ReportDataSets): IProcessedData {
        let output: IReportRow[] = [];
        const layout = report.Layouts[settings?.layoutIndex || 0];
        let hasMoreData = false;

        const datasetMatch = this.locateRow(layout.Dataset, report.Data.routes);

        if (datasetMatch) {
            let data = state.dataSets[datasetMatch.index];

            const summaries = {};
            const groups: Array<{ source: string; value?: any; label: string; summaries?: any; isSubtotal?: boolean }> =
                [];
            if (layout.Grouping?.Source)
                groups.push({ source: layout.Grouping.Source, label: layout.Grouping.Label, summaries: {} });
            if (layout.Grouping?.Parent)
                groups.push({ source: layout.Grouping.Parent, label: layout.Grouping.ParentLabel, summaries: {} });

            const pageLimit = settings.pageSize > 0 ? settings.page * settings.pageSize : -1;
            let paginationSliceIndex: number;

            for (let i = 0; i < data.length; i++) {
                if (pageLimit > 0 && i > pageLimit && !paginationSliceIndex) {
                    paginationSliceIndex = output.length;
                }

                const row = data[i];

                // Grouping
                const closingRows = [];
                const leadingRows = [];
                const isSubTotal = (layout.Grouping?.SubTotal && row[layout.Grouping.SubTotal]) || false;
                for (let groupIndex = 0; groupIndex < groups.length; groupIndex++) {
                    const group = groups[groupIndex];
                    const gSums = group.summaries;
                    const groupValue = this.getGroupValue(row, layout.Grouping, groupIndex);
                    if (groupValue && groupValue != group.value) {
                        if (group.value) {
                            const title = this.interpolate(group.label || group.value, data[i - 1]);
                            closingRows.push(
                                this.createSummaryRow(
                                    layout,
                                    gSums,
                                    title,
                                    group.isSubtotal ? ReportRowType.SubTotalFooter : ReportRowType.GroupFooter,
                                ),
                            );
                        }
                        group.isSubtotal = isSubTotal;
                        if (!isSubTotal) {
                            const title = this.interpolate(group.label || groupValue, row);
                            leadingRows.push(
                                this.createRow(ReportRowType.GroupHeader, {}, title, layout.Columns.length),
                            );
                        }
                        group.value = groupValue;
                    }
                }
                for (let xi = 0; xi < closingRows.length; xi++) output.push(closingRows[xi]);
                for (let xi = leadingRows.length - 1; xi >= 0; xi--) output.push(leadingRows[xi]);

                // Datarow
                const reportRow = this.createRow(ReportRowType.Row, data[i]);
                for (let c = 0; c < layout.Columns.length; c++) {
                    const col = layout.Columns[c];
                    const cellContent = this.calcCellValue(col, row);
                    if (cellContent || (cellContent === 0 && col.Format === 'money')) {
                        if (!isSubTotal) {
                            this.addToSummary(summaries, col, cellContent, c);
                        }
                        groups.forEach((g) => this.addToSummary(g.summaries, col, cellContent, c));
                    }
                }
                if (!isSubTotal) {
                    output.push(reportRow);
                }
            }

            hasMoreData = paginationSliceIndex > 0 || state.hasMorePages;

            if (!hasMoreData && output.length > 0) {
                // Last groups?
                for (var gsi = 0; gsi < groups.length; gsi++) {
                    const group = groups[gsi];
                    const lastRow = data[data.length - 1];
                    const title = this.interpolate(group.label || group.value, lastRow || {});
                    output.push(
                        this.createSummaryRow(
                            layout,
                            group.summaries,
                            title,
                            group.isSubtotal ? ReportRowType.SubTotalFooter : ReportRowType.GroupFooter,
                        ),
                    );
                }

                // Summary
                output.push(this.createSummaryRow(layout, summaries, '', ReportRowType.Summary));
            }

            // Set group indexes before sorting to avoid messing up grouping
            let groupIndex = -1;
            output = output.map((row) => {
                if (row.rowType === ReportRowType.GroupHeader) {
                    groupIndex++;
                }

                row['_groupIndex'] = groupIndex;
                return row;
            });

            // Sort
            output = this.sortRows(output, settings.sortField, settings.sortDirection);

            // Paging
            if (paginationSliceIndex > 0) {
                output = output.slice(0, paginationSliceIndex);
            }
        }

        return { data: output, hasMoreData: hasMoreData };
    }

    private static sortRows(rows: IReportRow[], sortField: string, direction: string = 'asc'): IReportRow[] {
        if (!sortField) return rows;

        const sortMultiplier = direction === 'asc' ? 1 : -1;

        const getCellValue = (row: IReportRow, field: string) => {
            const value = get(row, field);

            if (!value || typeof value !== 'string') return value;

            let numericValue = parseFloat(value.replace(',', '.').replace(/[^\d.-]/g, ''));

            if (!isNaN(numericValue)) {
                return numericValue;
            }

            return value.toString();
        };

        return rows.sort((rowA, rowB) => {
            if (rowA['_groupIndex'] !== rowB['_groupIndex']) {
                return 0;
            }

            // Don't reorder headers/footers etc
            if (rowA.rowType !== ReportRowType.Row || rowB.rowType !== ReportRowType.Row) {
                return 0;
            }

            const valueA = getCellValue(rowA, sortField);
            const valueB = getCellValue(rowB, sortField);

            if (!valueA) return -1 * sortMultiplier;
            if (!valueB) return 1 * sortMultiplier;

            if (typeof valueA == 'number' && typeof valueB === 'number') {
                return (valueA - valueB) * sortMultiplier;
            } else {
                return valueA.toString().localeCompare(valueB.toString()) * sortMultiplier;
            }
        });
    }

    private static mapQueries(report: IBizReport, settings: IBizReportSettings) {
        return report.Data.routes.map((src) => {
            const name = Object.getOwnPropertyNames(src)[0];
            let query = src[name];
            const macros = this.extractMacros(query);
            for (let i = macros.length - 1; i >= 0; i--) {
                const macro = macros[i];
                let value: string;
                const input = report.Input.filter((x) => x.Name == macro.name);
                if (input?.length > 0) {
                    value = this.mapToSettings(input[0].Default, settings);
                } else {
                    console.log(macro.name + ' not found');
                }
                query =
                    query.substring(0, macro.position) +
                    (value === undefined ? '' : value) +
                    query.substring(macro.position + macro.value.length);
            }
            return { name: name, query: query };
        });
    }

    public static interpolate(value: string, row: {}, valueRequired = false) {
        if (!value) return value;
        let hasInsertedActualValues = false;
        const macros = this.extractMacros(value);
        if (macros.length > 0) {
            for (let i = macros.length - 1; i >= 0; i--) {
                const macro = macros[i];
                let fragment = row[macro.name];
                hasInsertedActualValues = hasInsertedActualValues || !!fragment;
                value =
                    value.substring(0, macro.position) +
                    (fragment || '') +
                    value.substring(macro.position + macro.value.length);
            }
        }
        if (valueRequired && !hasInsertedActualValues) return undefined;
        return value;
    }

    private static calcCellValue(meta: { Source: string; Name?: string; cellType?: cellTypes; macros?: any }, row: {}) {
        if (!meta?.Source) return undefined;

        // First time we check for macros
        if (!meta.cellType) {
            meta.cellType = cellTypes.Regular; // default
            if (meta.Source.indexOf('{') >= 0) {
                // Cache macros on this meta
                meta.macros = this.extractMacros(meta.Source);
                if (meta.macros?.length > 0) {
                    meta.cellType = cellTypes.Macro;
                }
            }
            if (meta.cellType === cellTypes.Regular) {
                meta.Name = meta.Name || meta.Source;
            }
        }

        // Evaluate macro ?
        if (meta.cellType === cellTypes.Macro) {
            const macro = meta.macros[0];
            const value = this.parseExpr(macro.name, row);
            if (!meta.Name) {
                // Missing name? Create one from the expression
                meta.Name = macro.name.replace(/[\W]+/g, '');
            }
            row[meta.Name] = value;
            return value;
        }

        // Default: direct fetch from cell-data
        return row[meta.Source];
    }

    static parseExpr(str: string, params: any) {
        const names = Object.keys(params);
        const vals = Object.values(params);
        return Function(...names, `'use strict';return (${str})`)(...vals);
    }

    private static createRow(type: ReportRowType, row: {}, title?: string, colSpan?: number): IReportRow {
        row = row || {};
        row['rowType'] = type;
        if (title) row['rowTitle'] = title;
        if (colSpan) row['colSpan'] = colSpan;
        return <IReportRow>row;
    }

    private static createSummaryRow(layout: any, summaries: {}, label: string, type = ReportRowType.GroupFooter) {
        const row = this.createRow(type, {}, label);
        const summaryProp = type === ReportRowType.Summary ? 'grandTotal' : 'groupTotal';
        let hasFirstSum = false;

        for (let ci = 0; ci < layout.Columns.length; ci++) {
            const col = layout.Columns[ci];
            const gs = summaries['col' + ci];
            if (gs) {
                if (!hasFirstSum) {
                    row.colSpan = ci;
                    hasFirstSum = true;
                }
                row[col.Name] = gs[summaryProp];
            } else if (col.cellType === cellTypes.Macro && col.Format !== 'percent') {
                row[col.Name] = this.calcCellValue(col, row);
            }
        }

        this.eachKey(summaries, (x: Summary) => (x[summaryProp] = 0));

        return row;
    }

    private static addToSummary(sums: any, col: { Source: string; Format?: string }, value: any, colIndex: number) {
        if (col.Format != 'money') return;
        const key = 'col' + colIndex;
        let sum: Summary = sums[key];

        if (sum && !value) return;

        if (!sum) {
            sum = new Summary(value);
            sums[key] = sum;
            return sum;
        }
        sum.grandTotal += value;
        sum.groupTotal += value;
        return sum;
    }

    private static mapToSettings(value: string, settings: IBizReportSettings): string {
        if (!value) return value;
        switch (value.toString().toLowerCase()) {
            case '$financialyear':
                return settings.financialYear.toString();
            default:
                return value;
        }
    }

    public static extractMacros(
        value: string,
        lc = '{',
        rc = '}',
    ): Array<{ value: string; name: string; position: number }> {
        const result = [];
        let p1 = value.indexOf(lc);
        while (p1 >= 0) {
            let p2 = value.indexOf(rc, p1 + 1);
            if (p2 > 0) {
                const macro = value.substring(p1, p2 + 1);
                result.push({ name: macro.substring(1, macro.length - 1), value: macro, position: p1 });
                p1 = value.indexOf(lc, p2 + 1);
            } else {
                break;
            }
        }
        return result;
    }

    static mapValue(value: string, report: IBizReport, lang = 'NO'): string {
        if (!value) return value;
        if (value[0] === '§') {
            const name = value.substring(1);
            const langSet = report.I18n[lang];
            return langSet ? langSet[name] || name : name;
        }
        return value;
    }

    static locateRow(name: string, items: Array<any>): { index: number; item: any } {
        for (var i = 0; i < items.length; i++) {
            const item = Object.getOwnPropertyNames(items[i]);
            if (item?.length > 0 && item[0] == name) {
                return { index: i, item: items[i] };
            }
        }
    }

    static eachKey(value: Object, callback: Function) {
        if (!value) return;
        const keys = Object.getOwnPropertyNames(value);
        if (keys?.length > 0) {
            keys.forEach((x) => callback(value[x]));
        }
    }

    static getGroupValue(row: any, grouping?: { Source: string; Parent?: string }, level = 0) {
        if (!grouping) return undefined;
        if (level == 1 && grouping.Parent) {
            return row[grouping.Parent];
        }
        if (grouping.Source) {
            return row[grouping.Source];
        }
        return undefined;
    }
}

class Summary {
    grandTotal = 0;
    groupTotal = 0;
    constructor(value = 0) {
        this.grandTotal = value;
        this.groupTotal = value;
    }
}
