<section class="uni-modal" (keydown)="checkForSaveKey($event)">
    <header>{{ options.header }}</header>

    <article>
        <uni-form
            class="two-column-layout"
            [config]="{ showLabelAbove: true }"
            [fields]="fields$ | async"
            [model]="itemSource$ | async"
        >
        </uni-form>
    </article>

    <footer>
        @if (!options.data.readonly) {
            <button class="secondary" (click)="onClose.emit()">Avbryt</button>
            <button class="c2a" (click)="save()">Lagre</button>
        }
    </footer>
</section>
