<section role="dialog" class="uni-modal">
    <header>Feriepenger - oppsett og utbetaling</header>
    <article>
        <section>
            <label class="uni-label label-above">
                <div class="button-on-top gap-1">
                    <b>Grunnlag for opptjente feriepenger</b>
                    <uni-icon
                        [icon]="'info'"
                        [svgSize]="16"
                        tooltipMaxWidth="450px"
                        class="tooltip-icon"
                        matTooltip="I motsetning til et ferieår, som følger kalenderåret, vil opptjeningsåret for feriepenger være det forutgående kalenderår."
                    >
                    </uni-icon>
                </div>
                <div class="year-input">
                    <uni-select
                        [config]="selectYearConfig"
                        [items]="selectYears"
                        [(value)]="activeYear"
                        (valueChange)="onYearDropdownChange($event)"
                    >
                    </uni-select>
                </div>
            </label>
        </section>
        <section class="vacation-settings-container">
            <div class="button-on-top gap-1">
                <b>Sammendrag av instillinger</b>
                <uni-icon
                    [icon]="'info'"
                    [svgSize]="16"
                    tooltipMaxWidth="450px"
                    class="tooltip-icon"
                    matTooltip="Kort oversikt over utvalgte innstillinger på selskapet. Dersom du ønsker å redigere eller lese mer om disse kan det gjøres ved å klikke på Innstillinger.   "
                >
                </uni-icon>
                <button class="c2a secondary pull-right" (click)="openVacationSettingsModal()">
                    Gå til innstillinger
                </button>
            </div>
            <summary-card [options]="summaryOptions" [transparent]="true"></summary-card>
        </section>
        <section class="vacation-data-container">
            <label class="uni-label label-above">
                <div class="button-on-top gap-1">
                    <b>Prosentvis utbetaling av feriepenger</b>
                    <uni-icon
                        [icon]="'info'"
                        [svgSize]="16"
                        tooltipMaxWidth="450px"
                        class="tooltip-icon"
                        matTooltip="Dersom du ønsker at systemet skal regne ut en prosentvis utbetaling av feriepenger. Skriv et tall fra 1 til 100, der 1 = 1 % og 100 = 100%."
                    >
                    </uni-icon>
                </div>
                <input
                    type="number"
                    class="input-for-percent"
                    [(ngModel)]="vacationPayFilter.percentPayout"
                    (change)="onPercentPayoutChanged()"
                />
            </label>
            <ag-grid-wrapper
                [attr.aria-busy]="isBusy"
                [config]="vacationpayBasisTableConfig"
                [resource]="vacationpayBasis"
                (rowChange)="rowChanged($event)"
                (rowClick)="openSingleEditModal($event)"
                (rowSelectionChange)="onRowSelectionChange($event)"
            >
                <div class="flex-1 flex align-center gap-1">
                    <b>Vis i listen:</b>
                    <rig-toggle-button-group [multiple]="true" (change)="onPillChange()">
                        <rig-toggle-button [(checked)]="vacationPayFilter.employeesWithoutVacationPay"
                            >Vis Ansatte uten feriepengegrunnlag</rig-toggle-button
                        >
                        <rig-toggle-button [(checked)]="vacationPayFilter.vacationWeek6"
                            >Inkluder 6. ferieuke</rig-toggle-button
                        >
                    </rig-toggle-button-group>
                </div>
            </ag-grid-wrapper>
        </section>
    </article>
    <footer class="right gap-1">
        <button class="secondary" (click)="onClose.emit(false)">Avbryt</button>
        <combo-button [actions]="saveActions" [mainAction]="mainAction"></combo-button>
    </footer>
</section>
