<div class="import-container">
    <mat-progress-bar
        *ngIf="busy"
        class="uni-progress-bar"
        [mode]="barMode"
        [value]="percentComplete"
    ></mat-progress-bar>

    <section class="alert warn" *ngIf="lastErrorMessage">
        <i class="material-icons">info</i>&nbsp;
        {{ lastErrorMessage }}
    </section>

    <div class="info p1-a mr-1" *ngIf="state">
        <i class="material-icons color-c2a mr-1">info</i>
        <strong>Oppsummering:</strong>
        <li>{{ state.numTotal }} gyldige rader ble funnet</li>
        <li *ngIf="state.numSuccessFull">{{ state.numSuccessFull }} ble importert/oppdatert</li>
        <li *ngIf="state.numSkipped">{{ state.numSkipped }} eksisterte fra før</li>
        <li *ngIf="state.numInvalid">{{ state.numInvalid }} rader var ugyldige (manglet påkrevde felt)</li>
        <div *ngIf="state.numErrors" class="err err-logg">{{ state.numErrors }} feilet. Sjekk loggen for detaljer</div>
    </div>

    <ul>
        <li [class]="{ 'color-warn': item.isError, highlight: item.highlight }" *ngFor="let item of logg">
            {{ item.text }}
        </li>
    </ul>

    <div *ngIf="busy">
        <mat-spinner class="c2a running-progress"></mat-spinner>
    </div>
</div>
