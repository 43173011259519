import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from '@app/authService';
import { UniHttp } from '@uni-framework/core/http';
@Injectable()
export class SurveyService {
    constructor(
        private http: UniHttp,
        private authService: AuthService,
    ) {}

    shouldShowSurvey() {
        return this.http
            .asGET()
            .usingElsaDomain()
            .withEndPoint('/api/usersatisfactionresponses/show/' + this.authService.currentUser.GlobalIdentity)
            .send()
            .pipe(map((res) => res.body));
    }

    postSurveyAnswer(body) {
        return this.http
            .asPOST()
            .usingElsaDomain()
            .withEndPoint('/api/usersatisfactionresponses')
            .withBody(body)
            .send()
            .pipe(map((res) => res.body));
    }

    getGlobalID() {
        return this.authService.currentUser.GlobalIdentity;
    }
}
