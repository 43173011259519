import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/authService';
import { ImportFileType } from '@app/models/import-central/ImportDialogModel';
import { JobService } from '@app/services/admin/jobs/jobService';
import { ToastService, ToastTime, ToastType } from '@uni-framework/uniToast/toastService';
import { switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ImportOptions } from './importService';

// Separated from importService to avoid circular dependencies
@Injectable()
export class ImportJobService {
    constructor(
        private router: Router,
        private http: HttpClient,
        private authService: AuthService,
        private toastService: ToastService,
        private jobService: JobService,
    ) {}

    startImport(file: File, options: ImportOptions) {
        return this.uploadFileToFileServer(file, options.entityType, options.description).pipe(
            switchMap((res) => {
                const company = this.authService.activeCompany;

                const body = {
                    ...options.importModel,
                    ImportFileType: this.getImportFileType(file),
                    CompanyKey: company.Key,
                    CompanyName: company.Name,
                    Url: `${environment.BASE_URL_FILES}/api/externalfile/${company.Key}/${res.StorageReference}/${res.PublicToken}`,
                };

                return this.jobService.startJob(options.jobName, 0, body).pipe(
                    tap(() => {
                        this.toastService.toast({
                            title: 'Import startet, fremdrift finner du i import loggen',
                            type: ToastType.info,
                            duration: ToastTime.medium,
                            actions: [
                                {
                                    label: 'Gå til import logg',
                                    click: () => {
                                        this.router.navigate(['/import/log', { id: options.templateType }]);
                                    },
                                },
                            ],
                        });
                    }),
                );
            }),
        );
    }

    private uploadFileToFileServer(file: File, entityType: string, description?: string) {
        const data = new FormData();
        data.append('Token', this.authService.jwt);
        data.append('Key', this.authService.activeCompany.Key);
        data.append('EntityType', entityType);
        data.append('Description', description);
        data.append('WithPublicAccessToken', 'true');
        data.append('File', file);

        return this.http.post<any>(environment.BASE_URL_FILES + '/api/file', data);
    }

    private getImportFileType(file: File) {
        const type = file?.name?.toLowerCase().split(/[.]+/).pop();

        if (type === 'txt' || type === 'xlsx' || type === 'csv') {
            switch (type) {
                case 'txt':
                    return ImportFileType.StandardUniFormat;
                case 'xlsx':
                    return ImportFileType.StandardizedExcelFormat;
                case 'csv':
                    return ImportFileType.StandardizedCSVFormat;
                default:
                    return ImportFileType.StandardizedExcelFormat;
            }
        }
    }
}
