import { Component, EventEmitter } from '@angular/core';
import { ErrorService } from '@app/services/common/errorService';
import { UserService } from '@app/services/common/userService';
import { CustomerInvoiceReminderService } from '@app/services/sales/customerInvoiceReminderService';
import { IModalOptions, IUniModal } from '@uni-framework/uni-modal';

@Component({
    selector: 'reminder-sending-modal',
    templateUrl: './reminder-sending-modal.html',
    styleUrls: ['./reminder-sending-modal.sass'],
})
export class ReminderSendingModal implements IUniModal {
    options: IModalOptions = {};
    onClose = new EventEmitter();

    reminderIDs: number[];
    isSingleReminder: boolean;
    caseHandlerEmail: string;

    busy: boolean;
    alreadySentWarning: string;

    hasDistributionPlan?: boolean;
    canDistributeAllRemindersUsingPlan: boolean;
    hasPrintService: boolean;

    remindersWithEmail: number;
    remindersWithPrint: number;

    sendByEmail = true;
    sendByEmailDisabled: boolean;

    sendByPrint = false;
    sendByPrintDisabled: boolean;

    sendToCaseHandler = true;
    hasPaymentsMatched = true;

    constructor(
        private userService: UserService,
        private errorService: ErrorService,
        private reminderService: CustomerInvoiceReminderService,
    ) {
        this.userService.getCurrentUser().subscribe((res) => {
            this.caseHandlerEmail = res.Email;
        });
    }

    ngOnInit() {
        this.reminderIDs = this.options.data.reminderIDs;
        this.isSingleReminder = this.reminderIDs?.length === 1;

        if (this.options.data.distributionInfo) {
            this.init(this.options.data.distributionInfo);
        } else {
            this.busy = true;
            this.reminderService.ActionWithBody(null, this.reminderIDs, 'can-distribute-reminders').subscribe(
                (distributionInfo) => {
                    this.init(distributionInfo);
                    this.busy = false;
                },
                (err) => {
                    this.errorService.handle(err);
                    this.busy = false;
                },
            );
        }
    }

    init(distributionInfo) {
        this.hasDistributionPlan = distributionInfo.RemindersWithDistributionPlan > 0;
        this.canDistributeAllRemindersUsingPlan = distributionInfo.CanDistributeAllRemindersUsingPlan;
        this.hasPrintService = distributionInfo.HasPrintService;

        this.remindersWithEmail = distributionInfo.RemindersWithEmail;
        this.remindersWithPrint = distributionInfo.RemindersWithPrint;

        this.sendByEmailDisabled = !this.remindersWithEmail;
        this.sendByEmail = !this.sendByEmailDisabled;

        this.sendByPrintDisabled = !this.hasPrintService || !this.remindersWithPrint;
        this.sendByPrint = false;

        if (distributionInfo.AlreadySentCount) {
            const reminderText = this.isSingleReminder
                ? 'Purringen'
                : `${distributionInfo.AlreadySentCount} av purringene`;
            this.alreadySentWarning = `${reminderText} er allerede markert som sendt, og vil bli sendt på nytt dersom du fortsetter`;
        }

        this.hasPaymentsMatched = !distributionInfo.PaymentsNotMatchedCount;
    }

    send() {
        const body = {
            ReminderIds: this.reminderIDs,
            SendByDistributionPlanFirst: true,
            SendByEmailIfPossible: this.sendByEmail,
            SendByPrintServiceIfPossible: this.sendByPrint,
            SendRemainingToCasehandler: this.sendToCaseHandler,
            CasehandlerEmail: this.caseHandlerEmail,
        };

        this.busy = true;
        this.reminderService.ActionWithBody(null, body, 'distribute-reminders').subscribe(
            () => this.onClose.emit(true),
            (err) => {
                this.busy = false;
                this.errorService.handle(err);
            },
        );
    }
}
