import {
    Component,
    Input,
    ChangeDetectionStrategy,
    Output,
    EventEmitter,
    OnDestroy,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import {
    IUniEditorButton,
    IUniEditorLayout,
} from '@app/components/common/uni-entity-editor/uni-editor-layout.interface';
import { IUniEditorChangeEvent } from '@app/components/common/uni-entity-editor/uni-editor-change-event.interface';
import { UniEntityEditorFocusService } from '@app/components/common/uni-entity-editor/shared/services/uni-entity-editor-focus.service';
import { filter, map } from 'rxjs/operators';
export interface IUniEntityEditorConfig {
    autoFocus?: boolean;
}
@Component({
    selector: 'uni-entity-editor',
    templateUrl: './uni-entity-editor.component.html',
    styleUrls: ['./uni-entity-editor.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniEntityEditorComponent implements OnChanges, OnDestroy {
    @Input() editorLayouts: IUniEditorLayout[];
    @Input() model: any;
    @Input() config: IUniEntityEditorConfig;
    @Input() buttons: IUniEditorButton[];
    @Output() changeEvent: EventEmitter<IUniEditorChangeEvent> = new EventEmitter<IUniEditorChangeEvent>();

    constructor(private focusService: UniEntityEditorFocusService) {}

    ngOnDestroy(): void {
        this.focusService.clearFocus(this.editorLayouts.map((layout) => layout.title));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes['editorLayouts']) {
            return;
        }
        this.focusService
            .getFocus()
            .pipe(
                filter((focus) => !focus && !!this.editorLayouts?.length),
                map(() => this.editorLayouts[0]),
            )
            .subscribe((layout) => {
                this.focusService.setFocus({
                    layoutTitle: layout.title,
                    fieldProperty: layout.fieldGroups
                        .reduce((acc, curr) => [...acc, ...curr.fields], [])
                        .filter((field) => !field.ReadOnly && !field.Hidden)[0]?.Property,
                });
            });
    }

    onEditorChange(event: IUniEditorChangeEvent) {
        if (this.hasChanges(event.changes)) {
            this.changeEvent.emit(event);
        }
    }

    private hasChanges(changes: SimpleChanges) {
        return Object.keys(changes).some((key) => changes[key].previousValue !== changes[key].currentValue);
    }
}
