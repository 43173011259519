import { Injectable } from '@angular/core';
import { AuthService } from '@app/authService';
import { UniCache } from '@app/cache';
import { StatisticsService } from '@app/services/common/statisticsService';
import { cloneDeep } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { getHash } from '../common/utils/utils';

export interface ReportParamSearchConfig {
    getInitData?: () => Observable<any[]>;
    lookup: (query: string) => Observable<any[]>;
    displayFunction: (item: any) => string;
}

@Injectable()
export class ReportParamSearchService {
    requestCache = new UniCache(5);

    constructor(
        private statisticsService: StatisticsService,
        private authService: AuthService,
    ) {
        this.authService.companyChange.subscribe(() => this.requestCache.clear());
    }

    getSearchConfig(
        searchModel: string,
        value?: any,
        createGenericConfigOnNoSearchModelMatch = false,
        orderByDescending = false,
    ) {
        if (!searchModel) return;

        const [model, valueField] = searchModel.toLowerCase().split('.');

        if (model === 'supplier' || model === 'customer' || model === 'employeeledger') {
            return this.getSupplierAndCustomerConfig(model, valueField, value);
        }

        if (model === 'project' || model === 'department') {
            const label = model === 'project' ? 'prosjekt' : 'avdeling';
            return this.getDimensionConfig(model, valueField, value, label);
        }

        if (model === 'account') {
            return this.getAccountConfig(model, valueField, value);
        }

        if (model === 'customerinvoice' || model === 'customerorder' || model === 'customerquote') {
            return this.getTOFConfig(model, valueField, value, orderByDescending);
        }

        if (model === 'worker') {
            return this.getWorkerConfig(model, valueField, value);
        }

        if (model === 'payrollrun') {
            return this.getPayrollRunConfig(model, valueField, value);
        }

        if (model === 'employee') {
            return this.getEmployeeConfig(model, valueField, value);
        }

        if (model === 'subentity') {
            return this.getSubEntityConfig(model, valueField, value);
        }

        if (createGenericConfigOnNoSearchModelMatch) {
            return this.getFallbackConfig(searchModel, value);
        }
    }

    private cachedStatisticsRequest(query: string): Observable<any[]> {
        const hash = getHash(query);

        let request = this.requestCache.get(hash);
        if (!request) {
            request = this.statisticsService.GetAllUnwrapped(query).pipe(shareReplay(1));

            this.requestCache.add(hash, request);
        }

        // cloneDeep prevents code from mutating the cached data
        return request.pipe(map((data) => cloneDeep(data)));
    }

    private getSupplierAndCustomerConfig(model: string, valueField: string, value?: any): ReportParamSearchConfig {
        let isEmployeeLedger = false;
        if (model === 'employeeledger') {
            isEmployeeLedger = true;
            model = 'supplier';
        }

        const numberField = `${model}number`;
        let odata =
            `model=${model}&expand=Info&top=50` +
            `&select=${valueField} as value,${numberField} as ${numberField},Info.Name as label`;

        let staticFilter = '';

        if (model === 'supplier') {
            odata += `&join=supplier.SubAccountNumberSeriesID eq NumberSeries.ID and NumberSeries.NumberSeriesTypeID eq NumberSeriesType.ID`;
            staticFilter = `numberseriestype.isemployeeledger eq ${isEmployeeLedger ? 1 : 0}`;
        }

        return {
            lookup: (input) => {
                let filter = staticFilter || '';

                if (input) {
                    filter += filter.length ? ' and ' : '';
                    filter += `( contains(Info.Name,'${input}') or contains(${numberField},'${input}') )`;
                }

                return this.cachedStatisticsRequest(odata + (filter.length ? `&filter=${filter}` : ''));
            },
            getInitData: this.getInitDataResolver(odata, valueField, value, staticFilter),
            displayFunction: (item) => (item ? `${item[numberField]} - ${item.label}` : ''),
        };
    }

    getDimensionConfig(model: string, valueField: string, value: any, label: string): ReportParamSearchConfig {
        let numberField = 'number';
        if (model === 'project' || model === 'department') {
            numberField = `${model}Number`;
        }
        let odata = ``;

        odata =
            `model=${model}&top=0&orderby=${numberField}` +
            `&select=${valueField} as value,${numberField} as ${numberField},Name as label, StatusCode as StatusCode`;

        let getInitData = this.getInitDataResolver(odata, valueField, value);

        if (value === '0') {
            getInitData = () =>
                of([
                    {
                        label: `Uten ${label || ''}`,
                        value: '0',
                    },
                ]);
        }

        return {
            lookup: (input) => {
                let filter = '';
                if (input) {
                    if (model === 'project') {
                        filter = `&filter=contains(${numberField},'${input}') and Statuscode ne 42204 or contains(Name,'${input}') and Statuscode ne 42204 or Name eq '${input}' or ${numberField} eq '${input}' `;
                    } else {
                        filter = `&filter=contains(${numberField},'${input}') or contains(Name,'${input}')`;
                    }
                } else {
                    if (model === 'project') {
                        filter = '&filter=Statuscode ne 42204';
                    }
                }

                return this.cachedStatisticsRequest(odata + filter).pipe(
                    map((res) => {
                        const data = (res || []).sort((itemA, itemB) => {
                            const itemANumber = parseInt(itemA[numberField]) || 0;
                            const itemBNumber = parseInt(itemB[numberField]) || 0;

                            return itemANumber ? itemANumber - itemBNumber : 1;
                        });

                        const nullItemLabel = `Uten ${label || ''}`;

                        if (!input || nullItemLabel.toLowerCase().includes(input.toLowerCase())) {
                            data.unshift({ label: nullItemLabel, value: '0' });
                        }

                        return res;
                    }),
                );
            },
            getInitData,
            displayFunction: (item) => {
                if (item) {
                    return item[numberField] ? `${item[numberField]} - ${item.label}` : item.label;
                }
            },
        };
    }

    private getAccountConfig(model: string, valueField: string, value?: any): ReportParamSearchConfig {
        const odata =
            `model=${model}&top=50` +
            `&select=${valueField} as value,AccountNumber as AccountNumber,AccountName as AccountName`;

        return {
            lookup: (input) => {
                let filter = '&filter=isnull(CustomerID,0) eq 0 and isnull(SupplierID,0) eq 0';
                if (input) {
                    filter += ` and ( contains(AccountName,'${input}') or startswith(AccountNumber,'${input}') )`;
                }

                return this.cachedStatisticsRequest(odata + filter);
            },
            getInitData: this.getInitDataResolver(odata, valueField, value),
            displayFunction: (item) => (item ? `${item.AccountNumber} - ${item.AccountName}` : ''),
        };
    }

    private getTOFConfig(
        model: string,
        valueField: string,
        value?: any,
        sortByDescending = false,
    ): ReportParamSearchConfig {
        const sorting = sortByDescending ? `&OrderBy=${valueField} DESC` : '';
        const numberField = model.replace('customer', '') + 'number';
        const odata =
            `model=${model}&top=50` +
            `&select=${valueField} as value,${numberField} as ${numberField},CustomerName as CustomerName` +
            sorting;

        return {
            lookup: (input) => {
                let filter = `&filter=isnull(${numberField},0) gt 0`;
                if (input) {
                    filter += ` and ( contains(${numberField},'${input}') or contains(CustomerName,'${input}') )`;
                }

                return this.cachedStatisticsRequest(odata + filter);
            },
            getInitData: this.getInitDataResolver(odata, valueField, value),
            displayFunction: (item) => (item ? `${item[numberField] || ''} - ${item.CustomerName}` : ''),
        };
    }

    private getWorkerConfig(model: string, valueField: string, value?: any): ReportParamSearchConfig {
        const odata = `model=${model}&expand=Info&top=50&select=${valueField} as value,ID as ID,Info.Name as Name`;
        return {
            lookup: (input) => {
                let filter = input ? `&filter=ID eq ${input} or contains(Info.Name,'${input}')` : '';
                return this.cachedStatisticsRequest(odata + filter);
            },
            getInitData: this.getInitDataResolver(odata, valueField, value),
            displayFunction: (item) => (item ? `${item.ID} - ${item.Name}` : ''),
        };
    }

    private getPayrollRunConfig(model: string, valueField: string, value?: any): ReportParamSearchConfig {
        const odata = `model=${model}&top=50&select=${valueField} as value,ID as ID,Description as Description`;
        const staticFilter = `year(PayDate) eq activeyear()`;

        return {
            lookup: (input) => {
                let filter = staticFilter;
                if (input) {
                    filter += ` and ( startswith(ID,'${input}') or contains(Description,'${input}') )`;
                }

                return this.cachedStatisticsRequest(odata + `&filter=${filter}`);
            },
            getInitData: this.getInitDataResolver(odata, valueField, value, staticFilter),
            displayFunction: (item) => (item ? `${item.ID} - ${item.Description}` : ''),
        };
    }

    private getEmployeeConfig(model: string, valueField: string, value?: any): ReportParamSearchConfig {
        const odata =
            `model=${model}&expand=BusinessRelationInfo&top=50` +
            `&select=${valueField} as value,ID as ID,EmployeeNumber as EmployeeNumber,BusinessRelationInfo.Name as Name`;

        const staticFilter = `( isnull(EndDate,'') eq '' or year(EndDate) ge activeyear() )`;

        return {
            lookup: (input) => {
                let filter = staticFilter;
                if (input) {
                    filter += ` and ( startswith(EmployeeNumber,'${input}') or contains(BusinessRelationInfo.Name,'${input}') )`;
                }

                return this.cachedStatisticsRequest(odata + `&filter=${filter}`);
            },
            getInitData: this.getInitDataResolver(odata, valueField, value, staticFilter),
            displayFunction: (item) => (item ? `${item.EmployeeNumber} - ${item.Name}` : ''),
        };
    }

    private getSubEntityConfig(model: string, valueField: string, value?: any): ReportParamSearchConfig {
        const odata =
            `model=${model}&expand=BusinessRelationInfo&top=50` +
            `&select=${valueField} as value,ID as ID,OrgNumber as OrgNumber,BusinessRelationInfo.Name as Name`;

        const staticFilter = `SuperiorOrganizationID ge 0`;

        return {
            lookup: (input) => {
                let filter = staticFilter;
                if (input) {
                    filter += ` and ( contains(OrgNumber,'${input}') or contains(BusinessRelationInfo.Name,'${input}') )`;
                }

                return this.cachedStatisticsRequest(odata + `&filter=${filter}`);
            },
            getInitData: this.getInitDataResolver(odata, valueField, value, staticFilter),
            displayFunction: (item) => (item ? `${item.OrgNumber} - ${item.Name}` : ''),
        };
    }

    private getFallbackConfig(searchModel: string, value?: any): ReportParamSearchConfig {
        if (!searchModel?.includes('.')) return;

        let [model, field, labelField, expand, orderby] = searchModel.split('.');

        labelField = labelField || field;

        let odata =
            `model=${model}&top=30` +
            `&select=ID as ID,${field} as value,${labelField} as label` +
            (expand ? `&expand=${expand}` : '') +
            (orderby ? `&orderby=${orderby}` : '');

        return {
            lookup: (input) => {
                input = input?.toLowerCase().replace(/[`~!@#$^&*()_|+\=?;:'",.<>\{\}\[\]\\\/]/gi, '');

                const isNumeric = !isNaN(parseInt(input));

                const filter = isNumeric ? `startswith(${field},'${input}')` : `contains(${labelField},'${input}')`;

                return this.cachedStatisticsRequest(odata + `&filter=${filter}`);
            },
            getInitData: this.getInitDataResolver(odata, field, value),
            displayFunction: (item) => {
                if (item.label === item.value) return item.value;
                if (!item.label) return item.value;
                if (!item.value) return item.label;

                return `${item.value} - ${item.label}`;
            },
        };
    }

    private getInitDataResolver(odata: string, valueField: string, value: any, staticFilter?: string) {
        return () => {
            if (!value) return of([]);

            const filterValue = Number(value) ? value : `'${value}'`;
            let filter = `&filter=${valueField} eq ${filterValue}`;

            if (staticFilter) {
                filter += `&${staticFilter}`;
            }

            return this.cachedStatisticsRequest(odata + filter);
        };
    }
}
