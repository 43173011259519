import { Component, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { forkJoin } from 'rxjs';
import { UniTableConfig, UniTableColumn } from '../../../../framework/ui/unitable/index';
import { File, FileEntityLink } from '../../../unientities';
import { ToastService, ToastType } from '@uni-framework/uniToast/toastService';
import { ImageModal } from '../modals/ImageModal';
import { ConfirmActions, UniModalService } from '../../../../framework/uni-modal';
import { ErrorService } from '@app/services/common/errorService';
import { FileService } from '@app/services/common/fileService';
import { StatisticsService } from '@app/services/common/statisticsService';
import { switchMap } from 'rxjs/operators';

@Component({
    selector: 'uni-attachments',
    templateUrl: './attachments.html',
})
export class UniAttachments {
    @Input() entity: string;
    @Input() entityID: number;
    @Input() readonly: boolean;
    @Input() showFileList: boolean = true;
    @Input() uploadWithoutEntity: boolean = false;
    @Input() tooltip: string;
    @Input() multirowSelect: boolean = true;
    @Input() verticalLayout: boolean;

    @Output() filesLoadedOrChanged = new EventEmitter<File[]>();
    @Output() filesUploaded = new EventEmitter<File[]>();
    @Output() selectedFileSize: EventEmitter<number> = new EventEmitter();

    private fileLinks: FileEntityLink[] = [];

    files: File[] = [];
    tableConfig: UniTableConfig;
    fileOver = false;

    constructor(
        private errorService: ErrorService,
        private fileService: FileService,
        private modalService: UniModalService,
        private statisticsService: StatisticsService,
        private toast: ToastService,
    ) {
        const fileNameCol = new UniTableColumn('Name', 'Filnavn');
        const fileSizeCol = new UniTableColumn('Size', 'Størrelse').setTemplate(
            (file) => `${Math.ceil((file.Size || 0) / 1024)} KB`,
        );

        this.tableConfig = new UniTableConfig('attachments', false)
            .setColumns([fileNameCol, fileSizeCol])
            .setDeleteButton(true)
            .setSearchable(false)
            .setHideRowCount(true)
            .setMultiRowSelect(this.multirowSelect);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.showFileList && (changes['entity'] || changes['entityID']) && this.entity && this.entityID) {
            this.loadFiles();
        } else {
            this.files = [];
        }

        if (changes['multirowSelect']) {
            this.tableConfig = this.tableConfig.setMultiRowSelect(changes['multirowSelect'].currentValue);
        }
    }

    private loadFiles() {
        forkJoin([
            this.statisticsService.GetAllUnwrapped(
                `model=FileEntityLink` +
                    `&select=ID as ID,FileID as FileID,IsAttachment as IsAttachment` +
                    `&filter=EntityType eq '${this.entity}' and EntityID eq ${this.entityID}`,
            ),
            this.fileService.getFilesOnEntity(this.entity, this.entityID),
        ]).subscribe(
            ([fileLinks, files]) => {
                this.fileLinks = fileLinks;

                this.files = files
                    .map((file) => {
                        const link = this.fileLinks.find((l) => l.FileID === file.ID);
                        if (link) {
                            file._rowSelected = link.IsAttachment;
                        }
                        return file;
                    })
                    .filter((f) => f.StatusCode !== 50001);

                this.filesLoadedOrChanged.next(this.files);
            },
            (err) => this.errorService.handle(err),
        );
    }

    onFilesUploaded(files: File[]) {
        this.loadFiles();

        if (this.entity === 'CustomerInvoice') {
            const filesValidForEHFSending = files.every((file) => {
                return ['.csv', '.pdf', '.png', '.jpg', '.xlsx', '.ods'].some((type) => {
                    return file.Name?.toLowerCase().includes(type.toLowerCase());
                });
            });

            if (!filesValidForEHFSending) {
                this.toast.toast({
                    title: 'Ugyldig filtype for vedlegg til EHF',
                    message:
                        'Du har valgt en fil som ikke er godkjent som vedlegg for EHF, og vil ikke bli lagt til som vedlegg om du velger utsendelse via EHF. Gyldige formater er CSV, PDF, PNG, JPG, XLSX og ODS.',
                    type: ToastType.warn,
                    duration: 10,
                });
            }
        }
    }

    onAttachmentClicked(attachment: File) {
        const data = {
            entity: this.entity,
            entityID: this.entityID,
            showFileID: attachment.ID,
            readonly: true,
        };

        this.modalService.open(ImageModal, { data: data });
    }

    onRowSelectionChange(selectedFiles: File[]) {
        if (!this.multirowSelect) {
            return;
        }

        this.fileLinks.forEach((link) => {
            const isSelected = selectedFiles.some((f) => f.ID === link.FileID);
            if (isSelected !== link.IsAttachment) {
                link.IsAttachment = !link.IsAttachment;
                this.fileService.setIsAttachment(this.entity, this.entityID, link.FileID, link.IsAttachment).subscribe(
                    () => {},
                    (err) => {
                        this.errorService.handle(err);
                        link.IsAttachment = !link.IsAttachment;
                    },
                );
            }
        });

        var selectedSize = selectedFiles.map((file) => file.Size).reduce((a, b) => a + b, 0);
        this.selectedFileSize.emit(selectedSize);
    }

    onRowDeleted(file) {
        this.modalService
            .confirm({
                header: 'Bekreft sletting',
                message: 'Ønsker du å slette filen for godt, eller fjerne den herfra og legge i innboksen i stedet?',
                buttonLabels: {
                    cancel: 'Avbryt',
                    reject: 'Legg i innboks',
                    accept: 'Slett',
                },
                submitButtonClass: 'bad',
            })
            .onClose.subscribe((res) => {
                if (res === ConfirmActions.ACCEPT) {
                    this.fileService.deleteOnEntity(this.entity, this.entityID, file.ID).subscribe({
                        next: () => this.loadFiles(),
                        error: (err) => this.errorService.handle(err),
                    });
                } else if (res === ConfirmActions.REJECT) {
                    if (this.entity && this.entityID) {
                        this.fileService
                            .unlinkFile(this.entity, this.entityID, file.ID)
                            .pipe(switchMap(() => this.fileService.tag(file.ID, 'Upload')))
                            .subscribe({
                                next: () => this.loadFiles(),
                                error: (err) => this.errorService.handle(err),
                            });
                    }
                }
            });
    }
}
