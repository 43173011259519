<section role="dialog" class="uni-modal">
    <header>Varsler</header>
    <article>
        <section *ngIf="busy" class="modal-spinner">
            <mat-spinner class="small"></mat-spinner>
        </section>

        <section class="alert info">
            <i class="material-icons">info</i>
            <section>
                Velg hvordan du vil bli varslet om hendelser i systemet.<br />
                Du vil ikke motta varsler om hendelser som ikke er haket av i listen.
            </section>
        </section>

        <simple-table class="selectable">
            <thead>
                <tr>
                    <th>Type varsel</th>
                    <th [ngClass]="settings?.length > 2 ? 'align-left' : 'text-align-center'">
                        <rig-checkbox
                            *ngIf="settings?.length > 2"
                            [disabled]="busy"
                            [(ngModel)]="subscribedToAllWebNotifications"
                            (change)="onAllWebToggled()"
                        >
                        </rig-checkbox>
                        På web
                    </th>

                    <th [ngClass]="settings?.length > 2 ? 'align-left' : 'text-align-center'">
                        <rig-checkbox
                            *ngIf="settings?.length > 2"
                            [disabled]="busy"
                            [(ngModel)]="subscribedToAllEmailNotifications"
                            (change)="onAllEmailsToggled()"
                        >
                        </rig-checkbox>
                        Epost
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    *ngFor="let item of settings"
                    [ngClass]="querySelectedNotificationType === item.NotificationType ? 'selected' : ''"
                >
                    <td>
                        {{ item.displayName }}
                    </td>

                    <td [ngClass]="settings?.length > 2 ? 'align-left' : 'text-align-center'">
                        <rig-checkbox
                            [disabled]="item.busy"
                            [checked]="!item.IgnoreNotification"
                            (change)="toggle(item, 'IgnoreNotification')"
                        >
                        </rig-checkbox>
                    </td>

                    <td [ngClass]="settings?.length > 2 ? 'align-left' : 'text-align-center'">
                        <rig-checkbox
                            [disabled]="item.busy"
                            [checked]="!item.IgnoreEmail"
                            (change)="toggle(item, 'IgnoreEmail')"
                        >
                        </rig-checkbox>
                    </td>
                </tr>
            </tbody>
        </simple-table>
    </article>

    <footer>
        <button class="secondary" (click)="onClose.emit()">Lukk</button>
    </footer>
</section>
